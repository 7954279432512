<template lang="pug">
div(v-if='merchantIncomes')
  .sl-title {{ merchantIncomes.length }}
  v-row(wrap)
    v-col(cols='12' sm='6' md='3' v-for='(item, index) in merchantIncomes' :key='index' fill-height)
      v-card
        v-card-text
          .d-flex.flex-row.align-center
            .sl-title {{ item.year }} / {{ item.month }}
            v-spacer
            Edit(:input='item')
          div {{ item.income | currency }}
          div {{ item.transAmount | currency }} ({{ item.income / item.transAmount * 1000 | number(1) }}&#137;)
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "./Edit";

export default {
  components: { Edit },
  computed: {
    ...mapGetters(["merchantIncomes"])
  }
};
</script>

<template lang="pug">
div
  IconBtn(@click="add()", :loading="loading", icon="mdi-plus", title="Add")
  v-dialog(v-model="dialog", width="350")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Add a Partner
      v-card-text
        v-form(@submit.prevent="submit")
          v-text-field(v-model="name", label="Name")
          PhoneField(v-model="phone")
          v-text-field(label="Email", v-model.trim="email")
          v-select(label="Country", v-model="country", :items="country_items")
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="!valid",
            :loading="loading"
          ) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreatePartner",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      email: "",
      name: "",
      phone: "",
      country: "US",
      country_items: ["US", "CA"],
    };
  },
  computed: {
    valid() {
      return !!this.email && !!this.name && !!this.phone && !!this.country;
    },
  },
  methods: {
    ...mapActions(["addPartner"]),
    add() {
      this.dialog = true;
      this.email = "";
      this.name = "";
      this.phone = "";
      this.country = "US";
    },
    async submit() {
      const params = {
        userEmail: this.email,
        name: this.name.trim(),
        phone: this.phone,
        country: this.country,
      };
      this.loading = true;
      const result = await this.$api.partner.account.create(params);
      this.loading = false;
      this.dialog = false;
      this.addPartner(result);
    },
  },
};
</script>

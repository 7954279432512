<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Name
        th Address
        th Delivery
        th Public
        th Actions
    tbody
      tr(v-for="(item, index) in bizs", :key="index")
        td {{ item.name }}
        td {{ item.address.city }}, {{ item.address.state }}
        td {{ item.orderType.delivery.deliv }}
        td {{ item.orderType.delivery.price | currency }}
        td
          v-menu(offset-y, v-if="item.orderType.delivery.deliv")
            template(v-slot:activator="{ on }")
              v-btn(icon, small, v-on.stop="on")
                v-icon(color="secondary") mdi-dots-horizontal
            v-list(dense)
              v-list-item(@click="updatePrice(item)") Update Price
              v-list-item(@click="handleDisconnect(item)") Disconnect
</template>

<script>
export default {
  data() {
    return {
      bizs: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = {
        criteria: { "orderType.delivery.status": true },
        select: "name address orderType",
      };
      const result = await this.$api.biz.list(params);
      this.bizs = result?.toSorted((a, b) => a.name.localeCompare(b.name));
    },
    async updatePrice(biz) {
      if (!biz || !biz._id) return;
      const params = {
        criteria: { _id: biz._id },
        action: { "orderType.delivery.price": 5.99 },
      };
      await this.$api.biz.update(params);
      this.load();
    },
    handleDisconnect(biz) {
      confirm("Do you want to disconnect this partner?") &&
        this.disconnect(biz);
    },
    disconnect(biz) {
      if (!biz || !biz._id || !biz.orderType.delivery.deliv) return;
      const data = { bizId: biz._id, delivId: biz.orderType.delivery.deliv };
      this.axios
        .post("/delivPartner/disconnect", data)
        .then(() => {
          this.load();
        })
        .catch((err) => {
          this.$toast.error(err.response?.data || err.message);
        });
    },
  },
};
</script>
<template lang="pug">
div
  YearSelector(@select="select")
  OnlineWeekly(:year="year")
  DelivWeekly(:year="year")
</template>

<script>
import OnlineWeekly from "./OnlineWeekly";
import DelivWeekly from "./DelivWeekly/index";

export default {
  components: { OnlineWeekly, DelivWeekly },
  data() {
    return { year: null };
  },
  methods: {
    select(year) {
      this.year = year;
    },
  },
};
</script>

<template lang="pug">
v-row(wrap, dense)
  v-col(cols="12", sm="6")
    Last4Week
  v-col(cols="12", sm="6")
    Last4WeekDeliv
</template>

<script>
import Last4Week from "./Last4Week";
import Last4WeekDeliv from "./Last4WeekDeliv/index.vue";

export default {
  components: { Last4Week, Last4WeekDeliv },
};
</script>

<template lang="pug">
div
  v-btn.text-capitalize(@click="open()", rounded, small, color="secondary") Manaul Send
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Send Marketing Email Manually
      v-card-text
        BizSelector(@select="select")
        v-form(v-if="selectedBiz", @submit.prevent="send()")
          v-row
            v-col(cols="6")
              v-text-field(v-model="email", label="Member Email")
            v-col(cols="6")
              v-select(
                v-model="promoType",
                :items="promo_items",
                label="Promotion Type"
              )
          v-btn(color="secondary", block, @click="send()") Remote Load
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    selectedBiz: null,
    email: "",
    promoType: 2,
    promo_items: [
      { text: "Birthday", value: 2 },
      { text: "30 Day", value: 5 },
      { text: "60 Day", value: 6 },
      { text: "90 Day", value: 7 },
    ],
  }),
  methods: {
    open() {
      this.dialog = true;
      this.selectedBiz = null;
      this.email = "";
      this.promoType = 2;
    },
    reset() {
      this.dialog = false;
      this.selectedBiz = null;
      this.email = "";
      this.promoType = 2;
    },
    select(biz) {
      this.selectedBiz = biz;
    },
    async send() {
      if (!this.selectedBiz?._id) {
        return this.$toast.error("Incorrect biz");
      }
      const params = {
        bizId: this.selectedBiz._id,
        memberEmail: this.email,
        promoType: this.promoType,
      };
      try {
        await this.axios.post("/member/email/manualSend", params);
        this.$toast.success("done");
        this.reset();
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

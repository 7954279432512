<template lang="pug">
v-btn(small, text, color="secondary", @click="load()", :loading="loading") Connect Terminal
</template>

<script>
export default {
  props: {
    mid: { type: String, required: true },
    hsn: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  methods: {
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = { mid: this.mid, hsn: this.hsn };
      try {
        await this.$api.cardpointe.bolt.connect(params);
        this.$toast.success("Terminal connected");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
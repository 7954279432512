<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", small, outlined, color="secondary") Select Item
  v-list
    v-list-item(
      v-for="(item, index) in items",
      :key="index",
      @click="select(item)"
    )
      v-list-item-title {{ item.name }}
</template>

<script>
export default {
  props: ["items"],
  methods: {
    select(item) {
      this.$emit("select", item);
    },
  },
};
</script>
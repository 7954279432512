<template lang="pug">
div
  Page(title="Bizs", :items="items")
  HoursDialog
</template>

<script>
import HoursDialog from "/libs/components/Biz/HoursDialog/index.vue";

export default {
  components: { HoursDialog },
  data() {
    return {
      items: [
        { title: "Search", url: "/bizs/search" },
        { title: "Month", url: "/bizs/month" },
        { title: "Stripe", url: "/bizs/stripe" },
        { title: "Descriptor", url: "/bizs/stripe_descriptor" },
        { title: "Online", url: "/bizs/online" },
        { title: "Timecard", url: "/bizs/timecard" },
        { title: "Requests", url: "/bizs/requests" },
        { title: "Domains", url: "/bizs/domains" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        td
        td Name
        td POS
        td CCM
        td KDS
        td Inventory
        td Member
        td Reservation
        td Drive Thru
        td Front Facing
        td Third Party
        td Discount
        td Custom
        td Amount
        td Resell Partner
        td
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        role="button",
        @click="edit(item.biz)"
      )
        td {{ index + 1 }}
        td(:class="{ 'red--text': item.ended }") {{ item.name }}
          v-icon.ml-2(color="red", small, v-if="item.waive") mdi-cancel
        td
          v-icon(v-if="item.pos && item.pos.status", color="success", small) check
        td
          div(v-if="item.ccm && item.ccm.status")
            v-icon(color="success", small) check
            v-chip.ml-2(outlined, x-small) {{ item.ccm.quantity }}
        td
          v-icon(v-if="item.kds", color="success", small) check
        td
          v-icon(v-if="item.inventory", color="success", small) check
        td
          span(v-if="item.member") {{ item.member }}
        td
          v-icon(v-if="item.rsv", color="success", small) check
        td
          v-icon(v-if="item.dt", color="success", small) check
        td
          v-icon(v-if="item.ff", color="success", small) check
        td
          v-icon(
            v-if="item.third_party && item.third_party.status",
            color="success",
            small
          ) check
        td.red--text
          span(v-if="item.discount && item.discount.amount") {{ -item.discount.amount | currency }}
        td.green--text
          span(v-if="item.custom && item.custom.amount") {{ item.custom.amount | currency }}
        td
          span(v-if="item.amount") {{ item.amount | currency }}
        td {{ getOrganization(item) }}
        td
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn(icon, small, v-on="on")
                v-icon(color="secondary") mdi-dots-horizontal
            v-list.py-0(dense)
              v-list-item(@click.stop="endService(item)", v-if="!item.ended") End Service
              v-list-item(@click.stop="startService(item)", v-else) Start Service
              v-list-item(@click.stop="toggleWaive(item)") Toggle Waive
              CreateInvoice(:bizservice="item")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import CreateInvoice from "./CreateInvoice";

export default {
  components: { CreateInvoice },
  props: ["items"],
  computed: {
    ...mapGetters(["bizservices", "salesOrganizations", "salesPersons"]),
  },
  methods: {
    ...mapActions(["updateBizService"]),
    edit(bizId) {
      EventBus.$emit("show-edit-service", bizId);
    },
    getOrganization(service) {
      const val = service?.sales_force?.resell_partner;
      const found = this.salesOrganizations?.find((o) => o._id == val);
      return found?.name || "";
    },
    async endService(item) {
      const params = { id: item._id, ended: Date.now() };
      const result = await this.$api.service.account.custom(
        "endService",
        params
      );
      this.updateBizService(result);
    },
    async startService(item) {
      const params = { id: item._id, ended: 0 };
      const result = await this.$api.service.account.custom(
        "endService",
        params
      );
      this.updateBizService(result);
    },
    async toggleWaive(item) {
      const waive = !(item.waive == true);
      const action = { $set: { waive } };
      const result = await this.$api.service.account.put(item._id, action);
      this.updateBizService(result);
    },
  },
};
</script>

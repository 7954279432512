const state = {
  deliveryPayouts: [],
};

const getters = {
  deliveryPayouts: state => state.deliveryPayouts,
};

const actions = {
  setDeliveryPayouts: ({ commit }, data) => { commit("setDeliveryPayouts", data); },
  updateDeliveryPayout: ({ commit }, data) => { commit("updateDeliveryPayout", data); }
};

const mutations = {
  setDeliveryPayouts(state, data) {
    state.deliveryPayouts = data;
  },
  updateDeliveryPayout(state, data) {
    state.deliveryPayouts = state.deliveryPayouts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  }
};

export default { state, getters, actions, mutations };
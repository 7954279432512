<template lang="pug">
div(v-if="poi")
  v-dialog(v-model="dialog", width="828")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Edit Parallax
      v-card-text
        v-slider(
          v-model="height",
          min="200",
          max="600",
          step="5",
          label="Height",
          thumb-label="always",
          color="secondary",
          track-color="success"
        )
        .text-center
          croppa(
            v-model="myCroppa",
            :width="800",
            :height="height",
            :quality="2",
            :canvas-color="canvas_color",
            initial-size="contain"
          )
        v-text-field(
          v-model="canvas_color",
          label="Background Color",
          hint="Name, hex code, or valid color format"
        )
      v-card-actions
        v-btn(
          @click="generateBlob",
          block,
          color="secondary",
          :loading="loading"
        ) Save Parallax
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      poi: null,
      height: 400,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      myCroppa: {},
      loading: false,
      dialog: false,
      canvas_color: "#FFFFFF",
    };
  },
  mounted() {
    EventBus.$on("edit-poi-parallax", this.open);
  },
  destroyed() {
    EventBus.$off("edit-poi-parallax", this.open);
  },
  methods: {
    ...mapActions(["updatePOI"]),
    open(data) {
      this.poi = data;
      this.dialog = true;
    },
    generateBlob() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
            return;
          }
          this.uploadLogo(blob);
        },
        "image/jpeg",
        0.8
      );
    },
    async uploadLogo(blob) {
      if (!this.poi || !this.poi._id) return;
      this.loading = true;
      const poiId = this.poi._id;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("poiId", poiId);
      try {
        const url = "/geoService/poi/updateParallax";
        const { data } = await this.axios.post(url, formData);
        this.updatePOI(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
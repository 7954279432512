<template lang="pug">
div
  .subtitle-2.my-2 Selflane Deliveries
  Charts(:result="result")
  .subtitle-2.my-2 Non-Selflane Deliveries
  Charts(:result="resultNonSelflane")
</template>
<script>
import _ from "underscore";
import Charts from "./Charts";

export default {
  components: { Charts },
  props: ["year"],
  data() {
    return {
      result: [],
      resultNonSelflane: [],
    };
  },
  watch: {
    year() {
      this.load();
      this.loadNonSelflane();
    },
  },
  methods: {
    async load() {
      if (!this.year) return;
      const params = { other: false, year: this.year };
      const { data } = await this.axios.post("/delivOrders/weekly", params);
      if (!data) return;
      this.result = _.chain(data)
        .sortBy((o) => o.week)
        .sortBy((o) => o.year)
        .value();
    },
    async loadNonSelflane() {
      if (!this.year) return;
      const params = { other: true, year: this.year };
      const { data } = await this.axios.post("/delivOrders/weekly", params);
      if (!data) return;
      this.resultNonSelflane = _.chain(data)
        .sortBy((o) => o.week)
        .sortBy((o) => o.year)
        .value();
    },
  },
};
</script>

const state = {
  delivOrders: []
}

const getters = {
  delivOrders: state => state.delivOrders
}

const actions = {
  setDelivOrders: ({ commit }, data) => { commit('setDelivOrders', data) },
  updateDelivOrder: ({ commit }, data) => { commit('updateDelivOrder', data) },
  addDelivOrder: ({ commit }, data) => { commit("addDelivOrder", data); },
}

const mutations = {
  setDelivOrders(state, data) {
    state.delivOrders = data
  },
  updateDelivOrder(state, data) {
    state.delivOrders = state.delivOrders.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addDelivOrder(state, data) { state.delivOrders.push(data); },
}

export default { state, getters, actions, mutations }
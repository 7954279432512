<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="record")
    v-toolbar(flat, dense)
      .subtitle-2 Edit record
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(wrap)
          v-col(cols="6")
            v-text-field(label="Type", v-model.trim="record.type")
          v-col(cols="6")
            v-text-field(label="Reason", v-model.trim="record.reason")
          v-col(cols="6")
            PriceField(label="Amount", v-model.number="record.amount")
          v-col(cols="6")
            v-select(
              label="Currency",
              v-model="record.currency",
              :items="currency_items"
            )
        span {{ record.driverName }} {{ record.zone }}
        v-select(label="Zone", v-model="record.zone", :items="default_zones")
        DriverSelector(@select="setDriver")
        v-text-field(label="Order", v-model.trim="record.order")
        v-text-field(label="Deliv Order", v-model.trim="record.delivOrder")
        v-btn.mt-2(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!valid"
        ) Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import DriverSelector from "@/components/basic/DriverSelector";

export default {
  components: { DriverSelector },
  data() {
    return {
      record: null,
      dialog: false,
      errors: [],
      loading: false,
      default_zones: [],
      currency_items: ["usd", "cad"],
    };
  },
  computed: {
    valid() {
      return this.record && this.record.amount != 0 && this.record.driver;
    },
  },
  mounted() {
    EventBus.$on("edit-driver-rewardpenalty", this.open);
  },
  destroyed() {
    EventBus.$off("edit-driver-rewardpenalty", this.open);
  },
  methods: {
    ...mapActions(["updateRewardPenalty", "addRewardPenalty"]),
    open(val) {
      if (!val) {
        this.record = {
          driver: null,
          driverName: "",
          type: "Reward",
          reason: "",
          zone: "",
          delivOrder: null,
          order: null,
          amount: 0,
          currency: "usd",
        };
      } else {
        this.record = JSON.parse(JSON.stringify(val));
      }
      this.errors = [];
      this.dialog = true;
    },
    setDriver(driver) {
      this.record.driver = driver._id;
      this.record.driverName = driver.name;
      this.record.zone = driver.zones[0];
      this.default_zones = driver.zones;
    },
    async submit() {
      this.errors = [];
      this.loading = true;
      if (!this.record._id) {
        try {
          const { data } = await this.axios.post(
            "/driver/rewardPenalties/create",
            this.record
          );
          this.addRewardPenalty(data);
          this.dialog = false;
        } catch (err) {
          //
        }
      } else {
        const params = {
          criteria: { _id: this.record._id },
          action: { $set: this.record },
        };
        const res = await this.axios.post(
          "/driver/rewardPenalties/update",
          params
        );
        this.updateRewardPenalty(res.data);
        this.dialog = false;
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="category")
    v-card-title {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(v-model="category.name", label="Name")
      v-card-actions
        v-btn(:loading="loading", block, color="secondary", type="submit") Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      category: null,
      dialog: false,
      loading: false,
    };
  },
  computed: {
    title() {
      return this.category?._id ? "Edit Category" : "New Category";
    },
  },
  methods: {
    ...mapActions(["updateTimelineCategory", "addTimelineCategory"]),
    open(category) {
      this.category = JSON.parse(JSON.stringify(category));
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      try {
        if (this.category._id) {
          const params = {
            criteria: { _id: this.category._id },
            action: { $set: { name: this.category.name } },
          };
          const result = await this.$api.timeline.category.update(params);
          this.updateTimelineCategory(result);
        } else {
          const result = await this.$api.timeline.category.create(
            this.category
          );
          this.addTimelineCategory(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Organization
        th.text-right Count
    tbody
      tr(v-for="(item, index) in items", :key="index")
        th {{ item.name }}
        td.text-right {{ item.count }}
    tfoot
      tr
        th Total
        th.text-right {{ total }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["bizservices", "salesOrganizations"]),
    items() {
      // bizservices is an array of objects
      // each object has a sales_force property
      // sales_force is an object with a resell_partner property
      // count the number of resell_partner values for each sales_organization
      // sort by count descending
      let acc = {};
      for (const item of this.bizservices) {
        const salesOrg = item.sales_force?.resell_partner;
        if (!salesOrg) continue;
        if (!acc[salesOrg]) acc[salesOrg] = 0;
        acc[salesOrg]++;
      }
      return Object.keys(acc)
        .map((key) => {
          const found = this.salesOrganizations.find((o) => o._id == key);
          const name = found?.name || key;
          const count = acc[key];
          return { name, count };
        })
        .sort((a, b) => b.count - a.count);
    },
    total() {
      return this.items.reduce((acc, o) => acc + o.count, 0);
    },
  },
};
</script>
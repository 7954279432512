<template lang="pug">
v-btn(@click="submit", small, :loading="loading") Create by Month
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["date"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      const month = parseInt(moment(this.date).format("YYYYMM"));
      const result = await this.$api.transaction.stripe.custom(
        "createByMonth",
        { month }
      );
      this.$toast.success(result);
      this.$emit("created");
      this.loading = false;
    },
  },
};
</script>
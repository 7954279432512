<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="item")
    v-card-title Transaction Details
    simple-table
      tbody
        tr
          th Transaction ID
          td {{ transNum }}
        tr
          th Amount
          td {{ approveAmount | currency }}
        tr
          th Tip
          td {{ tipAmount | currency }}
        tr
          th Name
          td {{ name }}
        tr
          th Last4
          td {{ last4 }}
        tr
          th Auth Code
          td {{ authCode }}
        tr
          th Type
          td {{ item.type | paxType }}
        tr
          th Order/Check
          td {{ refNum }}
        tr
          th IP
          td {{ item.ip }}
        tr
          th Batch
          td {{ batchNum }}
        tr
          th Processed Time
          td {{ timestamp | datetime }} (Local)
        tr
          th Created At
          td {{ item.createdAt | datetime }}
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      item: null,
    };
  },
  computed: {
    // computed properties to allow read from both raw data or the result from DailySummary (mapped data)
    transNum() {
      return this.item?.transNum || this.item?.trace?.transNum;
    },
    refNum() {
      return this.item?.refNum || this.item?.trace?.refNum;
    },
    batchNum() {
      return this.item?.batchNum || this.item?.trace?.batchNum;
    },
    timestamp() {
      return this.item?.timestamp || this.item?.trace?.timestamp;
    },
    approveAmount() {
      return this.item?.approveAmount || this.item?.amount?.approveAmount;
    },
    tipAmount() {
      return this.item?.tipAmount || this.item?.amount?.tipAmount;
    },
    name() {
      return this.item?.name || this.item?.account?.cardHolder;
    },
    last4() {
      return this.item?.account?.account || this.item?.account;
    },
    authCode() {
      return this.item?.authCode || this.item?.host?.authCode;
    },
  },
  methods: {
    open(data) {
      this.item = data;
      this.dialog = true;
    },
  },
};
</script>
<template lang="pug">
v-card(flat, v-if="items.length > 0")
  v-card-title {{ title }}
  v-data-table(
    :items="items",
    :headers="headers",
    :search="search",
    sort-by="name",
    dense,
    mobile-breakpoint=0
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.address="{ item }")
      span {{ item.address | address("city, state") }}
    template(v-slot:item.action="{ item }")
      StatementDescriptor(:biz="item")
</template>

<script>
import StatementDescriptor from "@/components/Business/Stripe/StatementDescriptor";

export default {
  components: { StatementDescriptor },
  props: ["search", "items", "title"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Stripe Account", value: "payments.managed_account" },
        {
          text: "Descriptor",
          value: "payments.statement_descriptor",
        },
        { text: "", value: "action", align: "end" },
      ],
      selectedBizId: null,
    };
  },
};
</script>

<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="load()", :loading="loading") Terminals Details
  simple-table
    thead
      tr
        th HSN & Type
        th Connected
        th Connect
    tbody
      tr(v-for="(item, index) in items", :key="index")
        td
          div {{ item.hsn }}
          div {{ item.deviceType }}
        td {{ item.connected }}
        td
          Connect(:mid="mid", :hsn="item.hsn")
          DateTime(:mid="mid", :hsn="item.hsn")
          Ping(:mid="mid", :hsn="item.hsn")
          Display(:mid="mid", :hsn="item.hsn")
          ClearDisplay(:mid="mid", :hsn="item.hsn")
          ReadSignature(:mid="mid", :hsn="item.hsn")
          AuthCard(:mid="mid", :hsn="item.hsn")
          AuthManual(:mid="mid", :hsn="item.hsn")
          Tip(:mid="mid", :hsn="item.hsn")
          Capture(:mid="mid", :hsn="item.hsn")
</template>

<script>
import AuthCard from "./AuthCard";
import AuthManual from "./AuthManual";
import Capture from "./Capture";
import ClearDisplay from "./ClearDisplay";
import Connect from "./Connect";
import DateTime from "./DateTime";
import Display from "./Display";
import Ping from "./Ping";
import ReadSignature from "./ReadSignature";
import Tip from "./Tip";

export default {
  components: {
    Connect,
    DateTime,
    Ping,
    Display,
    ClearDisplay,
    ReadSignature,
    AuthCard,
    AuthManual,
    Tip,
    Capture,
  },
  props: {
    mid: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  methods: {
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = { mid: this.mid };
      try {
        this.items = await this.$api.cardpointe.bolt.terminalDetails(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
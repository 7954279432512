<template lang="pug">
div
  v-btn(@click="open()", rounded, outlined, color="secondary") Message All POS
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-text
        .sl-title Message All POS
        v-form(@submit.prevent="send()")
          v-text-field(
            v-model="criteria",
            label="Criteria(JSON, e.g. { 'appVersion': '1.0.0' })"
          )
          v-text-field(v-model="message", label="Message")
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="!message",
            :loading="loading"
          ) Send
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      criteria: "",
      message: "",
      loading: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.message = "";
    },
    reset() {
      this.dialog = false;
      this.message = "";
    },
    async send() {
      const message = this.message.trim();
      if (!message) return this.$toast.error("Enter message");

      const criteria = JSON.parse(this.criteria);
      const params = { criteria, message };
      this.loading = true;
      try {
        const { data } = await this.axios.post(
          "/bizNotif/messageAllPOS",
          params
        );
        this.$toast.success(data);
        this.reset();
      } catch (err) {
        this.$toast.error(err?.response?.data || "Failed to send message");
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card
    v-card-title {{ title }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(v-model="email", label="Email", :disabled="!isNew")
        PhoneField(v-model="phone")
        v-text-field(v-model="reason_code", label="Reason")
        v-select(
          v-model="stage",
          label="Stage",
          :items="['rejected', 'pending']"
        )
        v-btn(
          type="submit",
          block,
          :disabled="!valid",
          color="secondary",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      id: null,
      email: "",
      phone: "",
      reason_code: "Fraudulent",
      stage: "rejected",
      loading: false,
    };
  },
  computed: {
    title() {
      return this.id ? "Edit Blacklist User" : "Add Blacklist User";
    },
    valid() {
      return this.email || this.phone;
    },
    isNew() {
      return !this.id;
    },
  },
  methods: {
    ...mapActions(["addBlacklistUser", "updateBlacklistUser"]),
    open(data) {
      this.id = data?._id;
      this.email = data?.email || "";
      this.phone = data?.phone || "";
      this.reason_code = "Fraudulent";
      this.stage = data?.stage || "rejected";
      this.dialog = true;
    },
    async submit() {
      if (!this.valid) return;
      this.loading = true;
      if (this.id) {
        await this.update();
      } else {
        await this.save();
      }
      this.loading = false;
    },
    async save() {
      if (!this.valid) return;
      this.email = this.email?.trim();
      const params = {
        email: this.email,
        phone: this.phone,
        reason_code: this.reason_code,
      };
      try {
        const result = await this.$api.blacklist.create(params);
        this.addBlacklistUser(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
    },
    async update() {
      const params = {
        criteria: { _id: this.id },
        action: {
          $set: {
            phone: this.phone,
            reason_code: this.reason_code,
            stage: this.stage,
          },
        },
      };
      try {
        const result = await this.$api.blacklist.update(params);
        this.updateBlacklistUser(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Last 4-Week Delivery: {{ total | currency }}
    Download.ml-2(:items="items")
    v-spacer
    v-chip(small, outlined) {{ totalCount }}
    v-btn.ml-2(
      icon,
      @click="load",
      small,
      color="secondary",
      :loading="loading"
    )
      v-icon(small) mdi-refresh
  v-divider
  v-data-table(
    :items="items",
    :headers="headers",
    sort-by="total",
    sort-desc,
    dense,
    mobile-breakpoint=0
  )
    template(v-slot:item.total="{ item }")
      span {{ item.total | currency }}
    template(v-slot:item.total_diff="{ item }")
      span {{ item.total_diff | currency }}
</template>

<script>
import Download from "./Download";

export default {
  components: { Download },
  data() {
    return {
      items: [],
      loading: false,
      headers: [
        { text: "Biz", value: "bizName", sortable: false },
        { text: "Revenue", value: "total" },
        { text: "Rev Change", value: "total_diff" },
        { text: "Count", value: "count" },
        { text: "Ct Change", value: "count_diff" },
      ],
    };
  },
  computed: {
    total() {
      return this.items.reduce((a, b) => {
        return a + b.total;
      }, 0);
    },
    totalCount() {
      return this.items.reduce((a, b) => {
        return a + b.count;
      }, 0);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const { data } = await this.axios.get("/orders/last4weekdeliv");
        this.items = data;
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
Page(title="Reservation")
  Load
  List
</template>

<script>
import Load from "./Load";
import List from "./List";

export default {
  components: { Load, List },
};
</script>

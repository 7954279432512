<template lang="pug">
v-btn(
  icon,
  small,
  @click.stop="remove(item)",
  :loading="loading",
  color="error"
)
  v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async remove(item) {
      if (!this.biz || !item._id) return;
      this.loading = true;
      try {
        const action = { $pull: { "schedule.specialdays": { _id: item._id } } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$emit("updated", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to delete special day");
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Country
        th Basic Rate
        th Delivery Rate
        th Delivery Range
        th Delivery
        th Driver
        th Manager
    tbody
      tr(
        v-for="(item, index) in geoServiceCountries",
        :key="index",
        @click="edit(item)",
        role="button"
      )
        td {{ item.country }}
        td {{ item.basicRate }}%
        td {{ item.delivery.rate }}% >{{ item.delivery.minService | currency }}
        td ~{{ item.delivery.inclusiveRange }} &lt;{{ item.delivery.maxRange }}
        td {{ item.delivery.fee | currency }} + {{ item.delivery.unitFee | currency }}/unit
        td {{ item.driverBasePay | currency }}
        td {{ item.managerBasePay | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["geoServiceCountries"]),
  },
  methods: {
    edit(data) {
      EventBus.$emit("edit-geo-service-country", data);
    },
    editHour(item) {
      EventBus.$emit("edit-geo-service-country-hours", item);
    },
  },
};
</script>
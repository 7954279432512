<template lang="pug">
v-data-table(:items="items", :headers="headers", mobile-breakpoint=0)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
  template(v-slot:item.stripe_fee="{ item }")
    span {{ item.stripe_fee | currency }}
  template(v-slot:item.application_fee_amount="{ item }")
    span {{ item.application_fee_amount | currency }}
  template(v-slot:item.net="{ item }")
    span {{ item.net | currency }}
  template(v-slot:item.selflane_net="{ item }")
    span {{ item.selflane_net | currency }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "MOnth", value: "month" },
        { text: "Category", value: "category" },
        { text: "Amount", value: "amount" },
        { text: "Stripe Fee", value: "stripe_fee" },
        { text: "App Fee", value: "application_fee_amount" },
        { text: "Net", value: "net" },
        { text: "Selflane Net", value: "selflane_net" },
        { text: "Status", value: "status" },
      ],
    };
  },
};
</script>
<template lang="pug">
v-card
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }} Point of Interest
  v-card-text
    v-form(@submit.prevent="submit")
      v-text-field(label="URL", v-model.trim="poi.url")
      v-text-field(
        label="Name",
        v-model.trim="poi.name",
        autofocus,
        ref="focus"
      )
      v-text-field(label="Detail", v-model.trim="poi.detail", textarea)
      GoogleAddressField(v-model="poi.address")
      simple-table
        tbody
          tr(
            v-for="(item, index) in poi.bizs",
            :key="index",
            draggable="true",
            @dragstart="drag(index, $event)",
            @dragend="dragend($event)",
            @drop="drop(index, $event)",
            @dragover.prevent=""
          )
            td
              v-icon(small) mdi-menu
            td {{ bizName(item) }}
            td.text-right
              v-btn(icon, small, @click="removeBiz(index)")
                v-icon(small, color="error") mdi-delete
      BizSelector(@select="selectBiz")
      v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
      .pl-2.red--text(v-for="(error, index) in errors", :key="index + 'index'") {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  name: "POIForm",
  data() {
    return {
      poi: {
        name: "",
        url: "",
        detail: "",
        address: {
          city: "",
          country: "US",
          line1: "",
          line2: "",
          postal_code: "",
          state: "",
          geometry: {
            lat: undefined,
            lng: undefined,
          },
          timezone: "America/Denver",
        },
        bizs: [],
      },
      dialog: false,
      errors: [],
      loading: false,
      defaultBizs: [],
    };
  },
  computed: {
    title() {
      if (this.poi?._id) return "Edit";
      else return "Create";
    },
  },
  mounted() {
    this.initGoogle();
    EventBus.$on("edit-poi", this.open);
  },
  destroyed() {
    EventBus.$off("edit-poi", this.open);
  },
  methods: {
    ...mapActions(["updatePOI", "addPOI", "removePOI"]),
    open(val) {
      if (!val) {
        this.poi = {
          name: "",
          url: "",
          detail: "",
          address: { country: "US" },
          bizs: [],
        };
      } else {
        this.poi = JSON.parse(JSON.stringify(val));
      }
      this.errors = [];
      this.loadDefault();
      this.$forceUpdate();
    },
    async loadDefault() {
      if (!this.poi || !this.poi.bizs) return;
      const params = {
        criteria: { _id: { $in: this.poi.bizs } },
        select: "name",
      };
      this.defaultBizs = await this.$api.biz.list(params);
    },
    bizName(id) {
      return this.defaultBizs?.find((o) => o._id == id)?.name || id;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend(e) {
      e.target.style.opacity = 1;
    },
    drop(index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.poi.bizs.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.poi.bizs.length)
        return;
      let poi_bizs = JSON.parse(JSON.stringify(this.poi.bizs));
      let tmp = JSON.parse(JSON.stringify(poi_bizs[this.draggingIndex]));
      poi_bizs.splice(this.draggingIndex, 1);
      poi_bizs.splice(index, 0, tmp);
      this.poi.bizs = poi_bizs;
    },
    removeBiz(index) {
      this.poi.bizs.splice(index, 1);
    },
    selectBiz(biz) {
      if (!this.poi || !biz) return;
      if (!this.poi.bizs) this.poi.bizs = [biz._id];
      if (this.poi.bizs.indexOf(biz._id) < 0) this.poi.bizs.push(biz._id);
      this.defaultBizs.push(biz);
    },
    async submit() {
      this.errors = [];
      if (!this.poi._id) {
        const result = await this.$api.poi.create(this.poi);
        this.addPOI(result);
        this.loading = false;
        this.dialog = false;
      } else {
        const params = {
          criteria: { _id: this.poi._id },
          action: { $set: this.poi },
        };
        const result = await this.$api.poi.update(params);
        this.updatePOI(result);
        this.loading = false;
        this.dialog = false;
      }
      this.$emit("done");
    },
    handleDelete() {
      if (!this.poi._id) return;
      confirm("Do you want to delete this poi? This can not be reverted!") &&
        this.deletePOI();
    },
    async deletePOI() {
      if (!this.poi?._id) return;
      this.loading = true;
      try {
        const result = await this.$api.poi.delete(this.poi._id);
        this.removePOI(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
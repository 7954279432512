<template lang="pug">
div
  CountTableChart.mb-5(
    name="Model",
    :items="counts.model",
    v-if="counts.model",
    @click="loadModel"
  )
  CountTableChart.mb-5(
    name="App Version",
    :items="counts.appVersion",
    v-if="counts.appVersion",
    :sort-desc="true",
    @click="loadAppVersion"
  )
  CountTableChart.mb-5(
    name="System Version",
    :items="counts.systemVersion",
    v-if="counts.systemVersion",
    :sort-desc="true",
    @click="loadSystemVersion"
  )
  CountTableChart.mb-5(
    name="Major.Minor System Version",
    :items="majorMinorSystemVersion",
    v-if="majorMinorSystemVersion",
    :sort-desc="true"
  )
  CountTableChart.mb-5(
    name="Major System Version",
    :items="majorSystemVersion",
    v-if="majorSystemVersion",
    :sort-desc="true"
  )
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";

export default {
  data() {
    return { counts: {} };
  },
  computed: {
    majorMinorSystemVersion() {
      if (!this.counts?.systemVersion) return [];
      return _.chain(this.counts.systemVersion)
        .map((item) => {
          const [major, minor] = item.name.split(".");
          return { name: `${major}.${minor || ""}`, count: item.count };
        })
        .groupBy("name")
        .map((value, key) => {
          return {
            name: key,
            count: _.reduce(value, (memo, num) => memo + num.count, 0),
          };
        })
        .sortBy((o) => o.name)
        .reverse()
        .value();
    },
    majorSystemVersion() {
      if (!this.counts?.systemVersion) return [];
      return _.chain(this.counts.systemVersion)
        .map((item) => {
          const [major] = item.name.split(".");
          return { name: `${major}`, count: item.count };
        })
        .groupBy("name")
        .map((value, key) => {
          return {
            name: key,
            count: _.reduce(value, (memo, num) => memo + num.count, 0),
          };
        })
        .sortBy((o) => -o.count)
        .value();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.counts = await this.$api.posDevice.custom("stats/count", {});
    },
    loadModel(item) {
      const params = { criteria: { model: item.name } };
      EventBus.$emit("show-selected-devices", params);
    },
    loadAppVersion(item) {
      const params = { criteria: { appVersion: item.name } };
      EventBus.$emit("show-selected-devices", params);
    },
    loadSystemVersion(item) {
      const params = { criteria: { systemVersion: item.name } };
      EventBus.$emit("show-selected-devices", params);
    },
  },
};
</script>
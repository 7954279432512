<template lang="pug">
v-btn(@click="callAutoCapture", :loading="loading") Call Auto Capture
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async callAutoCapture() {
      this.loading = true;
      const result = await this.$api.stripe.paymentIntent.custom(
        "callAutoCapture"
      );
      this.$toast.success(result);
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
.s-vstack(v-if="biz")
  .my-container
    span Today's Hours
    DayHours(:schedule="biz.schedule")
  v-row(wrap)
    v-col(cols="12", md="6")
      RegularHours(:biz="biz", @updated="updated")
    v-col(cols="12", md="6")
      .s-vstack
        SpecialHours(:biz="biz", @updated="updated")
        Pause(:biz="biz", @updated="updated")
        TemporaryClose(:biz="biz", @updated="updated")
</template>

<script>
import Pause from "./Pause";
import RegularHours from "./RegularHours/index";
import SpecialHours from "./SpecialHours/index";
import TemporaryClose from "./TemporaryClose";

export default {
  components: { RegularHours, SpecialHours, Pause, TemporaryClose },
  props: {
    biz: { type: Object, required: true },
  },
  methods: {
    updated($event) {
      this.$emit("updated", $event);
    },
  },
};
</script>

<style lang="sass" scoped>
.my-container
  display: flex
  flex-direction: row
  align-items: center
  gap: 12px
  font-weight: 500
  font-size: 15px
</style>

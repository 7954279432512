<template lang="pug">
Page(title="CRM", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/salesforce/crm/dashboard" },
        { title: "Activities", url: "/salesforce/crm/activities" },
        { title: "Contacts", url: "/salesforce/crm/contacts" },
        { title: "Service Logs", url: "/salesforce/crm/service-logs" },
        { title: "Histories", url: "/salesforce/crm/histories" },
      ],
    };
  },
};
</script>
<template lang="pug">
.my-box
  .d-flex.flex-row.align-center.mb-3
    YearSelector(@select="select")
    v-btn-toggle.ml-3(v-model="interval", color="secondary", dense)
      v-btn(v-for="(item, index) in items", :key="index", :value="item", small) {{ item }}
  StandardChart(:input="data", :showDownload="true", chartType="bar")
</template>

<script>
import _ from "underscore";
import moment from "moment-timezone";

export default {
  props: {
    api: { type: String, required: true }, // e.g., /orders/messaging/stats/growth
    title: { type: String, required: true }, // e.g., Messaging Growth
  },
  data() {
    return {
      year: null,
      result: [],
      interval: "month",
      items: ["month", "week"],
    };
  },
  computed: {
    labels() {
      if (!this.year) return [];
      let end = moment().year(this.year).endOf("year");
      if (end.isAfter(moment())) end = moment();
      if (this.interval == "month")
        return _.range(1, end.month() + 2).map(this.monthName);
      if (this.interval == "week") return _.range(1, end.week() + 1);
      return [];
    },
    data() {
      let values = [];
      if (this.interval == "month") {
        values = this.labels.map((_name, index) => {
          const found = this.result?.find((item) => item.month == index + 1);
          return found?.count || 0;
        });
      } else {
        values = this.labels.map((_name, index) => {
          const found = this.result?.find((item) => item.week == index + 1);
          return found?.count || 0;
        });
      }
      return {
        title:
          this.year + " " + this.title + " / " + this.interval.toUpperCase(),
        labels: this.labels,
        values,
      };
    },
  },
  watch: {
    interval() {
      this.load();
    },
  },
  methods: {
    select(year) {
      this.year = year;
      this.load();
      this.$emit("select", year);
    },
    async load() {
      if (!this.year || !this.interval) return;
      const params = { year: this.year, interval: this.interval };
      try {
        const { data } = await this.axios.post(this.api, params);
        this.result = data;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    // convert number to month
    monthName(month) {
      const d = new Date();
      d.setMonth(month - 1);
      return d.toLocaleString("default", { month: "short" });
    },
  },
};
</script>

<style lang="sass" scoped>
.my-box
  display: flex
  flex-direction: column
  align-items: center
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import cart from './modules/cart'
import delivOrders from './modules/delivOrders'
import expSource from './modules/expSource'
import giftcards from './modules/giftcards'
import orders from './modules/orders'
import reservations from './modules/reservations'
import reviews from './modules/reviews'
import taxfiles from './modules/tax/taxfiles'

import userAccounts from './modules/User/accounts'
import blacklistUsers from './modules/User/blacklist'
import bouncingemail from './modules/User/bouncingemail'

import bizorders from './modules/bizorders'

import delivs from './modules/Delivery/deliv'
import deliveryPayouts from './modules/Delivery/deliveryPayouts'

import driverDocuments from './modules/Driver/documents'
import driverPayouts from './modules/Driver/driverPayouts'
import drivers from './modules/Driver/drivers'
import driverRewardpenalties from './modules/Driver/rewardpenalties'

import influencer from './modules/Influencer/index'

import geoServiceCountries from './modules/GeoService/countries'
import pois from './modules/GeoService/pois'
import geoServiceZones from './modules/GeoService/zones'

import stripeAccounts from './modules/Stripe/accounts'
import stripeBanks from './modules/Stripe/banks'
import stripePersons from './modules/Stripe/persons'

import merchantIncomes from './modules/Merchant/merchantIncomes'
import merchants from './modules/Merchant/merchants'

import partnerPayouts from './modules/Partner/partnerPayouts'
import partners from './modules/Partner/partners'

import access from './modules/Business/access'
import bizAccounts from './modules/Business/accounts'
import posDevices from './modules/Business/devices'
import bizDocuments from './modules/Business/documents'
import bizTransfers from './modules/Business/transfers'
import cities from './modules/cities'
import promotions from './modules/promotions'
import punchcards from './modules/punchcards'

import missedTransactions from './modules/Transaction/MissedTransactions'

import corp from './modules/Corp/index'
import members from './modules/Member/accounts'
import memberPrograms from './modules/Member/programs'

/// tool
import announcement from './modules/Tool/announcement'
import email_sample from './modules/Tool/email_sample'

/// libs
import bizservices from '/libs/store/bizservices'
import catalog from '/libs/store/catalog'
import press_reports from '/libs/store/company/press_reports'
import crm from '/libs/store/crm/index'
import dev from '/libs/store/dev'
import entp from '/libs/store/entp'
import invoices from '/libs/store/invoices'
import conversation from '/libs/store/order/conversation'
import sales_force from '/libs/store/sales_force/index'
import timeline from '/libs/store/timeline'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    orders,
    reservations,
    giftcards,
    expSource,
    cart,
    reviews,
    delivOrders,
    taxfiles,
    bizorders,
    userAccounts,
    blacklistUsers,
    bouncingemail,
    members,
    delivs,
    deliveryPayouts,
    drivers,
    driverPayouts,
    driverRewardpenalties,
    driverDocuments,
    geoServiceCountries,
    geoServiceZones,
    pois,
    stripeAccounts,
    stripeBanks,
    stripePersons,
    merchants,
    merchantIncomes,
    partners,
    partnerPayouts,
    influencer,
    access,
    posDevices,
    bizAccounts, // business bizs
    bizTransfers,
    bizDocuments,
    promotions,
    punchcards,
    missedTransactions,
    cities,
    memberPrograms,
    corp,
    email_sample,
    announcement,
    // libs
    press_reports,
    sales_force,
    bizservices,
    invoices,
    timeline,
    conversation,
    // namespaced modules
    crm, dev, entp, catalog
  }
})
export default store
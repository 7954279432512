<template lang="pug">
div
  .d-flex.flex-row.align-center
    .sl-title Countries
    v-btn(small, outlined, @click="add()") Add
      v-icon(small, right) mdi-plus
  Table
  Form
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Table from "./Table";
import Form from "./Form";
import { mapActions } from "vuex";

export default {
  components: { Table, Form },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setGeoServiceCountries"]),
    add() {
      EventBus.$emit("edit-geo-service-country");
    },
    async load() {
      const { data } = await this.axios.post("/geoService/countries/list");
      this.setGeoServiceCountries(data);
    },
  },
};
</script>
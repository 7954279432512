<template lang="pug">
v-card(outlined)
  simple-table
    thead
      tr
        th Zone
        th.text-right Count
    tbody
      tr(v-for="(item, zone) in countByZone", :key="zone")
        td {{ zone }}
        td.text-right {{ item.length }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["delivOrders"]),
    countByZone() {
      return _.chain(this.delivOrders)
        .filter((o) => o.type == 0)
        .groupBy("zone")
        .value();
    },
  },
};
</script>

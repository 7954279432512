import Accounts from './Accounts/index.vue'
import AccountsByPartner from './Accounts/ByPartner.vue'
import AccountsStats from './Accounts/Stats/index.vue'

import Payouts from './Payouts/index.vue'
import RewardPenalties from './RewardPenalties/index.vue'
import Documents from './Documents/index.vue'

export default [
  {
    path: '/driver/accounts', component: Accounts, meta: { auth: 'corp' },
    children: [
      { path: 'summary', component: AccountsByPartner },
      { path: 'stats', component: AccountsStats }
    ]
  },
  { path: '/driver/payouts', component: Payouts, meta: { auth: 'corp' } },
  { path: '/driver/rewardpenalties', component: RewardPenalties, meta: { auth: 'corp' } },
  { path: '/driver/documents', component: Documents, meta: { auth: 'corp' } }
]
<template lang="pug">
v-dialog(
  v-model="dialog",
  width="400",
  @keydown.esc="dialog = false",
  scrollable
)
  Form(@done="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Form from "./Form";

export default {
  name: "POIFormContainer",
  components: { Form },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    EventBus.$on("edit-poi", this.open);
  },
  destroyed() {
    EventBus.$off("edit-poi", this.open);
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>
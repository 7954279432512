<template lang="pug">
v-btn(@click="proceed", :loading="loading", small, outlined) Migrate Impression
</template>

<script>
export default {
  data() {
    return {
      count: 0,
      loading: false,
    };
  },
  methods: {
    async proceed() {
      this.loading = true;
      try {
        this.count += 1;
        await this.axios.post("/bizs/impression/migrate");
        if (this.count < 100) {
          this.proceed();
          return;
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
      this.count = 0;
    },
  },
};
</script>

<template lang="pug">
div
  v-text-field(
    v-model="search",
    solo,
    dense,
    label="Search",
    placeholder="Search",
    append-icon="search",
    color="secondary"
  )
  Table.mb-3(
    :drivers="good_active_items",
    :search="search",
    title="Good Standing Drivers"
  )
  Table.mb-3(
    :drivers="needs_attention",
    :search="search",
    title="Needs Attention"
  )
  Table.mb-3(
    :drivers="new_active_items",
    :search="search",
    title="New Drivers"
  )
  Table.mb-3(
    :drivers="rejected_active_items",
    :search="search",
    title="Rejected Drivers"
  )
  Table(:drivers="inactive_items", :search="search", title="Inactive Drivers")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Table from "./Table";
import { mapGetters } from "vuex";

export default {
  components: { Table },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapGetters(["drivers"]),
    active_items() {
      return this.drivers.filter((o) => o.status);
    },
    rejected_active_items() {
      return this.active_items.filter((o) => o.stage < 0);
    },
    needs_attention() {
      return this.active_items.filter((o) => o.stage == 0 && o.deliv);
    },
    new_active_items() {
      return this.active_items.filter((o) => o.stage == 0 && !o.deliv);
    },
    good_active_items() {
      return this.active_items.filter((o) => o.stage > 0);
    },
    inactive_items() {
      return this.drivers.filter((o) => !o.status);
    },
  },
  methods: {
    select(driver) {
      EventBus.$emit("show-driver-detail", driver);
    },
  },
};
</script>

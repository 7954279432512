
<template lang="pug">
div(v-if="item")
  div {{ item.amount | currency }} (amount) + {{ item.fee | currency }} (fee)
  .subtitle-2 Total: {{ item.total | currency }}
  v-btn(
    v-if="item.disputeId",
    color="secondary",
    small,
    text,
    :loading="loading",
    @click="show()"
  ) {{ item.disputeId }}
  v-btn(
    @click="load",
    color="secondary",
    small,
    text,
    v-else,
    :loading="loading"
  ) Load
  div {{ item.created | datetime }}
  .subtitle-2.text-capitalize {{ item.resolution }}
  div {{ item.resolution_type }}
  div {{ item.resolution_detail }}
  div {{ item.resolution_time | datetime }}
  Resolve(:item="item", @updated="$emit('updated')")
  UpdateStatus(:chargeback="item", @updated="$emit('updated')")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Resolve from "./Resolve";
import UpdateStatus from "./UpdateStatus";

export default {
  components: { Resolve, UpdateStatus },
  props: ["item", "order"],
  data() {
    return { loading: false };
  },
  computed: {
    charges() {
      let items = [];
      if (this.order?.payment?.charge?.id) {
        const charge = this.order.payment.charge;
        items.push({
          transId: charge.id,
          amount: charge.amount,
        });
      }
      this.order?.payment?.charges?.forEach((item) => {
        if (item.method == "online" || item.method == "stripe") {
          items.push(item);
        }
      });
      return items;
    },
  },
  methods: {
    async load() {
      if (!this.charges?.length) return;
      this.loading = true;
      for (const charge of this.charges) {
        let id = charge.transId;
        const isPI = id.startsWith("pi_");
        try {
          if (isPI) {
            const pi = await this.$api.stripe.paymentIntent.retrieve({
              stripeId: id,
            });
            id = pi.latest_charge;
          }
          const res = await this.$api.stripe.charges.retrieve({ id });
          await this.assignDispute(res);
        } catch (err) {
          console.log("failed to load charge");
          console.log(err);
          // fail silently
        }
      }
      this.loading = false;
    },
    async assignDispute(charge) {
      if (
        !charge.dispute ||
        charge.amount != Math.round(this.item.amount * 100)
      ) {
        this.$toast.error("no dispute or amount mismatch");
        return;
      }
      try {
        const dispute = await this.$api.stripe.disputes.retrieve({
          id: charge.dispute,
        });
        const action = {
          $set: {
            disputeId: dispute.id,
            dispute_status: dispute.status,
            dispute_reason: dispute.reason,
          },
        };
        await this.$api.chargeback.put(this.item._id, action);
        this.$emit("updated");
      } catch (e) {
        // fail silently
      }
    },
    show() {
      EventBus.$emit("dispute-detail", this.item);
    },
  },
};
</script>
<template lang="pug">
.my-5
  v-form(@submit.prevent="submit()")
    v-text-field(v-model="search", label="Search SubGroups")
  v-btn(
    text,
    v-for="(item, index) in subgroups",
    :key="index",
    @click="addItem(item)"
  ) {{ item.name }}
</template>

<script>
export default {
  data() {
    return {
      search: "",
      subgroups: [],
    };
  },
  methods: {
    async submit() {
      const search = this.search.trim();
      if (!search) return;
      if (search.length < 3) {
        return this.$toast.error("Please enter with at least 3 letters");
      }
      const params = {
        criteria: { name: { $regex: search, $options: "i" } },
        select: "name",
      };
      this.subgroups = await this.$api.catalog.subgroup.list(params);
    },
    addItem(item) {
      if (!item) return;
      this.$emit("add", item);
    },
  },
};
</script>
<template lang="pug">
div
  .d-flex.flex-row.mb-3
    YearSelector(@select="load")
    v-spacer
    IconBtn(@click="open()", title="Add", icon="mdi-plus")
  List(:loading="loading", @edit="open($event)")
  StatsGrowth.my-5(:title="title", :api="api")
  Form(ref="form")
</template>

<script>
import Form from "./Form";
import List from "./List";
import { mapActions } from "vuex";

export default {
  components: { Form, List },
  data() {
    return {
      api: "blacklist/user/stats/growth",
      title: "Blacklist Users",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setBlacklistUsers"]),
    async load(year) {
      const begin = new Date(year, 0, 1);
      const end = new Date(year + 1, 0, 1);
      this.loading = true;
      const params = {
        criteria: { created: { $gte: begin.getTime(), $lt: end.getTime() } },
      };
      const result = await this.$api.blacklist.list(params);
      this.setBlacklistUsers(result);
      this.loading = false;
    },
    open(data) {
      this.$refs.form.open(data);
    },
  },
};
</script>

<template lang="pug">
Page(title="Hourly Stats")
  v-btn.mr-3.my-3(
    v-for="(item, index) in geoServiceZones",
    :key="index",
    @click="loadStats(item)"
  ) {{ item.zoneName }}
  v-row
    v-col(
      cols="12",
      sm="6",
      md="4",
      v-for="(group, gindex) in groups",
      :key="gindex + 'group'"
    )
      Chart(:title="group.title", :items="group.items", :sum="group.sum")
    v-col(cols="12", sm="6", md="4")
      Week(:groups="groups")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import Chart from "./Chart";
import Week from "./Week";

export default {
  components: { Chart, Week },
  data() {
    return { result: [] };
  },
  computed: {
    ...mapGetters(["geoServiceZones"]),
    groups() {
      const result = _.chain(this.result)
        .groupBy("day")
        .map((o, k) => {
          const day = parseInt(k);
          let sum = o.reduce((a, b) => a + b.count, 0);
          sum = Math.round(sum / 4);
          const title = this.getWeekday(day);
          return { day, title, items: o, sum };
        })
        .sortBy("day")
        .value();
      return _.map([2, 3, 4, 5, 6, 7, 1], (o) => {
        const found = result.find((item) => item.day == o);
        if (found) return found;
        const title = this.getWeekday(o);
        return { day: o, title, items: [], sum: 0 };
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setGeoServiceZones"]),
    getWeekday(day) {
      switch (day) {
        case 1:
          return "Sun";
        case 2:
          return "Mon";
        case 3:
          return "Tue";
        case 4:
          return "Wed";
        case 5:
          return "Thu";
        case 6:
          return "Fri";
        case 7:
          return "Sat";
        default:
          return "Unknown";
      }
    },
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.geoZone.list(params);
      this.setGeoServiceZones(result);
    },
    async loadStats(zone) {
      if (!zone) return;
      const params = {
        zoneName: zone.zoneName,
        timezone: zone.timezone,
      };
      try {
        const url = "/delivOrders/stats/hourlyByZone";
        const { data } = await this.axios.post(url, params);
        this.result = data;
      } catch (err) {
        this.result = [];
      }
    },
  },
};
</script>

<template lang="pug">
.s-vstack
  .d-flex.flex-row
    v-btn.mb-5(@click="create", outlined, color="secondary", small) Create
    v-spacer
    MonthSelector(@select="load")
  List(@edit="edit")
  Form(ref="form")
</template>

<script>
import { mapActions } from "vuex";
import List from "./List/index";
import Form from "./Form";

export default {
  components: { List, Form },
  data() {
    return {
      range: {},
    };
  },
  methods: {
    ...mapActions(["setAnnouncements"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;
      const params = {
        criteria: { created: { $gte: this.range.begin, $lt: this.range.end } },
      };
      const result = await this.$api.announcement.list(params);
      this.setAnnouncements(result);
    },
    create() {
      this.$refs.form.open();
    },
    edit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>
<template lang="pug">
v-card.my-3
  v-card-text
    .d-flex.flex-row.align-center.justify-space-between
      .caption.sl-secondary-text Payout Account
    v-divider.my-2
    Details(:stripeAccount="account")
</template>

<script>
import Details from "./Details";

export default {
  components: { Details },
  props: ["account"],
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", x-small, depressed, @click="open") {{ account }}
  v-card(v-if="stripeAccount")
    v-toolbar(flat, dense)
      .subtitle-2 Stripe Managed Account
    simple-table
      tbody
        tr
          th ID
          td {{ stripeAccount.id }}
        tr
          th Name
          td {{ stripeAccount.business_profile.name }}
        tr
          th Statement Descriptor
          td {{ stripeAccount.settings.payments.statement_descriptor }}
        tr
          th MCC
          td {{ stripeAccount.business_profile.mcc }}
            v-btn(
              v-if="stripeAccount.business_profile.mcc != 5812",
              @click="updateMCC",
              x-small
            ) Set to 5812
        tr
          th Payout
          td
            div {{ stripeAccount.settings.payouts.schedule.interval }}
    v-card-actions
      v-progress-circular(v-if="updatingPayout", indeterminate)
      v-btn-toggle(v-else)
        v-btn(@click="updatePayout('manual')", x-small) Manual
        v-btn(@click="updatePayout('daily')", x-small) Daily
        v-btn(@click="updatePayout('weekly')", x-small) Weekly
</template>

<script>
export default {
  props: ["account"],
  data() {
    return {
      dialog: false,
      stripeAccount: null,
      updatingPayout: false,
    };
  },
  methods: {
    async open() {
      this.dialog = true;
      const params = { accountId: this.account };
      this.stripeAccount = await this.$api.stripe.account.retrieve(params);
    },
    async updateMCC() {
      const params = {
        accountId: this.account,
        stripeData: { business_profile: { mcc: 5812 } },
      };
      try {
        this.stripeAccount = await this.$api.stripe.account.update(params);
      } catch (err) {
        this.$toast.error(err.response.data);
      }
    },
    async updatePayout(interval) {
      let params = {
        accountId: this.account,
      };
      if (interval == "daily") {
        params.stripeData = {
          settings: { payouts: { schedule: { interval: "daily" } } },
        };
      } else if (interval == "weekly") {
        params.stripeData = {
          settings: {
            payouts: {
              schedule: { interval: "weekly", weekly_anchor: "monday" },
            },
          },
        };
      } else if (interval == "manual") {
        params.stripeData = {
          settings: { payouts: { schedule: { interval: "manual" } } },
        };
      } else {
        return;
      }
      this.updatingPayout = true;
      try {
        this.stripeAccount = await this.$api.stripe.account.update(params);
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.updatingPayout = false;
    },
  },
};
</script>
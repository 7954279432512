<template lang="pug">
v-list-item(@click="submit()") Migrate Billing
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["invoice"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    async submit() {
      if (!this.invoice) return;
      this.loading = true;
      const params = { invoice_id: this.invoice._id };
      try {
        const res = await this.axios.post("/invoices/migrateBilling", params);
        this.updateInvoice(res.data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{ on }")
    a.secondary--text(@click.stop="", v-on="on", color="secondary") {{ title }}
  v-list
    v-list-item(
      v-for="chain in items",
      :key="chain._id",
      @click="assign(biz, chain)"
    )
      v-list-item-title {{ chain.name }}
    v-list-item(v-if="title != 'Select'", @click="removeFrom(biz)")
      v-list-item-title Remove from {{ title }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("entp", ["chains", "enterprise"]),
    items() {
      return this.chains
        .filter((o) => o.enterprise == this.enterprise?._id)
        .toSorted((a, b) => a.name.localeCompare(b.name));
    },
    title() {
      return (
        this.chains
          .filter((o) => o.bizs.includes(this.biz._id))
          .map((o) => o.name)
          .toSorted()
          .join(", ") || "Select"
      );
    },
  },
  methods: {
    async assign(biz, chain) {
      if (!biz || !chain) return;
      this.loading = true;
      try {
        const action = { $addToSet: { bizs: biz._id } };
        const result = await this.$api.chain.account.put(chain._id, action);
        this.$store.dispatch("entp/updateChain", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async removeFrom(biz) {
      const chain = this.chains.find((o) => o.bizs.includes(biz._id));
      if (!chain) return;
      this.loading = true;
      try {
        const action = { $pull: { bizs: biz._id } };
        const result = await this.$api.chain.account.put(chain._id, action);
        this.$store.dispatch("entp/updateChain", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
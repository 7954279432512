<template lang="pug">
v-card(v-if="poi")
  v-toolbar(dense, flat)
    .subtitle-2 {{ poi.name }}
    v-spacer
    v-btn(icon, small, color="secondary", @click="edit()")
      v-icon(small) mdi-pencil
  v-divider
  v-subheader Parallax
    v-btn(icon, small, color="secondary", @click="editParallax()")
      v-icon(small) mdi-pencil
  v-img(:src="mediaHost + poi.parallax")
  v-subheader Logo
    v-btn(icon, small, color="secondary", @click="editLogo()")
      v-icon(small) mdi-pencil
  v-row(justify="center")
    v-img(:src="mediaHost + poi.logo", max-width="120", height="120", contain)
  simple-table
    tbody
      tr
        th URL
        td {{ poi.url }}
          v-btn(
            icon,
            small,
            color="secondary",
            :href="'https://selflane.com/poi/' + poi.url",
            target="_blank"
          )
            v-icon(small) mdi-link
      tr
        th Address
        td(style="white-space: inherit") {{ poi.address | address }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["poi"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  methods: {
    edit() {
      if (!this.poi) return;
      EventBus.$emit("edit-poi", this.poi);
    },
    editLogo() {
      if (!this.poi) return;
      EventBus.$emit("edit-poi-logo", this.poi);
    },
    editParallax() {
      if (!this.poi) return;
      EventBus.$emit("edit-poi-parallax", this.poi);
    },
  },
};
</script>
import { render, staticRenderFns } from "./Delivery.vue?vue&type=template&id=71944dcf&scoped=true&lang=pug"
import script from "./Delivery.vue?vue&type=script&lang=js"
export * from "./Delivery.vue?vue&type=script&lang=js"
import style0 from "./Delivery.vue?vue&type=style&index=0&id=71944dcf&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71944dcf",
  null
  
)

export default component.exports
<template lang="pug">
v-row(justify="space-between", wrap)
  v-btn.mx-3(rounded, small, @click="create()") Create Monthly Report
  v-btn.mx-3(rounded, small, @click="send()") Send Monthly Report
</template>

<script>
export default {
  methods: {
    async create() {
      try {
        const { data } = await this.axios.post(
          "/member/monthlyreport/create",
          {}
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async send() {
      try {
        const { data } = await this.axios.post(
          "/member/monthlyreport/send",
          {}
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
Page(title="Biz Expense Sources")
  template(v-slot:header)
    IconBtn(@click="open()", title="Add", icon="mdi-plus")
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      v-text-field(
        v-model="search",
        append-icon="search",
        label="Search",
        hide-details
      )
    v-data-table(:items="expSources", :headers="headers", :search="search")
      template(v-slot:item.actions="{ item }")
        .d-flex.flex-row.justify-end
          v-btn(icon, small, color="secondary", @click="open(item)")
            v-icon(small) mdi-pencil
          v-btn(icon, small, color="error", @click="handleRemove(item)")
            v-icon(small) mdi-delete
  v-dialog(v-model="dialog", width="300")
    Form(:source="source", :done="close")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return {
      source: {},
      dialog: false,
      search: "",
      loading: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Group", value: "group" },
        { text: "Website", value: "website" },
        { text: "Actions", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["expSources"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setExpSources", "removeExpSource"]),
    async load() {
      this.loading = true;
      try {
        const result = await this.$api.expense.source.list({ criteria: {} });
        this.setExpSources(result);
      } catch (e) {
        this.setExpSources([]);
      }
      this.loading = false;
    },
    close() {
      this.dialog = false;
    },
    open(data) {
      if (data) {
        this.source = JSON.parse(JSON.stringify(data));
      } else {
        this.source = { name: "", group: "", website: "" };
      }
      this.dialog = true;
    },
    handleRemove(data) {
      confirm("Do you want to remove this source?") && this.remove(data);
    },
    async remove(data) {
      this.loading = true;
      try {
        const result = await this.$api.expense.source.delete(data._id);
        this.removeExpSource(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-btn(
  small,
  text,
  color="secondary",
  @click="submit()",
  :loading="loading",
  v-if="visible"
) Submit
</template>

<script>
export default {
  props: { dispute: { type: Object, default: null } },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    visible() {
      return (
        this.dispute?.status === "needs_response" &&
        this.dispute?.evidence?.refund_policy &&
        this.dispute?.evidence?.receipt
      );
    },
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const result = await this.$api.stripe.disputes.update({
          id: this.dispute.id,
          params: { submit: true },
        });
        this.$toast.success("Dispute submitted.");
        this.$emit("update", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to submit dispute.");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  simple-table(v-if="opportunity")
    tbody
      tr
        th Company
        td {{ opportunity.company_name }}
      tr
        th Email
        td
          ClickToCopy(:text="opportunity.email", v-if="opportunity.email")
      tr
        th Phone
        td
          ClickToCopy(
            :text="opportunity.phone | phone",
            v-if="opportunity.phone"
          )
      tr
        th Size
        td {{ opportunity.size }}
      tr
        th Tags
        td
          Tags(:tags="opportunity.tags")
      tr
        th Address
        td {{ opportunity.address | address }}
      tr
        th Notes
        td {{ opportunity.notes }}
      tr
        th Status
        td {{ opportunity.status }}
      tr
        th Last Update
        td {{ opportunity.updated | date }}
      tr
        th Created
        td {{ opportunity.created | date }}
  div(v-if="contact")
    v-subheader Contact
    simple-table
      tbody
        tr
          th Name
          td {{ contact.first_name }} {{ contact.last_name }}
        tr
          th Email
          td
            ClickToCopy(:text="contact.email", v-if="contact.email")
        tr
          th Phone
          td
            ClickToCopy(:text="contact.phone | phone", v-if="contact.phone")
</template>

<script>
import { mapGetters } from "vuex";
import Tags from "/libs/components/Dev/Issue/Tags";

export default {
  components: { Tags },
  props: {
    opportunity: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    ...mapGetters("crm", ["contacts"]),
    contact() {
      return this.contacts.find((c) => c._id === this.opportunity.contact);
    },
  },
  async mounted() {
    if (!this.contacts.length) await this.loadContacts();
  },
  methods: {
    async loadContacts() {
      const organization = this.salesOrganization?._id;
      if (!organization) return;
      try {
        const criteria = { organization };
        const contacts = await this.$api.crm.contact.list({ criteria });
        this.$store.dispatch("crm/setContacts", contacts);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load contacts");
      }
    },
  },
};
</script>
<template lang="pug">
div
  .d-flex.flex-row.justify-center.align-center.mb-3
    YearSelector(@select="load")
    v-btn-toggle.mx-3(v-model="type", color="secondary", dense)
      v-btn(v-for="(item, index) in typeItems", :key="index", :value="item") {{ item }}
  Weekly(:type="type", :showDownload="showDownload", ref="weekly")
  Monthly(:type="type", :showDownload="showDownload", ref="monthly")
</template>

<script>
import Weekly from "./Weekly";
import Monthly from "./Monthly";

export default {
  components: { Weekly, Monthly },
  data() {
    return {
      showDownload: true,
      type: "weekly",
      typeItems: ["weekly", "monthly"],
    };
  },
  methods: {
    load(year) {
      this.$refs.weekly.load(year);
      this.$refs.monthly.load(year);
    },
  },
};
</script>

<template lang="pug">
div
  .subtitle-2.my-3 Count By Biz (No Assigned Program only)
  v-data-table(:items="result", :headers="headers", dense)
    template(v-slot:item.program="{ item }")
      span {{ formatProgram(item.biz) }}
    template(v-slot:item.action="{ item }")
      v-btn(small, outlined, @click="create(item)") Create
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Biz", value: "biz" },
        { text: "Program", value: "program" },
        { text: "Count", value: "count", align: "end" },
        { text: "Action", value: "action", align: "end" },
      ],
      result: [],
    };
  },
  computed: {
    ...mapGetters(["memberPrograms"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    formatProgram(id) {
      const found = this.memberPrograms?.find((o) => {
        return o.bizs.includes(id);
      });
      return found?.name || "";
    },
    async load() {
      const { data } = await this.axios.post("/member/stats/countByBiz");
      this.result = data;
    },
    create(item) {
      const program = { name: "", bizs: [item.biz] };
      EventBus.$emit("edit-member-program", program);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500", persistent)
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", small, depressed, dark, v-on="on") Create File
  v-card(:loading="loading")
    v-card-title
      h2 {{ title }}
    v-card-text
      v-form
        v-file-input(v-model="file", :rules="rules", label="File")
        v-select(v-model="purpose", :items="purpose_items", label="Purpose")
      div(v-if="result")
        .subtitle-2 Result
        pre {{ result }}
    v-card-actions
      v-btn(color="primary", @click="create") Create
      v-btn(@click="dialog = false") Cancel
</template>

<script>
export default {
  data() {
    return {
      title: "Create File",
      dialog: false,
      loading: false,
      file: null,
      purpose: "dispute_evidence",
      rules: [(value) => !!value || "Required."],
      purpose_items: [
        "business_icon",
        "business_logo",
        "customer_signature",
        "dispute_evidence",
        "identity_document",
        "pci_document",
        "tax_document_user_upload",
        "unknown",
      ],
      result: null,
    };
  },
  methods: {
    async create() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("purpose", this.purpose);
      this.loading = true;
      try {
        this.result = await this.$api.stripe.files.create(formData);
        this.$toast.success("File created.");
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create file.");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  v-card(v-if="groups && groups.length", flat, :loading="loading")
    v-card-title
      .d-flex.flex-row.align-center.justify-space-between
        v-text-field(
          v-model="searchText",
          label="Search ID, Last4, Amount, Type, Order",
          clearable,
          solo,
          hide-details
        )
        v-btn-toggle.ml-3(v-model="compact", color="secondary")
          v-btn(
            v-for="(item, index) in items",
            :key="index",
            :value="item.value",
            small
          ) {{ item.text }}
    v-tabs(centered)
      v-tab(v-for="(group, index) in groups", :key="index") Terminal {{ group.sn }}
      v-tab-item(v-for="(group, index) in groups", :key="index")
        Table(
          :items="group.items",
          :searchText="searchText",
          :compact="compact",
          :biz="biz",
          @show="show"
        )
        IDSummary(:items="group.items")
  Detail(ref="detail")
</template>


<script>
import Detail from "./Detail";
import IDSummary from "./IDSummary";
import Table from "./Table";

export default {
  components: { Table, IDSummary, Detail },
  props: ["groups", "loading", "biz"],
  data() {
    return {
      searchText: "",
      compact: true,
      items: [
        { text: "Compact", value: true },
        { text: "Standard", value: false },
      ],
    };
  },
  methods: {
    show(item) {
      this.$refs.detail.open(item);
    },
  },
};
</script>
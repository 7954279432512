<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(type="number", label="Amount", v-model="amount")
        v-text-field(label="Note", v-model="note")
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      bizId: null,
      amount: 0,
      note: "",
    };
  },
  mounted() {
    EventBus.$on("create-biz-account-credit", this.load);
  },
  destroyed() {
    EventBus.$off("create-biz-account-credit", this.load);
  },
  methods: {
    load(bizId) {
      this.bizId = bizId;
      this.dialog = true;
    },
    async submit() {
      if (!this.bizId) return;
      if (!this.amount) return;
      if (!this.note) return;
      this.loading = true;
      const params = {
        bizId: this.bizId,
        amount: this.amount,
        note: this.note,
      };
      this.loading = true;
      try {
        await this.axios.post("/bizAccountCredit/create", params);
        this.$toast.success("Credit created");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
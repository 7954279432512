<template lang="pug">
v-list-item(v-on="$listeners")
  v-list-item-content
    v-list-item-title
      .d-flex.flex-row.align-center
        v-icon.mr-2(small, :color="icon.color") {{ icon.name }}
        span {{ lead.company_name }}
    v-list-item-subtitle.caption
      div(v-if="lead.size") Monthly {{ lead.size }}
      Tags(:tags="lead.tags")
      div {{ lead.address | address("city, state") }}
      div {{ lead.sales_person_name }} | {{ organization }} | {{ lead.biz_name }}
      div {{ fromNow }} | last updated {{ lead.updated | datetime("smart") }}
      div {{ lead.status }}
      div {{ lead.notes }}
  v-list-item-action
    .d-flex.flex-row(v-if="lead.interactions")
      v-icon.mr-2(small) mdi-comment-outline
      .caption {{ lead.interactions }}
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Tags from "/libs/components/Dev/Issue/Tags";

export default {
  components: { Tags },
  props: {
    lead: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    fromNow() {
      const created = this.lead?.created;
      if (!created) return "";
      return moment(created).fromNow();
    },
    icon() {
      // status: new, contacted, qualified, unqualified, dead, converted
      let name, color;
      if (["new", "contacted", "qualified"].includes(this.lead?.status)) {
        name = "mdi-record-circle-outline";
        if (this.lead?.status == "new") color = "green";
        if (this.lead?.status == "contacted") color = "blue";
        if (this.lead?.status == "qualified") color = "orange";
      } else if (["unqualified", "dead"].includes(this.lead?.status)) {
        name = "mdi-close-circle-outline";
        color = this.leads?.status == "dead" ? "red" : "gray";
      } else if (this.lead?.status == "converted") {
        name = "mdi-check-circle-outline";
        color = "purple";
      } else {
        name = "mdi-help-box";
        color = "gray";
      }
      return { name, color };
    },
    organization() {
      const id = this.lead?.organization;
      if (!id) return "";
      return this.salesOrganizations.find((item) => item._id == id)?.name;
    },
  },
};
</script>
<template lang="pug">
div(v-if='merchants')
  .sl-title {{ merchants.length }}
  v-row(wrap)
    v-col(cols='12' sm='6' md='3' v-for='(item, index) in merchants' :key='index' fill-height)
      v-card
        v-card-text
          .sl-title {{ item.name }}
          div {{ item.phone | phone }}
          div {{ item.email }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["merchants"])
  }
};
</script>

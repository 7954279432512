<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-title {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-row(dense, wrap)
          v-col(cols="6")
            v-text-field(v-model="access.first_name", label="First Name")
          v-col(cols="6")
            v-text-field(v-model="access.last_name", label="Last Name")
          v-col(cols="6")
            PhoneField(v-model="access.phone")
          v-col(cols="12", sm="6")
            v-text-field(
              v-model="access.email",
              label="Email",
              :disabled="!isNew"
            )
          v-col(cols="12", sm="6")
            v-select(
              v-model="access.privilege",
              :items="privileges",
              label="Privilege",
              :disabled="isMe"
            )
          v-col(cols="12", sm="6", v-if="access.privilege > 1")
            v-select(
              v-model="access.regions",
              :items="myRegions",
              label="Regions",
              multiple
            )
        v-btn(type="submit", color="secondary", :loading="loading", block) Save
        div(v-if="!isNew && !isMe")
          v-divider.my-5
          .my-10 Do you want to delete this enterprise access?
          v-switch(v-model="deletable", label="Yes, delete it", color="error")
          v-btn(
            block,
            small,
            outlined,
            color="error",
            :disabled="!deletable",
            :loading="deleting",
            @click="deleteOne()",
            v-if="!isNew"
          ) Delete Enterprise Access
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EnterpriseAccessForm",
  props: {
    enterprise: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      access: {},
      loading: false,
      deleting: false,
      dialog: false,
      deletable: false,
      privileges: [
        { text: "Admin", value: 1 },
        { text: "Manager", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters("entp", ["regions"]),
    isNew() {
      return !this.access._id;
    },
    isMe() {
      return this.access.user && this.access.user == this.$auth.user()._id;
    },
    title() {
      return this.isNew ? "Add Enterprise Access" : "Edit Enterprise Access";
    },
    myRegions() {
      return this.regions
        .filter((o) => o.enterprise == this.enterprise?._id)
        .map((o) => {
          return { text: o.name, value: o._id };
        });
    },
  },
  methods: {
    open(data) {
      if (data) {
        this.access = JSON.parse(JSON.stringify(data));
      } else {
        this.access = {
          first_name: "",
          last_name: "",
          phone: "",
          email: "",
          privilege: 1,
          items: [],
          enterprise: this.enterprise?._id,
        };
      }
      this.deletable = false;
      this.dialog = true;
    },
    async submit() {
      if (!this.access || !this.enterprise) return;
      this.loading = true;
      if (this.access.privilege == 1) {
        this.access.regions = [];
      }
      try {
        if (!this.isNew) {
          const params = {
            first_name: this.access.first_name,
            last_name: this.access.last_name,
            phone: this.access.phone,
            privilege: this.access.privilege,
            items: this.access.items,
            regions: this.access.regions,
          };
          const result = await this.$api.entp.access.put(
            this.access._id,
            params
          );
          this.$store.dispatch("entp/updateAccess", result);
        } else {
          const result = await this.$api.entp.access.create(this.access);
          this.$store.dispatch("entp/addAccess", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async deleteOne() {
      if (!this.access) return;
      this.deleting = true;
      try {
        const result = await this.$api.entp.access.delete(this.access._id);
        this.$store.dispatch("entp/removeAccess", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.deleting = false;
    },
  },
};
</script>


<template lang="pug">
.my-container
  .subtitle-2 Actions Logs ({{ timezone }})
  .caption Order created at: {{ order.created | datetime("smart", timezone) }}
  .caption(v-if="device") Device: {{ device }}
  PrintLogView(ref="printLog", :order="order")
  ActionView(ref="actionView", :order="order")
  ConversationView(ref="conversationView", :order="order")
  Conversation(:order="order")
</template>

<script>
import ActionView from "./ActionView";
import ConversationView from "./ConversationView";
import PrintLogView from "./PrintLogView";
import Conversation from "/libs/components/Order/Conversation";

export default {
  components: { PrintLogView, ActionView, ConversationView, Conversation },
  props: {
    order: { type: Object, default: null },
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
    device() {
      let text = "";
      if (this.order?.device?.id) {
        text += this.order.device.id.substring(0, 8);
      }
      if (this.order?.device?.appName) {
        text += ", " + this.order.device.appName;
      }
      if (this.order?.device?.appVersion) {
        text += " (" + this.order.device.appVersion + ")";
      }
      return text;
    },
  },
  methods: {
    async load() {
      await this.$refs.printLog.load();
      await this.$refs.actionView.load();
      await this.$refs.conversationView.load();
    },
  },
};
</script>

<style lang="sass" scoped>
// container with vertical stack and gap of 10px
.my-container
  display: flex
  flex-direction: column
  gap: 10px
</style>
<template lang="pug">
.d-flex.flex-row.align-center
  v-select(v-model="contact", :items="contact_items", label="Contact")
  IconBtn(
    @click.stop="load()",
    icon="mdi-refresh",
    title="",
    :loading="loading"
  )
  IconBtn(@click.stop="create", icon="mdi-plus", title="")
  Form(ref="form", @done="setContact")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./../Form";

export default {
  components: { Form },
  props: {
    value: { type: String, default: null },
  },
  data() {
    return {
      contact: this.value,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("crm", ["contacts"]),
    ...mapGetters(["salesOrganization"]),
    contact_items() {
      return this.contacts
        .filter((i) => i.organization === this.salesOrganization?._id)
        .map((i) => ({
          text: i.first_name + " " + i.last_name,
          value: i._id,
        }))
        .toSorted((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    value(v) {
      this.contact = v;
    },
    contact(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    async load() {
      const organization = this.salesOrganization?._id;
      if (!organization) return;
      this.loading = true;
      try {
        const criteria = { organization };
        const contacts = await this.$api.crm.contact.list({ criteria });
        this.$store.dispatch("crm/setContacts", contacts);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load contacts");
      }
      this.loading = false;
    },
    create() {
      const organization = this.salesOrganization?._id;
      const contact = {
        first_name: "",
        last_name: "",
        company: "",
        job_title: "",
        email: "",
        phone: "",
        organization,
        address: {},
      };
      this.$refs.form.open(contact);
    },
    setContact(contact) {
      this.contact = contact._id;
    },
  },
};
</script>
<template lang="pug">
v-btn.text-capitalize(@click="handlePopulate()") Populate Member Phone
</template>

<script>
export default {
  methods: {
    handlePopulate() {
      confirm("This will populate member with phone") && this.populate();
    },
    async populate() {
      try {
        const { data } = await this.axios.post("/member/populatePhone");
        this.$toast.success(data);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to populate");
      }
    },
  },
};
</script>
<template lang="pug">
div
  v-btn(@click="load()", outlined) Show Offline Businesses
  v-dialog(v-model="dialog", width="700", scrollable)
    v-card
      v-toolbar(dense, flat)
        .subtitle-2 Offline Businesses {{ bizs.length }}
      simple-table
        thead
          tr
            th Name
            th Industry
            th Address
            th Stripe
        tbody
          tr(v-for="(item, index) in bizs", :key="index")
            td {{ item.name }}
            td {{ item.industry | industry }}
            td {{ item.address | address }}
            td {{ item.payments.managed_account }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      bizs: [],
    };
  },
  computed: {
    ...mapGetters(["bizservices"]),
  },
  methods: {
    async load() {
      this.dialog = true;
      const ids = _.chain(this.bizservices)
        .filter((o) => o.pos && o.pos.status)
        .pluck("biz")
        .value();
      const params = {
        criteria: { _id: { $in: ids }, "status.byAdmin": 0 },
        select: "name address industry payments",
      };
      this.bizs = await this.$api.biz.list(params);
    },
  },
};
</script>

<template lang="pug">
Page(title="User", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Search", url: "/users/search" },
        { title: "Daily", url: "/users/daily" },
        { title: "Dashboard", url: "/users/dashboard" },
        { title: "Unverified", url: "/users/unverified" },
        { title: "Blacklist", url: "/users/blacklist" },
        { title: "Bouncing Email", url: "/users/bouncingemail" },
        { title: "Domain", url: "/users/domain" },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  v-row(wrap, v-if="promotions", dense)
    v-col(
      cols="6",
      sm="4",
      md="3",
      v-for="(item, index) in promotions",
      :key="index"
    )
      Cell(:item="item")
  UpdatePromotion
</template>

<script>
import Cell from "./Cell";
import UpdatePromotion from "./UpdatePromotion";

export default {
  name: "CouponList",
  components: { Cell, UpdatePromotion },
  props: ["promotions"],
};
</script>

<template lang="pug">
.my-3(v-if="taxfiles && taxfiles.length")
  .d-flex.flex-row.align-center.my-5
    .subtitle-2 {{ label }}
    v-spacer
    .text-field-box
      v-text-field(
        v-model="search",
        placeholder="Search",
        solo,
        dense,
        hide-details
      )
  v-data-table(:headers="headers", :items="taxfiles", dense, :search="search")
    template(v-slot:item.action="{ item }")
      .text-right
        span(v-if="item.emailtime") {{ item.emailtime | date }}
        AccountLink(:filename="item.filename")
        HandleEmail(:item="item")
        DeleteBtn(:item="item")
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            v-btn(icon, small, v-on.stop="on")
              v-icon(color="secondary") mdi-dots-horizontal
          v-list.py-0(dense)
            Open(:item="item")
            DeleteOne(:item="item")
</template>

<script>
import Open from "./Open";
import HandleAssign from "./HandleAssign";
import HandleEmail from "./HandleEmail";
import DeleteOne from "./DeleteOne";
import AccountLink from "./AccountLink";
import DeleteBtn from "./DeleteBtn";

export default {
  components: {
    Open,
    HandleAssign,
    HandleEmail,
    DeleteOne,
    AccountLink,
    DeleteBtn,
  },
  props: ["label", "taxfiles"],
  data() {
    return {
      search: "",
      headers: [
        { text: "Type", value: "filetype" },
        { text: "File", value: "filename" },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "", value: "action" },
      ],
    };
  },
};
</script>

<style lang="sass" scope>
.text-field-box
  max-width: 200px
</style>

<!--
  Display the balance of a Stripe account
  API Reference: https://stripe.com/docs/api/balance
-->
<template lang="pug">
.my-container
  div(v-if="!id") No Stripe Account
  div(v-else)
    v-progress-circular(v-if="loading", indeterminate, color="secondary")
    .mx-3.my-container(v-for="(item, index) in groups", :key="index")
      .caption.secondary-text Remaining Balance
      .subtitle-2.text-uppercase {{ item.value | currency }} {{ item.currency }}
</template>

<script>
import _ from "underscore";

export default {
  data() {
    return { balance: null, loading: false, id: null };
  },
  computed: {
    // Combine available and pending balance and group by currency
    groups() {
      if (!this.balance) return [{ currency: "", value: "" }];
      const items = this.balance.available.concat(this.balance.pending);
      return _.chain(items)
        .groupBy("currency")
        .map((items, currency) => {
          const value = items.reduce((a, b) => a + b.amount, 0) / 100;
          return { currency, value }; // Convert cent to dollar
        })
        .value();
    },
  },
  methods: {
    async load(id) {
      this.id = id;
      if (!id) {
        this.balance = null;
        return;
      }
      this.loading = true;
      const params = { accountId: id };
      try {
        this.balance = await this.$api.stripe.balance.retrieve(params);
      } catch (e) {
        this.balance = null;
        // fail silently
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.my-container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
</style>

<template lang="pug">
div
  v-list-item(@click.stop="open()") Edit Biz
  v-dialog(v-model="dialog", width="300")
    v-card(v-if="order")
      v-toolbar(dense, flat)
        .overline Edit Biz
      v-divider
      v-card-text
        .red--text Only use it if you know exactly what you are doing!
        v-form.mt-3(@submit.prevent="submit")
          v-text-field(label="Biz ID", v-model="bizId")
          v-btn(type="submit", block, color="secondary") Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      bizId: "",
    };
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open() {
      this.dialog = true;
      this.bizId = "";
    },
    async submit() {
      if (!this.order || !this.bizId) return;
      const params = { orderId: this.order._id, bizId: this.bizId };
      try {
        const { data } = await this.axios.post("/orders/editBiz", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

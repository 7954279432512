<template lang="pug">
StandardChart(:input="chartData", chartType="bar", legend, maxWidth="100%")
  simple-table
    thead
      tr
        th Month
        th Total
        th.text-right Count
        th.text-right Average
        th.text-right Growth
    tbody
      tr(v-for="(item, index) in sorted", :key="index")
        td {{ item.period.month | month(true) }}
        td {{ item.total | currency }}
        td.text-right {{ item.count }}
        td.text-right {{ (item.total / item.count) | currency }}
        td.text-right(:class="{ 'red--text': item.growth < 0 }") {{ item.growth }}%
</template>

<script>
export default {
  props: ["items", "currency", "year"],
  computed: {
    sorted() {
      return this.items.toSorted((a, b) => b.period.month - a.period.month);
    },
    chartData() {
      const month = this.$options.filters.month;
      const total = this.items.map((o) => o.total);
      const paid = this.items.map((o) => o.paid);
      return {
        title: `${this.year} Hardware & Service Growth (${this.currency})`,
        labels: this.items.map((o) => month(o.period.month, true)),
        datasets: [
          { label: "Total", backgroundColor: "#7f7fff", data: total },
          { label: "Paid", backgroundColor: "#8D6E63", data: paid },
        ],
        isCurrency: true,
      };
    },
  },
};
</script>

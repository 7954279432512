<template lang="pug">
.avatar-container(v-if="enterprise")
  v-avatar(:size="size", color="green lighten-3")
    img(:src="picture", v-if="picture")
    v-icon(v-else) mdi-camera
  .edit-btn
    v-btn(@click="open", icon, color="secondary")
      v-icon(small) mdi-pencil
  UploadForm(ref="form")
</template>

<script>
import UploadForm from "./UploadForm";

export default {
  components: { UploadForm },
  props: {
    size: { type: Number, default: 120 },
    enterprise: { type: Object, default: null },
  },
  computed: {
    picture() {
      const url = this.enterprise?.profile_picture;
      if (url) return process.env.VUE_APP_MEDIAHOST + url;
      return null;
    },
  },
  methods: {
    open() {
      this.$refs.form.open(this.enterprise);
    },
  },
};
</script>

<style lang="sass" scoped>
// make edit btn align to bottom right of avatar
.avatar-container
  position: relative
  display: inline-block
.edit-btn
  position: absolute
  bottom: 0
  right: -20px
  z-index: 1
</style>
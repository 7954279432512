<template lang="pug">
Page(title="Press")
  template(v-slot:header)
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  Items
  Form
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Form from "./Form";
import Items from "./Items";

export default {
  components: { Form, Items },
  methods: {
    create() {
      EventBus.$emit("edit-press-report");
    },
  },
};
</script>
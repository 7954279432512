<template lang="pug">
div
  v-form(@submit.prevent="submit()")
    v-text-field(
      v-model="search",
      label="Search Driver",
      placeholder="Name",
      append-icon="search",
      @click:append="submit()",
      solo,
      hide-details,
      dense
    )
  v-list(dense)
    v-list-item(
      v-for="(item, index) in drivers",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item.name }} {{ item.phone | phone }}
        v-list-item-subtitle {{ item.email }} {{ item.zones }} {{ item.status }}
      v-list-item-action(v-if="item._id == selection")
        v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  data() {
    return {
      search: "",
      drivers: [],
      selection: null,
    };
  },
  methods: {
    async submit() {
      const search = this.search.trim();
      if (!search || search.length < 3) {
        return this.$toast.error("Please enter with at least 3 letters");
      }
      const params = {
        criteria: { name: { $regex: search, $options: "i" } },
        select: "name email phone zones status",
      };
      this.drivers = await this.$api.driver.list(params);
    },
    select(item) {
      if (!item) return;
      this.selection = item._id;
      this.$emit("select", item);
    },
  },
};
</script>

<template lang="pug">
div
  DaySelector(@select="load")
  v-text-field.my-5(
    v-model="searchText",
    label="Filter Result",
    clearable,
    solo,
    hide-details,
    flat,
    dense
  )
  .subtitle-2.my-3 Finished ({{ finishedItems.length }})
  Table.my-3(:items="finishedItems", :searchText="searchText")
  .subtitle-2.my-3 Unfinished ({{ unfinishedItems.length }})
  Table(:items="unfinishedItems", :searchText="searchText")
  .subtitle-2.my-3 Short ({{ shortItems.length }})
  Table.my-3(
    :items="shortItems",
    :searchText="searchText",
    :isDeletable="isDeletable"
  )
</template>

<script>
import Table from "./Table.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  components: { Table },
  data() {
    return {
      searchText: "",
      selectedDate: null,
      isDeletable: true,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts", "punchcards"]),
    finishedItems() {
      return this.punchcards.filter((o) => o.clockOut);
    },
    shortItems() {
      return this.finishedItems.filter((o) => {
        const minutes = Math.round((o.clockOut - o.clockIn) / 1000 / 60);
        return minutes < 2;
      });
    },
    unfinishedItems() {
      return this.punchcards.filter((o) => !o.clockOut);
    },
  },
  methods: {
    ...mapActions(["setBizAccounts", "setPunchcards"]),
    async load(date) {
      if (date) this.selectedDate = date;
      if (!this.selectedDate) return;
      const begin = moment(this.selectedDate).startOf("day").unix() * 1000;
      const end = moment(this.selectedDate).endOf("day").unix() * 1000;
      const params = { criteria: { clockIn: { $gt: begin, $lte: end } } };
      const result = await this.$api.punchcard.list(params);
      this.setPunchcards(result);
    },
  },
};
</script>

<template lang="pug">
simple-table
  tbody
    AmountTableRow(name="Subtotal", :amount="bizorder.payment.subtotal")
    AmountTableRow(:name="taxName", :amount="bizorder.payment.tax")
    AmountTableRow(name="Shipping", :amount="bizorder.payment.shipping")
    AmountTableRow(name="Total", :amount="bizorder.payment.total", bold)
    AmountTableRow(
      :name="paymentName",
      :amount="bizorder.payment.charge?.amount",
      bold
    )
</template>

<script>
export default {
  props: ["bizorder"],
  computed: {
    taxName() {
      return `Tax ${this.bizorder.taxRate}%`;
    },
    paymentName() {
      const method = this.bizorder.payment.charge?.method;
      if (method) return `Paid via ${method}`;
      return "";
    },
  },
};
</script>

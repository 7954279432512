const state = {
  stripePersons: []
}

const getters = {
  stripePersons: state => state.stripePersons
}

const actions = {
  setStripePersons: ({ commit }, data) => { commit('setStripePersons', data) }
}

const mutations = {
  setStripePersons(state, data) { state.stripePersons = data }
}

export default { state, getters, actions, mutations }
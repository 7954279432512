<template lang="pug">
v-data-table(
  :items="bizs",
  :headers="headers",
  :search="searchText",
  sort-by="name",
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.logo="{ item }")
    v-avatar
      v-img(:src="mediaHost + item.logo", v-if="item.logo")
      v-icon(v-else) mdi-image-outline
  template(v-slot:item.address="{ item }")
    span {{ item.address | address }}
  template(v-slot:item.pos_start="{ item }")
    span {{ item.pos_start | date }}
  template(v-slot:item.online_start="{ item }")
    span {{ item.online_start | date }}
  template(v-slot:item.status.byAdmin="{ item }")
    v-icon.mr-3(small, color="success", v-if="item.status.byAdmin == 1") mdi-earth
</template>

<script>
export default {
  props: ["bizs", "searchText"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      headers: [
        { text: "Logo", value: "logo", filterable: false },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "POS", value: "pos_start" },
        { text: "Online", value: "online_start" },
        { text: "Address", value: "address" },
        { text: "Action", value: "status.byAdmin", align: "end" },
      ],
    };
  },
};
</script>

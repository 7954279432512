const state = {
  giftcards: []
}

const getters = {
  giftcards: state => state.giftcards
}

const actions = {
  setGiftCards: ({ commit }, data) => { commit('setGiftCards', data) },
  updateGiftCard: ({ commit }, data) => { commit('updateGiftCard', data) }
}

const mutations = {
  setGiftCards(state, data) { state.giftcards = data },
  updateGiftCard(state, data) {
    state.giftcards = state.giftcards.map(o => {
      if (o._id === data._id) return data
      return o
    })
  }
}

export default { state, getters, actions, mutations }
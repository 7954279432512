<template lang="pug">
v-card(flat)
  v-card-text
    v-text-field(v-model="searchText", label="Search")
  v-data-table(
    :items="products",
    :headers="headers",
    :search="searchText",
    :item-class="getRowBkg",
    sort-by="sku",
    @click:row="editProduct",
    role="button",
    mobile-breakpoint="0"
  )
    template(v-slot:item.price="{ item }")
      span {{ item.price | currency }}
    template(v-slot:item.action="{ item }")
      v-btn(
        icon,
        depressed,
        @click.stop="addItemToCart(item)",
        color="secondary"
      )
        v-icon(small) mdi-cart-plus
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      searchText: "",
      headers: [
        { text: "SKU", value: "sku" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "", value: "action" },
      ],
    };
  },
  computed: { ...mapGetters("catalog", ["products"]) },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["addItemToCart"]),
    getRowBkg(item) {
      const invalid = !item.cost && item.taxType == "tg";
      return invalid ? "warning-row" : "";
    },
    async load() {
      try {
        const params = { criteria: {} };
        const result = await this.$api.catalog.product.list(params);
        this.$store.dispatch("catalog/setProducts", result);
      } catch (e) {
        this.$store.dispatch("catalog/setProducts", []);
      }
    },
    editProduct(item) {
      EventBus.$emit("edit-product", item);
    },
  },
};
</script>

<style lang="sass" scoped>
.warning-row
  background-color: #ffeb3b !important
</style>
<template lang="pug">
div
  v-btn.mb-5(@click="createSample", outlined, color="secondary", small) Create
  Table
  EditForm
</template>

<script>
import { mapActions } from "vuex";
import Table from "./Table";
import EditForm from "./EditForm";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table, EditForm },
  methods: {
    ...mapActions(["setEmailSamples"]),
    mounted() {
      this.load();
    },
    async load() {
      const params = { criteria: {} };
      const url = "/corp/tool/email_sample/list";
      const { data } = await this.axios.post(url, params);
      this.setEmailSamples(data);
    },
    createSample() {
      EventBus.$emit("edit-email-sample");
    },
  },
};
</script>
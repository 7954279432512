const state = {
  biz: null,
  bizAccounts: [],
};

const getters = {
  biz: state => state.biz,
  bizAccounts: state => state.bizAccounts,
};

const actions = {
  setBiz: ({ commit }, data) => {
    commit('setBiz', data)
    if (data) commit('updateBizAccount', data);
  },
  setBizAccounts: ({ commit }, data) => { commit("setBizAccounts", data); },
  updateBizAccount: ({ commit }, data) => { commit("updateBizAccount", data); },
  removeBizAccount: ({ commit }, data) => { commit("removeBizAccount", data); },
};

const mutations = {
  setBiz(state, data) { state.biz = data },
  setBizAccounts(state, data) { state.bizAccounts = data; },
  updateBizAccount(state, data) {
    state.bizAccounts = state.bizAccounts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeBizAccount(state, data) {
    state.bizAccounts = state.bizAccounts.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };
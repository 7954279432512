const state = {
  pos_devices: [], // access defined by user
};

const getters = {
  pos_devices: state => state.pos_devices,
};

const actions = {
  setPOSDevices: ({ commit }, data) => { commit("setPOSDevices", data); },
  removePOSDevice: ({ commit }, data) => { commit("removePOSDevice", data); },
};

const mutations = {
  setPOSDevices(state, data) { state.pos_devices = data; },
  removePOSDevice(state, data) {
    state.pos_devices = state.pos_devices.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  Block.my-3(
    :group="group",
    v-for="(group, gindex) in groups",
    :key="gindex",
    :loading="loading"
  )
</template>

<script>
import _ from "underscore";
import Block from "./Block";

export default {
  components: { Block },
  data() {
    return {
      loading: false,
      items: [],
      organizations: [],
    };
  },
  computed: {
    groups() {
      if (!this.items || this.items.length <= 0) return [];
      const data = _.chain(this.items)
        .groupBy("currency")
        .map((list, currency) => {
          const rows = _.chain(list)
            .groupBy("organization_id")
            .map((list_name, id) => {
              const items = _.chain(_.range(1, 13))
                .map((month) => {
                  const found = list_name.find((o) => o.period.month == month);
                  const total = found?.total || 0;
                  const count = found?.count || 0;
                  return { total, count };
                })
                .value();
              const total = items.reduce((a, b) => a + b.total, 0);
              const name =
                this.organizations.find((o) => o._id == id)?.name || id;
              return { name, items, total };
            })
            .sortBy((o) => -o.total)
            .value();
          return { currency, rows };
        })
        .value();
      return data;
    },
  },
  methods: {
    async load(year) {
      if (!year) return;
      this.loading = true;
      const params = { criteria: { "period.year": year } };
      const url = "invoices/monthlyOrg/list";
      const { data } = await this.axios.post(url, params);
      this.items = data;
      await this.loadOrgs();
      this.loading = false;
    },
    async loadOrgs() {
      const ids = this.items?.map((o) => o.organization_id);
      if (!ids || ids.length <= 0) return;
      const params = { criteria: { _id: { $in: ids } } };
      this.organizations = await this.$api.salesforce.organization.list(params);
    },
  },
};
</script>
<template lang="pug">
.sl-medal.count-box
  h2 Total Users
  h1 {{ count | toLocaleString }}
</template>

<script>
export default {
  data() {
    return { count: 0 };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const { count } = await this.$api.user.count();
      this.count = count;
    },
  },
};
</script>

<style lang="sass" scoped>
.count-box
  max-width: 190px
  margin-left: auto
  margin-right: auto
  margin-top: 20px
  margin-bottom: 12px
</style>
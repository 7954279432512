<template lang="pug">
v-container(grid-list-lg)
  v-btn(@click="load") Reload
  v-row.my-5
    v-col(cols="12", md="6")
      PeopleChart(:summary="summary")
    v-col(cols="12", md="6")
      VisitChart(:summary="summary")
    v-col(cols="12", md="6")
      SpendingChart(:summary="summary")
    v-col(cols="12", md="6")
      SpendingPowerChart(:summary="summary")
</template>

<script>
import _ from "underscore";
import PeopleChart from "./PeopleChart";
import VisitChart from "./VisitChart";
import SpendingChart from "./SpendingChart";
import SpendingPowerChart from "./SpendingPowerChart";

export default {
  components: { PeopleChart, VisitChart, SpendingChart, SpendingPowerChart },
  data() {
    return {
      summary: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    processData(data) {
      if (!data?.length) return;
      const months = data.map((o) => o.month);
      const minMonth = Math.min(...months);
      const maxMonth = Math.max(...months);
      const allMonths = [];
      for (var i = minMonth; i <= maxMonth; ) {
        allMonths.push(i);
        if (i % 100 === 12) i = (Math.round(i / 100) + 1) * 100 + 1;
        else i++;
      }
      this.summary = _.map(allMonths, (month) => {
        const found = data.find((o) => o.month === month);
        if (found) return found;
        return { month, people: 0, visit: 0, amount: 0 };
      });
    },
    async load() {
      try {
        const params = { match: { $match: {} } };
        const url = "/userExpense/summarize";
        const { data } = await this.axios.post(url, params);
        this.processData(data);
      } catch (err) {
        //
      }
    },
  },
};
</script>
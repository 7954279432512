<template lang="pug">
v-row(justify="center")
  v-col(cols="3", sm="2", align="center")
    .mb-1.subheading.blue--text.text--lighten-1.text-capitalize Emailed
    .mb-1.secondary--text.title {{ emailed }}
  v-col(cols="3", sm="2", align="center")
    .mb-1.subheading.blue--text.text--lighten-1.text-capitalize Not Emailed
    .mb-1.secondary--text.title {{ taxfiles.length - emailed }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["taxfiles"]),
    emailed() {
      return this.taxfiles.filter((o) => o.emailtime)?.length || 0;
    },
  },
};
</script>

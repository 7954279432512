<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(:loading="loading")
    v-card-title Edit Domain
    form(@submit.prevent="submit", v-if="domain")
      v-card-text
        v-text-field(v-model="domain.url", label="URL", required)
        v-text-field(v-model="domain.title", label="Title", required)
        v-textarea(v-model="domain.description", label="Description")
        BizSelector(
          @select="selectBiz",
          v-if="!domain.biz",
          label="Assign a Biz"
        )
      v-card-actions
        v-btn(@click="dialog = false") Cancel
        v-btn(type="submit", :loading="loading") Save
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      domain: null,
    };
  },
  methods: {
    selectBiz(biz) {
      this.domain.biz = biz?._id;
    },
    async open(data) {
      this.dialog = true;
      this.loading = true;
      if (!data) {
        this.domain = {
          url: "",
          title: "",
          description: "",
        };
      } else {
        this.domain = await this.$api.b.domain.retrieve({
          criteria: { _id: data._id },
        });
      }
      this.loading = false;
    },
    async submit() {
      // lowercase the url
      this.domain.url = this.domain.url?.toLowerCase();
      this.loading = true;
      try {
        if (this.domain._id) {
          const id = this.domain._id;
          const action = {
            $set: {
              url: this.domain.url,
              title: this.domain.title,
              description: this.domain.description,
              biz: this.domain.biz,
            },
          };
          await this.$api.b.domain.put(id, action);
        } else {
          await this.$api.b.domain.create(this.domain);
        }
        this.dialog = false;
        this.$emit("refresh");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  YearSelector(@select="select")
  Itemized.my-3(:items="items")
  Organizations.my-3(ref="org")
  Monthly.my-3(:items="monthly", :year="year")
  Generate.my-3(:year="year", @refresh="select")
</template>

<script>
import Itemized from "./Itemized";
import Monthly from "./Monthly";
import Organizations from "./Organizations";
import Generate from "./Generate";

export default {
  components: { Itemized, Monthly, Organizations, Generate },
  data() {
    return { year: 0, items: [], monthly: [] };
  },
  methods: {
    select(year) {
      if (year) this.year = year;
      this.loadItem();
      this.loadMonthly();
      this.$refs.org.load(this.year);
    },
    async loadItem() {
      const params = { criteria: { "period.year": this.year } };
      const url = "invoices/monthlyitem/list";
      const { data } = await this.axios.post(url, params);
      this.items = data;
    },
    async loadMonthly() {
      const params = { criteria: { "period.year": this.year } };
      const { data } = await this.axios.post("invoices/monthly/list", params);
      this.monthly = data;
    },
  },
};
</script>
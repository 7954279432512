<template lang="pug">
div
  v-divider
  .subtitle-2 Stage: {{ driver.stage }}
  .subtitle-2 Status: {{ driver.status }}
  .subtitle-2 Deliv: {{ driver.deliv }}
  .subtitle-2 Zones: {{ driver.zones }}
  .d-flex.flex-row.align-center.my-3
    v-btn(
      v-if="canCorrect",
      @click="correctStage()",
      outlined,
      rounded,
      small,
      :loading="loading"
    ) Correct Stage
    v-btn(
      v-if="canRetouch",
      @click="retouch()",
      color="error",
      outlined,
      rounded,
      small,
      :loading="loading"
    ) Retouch Stage
    v-select(
      v-model="driver.zones",
      :items="zone_items",
      @change="updateZone()"
    )
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["driver"],
  data() {
    return {
      zone_items: [],
      loading: false,
    };
  },
  computed: {
    canCorrect() {
      return this.driver.stage == 0 && this.driver.deliv;
    },
    canRetouch() {
      return this.driver.stage < 0;
    },
  },
  mounted() {
    this.loadGeoZones();
  },
  methods: {
    ...mapActions(["updateDriver", "setGeoServiceZones"]),
    // retouch a rejected driver to new
    async correctStage() {
      if (!this.driver) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.driver._id },
        action: { $set: { stage: 1 } },
      };
      const { data } = await this.axios.post("/driver/accounts/update", params);
      this.updateDriver(data);
      this.loading = false;
    },
    async retouch() {
      if (!this.driver) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.driver._id },
        action: { $set: { stage: 0, status: true, deliv: null } },
      };
      const { data } = await this.axios.post("/driver/accounts/update", params);
      this.updateDriver(data);
      this.loading = false;
    },
    async updateZone() {
      if (!this.driver) return;
      const params = {
        criteria: { _id: this.driver._id },
        action: { $set: { zones: [this.driver.zones] } },
      };
      const { data } = await this.axios.post("/driver/accounts/update", params);
      this.updateDriver(data);
    },
    async loadGeoZones() {
      const params = { criteria: {}, select: "zoneName" };
      const { data } = await this.axios.post("/geoService/zones/list", params);
      this.setGeoServiceZones(data);
      this.zone_items = data.map((o) => o.zoneName);
    },
  },
};
</script>
<template lang="pug">
v-data-table(:headers="headers", :items="outs", mobile-breakpoint=0)
  template(v-slot:item.date="{ item }")
    span {{ props.item.date | date }}
  template(v-slot:item.staff="{ item }")
    UserName(:users="users", :id="props.item.staff")
  template(v-slot:item.product="{ item }")
    ProductName(:products="products", :id="props.item.product")
</template>

<script>
import ProductName from "./ProductName";
import UserName from "./UserName";

export default {
  components: { ProductName, UserName },
  props: ["outs", "products", "users"],
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Staff", value: "staff", sortable: "" },
        { text: "Product", value: "product", sortable: "" },
        { text: "Vendor", value: "vendor", sortable: false },
        { text: "Price", value: "price", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="open()") Inquire
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Inquire
      v-form(@submit.prevent="load()")
        v-card-text
          v-text-field(v-model="retref", label="Retref")
          div(v-if="result")
            .subtitle-2 Result
            pre {{ result }}
        v-card-actions
          v-btn(color="primary", type="submit", :loading="loading") Submit
</template>

<script>
export default {
  props: {
    mid: { type: String, required: true },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      retref: "",
      result: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = { mid: this.mid, retref: this.retref };
      try {
        this.result = await this.$api.cardpointe.cardconnect.inquire(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
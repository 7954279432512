<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Domain
        th.text-right Count
    tbody
      tr(v-for="(item, index) in domains", :key="index")
        th {{ item.domain }}
        td.text-right {{ item.count }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["userAccounts"]),
    domains() {
      return _.chain(this.userAccounts)
        .pluck("email")
        .map((o) => {
          const index = o.indexOf("@");
          return o.slice(index);
        })
        .countBy()
        .map((value, key) => {
          return { domain: key, count: value };
        })
        .sortBy((o) => -o.count)
        .value();
    },
  },
};
</script>
<template lang="pug">
v-row(wrap, dense)
  v-col(cols="12", sm="6")
    StandardChart(:input="totalData", max-width="100%")
  v-col(cols="12", sm="6")
    StandardChart(:input="countData", max-width="100%")
</template>

<script>
import _ from "underscore";

export default {
  name: "DelivWeeklyCharts",
  props: ["result"],
  computed: {
    totalData() {
      if (!this.result) return null;
      const weeks = _.map(
        this.result,
        (o) => String(o.year) + "/" + String(o.week)
      );
      return {
        title: "Delivery Fee",
        labels: weeks,
        values: _.pluck(this.result, "total"),
        isCurrency: true,
      };
    },
    countData() {
      if (!this.result) return null;
      const weeks = _.map(
        this.result,
        (o) => String(o.year) + "/" + String(o.week)
      );
      return {
        title: "# Delivery",
        labels: weeks,
        values: _.pluck(this.result, "count"),
        isCurrency: false,
      };
    },
  },
};
</script>

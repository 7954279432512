<template lang="pug">
Page(title="Influencer", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/influencer/accounts/dashboard" },
        { title: "Monthly", url: "/influencer/accounts/monthly" },
        { title: "Search", url: "/influencer/accounts/search" },
        { title: "Unverified", url: "/influencer/accounts/unverified" },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  v-toolbar(flat, dense)
    v-chip(outlined) {{ count }}
    .title.ml-3 {{ bizservicetotalvalue | currency }}
  .mb-3(v-for="(item, index) in groups", :key="index")
    .sl-title {{ item.country }}
    Table(:items="item.items")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  computed: {
    ...mapGetters(["bizservices", "bizservicetotalvalue"]),
    count() {
      return this.bizservices?.filter((o) => o.amount > 0)?.length;
    },
    groups() {
      return _.chain(this.bizservices)
        .groupBy((o) => o.address?.country || "N/A")
        .map((items, country) => {
          const sorted = _.chain(items).sortBy("name").sortBy("waive").value();
          return { country, items: sorted };
        })
        .value();
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="device")
    v-toolbar(flat, dense)
      .subtitle-2 POS Device Details
    Table(:device="device")
    v-divider
    v-card-actions
      .link-box
        Notify(:device="device", :isInternal="isInternal", v-if="hasToken")
        FCM(:device="device", v-if="isInternal")
        Delete(:device="device", @close="dialog = false", v-if="isInternal")
</template>

<script>
import Delete from "./Delete";
import FCM from "./FCM";
import Notify from "./Notify";
import Table from "./Table";
export default {
  components: { Table, Notify, FCM, Delete },
  data() {
    return {
      dialog: false,
      device: null,
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    hasToken() {
      return this.device?.deviceToken;
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
  },
  methods: {
    load(data) {
      this.device = data;
      this.dialog = true;
    },
  },
};
</script>

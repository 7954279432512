<template lang="pug">
v-btn.text-capitalize(
  outlined,
  small,
  @click.prevent="handleRemove()",
  color="error",
  v-if="group && group._id",
  :loading="loading"
) Remove
  v-icon(right, small) mdi-delete
</template>

<script>
export default {
  props: ["group"],
  data() {
    return { loading: false };
  },
  methods: {
    handleRemove() {
      confirm("Do you really want to remove this group?") && this.remove();
    },
    async remove() {
      if (!this.group?._id) return;
      this.loading = true;
      try {
        const result = await this.$api.catalog.group.delete(this.group._id);
        this.$store.dispatch("catalog/removeGroup", result);
        this.$emit("removed");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
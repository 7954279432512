<template lang="pug">
v-list-item(v-on="$listeners")
  v-list-item-content
    v-list-item-title
      .d-flex.flex-row.align-center
        v-icon.mr-2(small, :color="icon.color") {{ icon.name }}
        span {{ item.title }}
    v-list-item-subtitle.caption
      Tags.my-1(:tags="item.tags")
      Author(:item="item")
      div {{ item.description | truncate(100) }}
  v-list-item-action
    .d-flex.flex-row(v-if="count")
      v-icon.mr-2(small) mdi-comment-outline
      .caption {{ count }}
</template>

<script>
import { mapGetters } from "vuex";
import Author from "./../Author";
import Tags from "./../Tags";

export default {
  components: { Author, Tags },
  props: {
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    organization() {
      const id = this.item?.organization;
      if (!id) return "";
      return this.salesOrganizations.find((item) => item._id == id)?.name;
    },
    count() {
      return this.item?.comments?.length || 0;
    },
    icon() {
      // status: open, no_more_comments, closed
      let name, color;
      const status = this.item?.status;
      if (status == "open") {
        name = "mdi-record-circle-outline";
        color = "green";
      } else if (status == "no_more_comments") {
        name = "mdi-record-circle-outline";
        color = "purple";
      } else if (status == "closed") {
        name = "mdi-check-circle-outline";
        color = "gray";
      } else {
        name = "mdi-help-box";
        color = "gray";
      }
      return { name, color };
    },
  },
};
</script>
const state = {
  driver: null,
  drivers: [],
  recentDrivers: []
};

const getters = {
  driver: state => state.driver,
  drivers: state => state.drivers,
  recentDrivers: state => state.recentDrivers
};

const actions = {
  setDriver: ({ commit }, data) => { commit("setDriver", data); },
  setDrivers: ({ commit }, data) => { commit("setDrivers", data); },
  updateDriver: ({ commit }, data) => { commit("updateDriver", data); },
  addDriver: ({ commit }, data) => { commit("addDriver", data); },
  removeDriver: ({ commit }, data) => { commit("removeDriver", data); },
  addRecentDriver: ({ commit }, data) => { commit("addRecentDriver", data); },
};

const mutations = {
  setDriver(state, data) { state.driver = data; },
  setDrivers(state, data) { state.drivers = data; },
  updateDriver(state, data) {
    state.drivers = state.drivers.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
    state.driver = data;
  },
  addDriver(state, data) { state.drivers.push(data); },
  removeDriver(state, data) {
    state.drivers = state.drivers.filter(o => o._id !== data._id);
  },
  addRecentDriver(data) {
    state.recentDrivers = state.recentDrivers.filter(o => o._id !== data._id);
    state.recentDrivers.unshift(data)
    state.recentDrivers = state.recentDrivers.slice(1, 5)
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  Summary.mb-2
  List.my-2(:orders="created", title="New Orders")
  List.my-2(:orders="processed", title="Processed Orders")
  List.my-2(:orders="canceled", title="Canceled Orders")
  SummaryByBiz.my-2
  DelivOrder
  Compensate
  ChangeToDelivery
  UpdateAddress
</template>

<script>
import { mapGetters } from "vuex";
import Summary from "./Summary";
import SummaryByBiz from "./SummaryByBiz";
import List from "./List/index.vue";
import DelivOrder from "@/components/Sales/DelivOrder/index.vue";
import Compensate from "./Compensate";
import ChangeToDelivery from "./ChangeToDelivery/index";
import UpdateAddress from "./UpdateAddress/index";

export default {
  components: {
    Summary,
    SummaryByBiz,
    List,
    DelivOrder,
    Compensate,
    ChangeToDelivery,
    UpdateAddress,
  },
  computed: {
    ...mapGetters(["orders"]),
    created() {
      return this.orders?.filter((o) => o.status == 0);
    },
    processed() {
      return this.orders?.filter((o) => o.status > 0);
    },
    canceled() {
      return this.orders?.filter((o) => o.status < 0);
    },
  },
};
</script>

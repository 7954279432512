<template lang="pug">
v-card(flat, v-if="userAccounts.length")
  v-toolbar(flat, dense)
    .subtitle-2 Count by Signin
    v-spacer
    .subtitle-2 {{ userAccounts.length }}
  v-divider
  v-list.py-0(dense)
    v-list-item(v-for="group in groups", :key="group.name")
      v-list-item-content
        v-list-item-title {{ group.name }}
        v-list-item-subtitle {{ group.count }} ({{ group.percent }}%)
      v-list-item-action
        v-progress-circular(:size="20", :value="group.percent")
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["userAccounts"]),
    groups() {
      // count by signin method
      // apple.id exists, google.id exists, else email
      const groups = this.userAccounts.reduce((acc, user) => {
        const key = user.apple?.id
          ? "Apple"
          : user.google?.id
          ? "Google"
          : "Email";
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {});
      // structure to array of objects
      // name: "Apple", count: 10, percent: 10%
      // sort to Google, Email, Apple
      const keys = Object.keys(groups).sort((a, b) => {
        if (a === "Google") return -1;
        if (b === "Google") return 1;
        if (a === "Email") return 1;
        if (b === "Email") return -1;
        return 0;
      });
      return keys.map((key) => ({
        name: key,
        count: groups[key],
        percent: ((groups[key] / this.userAccounts.length) * 100).toFixed(2),
      }));
    },
  },
};
</script>
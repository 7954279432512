<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  mobile-breakpoint=0,
  sort-by="created",
  sort-desc,
  dense
)
  template(v-slot:item.created="{ item }")
    span {{ (item.created * 1000) | time }}
  template(v-slot:item.amount="{ item }")
    span {{ (item.amount / 100) | currency }}
  template(v-slot:item.stripe_fee="{ item }")
    span {{ (item.stripe_fee / 100) | currency }}
  template(v-slot:item.application_fee_amount="{ item }")
    span {{ (item.application_fee_amount / 100) | currency }}
  template(v-slot:item.net="{ item }")
    span {{ (item.net / 100) | currency }}
  template(v-slot:item.selflane_net="{ item }")
    span {{ (item.selflane_net / 100) | currency }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Created", value: "created" },
        { text: "Amount", value: "amount" },
        { text: "Stripe Fee", value: "stripe_fee" },
        { text: "App Fee", value: "application_fee_amount" },
        { text: "Net", value: "net" },
        { text: "Selflane Net", value: "selflane_net" },
        { text: "Status", value: "status" },
        { text: "ID", value: "stripeId" },
      ],
    };
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Chain Access
      v-card-text
        v-row(wrap, dense)
          v-col(cols="6")
            v-text-field(label="Name", v-model="name")
          v-col(cols="6")
            PhoneField(v-model="phone")
          v-col(cols="6")
            v-text-field(
              label="Email",
              v-model="email",
              :error-messages="emailErrors",
              @blur="$v.email.$touch()",
              :disabled="isOld"
            )
          v-col(cols="6")
            v-select(
              label="Access Level",
              v-model="privilege",
              :items="roles",
              :error-messages="privilegeErrors",
              @blur="$v.privilege.$touch()",
              :disabled="isMe"
            )
        div(
          v-for="(group, gindex) in accesses_groups",
          :key="gindex + 'group'",
          v-if="isCustom"
        )
          .subtitle-2 {{ group.title }}
          v-radio-group(v-model="items", multiple, row)
            v-radio(
              v-for="(item, index) in group.items",
              :key="index + 'item'",
              :label="item.title",
              :value="item.name",
              color="secondary"
            )
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { EventBus } from "@/event-bus.js";
import AccessGroups from "./Groups.json";
import _ from "underscore";

export default {
  name: "ChainAccessForm",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    privilege: { required },
  },
  data() {
    return {
      menu: null,
      dialog: false,
      access: null,
      loading: false,
      email: "",
      name: "",
      phone: "",
      privilege: null,
      items: [],
      roles: [
        { text: "Owner", value: 1 },
        { text: "Custom", value: 2 },
      ],
      accesses_groups: AccessGroups,
    };
  },
  computed: {
    ...mapGetters("entp", ["chain"]),
    isOld() {
      return this.access && this.access._id != undefined;
    },
    isMe() {
      return this.access && this.access.user == this.$auth.user()._id;
    },
    isCustom() {
      return this.privilege == 4;
    },
    mapped_items() {
      if (!this.isCustom) return [];
      return _.map(this.items, (o) => {
        return {
          name: o,
        };
      });
    },
    title() {
      if (this.access) return "Edit";
      else return "Add";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    privilegeErrors() {
      const errors = [];
      if (!this.$v.privilege.$dirty) return errors;
      !this.$v.privilege.required && errors.push("Role is required");
      return errors;
    },
  },
  mounted() {
    EventBus.$on("edit-chain-access", this.open);
  },
  destroyed() {
    EventBus.$off("edit-chain-access", this.open);
  },
  methods: {
    ...mapActions(["addChainAccess", "updateChainAccess"]),
    open(data) {
      this.access = data;
      if (data) {
        this.email = data.email;
        this.name = data.name;
        this.phone = data.phone;
        this.privilege = data.privilege;
        this.items = _.pluck(data.items, "name");
      } else {
        this.email = "";
        this.name = "";
        this.phone = "";
        this.privilege = null;
        this.items = [];
      }
      this.dialog = true;
      this.$v.$reset();
    },
    submit() {
      if (!this.email || !this.privilege) {
        return this.$toast.error(
          "Please enter email and select an access level"
        );
      }
      if (this.access && this.access._id) this.update();
      else this.create();
    },
    async update() {
      const phone = this.phone || "";
      const params = {
        criteria: { _id: this.access._id },
        action: {
          $set: {
            name: this.name,
            phone,
            privilege: this.privilege,
            items: this.mapped_items,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.chain.access.update(params);
        this.updateChainAccess(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async create() {
      if (!this.chain) return;
      const phone = this.phone || "";
      const params = {
        chain: this.chain._id,
        email: this.email,
        name: this.name,
        phone,
        privilege: this.privilege,
        items: this.mapped_items,
      };
      this.loading = true;
      try {
        const result = await this.$api.chain.access.create(params);
        this.addChainAccess(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>
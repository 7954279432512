<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Date
        th Type
        th status
        th
    tbody
      tr(v-for="(item, index) in driverDocuments", :key="index")
        td {{ item.created | date }}
        td {{ item.file_type }}
        td {{ getStatus(item.status) }}
        td.text-right
          v-btn(small, outlined, rounded, @click="handleView(item)") View
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  computed: {
    ...mapGetters(["driverDocuments"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setDriverDocuments"]),
    async load() {
      const user = this.$auth.user();
      if (!user) return;
      const params = { criteria: {} };
      const { data } = await this.axios.post("/driver/documents/list", params);
      this.setDriverDocuments(data);
    },
    getStatus(value) {
      if (value == 0) {
        return "Created";
      } else if (value == -1) {
        return "Rejected";
      } else if (value == 1) {
        return "Accepted";
      }
    },
    handleView(item) {
      EventBus.$emit("show-document", item);
    },
  },
};
</script>


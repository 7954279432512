<template lang="pug">
v-dialog(v-model="dialog", max-width="400")
  template(v-slot:activator="{ on }")
    .link-box
      .subtitle-2 Standing:
      v-btn(@click="open", color="secondary", small, outlined, v-on="on") {{ biz.standing }}
  v-card
    v-card-title Edit Standing
    v-card-text
      v-select(v-model="standing", :items="items", label="Standing")
    v-card-actions
      v-btn(@click="save", :loading="loading", color="secondary") Save
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      standing: "",
      loading: false,
      dialog: false,
      items: [
        { text: "Test", value: "test" },
        { text: "Staging", value: "staging" },
        { text: "Live", value: "live" },
        { text: "Lost", value: "lost" },
        { text: "Permanently Closed", value: "permanently_closed" },
        { text: "Temporarily Closed", value: "temporarily_closed" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    open() {
      this.standing = this.biz.standing;
      this.dialog = true;
    },
    async save() {
      this.loading = true;
      try {
        if (!this.standing) throw new Error("Standing is required");
        if (!this.biz?._id) throw new Error("Invalid business");
        const params = {
          criteria: { _id: this.biz._id },
          action: { standing: this.standing },
        };
        const result = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
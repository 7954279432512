const state = {
  memberPrograms: [],
};

const getters = {
  memberPrograms: state => state.memberPrograms
};

const actions = {
  setMemberPrograms: ({ commit }, data) => { commit("setMemberPrograms", data); },
  updateMemberProgram: ({ commit }, data) => { commit("updateMemberProgram", data); },
  addMemberProgram: ({ commit }, data) => { commit("addMemberProgram", data); },
  removeMemberProgram: ({ commit }, data) => { commit("removeMemberProgram", data); }
};

const mutations = {
  setMemberPrograms(state, data) {
    state.memberPrograms = data;
  },
  updateMemberProgram(state, data) {
    state.memberPrograms = state.memberPrograms.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addMemberProgram(state, data) {
    state.memberPrograms.push(data);
  },
  removeMemberProgram(state, data) {
    state.memberPrograms = state.memberPrograms.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="promotion")
    v-toolbar(dense, flat)
      .subtitle-2 Update Promotion
    v-card-text
      v-form
        .text-center.my-3
          .subtitle-2 Select Expiration Date
          v-date-picker.my-3(
            v-model="expiry",
            :min="minDate",
            color="secondary",
            no-title
          )
        v-btn(block, color="secondary", :loading="loading", @click="save") Update Promotion
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      dialog: false,
      promotion: null,
      minDate: moment().format("YYYY-MM-DD"),
      expiry: moment().format("YYYY-MM-DD"),
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("update-promotion", this.open);
  },
  destroyed() {
    EventBus.$off("update-promotion", this.open);
  },
  methods: {
    ...mapActions(["updatePromotion"]),
    open(data) {
      this.promotion = JSON.parse(JSON.stringify(data));
      this.expiry = moment(this.promotion.expiry).format("YYYY-MM-DD");
      this.dialog = true;
    },
    async save() {
      if (!this.promotion) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.promotion._id },
        action: {
          $set: {
            expiry: moment(this.expiry).endOf("day").unix() * 1000,
          },
        },
      };
      try {
        const result = await this.$api.promotion.update(params);
        this.updatePromotion(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>

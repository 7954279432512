<template lang="pug">
div
  IconBtn(icon="mdi-pencil", @click="open()", title="", :loading="loading")
  v-dialog(v-model="dialog", width="300")
    v-card
      v-list.py-0
        v-list-item(
          v-for="(zone, zone_index) in geoServiceZones",
          :key="zone_index",
          @click="save(zone)"
        )
          v-list-item-content {{ zone.zoneName }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["biz"],
  data() {
    return { dialog: false, loading: false };
  },
  computed: { ...mapGetters(["geoServiceZones"]) },
  methods: {
    ...mapActions(["setBiz", "setGeoServiceZones"]),
    open() {
      if (!this.biz) return this.$toast.error("No biz is set");

      this.dialog = true;
      this.loadGeoZones();
    },
    async loadGeoZones() {
      const params = { criteria: {} };
      const result = await this.$api.geoZone.list(params);
      this.setGeoServiceZones(result);
    },
    async save(zone) {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: {
          $set: { geo_service_zone: { name: zone.zoneName, id: zone._id } },
        },
      };
      try {
        const result = await this.$api.biz.update(params);
        this.setBiz(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>

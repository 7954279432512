<template lang="pug">
Page(title="Growth", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "POS", url: "/growth/pos" },
        { title: "Gift Card", url: "/growth/giftcard" },
        { title: "Delivery", url: "/growth/delivorder" },
        { title: "Biz Prepaid", url: "/growth/bizaccount" },
        { title: "Promotion", url: "/growth/promotion" },
        { title: "Merchant", url: "/growth/merchant" },
        { title: "Messaging", url: "/growth/messaging" },
        { title: "Consolidate", url: "/growth/consolidate" },
      ],
    };
  },
};
</script>

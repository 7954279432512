<template lang="pug">
v-dialog(width="500", v-model="dialog", scrollable)
  v-card(v-if="document")
    v-toolbar(flat, dense)
      .subtitle-2 Biz Document
    v-divider
    v-card-text
      v-row.my-2(v-if="isCreated")
        v-col
          v-btn(
            @click.stop="handleAcceptReject(1)",
            color="success",
            block,
            :loading="loading"
          ) Approve
        v-col
          v-btn(@click.stop="handleAcceptReject(-1)", color="error", block) Reject
      v-img.my-3(:src="file")
      v-btn(
        @click.stop="handleDelete()",
        color="error",
        block,
        :loading="loading"
      ) Delete Document
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      driver: null,
      document: null,
      dialog: false,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      loading: false,
    };
  },
  computed: {
    file() {
      if (this.document) {
        return this.mediaHost + this.document.file_path;
      }
      return "";
    },
    isCreated() {
      return this.document && this.document.status == 0;
    },
  },
  mounted() {
    EventBus.$on("show-biz-document", this.open);
  },
  destroyed() {
    EventBus.$off("show-biz-document", this.open);
  },
  methods: {
    ...mapActions(["updateBizDocument", "removeBizDocument"]),
    open(data) {
      this.document = data;
      this.dialog = true;
    },
    async handleAcceptReject(value) {
      if (!this.document) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.document._id },
        action: { $set: { status: value } },
      };
      this.document = await this.$api.b.document.update(params);
      this.updateBizDocument(this.document);
      this.loading = false;
    },
    async handleDelete() {
      if (!this.document) return;
      this.loading = true;
      try {
        await this.$api.b.document.delete(this.document._id);
        this.removeBizDocument(this.document);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div(v-if="order")
  v-btn.text-capitalize.mr-3(
    small,
    outlined,
    color="secondary",
    @click="open()"
  ) Add Charge
    v-icon(small, right) mdi-plus
  v-dialog(v-model="dialog", width="550")
    v-card
      v-card-title Add Charge
      v-card-text
        OrderHeader(:order="order")
        Transactions(@apply="applyTxn")
        v-form(@submit.prevent="save")
          v-btn-toggle.my-3(v-model="method", color="secondary")
            v-btn(value="card", small) Card
            v-btn(value="stripe", small) Stripe
          v-select(
            label="Check ID",
            v-if="hasSplitCheck",
            :items="order.splitChecks",
            item-text="_id",
            item-value="_id",
            v-model="checkId",
            @blur="$v.checkId.$touch()",
            :error-messages="checkIdErrors"
          )
          v-row(dense)
            v-col(cols="4")
              v-text-field(
                type="number",
                label="Base",
                v-model.number="base",
                @blur="$v.base.$touch()",
                :error-messages="baseErrors"
              )
            v-col(cols="4")
              v-text-field(type="number", label="Tip", v-model.number="tip")
            v-col(cols="4")
              v-text-field(
                label="Trans ID",
                v-model="transId",
                @blur="$v.transId.$touch()",
                :error-messages="transIdErrors"
              )
            v-col(cols="6")
              v-text-field(
                label="Adjust Amount",
                v-model.number="adjust_amount"
              )
            v-col(cols="6")
              v-combobox(
                label="Adjust Name",
                v-model="adjust_name",
                :items="adjust_name_items"
              )
          v-row(dense)
            v-col(cols="6")
              v-text-field(
                label="Brand",
                v-model="brand",
                @blur="$v.brand.$touch()",
                :error-messages="brandErrors"
              )
            v-col(cols="6")
              v-text-field(
                type="number",
                label="Last 4",
                v-model="last4",
                @blur="$v.last4.$touch()",
                :error-messages="last4Errors"
              )
          v-row(dense, v-if="method == 'card'")
            v-col(cols="6")
              v-combobox(
                label="Device Name",
                v-model="device",
                :items="deviceNames"
              )
            v-col(cols="6")
              v-combobox(
                type="url",
                label="Device Location",
                v-model="location",
                :items="deviceLocations"
              )
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="$v.$invalid"
          ) Add {{ total | currency }}
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minValue,
  numeric,
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import Transactions from "./Transactions";

export default {
  components: { Transactions },
  mixins: [validationMixin],
  props: ["order"],
  validations: {
    checkId: {
      required: requiredIf("hasSplitCheck"),
    },
    base: { required, minValue: minValue(0.01) },
    transId: { required },
    brand: { required },
    last4: { required, numeric },
  },
  data() {
    return {
      dialog: false,
      checkId: "",
      method: "card",
      base: 0,
      tip: 0,
      adjust_amount: 0,
      adjust_name: "",
      transId: "",
      brand: "Visa",
      last4: "",
      device: "",
      location: "",
      adjust_name_items: ["Non-Cash Adjustment"],
      approve: "",
      name: "",
      entry: "",
    };
  },
  computed: {
    ...mapGetters(["orders"]),
    hasSplitCheck() {
      return (
        this.order &&
        this.order.splitChecks &&
        this.order.splitChecks.length > 0
      );
    },
    checkIdErrors() {
      const errors = [];
      if (!this.$v.checkId.$dirty) return errors;
      !this.$v.checkId.required && errors.push("Required");
      return errors;
    },
    baseErrors() {
      const errors = [];
      if (!this.$v.base.$dirty) return errors;
      !this.$v.base.required && errors.push("Required");
      !this.$v.base.minValue && errors.push("Should be a positive value");
      return errors;
    },
    transIdErrors() {
      const errors = [];
      if (!this.$v.transId.$dirty) return errors;
      !this.$v.transId.required && errors.push("Required");
      return errors;
    },
    brandErrors() {
      const errors = [];
      if (!this.$v.brand.$dirty) return errors;
      !this.$v.brand.required && errors.push("Required");
      return errors;
    },
    last4Errors() {
      const errors = [];
      if (!this.$v.last4.$dirty) return errors;
      !this.$v.last4.required && errors.push("Required");
      !this.$v.last4.numeric && errors.push("Numeric");
      return errors;
    },
    devices() {
      return _.chain(this.orders)
        .pluck("payment")
        .pluck("charges")
        .flatten()
        .compact()
        .filter((o) => o.method == "card")
        .pluck("device")
        .uniq((o) => o.loc + o.name)
        .sortBy()
        .value();
    },
    deviceNames() {
      return this.devices?.map((o) => o.name) || [];
    },
    deviceLocations() {
      return (
        this.devices?.filter((o) => o.name == this.device)?.map((o) => o.loc) ||
        []
      );
    },
    total() {
      return this.base + this.adjust_amount + this.tip;
    },
  },
  methods: {
    ...mapActions(["updateOrder", "updateMissedTransaction"]),
    open() {
      this.dialog = true;
    },
    reset() {
      this.dialog = false;
      this.method = "card";
      this.checkId = "";
      this.base = 0;
      this.tip = 0;
      this.adjust_amount = 0;
      this.adjust_name = "";
      this.transId = "";
      this.brand = "Visa";
      this.last4 = "";
      this.device = "";
      this.location = "";
      this.approve = "";
      this.name = "";
      this.entry = "";
      this.$v.$reset();
    },
    async save() {
      if (!this.order) return;
      const charge = {
        method: this.method,
        base: this.base,
        amount: this.total,
        tip: this.tip,
        adjust_amount: this.adjust_amount,
        adjust_name: this.adjust_name,
        transId: this.transId,
        device: { name: this.device, loc: this.location },
        brand: this.brand,
        last4: this.last4,
        approve: this.approve,
        name: this.name,
        entry: this.entry,
      };
      let url = "/orders/charge";
      let params;
      if (this.hasSplitCheck) {
        params = { orderId: this.order._id, checkId: this.checkId, charge };
        url = "/orders/chargeCheck";
      } else {
        params = { orderId: this.order._id, charge };
        url = "/orders/charge";
      }
      try {
        const { data } = await this.axios.post(url, params);
        this.updateOrder(data);
        this.reset();
        this.$toast.success("Charge saved");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    applyTxn(txn) {
      if (txn.type != "01" && txn.type != "04") {
        return this.$toast.error("Invalid transaction type: " + txn.type);
      }
      let payment = this.order.payment;
      const refNum = txn.trace.refNum;
      const items = refNum.split("=");
      if (items.length > 1) {
        const index = parseInt(items[1]) - 1;
        this.checkId = this.order.splitChecks[index]._id;
        payment = this.order.splitChecks[index].payment;
      }
      this.method = "card";
      this.tip = txn.amount.tipAmount;
      const base = txn.amount.approveAmount - this.tip;
      const total =
        Math.round(
          (payment.total - payment.adjustment.total - payment.tip) * 100
        ) / 100;
      if (base > total) {
        this.base = total;
        this.adjust_amount = Math.round((base - total) * 100) / 100;
        this.adjust_name = "Non-Cash Adjustment";
      } else {
        this.base = base;
        this.adjust_amount = 0;
        this.adjust_name = "";
      }
      this.transId = txn.trace.transNum;
      this.brand = this.getBrand(txn);
      this.last4 = txn.account.account;
      this.device = txn.addInfo.sn;
      if (txn.ip) {
        this.location = "http://" + txn.ip + ":10009";
      } else {
        this.location = "";
      }
      this.approve = txn.host.authCode;
      this.name = txn.account.cardHolder;
      this.entry = this.getEntryMode(txn);
    },
    getBrand(txn) {
      const cartType = txn.account.cardType;
      switch (cartType) {
        case "01":
          return "Visa";
        case "02":
          return "MasterCard";
        case "03":
          return "AMEX";
        case "04":
          return "Discover";
        case "05":
          return "Diner Club";
        case "06":
          return "enRoute";
        case "07":
          return "JCB";
        case "08":
          return "RevolutionCard";
        case "09":
          return "VisaFleet";
        case "10":
          return "MasterCardFleet";
        case "11":
          return "FleetOne";
        case "12":
          return "Fleetwide";
        case "13":
          return "Fuelman";
        case "14":
          return "Gascard";
        case "15":
          return "Voyager";
        case "16":
          return "WrightExpress";
        case "17":
          return "Other";
        default:
          return "Other";
      }
    },
    getEntryMode(txn) {
      let entryMode = txn.account.entryMode;
      switch (entryMode) {
        case 0:
          return "Manual";
        case 1:
          return "Swipe";
        case 2:
          return "Contactless";
        case 3:
          return "Scanner";
        case 4:
          return "Chip";
        case 5:
          return "Chip Fall Back Swipe";
        default:
          return "N/A";
      }
    },
  },
};
</script>

<template lang="pug">
v-btn(@click="handleProceed()", :loading="loading") Close Orders
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    handleProceed() {
      confirm("Do you want to clean up closable orders?") && this.proceed();
    },
    async proceed() {
      this.loading = true;
      try {
        await this.$api.service.order.close();
        this.$toast.success("Close orders successful");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="item")
    v-card-title Charge Detail
    simple-table
      tbody
        tr
          td Stripe ID
          td.text-right {{ item.stripe_id }}
        tr
          td Type
          td.text-right {{ item.stripe_type }}
        tr
          td Category
          td.text-right {{ item.category }}
        AmountTableRow(name="Amount", :amount="item.amount")
        AmountTableRow(name="App Fee", :amount="item.application_fee_amount")
        AmountTableRow(name="Stripe Fee", :amount="item.stripe_fee")
        AmountTableRow(name="Net", :amount="item.net")
        AmountTableRow(name="Selflane Net", :amount="item.selflane_net")
        tr
          td On Behalf
          td.text-right {{ item.on_behalf_of }}
        tr
          td Status
          td.text-right {{ item.status }}
        tr
          td Created
          td.text-right {{ item.created | datetime }}
    v-card-actions
      v-btn(color="error", text, @click="cancel", :loading="loading") Cancel
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("show-stripe-charge", this.open);
  },
  destroyed() {
    EventBus.$off("show-stripe-charge", this.open);
  },
  methods: {
    open(data) {
      this.item = data;
      this.dialog = true;
    },
    async cancel() {
      this.loading = true;
      try {
        const params = { id: this.item._id };
        await this.$api.transaction.stripe.custom("cancel", params);
        this.dialog = false;
        this.$toast.success("Charge cancelled");
        this.$emit("canceled");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
Page(title="Charge Back")
  .s-vstack
    div
      YearSelector(@select="select")
    Table(:items="pending_items", title="Pending")
    v-row(wrap, dense)
      v-col(cols="12", md="6")
        Table(:items="favbiz_items", title="Favor Business")
      v-col(cols="12", md="6")
        Table(:items="favcustomer_items", title="Favor Customer")
  DisputeDialog
  InvoiceDetail
</template>

<script>
import moment from "moment-timezone";
import Table from "./Table";
import DisputeDialog from "/libs/components/Stripe/DisputeDialog/index.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: { Table, DisputeDialog },
  computed: {
    ...mapGetters(["chargebacks"]),
    pending_items() {
      return this.chargebacks?.filter(
        (o) => !o.resolution || o.resolution == "pending"
      );
    },
    favbiz_items() {
      return this.chargebacks?.filter(
        (o) => !o.resolution || o.resolution == "favor_business"
      );
    },
    favcustomer_items() {
      return this.chargebacks?.filter(
        (o) => !o.resolution || o.resolution == "favor_customer"
      );
    },
  },
  methods: {
    ...mapActions(["setChargebacks"]),
    async select(year) {
      const begin = moment().year(year).startOf("year").valueOf();
      const end = moment().year(year).endOf("year").valueOf();
      const params = { criteria: { created: { $gte: begin, $lt: end } } };
      const result = await this.$api.chargeback.list(params);
      this.setChargebacks(result);
    },
  },
};
</script>

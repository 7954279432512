<template lang="pug">
Page(title="Stripe Terminals", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Locations", url: "/stripe/terminals/locations" },
        { title: "Payments", url: "/stripe/terminals/payments" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-btn(
  icon,
  small,
  color="error",
  @click.stop="confirmDelete",
  :loading="loading"
)
  v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    item: { type: Object, default: () => {} },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    confirmDelete() {
      confirm("Are you sure you want to delete this item?") && this.deleteOne();
    },
    async deleteOne() {
      const id = this.item._id;
      if (!id) return;
      this.loading = true;
      try {
        const result = await this.$api.crm.contact.delete(id);
        this.$store.dispatch("crm/removeContact", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
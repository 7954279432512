<template lang="pug">
v-list-item(v-on="$listeners")
  v-list-item-content
    v-list-item-title
      .d-flex.flex-row.align-center
        v-icon.mr-2(small, :color="icon.color") {{ icon.name }}
        span {{ opportunity.company_name }}
    v-list-item-subtitle.caption
      div(v-if="opportunity.size") Monthly {{ opportunity.size }}
      Tags(:tags="opportunity.tags")
      div {{ opportunity.address | address("city, state") }}
      div {{ opportunity.sales_person_name }} | {{ organization }} | {{ opportunity.biz_name }}
      div {{ fromNow }} | last updated {{ opportunity.updated | datetime("smart") }}
      div {{ opportunity.status }}
      div {{ opportunity.notes }}
  v-list-item-action
    .d-flex.flex-row(v-if="opportunity.interactions")
      v-icon.mr-2(small) mdi-comment-outline
      .caption {{ opportunity.interactions }}
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Tags from "/libs/components/Dev/Issue/Tags";

export default {
  components: { Tags },
  props: {
    opportunity: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    fromNow() {
      const created = this.opportunity?.created;
      if (!created) return "";
      return moment(created).fromNow();
    },
    icon() {
      // status: open, won, lost
      let name, color;
      const status = this.opportunity?.status;
      if (status == "open") {
        name = "mdi-record-circle-outline";
        color = "green";
      } else if (status == "won") {
        name = "mdi-check-circle-outline";
        color = "purple";
      } else if (status == "lost") {
        name = "mdi-close-circle-outline";
        color = "red";
      } else {
        name = "mdi-help-box";
        color = "gray";
      }
      return { name, color };
    },
    organization() {
      const id = this.opportunity?.organization;
      if (!id) return "";
      return this.salesOrganizations.find((o) => o._id == id)?.name;
    },
  },
};
</script>
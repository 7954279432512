import Grouping from './index.vue'
import Chain from './Chain/index.vue'
import POI from './POI/index.vue'
import City from './City/index.vue'

export default [{
  path: '/biz/grouping', component: Grouping, meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'chain' },
    { path: 'chain', component: Chain },
    { path: 'poi', component: POI },
    { path: 'city', component: City }
  ]
}]
<template lang="pug">
div
  v-btn(v-if="visible", @click="dialog = true", small, outlined, color="error") Permanently closed
  v-dialog(v-model="dialog", width="400")
    v-card
      v-toolbar(flat)
        .subtitle-2 Are you sure this business would never be live again?
      v-card-text
        v-form(@submit.prevent="save")
          v-btn(type="submit", block, color="secondary", :loading="loading") Yes, I Confirm
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    visible() {
      const items = ["test", "permanently_closed"];
      return !items.includes(this.biz?.standing);
    },
  },
  methods: {
    ...mapActions(["updateBizAccount"]),
    async save() {
      this.loading = true;
      try {
        const params = { bizId: this.biz._id };
        const result = await this.$api.b.action.custom(
          "permanentClose",
          params
        );
        this.updateBizAccount(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to permanently close");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
Page(title="Service Desk", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [{ title: "Issues", url: "/salesforce/dev/issues" }],
    };
  },
};
</script>
import FileSaver from "file-saver";
import Papa from "papaparse";

class CSVGenerator {
  constructor() {
    this.data = []
  }
  addNewRow() {
    this.data.push([])
  }
  addRow(items) {
    if (!items?.length) return;
    this.data.push(items)
  }
  save(filename = "file", options = { header: false }) {
    if (!this.data?.length) return
    const csv = Papa.unparse(this.data, options);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(blob, filename + ".csv");
  }
}

export default CSVGenerator;
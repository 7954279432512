const state = {
  bouncingEmails: []
};

const getters = {
  bouncingEmails: state => state.bouncingEmails
};

const actions = {
  setBouncingEmails: ({ commit }, data) => { commit("setBouncingEmails", data); },
  addBouncingEmail: ({ commit }, data) => { commit('addBouncingEmail', data) },
  removeBouncingEmail: ({ commit }, data) => { commit("removeBouncingEmail", data); },
};

const mutations = {
  setBouncingEmails(state, data) { state.bouncingEmails = data; },
  addBouncingEmail(state, data) {
    const some = state.bouncingEmails.some(o => o._id === data._id);
    if (some) {
      state.bouncingEmails = state.bouncingEmails.map(o => {
        if (o._id === data._id) return data;
        return o;
      });
    } else {
      state.bouncingEmails.push(data)
    }
  },
  removeBouncingEmail(state, data) {
    state.bouncingEmails = state.bouncingEmails.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
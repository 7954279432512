<template lang="pug">
div
  v-dialog(v-model="dialog", width="800")
    Table(@select="select")
  DeviceDetail(ref="detail")
</template>

<script>
import Table from "./Table";
import DeviceDetail from "/libs/components/POS/DeviceDetail";
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Table, DeviceDetail },
  data() {
    return { dialog: false };
  },
  computed: {
    ...mapGetters(["pos_devices"]),
  },
  mounted() {
    EventBus.$on("show-selected-devices", this.open);
  },
  destroyed() {
    EventBus.$off("show-selected-devices", this.open);
  },
  methods: {
    ...mapActions(["setPOSDevices", "setBizAccounts"]),
    select(item) {
      this.$refs.detail.load(item);
    },
    async open(params) {
      if (!params) return;
      const result = await this.$api.posDevice.list(params);
      this.setPOSDevices(result);
      this.loadBizs();
      this.dialog = true;
    },
    async loadBizs() {
      if (!this.pos_devices) return;
      const ids = this.pos_devices.map((o) => o.biz);
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
    },
  },
};
</script>
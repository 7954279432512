<template lang="pug">
v-row(wrap, dense)
  v-col(cols="12", sm="6", md="4", lg="3", v-for="o in orders", :key="o._id")
    Cell(:order="o")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["orders"],
};
</script>

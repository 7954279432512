<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="stateTax")
    v-toolbar(dense, flat)
      .overline {{ title }}
    v-divider
    v-card-text
      v-form.mt-5(@submit.prevent="save()")
        v-row(dense)
          v-col(cols="6")
            v-text-field(v-model="stateTax.country", label="Country", disabled)
          v-col(cols="6")
            v-select(
              v-model="stateTax.state",
              :items="usStates",
              label="State"
            )
        v-text-field(v-model="stateTax.rate", label="Rate", suffix="%")
        v-btn.my-3(block, color="secondary", type="submit") Save
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialog: false,
      stateTax: null,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      usStates: [],
    };
  },
  computed: {
    title() {
      if (this.stateTax && this.stateTax._id) return "Edit State Tax Rate";
      else return "Create State Tax Rate";
    },
  },
  mounted() {
    this.loadUSStates();
  },
  methods: {
    open(data) {
      if (!data) {
        this.stateTax = { country: "US", state: "", rate: 0 };
      } else {
        this.stateTax = JSON.parse(JSON.stringify(data));
      }
      this.dialog = true;
    },
    save() {
      if (!this.stateTax) return;
      if (!this.stateTax._id) this.create();
      else this.update();
    },
    async update() {
      this.loading = true;
      try {
        const action = { $set: this.stateTax };
        const result = await this.$api.catalog.stateTax.put(
          this.stateTax._id,
          action
        );
        this.$store.dispatch("catalog/updateStateTax", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    async create() {
      this.loading = true;
      try {
        const result = await this.$api.catalog.stateTax.create(this.stateTax);
        this.$store.dispatch("catalog/addStateTax", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.loading = false;
    },
    async loadUSStates() {
      const url = this.mediaHost + "public/json/usStates.json";
      const response = await window.axios2.get(url);
      this.usStates = response.data;
    },
  },
};
</script>

<template lang="pug">
v-btn(icon, small, color="error", :loading="loading", @click="submit")
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["removePunchcard"]),
    async submit() {
      if (!this.item) return;
      this.loading = true;
      try {
        const result = await this.$api.punchcard.delete(this.item._id);
        this.removePunchcard(result);
        this.$toast.success("Deleted");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
IconBtn(
  @click.stop="invite",
  :loading="loading",
  title="Invite",
  icon="mdi-send",
  v-if="access.status === 'invited'",
  :small="false",
  :xSmall="true"
)
</template>

<script>
export default {
  name: "EnterpriseAccessInvite",
  props: ["access"],
  data() {
    return { loading: false };
  },
  methods: {
    async invite() {
      if (!this.access) return;
      this.loading = true;
      const params = { id: this.access._id };
      try {
        await this.$api.entp.access.custom("invite", params);
        const result = await this.$api.entp.access.retrieve({
          criteria: { _id: this.access._id },
        });
        this.$store.dispatch("entp/updateAccess", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-text-field.mb-3(
    v-model="search",
    solo,
    flat,
    label="Search",
    dense,
    hide-details
  )
  v-data-table(
    :items="chains",
    :headers="headers",
    :search="search",
    @click:row="show",
    role="button",
    sort-by="name",
    mobile-breakpoint=0
  )
    template(v-slot:item.logo="{ item }")
      v-avatar
        v-img(:src="mediaHost + item.logo", v-if="item.logo")
        v-icon(v-else) mdi-image-outline
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      headers: [
        { text: "Logo", value: "logo", filterable: false },
        { text: "Name", value: "name" },
        { text: "URL", value: "url" },
        { text: "Level", value: "level" },
        { text: "States", value: "states" },
        { text: "Country", value: "country", align: "end" },
      ],
      search: "",
    };
  },
  computed: {
    ...mapGetters("entp", ["chains"]),
  },
  methods: {
    ...mapActions("entp", ["setChain"]),
    show(item) {
      this.$store.dispatch("entp/setChain", item);
    },
  },
};
</script>
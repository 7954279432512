<template lang="pug">
v-btn(
  icon,
  small,
  color="secondary",
  v-if="link",
  :href="link",
  target="_blank"
)
  v-icon(small) mdi-link
</template>

<script>
export default {
  props: ["filename"],
  computed: {
    link() {
      if (!this.filename) return null;
      const items = this.filename.split("-");
      const id = items.find((o) => o.startsWith("acct_"));
      if (id) {
        return "https://dashboard.stripe.com/connect/accounts/" + id;
      }
      return null;
    },
  },
};
</script>
<template lang="pug">
div
  div(v-for="(group, index) in groups", :key="index")
    .subtitle-2.mb-3 {{ group.zone }}
    Table.mb-3(title="Orders", :days="group.days")
    Table.mb-3(title="Drivers", :days="group.days", drivers="1")
    LaborExpectation(:days="group.days")
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";
import Table from "./Table";
import LaborExpectation from "./LaborExpectation";

export default {
  components: { Table, LaborExpectation },
  data() {
    return { items: [] };
  },
  computed: {
    groups() {
      const data = _.chain(this.items)
        .groupBy("zone")
        .map((list, k) => {
          const mappedDay = _.chain(list)
            .map((o) => {
              return {
                day: moment(String(o.date), "YYYYMMDD").day(),
                countByHour: o.countByHour,
              };
            })
            .groupBy("day")
            .map((list, k) => {
              const countByHour = _.chain(list)
                .pluck("countByHour")
                .flatten()
                .groupBy("hour")
                .map((list, hour) => {
                  const count = list.reduce((a, b) => a + b.count, 0);
                  const drivers = list.reduce((a, b) => a + b.drivers, 0);
                  return {
                    hour,
                    count: Math.round((count / list.length) * 10) / 10,
                    drivers: Math.round((drivers / list.length) * 10) / 10,
                  };
                })
                .sortBy("hour")
                .value();
              const expanded = _.map(_.range(7, 24), (hour) => {
                let count = 0;
                let drivers = 0;
                const found = countByHour.find((item) => item.hour == hour);
                if (found) {
                  count = found.count;
                  drivers = found.drivers;
                }
                return { hour, count, drivers };
              });
              return { day: k, countByHour: expanded };
            })
            .sortBy("day")
            .value();
          return {
            zone: k,
            days: mappedDay,
          };
        })
        .value();
      return data;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const begin = parseInt(moment().subtract(29, "day").format("YYYYMMDD"));
      const end = parseInt(moment().subtract(1, "day").format("YYYYMMDD"));
      const params = { criteria: { date: { $gte: begin, $lte: end } } };
      const res = await this.axios.post("/delivery/dailysummary/list", params);
      this.items = res.data;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="600", scrollable)
  v-card(v-if="invoice")
    v-toolbar(flat, dense)
      .subtitle-2 {{ invoice.organization.name }}
      v-spacer
      .subtitle-2 {{ invoice._id }}
    Items(:items="invoice.items")
    v-card-text
      Payment(:invoice="invoice")
      Pay(:item="invoice")
      DeleteOne(:item="invoice")
</template>

<script>
import { EventBus } from "@/event-bus";
import Items from "./Items";
import Payment from "./Payment";
import Pay from "./Pay";
import DeleteOne from "./DeleteOne";

export default {
  components: { Items, Payment, Pay, DeleteOne },
  data() {
    return {
      dialog: false,
      invoice: null,
    };
  },
  mounted() {
    EventBus.$on("show-org-invoice", this.open);
  },
  destroyed() {
    EventBus.$off("show-org-invoice", this.open);
  },
  methods: {
    async open(invoice_id) {
      if (!invoice_id) return;
      try {
        const params = { invoice_id: invoice_id };
        this.invoice = await this.$api.salesforce.invoice.retrieve(params);
        this.dialog = true;
      } catch (err) {
        this.$toast.error(err.response?.data || err.response);
      }
    },
  },
};
</script>
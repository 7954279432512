const state = {
  partnerPayouts: [],
};

const getters = {
  partnerPayouts: state => state.partnerPayouts,
};

const actions = {
  setPartnerPayouts: ({ commit }, data) => { commit("setPartnerPayouts", data); },
  updatePartnerPayout: ({ commit }, data) => { commit("updatePartnerPayout", data); }
};

const mutations = {
  setPartnerPayouts(state, data) { state.partnerPayouts = data; },
  updatePartnerPayout(state, data) {
    state.partnerPayouts = state.partnerPayouts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
.d-flex.flex-row.align-center
  TimeSelector.selector-box(v-model="value[0]", :min="0")
  span.mx-2 ~
  TimeSelector.selector-box(v-model="value[1]", :min="value[0]")
</template>

<script>
/**
 * BirthdayPicker
 * @event input - { mm: 0, dd: 0 }
 */
import TimeSelector from "./TimeSelector";

export default {
  name: "TimeRangePicker",
  components: { TimeSelector },
  props: {
    value: {
      type: Array,
      default: () => {
        return [480, 1200]; // 8am to 8pm
      },
      required: true,
    },
  },
  data() {
    return {
      start: this.value[0],
      end: this.value[1],
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.start = this.value[0];
        this.end = this.value[1];
      },
    },
    start(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", [this.start, this.end]);
      this.$emit("changed");
    },
    end(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", [this.start, this.end]);
      this.$emit("changed");
    },
  },
};
</script>

<style lang="sass" scoped>
.selector-box
  min-width: 100px
  max-width: 160px
</style>

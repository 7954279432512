<template lang="pug">
v-dialog(width="500", v-model="dialog", scrollable)
  v-card(v-if="document")
    v-toolbar(flat, dense)
      .subtitle-2 Driver & Document
    v-divider
    v-card-text
      v-form(@submit.prevent="saveDriver()", v-if="driver")
        v-text-field(v-model="driver.name", label="Name")
        v-row(dense)
          v-col
            v-text-field(v-model.number="driver.dob.yyyy", label="DOB YYYY")
          v-col
            v-text-field(v-model.number="driver.dob.mm", label="DOB mm")
          v-col
            v-text-field(v-model.number="driver.dob.dd", label="DOB DD")
        v-switch(
          label="Alcohol Status",
          v-model="driver.alcohol_license.status"
        )
        v-row(dense)
          v-col
            v-text-field(
              v-model.number="driver.alcohol_license.expiry.yyyy",
              label="Expiry YYYY"
            )
          v-col
            v-text-field(
              v-model.number="driver.alcohol_license.expiry.mm",
              label="Expiry mm"
            )
          v-col
            v-text-field(
              v-model.number="driver.alcohol_license.expiry.dd",
              label="Expiry DD"
            )
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
      v-row.my-2(v-if="isCreated")
        v-col
          v-btn(
            @click.stop="handleAcceptReject(1)",
            color="success",
            block,
            :loading="loading"
          ) Approve
        v-col
          v-btn(@click.stop="handleAcceptReject(-1)", color="error", block) Reject
      v-img.my-3(:src="file")
      div
        a(:href="file", target="_blank") Direct Link
      v-btn(
        @click.stop="handleDelete()",
        color="error",
        block,
        :loading="loading"
      ) Delete Document
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      driver: null,
      document: null,
      dialog: false,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      loading: false,
    };
  },
  computed: {
    file() {
      if (this.document) {
        return this.mediaHost + this.document.file_path;
      }
      return "";
    },
    isCreated() {
      return this.document && this.document.status == 0;
    },
  },
  mounted() {
    EventBus.$on("show-document", this.open);
  },
  destroyed() {
    EventBus.$off("show-document", this.open);
  },
  methods: {
    ...mapActions(["updateDriverDocument", "removeDriverDocument"]),
    open(data) {
      this.document = data;
      this.dialog = true;
      this.loadDriver();
    },
    async handleAcceptReject(value) {
      if (!this.document) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.document._id },
        action: { $set: { status: value } },
      };
      const { data } = await this.axios.post(
        "/driver/documents/update",
        params
      );
      this.updateDriverDocument(data);
      this.document = data;
      this.loading = false;
    },
    async loadDriver() {
      const params = {
        criteria: { user: this.document.user },
      };
      const { data } = await this.axios.post(
        "/driver/accounts/retrieve",
        params
      );
      if (!data.dob) {
        data.dob = { yyyy: null, mm: null, dd: null };
      }
      if (!data.alcohol_license.expiry) {
        data.alcohol_license.expiry = { yyyy: null, mm: null, dd: null };
      }
      this.driver = data;
    },
    async saveDriver() {
      if (!this.driver) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.driver._id },
        action: {
          $set: {
            dob: this.driver.dob,
            alcohol_license: this.driver.alcohol_license,
          },
        },
      };
      const { data } = await this.axios.post("/driver/accounts/update", params);
      this.driver = data;
      this.loading = false;
    },
    async handleDelete() {
      if (!this.document) return;
      this.loading = true;
      const params = { docId: this.document._id };
      try {
        await this.axios.post("/driver/documents/deleteOne", params);
        this.removeDriverDocument(this.document);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  dense,
  mobile-breakpoint=0,
  @click:row="edit",
  role="button"
)
  template(v-slot:item.us_price="{ item }")
    span {{ item.us_price | currency }}; {{ (((item.us_price - item.us_value) / item.us_value) * 100) | number }}% off
  template(v-slot:item.ca_price="{ item }")
    span {{ item.ca_price | currency }}; {{ (((item.ca_price - item.ca_value) / item.ca_value) * 100) | number }}% off
  template(v-slot:item.us_value="{ item }")
    span {{ item.us_value | currency }}
  template(v-slot:item.ca_value="{ item }")
    span {{ item.ca_value | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Detail", value: "detail" },
        { text: "US Price", value: "us_price" },
        { text: "CA Price", value: "ca_price" },
        { text: "US Value", value: "us_value" },
        { text: "CA Value", value: "ca_value" },
      ],
    };
  },
  computed: {
    ...mapGetters(["service_plans"]),
    items() {
      return this.service_plans?.toSorted((a, b) => a.level - b.level) ?? [];
    },
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>
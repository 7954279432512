<template lang="pug">
div
  v-list-item(@click.stop="open()") Create Invoice
  v-dialog(width="400", v-model="dialog")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Create an Invoice
      v-card-text(v-if="bizservice")
        p {{ bizservice.name }}
        MonthSelector(@select="selectMonth")
        v-btn.mt-3(
          small,
          depressed,
          rounded,
          color="secondary",
          @click="create()",
          :loading="loading"
        ) Create
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["bizservice"],
  data() {
    return {
      dialog: false,
      month: 0,
      year: 0,
      loading: false,
    };
  },
  methods: {
    ...mapActions(["addInvoice"]),
    open() {
      this.dialog = true;
    },
    selectMonth(data) {
      if (!data || !data.name) return;
      const items = data.name.split("-");
      if (!items || items.length != 2) return;
      this.year = parseInt(items[0]);
      this.month = parseInt(items[1]);
    },
    async create() {
      if (!this.bizservice) return;
      if (!this.year || !this.month) return;
      this.loading = true;
      const params = {
        bizId: this.bizservice.biz,
        period: { year: this.year, month: this.month },
      };
      try {
        const result = await this.$api.invoice.create(params);
        this.addInvoice(result);
        this.dialog = false;
        this.$toast.success("Invoice created");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-icon(x-small, :color="color") mdi-circle
</template>

<script>
export default {
  props: {
    biz: { type: Object, default: null },
  },
  computed: {
    color() {
      if (!this.biz) return "grey";
      if (this.biz.standing == "test") return "grey";
      if (this.biz.standing == "staging") return "blue";
      if (this.biz.standing == "live") return "green";
      if (this.biz.standing == "lost") return "red";
      if (this.biz.standing == "permanently_closed") return "black";
      if (this.biz.standing == "temporarily_closed") return "orange";
      return "grey";
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="event")
    v-card-title {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(v-model="event.name", label="Name")
        v-text-field(v-model="event.detail", label="Detail")
        v-text-field(v-model="event.link", label="Link")
        v-select(
          v-model="event.categories",
          :items="categories",
          multiple,
          chips,
          label="Categories",
          item-text="name",
          item-value="_id"
        )
        v-select(v-model="event.access", :items="access_items", label="Access")
        DateFieldPicker(v-model="date")
      v-card-actions
        v-btn(:loading="loading", block, color="secondary", type="submit") Save
</template>

<script>
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      event: null,
      date: new Date().toISOString().substring(0, 10),
      access_items: ["public", "user", "admin"],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["timeline_categories"]),
    categories() {
      return this.timeline_categories.toSorted((a, b) =>
        a.name?.localeCompare(b.name)
      );
    },
    title() {
      return this.event?._id ? "Edit Event" : "New Event";
    },
  },
  methods: {
    ...mapActions(["updateTimelineEvent", "addTimelineEvent"]),
    open(event) {
      this.event = JSON.parse(JSON.stringify(event));
      this.date = new Date(this.event.date).toISOString().substring(0, 10);
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      this.event.date = moment(this.date).valueOf();
      try {
        if (this.event?._id) {
          const params = {
            criteria: { _id: this.event._id },
            action: {
              $set: {
                name: this.event.name,
                detail: this.event.detail,
                link: this.event.link,
                categories: this.event.categories,
                access: this.event.access,
                date: this.event.date,
              },
            },
          };
          const result = await this.$api.timeline.event.update(params);
          this.updateTimelineEvent(result);
        } else {
          const result = await this.$api.timeline.event.create(this.event);
          this.addTimelineEvent(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>
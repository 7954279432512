const state = {
  stripeAccounts: []
}

const getters = {
  stripeAccounts: state => state.stripeAccounts,
}

const actions = {
  setStripeAccounts: ({ commit }, data) => { commit('setStripeAccounts', data) },
  updateStripeAccount: ({ commit }, data) => { commit('updateStripeAccount', data) }
}

const mutations = {
  setStripeAccounts(state, data) {
    state.stripeAccounts = data
  },
  updateStripeAccount(state, data) {
    state.stripeAccounts = state.stripeAccounts.map(o => {
      if (o.id === data.id) return data
      return o
    })
  }
}

export default { state, getters, actions, mutations }
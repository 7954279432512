<template lang="pug">
div
  v-card-text
    .d-flex.flex-row.align-center
      .subtitle-2 Card Pointe Integration
      v-spacer
      IconBtn(@click="handleEdit", small, text)
    .body-2 MID: {{ app_mid }}
    Terminal(:mid="app_mid")
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title Card Pointe Settings
      v-form(@submit.prevent="submit")
        v-card-text
          v-text-field(v-model="mid", label="MID")
        v-card-actions
          v-btn(color="secondary", type="submit", :loading="loading", block) Submit
</template>

<script>
import { mapActions } from "vuex";
import Terminal from "/libs/components/CardPointe/Terminal";

export default {
  components: { Terminal },
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      mid: "",
    };
  },
  computed: {
    app() {
      return this.item?.apps?.find((app) => app.name === "card_pointe");
    },
    app_mid() {
      return this.app?.meta?.find((meta) => meta.key === "mid")?.value;
    },
  },
  methods: {
    ...mapActions(["setBizService"]),
    handleEdit() {
      this.mid = this.app_mid;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      let criteria;
      let action;
      if (this.app) {
        criteria = { _id: this.item._id, "apps.name": "card_pointe" };
        action = {
          $set: {
            "apps.$.meta": [{ key: "mid", value: this.mid }],
          },
        };
      } else {
        criteria = { _id: this.item._id };
        action = {
          $push: {
            apps: {
              name: "card_pointe",
              meta: [{ key: "mid", value: this.mid }],
            },
          },
        };
      }
      try {
        const result = await this.$api.service.account.update({
          criteria,
          action,
        });
        this.setBizService(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  v-list-item(@click.stop="handleRefund()", v-if="valid") Refund Tip
  v-dialog(v-model="dialog", width="300")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Refund Tip
      v-card-text
        v-text-field(v-model="amount", type="number", label="New Tip Amount")
        v-btn(
          @click="submit",
          :disabled="amount < 0",
          block,
          :loading="loading",
          color="secondary"
        ) Submit
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      amount: 0,
      loading: false,
    };
  },
  computed: {
    valid() {
      return (
        this.order?.proxy != "Test" &&
        !this.order.deliv &&
        this.order.status >= 0
      );
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleRefund() {
      this.dialog = true;
      this.amount = 0;
    },
    async submit() {
      if (!this.order) return;
      if (this.amount < 0) return;
      const params = { orderId: this.order._id, amount: this.amount };
      this.loading = true;
      try {
        const { data } = await this.axios.post("/orders/editTip", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

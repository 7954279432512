<template lang="pug">
StandardChart(
  :input="chartData",
  chartType="bar",
  :stacked="true",
  :legend="true",
  :gridLines="true",
  :loading="loading",
  max-width="100%"
)
  template(v-slot:header)
    Regenerate(:bizId="bizId", @done="load")
  v-card-text
    .caption {{ subtitle }}
</template>

<script>
import _ from "underscore";
import Regenerate from "./Regenerate";

export default {
  components: { Regenerate },
  props: {
    bizId: { type: String, default: null },
    title: { type: String, default: null },
  },
  data() {
    return {
      summary: [],
      stacked: true,
      loading: false,
    };
  },
  computed: {
    subtitle() {
      if (!this.summary) return null;
      const currency = this.$options.filters.currency;
      let text =
        currency(this.total) +
        " (in-house: " +
        currency(this.local) +
        ", selflane: " +
        currency(this.online);
      if (this.third_party) {
        text += ", third party: " + currency(this.third_party);
      }
      text += ")";
      return text;
    },
    chartData() {
      if (!this.summary) return {};
      const local = {
        label: "In House",
        backgroundColor: "#A0C2FA",
        data: _.pluck(this.summary, "local"),
        stack: "Stack 0",
      };
      const online = {
        label: "Selflane",
        backgroundColor: "#5469D4",
        data: _.pluck(this.summary, "online"),
        stack: "Stack 0",
      };
      const third_party = {
        label: "Third Party",
        backgroundColor: "#68B7DC",
        data: _.pluck(this.summary, "third_party"),
        stack: "Stack 0",
      };
      let title = "30-Day Net Sales";
      if (this.title) title += this.title + " - " + title;
      return {
        title: title,
        labels: _.pluck(this.summary, "day"),
        datasets: [third_party, online, local],
        isCurrency: true,
      };
    },
    third_party() {
      return this.getTotal("third_party");
    },
    online() {
      return this.getTotal("online");
    },
    local() {
      return this.getTotal("local");
    },
    total() {
      return this.getTotal("total");
    },
  },
  watch: {
    bizId() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    getTotal(key) {
      return this.summary.reduce((acc, cur) => acc + cur[key], 0);
    },
    async load() {
      if (!this.bizId) return;
      this.loading = true;
      try {
        const params = { bizId: this.bizId };
        this.summary = await this.$api.order.custom("summarize", params);
      } catch (e) {
        //
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
Page(title="Driver", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Summary", url: "/driver/accounts/summary" },
        { title: "Stats", url: "/driver/accounts/stats" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-card(v-if="data")
  v-card-title {{ title }}
  v-card-text
    v-form(@submit.prevent="save")
      v-text-field(
        name="name",
        label="Name",
        v-model.trim="data.name",
        :error-messages="nameErrors",
        @blur="$v.data.name.$touch()"
      )
      v-text-field(
        name="group",
        label="Group",
        v-model.trim="data.group",
        :error-messages="groupErrors",
        @blur="$v.data.group.$touch()"
      )
      v-text-field(
        name="website",
        label="Website",
        v-model.trim="data.website"
      )
      v-btn(block, :disabled="$v.$invalid", type="submit") save
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: ["source", "done"],
  validations: {
    data: {
      name: { required },
      group: { required },
    },
  },
  data() {
    return { data: null };
  },
  computed: {
    title() {
      return this.data._id ? "Edit Source" : "Add Source";
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.data.name.$dirty) return errors;
      !this.$v.data.name.required && errors.push("Name is required");
      return errors;
    },
    groupErrors() {
      const errors = [];
      if (!this.$v.data.group.$dirty) return errors;
      !this.$v.data.group.required && errors.push("Group is required");
      return errors;
    },
  },
  watch: {
    source: function (val) {
      if (val) {
        this.data = JSON.parse(JSON.stringify(val));
      }
    },
  },
  methods: {
    ...mapActions(["updateExpSource", "addExpSource"]),
    async save() {
      if (!this.data) return;
      try {
        if (this.data._id) {
          const params = {
            criteria: { _id: this.data._id },
            action: { $set: this.data },
          };
          const result = await this.$api.expense.source.update(params);
          this.updateExpSource(result);
        } else {
          const result = await this.$api.expense.source.create(this.data);
          this.addExpSource(result);
        }
        this.done();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<template lang="pug">
.py-3
  StatsGrowth(:api="api", :title="title")
  .subtitle-2.my-3 New Requests
  Table.my-3(:items="items")
  Detail
</template>

<script>
import Table from "./Table";
import Detail from "./Detail";

export default {
  components: { Table, Detail },
  data() {
    return {
      api: "/bizs/request/stats/growth",
      title: "Request Stats",
      items: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: { status: "new" } };
      this.items = await this.$api.b.request.list(params);
    },
  },
};
</script>

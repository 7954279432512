<template lang="pug">
StandardChart(:input="chartData", chartType="bar", showDownload)
  v-data-table(
    :headers="headers",
    :items="items",
    mobile-breakpoint=0,
    dense,
    @click:row="show",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.status="{ item }")
      span {{ item.status == 1 ? "Paid" : "Not Paid" }}
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      headers: [
        { text: "Date", value: "created" },
        { text: "Organization", value: "organization.name" },
        { text: "Discount", value: "payment.discount" },
        { text: "Total", value: "payment.total" },
        { text: "Status", value: "status", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["org_invoices"]),
    items() {
      return this.org_invoices.toSorted((a, b) => b.created - a.created);
    },
    total() {
      const value = this.org_invoices.reduce((a, b) => a + b.payment.total, 0);
      return Math.round(value * 100) / 100;
    },
    paid() {
      const value = this.org_invoices.reduce(
        (a, b) => a + (b.payment.charge?.amount || 0),
        0
      );
      return Math.round(value * 100) / 100;
    },
    chartData() {
      // group invoices by month
      const data = this.org_invoices.reduce((acc, item) => {
        const month = new Date(item.created).getMonth();
        acc[month] = acc[month] || 0;
        acc[month] += item.payment.total;
        return acc;
      }, {});
      // fill up missing months
      const months = Array.from({ length: 12 }, (_, i) => i);
      const values = months.map((month) => data[month] || 0);
      return {
        title: `Org Invoices (total: ${this.total} | paid: ${this.paid})`,
        labels: months.map((month) =>
          new Date(0, month).toLocaleString("en", { month: "short" })
        ),
        values,
        isCurrency: true,
      };
    },
  },
  methods: {
    show(item) {
      EventBus.$emit("show-org-invoice", item?._id);
    },
  },
};
</script>
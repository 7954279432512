<template lang="pug">
v-btn(x-small, outlined, rounded, color="secondary", @click="download") PDF
</template>

<script>
import jsPDF from "jspdf";
import _ from "underscore";
import moment from "moment-timezone";

export default {
  props: ["items"],
  methods: {
    download() {
      let doc = new jsPDF({ format: "letter" });
      let x = 19;
      let y = 25;
      let pageHeight = doc.internal.pageSize.height;
      addTitle("Delivery: Top 20 Business " + moment().format("L"));
      addTableLine(["Name", "Count", "Change"]);
      _.chain(this.items)
        .sortBy((o) => -o.count)
        .first(20)
        .each((o) => {
          addTableLine([o.bizName, String(o.count), String(o.count_diff)]);
        });
      doc.addPage();
      y = 25;
      addTitle("Delivery: Top 10 Business Moved Up");
      addTableLine(["Name", "Count", "Change"]);
      _.chain(this.items)
        .sortBy((o) => -o.count_diff)
        .first(10)
        .each((o) => {
          addTableLine([o.bizName, String(o.count), String(o.count_diff)]);
        });
      doc.addPage();
      y = 25;
      addTitle("Delivery: Top 10 Business Moved Down");
      addTableLine(["Name", "Count", "Change"]);
      _.chain(this.items)
        .sortBy((o) => o.count_diff)
        .first(10)
        .each((o) => {
          addTableLine([o.bizName, String(o.count), String(o.count_diff)]);
        });

      doc.save("delivery_4week_performance.pdf");

      function addTitle(text) {
        doc.setFontSize(12);
        doc.text(text, x, y);
        doc.setFontSize(10);
        nextLine();
        addLine(8);
      }

      function addTableLine(items) {
        _.each(items, (o) => {
          doc.text(o, x, y);
          nextCell(80);
        });
        nextLine(6);
      }

      function addLine(height) {
        doc.line(x, y, x + 180, y);
        nextLine(height);
      }
      function nextLine(height) {
        x = 19;
        if (y + 25 > pageHeight) {
          doc.addPage();
          y = 25;
          return;
        }
        if (height) y += height;
        else y += 8;
        x = 19;
      }
      function nextCell(width) {
        if (width) x += width;
        else x += 40;
      }
    },
  },
};
</script>
<template lang="pug">
.inline-block
  v-icon(x-small) {{ deviceIcon }}
  v-icon(x-small) {{ typeIcon }}
  div {{ item.orderNumber }}
  div {{ item?.seller?.name | truncate(18) }}
</template>

<script>
export default {
  props: ["item"],
  computed: {
    deviceIcon() {
      if (this.item.proxy == "Vue") {
        return "fa fa-globe";
      } else if (this.item.proxy == "iOS-Customer") {
        return "fab fa-apple";
      } else {
        return "mdi-help";
      }
    },
    typeIcon() {
      if (this.item.type == "delivery") {
        return "fa fa-truck";
      } else if (this.item.type == "pickup") {
        return "fa fa-shopping-basket";
      } else if (this.item.type == "dinein") {
        return "fa fa-home";
      } else if (this.item.type == "curbside") {
        return "mdi-sign-text";
      } else {
        return "mdi-help";
      }
    },
  },
};
</script>

<style lang="sass" scoped>
// flex gap 4px
.inline-block
  display: flex
  align-items: center
  gap: 4px
  white-space: nowrap
  overflow: hidden
</style>
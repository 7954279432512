<template lang="pug">
v-btn(@click="save", color="secondary", x-small, outlined, :loading="loading") Set {{ title }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  // date format: "2020-08-01"
  props: ["item", "value"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    title() {
      if (this.value == 1) return "Fulfilled";
      if (this.value == -1) return "False Alarm";
      return "Unknown";
    },
  },
  methods: {
    ...mapActions(["updateMissedTransaction"]),
    async save() {
      if (!this.item || !this.value) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.item._id },
        action: { $set: { status: this.value } },
      };
      try {
        const result = await this.$api.transaction.missed.update(params);
        this.updateMissedTransaction(result);
        this.$toast.success(`Updated to ${this.title}`);
        this.$emit("updated", result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
StandardChart(:input="chartData", v-if="summary")
</template>

<script>
import _ from "underscore";

export default {
  name: "MemberPeopleChart",
  props: ["summary"],
  computed: {
    chartData() {
      if (!this.summary) return null;
      const months = _.map(
        this.summary,
        (o) => String(Math.round(o.month / 100)) + "/" + String(o.month % 100)
      );
      return {
        title: "Active Member",
        labels: months,
        values: _.pluck(this.summary, "people"),
        isCurrency: false,
      };
    },
  },
};
</script>
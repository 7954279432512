<template lang="pug">
div(v-if="biz")
  v-btn(icon, small, :color="google_color", @click="toggle_google()")
    v-icon(small) mdi-google
  v-btn(icon, small, :color="website_color", @click="toggle_website()")
    v-icon(small) mdi-web
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["biz"],
  computed: {
    google_color() {
      if (this.biz.google_connect) return "success";
      else return "red";
    },
    website_color() {
      if (this.biz.website_connect) return "success";
      else return "red";
    },
  },
  methods: {
    ...mapActions(["updateBizAccount"]),
    async toggle_google() {
      if (!this.biz) return;
      const value = this.biz.google_connect ? false : true;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { google_connect: value } },
      };
      const result = await this.$api.biz.update(params);
      this.updateBizAccount(result);
    },
    async toggle_website() {
      if (!this.biz) return;
      const value = this.biz.website_connect ? false : true;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { website_connect: value } },
      };
      const result = await this.$api.biz.update(params);
      this.updateBizAccount(result);
    },
  },
};
</script>
import Home from './index.vue'
import Dashboard from './Dashboard/index.vue'
import Monthly from './Monthly/index.vue'
import Map from './Map/index.vue'
import List from './List/index.vue'


export default [{
  path: '/biz/growth', component: Home, meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'dashboard' },
    { path: 'dashboard', component: Dashboard },
    { path: 'monthly', component: Monthly },
    { path: 'map', component: Map },
    { path: 'list', component: List }
  ]
}]
<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  @click:row="show",
  role="button",
  sort-by="created",
  sort-desc,
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Created", value: "created", filterable: false },
        { text: "Title", value: "title" },
        { text: "Message", value: "message", sortable: false },
      ],
    };
  },
  methods: {
    show(item) {
      EventBus.$emit("show-biz-request-detail", item);
    },
  },
};
</script>
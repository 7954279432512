<template lang="pug">
simple-table
  thead
    tr
      th Country
      th State
      th.text-right Rate
  tbody
    tr(
      v-for="(item, index) in items",
      :key="index",
      @click="handleEdit(item)",
      role="button"
    )
      td {{ item.country }}
      td {{ item.state }}
      td.text-right {{ item.rate }}%
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters("catalog", ["stateTaxes"]),
    items() {
      return _.chain(this.stateTaxes).sortBy("state").sortBy("country").value();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.catalog.stateTax.list(params);
      this.$store.dispatch("catalog/setStateTaxes", result);
    },
    handleEdit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>

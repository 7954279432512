<template lang="pug">
div
  BizSelector(@select="select")
  List(:reviews="reviews")
</template>

<script>
import List from "/libs/components/Review/List";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { List },
  data() {
    return { selectedBiz: null };
  },
  computed: {
    ...mapGetters(["reviews"]),
  },
  mounted() {
    this.setReviews([]);
  },
  methods: {
    ...mapActions(["setReviews"]),
    async select(biz) {
      if (!biz) return;
      const params = { criteria: { biz: biz._id }, limit: 100 };
      const result = await this.$api.review.list(params);
      this.setReviews(result);
    },
  },
};
</script>

<template lang="pug">
div
  v-text-field.mx-5(v-model="searchText", label="Search", clearable)
  v-data-table(
    :items="influencers",
    :headers="headers",
    :search="searchText",
    @click:row="show",
    role="button",
    sort-by="created",
    sort-desc,
    dense,
    mobile-breakpoint=0
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.phone="{ item }")
      span {{ item.phone | phone }}
    template(v-slot:item.actions="{ item }")
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn(icon, small, v-on.stop="on")
            v-icon(color="secondary") mdi-dots-horizontal
        v-list(dense)
          v-list-item(@click="toggleStatus(item)") Toggle
          AssignCode(:item="item")
          v-list-item(@click="editLevel(item)") Set Level
          Verify(:item="item")
          v-list-item(@click="handleDelete(item)", v-if="!item.verified") Delete
  SetLevel
</template>

<script>
import AssignCode from "./AssignCode";
import SetLevel from "./SetLevel";
import Verify from "./Verify";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { AssignCode, SetLevel, Verify },
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Created", value: "created" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Code", value: "code" },
        { text: "Level", value: "level" },
        { text: "Fee", value: "fee_rate" },
        { text: "Verified", value: "verified" },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },
  computed: { ...mapGetters(["influencers"]) },
  methods: {
    ...mapActions(["updateInfluencer", "removeInfluencer"]),
    async toggleStatus(item) {
      const params = {
        criteria: { _id: item._id },
        action: { $set: { verified: !item.verified } },
      };
      const result = await this.$api.influencer.account.update(params);
      this.updateInfluencer(result);
    },
    editLevel(item) {
      EventBus.$emit("edit-influencer-level", item);
    },
    handleDelete(item) {
      confirm("Do you want to delete this influencer account") &&
        this.remove(item);
    },
    async remove(item) {
      const params = { influencerId: item._id };
      try {
        await this.$api.influencer.account.delete(params);
        this.removeInfluencer(item);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    show(item) {
      EventBus.$emit("show-influencer", item);
    },
  },
};
</script>

<template lang="pug">
div
  YearSelector(@select="load")
  v-card.my-4(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Total: {{ total | currency }}
    v-data-table(:items="result", :headers="headers", sortBy="state")
      template(v-slot:item.subtotal="{ item }")
        span {{ item.subtotal | currency }}
      template(v-slot:item.tax="{ item }")
        span {{ item.tax | currency }}
      template(v-slot:item.shipping="{ item }")
        span {{ item.shipping | currency }}
      template(v-slot:item.total="{ item }")
        span {{ item.total | currency }}
      template(v-slot:item.paid="{ item }")
        span {{ item.paid | currency }}
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      result: [],
      loading: false,
      headers: [
        { text: "State", value: "state" },
        { text: "Semi", value: "semi" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Tax", value: "tax" },
        { text: "Shipping", value: "shipping" },
        { text: "Total", value: "total" },
        { text: "Paid", value: "paid" },
      ],
    };
  },
  computed: {
    total() {
      return this.result?.reduce((a, b) => a + b.total, 0);
    },
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      if (!this.year) return;
      const params = { year: this.year };
      this.loading = true;
      try {
        this.result = await this.$api.bizOrder.custom("report/annual", params);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

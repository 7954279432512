<template lang="pug">
v-data-table(:headers="headers", :items="items", dense, mobile-breakpoint=0)
  template(v-slot:item.stage="{ item }")
    span {{ item.stage | stage }}
</template>

<script>
export default {
  filters: {
    stage(val) {
      switch (val) {
        case 0:
          return "New Driver";
        case 1:
          return "Normal";
        case 2:
          return "Catering";
        case -1:
          return "Inactive";
        case -2:
          return "Suspended";
        case -3:
          return "Rejected";
        default:
          return "Unknown Stage";
      }
    },
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "Stage", value: "stage" },
        { text: "Count", value: "count", align: "end" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const path = "/driver/accounts/stats/countByStage";
      const { data } = await this.axios.post(path, {});
      this.items = data;
    },
  },
};
</script>
<template lang="pug">
.mb-10
  v-progress-linear(v-if="loading", indeterminate)
  Account(
    v-for="(item, index) in stripeAccounts",
    :key="index",
    :account="item"
  )
  .link-box
    v-btn(@click="previous()", v-if="stripeAccounts?.length") Previous
    v-btn(@click="next()", v-if="stripeAccounts?.length") Next
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Account from "./Account/index";
import _ from "underscore";

export default {
  components: { Account },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["stripeAccounts"]),
  },
  mounted() {
    this.setStripeAccounts([]);
    this.load();
  },
  methods: {
    ...mapActions(["setStripeAccounts"]),
    previous() {
      const first = _.first(this.stripeAccounts);
      const params = { ending_before: first.id, limit: 20 };
      this.load(params);
    },
    next() {
      const last = _.last(this.stripeAccounts);
      const params = { starting_after: last.id, limit: 20 };
      this.load(params);
    },
    async load(input) {
      this.loading = true;
      const params = input || { limit: 20 };
      try {
        const result = await this.$api.stripe.account.list(params);
        this.setStripeAccounts(result);
      } catch (err) {
        this.$toast.error(
          err.response?.data || err.message || "Failed to list accounts"
        );
      }
      this.loading = false;
    },
  },
};
</script>
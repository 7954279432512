import Home from './index.vue'
import Issues from './Issues'

export default [
  {
    path: '/salesforce/dev', component: Home, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'issues' },
      { path: 'issues', component: Issues },
    ]
  },
]
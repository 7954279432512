<template lang="pug">
span {{ charge | currency }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["item"],
  computed: {
    charge() {
      let amount = 0;
      if (this.item.payment && this.item.payment.charge) {
        amount += this.item.payment.charge.amount;
      }
      _.each(this.item.payment.charges, (o) => {
        amount += o.amount;
      });
      return amount;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="item")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Tax Item
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense)
          v-col(cols="6")
            v-text-field(label="Name", v-model="item.name", autofocus)
          v-col(cols="6")
            v-text-field(
              label="Percentage",
              v-model.number="item.percentage",
              suffix="%"
            )
        v-select(
          v-model="item.courses",
          multiple,
          :items="courses",
          hint="Only select if apply to specific courses. Otherwise, leave it blank.",
          persistent-hint,
          label="Courses"
        )
        v-select(
          v-model="item.excludeOrderTypes",
          multiple,
          :items="order_type_items",
          hint="Only select if tax not applicable to the order type.",
          persistent-hint,
          label="Excluded Order Types"
        )
        v-radio-group(v-model="item.type", row, label="Type")
          v-radio(
            v-for="(item, index) in type_items",
            :key="index",
            :label="item.title",
            :value="item.value"
          )
        v-radio-group(v-model="item.status", row, label="Status")
          v-radio(
            v-for="(item, index) in status_items",
            :key="index",
            :label="item.title",
            :value="item.value"
          )
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  name: "CourseForm",
  props: ["input"],
  data() {
    return {
      item: null,
      dialog: false,
      errors: [],
      loading: false,
      type_items: [
        { title: "Exclusive", value: 0 },
        { title: "Inclusive", value: 1 },
      ],
      status_items: [
        { title: "On", value: true },
        { title: "Off", value: false },
      ],
      order_type_items: [
        { text: "Delivery", value: "delivery" },
        { text: "Pick up", value: "pickup" },
        { text: "Dine in", value: "dinein" },
        { text: "Drive thru", value: "drive" },
        { text: "Retail", value: "retail" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    courses() {
      return this.biz.courses.map((o) => ({ text: o.name, value: o._id }));
    },
  },
  mounted() {
    EventBus.$on("edit-tax-item", this.open);
  },
  destroyed() {
    EventBus.$off("edit-tax-item", this.open);
  },
  methods: {
    open(data) {
      this.item = JSON.parse(JSON.stringify(data));
      this.dialog = true;
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.biz || !this.item) {
        this.errors = ["Invalid data"];
        return;
      }
      if (!this.item.name) this.errors.push("Name is required");
      if (this.item.percentage < 0) {
        this.errors.push("Percentage should be a non-negative number");
      }
      if (this.item.percentage == 0 && this.item.courses.length == 0) {
        this.errors.push("O tax can only be set for specified courses");
      }
      if (this.errors?.length) return;
      this.loading = true;
      let params;
      if (this.item?._id) {
        params = {
          criteria: { _id: this.biz._id, "tax_items._id": this.item._id },
          action: { $set: { "tax_items.$": this.item } },
        };
      } else {
        params = {
          criteria: { _id: this.biz._id },
          action: { $push: { tax_items: this.item } },
        };
      }
      try {
        const result = await this.$api.biz.update(params);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
simple-table(v-if="account")
  thead
    tr
      th Stripe Account
      th
      th.text-right
  tbody
    tr
      td Schedule
      td.text-capitalize {{ account.settings.payouts.schedule.interval }}
      td.text-right
        v-btn(
          small,
          @click="changeToDaily()",
          rounded,
          depressed,
          outlined,
          color="secondary"
        ) daily
    tr
      td ID
      td {{ account.id }}
      td.text-right
        v-btn(
          icon,
          x-small,
          :href="'https://dashboard.stripe.com/connect/accounts/' + account.id",
          target="_blank",
          color="secondary"
        )
          v-icon mdi-link
    tr(
      v-if="account.requirements.currently_due && account.requirements.currently_due.length"
    )
      td Currently Due
      td
        div(
          v-for="(item, index) in account.requirements.currently_due",
          :key="index"
        ) {{ item }}
      td
        v-btn.text-capitalize(
          v-if="miss_external_account",
          @click="handleAddBankAccount()",
          rounded,
          outlined,
          color="secondary"
        ) Remind Bank Account
</template>

<script>
export default {
  props: ["account", "driver"],
  computed: {
    miss_external_account() {
      return this.account?.requirements?.currently_due?.some(
        (o) => o == "external_account"
      );
    },
  },
  methods: {
    async changeToDaily() {
      if (!this.account) return;
      const params = {
        accountId: this.account.id,
        stripeData: {
          settings: { payouts: { schedule: { interval: "daily" } } },
        },
      };
      try {
        await this.$api.stripe.account.update(params);
        this.$toast.success("Updated");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async handleAddBankAccount() {
      if (!this.driver) return;
      const params = { driverId: this.driver._id };
      try {
        await this.axios.post("/driver/accounts/mail/addBankAccount", params);
        this.$toast.success("Sent");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

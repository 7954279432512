<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    div(v-for="(item, index) in delivOrders", :key="index")
      Cell(:delivOrder="item")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  data: () => ({
    orderId: null,
    dialog: false,
  }),
  computed: {
    ...mapGetters(["delivOrders"]),
  },
  mounted() {
    EventBus.$on("show-deliv-order", this.load);
  },
  destroyed() {
    EventBus.$off("show-deliv-order", this.load);
  },
  methods: {
    ...mapActions(["setDelivOrders"]),
    async load(orderId) {
      if (!orderId) return;
      this.setDelivOrders([]);
      const params = { criteria: { order: orderId } };
      const result = await this.$api.delivOrder.list(params);
      this.setDelivOrders(result);
      this.dialog = true;
    },
  },
};
</script>

const state = {
  pois: []
}

const getters = {
  pois: state => state.pois
}

const actions = {
  setPOIs: ({ commit }, data) => { commit('setPOIs', data) },
  addPOI: ({ commit }, data) => { commit('addPOI', data) },
  updatePOI: ({ commit }, data) => { commit('updatePOI', data) },
  removePOI: ({ commit }, data) => { commit('removePOI', data) }
}

const mutations = {
  setPOIs(state, data) { state.pois = data },
  addPOI(state, data) {
    state.pois = state.pois.concat([data])
  },
  updatePOI(state, data) {
    state.pois = state.pois.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removePOI(state, data) {
    state.pois = state.pois.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
<template lang="pug">
v-card(v-if="drivers && drivers.length")
  v-toolbar(dense, flat)
    .subtitle-2 {{ title }}
  v-data-table(
    :headers="headers",
    :items="drivers",
    sort-by="name",
    :search="search",
    mobile-breakpoint="0",
    dense,
    @click:row="select",
    role="button"
  )
    template(v-slot:item.phone="{ item }")
      span {{ item.phone | phone }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["drivers", "search", "title"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Stage", value: "stage" },
        { text: "Zones", value: "zones", align: "end" },
      ],
    };
  },
  methods: {
    select(item) {
      EventBus.$emit("show-driver-detail", item);
    },
  },
};
</script>

<template lang="pug">
v-btn(
  v-if="closable",
  color="secondary",
  @click="handleClose()",
  rounded,
  depressed,
  x-small,
  :loading="loading"
) Close Order
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return { loading: false };
  },
  computed: {
    closable() {
      return (
        this.order &&
        this.order.status >= 0 &&
        this.order.status < 3 &&
        this.order.needed < Date.now()
      );
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async handleClose() {
      if (!this.order) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.order._id },
        action: { $set: { status: 3 } },
      };
      const result = await this.$api.order.update(params);
      this.updateOrder(result);
      this.loading = false;
    },
  },
};
</script>


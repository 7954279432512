<template lang="pug">
div
  v-btn(@click="dialog = !dialog") Simple Email
  v-dialog(width="300", v-model="dialog")
    v-card
      v-card-title Send Email
      v-card-text
        v-form(@submit.prevent="submit")
          v-text-field(label="Email", v-model="email")
          v-text-field(label="Subject", v-model="subject")
          v-textarea(label="Message", v-model="message")
          v-btn(block, type="submit", color="secondary", :loading="loading") Send Message
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      email: "",
      subject: "",
      message: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      let params = {
        email: this.email,
        subject: this.subject,
        message: this.message,
      };
      this.loading = true;
      try {
        await this.$api.misc.custom("simpleEmail", params);
        this.email = "";
        this.subject = "";
        this.message = "";
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

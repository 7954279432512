<template lang="pug">
div
  v-btn(@click="open()", rounded, outlined, color="secondary") Message POS
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-text
        .sl-title Message POS
        BizSelector(@select="select")
        v-form(v-if="selectBiz", @submit.prevent="send()")
          v-text-field(v-model="message", label="Message")
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="!message",
            :loading="loading"
          ) Send
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      selectBiz: null,
      message: "",
      loading: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.selectBiz = null;
      this.message = "";
    },
    reset() {
      this.dialog = false;
      this.selectBiz = null;
      this.message = "";
    },
    select(biz) {
      this.selectBiz = biz;
    },
    async send() {
      if (!this.selectBiz || !this.selectBiz._id) {
        return this.$toast.error("Incorrect business");
      }
      const message = this.message.trim();
      if (!message) {
        return this.$toast.error("Enter message");
      }
      this.loading = true;
      const params = {
        bizId: this.selectBiz._id,
        message: message,
      };
      try {
        const { data } = await this.axios.post("/bizNotif/messagePOS", params);
        this.$toast.success(data);
        this.reset();
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

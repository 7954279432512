<template lang="pug">
Page(title="Delivery Partners")
  List
</template>

<script>
import List from "./List";

export default {
  components: { List },
};
</script>
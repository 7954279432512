<template lang="pug">
v-dialog(v-model="dialog", max-width="490")
  template(v-slot:activator="{ on }")
    v-btn.ma-2(v-on="on", color="primary", dark) Get Delivery
  v-card
    v-form(ref="form", @submit.prevent="getDelivery")
      v-card-text
        v-text-field(v-model="id", label="Enter Delivery ID", required)
        pre(v-if="delivery") {{ delivery }}
      v-card-actions
        v-btn(v-on:click="dialog = false", color="primary", dark) Cancel
        v-btn(type="submit", color="primary", dark) Get
</template>

<script lang="ts"> // vuejs 2
export default {
  data() {
    return {
      dialog: false,
      id: '',
      delivery: null
    }
  },
  methods: {
    async getDelivery() {
      try {
        this.delivery = await this.$api.doordash.custom('getDelivery', { id: this.id })
      } catch (e) {
        this.$toast.error(e.response?.data || e.message)
      }
    }
  }
}
</script>
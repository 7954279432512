<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Missed Transactions
  Table(:items="items")
</template>

<script>
import { mapGetters } from "vuex";
import Table from "@/components/Sales/Transactions/MissedTransactions/Table";

export default {
  components: { Table },
  // date format: "2020-08-01"
  props: ["date"],
  computed: {
    ...mapGetters(["missedTransactions", "biz"]),
    items() {
      if (!this.biz) return [];
      return this.missedTransactions?.filter(
        (o) => o.status >= 0 && o.biz == this.biz._id
      );
    },
  },
};
</script>
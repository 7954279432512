<template lang="pug">
div
  Table(:items="items")
</template>

<script>
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const url = "/corp/tool/email_log/list";
      const { data } = await this.axios.post(url, params);
      this.items = data;
    },
  },
};
</script>
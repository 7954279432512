<template lang="pug">
div
  .subtitle-2.my-3 Remind
  v-btn(@click="remind()") Remind
</template>

<script>
export default {
  methods: {
    async remind() {
      const { data } = await this.axios.post("/promoclaim/remind", {});
      this.$toast.success(data);
    },
  },
};
</script>
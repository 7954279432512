<template lang="pug">
v-row.my-3(justify="center")
  v-card(width="300", flat)
    v-card-text
      DaySelector.mb-3(@select="selectDay")
      BizSelector(@select="selectBiz")
      v-btn.text-capitalize(
        @click="load()",
        block,
        color="secondary",
        :disabled="!date || !biz",
        :loading="loading"
      ) Load
        v-icon(right, small) mdi-send
      LoadMissed(@select="loadBiz", ref="missed")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "underscore";
import LoadMissed from "./LoadMissed";

export default {
  components: { LoadMissed },
  data() {
    return {
      biz: null,
      date: new Date().toISOString().substring(0, 10),
      loading: false,
    };
  },
  computed: { ...mapGetters(["missedTransactions"]) },
  mounted() {
    this.setOrders([]);
    this.setTransactions([]);
  },
  methods: {
    ...mapActions(["setOrders", "setBiz", "setTransactions"]),
    selectBiz(biz) {
      this.biz = biz;
    },
    selectDay(data) {
      this.date = moment(data).format("YYYY-MM-DD");
      this.$refs.missed.load(this.date);
    },
    loadBiz(biz) {
      this.setBiz(biz);
      this.biz = biz;
      this.load();
    },
    async load() {
      if (!this.date || !this.biz) return;
      const params = { bizId: this.biz._id, date: this.date };
      this.loading = true;
      const { data } = await this.axios.post("/orders/listByDay", params);
      this.setOrders(data);
      this.loading = false;
      this.loadTxn();
    },
    async loadTxn() {
      const ids = _.chain(this.missedTransactions)
        .reject((o) => o.biz != this.biz._id)
        .pluck("txn")
        .compact()
        .value();
      if (!ids?.length) {
        this.setTransactions([]);
        return;
      }
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.paxTrans.list(params);
      this.setTransactions(result);
    },
  },
};
</script>

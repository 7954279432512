<template lang="pug">
v-btn(
  @click="confirmRemove",
  outlined,
  color="error",
  small,
  :loading="loading"
) Delete Device
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["device"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["removePOSDevice"]),
    async confirmRemove() {
      confirm("Are you sure you want to delete this device?") &&
        this.handleRemove();
    },
    async handleRemove() {
      this.loading = true;
      try {
        this.validate();
        const result = await this.$api.posDevice.delete(this.device._id);
        this.$toast.success(`Device ${result.deviceName} deleted`);
        this.removePOSDevice(result);
        this.$emit("close");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    validate() {
      if (!this.device) {
        throw new Error("Device not found");
      }
      const user_email = this.device.user_email;
      if (user_email === "selflane.public@gmail.com") {
        return true;
      }
      let cutoff = new Date().getTime() - 365 * 24 * 60 * 60 * 1000;
      if (this.device.last_visit > cutoff) {
        throw new Error("Not allowed, last visit within 1 year");
      }
      let appVersion;
      if (this.device?.appVersion) {
        appVersion = parseFloat(this.device.appVersion);
      }
      let minAppVersion = 5.99;
      if (appVersion > minAppVersion) {
        throw new Error(`Not allowed, app version > ${minAppVersion}`);
      }
    },
  },
};
</script>
import Accounts from './Accounts/index.vue'
import AccountDashboard from './Accounts/Dashboard/index.vue'
import AccountMonthly from './Accounts/Monthly/index.vue'
import AccountSearch from './Accounts/Search/index.vue'
import AccountUnverified from './Accounts/Unverified/index.vue'

import Commissions from './Commissions/index.vue'
import CommissionDashboard from './Commissions/Dashboard/index.vue'

import Plans from './Plans/index.vue'
import PlanDashboard from './Plans/Dashboard/index.vue'
import PlanMonthly from './Plans/Monthly/index.vue'
import PlanSearch from './Plans/Search/index.vue'

export default [{
  path: '/influencer/accounts',
  component: Accounts,
  meta: { auth: 'corp' },
  children: [
    { path: 'dashboard', component: AccountDashboard },
    { path: 'monthly', component: AccountMonthly },
    { path: 'search', component: AccountSearch },
    { path: 'unverified', component: AccountUnverified }
  ]
}, {
  path: '/influencer/commissions',
  component: Commissions,
  meta: { auth: 'corp' },
  children: [
    { path: 'dashboard', component: CommissionDashboard }
  ]
}, {
  path: '/influencer/plans',
  component: Plans,
  meta: { auth: 'corp' },
  children: [
    { path: 'dashboard', component: PlanDashboard },
    { path: 'monthly', component: PlanMonthly },
    { path: 'search', component: PlanSearch }
  ]
}]
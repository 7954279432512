<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="request")
    v-card-title {{ request.title }}
    v-card-text
      .subtitle-2(v-if="biz") {{ biz.name }}
      div {{ request.message }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
export default {
  data() {
    return {
      dialog: false,
      request: null,
      biz: null,
    };
  },
  mounted() {
    EventBus.$on("show-biz-request-detail", this.open);
  },
  destroyed() {
    EventBus.$off("show-biz-request-detail", this.open);
  },
  methods: {
    async open(data) {
      this.request = data;
      await this.loadBiz();
      this.dialog = true;
    },
    async loadBiz() {
      const params = {
        criteria: { _id: this.request.biz },
        select: "name address",
      };
      this.biz = await this.$api.biz.retrieve(params);
    },
  },
};
</script>
<template lang="pug">
Page(title="Services", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Accounts", url: "/pos/clients" },
        { title: "Chowly", url: "/pos/chowly" },
        { title: "7Shifts", url: "/pos/7shifts" },
        { title: "Card Pointe", url: "/pos/card_pointe" },
        { title: "Plans", url: "/pos/plans" },
        { title: "Items", url: "/pos/service_items" },
        { title: "Subscriptions", url: "/pos/subscriptions" },
        { title: "Activities", url: "/pos/activities" },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  v-btn(icon, small, v-if="isCredit", @click="handleEdit()")
    v-icon(small, color="secondary") mdi-pencil
  v-dialog(v-model="dialog", width="300")
    v-card
      v-toolbar(dense, flat)
        .overline Edit Charge IP Address
      v-divider
      v-card-text
        v-form.mt-3(@submit.prevent="submit")
          v-text-field(label="IP Loc", v-model="loc")
          v-btn(type="submit", block, color="secondary") Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order", "charge"],
  data() {
    return { dialog: false, loc: "" };
  },
  computed: {
    isCredit() {
      return this.charge?.method === "card";
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    reset() {
      this.dialog = false;
      this.loc = "";
    },
    handleEdit() {
      if (!this.charge) return;
      this.dialog = true;
      this.loc = this.charge.device.loc;
    },
    async submit() {
      if (!this.order || !this.charge || !this.loc) return;
      const params = {
        criteria: {
          _id: this.order._id,
          "payment.charges._id": this.charge._id,
        },
        action: { $set: { "payment.charges.$.device.loc": this.loc } },
      };
      try {
        const result = await this.$api.order.update(params);
        this.updateOrder(result);
        this.reset();
        this.$toast.success("Charge IP Address Updated");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

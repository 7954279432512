<template lang="pug">
v-card-text(v-if="item._id")
  v-divider.my-5
  v-checkbox(v-model="confirmDelete", label="Confirm Delete")
  v-btn(
    color="error",
    block,
    outlined,
    small,
    @click="deleteItem(item._id)",
    :disabled="!confirmDelete",
    :loading="loading"
  ) Delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return { loading: false, confirmDelete: false };
  },
  watch: {
    item() {
      this.confirmDelete = false;
    },
  },
  methods: {
    ...mapActions(["removeBizServiceSubscription"]),
    async deleteItem(id) {
      if (!id) return;
      this.loading = true;
      try {
        const result = await this.$api.service.subscription.delete({
          item_id: id,
        });
        this.removeBizServiceSubscription(result);
        this.$toast.success(`Deleted ${result.biz_name} successfully`);
        this.$emit("deleted");
        this.confirmDelete = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="item")
    v-card-title {{ title }}
    v-form(@submit.prevent="submit", v-if="isEdit")
      v-card-text
        v-row(wrap)
          v-col(cols="6")
            v-text-field(v-model="item.first_name", label="First Name")
          v-col(cols="6")
            v-text-field(v-model="item.last_name", label="Last Name")
          v-col(cols="6")
            v-text-field(v-model="item.company", label="Company Name")
          v-col(cols="6")
            v-combobox(
              v-model="item.job_title",
              label="Job Title",
              :items="job_items",
              hint="Select or type to add",
              persistent-hint
            )
          v-col(cols="6")
            v-text-field(
              v-model="item.email",
              label="Email",
              required,
              :disabled="hasUser"
            )
          v-col(cols="6")
            PhoneField(v-model="item.phone")
          v-col(cols="12")
            GoogleAddressField(v-model="item.address")
          v-col(cols="12")
            v-text-field(v-model="item.notes", label="Notes", multi-line)
          v-col(cols="12")
            v-select(
              v-model="item.organization",
              :items="organization_items",
              label="Organization",
              required,
              :disabled="!isInternal"
            )
      v-card-actions
        v-btn(color="secondary", :loading="loading", type="submit") Save
        v-btn(v-if="!isNew", @click="isEdit = false", text) Cancel
    StaticInfo(v-else, :contact="item", @edit="isEdit = true")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import StaticInfo from "./StaticInfo";
import jobsJSON from "./jobs.json";

export default {
  components: { StaticInfo },
  data() {
    return {
      dialog: false,
      loading: false,
      item: null,
      app_name: process.env.VUE_APP_NAME,
      job_items: JSON.parse(JSON.stringify(jobsJSON)),
      isEdit: false,
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    isNew() {
      return !this.item._id;
    },
    title() {
      if (!this.isEdit) return "View Contact";
      return this.item._id ? "Edit Contact" : "Add Contact";
    },
    hasUser() {
      return !!this.item.user;
    },
    organization_items() {
      return this.salesOrganizations.map((item) => ({
        text: item.name,
        value: item._id,
      }));
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
  },
  mounted() {
    EventBus.$on("edit-crm-contact", this.open);
  },
  destroyed() {
    EventBus.$off("edit-crm-contact", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.item = JSON.parse(JSON.stringify(data));
      } else {
        this.item = {
          first_name: "",
          last_name: "",
          company: "",
          job_title: "",
          email: "",
          phone: "",
          organization: null,
          address: {},
        };
      }
      this.isEdit = this.isNew;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      try {
        if (this.item._id) {
          const action = { ...this.item };
          const result = await this.$api.crm.contact.put(this.item._id, action);
          this.$store.dispatch("crm/updateContact", result);
          this.$emit("done", result);
        } else {
          const result = await this.$api.crm.contact.create(this.item);
          this.$store.dispatch("crm/addContact", result);
          this.$emit("done", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
import Login from './index.vue'
import ResetPWD from './ResetPWD.vue'
import Verify from './Verify.vue'

export default [{
  path: '/login',
  component: Login
}, {
  path: '/resetPWD',
  component: ResetPWD
}, {
  path: '/users/verify',
  component: Verify,
  props: (route) => ({
    email: route.query.a,
    code: route.query.b
  })
}]

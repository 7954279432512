<template lang="pug">
v-btn.text-capitalize.mr-2(
  @click="notify(item)",
  color="secondary",
  :loading="loading",
  x-small,
  outlined
) Notify
</template>


<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    valid() {
      if (!this.item.txn) return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["updateMissedTransaction"]),
    async notify(item) {
      if (!this.valid) return this.$toast.error("Invalid Data");
      if (!item.loc) return this.$toast.error("No IP");

      this.loading = true;
      const ip = item.loc.replace("http://", "").replace(":10009", "");
      const params = {
        doc_id: item._id,
        bizId: item.biz,
        ip,
        transNum: String(item.transNum),
      };
      try {
        await this.$api.transaction.missed.custom("notify", params);
        this.$toast.success("Notified");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  v-dialog(v-model="dialog", width="500px")
    v-card(v-if="giftcard")
      v-toolbar(flat, dense)
        .subtitle-2 Gift Card
        v-spacer
        Download(:card="giftcard")
      simple-table
        tbody
          tr
            th Biz
            td {{ giftcard.name }}
          tr
            th Amount
            td {{ giftcard.amount | currency }}
          tr
            th Balance
            td {{ giftcard.balance | currency }}
          tr
            th Owner
            td
              .link-box
                span {{ giftcard.owner }}
                v-btn(
                  icon,
                  small,
                  color="secondary",
                  @click="showUser(giftcard.owner)"
                )
                  v-icon(small) mdi-account
          tr
            th To
            td {{ giftcard.to }}
          tr
            th Message
            td {{ giftcard.message }}
          tr
            th Created
            td {{ giftcard.created | date }}
          tr
            th Link
            td
              a(:href="link") {{ link }}
      v-card-actions
        Remind(:item="giftcard")
        v-btn(outlined, small, color="secondary", @click="handleSend") Send to Friend
  EmailForm(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Remind from "./Remind";
import EmailForm from "./EmailForm";
import Download from "/libs/components/Gift/Download";

export default {
  components: { Remind, EmailForm, Download },
  data() {
    return {
      dialog: false,
      giftcard: null,
    };
  },
  computed: {
    link() {
      return `https://selflane.com/store-gift-card/${this.giftcard._id}`;
    },
    qrcode() {
      if (!this.giftcard) return "";
      return "sl:gc:" + this.giftcard._id;
    },
  },
  methods: {
    open(giftcard) {
      this.giftcard = giftcard;
      this.dialog = true;
    },
    showUser(id) {
      if (!id) return;
      EventBus.$emit("show-user-detail", id);
    },
    handleSend() {
      this.$refs.form.open(this.giftcard);
    },
  },
};
</script>
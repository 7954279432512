<template lang="pug">
div
  CreatePerson
  List.my-3(:items="salesPersons")
  Form
</template>

<script>
import CreatePerson from "/libs/components/SalesForce/Person/CreatePerson";
import Form from "/libs/components/SalesForce/Person/Form";
import List from "/libs/components/SalesForce/Person/List";
import { mapGetters } from "vuex";

export default {
  components: { CreatePerson, Form, List },
  computed: {
    ...mapGetters(["salesPersons"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.salesforce.person.list(params);
      this.$store.dispatch("setSalesPersons", result);
    },
  },
};
</script>

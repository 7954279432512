<template lang="pug">
Page(title="Home")
  .subtitle-2 This is a Selflane Internal website.
  .subtitle-2.my-3(v-if="!hasUser") Please login to continue.
    v-btn.ml-3(
      to="/login",
      outlined,
      color="secondary",
      small,
      v-if="!hasUser"
    ) Login
</template>

<script>
export default {
  computed: {
    hasUser() {
      return this.$auth.check();
    },
  },
};
</script>

<template lang="pug">
div
  List
</template>

<script>
import List from "./../List";
import { mapActions } from "vuex";

export default {
  components: { List },
  mounted() {
    this.load();
  },
  destroyed() {
    this.setInfluencers([]);
  },
  methods: {
    ...mapActions(["setInfluencers"]),
    async load() {
      const params = {
        criteria: { verified: { $ne: true } },
        limit: 100,
      };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
  },
};
</script>

<template lang="pug">
Page(title="Commissions", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [{ title: "Dashboard", url: "/influencer/commissions/dashboard" }],
    };
  },
};
</script>

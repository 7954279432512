<template lang="pug">
table(v-if="invoice")
  tbody
    AmountTableRow(name="Subtotal", :amount="invoice.payment.subtotal")
    AmountTableRow(name="Tax", :amount="invoice.payment.tax")
    AmountTableRow(name="Discount", :amount="-invoice.payment.discount")
    AmountTableRow(name="Total", :amount="invoice.payment.total", :bold="true")
    AmountTableRow(
      :name="paidName",
      :amount="invoice.payment.charge.amount",
      v-if="invoice.payment.charge.amount > 0",
      :bold="true"
    )
</template>

<script>
export default {
  props: ["invoice"],
  computed: {
    paidName() {
      return `Paid ${this.invoice.payment.charge.method}`;
    },
  },
};
</script>

<style lang="sass" scoped>
table
  width: 200px
  border-collapse: collapse
  margin-left: auto
  margin-right: 0
</style>
<template lang="pug">
div
  IconBtn(
    @click="submit",
    title="Unverified (100)",
    icon="mdi-delete",
    :loading="loading"
  )
  .my-2(v-if="total > 0") {{ deleted }}/{{ total }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";

export default {
  data() {
    return {
      deleted: 0,
      total: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["userAccounts"]),
    unverified() {
      // filter out unverified accounts older than 1 day
      return this.userAccounts.filter(
        (o) =>
          !o.status.verified &&
          o.created < moment().subtract(1, "days").valueOf()
      );
    },
  },
  methods: {
    ...mapActions(["removeUserAccount"]),
    async submit() {
      const items = this.unverified.slice(0, 100);
      this.total = items.length;
      this.deleted = 0;
      this.loading = true;
      for (var i = 0; i < items.length; i++) {
        const user = items[i];
        const params = { userId: user._id };
        try {
          await this.axios.post("/users/deleteOne", params);
          this.removeUserAccount(user);
          this.deleted++;
        } catch (err) {
          //
        }
      }
      this.loading = false;
    },
  },
};
</script>
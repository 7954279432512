import Home from './index.vue'
import Accounts from './Accounts/index.vue'
import Incomes from './Incomes/index.vue'

export default [{
  path: '/merchant',
  component: Home,
  meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'accounts' },
    { path: 'accounts', component: Accounts },
    { path: 'incomes', component: Incomes }
  ]
}]
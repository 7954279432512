<template lang="pug">
.d-flex.flex-row.align-center
  MonthSelector(@select="select")
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return { range: null };
  },
  methods: {
    ...mapActions(["setPartnerPayouts"]),
    async select(data) {
      if (data) this.range = data.range;
      if (!this.range) return;
      const params = {
        criteria: { created: { $gte: this.range.begin, $lt: this.range.end } },
      };
      const result = await this.$api.partner.payout.list(params);
      this.setPartnerPayouts(result);
    },
  },
};
</script>

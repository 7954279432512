<template lang="pug">
StandardChart(:input="chartData" chartType="bar")
</template>
<script>
import _ from "underscore";

export default {
  props: ["items", "title"],
  computed: {
    chartData() {
      if (!this.items) return null;
      const values = _.map(_.range(0, 24), (o) => {
        const found = _.find(this.items, (item) => item.hour == o);
        if (found) return Math.round((found.count / 4) * 10) / 10;
        else return 0;
      });
      const data = {
        title: this.title,
        labels: _.range(0, 24),
        values: values,
        isCurrency: false,
      };
      return data;
    },
  },
};
</script>

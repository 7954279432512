<template lang="pug">
div
  .d-flex.flex-row.align-center
    .sl-title Zones
    v-btn(small, outlined, @click="add()") Add
      v-icon(small, right) mdi-plus
  Table.my-3
  Form
  HourForm
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Table from "./Table";
import Form from "./Form";
import HourForm from "./HourForm";
import { mapActions } from "vuex";

export default {
  components: { Table, Form, HourForm },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setGeoServiceZones"]),
    add() {
      EventBus.$emit("edit-geo-service-zone");
    },
    async load() {
      const params = { criteria: {} };
      const { data } = await this.axios.post("/geoService/zones/list", params);
      this.setGeoServiceZones(data);
    },
  },
};
</script>
<template lang="pug">
div
  v-list-item(@click.stop="open()", v-if="valid") Change Time
  v-dialog(v-model="dialog", width="500")
    v-card(v-if="order")
      v-card-title Edit Time
      v-form(@submit.prevent="changeTime()")
        v-card-text
          OrderHeader(:order="order")
          v-text-field(
            v-model="time",
            label="New Time",
            hint="Format: YYYY-MM-DD h:mm A in merchant timezone",
            persistent-hint
          )
          v-text-field(
            v-model="message",
            label="Message",
            hint="Optional message to customer",
            persistent-hint
          )
        v-card-actions
          v-btn(type="submit", block, color="secondary", :loading="loading") Submit
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "vuex";

export default {
  props: ["order"],
  data() {
    return {
      dialog: false,
      time: moment().format("YYYY-MM-DD h:mm A"),
      message: "",
      loading: false,
    };
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone || moment.tz.guess();
    },
    valid() {
      return this.order.status >= 0;
    },
    needed() {
      return moment.tz(this.time, "YYYY-MM-DD h:mm A", this.timezone).valueOf();
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open() {
      if (!this.order) return;
      const startOfToday = moment.tz(this.timezone).startOf("day").valueOf();
      if (this.order.needed < startOfToday) {
        return this.$toast.error("Not Allowed! Can only edit today's order");
      }
      this.time = moment
        .tz(this.timezone)
        .add(10, "minutes")
        .format("YYYY-MM-DD h:mm A");
      this.message = "";
      this.dialog = true;
    },
    async changeTime() {
      if (!this.order) return;
      if (!this.needed || this.needed < this.startOfToday) return;
      this.loading = true;
      const params = {
        orderId: this.order._id,
        needed: this.needed,
        message: this.message,
        portal: "Selflane",
      };
      try {
        const res = await this.axios.post("/orders/editTime", params);
        this.updateOrder(res.data);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-btn-toggle(v-model="status", dense, color="secondary")
    v-btn(small, value="active") Active ({{ active.length }})
    v-btn(small, value="inactive") Inactive ({{ inactive.length }})
  div(v-for="(group, index) in groups", :key="index")
    .sl-title {{ group.country }}
    Table(:items="group.items")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return { status: "active" };
  },
  computed: {
    ...mapGetters(["bizservices"]),
    active() {
      return this.bizservices.filter((o) => !o.waive && !o.ended);
    },
    inactive() {
      return this.bizservices.filter((o) => o.waive || o.ended);
    },
    selected() {
      if (this.status === "active") return this.active;
      return this.inactive;
    },
    groups() {
      return _.chain(this.selected)
        .groupBy((o) => o.address?.country || "N/A")
        .map((items, country) => {
          return { country, items };
        })
        .value();
    },
  },
};
</script>

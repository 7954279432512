<template lang="pug">
StandardChart(:input="chartData", chartType="bar")
</template>

<script>
export default {
  props: ["summary"],
  computed: {
    chartData() {
      if (!this.summary) return null;
      return {
        title: "Spending",
        labels: this.summary.map(
          (o) => `${parseInt(o.month / 100)}/${o.month % 100}`
        ),
        values: this.summary.map((o) => o.amount),
      };
    },
  },
};
</script>
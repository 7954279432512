<template lang="pug">
v-btn(@click="test", outlined, color="secondary", small, :loading="loading") Test FCM
</template>

<script>
export default {
  props: ["device"],
  data() {
    return { loading: false };
  },
  methods: {
    async test() {
      const params = { id: this.device._id };
      this.loading = true;
      try {
        const result = await this.$api.posDevice.custom("test_fcm", params);
        this.$toast.success(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-toolbar(flat, dense)
  .subtitle-2 Users &#38; Access
  v-spacer
  IconBtn(@click="create()", title="Add", icon="mdi-plus")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    create() {
      EventBus.$emit("edit-access");
    },
  },
};
</script>
<template lang="pug">
div
  YearSelector(@select="load")
  Summary
  .link-box.my-3
    AutoAssign(:year="year", @update="load")
    AutoEmail(:year="year", @update="load")
  List(:taxfiles="bizs", label="Bizs")
  List(:taxfiles="delivs", label="Delivery  Companies")
  List(:taxfiles="users", label="Users")
  List(:taxfiles="unknowns", label="Unknown")
  List(:taxfiles="notfound", label="Not Found")
</template>

<script>
import Summary from "./Summary";
import List from "./List";
import { mapGetters, mapActions } from "vuex";
import AutoAssign from "./AutoAssign";
import AutoEmail from "./AutoEmail";

export default {
  components: { Summary, List, AutoAssign, AutoEmail },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["taxfiles"]),
    delivs() {
      return this.taxfiles.filter((o) => o.deliv);
    },
    bizs() {
      return this.taxfiles.filter((o) => o.biz);
    },
    users() {
      return this.taxfiles.filter((o) => o.user);
    },
    unknowns() {
      return this.taxfiles.filter(
        (o) => !o.user && !o.deliv && !o.biz && !o.not_found
      );
    },
    notfound() {
      return this.taxfiles.filter((o) => o.not_found);
    },
  },
  methods: {
    ...mapActions(["setTaxFiles"]),
    async load(year) {
      if (year) this.year = year;
      this.setTaxFiles([]);
      const params = { criteria: { year: this.year }, limit: 2000 };
      const result = await this.$api.taxFile.list(params);
      this.setTaxFiles(result);
    },
  },
};
</script>
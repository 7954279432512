<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="count",
  sort-desc,
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.total="{ item }")
    span {{ item.total | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Count", value: "count" },
        { text: "Total", value: "total", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["driverPayouts"]),
    items() {
      return _.chain(this.driverPayouts)
        .groupBy("driver")
        .map((o, k) => {
          const count = o.reduce((a, b) => a + b.count, 0);
          const total = o.reduce((a, b) => a + b.amount, 0);
          return {
            driverId: k,
            name: _.last(o).name,
            count,
            total,
          };
        })
        .value();
    },
  },
};
</script>
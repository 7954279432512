<template lang="pug">
.login-container
  .login
    Head
    Form
</template>

<script>
import Form from "./Form/index";
import Head from "./Head";

export default {
  components: { Head, Form },
  metaInfo() {
    const title = (process.env.VUE_APP_NAME || "Selflane") + ": Login";
    const description =
      this.app_name == "Selflane Business"
        ? "Login to Selflane Business to manage your restaurant."
        : "Login to Selflane to access hundreds of restaurants and order food online.";
    return { title, meta: [{ name: "description", content: description }] };
  },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
};
</script>

<style lang="sass" scoped>
.login-container
  display: flex
  flex-flow: column
  justify-content: center
  align-items: center
  margin-top: 100px
  margin-bottom: 100px

.login
  width: 95%
  max-width: 390px
</style>


const state = {
  enterprise: null,
  enterprises: [],
  access: null,
  accesses: [],
  region: null,
  regions: [],
  chain: null,
  chains: [],
}

const getters = {
  enterprise: state => state.enterprise,
  enterprises: state => state.enterprises,
  access: state => state.access,
  accesses: state => state.accesses,
  region: state => state.region,
  regions: state => state.regions,
  chain: state => state.chain,
  chains: state => state.chains,
}

const actions = {
  setEnterprise: ({ commit }, data) => { commit('setEnterprise', data) },
  setEnterprises: ({ commit }, data) => { commit('setEnterprises', data) },
  updateEnterprise: ({ commit }, data) => { commit('updateEnterprise', data) },
  addEnterprise: ({ commit }, data) => { commit('addEnterprise', data) },
  removeEnterprise: ({ commit }, data) => { commit('removeEnterprise', data) },
  setAccess: ({ commit }, data) => { commit('setAccess', data) },
  setAccesses: ({ commit }, data) => { commit('setAccesses', data) },
  addAccesses: ({ commit }, data) => { commit('addAccesses', data) },
  updateAccess: ({ commit }, data) => { commit('updateAccess', data) },
  addAccess: ({ commit }, data) => { commit('addAccess', data) },
  removeAccess: ({ commit }, data) => { commit('removeAccess', data) },
  setRegion: ({ commit }, data) => { commit('setRegion', data) },
  setRegions: ({ commit }, data) => { commit('setRegions', data) },
  updateRegion: ({ commit }, data) => { commit('updateRegion', data) },
  addRegion: ({ commit }, data) => { commit('addRegion', data) },
  removeRegion: ({ commit }, data) => { commit('removeRegion', data) },
  setChain: ({ commit }, data) => { commit('setChain', data) },
  setChains: ({ commit }, data) => { commit('setChains', data) },
  addChain: ({ commit }, data) => { commit('addChain', data) },
  updateChain: ({ commit }, data) => { commit('updateChain', data) },
  removeChain: ({ commit }, data) => { commit('removeChain', data) },
}

const mutations = {
  setEnterprise(state, data) {
    state.enterprise = data
  },
  setEnterprises(state, data) {
    state.enterprises = data
  },
  updateEnterprise(state, data) {
    state.enterprises = state.enterprises.map(o => {
      if (o._id === data._id) return data
      return o
    })
    if (state.enterprise?._id === data._id) state.enterprise = data
  },
  addEnterprise(state, data) {
    state.enterprises.push(data)
  },
  removeEnterprise(state, data) {
    state.enterprises = state.enterprises.filter(o => o._id !== data._id)
  },
  setAccess(state, data) {
    state.access = data
  },
  setAccesses(state, data) {
    state.accesses = data
  },
  addAccesses(state, data) {
    if (!Array.isArray(data)) return
    data.forEach(o => {
      const existing = state.accesses.find(w => w._id === o._id)
      if (existing) {
        state.accesses = state.accesses.map(w => {
          if (w._id === o._id) return o
          return w
        })
      } else {
        state.accesses.push(o)
      }
    })
  },
  updateAccess(state, data) {
    state.accesses = state.accesses.map(o => {
      if (o._id === data._id) return data
      return o
    })
    if (state.access?._id === data._id) state.access = data
  },
  addAccess(state, data) {
    state.accesses.push(data)
  },
  removeAccess(state, data) {
    state.accesses = state.accesses.filter(o => o._id !== data._id)
  },
  setRegion(state, data) {
    state.region = data
  },
  setRegions(state, data) {
    state.regions = data
  },
  updateRegion(state, data) {
    state.regions = state.regions.map(o => {
      if (o._id === data._id) return data
      return o
    })
    if (state.region?._id === data._id) state.region = data
  },
  addRegion(state, data) {
    state.regions.push(data)
  },
  removeRegion(state, data) {
    state.regions = state.regions.filter(o => o._id !== data._id)
  },
  setChain(state, data) { state.chain = data },
  setChains(state, data) { state.chains = data },
  addChain(state, data) { state.chains.push(data) },
  updateChain(state, data) {
    state.chains = state.chains.map(o => {
      if (o._id === data._id) return data
      return o
    })
    if (state.chain?._id === data._id) state.chain = data
  },
  removeChain(state, data) {
    state.chains = state.chains.filter(o => o._id !== data._id)
    if (state.chain?._id === data._id) state.chain = null
  },
}

export default { namespaced: true, state, getters, actions, mutations }
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", small, depressed, dark, v-on="on") {{ title }}
  v-card(:loading="loading")
    v-card-title
      h2 {{ title }}
    v-card-text
      v-form
        v-file-input(v-model="file", :rules="rules", label="File")
      div(v-if="result")
        .subtitle-2 Result
        pre {{ result }}
    v-card-actions
      v-btn(color="primary", @click="submit", :loading="loading") Create
      v-btn(@click="dialog = false") Cancel
</template>

<script>
export default {
  props: { dispute: { type: Object, default: null } },
  data() {
    return {
      title: "Receipt",
      dialog: false,
      loading: false,
      file: null,
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    async createFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("purpose", "dispute_evidence");
      this.loading = true;
      try {
        const result = await this.$api.stripe.files.create(formData);
        this.$toast.success("File created.");
        return result;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create file.");
      }
      this.loading = false;
    },
    async submit() {
      this.loading = true;
      try {
        const file = await this.createFile();
        const result = await this.$api.stripe.disputes.update({
          id: this.dispute.id,
          params: {
            evidence: { receipt: file.id },
            submit: false,
          },
        });
        this.$toast.success("Receipt attached.");
        this.$emit("update", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to attach receipt.");
      }
      this.loading = false;
    },
  },
};
</script>
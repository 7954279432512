<template lang="pug">
.s-vstack
  v-card(v-for="(item, index) in announcements", :key="index", flat)
    v-toolbar(flat, dense)
      span {{ item.title }}
      v-spacer
      v-btn.mr-2(@click="edit(item)", outlined, color="secondary", small)
        v-icon(color="secondary", small) mdi-pencil
      SendEmail(:item="item")
    v-card-text
      .caption {{ item.url }}
      v-md-preview(:text="item.summary")
      div Reader: {{ reader }}
      div {{ item.start | date }} - {{ item.end | date }}
</template>

<script>
import { mapGetters } from "vuex";
import SendEmail from "./SendEmail";

export default {
  components: { SendEmail },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["announcements"]),
    reader() {
      return this.item?.readers?.join(",");
    },
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>
<template lang="pug">
StandardChart(
  :input="chartData",
  :showDownload="true",
  :showTotal="true",
  chartType="bar"
)
</template>

<script>
import _ from "underscore";

export default {
  props: ["input", "title", "selection", "isCurrency"],
  data() {
    return {
      showDownload: true,
      showTotal: true,
    };
  },
  computed: {
    chartData() {
      // yearly
      if (this.selection == 0) {
        const data = _.chain(this.input)
          .groupBy("yyyy")
          .map((list, year) => {
            let value = list.reduce((a, b) => a + b.value, 0);
            value = Math.round(value * 100) / 100;
            return { year, value };
          })
          .sortBy("year")
          .value();
        return {
          title: this.title,
          labels: data.map((o) => o.year),
          values: data.map((o) => o.value),
          isCurrency: this.isCurrency,
        };
      }
      // monthly
      let data = JSON.parse(JSON.stringify(this.input));
      if (this.selection > 1000) {
        const year = this.selection;
        const filtered = this.input.filter((o) => o.yyyy == year);
        data = _.map(_.range(1, 13), (month) => {
          const found = filtered.find((o) => o.mm == month);
          if (found) return found;
          else
            return {
              yyyy: year,
              mm: month,
              value: 0,
            };
        });
      }
      data = _.sortBy(data, (o) => {
        return o.yyyy * 100 + o.mm;
      });
      const labels = data.map((o) => o.yyyy + "/" + o.mm);
      return {
        title: this.title,
        labels,
        values: data.map((o) => o.value),
        isCurrency: this.isCurrency,
      };
    },
  },
};
</script>

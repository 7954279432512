<template lang="pug">
Page(title="Business Accounts")
  List.mb-3(:accounts="accounts", :bizs="bizs")
  Summary(:accounts="accounts")
  Touch
  Credits
  WaitlistMonthly
  CreateCredit
</template>

<script>
import List from "./List/index";
import Summary from "./Summary";
import Credits from "./Credits/index";
import WaitlistMonthly from "./WaitlistMonthly";
import CreateCredit from "./CreateCredit/index";
import Touch from "./Touch";

export default {
  components: { List, Summary, Credits, WaitlistMonthly, CreateCredit, Touch },
  data() {
    return {
      accounts: [],
      bizs: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      this.accounts = await this.$api.bizAccount.list(params);
      this.loadBizs();
    },
    async loadBizs() {
      if (!this.accounts) return;
      const bizIds = this.accounts.map((item) => item._id);
      const params = {
        criteria: { _id: { $in: bizIds } },
        select: "name address",
      };
      this.bizs = await this.$api.biz.list(params);
    },
  },
};
</script>

import { render, staticRenderFns } from "./WeekSelector.vue?vue&type=template&id=066ac916&scoped=true&lang=pug"
import script from "./WeekSelector.vue?vue&type=script&lang=js"
export * from "./WeekSelector.vue?vue&type=script&lang=js"
import style0 from "./WeekSelector.vue?vue&type=style&index=0&id=066ac916&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066ac916",
  null
  
)

export default component.exports
<template lang="pug">
div
  v-btn(@click="open", outlined, color="secondary", small) Notify
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-text
        .sl-title Sent Notification to Device
        p You can use this to test the notification feature of the device. Enter the message and click send. If the notification is on, the device will receive the message and show it as an alert.
        v-form(@submit.prevent="send()")
          v-text-field(v-model="message", label="Message")
          .my-2(v-if="isInternal")
            v-btn(
              text,
              @click="setMessage(updateOS)",
              color="secondary",
              x-small
            ) Update OS
            v-btn(
              text,
              @click="setMessage(updateApp)",
              color="secondary",
              x-small
            ) Update App
          v-btn(
            block,
            color="secondary",
            type="submit",
            :disabled="!message",
            :loading="loading"
          ) Send
</template>

<script>
export default {
  props: {
    device: { type: Object, required: true },
    isInternal: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      message: "",
      loading: false,
      updateOS:
        "Please update your OS to the latest version to get the latest features.",
      updateApp:
        "Please update your app to the latest version to get the latest features.",
    };
  },
  methods: {
    open() {
      this.dialog = true;
      this.message = "";
    },
    setMessage(message) {
      this.message = message;
    },
    async send() {
      const token = this.device?.deviceToken;
      if (!token) {
        return this.$toast.error("Device token not found");
      }
      const message = this.message.trim();
      if (!message) {
        return this.$toast.error("Enter message");
      }
      this.loading = true;
      try {
        const url = "/bizNotif/messageDevice";
        const params = { token, message };
        const { data } = await this.axios.post(url, params);
        this.$toast.success(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
Page(title="Zone Services")
  Countries
  Zones
</template>

<script>
import Countries from "./Countries/index";
import Zones from "./Zones/index";

export default {
  components: { Countries, Zones },
};
</script>
<template lang="pug">
div
  v-col(align="center")
    YearSelector(@select="load")
    .sl-medal.elevation-2
      h2 Income
      h1 {{ total | currency }}
  v-row(wrap, dense)
    v-col(cols="12", md="4")
      StandardChart(:input="amountData")
    v-col(cols="12", md="4")
      StandardChart(:input="countData")
    v-col(cols="12", md="4")
      StandardChart(:input="countNSData")
</template>

<script>
import _ from "underscore";
export default {
  data() {
    return {
      year: 2019,
      result: [],
      amountData: null,
      countData: null,
      countNSData: null,
    };
  },
  computed: {
    total() {
      return this.result.reduce((a, b) => a + b.amount, 0);
    },
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      const params = {
        year: this.year,
      };
      const res = await this.axios.post("/delivOrders/monthly-growth", params);
      this.result = _.chain(res.data).sortBy("month").sortBy("year").value();
      this.setData();
    },
    setData() {
      const months = _.map(this.result, (o) => {
        return o.year + "/" + o.month;
      });
      const amounts = this.result.map((o) => o.amount);
      const count = this.result.map((o) => o.count);
      const countNS = this.result.map((o) => o.countNS);
      this.amountData = {
        title: "Amount",
        labels: months,
        values: amounts,
        isCurrency: true,
      };
      this.countData = {
        title: "Count Selflane",
        labels: months,
        values: count,
      };
      this.countNSData = {
        title: "Count Non-Selflane",
        labels: months,
        values: countNS,
      };
    },
  },
};
</script>

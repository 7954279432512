<template lang="pug">
div
  v-text-field.mx-5(v-model="searchText", label="Search", clearable)
  v-data-table(
    :items="items",
    :headers="headers",
    :search="searchText",
    @click:row="show",
    role="button",
    sort-by="created",
    sort-desc,
    dense,
    mobile-breakpoint=0
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.expiry="{ item }")
      span {{ item.expiry | date }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  props: ["bizs"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Biz", value: "name" },
        { text: "Created", value: "created" },
        { text: "Expiry", value: "expiry" },
        { text: "Type", value: "type" },
        { text: "Discount", value: "discount" },
        { text: "Influencer", value: "influencerName" },
        { text: "Comm. Rate", value: "commission_rate", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["influencerPlans", "influencers"]),
    items() {
      return _.map(this.influencerPlans, (o) => {
        const found = this.bizs.find((biz) => biz._id == o.biz);
        const name = found?.name || o.biz;
        let discount = "";
        if (o.type == "pct") discount = o.pct + "%";
        else discount = "$" + o.fix;

        let influencerName = "";
        if (!o.influencer) {
          influencerName = "Open";
        } else {
          const foundInfluencer = this.infuencers.find(
            (inf) => inf._id == o.influencer
          );
          if (foundInfluencer) influencerName = foundInfluencer.name;
        }
        return {
          name,
          created: o.created,
          expiry: o.expiry,
          type: o.type,
          discount,
          influencerName,
          commission_rate: o.commission_rate + "%",
        };
      });
    },
  },
};
</script>

<template lang="pug">
v-btn.text-capitalize(@click="handleClean()", :loading="loading") Remove Zero Balance Memebers
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    handleClean() {
      confirm("This will remove any member with no balance") && this.clean();
    },
    async clean() {
      this.loading = true;
      try {
        await this.$api.member.clean();
        this.$toast.success("Clean successful");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
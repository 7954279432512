<template lang="pug">
v-list-item(@click="populate()", v-if="!payout.name") Populate
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["payout"],
  methods: {
    ...mapActions(["updateDriverPayout"]),
    async populate() {
      if (!this.payout) return;
      const params = {
        payoutId: this.payout._id,
      };
      try {
        const { data } = await this.axios.post(
          "/driver/payouts/populate",
          params
        );
        this.updateDriverPayout(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
Page(title="Invoice", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Month", url: "/invoice/month" },
        { title: "Org Invoices", url: "/invoice/org_invoice" },
        { title: "Payment", url: "/invoice/payment" },
        { title: "Growth", url: "/invoice/growth" },
        { title: "YoY", url: "/invoice/yoy" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-btn(
  x-small,
  outlined,
  color="secondary",
  @click.stop="download(item)",
  :loading="loading"
) PDF
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async download(item) {
      if (!item) return;
      this.loading = true;
      const params = { invoiceId: item._id };
      try {
        const { data } = await this.axios.post("/invoices/createPDF", params, {
          responseType: "blob",
        });
        FileSaver.saveAs(data, "invoice-" + item._id + ".pdf");
        this.$toast.success("Invoice downloaded");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

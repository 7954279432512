<template lang="pug">
v-card(flat)
  v-card-text
    v-text-field(label="Search", v-model="searchText")
  v-data-table(
    :items="corpaccesses",
    :headers="headers",
    :search="searchText",
    @click:row="edit",
    role="button",
    sort-by="name",
    mobile-breakpoint=0,
    dense
  )
    template(v-slot:item.name="{ item }")
      span.nowrap {{ item.name }}
    template(v-slot:item.phone="{ item }")
      span.nowrap {{ item.phone | phone }}
    template(v-slot:item.privilege="{ item }")
      span {{ item.privilege | role }}
    template(v-slot:item.action="{ item }")
      v-btn(icon, small, color="error", @click.stop="handleRemove(item)")
        v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AccessForm from "./AccessForm";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  components: { AccessForm },
  filters: {
    role(val) {
      switch (val) {
        case 1:
          return "Owner";
        case 2:
          return "Manager";
        case 3:
          return "POS App Only";
        case 4:
          return "Custom";
        default:
          return "Role";
      }
    },
  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Access", value: "privilege" },
        { text: "Regions", value: "country_states" },
        { text: "", value: "action", align: "end" },
      ],
      searchText: "",
    };
  },
  computed: {
    ...mapGetters(["corpaccesses"]),
    items() {
      return _.chain(this.corpaccesses)
        .sortBy("name")
        .sortBy("privilege")
        .value();
    },
  },
  methods: {
    ...mapActions(["removeCorpAccess"]),
    handleRemove(access) {
      const isMe = access && access.user == this.$auth.user()._id;
      if (isMe) {
        return this.$toast.error("Only another owner can remove you");
      }
      confirm("Do you really want to delete this user & access?") &&
        this.remove(access);
    },
    async remove(access) {
      try {
        const result = await this.$api.corp.access.delete(access._id);
        this.removeCorpAccess(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    edit(item) {
      EventBus.$emit("edit-corp-access", item);
    },
  },
};
</script>
<template lang="pug">
v-list-item
  v-list-item-content
    v-list-item-title
      span Payment
  v-list-item-action
    v-btn-toggle(v-model="value", @change="submit", dense, color="secondary")
      v-btn(
        v-for="(item, index) in items",
        :key="index",
        x-small,
        :value="item.value"
      ) {{ item.name }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      value: this.item.preferred_payment || "",
      items: [
        { name: "N/S", value: "" },
        { name: "Stripe", value: "stripe_balance" },
        { name: "Credit Card", value: "credit_card" },
      ],
    };
  },
  methods: {
    ...mapActions(["setBizService"]),
    async submit() {
      this.loading = true;
      const action = { $set: { preferred_payment: this.value } };
      try {
        const result = await this.$api.service.account.put(
          this.item._id,
          action
        );
        this.setBizService(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-app
  Nav(v-if="$auth.check('corp')")
  v-main(style="background-color: #f0f2f5")
    router-view
  Footer
  OrderDetail
  UserDetail
  OrderListByUserId
  ShowPhoto
  EditService
  ServiceView
  StripeCharge
</template>


<script>
import OrderListByUserId from "@/components/Dialog/Order/ListByUserId/index";
import ShowPhoto from "./components/basic/ShowPhoto";
import UserDetail from "./components/Customer/Users/Detail/index";
import Footer from "./components/Footer";
import EditService from "./components/Internal/POSClients/EditService";
import ServiceView from "./components/Internal/POSClients/ServiceView";
import Nav from "./components/Nav";
import OrderDetail from "./components/Sales/Detail/index";
import StripeCharge from "/libs/components/Stripe/StripeCharge/index";

export default {
  name: "App",
  components: {
    Nav,
    Footer,
    OrderDetail,
    UserDetail,
    OrderListByUserId,
    ShowPhoto,
    EditService,
    ServiceView,
    StripeCharge,
  },
  mounted() {
    this.checkUser();
  },
  methods: {
    checkUser() {
      // Ensure user has corp access, otherwise logout.
      if (this.$auth.check() && !this.$auth.check("corp")) {
        this.$auth.logout();
      }
    },
  },
};
</script>

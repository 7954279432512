<template lang="pug">
v-card.my-3(width="500")
  v-card-text
    v-form(@submit.prevent="send")
      v-row
        v-col(cols="6")
          v-text-field(
            label="Email",
            type="email",
            v-model="email",
            :error-messages="emailErrors",
            @blur="$v.email.$touch()",
            ref="focus"
          )
        v-col(cols="6")
          v-text-field(
            label="Name",
            v-model="name",
            :error-messages="nameErrors",
            @blur="$v.name.$touch()"
          )
      v-select(
        :items="topics",
        item-text="subject",
        item-value="name",
        v-model="sel_topic",
        dense
      )
      v-btn(color="secondary", block, type="submit", :disabled="$v.$invalid") Send Marketing Email
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations() {
    return {
      email: { required, email },
      name: { required },
    };
  },
  data: () => ({
    sel_topic: "create_business",
    name: "",
    email: "",
    topics: [
      {
        name: "create_business",
        subject: "Take Your Restaurant Online with Selflane",
        path: "Marketing/CreateBusiness",
      },
    ],
  }),
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required");
      return errors;
    },
  },
  methods: {
    async send() {
      if (!this.sel_topic) return;
      if (!this.name || !this.email) return;
      const params = {
        email: this.email,
        name: this.name,
        topic_name: this.sel_topic,
      };
      try {
        const { data } = await this.axios.post("/email/mailMarketing", params);
        this.$toast.success(data);
        this.name = "";
        this.email = "";
        this.$v.$reset();
        this.$nextTick(() => this.$refs.focus.focus());
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

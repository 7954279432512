<template lang="pug">
MarkerMap(:geometries="geometries")
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  data() {
    return { geometries: [] };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const { data } = await this.axios.post(this.url, {});
      this.geometries = data;
    },
  },
};
</script>
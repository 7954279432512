<template lang="pug">
v-row(wrap)
  v-col(
    cols="12",
    sm="6",
    md="3",
    v-for="(item, index) in delivs",
    :key="index",
    fill-height
  )
    v-card(flat)
      v-card-text
        .d-flex.flex-row.align-center.justify-space-between
          .sl-title {{ item.name }}
            v-icon.ml-2(v-if="item.verified", color="success", small) mdi-check-circle
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn(icon, small, v-on.stop="on")
                v-icon(color="secondary") mdi-dots-horizontal
            v-list(dense)
              v-list-item(@click="toggleStatus(item)") Toggle
              v-list-item(@click="handlePayout(item)") Payout
              v-list-item(@click="handleDelete(item)", v-if="!item.verified") Delete
        div
          v-icon.mr-2(small) mdi-phone
          span {{ item.phone | phone }}
        div
          v-icon.mr-2(small) mdi-email
          span {{ item.email }}
        .d-flex.flex-row
          v-icon.mr-2(small) mdi-map
          div
            span {{ item.country }}: {{ item.states | joinArray }}
            div
              span.mr-2(
                v-for="(zone, index) in item.zones",
                :key="index + 'zone'"
              ) {{ zone }}
              v-btn(
                x-small,
                outlined,
                rounded,
                color="secondary",
                @click="editZone(item)"
              ) edit
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  filters: {
    joinArray(input) {
      return input?.join(", ");
    },
  },
  computed: {
    ...mapGetters(["delivs"]),
  },
  methods: {
    ...mapActions(["updateDeliv", "removeDeliv"]),
    async toggleStatus(item) {
      const params = {
        criteria: { _id: item._id },
        action: { $set: { verified: !item.verified } },
      };
      const result = await this.$api.delivery.update(params);
      this.updateDeliv(result);
    },
    handlePayout(item) {
      confirm("Do you want to payout this delivery company") &&
        this.payout(item);
    },
    payout(item) {
      const data = { delivId: item._id };
      this.axios
        .post("/delivery/payouts/create", data)
        .then(() => {
          this.$toast.success("Payout created successfully");
        })
        .catch((err) => {
          this.$toast.error(err.response?.data || err.message);
        });
    },
    handleDelete(item) {
      confirm("Do you want to delete this delivery company") &&
        this.remove(item);
    },
    remove(item) {
      const data = { delivId: item._id };
      this.axios
        .post("/delivery/accounts/deleteone", data)
        .then(() => {
          this.removeDeliv(item);
        })
        .catch((err) => {
          this.$toast.error(err.response?.data || err.message);
        });
    },
    editZone(item) {
      EventBus.$emit("set-deliv-zone", item);
    },
  },
};
</script>

import Home from './index.vue'
import Organizations from './Organizations'
import Agents from './Agents'

export default [
  {
    path: '/salesforce/admin', component: Home, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'organizations' },
      { path: 'organizations', component: Organizations },
      { path: 'agents', component: Agents },
    ]
  },
]
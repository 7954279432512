<template lang="pug">
v-layout(wrap, v-if="stripeAccount", justify-center)
  v-flex.text-xs-center(xs6, sm3)
    .caption.sl-secondary-text Type
    .subtitle-2.text-capitalize {{ stripeAccount.business_type }}
  v-flex.text-xs-center(xs6, sm3)
    .caption.sl-secondary-text Name
    .subtitle-2 {{ accountName }}
  v-flex.text-xs-center(xs6, sm3)
    .caption.sl-secondary-text Schedule
    .subtitle-2.text-capitalize {{ payout }}
  v-flex.text-xs-center(
    xs6,
    sm3,
    v-if="stripeAccount.business_type == 'individual'"
  )
    .caption.sl-secondary-text Status
    .subtitle-2.text-capitalize {{ status }}
  v-flex.text-xs-center(xs6, sm3, v-if="!isComplete")
    .caption.sl-secondary-text Currently Due
    div(
      v-for="(item, index) in stripeAccount.requirements.currently_due",
      :key="index"
    ) {{ item }}
  Capabilities(:stripeAccount="stripeAccount")
</template>

<script>
import Capabilities from "./Capabilities";

export default {
  components: { Capabilities },
  props: ["stripeAccount"],
  computed: {
    accountName() {
      if (this.stripeAccount) {
        if (this.stripeAccount.business_type == "company")
          return this.stripeAccount.company.name;
        else if (this.stripeAccount.individual)
          return (
            this.stripeAccount.individual.first_name +
            " " +
            this.stripeAccount.individual.last_name
          );
      }
      return "";
    },
    payout() {
      if (this.stripeAccount?.payouts_enabled) {
        const schedule = this.stripeAccount.settings.payouts.schedule;
        if (schedule.interval == "manual") return "Manual";
        return `${schedule.interval}/${schedule.monthly_anchor}`;
      }
      return "Not Enabled";
    },
    status() {
      return this.stripeAccount?.individual?.verification?.status;
    },
    isComplete() {
      return !this.stripeAccount?.requirements?.currently_due?.length;
    },
  },
};
</script>

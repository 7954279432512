<template lang="pug">
div(v-if="order")
  div(v-if="order.seller")
    .subtitle-2 {{ order.seller.name }}
    .caption {{ order.seller.address | address }}
    .caption {{ order.seller.phone | phone }}
  v-divider.my-1
  .d-flex.flex-row.mb-1
    v-icon.mr-2(color="success", small) mdi-ticket
    span \#{{ order.orderNumber }} | {{ platform }} | {{ order.type | orderType }} | {{ order.status | orderStatus }}
  .d-flex.flex-row.mb-1
    v-icon.mr-2(color="success", small) mdi-clock
    span {{ order.needed | datetime(null, timezone) }} ({{ timezone }})
  .d-flex.flex-row.mb-1
    v-icon.mr-2(color="success", small) mdi-cash
    span {{ order.payment.total | currency }} (tip: {{ order.payment.tip | currency }})
  div(v-if="order.customer")
    .d-flex.flex-row.mb-1(v-if="order.customer.name")
      v-icon.mr-2(color="success", small) mdi-account
      span {{ order.customer.name }}
    .d-flex.flex-row.mb-1(v-if="order.customer.phone")
      v-icon.mr-2(color="success", small) mdi-phone
      span {{ order.customer.phone | phone }}
    .d-flex.flex-row.mb-1(v-if="hasAddress")
      v-icon.mr-2(color="success", small) mdi-map-marker
      div
        span {{ order.customer.address | address }}
        span {{ order.customer.address.note }}
    .d-flex.flex-row.mb-1(v-if="order.customer.email")
      v-icon.mr-2(color="success", small) mdi-email
      ClickToCopy(:text="order.customer.email")
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn.ml-2(color="secondary", small, icon, v-on="on")
            v-icon(small) mdi-account
        v-list
          v-list-item(@click="showUser()") Show User
          v-list-item(@click="showOrders()") Show Orders
  .mb-1(v-if="order.cancelNote")
    .caption.red--text {{ order.cancelNote }}
  v-divider.my-1(v-if="!noDivider")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order", "noDivider"],
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
    platform() {
      if (this.order.platform) return this.order.platform;
      const type = this.order.orderer?.type;
      if (type === 0) return "Selflane";
      else if (type == 2) return this.order.od;
      return "POS";
    },
    userId() {
      return this.order?.customer?.id;
    },
    hasAddress() {
      return this.order?.customer?.address?.line1;
    },
  },
  methods: {
    showUser() {
      if (!this.userId) return;
      EventBus.$emit("show-user-detail", this.userId);
    },
    showOrders() {
      if (!this.userId) return;
      EventBus.$emit("list-order-by-userid", this.userId);
    },
  },
};
</script>
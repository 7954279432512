<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-form(@submit.prevent="handleSearch()")
      v-card-text
        v-radio-group(v-model="type", row)
          v-radio.text-capitalize(
            v-for="(item, index) in typeItems",
            :key="index + 'type'",
            :label="item",
            :value="item"
          )
        v-layout
          v-text-field(v-model="searchText", :label="searchLabel")
    Users(:users="users", @assign-user="assignUser")
    Bizs(:bizs="bizs", @assign-biz="assignBiz")
    Delivs(:delivs="delivs", @assign-deliv="assignDeliv")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import Bizs from "./Bizs";
import Delivs from "./Delivs";
import Users from "./Users";

export default {
  name: "TaxFileAssign",
  components: { Bizs, Delivs, Users },
  data() {
    return {
      dialog: false,
      searchText: "",
      type: "biz",
      typeItems: ["biz", "deliv", "user"],
      taxfile: null,
      bizs: [],
      delivs: [],
      users: [],
    };
  },
  computed: {
    searchLabel() {
      if (this.type == "biz") return "Biz Name";
      else if (this.type == "deliv") return "Company Name";
      else if (this.type == "user") return "User Email";
      return "N/A";
    },
  },
  mounted() {
    EventBus.$on("tax-file-assign", this.load);
  },
  destroyed() {
    EventBus.$off("tax-file-assign", this.load);
  },
  methods: {
    ...mapActions(["updateTaxFile"]),
    load(taxfile) {
      if (!taxfile) return;
      this.taxfile = taxfile;
      this.dialog = true;
      this.searchText = "";
      this.bizs = [];
      this.delivs = [];
      this.users = [];
    },
    reset() {
      this.bizs = [];
      this.delivs = [];
      this.users = [];
    },
    close() {
      this.reset(), (this.dialog = false);
    },
    async assignUser(user) {
      if (!user) return;
      const params = {
        fileid: this.taxfile._id,
        user: user._id,
        email: user.email,
      };
      try {
        const { data } = await this.axios.post("/tax/taxfile/assign", params);
        this.updateTaxFile(data);
        this.close();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async assignBiz(biz) {
      if (!biz) return;
      const params = {
        fileid: this.taxfile._id,
        biz: biz._id,
        email: biz.email,
      };
      try {
        const { data } = await this.axios.post("/tax/taxfile/assign", params);
        this.updateTaxFile(data);
        this.close();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async assignDeliv(deliv) {
      if (!deliv) return;
      const params = {
        fileid: this.taxfile._id,
        deliv: deliv._id,
        email: deliv.email,
      };
      try {
        const { data } = await this.axios.post("/tax/taxfile/assign", params);
        this.updateTaxFile(data);
        this.close();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    handleSearch() {
      this.reset();
      if (this.type == "biz") this.searchBiz();
      else if (this.type == "deliv") this.searchDeliv();
      else if (this.type == "user") this.searchUser();
    },
    async searchBiz() {
      const searchText = this.searchText.trim();
      if (!searchText) return;
      const params = {
        criteria: { name: { $regex: searchText } },
        select: "name address email",
        limit: 20,
      };
      this.bizs = await this.$api.biz.list(params);
    },
    async searchDeliv() {
      const searchText = this.searchText.trim();
      if (!searchText) return;
      const params = {
        criteria: { name: { $regex: searchText } },
        select: "name address email",
        limit: 20,
      };
      this.delivs = await this.$api.delivery.list(params);
    },
    async searchUser() {
      const searchText = this.searchText.trim();
      if (!searchText) return;
      const params = {
        criteria: { email: { $regex: searchText } },
        select: "name email",
        limit: 20,
      };
      this.users = await this.$api.user.list(params);
    },
  },
};
</script>

<template lang="pug">
div
  Create
  List.my-5
</template>

<script>
import Create from "./Create";
import List from "./List";
import { mapActions } from "vuex";

export default {
  components: { Create, List },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setMerchantIncomes"]),
    async load() {
      const params = { criteria: { year: new Date().getFullYear() } };
      const result = await this.$api.merchant.income.list(params);
      this.setMerchantIncomes(result);
    },
  },
};
</script>

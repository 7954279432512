<template lang="pug">
div
  List
  Summary.my-5
</template>

<script>
import Load from "./Load";
import List from "./List/index";
import Summary from "./Summary";

export default {
  components: { Load, List, Summary },
};
</script>

import moment from 'moment'

const state = {
  punchcards: []
}

const getters = {
  punchcards: state => state.punchcards
}

const actions = {
  setPunchcards: ({ commit }, data) => { commit('setPunchcards', data) },
  updatePunchcard: ({ commit }, data) => { commit('updatePunchcard', data) },
  addPunchcard: ({ commit }, data) => { commit('addPunchcard', data) },
  removePunchcard: ({ commit }, data) => { commit('removePunchcard', data) }
}

const mutations = {
  setPunchcards(state, data) {
    state.punchcards = data.map(o => {
      return extendHour(o)
    })
  },
  updatePunchcard(state, input) {
    const data = extendHour(input)
    const found = state.punchcards.find(o => o._id === data._id)
    if (found) {
      state.punchcards = state.punchcards.map(o => {
        if (o._id === data._id) return data
        return o
      })
    } else {
      state.punchcards.push(data)
    }
  },
  addPunchcard(state, data) {
    state.punchcards.push(extendHour(data))
  },
  removePunchcard(state, punchcard) {
    state.punchcards = state.punchcards.filter(o => o._id !== punchcard._id)
  }
}

export default { state, getters, actions, mutations }

function extendHour(card) {
  let hours = 0;
  if (card.clockOut && card.clockOut > 0) {
    hours = (card.clockOut - card.clockIn) / 1000 / 60 / 60;
  } else {
    hours = (Date.now() - card.clockIn) / 1000 / 60 / 60;
  }
  let day = moment(card.clockIn).day();
  if (day == 0) day = 7;
  return { ...card, hours, day }
}
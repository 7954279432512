const state = {
  delivs: []
};

const getters = {
  delivs: state => state.delivs
};

const actions = {
  setDelivs: ({ commit }, data) => { commit("setDelivs", data); },
  updateDeliv: ({ commit }, data) => { commit("updateDeliv", data); },
  addDeliv: ({ commit }, data) => { commit("addDeliv", data); },
  removeDeliv: ({ commit }, data) => { commit("removeDeliv", data); }
};

const mutations = {
  setDelivs(state, data) { state.delivs = data; },
  updateDeliv(state, data) {
    state.delivs = state.delivs.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addDeliv(state, data) { state.delivs.push(data); },
  removeDeliv(state, data) {
    state.delivs = state.delivs.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
v-btn(icon, small, @click="handleRemove()")
  v-icon(small, color="error") mdi-delete
</template>

<script>
export default {
  props: ["item"],
  methods: {
    handleRemove() {
      confirm("Do you really want to remove this inventory?") && this.remove();
    },
    async remove() {
      if (!this.item) return;
      const params = { id: this.item._id };
      try {
        await this.$api.catalog.inventory.misc.delete(params);
        this.$emit("deleted");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
<template lang="pug">
v-data-table(:headers="headers", :items="result" dense)
</template>

<script>
export default {
  props: ["result"],
  data() {
    return {
      headers: [
        { text: "Biz", value: "name" },
        { text: 'ID', value: 'biz', sortable: false },
        { text: "Count", value: "count", align: "end" },
      ],
    }
  }
};
</script>
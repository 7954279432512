<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", small, depressed, dark, v-on="on", @click.stop="") Favicon
  v-card(:loading="loading")
    v-card-title
      h2 Favicon
    v-card-text(v-if="domain.favicon")
      v-img(:src="domain.favicon", height="48px", width="48px")
    v-form(@submit.prevent="createFile")
      v-card-text
        v-file-input(v-model="file", :rules="rules", label="File")
      v-card-actions
        v-btn(color="primary", type="submit", :loading="loading") Create
        v-btn(@click="dialog = false") Cancel
</template>

<script>
export default {
  props: { domain: { type: Object, default: null } },
  data() {
    return {
      dialog: false,
      loading: false,
      file: null,
      rules: [(value) => !!value || "Required."],
    };
  },
  methods: {
    async createFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("domainId", this.domain._id);
      this.loading = true;
      try {
        await this.$api.b.domain.custom("uploadFavicon", formData);
        this.$toast.success("File created.");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create file.");
      }
      this.loading = false;
    },
  },
};
</script>
const state = {
  corpaccesses: []
}

const getters = {
  corpaccesses: state => state.corpaccesses,
}

const actions = {
  setCorpAccesses: ({ commit }, data) => { commit('setCorpAccesses', data) },
  addCorpAccess: ({ commit }, data) => { commit('addCorpAccess', data) },
  updateCorpAccess: ({ commit }, data) => { commit('updateCorpAccess', data) },
  removeCorpAccess: ({ commit }, data) => { commit('removeCorpAccess', data) },
}

const mutations = {
  setCorpAccesses(state, data) { state.corpaccesses = data },
  addCorpAccess(state, data) { state.corpaccesses.push(data) },
  updateCorpAccess(state, data) {
    state.corpaccesses = state.corpaccesses.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeCorpAccess(state, data) {
    state.corpaccesses = state.corpaccesses.filter(o => o._id !== data._id)
  },
}

export default { state, getters, actions, mutations }
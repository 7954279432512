<template lang="pug">
v-btn(
  @click="sendEmail(item)",
  outlined,
  color="secondary",
  small,
  :loading="loading"
)
  v-icon(color="secondary", small) mdi-send
</template>

<script>
export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  methods: {
    async sendEmail() {
      if (!this.item) return;
      const params = { id: this.item._id };
      this.loading = true;
      try {
        const { data } = await this.axios.post(
          "/announcement/sendEmail",
          params
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  :items="groups",
  :headers="headers",
  dense,
  mobile-breakpoint=0,
  v-if="groups?.length"
)
  template(v-slot:item.total="{ item }")
    span {{ item.total | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Total", value: "total" },
        { text: "Count", value: "count", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["orders"]),
    groups() {
      return _.chain(this.orders)
        .groupBy((item) => item.seller?.name || "Unknown")
        .map((list, name) => {
          const total = list.reduce((a, b) => a + b.payment.total, 0);
          return { name, count: list.length, total };
        })
        .sortBy("total")
        .value();
    },
  },
};
</script>

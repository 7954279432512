<template lang="pug">
simple-table(v-if="user")
  tbody
    tr(v-for="(item, index) in user.addresses", :key="index")
      td {{ item | address }}
      td.text-right
        v-btn(
          icon,
          x-small,
          color="error",
          :loading="loading",
          @click="submit(item)"
        )
          v-icon(small) mdi-delete
</template>

<script>
export default {
  props: ["user"],
  data() {
    return { loading: false };
  },
  methods: {
    async submit(address) {
      if (!this.user || !address) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.user._id },
        action: { $pull: { addresses: { _id: address._id } } },
        select: this.select,
      };
      try {
        const result = await this.$api.user.update(params);
        this.$emit("update", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
const state = {
  bizorders: []
}

const getters = {
  bizorders: state => state.bizorders
}

const actions = {
  setBizOrders: ({ commit }, data) => { commit('setBizOrders', data) },
  updateBizOrder: ({ commit }, data) => { commit('updateBizOrder', data) },
  deleteBizOrder: ({ commit }, data) => { commit('deleteBizOrder', data) }
}

const mutations = {
  setBizOrders(state, data) { state.bizorders = data },
  updateBizOrder(state, data) {
    state.bizorders = state.bizorders.map(o => { return o._id === data._id ? data : o })
  },
  deleteBizOrder(state, data) {
    state.bizorders = state.bizorders.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
const state = {
  expSources: []
}

const getters = {
  expSources: state => state.expSources
}

const actions = {
  setExpSources: ({ commit }, data) => { commit('setExpSources', data) },
  updateExpSource: ({ commit }, data) => { commit('updateExpSource', data) },
  addExpSource: ({ commit }, data) => { commit('addExpSource', data) },
  removeExpSource: ({ commit }, data) => { commit('removeExpSource', data) }
}

const mutations = {
  setExpSources(state, data) {
    state.expSources = data
  },
  updateExpSource(state, data) {
    state.expSources = state.expSources.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addExpSource(state, data) {
    state.expSources = state.expSources.concat([data])
  },
  removeExpSource(state, data) {
    state.expSources = state.expSources.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
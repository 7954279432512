<template lang="pug">
v-data-table(:items="bizs", :headers="headers", v-if="bizs && bizs.length")
  template(v-slot:item.action="{ item }")
    v-btn(small, color="secondary", depressed, @click="assign(item)") assign
</template>

<script>
export default {
  props: ["bizs"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  methods: {
    assign(biz) {
      if (!biz) return;
      this.$emit("assign-biz", biz);
    },
  },
};
</script>

<template lang="pug">
span {{ name }}
</template>

<script>
export default {
  name: "InventoryInListProductName",
  props: ["users", "id"],
  computed: {
    name() {
      if (this.id) {
        const found = this.users?.find((o) => o._id == this.id);
        return found?.email || this.id;
      }
      return "";
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Regions: ({{ filtered?.length || 0 }} regions)
    v-spacer
    IconBtn(@click="add", title="Add", icon="mdi-plus")
  simple-table
    thead
      tr
        th Name
        th Description
        th.text-right Bizs
    tbody
      tr(
        v-for="item in filtered",
        :key="item._id",
        @click="edit(item)",
        role="button"
      )
        th {{ item.name }}
        td {{ item.description }}
        td.text-right {{ item.bizs?.length }}
  Form(:bizs="bizs", ref="form")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";
import Form from "./Form";

export default {
  name: "EnterpriseRegionList",
  components: { Cell, Form },
  props: {
    bizs: { type: Array, required: true },
  },
  computed: {
    ...mapGetters("entp", ["enterprise", "regions"]),
    filtered() {
      return this.regions
        .filter((o) => o.enterprise == this.enterprise?._id)
        .toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    edit(item) {
      this.$refs.form.open(item);
    },
    add() {
      this.$refs.form.open();
    },
  },
};
</script>

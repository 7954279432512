<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Date
        th Begin
        th End
        th #Selflane
        th #Others
        th Deduct
        th Total
    tbody
      tr(v-for="(item, index) in deliveryPayouts", :key="index")
        td {{ item.created | date({ dynamic: true }) }}
        td {{ item.begin | date({ dynamic: true }) }}
        td {{ item.end | date({ dynamic: true }) }}
        td {{ item.count }}
        td {{ item.countNS }}
        td {{ item.deduct | currency }}
        td {{ item.amount | currency }} {{ item.currency }}
    tfoot
      tr
        td Sum
        td
        td
        td {{ sum.count }}
        td {{ sum.countNS }}
        td {{ sum.deduct | currency }}
        td {{ sum.amount | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["deliveryPayouts"]),
    sum() {
      return {
        count: this.deliveryPayouts.reduce((a, b) => a + b.count, 0),
        countNS: this.deliveryPayouts.reduce((a, b) => a + b.countNS, 0),
        deduct: this.deliveryPayouts.reduce((a, b) => a + b.deduct, 0),
        amount: this.deliveryPayouts.reduce((a, b) => a + b.amount, 0),
      };
    },
  },
};
</script>

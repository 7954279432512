<template lang="pug">
v-list-item(v-if="valid", @click.stop="handleProcess()") Charge Deliver On Cancel
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  computed: {
    valid() {
      return this.order.deliv && this.order.status == -1;
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleProcess() {
      confirm(
        "Do you really want to issue a delivery charge to this canceled?"
      ) && this.process();
    },
    async process() {
      if (!this.order) return;
      const params = { orderId: this.order._id };
      try {
        await this.axios.post("/orders/chargeDeliverOnCancel", params);
        this.$toast.success("Charge deliver on cancel successful");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

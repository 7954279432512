<template lang="pug">
div
  .d-flex.flex-row.mb-3
    MonthSelector(@select="load")
    v-spacer
    v-btn(
      rounded,
      outlined,
      small,
      color="secondary",
      @click="generate()",
      :loading="loading"
    ) Generate
    CorrectZone.ml-3
  Table(:items="items", @delete="deleteOne")
</template>

<script>
import Table from "./Table/index";
import CorrectZone from "./CorrectZone";
import moment from "moment-timezone";

export default {
  components: { Table, CorrectZone },
  data() {
    return {
      selectedMonth: moment().format("YYYY-MM"),
      items: [],
      loading: false,
    };
  },
  methods: {
    async load(selected) {
      if (selected) this.selectedMonth = selected.name;
      const begin = parseInt(this.selectedMonth.replace(/-/g, "")) * 100;
      const end = begin + 31;
      const params = { criteria: { date: { $gte: begin, $lte: end } } };
      const { data } = await this.axios.post(
        "/delivery/dailysummary/list",
        params
      );
      this.items = data;
    },
    async generate() {
      this.loading = true;
      const day = moment(this.selectedMonth, "YYYY-MM").endOf("month").date();
      for (var i = 1; i < day + 1; i++) {
        const str = String(i);
        const date = this.selectedMonth + "-" + str.padStart(2, "0");
        await this.axios.post("/delivery/dailysummary/create", { date });
      }
      this.loading = false;
      this.load();
    },
    async deleteOne(item) {
      const params = { id: item._id };
      await this.axios.post("/delivery/dailysummary/deleteOne", params);
      this.items = this.items.filter((o) => o._id != item._id);
    },
  },
};
</script>

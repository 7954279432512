<template lang="pug">
v-btn.text-capitalize(
  @click="handlePayout()",
  outlined,
  small,
  depressed,
  color="grey darken-2"
) Pay
  v-icon(right, small) mdi-send
</template>

<script>
export default {
  props: ["driver"],
  methods: {
    handlePayout() {
      confirm("Do you want to payout this driver") && this.payout();
    },
    async payout() {
      if (!this.driver) return;
      const params = { driverId: this.driver._id };
      try {
        const { data } = await this.axios.post(
          "/driver/payouts/create",
          params
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
.my-3(v-if="overPaid > 0.1")
  v-btn(
    @click="send()",
    outlined,
    small,
    color="secondary",
    :loading="loading"
  ) Alert POS Overpayment
  span.mx-2 {{ message }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["order"],
  data() {
    return { loading: false };
  },
  computed: {
    charges() {
      let items = [];
      items.push(this.order.payment.charges);
      _.each(this.order.splitChecks, (check) => {
        items.push(check.payment.charges);
      });
      return _.flatten(items);
    },
    totalPaid() {
      return this.charges?.reduce((a, b) => a + b.amount, 0) || 0;
    },
    overPaid() {
      return (
        Math.round((this.totalPaid - this.order.payment.total) * 100) / 100
      );
    },
    duplicateCards() {
      const counts = _.chain(this.charges).pluck("last4").countBy().value();
      const result = _.chain(counts)
        .map((count, last4) => {
          return { last4: last4, count: count };
        })
        .reject((o) => o.count == 1)
        .map((o) => "Card ..." + o.last4 + " is charged " + o.count + " times")
        .value();
      if (result.length) return " " + result.join(". ") + ".";
      return "";
    },
    message() {
      if (!this.order) return;
      return (
        "Order #" +
        this.order.orderNumber +
        " is over paid by $" +
        this.overPaid +
        "." +
        this.duplicateCards +
        " Please void the extra payment."
      );
    },
  },
  methods: {
    async send() {
      if (!this.order) return;
      const params = { bizId: this.order.seller.id, message: this.message };
      this.loading = true;
      await this.axios.post("/bizNotif/messagePOS", params);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-btn(
  :loading="loading",
  @click="handleSubmit()",
  icon,
  small,
  color="error",
  v-if="valid"
)
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["item", "order", "check"],
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["transactions"]),
    authcode() {
      return this.item.approve;
    },
    voided() {
      if (!this.authcode) return false;
      return this.transactions
        ?.filter((o) => o.biz == this.order.seller.id)
        ?.some((o) => o.host?.authCode == this.authcode && o.type == "17");
    },
    valid() {
      if (this.voided) return true;
      return (
        this.item.method == "cash" ||
        this.item.method == "excard" ||
        this.item.method == "check" ||
        // this.item.method == "card"
        (this.item.method == "card" && !this.item.device.loc)
      );
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleSubmit() {
      confirm("Do you really want to void this charge?") && this.submit();
    },
    async submit() {
      this.loading = true;
      let params;
      let path = "voidCharge";
      if (this.check) {
        params = {
          orderId: this.order._id,
          checkId: this.check._id,
          chargeId: this.item._id,
        };
        path = "voidChargeCheck";
      } else {
        params = { orderId: this.order._id, chargeId: this.item._id };
      }
      try {
        const result = await this.$api.order.custom(path, params);
        this.updateOrder(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

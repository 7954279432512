<template lang="pug">
v-data-table(:headers="headers", :items="items", dense, mobile-breakpoint=0)
</template>

<script>
export default {
  data() {
    return {
      items: [],
      headers: [
        { text: "Country", value: "country" },
        { text: "Count", value: "count", align: "end" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const path = "/driver/accounts/stats/countByCountry";
      const { data } = await this.axios.post(path, {});
      this.items = data;
    },
  },
};
</script>
<template lang="pug">
div
  Growth
</template>

<script>
import Growth from "./Growth/index";

export default {
  components: { Growth },
};
</script>

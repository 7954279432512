const state = {
  driverRewardPenalties: [],
};

const getters = {
  driverRewardPenalties: state => state.driverRewardPenalties,
};

const actions = {
  setRewardPenalties: ({ commit }, data) => { commit("setRewardPenalties", data); },
  updateRewardPenalty: ({ commit }, data) => { commit("updateRewardPenalty", data); },
  addRewardPenalty: ({ commit }, data) => { commit("addRewardPenalty", data); },
  removeRewardPenalty: ({ commit }, data) => { commit("removeRewardPenalty", data); }
};

const mutations = {
  setRewardPenalties(state, data) { state.driverRewardPenalties = data; },
  updateRewardPenalty(state, data) {
    state.driverRewardPenalties = state.driverRewardPenalties.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addRewardPenalty(state, data) { state.driverRewardPenalties.push(data); },
  removeRewardPenalty(state, data) {
    state.driverRewardPenalties = state.driverRewardPenalties.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="open()", :loading="loading") Authorization
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Authorization with Card Connect (not Bolt)
      v-form(@submit.prevent="load()")
        v-card-text
          v-text-field(v-model.number="amount", label="Amount (cent)")
          v-btn-toggle(
            v-model="account_type",
            dense,
            rounded,
            color="secondary"
          )
            v-btn(
              :value="item",
              v-for="(item, index) in account_type_items",
              :key="index",
              x-small
            ) {{ item }}
          v-text-field(
            v-model="name",
            label="Name",
            v-if="account_type != 'profile'"
          )
          AccountField(
            v-model="bankaba",
            label="Bank routing (ABA) number (if ACH)",
            :num="step",
            v-if="account_type == 'ach'"
          )
          AccountField(
            v-model="account",
            label="Account",
            :num="step",
            v-if="account_type != 'profile'"
          )
          v-text-field(
            v-model="profile",
            label="Profile ID",
            v-if="account_type == 'profile'"
          )
          v-text-field(v-model="cvv2", label="CVV (if card)")
          v-text-field(v-model="expiry", label="Expiry (MMYY) (if card)")
          v-row(wrap)
            v-col(cols="4")
              v-select(
                v-model="ecomind",
                label="Indicator",
                :items="ecomind_items"
              )
            v-col(cols="4")
              v-select(v-model="cof", label="COF", :items="cof_items")
            v-col(cols="4")
              v-select(
                v-model="cofscheduled",
                label="COF Scheduled",
                :items="cofscheduled_items"
              )
            v-col(cols="4", v-if="account_type != 'profile'")
              v-select(
                v-model="profile",
                label="Save to Profile",
                :items="profile_items"
              )
            v-col(cols="4")
              v-select(
                v-model="capture",
                label="Capture",
                :items="capture_items"
              )
          div(v-if="result")
            .subtitle-2 Result
            pre {{ result }}
        v-card-actions
          v-btn(color="primary", type="submit", :loading="loading") Submit
</template>

<script>
import AccountField from "../../AccountField.vue";

export default {
  components: { AccountField },
  props: {
    mid: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      amount: null,
      expiry: "",
      name: "",
      account_type: "card",
      account: "",
      bankaba: "",
      cvv2: "",
      cof: "",
      cofscheduled: "",
      profile: "",
      ecomind: "E",
      capture: "Y",
      result: null,
      dialog: false,
      account_type_items: ["card", "ach", "profile"],
      ecomind_items: [
        { text: "Ecommerce", value: "E" },
        { text: "Telephone/Mail", value: "T" },
        { text: "Recurring", value: "R" },
      ],
      cof_items: [
        { text: "Customer Initiated", value: "C" },
        { text: "Merchant Initiated", value: "M" },
      ],
      cofscheduled_items: [
        { text: "Scheduled Payment", value: "Y" },
        { text: "One Time", value: "N" },
      ],
      profile_items: [
        { text: "Yes", value: "Y" },
        { text: "No", value: "" },
      ],
      capture_items: ["Y", "N"],
    };
  },
  computed: {
    step() {
      if (this.account_type == "ach") return 3;
      return 4;
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = {
        mid: this.mid,
        amount: this.amount,
        expiry: this.expiry,
        name: this.name,
        account: this.account,
        bankaba: this.bankaba,
        cvv2: this.cvv2,
        ecomind: this.ecomind,
        profile: this.profile,
        cof: this.cof,
        cofscheduled: this.cofscheduled,
        capture: this.capture,
      };
      if (this.account_type == "profile") {
        delete params.expiry;
        delete params.account;
        delete params.bankaba;
        delete params.cvv2;
        delete params.name;
      } else if (this.account_type == "ach") {
        delete params.expiry;
        delete params.cvv2;
      }
      try {
        this.result = await this.$api.cardpointe.cardconnect.auth(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
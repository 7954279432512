<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="lead")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(
          v-model="lead.company_name",
          label="Company Name",
          required
        )
        v-row
          v-col(cols="6")
            PhoneField(v-model="lead.phone")
          v-col(cols="6")
            v-text-field(v-model="lead.email", label="Email")
          v-col(cols="6")
            SizeSelector(v-model="lead.size")
          v-col(cols="6")
            TagInput(v-model="lead.tags")
        GoogleAddressField(v-model="lead.address")
        v-row
          v-col(cols="6")
            ContactPicker(v-model="lead.contact")
          v-col(cols="6")
            v-select(
              :disabled="!isInternal",
              v-model="lead.organization",
              :items="organization_items",
              label="Organization"
            )
        v-text-field(v-model="lead.notes", label="Notes", multi-line)
        .list-box(v-if="lead.biz")
          .subtitle-2 Biz: {{ lead.biz_name }}
          v-btn(icon, small, @click="clearBiz", color="error")
            v-icon(small) mdi-close
        BizSelector(
          @select="selectBiz",
          v-else,
          label="Assign a Biz",
          :auto-select="false"
        )
      v-card-actions
        v-btn(color="secondary", :loading="loading", type="submit") Save
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import ContactPicker from "/libs/components/CRM/Contact/Picker";
import SizeSelector from "/libs/components/CRM/FormInput/SizeSelector";
import TagInput from "/libs/components/CRM/FormInput/TagInput";

export default {
  components: { ContactPicker, SizeSelector, TagInput },
  data() {
    return {
      dialog: false,
      loading: false,
      lead: {},
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations", "salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    title() {
      return this.lead._id ? "Edit Lead" : "New Lead";
    },
    organization_items() {
      return this.salesOrganizations.map((o) => ({
        text: o.name,
        value: o._id,
      }));
    },
  },
  mounted() {
    EventBus.$on("edit-lead", this.open);
  },
  destroyed() {
    EventBus.$off("edit-lead", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.lead = JSON.parse(JSON.stringify(data));
      } else {
        this.lead = {};
      }
      this.dialog = true;
    },
    selectBiz(biz) {
      this.lead.biz = biz._id;
      this.lead.biz_name = biz.name;
    },
    clearBiz() {
      this.lead.biz = null;
      this.lead.biz_name = null;
    },
    async submit() {
      const sales_person = this.lead.sales_person || this.salesPerson._id;
      const sales_person_name =
        this.lead.sales_person_name || this.salesPerson.first_name;
      if (!sales_person) {
        this.$toast.error("Sales person is required");
        return;
      }
      this.loading = true;
      const action = {
        company_name: this.lead.company_name,
        phone: this.lead.phone,
        email: this.lead.email,
        address: this.lead.address,
        notes: this.lead.notes,
        contact: this.lead.contact,
        biz: this.lead.biz,
        biz_name: this.lead.biz_name,
        organization: this.lead.organization,
        sales_person,
        sales_person_name,
        status: this.lead.status,
        size: this.lead.size,
        tags: this.lead.tags,
        updated: Date.now(),
      };
      try {
        if (this.lead._id) {
          delete action.status;
          const result = await this.$api.crm.lead.put(this.lead._id, action);
          this.$store.dispatch("crm/updateLead", result);
          this.$emit("done", result);
        } else {
          const result = await this.$api.crm.lead.create(action);
          this.$store.dispatch("crm/addLead", result);
          this.$emit("done", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  simple-table
    thead
      tr
        th Domain
        th.text-right Title
        th.text-right Action
    tbody
      tr(
        v-for="domain in domains",
        @click="$emit('select', domain)",
        :key="domain._id",
        role="button"
      )
        td {{ domain.url }}
        td.text-right {{ domain.title }}
        td.text-right
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn(v-on="on", icon)
                v-icon mdi-dots-horizontal
            v-list
              v-list-item(@click.stop="editGallery(domain)")
                v-list-item-title Edit Gallery
  Gallery(ref="gallery")
</template>

<script>
import Favicon from "/libs/components/Biz/Domain/Favicon/index.vue";
import Gallery from "/libs/components/Biz/Domain/Gallery/index.vue";

export default {
  components: { Favicon, Gallery },
  data() {
    return {
      domains: [],
    };
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      try {
        this.domains = await this.$api.b.domain.list({ criteria: {} });
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async editGallery(domain) {
      this.$refs.gallery.open(domain);
    },
  },
};
</script>
<template lang="pug">
div
  v-btn.text-capitalize.mb-3(to="/member/upload") Upload Members
  Clean.mb-3
  PopulatePhone.mb-3
</template>

<script>
import Clean from "./Clean";
import PopulatePhone from "./PopulatePhone";

export default {
  components: { Clean, PopulatePhone },
};
</script>

<style lang="sass" scoped>
button
  display: block
</style>
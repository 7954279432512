<template lang="pug">
.pa-3
  Count
  Growth
</template>

<script>
import Count from "./Count";
import Growth from "./Growth/index";

export default {
  components: { Count, Growth },
};
</script>

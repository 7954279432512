<template lang="pug">
div
  Block.my-3(:group="group", v-for="(group, gindex) in groups", :key="gindex")
</template>

<script>
import _ from "underscore";
import Block from "./Block";

export default {
  components: { Block },
  props: ["items"],
  computed: {
    groups() {
      const data = _.chain(this.items)
        .groupBy("currency")
        .map((list, currency) => {
          const rows = _.chain(list)
            .groupBy("name")
            .map((list_name, name) => {
              const items = _.chain(_.range(1, 13))
                .map((month) => {
                  const found = list_name.find((o) => o.period.month == month);
                  const amount = found?.amount || 0;
                  const quantity = found?.quantity || 0;
                  return { amount, quantity };
                })
                .value();
              const total = items.reduce((a, b) => a + b.amount, 0);
              return { name, items, total };
            })
            .sortBy((o) => -o.total)
            .value();
          return { currency, rows };
        })
        .value();
      return data;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="900", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="story")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} Story
        v-spacer
        v-switch(v-model="story.published", label="Published")
      v-card-text
        v-row
          v-col(cols="6")
            v-text-field(label="Title", v-model="story.title")
          v-col(cols="6")
            DateFieldPicker(label="date", v-model="date")
        v-text-field(label="Artwork", v-model="story.artwork")
        v-row
          v-col(cols="10")
            v-text-field(label="url", v-model="story.url")
          v-col(cols="2")
            v-btn(@click="sluggify", text) Auto
        v-md-editor.mb-2(v-model="story.summary")
        v-md-editor(v-model="story.content")
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data() {
    return {
      story: null,
      dialog: false,
      loading: false,
      date: moment().toISOString().substring(0, 10),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.story && this.story._id) return "Edit an";
      else return "Create a New";
    },
  },
  methods: {
    ...mapActions(["addSuccessStory", "updateSuccessStory"]),
    open(data) {
      if (!data) {
        this.story = {
          title: "",
          url: "",
          summary: "",
          content: "",
          published: false,
        };
      } else {
        this.story = JSON.parse(JSON.stringify(data));
      }
      this.date = moment(this.story.date).toISOString().substring(0, 10);
      this.dialog = true;
    },
    sluggify() {
      this.story.url = this.story.title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    submit() {
      if (!this.story) return;
      if (!this.story._id) this.create();
      else this.update();
    },
    async update() {
      if (!this.story._id) return;
      const params = {
        criteria: { _id: this.story._id },
        action: {
          $set: {
            title: this.story.title,
            summary: this.story.summary,
            content: this.story.content,
            artwork: this.story.artwork,
            date: moment(this.date).valueOf(),
            url: this.story.url,
            published: this.story.published,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.successStory.update(params);
        this.updateSuccessStory(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async create() {
      if (this.story._id) return;
      this.story.date = moment(this.date).valueOf();
      this.loading = true;
      try {
        const result = await this.$api.successStory.create(this.story);
        this.addSuccessStory(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-card-text
    v-text-field(
      v-model="searchText",
      solo,
      placeholder="Search Order Number",
      clearable,
      dense
    )
  v-data-table(
    :items="orders",
    :headers="headers",
    @click:row="select",
    role="button",
    :search="searchText",
    dense,
    mobile-breakpoint=0
  )
    template(v-slot:item.needed="{ item }")
      span {{ item.needed | time }}
    template(v-slot:item.payment.total="{ item }")
      span {{ item.payment.total | currency }}
  v-card-text
    span Missed transactions:
      v-btn.ml-1(
        v-for="(item, index) in missed",
        :key="index + 'missed'",
        text,
        small,
        @click="select(item)"
      ) \#{{ item?.orderNumber }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      selected: null,
      searchText: null,
      headers: [
        { text: "#", value: "orderNumber" },
        { text: "Needed", value: "needed", filterable: false },
        { text: "Total", value: "payment.total", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["orders", "transactions", "biz"]),
    missed() {
      return _.chain(this.transactions)
        .pluck("trace")
        .pluck("refNum")
        .compact()
        .uniq()
        .sortBy()
        .map((o) => {
          const items = o.split("=");
          if (items.length < 1) return;
          const orderNumber = items[0];
          return this.orders.find((o) => o.orderNumber == orderNumber);
        })
        .value();
    },
  },
  methods: {
    ...mapActions(["setOrder"]),
    async select(item) {
      this.$emit("select", item?._id);
    },
  },
};
</script>


<style lang="sass" scoped>
.pane
  overflow-y: scroll
  max-height: 800px
</style>
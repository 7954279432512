<template lang="pug">
v-btn(
  color="secondary",
  @click="handleEmail",
  outlined,
  small,
  :loading="loading"
)
  v-icon(icon, left, small) mdi-email
  span.text-notransform Email Again
</template>

<script>
export default {
  props: ["bizorder"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async handleEmail() {
      this.loading = true;
      const id = this.bizorder._id;
      await this.$api.bizOrder.custom("send", { bizOrderId: id });
      this.loading = false;
      this.$toast.success("Email sent");
    },
  },
};
</script>

<template lang="pug">
div
  v-row(align="center")
    v-col(cols="6")
      v-select(:items="items", v-model="sel_biz", solo, hide-details, dense)
    MonthSelector(@select="selectMonth")
    v-btn.ml-3(rounded, depressed, small, color="accent", @click="load()") Load
  Month(
    :year="year",
    :month="month",
    :result="result",
    :emailResult="emailResult"
  )
</template>

<script>
import Month from "./Month";

export default {
  components: { Month },
  props: ["bizs"],
  data: () => ({
    sel_biz: null,
    month: 0,
    year: 0,
    result: null,
    emailResult: null,
  }),
  computed: {
    items() {
      return this.bizs?.map((o) => {
        return { text: o.name, value: o.biz };
      });
    },
  },
  methods: {
    selectMonth(data) {
      if (!data || !data.name) return;
      const items = data.name.split("-");
      if (!items || items.length != 2) return;
      this.year = parseInt(items[0]);
      this.month = parseInt(items[1]);
    },
    async load() {
      if (!this.sel_biz) return this.$toast.error("Select a business first");

      const params = {
        bizId: this.sel_biz,
        year: this.year,
        month: this.month,
      };
      try {
        const url = "/member/report/monthly";
        const { data } = await this.axios.post(url, params);
        this.result = data;
        await this.loadEmails(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async loadEmails(params) {
      const url = "/memberEmail/report/monthly";
      const { data } = await this.axios.post(url, params);
      this.emailResult = data;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="700")
  v-card(v-if="biz")
    v-card-title Business Hours
    v-card-text
      Hours(:biz="biz", @updated="updated")
</template>

<script>
import Hours from "/libs/components/Biz/Hours";

export default {
  components: { Hours },
  data() {
    return {
      dialog: false,
      biz: null,
    };
  },
  methods: {
    open(item) {
      this.biz = item;
      this.dialog = true;
    },
    updated(data) {
      if (data) this.biz = data;
      this.$emit("updated");
    },
  },
};
</script>
<template lang="pug">
v-btn(
  @click="handleRefund()",
  color="error",
  :loading="loading",
  x-small,
  outlined
) Refund
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["invoice", "selected"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    handleRefund() {
      if (!this.selected?.length) return this.$toast.error("No item selected");
      confirm("Do you want to refund selected items?") && this.refund();
    },
    async refund() {
      const itemIds = this.selected.map((item) => item._id);
      const params = { invoiceId: this.invoice._id, itemIds };
      this.loading = true;
      try {
        const result = await this.$api.invoice.custom("refund", params);
        this.updateInvoice(result);
        this.$toast.success("Refund was successful");
        this.$emit("done", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.response);
      }
      this.loading = false;
    },
  },
};
</script>

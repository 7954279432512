<template lang="pug">
v-list-item(@click="submit()") Set Payout to Order
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["payout"],
  methods: {
    ...mapActions(["updateDriverPayout"]),
    async submit() {
      if (!this.payout) return;
      const params = { payoutId: this.payout._id };
      try {
        const { data } = await this.axios.post(
          "/driver/payouts/setOrder",
          params
        );
        this.updateDriverPayout(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

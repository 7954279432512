<template lang="pug">
div
  v-card.mb-3(v-for="(item, index) in items", :key="index", flat)
    v-toolbar(flat, dense)
      span {{ item.title }}
      v-spacer
      v-btn.mr-2(@click="edit(item)", outlined, color="secondary", small)
        v-icon(color="secondary", small) mdi-pencil
    v-card-text
      v-md-preview(:text="item.summary")
      div {{ item.date | date }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["podcasts"]),
    items() {
      return this.podcasts.toSorted((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>
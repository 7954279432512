<template lang="pug">
div(v-if="visible")
  StandardChart(:input="userData", :showDownload="showDownload")
</template>

<script>
import _ from "underscore";

export default {
  props: ["year", "type", "showDownload"],
  data() {
    return {
      result: [],
    };
  },
  computed: {
    visible() {
      return this.type == "weekly";
    },
    userData() {
      const months = _.map(this.result, (o) => {
        return o.year + "/" + o.week;
      });
      return {
        title: "User Growth / Week",
        labels: months,
        values: this.result.map((o) => o.count),
      };
    },
  },
  watch: {
    year() {
      this.load();
    },
    type() {
      this.load();
    },
  },
  methods: {
    async load() {
      if (!this.year || !this.visible) return;
      const params = { year: this.year };
      const result = await this.$api.influencer.account.custom(
        "stats/growth_weekly",
        params
      );
      this.result = _.chain(result).sortBy("week").sortBy("year").value();
    },
  },
};
</script>

<style lang="sass" scoped>
.growth-box
  max-width: 500px
  width: 95%
  margin-left: auto
  margin-right: auto
</style>
<template lang="pug">
v-form(@submit.prevent="search")
  .d-flex.flex-row.align-center
    v-select.text-capitalize(
      dense,
      solo,
      v-model="type",
      :items="items",
      hide-details
    )
    v-text-field.mx-3(
      v-model="pattern",
      autofocus,
      label="Search",
      solo,
      dense,
      hide-details
    )
    v-btn(type="submit", color="primary", :loading="loading", icon)
      v-icon mdi-magnify
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      pattern: "",
      type: "Name",
      loading: false,
      items: [
        "Name",
        "Email",
        "Owner's Email",
        "ID",
        "Stripe Account",
        "Stripe Customer ID",
      ],
    };
  },
  watch: {
    pattern() {
      // is object id
      if (this.pattern.length == 24 && this.pattern.match(/^[0-9a-fA-F]+$/)) {
        this.type = "ID";
        this.search();
        return;
      }
      // is striper customer id
      if (this.pattern.length == 18 && this.pattern.startsWith("cus_")) {
        this.type = "Stripe Customer ID";
        this.search();
        return;
      }
      // is striper account id
      if (this.pattern.length == 18 && this.pattern.startsWith("acct_")) {
        this.type = "Stripe Account";
        this.search();
        return;
      }
    },
  },
  mounted() {
    this.setBizAccounts([]);
    this.setBiz(null);
  },
  destroyed() {
    this.setBizAccounts([]);
  },
  methods: {
    ...mapActions(["setBizAccounts", "setBiz"]),
    search() {
      this.pattern = this.pattern.trim();
      if (!this.pattern) {
        this.$toast.error("Please enter a search text.");
        return;
      }
      let criteria;
      if (this.type == "Email") {
        criteria = { email: { $regex: this.pattern, $options: "i" } };
      } else if (this.type == "Owner's Email") {
        this.searchOwner();
        return;
      } else if (this.type == "ID") {
        criteria = { _id: this.pattern };
      } else if (this.type == "Stripe Account") {
        criteria = { "payments.managed_account": this.pattern };
      } else if (this.type == "Stripe Customer ID") {
        criteria = { "payments.customer": this.pattern };
      } else {
        criteria = { name: { $regex: this.pattern, $options: "i" } };
      }
      this.load(criteria);
    },
    async searchOwner() {
      // get user
      let user;
      try {
        const params = { criteria: { email: this.pattern }, select: "email" };
        user = await this.$api.user.retrieve(params);
      } catch (err) {
        this.$toast.error("User not found.");
        return;
      }
      // get access
      let access;
      try {
        const params = { criteria: { user: user._id } };
        access = await this.$api.bizAccess.list(params);
      } catch (err) {
        this.$toast.error("User has no access to any business.");
        return;
      }
      if (!access?.length) {
        this.$toast.error("User has no access to any business.");
        return;
      }
      const ids = access.map((o) => o.biz);
      this.load({ _id: { $in: ids } });
    },
    async load(criteria) {
      if (!criteria) return;
      this.loading = true;
      const select =
        "name short_name status orderType tax_items online_start pos_start created address industry geo_service_zone standing";
      const params = { criteria, select, limit: 100 };
      try {
        const result = await this.$api.biz.list(params);
        this.setBizAccounts(result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load bizs");
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  Create.mb-3
  List
</template>

<script>
import Create from "./Create";
import List from "./List/index";

export default {
  components: { Create, List },
};
</script>
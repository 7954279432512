<template lang="pug">
v-btn(@click="jwtTest", :loading="loading") JWT Test
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async jwtTest() {
      this.loading = true;
      try {
        const result = await this.$api.misc.custom("jwt");
        console.log(result);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>
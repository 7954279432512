import Home from './index'
import Search from './Search/index.vue'
import Month from './Month'
import Stripe from './Stripe'
import Online from './Online/index.vue'
import StripeDescriptor from './StripeDescriptor/index.vue'
import Timecard from './Timecard/index.vue'
import Requests from './Requests/index.vue'
import Domains from './Domains/index.vue'

export default [{
  path: '/bizs', component: Home, meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'search' },
    { path: 'search', component: Search },
    { path: 'month', component: Month },
    { path: 'stripe', component: Stripe },
    { path: 'stripe_descriptor', component: StripeDescriptor },
    { path: 'timecard', component: Timecard },
    { path: 'online', component: Online },
    { path: 'requests', component: Requests },
    { path: 'domains', component: Domains }
  ]
}]
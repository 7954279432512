<template lang="pug">
Page(title="Delivery Companies")
  List
  SetZone
</template>

<script>
import List from "./List";
import { mapActions } from "vuex";
import SetZone from "./SetZone";

export default {
  components: { List, SetZone },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setDelivs"]),
    load() {
      const params = {
        criteria: {},
        select: "name email phone verified zones country states",
      };
      this.axios.post("/delivery/accounts/list", params).then((response) => {
        this.setDelivs(response.data);
      });
    },
  },
};
</script>

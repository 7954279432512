<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Created
        th Period
        th # Orders
        th Fee
        th Tip
        th Reward
        th Deduct
        th Transfer Amount
        th Name
        th
    tbody
      tr(v-for="(item, index) in driverPayouts", :key="index")
        td {{ item.created | date({ dynamic: true }) }}
        td {{ item.begin | date({ dynamic: true }) }} - {{ item.end | date({ dynamic: true }) }}
        td {{ item.count }}
        td {{ item.fee | currency }}
        td {{ item.tip | currency }}
        td {{ item.reward | currency }}
        td {{ -item.deduct | currency }}
        td {{ item.amount | currency }} {{ item.currency }}
        td
          span(v-if="item.name") {{ item.name }}
          span(v-else) {{ item.email }}
        td
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn(icon, small, v-on.stop="on")
                v-icon(color="secondary") mdi-dots-horizontal
            v-list(dense)
              PopulateOrders(:payout="item")
              ShowOrders(:payout="item")
              SetOrders(:payout="item")
          UpdateDeliv(:payout="item")
    tfoot
      tr
        td Summary
        td {{ driverPayouts.length }}
        td {{ sum.count }}
        td {{ sum.fee | currency }}
        td {{ sum.tip | currency }}
        td {{ sum.reward | currency }}
        td {{ -sum.deduct | currency }}
        td {{ sum.amount | currency }}
        td
  DelivOrders
  SendFund
</template>

<script>
import { mapGetters } from "vuex";
import PopulateOrders from "./PopulateOrders";
import ShowOrders from "./ShowOrders";
import SetOrders from "./SetOrders";
import UpdateDeliv from "./UpdateDeliv";
import DelivOrders from "./DelivOrders";
import SendFund from "./SendFund";

export default {
  components: {
    PopulateOrders,
    SetOrders,
    ShowOrders,
    UpdateDeliv,
    DelivOrders,
    SendFund,
  },
  computed: {
    ...mapGetters(["driverPayouts"]),
    sum() {
      return {
        count: this.driverPayouts.reduce((a, b) => a + b.count, 0),
        fee: this.driverPayouts.reduce((a, b) => a + b.fee, 0),
        tip: this.driverPayouts.reduce((a, b) => a + b.tip, 0),
        reward: this.driverPayouts.reduce((a, b) => a + b.reward, 0),
        deduct: this.driverPayouts.reduce((a, b) => a + b.deduct, 0),
        amount: this.driverPayouts.reduce((a, b) => a + b.amount, 0),
      };
    },
  },
};
</script>

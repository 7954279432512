<template lang="pug">
v-btn(@click="submit", small, :loading="loading") Create by Day
</template>

<script>
import moment from "moment-timezone";

export default {
  props: ["date"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      const date = parseInt(moment(this.date).format("YYYYMMDD"));
      const result = await this.$api.transaction.stripe.custom("createByDay", {
        date,
      });
      this.$toast.success(result);
      this.$emit("created");
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-card(v-if="biz?.address", flat)
  v-toolbar(dense, flat)
    .subtitle-2
      v-icon(color="primary") mdi-map-marker
    v-spacer
    Form
  v-card-text
    .subtitle-2 {{ biz.address | address("postal_code") }}
    div(v-if="biz.address.note") Note: {{ biz.address.note }}
    div Timezone: {{ biz.address.timezone }}
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  name: "BizAddress",
  components: { Form },
  computed: { ...mapGetters(["biz"]) },
};
</script>

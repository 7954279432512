<template lang="pug">
.d-flex.flex-row.mt-3
  v-text-field(solo, placeholder="Email", v-model="toUserEmail", dense)
  v-btn.ml-3.text-capitalize(@click="handleTransfer()") Transfer User
</template>

<script>
export default {
  props: ["driver"],
  data() {
    return {
      toUserEmail: "",
    };
  },
  methods: {
    handleTransfer() {
      confirm("Do you want to transfer this driver to another user?") &&
        this.transfer();
    },
    async transfer() {
      if (!this.driver) return;
      const params = {
        fromUserEmail: this.driver.email,
        toUserEmail: this.toUserEmail.trim(),
      };
      try {
        await this.axios.post("/driver/accounts/transfer", params);
        this.$toast.success("Transfer successful");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.toUserEmail = "";
    },
  },
};
</script>

<template lang="pug">
v-card.my-3(flat)
  simple-table
    thead
      tr
        th ID
        th Station
        th System
        th App
        th User
        th IP
        th PAX IP
        th.text-capitalize(
          v-for="(tag, tindex) in printer_tags",
          :key="tindex"
        ) {{ tag }}
        th Last Login
        th
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        @click="select(item)",
        role="button"
      )
        th(:class="getClass(item)") {{ item.deviceUUID | truncate(8) }}
        td {{ item.station }}
        td {{ item.model }} {{ item.systemVersion }}
        td {{ item.appName }} {{ item.appVersion }}
        td {{ item.user_email }}
        td {{ item.deviceIP | truncate(15) }}
        td {{ item.paxIP | truncate(15) }}
        td(v-for="(printer, pindex) in item.printers", :key="pindex") {{ printer }}
        td {{ item.last_visit | datetime("smart") }}
        td.text-right
          v-btn.text-notransform.ml-2(
            @click.stop="handleOpen(item)",
            outlined,
            x-small,
            rounded,
            color="secondary"
          ) Log
</template>

<script>
import _ from "underscore";

export default {
  props: {
    printers: { type: Array, default: () => [] },
    devices: { type: Array, default: () => [] },
  },
  computed: {
    printer_tags() {
      return _.chain(this.printers).pluck("tag").uniq().value();
    },
    items() {
      const result = _.chain(this.devices)
        .map((device) => {
          const printers = _.map(this.printer_tags, (tag) => {
            const found = this.printers.find(
              (o) => o.uuid == device.deviceUUID && o.tag == tag
            );
            let name = "";
            if (found) {
              name += found.name;
              if (found.bdAddress) name += " " + found.bdAddress;
              if (found.ipAddress) name += " " + found.ipAddress;
            }
            return name;
          });
          return { ...device, printers };
        })
        .sortBy("deviceUUID")
        .value();
      return result;
    },
  },
  methods: {
    getClass(item) {
      if (item.auto_print_online) return "green--text";
      return "";
    },
    handleOpen(item) {
      this.$emit("showPrinterLog", item.deviceUUID);
    },
    select(item) {
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="sass" scoped>
.table-style
  table-layout: fixed
  overflow-x: auto
  & td
    overflow: hidden
    white-space: nowrap
</style>
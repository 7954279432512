<template lang="pug">
v-btn(small, text, color="secondary", @click="load()", :loading="loading") Tip
</template>

<script>
export default {
  props: {
    mid: { type: String, required: true },
    hsn: { type: String, required: true },
  },
  data() {
    return { loading: false };
  },
  methods: {
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = {
        mid: this.mid,
        hsn: this.hsn,
        amount: 1000,
        prompt: "Enter tip amount",
      };
      try {
        const result = await this.$api.cardpointe.bolt.tip(params);
        this.$toast.success(JSON.stringify(result));
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
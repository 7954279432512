<template lang="pug">
IconBtn(
  @click="submit",
  :loading="loading",
  icon="mdi-folder-plus",
  title="Monthly Items"
)
</template>

<script>
export default {
  props: ["year"],
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const params = { year: this.year };
        const result = await this.$api.invoice.custom(
          "generateMonthly",
          params
        );
        this.$toast.success(result);
        this.$emit("refresh");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  MonthSelector(@select="load")
  List
</template>

<script>
import List from "./../List/index";
import { mapActions } from "vuex";

export default {
  components: { List },
  data() {
    return { range: {} };
  },
  methods: {
    ...mapActions(["setInfluencers"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;
      const params = {
        criteria: { created: { $gte: this.range.begin, $lt: this.range.end } },
      };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
  },
};
</script>

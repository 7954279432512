<template lang="pug">
div
  DishCell(
    v-for="(dish, dindex) in order.dishes",
    :key="dindex + 'dish'",
    :dish="dish",
    divider,
    prints
  )
  div(v-for="(item, gindex) in order.gifts", :key="gindex + 'gift'")
    .item
      v-flex
        .item-name Gift Card
      div {{ item.amount | currency }}
    .sl-dash-divider.my-2
  div(v-for="(item, vindex) in order.vouchers", :key="vindex + 'voucher'")
    .item
      v-flex
        .item-name {{ item.name }}
        .item-detail {{ item.detail }}
      .red--text {{ -item.amount | currency }}
    .sl-dash-divider.my-2
</template>

<script>
export default { props: ["order"] };
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: center

  &-name
    font-size: 14px
    font-weight: 500
    color: black

  &-detail
    font-size: 13px
    color: rgba(28, 31, 52, 0.6)
</style>
<template lang="pug">
div
  List
  v-row.my-5(wrap)
    v-col(cols="12", md="6", lg="4")
      Domains.mb-3
      DeleteMany
</template>

<script>
import List from "../List";
import Domains from "./Domains";
import DeleteMany from "./DeleteMany";
import { mapActions } from "vuex";

export default {
  components: { List, Domains, DeleteMany },
  mounted() {
    this.load();
  },
  destroyed() {
    this.setUserAccounts([]);
  },
  methods: {
    ...mapActions(["setUserAccounts"]),
    async load() {
      const params = {
        criteria: { "status.verified": 0 },
        select: "email google apple status phones member payments created",
        limit: 500,
      };
      const result = await this.$api.user.list(params);
      this.setUserAccounts(result);
    },
  },
};
</script>

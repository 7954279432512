<template lang="pug">
.d-flex.flex-row.align-center
  DaySelector.mr-2(@select="load")
  v-btn(outlined, small, depressed, @click="load()")
    v-icon(small) mdi-sync
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data: () => ({
    selectedDate: "",
  }),
  methods: {
    ...mapActions(["setDelivOrders"]),
    async load(date) {
      if (date) {
        this.selectedDate = date;
      }
      if (!this.selectedDate) return;
      this.loading = true;
      const begin = moment(this.selectedDate).startOf("day").unix() * 1000;
      const end = moment(this.selectedDate).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          needed: { $gte: begin, $lte: end },
          status: { $gte: 0 },
        },
      };
      const result = await this.$api.delivOrder.list(params);
      this.setDelivOrders(result);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400", scrollable)
  v-card(v-if="report")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }} Press Report
    v-card-text
      v-form(@submit.prevent="submit")
        DateFieldPicker(v-model="formatted_date")
        v-text-field(label="Channel", v-model="report.channel")
        v-text-field(label="Title", v-model="report.title")
        v-text-field(label="Content", v-model="report.content", textarea)
        v-text-field(label="Picture", v-model="report.picture")
        v-text-field(label="External Link", v-model="report.external_link")
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
        .pl-2.red--text(
          v-for="(error, index) in errors",
          :key="index + 'index'"
        ) {{ error }}
      Delete(:item="report", @deleted="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import moment from "moment-timezone";
import Delete from "./Delete";

export default {
  name: "PressReportForm",
  components: { Delete },
  data() {
    return {
      formatted_date: moment().toISOString().substring(0, 10),
      report: {
        date: 0,
        channel: "",
        title: "",
        content: "",
        picture: "",
        external_link: "",
        featured: false,
      },
      dialog: false,
      errors: [],
      loading: false,
      defaultBizs: [],
    };
  },
  computed: {
    title() {
      return this.report?._id ? "Edit" : "Create";
    },
  },
  mounted() {
    EventBus.$on("edit-press-report", this.open);
  },
  destroyed() {
    EventBus.$off("edit-press-report", this.open);
  },
  methods: {
    ...mapActions(["updatePressReport", "addPressReport"]),
    open(data) {
      if (!data) {
        this.report = {
          date: Date.now(),
          channel: "",
          title: "",
          content: "",
          picture: "",
          external_link: "",
          featured: false,
        };
      } else {
        this.report = JSON.parse(JSON.stringify(data));
      }
      this.formatted_date = moment(this.report.date)
        .tz("America/Denver")
        .format("YYYY-MM-DD");
      this.dialog = true;
      this.errors = [];
    },
    async submit() {
      this.errors = [];
      this.report.date = moment(this.formatted_date)
        .tz("America/Denver")
        .valueOf();
      if (this.report?._id) {
        this.update();
      } else {
        this.create();
      }
    },
    async create() {
      this.loading = true;
      try {
        const result = await this.$api.corp.pressReport.create(this.report);
        this.addPressReport(result);
        this.$toast.success("Press report created successfully");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create press report.");
      }
      this.loading = false;
    },
    async update() {
      this.loading = true;
      try {
        const action = { $set: this.report };
        const result = await this.$api.corp.pressReport.put(
          this.report._id,
          action
        );
        this.updatePressReport(result);
        this.$toast.success("Press report updated successfully");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update press report.");
      }
      this.loading = false;
    },
  },
};
</script>
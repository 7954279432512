<template lang="pug">
div
  v-data-table(
    :items="items",
    :headers="headers",
    dense,
    mobile-breakpoint=0,
    @click:row="show",
    role="button"
  )
    template(v-slot:item.createdAt="{ item }")
      span.nowrap {{ item.createdAt | datetime("MMM D, h:mm A") }}
    template(v-slot:item.action="{ item }")
      v-btn(@click.stop="handleLoadTxn(item)", small, text, color="secondary") Load TXN
      v-icon.ml-2(small, color="success", v-if="item.status == 1") mdi-check-circle
      v-icon.ml-2(small, color="grey", v-if="item.status == -1") mdi-close-circle-outline
  Detail(ref="detail", @show-txn="showTxn")
  TxnDetail(ref="txnDetail")
</template>

<script>
import moment from "moment-timezone";
import Detail from "./Detail";
import TxnDetail from "/libs/components/Transaction/DailySummary/Detail.vue";

export default {
  components: { Detail, TxnDetail },
  // date format: "2020-08-01"
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Time", value: "createdAt" },
        { text: "Biz", value: "bizName" },
        { text: "Terminal", value: "sn" },
        { text: "ID", value: "transNum" },
        { text: "Action", value: "action", align: "end" },
      ],
    };
  },
  methods: {
    handleLoadTxn(item) {
      const timezone = "America/Denver";
      const date = moment.tz(item.created, timezone).format("YYYY-MM-DD");
      this.$emit("load-txn", date, item.biz);
    },
    show(item) {
      this.$refs.detail.open(item);
    },
    showTxn(item) {
      this.$refs.txnDetail.open(item);
    },
  },
};
</script>
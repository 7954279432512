<template lang="pug">
StandardChart(:input="chartData" chartType="bar")
</template>
<script>
import _ from "underscore";

export default {
  props: ["groups"],
  computed: {
    chartData() {
      if (!this.groups) return null;
      const data = {
        title: "Week",
        labels: _.pluck(this.groups, "title"),
        values: _.pluck(this.groups, "sum"),
        isCurrency: false,
      };
      return data;
    },
  },
};
</script>

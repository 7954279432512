<template lang="pug">
v-row
  v-col(cols="12", md="6", v-for="(group, gindex) in groups", :key="gindex")
    Block(:items="group.items", :currency="group.currency", :year="year")
</template>

<script>
import _ from "underscore";
import Block from "./Block";

export default {
  components: { Block },
  props: ["items", "year"],
  computed: {
    groups() {
      const data = _.chain(this.items)
        .groupBy("currency")
        .map((list, currency) => {
          const items = _.sortBy(list, "year");
          // set growth year over year
          if (items.length < 2) return { currency, items };
          items[0].growth = 0;
          for (let i = 1; i < items.length; i++) {
            const prev = items[i - 1];
            const curr = items[i];
            curr.growth = ((curr.total - prev.total) / prev.total) * 100;
            // round to 1 decimal places
            curr.growth = Math.round(curr.growth * 10) / 10;
          }
          return { currency, items };
        })
        .sortBy("currency")
        .value();
      return data;
    },
  },
};
</script>

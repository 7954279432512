<template lang="pug">
v-data-table(
  :items="service_items",
  :headers="headers",
  sortBy="sort",
  dense,
  mobile-breakpoint=0,
  @click:row="edit",
  role="button"
)
  template(v-slot:item.us_price="{ item }")
    span {{ item.us_price | currency }}
  template(v-slot:item.ca_price="{ item }")
    span {{ item.ca_price | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["items", "title"],
  data() {
    return {
      headers: [
        { text: "Sort", value: "sort" },
        { text: "Name", value: "name" },
        { text: "SKU", value: "sku" },
        { text: "Category", value: "category" },
        { text: "Detail", value: "detail", sortable: false },
        { text: "US Price", value: "us_price", align: "end" },
        { text: "CA Price", value: "ca_price", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["service_items"]),
  },
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>
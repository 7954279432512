<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="promotion")
    v-toolbar(dense, flat)
      .subtitle-2 {{ promotion.name }}
    v-divider
    v-card-text
      .subtitle-2 {{ promotion.title }}
      .body-2 {{ promotion.desc }}
      .green--text.title
        span(v-if="promotion.type == 'pct'") {{ -promotion.pct }}%
        span(v-else) {{ -promotion.fix | currency }}
      ul
        li(v-if="promotion.chain") Apply to Entire Chain
        li Total Coupons: {{ promotion.total }}
        li Minimum Purchase: {{ promotion.minimum | currency }}
        li Expiry: {{ promotion.expiry | date }}
      ClaimedChart.my-3(:stats="stats")
      SalesChart.my-3(:stats="stats")
</template>

<script>
import ClaimedChart from "./ClaimedChart";
import SalesChart from "./SalesChart";
import { EventBus } from "@/event-bus.js";

export default {
  components: { ClaimedChart, SalesChart },
  data() {
    return {
      dialog: false,
      promoId: null,
      promotion: null,
      stats: null,
    };
  },
  mounted() {
    EventBus.$on("show-promotion", this.load);
  },
  destroyed() {
    EventBus.$off("show-promotion", this.load);
  },
  methods: {
    async load(promoId) {
      this.promoId = promoId;
      if (!this.promoId) return;
      this.dialog = true;
      const params = { criteria: { _id: this.promoId } };
      try {
        this.promotion = await this.$api.promotion.retrieve(params);
        await this.loadStats();
      } catch (error) {
        this.$router.push("/promotions");
      }
    },
    async loadStats() {
      this.stats = null;
      if (!this.promoId) return;
      const params = { promoId: this.promoId };
      try {
        const { data } = await this.axios.post("/promoclaim/stats", params);
        this.stats = data;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

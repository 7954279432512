<template lang="pug">
v-data-table(:items="reservations", :headers="headers", dense)
  template(v-slot:item.needed="{ item }")
    span {{ item.needed | time }}
  template(v-slot:item.created="{ item }")
    span {{ item.created | time }}
  template(v-slot:item.action="{ item }")
    v-btn(
      small,
      rounded,
      depressed,
      v-if="item.status != 0",
      @click="reopen(item)"
    ) Reopen
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Time", value: "needed" },
        { text: "Created", value: "created" },
        { text: "Customer", value: "customer.name" },
        { text: "Table", value: "tableId" },
        { text: "Guests", value: "total_people" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["reservations"]),
  },
  methods: {
    ...mapActions(["updateReservation"]),
    async reopen(rsvt) {
      if (!rsvt) return;
      try {
        const action = { $set: { status: 0 } };
        const result = await this.$api.reservation.put(rsvt._id, action);
        this.updateReservation(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<template lang="pug">
v-btn(@click="submit", outlined, :loading="loading") Create Last Month Invoices
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const result = await this.$api.invoice.custom("createLastMonth", {});
        this.$toast.success(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", icon, :loading="loading")
      v-icon mdi-dots-horizontal
  v-list
    v-list-item(@click="deleteOne")
      v-list-item-icon
        v-icon mdi-delete
      v-list-item-title Delete
</template>

<script>
export default {
  props: {
    enterprise: { type: Object, required: true },
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async deleteOne() {
      if (!confirm("Are you sure you want to delete this item?")) return;
      this.loading = true;
      try {
        const params = {
          enterpriseId: this.enterprise._id,
          bizId: this.item._id,
        };
        const result = await this.$api.entp.biz.delete(params);
        this.$store.dispatch("entp/updateEnterprise", result);
        const biz = await this.$api.biz.retrieve({
          criteria: { _id: this.item._id },
        });
        this.$store.dispatch("updateBizAccount", biz);
        this.$emit("deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-flex.text-xs-center(xs6, sm3)
  .caption.sl-secondary-text Capabilities
  div(v-for="(item, index) in stripeAccount.capabilities", :key="index") {{ index }}: {{ item }}
  v-btn(
    small,
    color="secondary",
    text,
    :loading="loading",
    @click="update()",
    v-if="!isComplete"
  ) Update Capabilities
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "UpdateStripeAccountCapabilities",
  props: ["stripeAccount"],
  data() {
    return { loading: false };
  },
  computed: {
    isComplete() {
      const has1099K =
        this.stripeAccount?.capabilities?.tax_reporting_us_1099_k === "active";
      const has1099MISC =
        this.stripeAccount?.capabilities?.tax_reporting_us_1099_misc ===
        "active";
      return has1099K || has1099MISC;
    },
  },
  methods: {
    ...mapActions(["updateStripeAccount"]),
    async update() {
      if (!this.stripeAccount) return;
      this.loading = true;
      try {
        const params = { accountId: this.stripeAccount.id };
        const result = await this.$api.stripe.account.custom(
          "updateCapabilities",
          params
        );
        this.updateStripeAccount(result);
        this.$toast.success("Capabilities updated");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message || "Failed");
      }
      this.loading = false;
    },
  },
};
</script>

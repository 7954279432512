<template lang="pug">
simple-table
  thead
    tr
      th Name
      th Email
      th Phone
      th Access
      th
  tbody
    tr(v-for="(item, index) in items", @click="edit(item)", role="button")
      td {{ item.name }}
      td {{ item.email }}
      td {{ item.phone | phone }}
      td {{ item.privilege | role }}
      td.text-right
        v-btn(icon, small, color="error", @click.stop="handleRemove(item)")
          v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AccessForm from "./AccessForm";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  components: { AccessForm },
  filters: {
    role(val) {
      if (val == 1) return "Owner";
      if (val == 2) return "Custom";
      return "Role";
    },
  },
  props: {
    chain: { type: Object, required: true },
  },
  computed: {
    ...mapGetters(["chainaccess"]),
    items() {
      return _.chain(this.chainaccess)
        .filter((item) => item.chain == this.chain?._id)
        .sortBy("name")
        .sortBy("privilege")
        .value();
    },
  },
  methods: {
    ...mapActions(["removeChainAccess"]),
    handleRemove(access) {
      confirm("Do you really want to delete this user & access?") &&
        this.remove(access);
    },
    async remove(access) {
      try {
        const result = await this.$api.chain.access.delete(access._id);
        this.removeChainAccess(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    edit(item) {
      EventBus.$emit("edit-chain-access", item);
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Leads
    v-spacer
    IconBtn(icon="mdi-plus", @click="create()", title="Add")
  v-card-text
    .link-box
      v-text-field(
        v-model="search",
        label="Search",
        placeholder="Search by name, phone, city",
        append-icon="mdi-magnify",
        clearable,
        hide-details
      )
      v-btn-toggle(v-model="status", mandatory, small)
        v-btn(
          small,
          v-for="item in status_items",
          :key="item.value",
          :value="item.value"
        ) {{ item.text }} ({{ groups[item.value]?.length }})
  v-row(wrap)
    v-col(cols="12", md="6")
      v-list(two-line, dense)
        template(v-for="item in paged_items")
          v-divider
          Cell(:lead="item", @click="select(item)")
    v-col(cols="12", md="6")
      MarkerMap(:geometries="geometries", :center="geo_center", :zoom="4")
  v-card-actions(v-if="total_pages > 1")
    v-pagination(
      v-model="current_page",
      :length="total_pages",
      color="secondary",
      circle
    )
  Worksheet(ref="worksheet")
  Form(ref="form")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./../Form";
import Worksheet from "./../Worksheet";
import Cell from "./Cell";

export default {
  components: { Worksheet, Form, Cell },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      status: "open",
      status_items: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
      ],
      search: "",
      current_page: 1,
      page_size: 20,
    };
  },
  computed: {
    ...mapGetters(["salesOrganization"]),
    paged_items() {
      const start = (this.current_page - 1) * this.page_size;
      const end = start + this.page_size;
      return this.selected.slice(start, end);
    },
    selected() {
      let items = [];
      if (this.status == "open") items = this.groups.open;
      else if (this.status == "closed") items = this.groups.closed;
      if (!this.search) return items;
      return items.filter((o) => {
        const search = this.search.trim().toLowerCase();
        return (
          o.company_name?.toLowerCase().includes(search) ||
          o.phone?.toLowerCase().includes(search) ||
          o.address?.city?.toLowerCase().includes(search)
        );
      });
    },
    geometries() {
      const result = this.selected.map((o) => {
        const geo = o.address?.geometry;
        if (geo) return { lat: geo.lat, lng: geo.lng, title: o.company_name };
        return null;
      });
      return result;
    },
    geo_center() {
      return this.salesOrganization?.address?.geometry;
    },
    groups() {
      let open = [];
      let closed = [];
      const open_status = ["new", "contacted", "qualified"];
      this.items.forEach((o) => {
        if (open_status.includes(o.status)) open.push(o);
        else closed.push(o);
      });
      open.sort((a, b) => b.updated - a.updated);
      closed.sort((a, b) => b.updated - a.updated);
      return { open, closed };
    },
    total_pages() {
      return Math.ceil(this.selected.length / this.page_size);
    },
  },
  methods: {
    select(item) {
      this.$refs.worksheet.open(item);
    },
    create() {
      const organization = this.salesOrganization._id;
      if (!organization) return;
      const data = {
        company_name: "",
        phone: "",
        email: "",
        address: {},
        contact: null,
        biz: null,
        organization,
        status: "new",
      };
      this.$refs.form.open(data);
    },
  },
};
</script>
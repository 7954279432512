<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="invoice")
    v-card-title Invoice
    v-card-text
      .subtitle-2 {{ invoice.biz.name }}
      .subtitle-2 Period: {{ invoice.period.month }}/{{ invoice.period.year }}
      ClickToCopy(:text="invoice._id")
    v-data-table(
      v-model="selected",
      :items="invoice.items",
      :headers="headers",
      show-select,
      item-key="_id",
      mobile-breakpoint="0"
    )
    v-card-text
      Payment(:invoice="invoice")
    Charges(:charges="charges", :invoice="invoice")
    v-card-actions
      Recover(:invoice="invoice", @updated="refresh")
      Refund(:invoice="invoice", :selected="selected", @done="refresh")
      v-spacer
      Download(:item="invoice")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import Charges from "./Charges";
import Payment from "./Payment";
import Recover from "./Recover";
import Refund from "./Refund";
import Download from "/libs/components/Billing/Invoice/List/Download";

export default {
  components: { Payment, Refund, Recover, Charges, Download },
  data() {
    return {
      dialog: false,
      invoice: null,
      charges: [],
      headers: [
        { text: "Item", value: "name" },
        { text: "Price", value: "amount" },
        { text: "Quantity", value: "quantity", align: "end" },
      ],
      selected: [],
    };
  },
  mounted() {
    EventBus.$on("show-invoice-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-invoice-detail", this.load);
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    async load(id) {
      this.dialog = true;
      this.selected = [];
      const params = { criteria: { _id: id } };
      this.invoice = await this.$api.invoice.retrieve(params);
      await this.loadCharges(id);
      this.updateInvoice(this.invoice);
    },
    async loadCharges(id) {
      const params = { criteria: { invoice_id: id } };
      this.charges = await this.$api.transaction.stripe.list(params);
    },
    refresh(data) {
      this.invoice = data;
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="delivs",
  :headers="headers",
  v-if="delivs && delivs.length"
)
  template(v-slot:item.action="{ item }")
    v-btn(small, color="secondary", depressed, @click="assign(item)") assign
</template>

<script>
export default {
  props: ["delivs"],
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  methods: {
    assign(deliv) {
      if (!deliv) return;
      this.$emit("assign-deliv", deliv);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="600")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open", v-on="on")
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Edit Weekly Hours
    v-divider
    v-form(@submit.prevent="submit")
      v-card-text
        v-select(
          v-model="open_delay",
          :items="items",
          label="Delay time",
          :hint="instruction",
          persistent-hint,
          menu-props="auto"
        )
        v-row(v-for="(item, windex) in weekdays", :key="windex")
          v-col(cols="2")
            v-checkbox(
              v-model="item.status",
              :label="weekday(item.date)",
              hide-details,
              color="secondary"
            )
          v-col(v-if="item.status")
            RangesSelector(
              v-model="item.schedule",
              :copy="true",
              @copy="copyRange(windex)"
            )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import RangesSelector from "./../RangesSelector";
import BizUtils from "/libs/utils/biz.js";

export default {
  components: { RangesSelector },
  props: { biz: { type: Object, default: () => ({}) } },
  data() {
    return {
      dialog: false,
      open_delay: 0,
      weekdays: [],
      loading: false,
      // 0, 5, 10, 15, 20, 25, 30 minutes
      items: [
        { text: "0 minutes", value: 0 },
        { text: "5 minutes", value: 5 },
        { text: "10 minutes", value: 10 },
        { text: "15 minutes", value: 15 },
        { text: "20 minutes", value: 20 },
        { text: "25 minutes", value: 25 },
        { text: "30 minutes", value: 30 },
      ],
      instruction:
        "e.g., a business opens at 10:00, but it can delay the first online order to 10:30 if delay time is 30",
    };
  },
  methods: {
    weekday(date) {
      return this.$options.filters.day(date, true);
    },
    open() {
      if (!this.biz) return;
      this.dialog = true;
      this.open_delay = this.biz.schedule.open_delay || 0;
      this.weekdays = JSON.parse(JSON.stringify(this.biz.schedule.weekdays));
      /// put sunday to end
      if (this.weekdays?.length && this.weekdays[0].date === 0) {
        const sunday = this.weekdays.shift();
        this.weekdays.push(sunday);
      }
    },
    copyRange(windex) {
      if (windex < this.weekdays.length && windex > 0) {
        this.weekdays[windex].schedule = this.weekdays[windex - 1].schedule;
      }
    },
    async submit() {
      if (!this.biz) return;
      // 1. prepare ranges for each day
      this.weekdays.forEach((o) => {
        o.schedule = BizUtils.prepareRanges(o.schedule);
      });
      // 2. check if any range is incorrect
      for (const day of this.weekdays) {
        if (!day.status) continue;
        try {
          BizUtils.validateRanges(day.schedule);
        } catch (e) {
          return this.$toast.error(e.message);
        }
      }
      this.loading = true;
      try {
        const action = {
          $set: {
            "schedule.open_delay": this.open_delay,
            "schedule.weekdays": this.weekdays,
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$emit("updated", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update weekly hours");
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(width="300", v-model="dialog")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on") Call
  v-card
    v-card-title Call
    v-card-text
      v-form(@submit.prevent="submit")
        PhoneField(v-model="number")
        v-btn(block, type="submit", color="secondary", :loading="loading") Make Call
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      number: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const params = { phone: this.number };
        const { data } = await this.axios.post("/misc/call", params);
        this.$toast.success(data);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

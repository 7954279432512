<template lang="pug">
div
  v-row(wrap)
    v-col(cols="12", md="6")
      ProductTable
    v-col(cols="12", md="6")
      Cart
  EditProduct
</template>

<script>
import { mapActions } from "vuex";
import Cart from "./Cart";
import ProductTable from "./ProductTable";
import EditProduct from "./../../Catalog/Products/EditForm";

export default {
  components: { ProductTable, Cart, EditProduct },
  data() {
    return {
      editData: null,
      products: [],
      headers: [
        { text: "SKU", value: "sku" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["addItemToCart"]),
    async load() {
      const params = { criteria: { status: true } };
      try {
        this.products = await this.$api.catalog.product.list(params);
      } catch (e) {
        this.products = [];
      }
    },
  },
};
</script>

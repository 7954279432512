<template lang="pug">
div
  .d-flex.flex-row.align-center.justify-space-between.mb-3
    v-btn.mr-3.text-capitalize(
      small,
      :class="{ accent: openOnly }",
      @click="load(true)",
      rounded,
      depressed
    ) Open
    v-btn.mr-3.text-capitalize(
      small,
      :class="{ accent: !openOnly }",
      @click="load(false)",
      rounded,
      depressed
    ) All
    MonthSelector(@select="selectMonth", v-if="!openOnly")
    v-spacer
    Create
    Migrate
    PayMultiple
  List.mb-3(
    v-for="(group, index) in groups",
    :key="index",
    :invoices="group.invoices",
    :title="group.title"
  )
  InvoiceDetail
  BizTable.my-5(:items="bizs")
  ThirtyDay
  InvoicePay
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import List from "./List/index";
import Create from "./Create/index";
import PayMultiple from "./PayMultiple/index";
import Migrate from "./Migrate/index";
import BizTable from "./BizTable";
import ThirtyDay from "@/components/Business/Biz/Table/Detail/ThirtyDay.vue";
import FixCurrency from "./FixCurrency";
import InvoicePay from "/libs/components/Billing/Invoice/List/Pay/index";

export default {
  components: {
    List,
    Create,
    PayMultiple,
    Migrate,
    ThirtyDay,
    BizTable,
    FixCurrency,
    InvoicePay,
  },
  data() {
    return {
      month: 0,
      year: 0,
      openOnly: true,
      bizs: [],
      sales_organizations: [],
    };
  },
  computed: {
    ...mapGetters(["invoices"]),
    // group invoices by sales_organization
    // map to { id, title, invoices }
    groups() {
      const groups = {};
      this.invoices.forEach((o) => {
        const key = o.sales_organization || "No Sales Organization";
        if (!key) return;
        if (!groups[key]) groups[key] = [];
        groups[key].push(o);
      });
      return Object.keys(groups).map((key) => {
        const title =
          this.sales_organizations.find((o) => o._id == key)?.name || key;
        return {
          id: key,
          title,
          invoices: groups[key],
        };
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setInvoices", "updateInvoice"]),
    selectMonth(data) {
      if (!data || !data.name) return;
      const items = data.name.split("-");
      if (!items || items.length != 2) return;
      this.year = parseInt(items[0]);
      this.month = parseInt(items[1]);
      if (!this.openOnly) this.load(false);
    },
    async load(openOnly) {
      if (openOnly != undefined) this.openOnly = openOnly;
      let params;
      const select =
        "biz period payment emailed sales_organization billing_organization org_invoice";
      if (this.openOnly) {
        params = {
          criteria: { status: 0, org_invoice: { $exists: false } },
          select: select,
        };
      } else {
        if (this.year <= 0 || this.month <= 0) return;
        params = {
          criteria: { period: { year: this.year, month: this.month } },
          select: select,
        };
      }
      const result = await this.$api.invoice.list(params);
      this.setInvoices(result);
      await this.loadBizs();
      await this.loadSalesOrganizations();
    },
    async loadBizs() {
      const ids = this.invoices.map((o) => o.biz?.id);
      const uniqIds = Array.from(new Set(ids));
      const params = {
        criteria: { _id: { $in: uniqIds } },
        select: "name pos_start online_start created address",
      };
      this.bizs = await this.$api.biz.list(params);
    },
    async loadSalesOrganizations() {
      const ids = this.invoices.map((o) => o.sales_organization);
      const uniqIds = Array.from(new Set(ids));
      if (!uniqIds?.length) return;

      const params = { criteria: { _id: { $in: uniqIds } }, select: "name" };
      this.sales_organizations = await this.$api.salesforce.organization.list(
        params
      );
    },
  },
};
</script>

<template lang="pug">
v-dialog(width="700")
  template(v-slot:activator="{ on }")
    v-btn.my-5(v-on="on", rounded, outlined, small) Show Actions
  v-card
    v-toolbar(flat)
      .subtitle-2 Actions
      v-spacer
      DaySelector.mr-2(@select="load")
    simple-table
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ item.name }}
          td {{ item.detail }}
          td.text-right {{ item.created | datetime }}
</template>


<script>
import moment from "moment-timezone";

export default {
  data() {
    return {
      selectedDate: "",
      items: [],
    };
  },
  methods: {
    async load(date) {
      if (date) {
        this.selectedDate = date;
      }
      if (!this.selectedDate) return;
      const begin = moment(this.selectedDate).startOf("day").unix() * 1000;
      const end = moment(this.selectedDate).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          created: { $gte: begin, $lte: end },
        },
      };
      const res = await this.axios.post("/delivery/actions/list", params);
      this.items = res.data;
    },
  },
};
</script>

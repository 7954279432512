<template lang="pug">
.s-vstack
  div
    MonthSelector(@select="load")
  List
  SalesForce
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import List from "./../../Biz/Table/index.vue";
import SalesForce from "./SalesForce/index";

export default {
  components: { List, SalesForce },
  data() {
    return {
      range: {},
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    posBizs() {
      if (!this.range) return;
      return this.bizAccounts.filter(
        (o) => o.pos_start >= this.range.begin && o.pos_start < this.range.end
      );
    },
  },
  methods: {
    ...mapActions(["setBizAccounts", "setBizServices"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;

      const params = {
        criteria: {
          $or: [
            { online_start: { $gte: this.range.begin, $lt: this.range.end } },
            { pos_start: { $gte: this.range.begin, $lt: this.range.end } },
          ],
        },
        select:
          "name short_name status orderType tax_items online_start pos_start created address industry geo_service_zone standing",
      };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
      this.loadServices();
    },
    async loadServices() {
      const ids = this.posBizs.map((o) => o._id);
      const params = { criteria: { biz: { $in: ids } } };
      const result = await this.$api.service.account.list(params);
      this.setBizServices(result);
    },
  },
};
</script>

<template lang="pug">
v-btn(
  v-if="valid",
  color="secondary",
  @click="handler",
  rounded,
  depressed,
  x-small
) Partial Refund
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],
  computed: {
    valid() {
      return this.order && this.order.status >= 0 && this.proxy != "Test";
    },
  },
  methods: {
    async handler() {
      EventBus.$emit("order-partial-refund");
    },
  },
};
</script>


<template lang="pug">
v-btn(
  @click="submit()",
  :loading="loading",
  color="secondary",
  x-small,
  outlined
) Recover
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["invoice"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    async submit() {
      this.loading = true;
      try {
        const params = { id: this.invoice._id };
        const result = await this.$api.invoice.custom("recoverCharge", params);
        this.$toast.success("Recover successful");
        if (result._id) {
          this.$emit("updated", result);
          this.updateInvoice(result);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
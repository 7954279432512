
<template lang="pug">
v-btn.mr-3(@click="handler", small, :loading="loading") Update Stripe PI
</template>

<script>
export default {
  props: ["selectedDate"],
  data() {
    return { loading: false };
  },
  methods: {
    async handler() {
      this.loading = true;
      const params = {
        criteria: { currency: null, status: { $gte: 0 } },
        limit: 100,
      };
      try {
        const result = await this.$api.stripe.paymentIntent.custom(
          "updateNet",
          params
        );
        let items = result.split(" ");
        if (items.length == 2 && parseInt(items[1]) > 0) {
          this.handler();
        } else {
          console.log(items);
        }
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
  },
};
</script>
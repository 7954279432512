<template lang="pug">
simple-table(v-if="report")
  thead
    tr
      th Order Report
      th
  tbody
    tr
      th # orders
      td.text-right {{ report.count }}
    tr
      th Base Fee
      td.text-right {{ report.fee | currency }}
    tr
      th Tip
      td.text-right {{ report.tip | currency }}
    tr
      th Reward
      td.text-right {{ report.reward | currency }}
    tr
      th Total
      td.text-right {{ (report.fee + report.tip + report.reward) | currency }}
        span(v-if="average") ({{ average | currency }}/order)
    tr.red--text(v-if="report.count == 0")
      th Delete Driver
      td.text-right
        v-btn(icon, small, color="error", @click="handleDelete()")
          v-icon(small) mdi-delete
</template>

<script>
export default {
  props: ["report", "driver"],
  computed: {
    average() {
      if (!this.report) return 0;
      return (this.report.fee + this.report.tip) / this.report.count;
    },
    has_duplicate_driver() {
      return (
        this.report && this.report.count_driver && this.report.count_driver > 1
      );
    },
  },
  methods: {
    async handleDelete() {
      if (!this.driver) return;
      const params = { driverId: this.driver._id };
      await this.axios.post("/driver/accounts/deleteOne", params);
      this.$toast.success("Delete driver successful");
    },
  },
};
</script>

const state = {
  transactions: [],
  missedTransactions: []
}

const getters = {
  transactions: state => state.transactions,
  missedTransactions: state => state.missedTransactions
}

const actions = {
  setTransactions: ({ commit }, data) => { commit('setTransactions', data) },
  setMissedTransactions: ({ commit }, data) => { commit('setMissedTransactions', data) },
  updateMissedTransaction: ({ commit }, data) => { commit('updateMissedTransaction', data) },
  addMissedTransaction: ({ commit }, data) => { commit('addMissedTransaction', data) },
  removeMissedTransaction: ({ commit }, data) => { commit('removeMissedTransaction', data) }
}

const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setMissedTransactions(state, missedTransactions) {
    state.missedTransactions = missedTransactions
  },
  updateMissedTransaction(state, data) {
    state.missedTransactions = state.missedTransactions.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMissedTransaction(state, data) {
    state.missedTransactions.push(data)
  },
  removeMissedTransaction(state, data) {
    state.missedTransactions = state.missedTransactions.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
<template lang="pug">
v-row(wrap, dense, v-if="result")
  v-col(
    cols="12",
    sm="6",
    md="4",
    lg="3",
    v-for="(item, index) in days",
    :key="index + '_day'"
  )
    v-card(flat)
      simple-table
        thead
          tr
            th Date
            th.text-right {{ item }}
        tbody
          tr(v-if="result.birthday && result.birthday.length > index")
            th Birthday
            td.text-right {{ result.birthday[index].count }}
          tr(v-if="result.anniv && result.anniv.length > index")
            th Anniversary
            td.text-right {{ result.anniv[index].count }}
          tr(
            v-if="result.spouseBirthday && result.spouseBirthday.length > index"
          )
            th Spouse Birthday
            td.text-right {{ result.spouseBirthday[index].count }}
      v-divider
      simple-table(v-if="emailResult")
        tbody
          tr(v-for="emailType in emailResult[index].grouped")
            th {{ emailType.type | memberEmailType }}
            td.text-right {{ emailType.count }}
</template>

<script>
import _ from "underscore";
import moment from "moment-timezone";

export default {
  filters: {
    memberEmailType: function (val) {
      val = parseInt(val);
      switch (val) {
        case 1:
          return "New Member";
        case 2:
          return "Birthday";
        case 3:
          return "Anniversary";
        case 4:
          return "Spouse Birthday";
        case 5:
          return "30 day";
        case 6:
          return "60 day";
        case 7:
          return "90 day";
        default:
          return "Type " + val;
      }
    },
  },
  props: ["year", "month", "result", "emailResult"],
  computed: {
    days() {
      if (!this.year || !this.month) return [];
      const selected = moment()
        .year(this.year)
        .month(this.month - 1)
        .date(1);
      const endDay = selected.endOf("month").date();
      return _.range(1, endDay + 1);
    },
  },
};
</script>

<template lang="pug">
div
  .subtitle-2(v-if="items?.length") Conversation
  simple-table(v-if="items?.length")
    thead
      tr
        th Time
        th Name
        th Message
    tbody
      tr(v-for="(item, index) in items", :key="index + '-row'")
        td {{ item.created | datetime("smart", timezone) }}
        td {{ item.name }}
        td {{ item.content }}
</template>

<script>
export default {
  props: {
    order: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
  },
  methods: {
    async load() {
      const orderId = this.order?._id;
      if (!orderId) return;
      this.loading = true;
      const params = { order_id: orderId };
      try {
        const res = await this.$api.orderAction.conversation.retrieve(params);
        this.items = res?.messages || [];
      } catch (e) {
        this.items = [];
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
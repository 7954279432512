<template lang="pug">
Page(title="Timeline")
  Category.mb-3
  Event.mb-3
</template>

<script>
import Category from "./Category";
import Event from "./Event";

export default {
  components: { Category, Event },
};
</script>
<template lang="pug">
div
  Table
  EditOrderType
</template>

<script>
import { mapActions } from "vuex";
import Table from "./Table";
import EditOrderType from "./../Table/Detail/Edit/OrderType";

export default {
  components: { Table, EditOrderType },
  data() {
    return {
      select:
        "name logo status address orderType pos_start pos_end online_start online_end google_connect website_connect schedule delivTiers geo_service_zone",
    };
  },
  mounted() {
    this.setBizAccounts([]);
    this.load();
    this.loadGeoZones();
  },
  methods: {
    ...mapActions(["setBizAccounts", "setGeoServiceZones"]),
    async load() {
      const params = {
        criteria: {
          online_start: { $gt: 0 },
          standing: { $nin: ["test", "permanently_closed"] },
        },
        select: this.select,
      };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
    },
    async loadGeoZones() {
      const params = { criteria: {} };
      const result = await this.$api.geoZone.list(params);
      this.setGeoServiceZones(result);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(:loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 User Details
    simple-table(v-if="user")
      tbody
        Email(:user="user", @updated="user = $event")
        tr
          th Name
          td {{ name }}
        tr(v-for="phone in user.phones")
          th Phone
          td {{ phone.number | phone }}
        tr(v-if="customer")
          th Payment Account
          td
            .d-flex.flex-row
              div {{ customer }}
              v-btn(
                icon,
                x-small,
                :href="customerLink",
                target="_blank",
                color="secondary"
              )
                v-icon(small) mdi-link
        tr(v-if="manageAccount")
          th Payout Account
          td
            .d-flex.flex-row
              div {{ manageAccount }}
              v-btn(
                icon,
                x-small,
                :href="manageAccountLink",
                target="_blank",
                color="secondary"
              )
                v-icon(small) mdi-link
        tr
          th ID
          td
            ClickToCopy(:text="user._id")
        tr
          th Created
          td {{ user.created | datetime("smart") }}
    Addresses(:user="user", @update="user = $event")
    Blacklist(
      :blockUser="blockUser",
      :userId="userId",
      @update="blockUser = $event",
      @unblocked="blockUser = null"
    )
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Addresses from "./Addresses";
import Blacklist from "./Blacklist";
import Email from "./Email";

export default {
  components: { Addresses, Blacklist, Email },
  data() {
    return {
      dialog: false,
      user: null,
      blockUser: null,
      loading: false,
      select: "email name status phones addresses member payments created",
    };
  },
  computed: {
    userId() {
      return this.user?._id;
    },
    name() {
      const first = this.user?.name?.first;
      const last = this.user?.name?.last;
      const preferred = this.user?.name?.preferred;
      const fullname = [first, last].filter(Boolean).join(" ");
      if (!preferred) return fullname;
      return `${fullname} (${preferred})`;
    },
    customer() {
      return this.user?.payments?.customer;
    },
    customerLink() {
      if (!this.customer) return null;
      return `https://dashboard.stripe.com/customers/${this.customer}`;
    },
    manageAccount() {
      return this.user?.payments?.managed_account;
    },
    manageAccountLink() {
      if (!this.manageAccount) return null;
      return `https://dashboard.stripe.com/connect/accounts/${this.manageAccount}`;
    },
  },
  mounted() {
    EventBus.$on("show-user-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-user-detail", this.load);
  },
  methods: {
    async load(userId) {
      this.blockUser = null;
      if (!userId) return;
      this.loading = true;
      this.dialog = true;
      try {
        const params = { criteria: { _id: userId }, select: this.select };
        this.user = await this.$api.user.retrieve(params);
        await this.loadBlacklist(userId);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadBlacklist(id) {
      this.blockUser = null;
      if (!id) return;
      const params = { criteria: { user: id } };
      this.blockUser = await this.$api.blacklist.retrieve(params);
    },
  },
};
</script>
const state = {
  timeline_categories: [],
  timeline_events: [],
}

const getters = {
  timeline_categories: state => state.timeline_categories,
  timeline_events: state => state.timeline_events,
}

const actions = {
  setTimelineCategories: ({ commit }, data) => { commit("setTimelineCategories", data); },
  updateTimelineCategory: ({ commit }, data) => { commit("updateTimelineCategory", data); },
  addTimelineCategory: ({ commit }, data) => { commit("addTimelineCategory", data); },
  removeTimelineCategory: ({ commit }, data) => { commit("removeTimelineCategory", data); },
  setTimelineEvents: ({ commit }, data) => { commit("setTimelineEvents", data); },
  updateTimelineEvent: ({ commit }, data) => { commit("updateTimelineEvent", data); },
  addTimelineEvent: ({ commit }, data) => { commit("addTimelineEvent", data); },
  removeTimelineEvent: ({ commit }, data) => { commit("removeTimelineEvent", data); },
};

const mutations = {
  setTimelineCategories(state, data) {
    state.timeline_categories = data;
  },
  updateTimelineCategory(state, data) {
    state.timeline_categories = state.timeline_categories.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addTimelineCategory(state, data) {
    state.timeline_categories.push(data);
  },
  removeTimelineCategory(state, data) {
    state.timeline_categories = state.timeline_categories.filter(o => o._id !== data._id);
  },
  setTimelineEvents(state, data) {
    state.timeline_events = data;
  },
  updateTimelineEvent(state, data) {
    state.timeline_events = state.timeline_events.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addTimelineEvent(state, data) {
    state.timeline_events.push(data);
  },
  removeTimelineEvent(state, data) {
    state.timeline_events = state.timeline_events.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations }
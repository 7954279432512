<template lang="pug">
div
  Dialogs
  v-dialog(v-model="dialog", max-width="1000", scrollable)
    v-card(v-if="order")
      v-card-title
      v-card-text
        OrderHeader(:order="order")
        v-row(wrap, dense)
          v-col(cols="12", md="6")
            Items(:order="order")
            Payment(:order="order")
            Delivery(:delivOrder="delivOrder")
            Misc
            .link-box
              Download(:order="order", :delivOrder="delivOrder")
              v-btn(
                @click="handleChargeback()",
                v-if="showChargeBackBtn",
                small,
                outlined,
                color="secondary"
              ) Charge Back
              EarlyFraudMail(:order="order")
            simple-table(v-if="transfers?.length")
              thead
                tr
                  th Transfer Reason
                  th Amount
                  th Created
              tbody
                tr(v-for="transfer in transfers")
                  td {{ transfer.transferReason }}
                  td {{ (transfer.amount / 100) | currency }}
                  td {{ transfer.created | datetime }}
          v-col(cols="12", md="6")
            ActionLogs(:order="order", ref="actionLogs")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import EarlyFraudMail from "./Actions/EarlyFraudMail";
import Delivery from "./Delivery";
import Dialogs from "./Dialogs/index";
import Items from "./Items";
import Misc from "./Misc/index.vue";
import ActionLogs from "/libs/components/Order/ActionLogs/index";
import Download from "/libs/components/Order/Detail/Download";
import Payment from "/libs/components/Order/Payment/index";

export default {
  components: {
    Items,
    Payment,
    Delivery,
    Misc,
    Download,
    EarlyFraudMail,
    Dialogs,
    ActionLogs,
  },
  data() {
    return {
      compact: true,
      delivOrder: null,
      dialog: false,
      loading: false,
      transfers: null,
      onedayold: Date.now() - 24 * 60 * 60 * 1000,
    };
  },
  computed: {
    ...mapGetters(["order"]),
    showChargeBackBtn() {
      if (!this.transfers?.length) {
        return (
          this.order &&
          this.order.needed < this.onedayold &&
          this.order.proxy != "Test"
        );
      }
      return true;
    },
  },
  mounted() {
    EventBus.$on("show-order-detail", this.load);
  },
  destroyed() {
    EventBus.$off("show-order-detail", this.load);
  },
  methods: {
    ...mapActions(["setOrder"]),
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      const result = await this.$api.order.retrieve(params);
      this.setOrder(result);
      await this.wait(100);
      this.$refs.actionLogs.load();
      await this.loadDelivOrder();
      await this.loadTransfers();
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async loadDelivOrder() {
      if (this.order.type != "delivery") return;
      const params = { criteria: { order: this.order._id } };
      try {
        this.delivOrder = await this.$api.delivOrder.retrieve(params);
      } catch (err) {
        this.delivOrder = null;
      }
    },
    async loadTransfers() {
      this.transfers = null;
      const params = { criteria: { order: this.order._id } };
      try {
        this.transfers = await this.$api.bizTransfer.list(params);
      } catch (err) {
        this.transfers = [];
      }
    },
    handleChargeback() {
      if (!this.order) return;
      EventBus.$emit("chargeback-order", this.order);
    },
  },
};
</script>
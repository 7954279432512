<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(
      x-small,
      text,
      color="secondary",
      v-on="on",
      v-if="showRefundBtn",
      @click="open"
    ) Refund
  v-card(v-if="item")
    v-card-title Refund Credit
    form(v-on:submit.prevent="refund(item)")
      v-card-text
        .sl-row
          .my-2
            div {{ item.created | datetime }}
            .sl-secondary-text {{ item.charge }}
            .sl-secondary-text {{ item.payment_intent }}
            .sl-secondary-text {{ item.transfer }}
          v-spacer
          span {{ item.amount | currency }}
        v-text-field(
          v-model="amount",
          label="Amount",
          type="number",
          outlined,
          dense,
          required
        )
      v-card-actions
        v-btn(small, text, @click="dialog = false") Cancel
        v-btn(
          small,
          rounded,
          outlined,
          color="secondary",
          :loading="loading",
          type="submit"
        ) Refund
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      amount: 0,
      // 60 days ago
      cutoff: new Date(Date.now() - 60 * 24 * 60 * 60 * 1000),
    };
  },
  computed: {
    showRefundBtn() {
      return this.item?.amount > 0 && this.item?.created > this.cutoff;
    },
  },
  mounted() {
    EventBus.$on("show-biz-account-credit-list", this.load);
  },
  destroyed() {
    EventBus.$off("show-biz-account-credit-list", this.load);
  },
  methods: {
    open() {
      this.amount = this.item.amount;
      this.dialog = true;
    },
    async refund() {
      if (!this.amount) return;
      this.loading = true;
      const params = { creditId: this.item._id, amount: this.amount };
      try {
        const { data } = await this.axios.post(
          "/bizAccountCredit/refund",
          params
        );
        this.$toast.success("Refunded");
        this.$emit("refunded", data);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
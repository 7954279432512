<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .overline Cart
    v-spacer
    v-btn(icon, small, @click="handleReset()", title="clean cart")
      v-icon(small) mdi-delete
  v-divider
  CartBiz
  CartContent
  v-card-text
    v-btn(block, color="secondary", @click="handleSubmit", :loading="loading") Submit
</template>

<script>
import CartBiz from "./CartBiz";
import CartContent from "./CartContent";
import { mapActions } from "vuex";

export default {
  components: { CartBiz, CartContent },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["resetCart", "submitCart"]),
    async handleSubmit() {
      this.loading = true;
      try {
        await this.submitCart();
        this.$toast.success("Cart submitted successfully");
      } catch (e) {
        this.$toast.error(e.message);
      }
      this.loading = false;
    },
    handleReset() {
      confirm("Would you like to clear all items in your cart?") &&
        this.resetCart();
    },
  },
};
</script>

<template lang="pug">
v-btn(
  @click="runDailyJobs",
  outlined,
  color="secondary",
  small,
  :loading="loading"
) Run Daily Jobs2
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async runDailyJobs() {
      this.loading = true;
      try {
        await this.axios.get("/corp/tool/jobs/runDailyJobs2");
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>
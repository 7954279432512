<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card
    v-card-title
    v-card-text
      OrderHeader(:order="order")
      v-form(@submit.prevent="submit()")
        v-text-field(
          v-model.number="amount",
          type="number",
          label="Extra Charge Amount; Before Tax"
        )
        v-text-field(v-model="reason", label="Reason")
        v-btn(
          type="submit",
          :disabled="!amount",
          block,
          color="secondary",
          :loading="loading"
        ) Adjust Price
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data: () => ({
    dialog: false,
    loading: false,
    amount: 0,
    reason: "",
  }),
  computed: {
    ...mapGetters(["order"]),
    valid() {
      return this.order && this.order.proxy != "Test" && this.order.status >= 0;
    },
  },
  mounted() {
    EventBus.$on("adjust-price-order", this.open);
  },
  destroyed() {
    EventBus.$off("adjust-price-order", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open() {
      this.amount = 0;
      this.reason = "";
      this.dialog = true;
    },
    async submit() {
      if (!this.order) return;
      if (!this.amount) return;
      if (!this.reason) return;
      this.loading = true;
      const params = {
        orderId: this.order._id,
        amount: this.amount,
        reason: this.reason,
      };
      try {
        const { data } = await this.axios.post("/orders/adjustPrice", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

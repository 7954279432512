<template lang="pug">
v-dialog(v-model="dialog", max-width="500", width="95%")
  v-card(v-if="member")
    v-container(grid-list-sm)
      v-form(@submit.prevent="submit")
        v-text-field(label="Email", v-model="member.email")
        v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      member: null,
    };
  },
  mounted() {
    EventBus.$on("edit-member-email", this.edit);
  },
  destroyed() {
    EventBus.$off("edit-member-email", this.edit);
  },
  methods: {
    ...mapActions(["updateMember"]),
    edit(input) {
      if (!input) return;
      this.member = JSON.parse(JSON.stringify(input));
      this.dialog = true;
    },
    async submit() {
      if (!this.member || !this.member._id) return;
      const params = { id: this.member._id, email: this.member.email };
      const { data } = await this.axios.post("/member/edit/email", params);
      this.updateMember(data);
      this.dialog = false;
    },
  },
};
</script>
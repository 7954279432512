<template lang="pug">
tr
  th Email
  td {{ user.email }}
    v-dialog(v-model="dialog", width="300")
      template(v-slot:activator="{ on }")
        v-btn.ml-2(icon, v-on="on", color="secondary", small)
          v-icon(small) mdi-pencil
      v-card
        v-card-title Edit User Email
        v-card-text
          v-form(@submit.prevent="submit")
            v-text-field(v-model="newEmail", label="New Email", required)
            v-btn(
              :disabled="!newEmail",
              color="primary",
              type="submits",
              :loading="loading"
            ) Update
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      dialog: false,
      newEmail: "",
      loading: false,
    };
  },
  methods: {
    async submit() {
      const params = { email: this.user.email, newEmail: this.newEmail };
      this.loading = true;
      try {
        const result = await this.$api.user.custom("editEmail", params);
        this.$emit("update", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  v-btn-toggle.mb-3(
    v-model="selection",
    rounded,
    dense,
    color="secondary",
    @change="handleSelect()"
  )
    v-btn.text-capitalize(
      v-for="(item, index) in items",
      :key="index",
      :value="item.value",
      small
    ) {{ item.text }}
  v-progress-linear(color="secondary", v-if="loading", indeterminate)
  Grouping
</template>

<script>
import { mapActions } from "vuex";
import Grouping from "./Grouping";

export default {
  components: { Grouping },
  data() {
    return {
      loading: false,
      selection: -1,
      items: [
        { text: "Online Only", value: 0 },
        { text: "POS Only", value: 1 },
        { text: "POS+Online", value: 2 },
        { text: "Unassigned", value: 3 },
        { text: "Dummy", value: 4 },
        { text: "Permanently Closed", value: 5 },
      ],
    };
  },
  mounted() {
    this.setBizAccounts([]);
  },
  methods: {
    ...mapActions(["setBizAccounts"]),
    handleSelect() {
      switch (this.selection) {
        case 0:
          this.loadOnlineOnly();
          break;
        case 1:
          this.loadPOSOnly();
          break;
        case 2:
          this.loadOnlinePOS();
          break;
        case 3:
          this.loadUnassigned();
          break;
        case 4:
          this.loadDummy();
          break;
        case 5:
          this.loadPermanentClose();
          break;
        default:
          break;
      }
    },
    async loadOnlineOnly() {
      const criteria = {
        online_start: { $gt: 0 },
        online_end: null,
        pos_start: null,
        standing: { $nin: ["test", "permanently_closed"] },
      };
      this.load(criteria);
    },
    async loadPOSOnly() {
      const criteria = {
        pos_start: { $gt: 0 },
        pos_end: null,
        online_start: null,
        standing: { $nin: ["test", "permanently_closed"] },
      };
      this.load(criteria);
    },
    async loadOnlinePOS() {
      const criteria = {
        pos_start: { $gt: 0 },
        pos_end: null,
        online_start: { $gt: 0 },
        online_end: null,
        standing: { $nin: ["test", "permanently_closed"] },
      };
      this.load(criteria);
    },
    async loadUnassigned() {
      const criteria = {
        pos_start: null,
        online_start: null,
        standing: { $nin: ["test", "permanently_closed"] },
      };
      this.load(criteria);
    },
    async loadDummy() {
      const criteria = { standing: "test" };
      this.load(criteria);
    },
    async loadPermanentClose() {
      const criteria = { standing: "permanently_closed" };
      this.load(criteria);
    },
    async load(criteria) {
      const params = {
        criteria: criteria,
        select:
          "name logo status address orderType pos_start pos_end online_start online_end google_connect website_connect standing",
      };
      this.loading = true;
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
      this.loading = false;
    },
  },
};
</script>
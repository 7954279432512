<template lang="pug">
div
  .sl-title Misc
  v-data-table(:headers="headers", :items="miscItems", mobile-breakpoint=0)
    template(v-slot:item.date="{ item }")
      td {{ item.date | date }}
    template(v-slot:item.staff="{ item }")
      UserName(:users="users", :id="props.item.staff")
    template(v-slot:item.action="{ item }")
      DeleteMisc(
        :item="props.item",
        @deleted="miscItems.splice(props.item.index, 1)"
      )
</template>

<script>
import UserName from "./UserName";
import DeleteMisc from "./DeleteMisc";

export default {
  components: { UserName, DeleteMisc },
  props: ["users", "miscItems"],
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Staff", value: "staff", sortable: "" },
        { text: "Type", value: "type", sortable: "" },
        { text: "Vendor", value: "vendor", sortable: false },
        { text: "Order", value: "order", sortable: false },
        { text: "Cost", value: "cost", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },
};
</script>

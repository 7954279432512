<template lang="pug">
div
  Create
  List.my-5
  Contact
</template>

<script>
import Create from "./Create";
import List from "./List";
import Contact from "./Contact";
import { mapActions } from "vuex";

export default {
  components: { Create, List, Contact },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setMerchants"]),
    async load() {
      try {
        const params = { criteria: {} };
        const data = await this.$api.merchant.account.list(params);
        this.setMerchants(data);
      } catch (e) {
        this.setMerchants([]);
      }
    },
  },
};
</script>

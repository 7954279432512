<template lang="pug">
div
  DaySelector(@select="load")
  .s-vstack
    List
    CountBySignin
</template>

<script>
import List from "./../List";
import CountBySignin from "./CountBySignin";
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  components: { List, CountBySignin },
  methods: {
    ...mapActions(["setUserAccounts"]),
    destroyed() {
      this.setUserAccounts([]);
    },
    async load(day) {
      const begin = moment(day).startOf("day").valueOf();
      const end = moment(day).endOf("day").valueOf();
      const params = {
        criteria: { created: { $gt: begin, $lte: end } },
        select: "email google apple status phones member payments created",
        limit: 1000,
      };
      try {
        const result = await this.$api.user.list(params);
        this.setUserAccounts(result);
      } catch (e) {
        this.setUserAccounts([]);
      }
    },
  },
};
</script>

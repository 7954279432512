<template lang="pug">
div
  v-btn-toggle.mb-3(v-model="selection", rounded, dense, color="secondary")
    v-btn.text-capitalize(
      v-for="(item, index) in items",
      :key="index",
      :value="item.value",
      small
    ) {{ item.text }}
  div(v-for="(group, index) in groups", :key="index")
    div {{ group.zone }}
    Group(:input="group.data", :selection="selection")
</template>

<script>
import _ from "underscore";
import Group from "./Group";

export default {
  components: { Group },
  data() {
    return {
      selection: 0,
      groups: [],
    };
  },
  computed: {
    items() {
      const yearStart = 2019;
      const yearEnd = new Date().getFullYear();
      const years = _.map(_.range(yearEnd, yearStart, -1), (o) => {
        return { text: String(o), value: o };
      });
      let data = [
        { text: "Yearly", value: 0 },
        { text: "Monthly", value: 1 },
      ];
      return data.concat(years);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const { data } = await this.axios.post("/delivery/dailysummary/monthly");
      this.groups = _.chain(data)
        .groupBy("zone")
        .map((data, zone) => {
          return { zone, data };
        })
        .sortBy("zone")
        .value();
    },
  },
};
</script>

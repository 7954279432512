<template lang="pug">
div
  v-btn(@click="dialog = true", outlined) Show Pricings
  v-dialog(v-model="dialog", width="500")
    v-card(flat)
      simple-table
        thead
          tr
            th Service
            th Price
        tbody
          tr
            th POS
            td {{ bizservicepricing.pos | currency }}
          tr
            th Credit Card Machine
            td {{ bizservicepricing.ccm | currency }}
          tr
            th Kitchen Display
            td {{ bizservicepricing.kds | currency }}
          tr
            th Inventory
            td {{ bizservicepricing.inventory | currency }}
          tr
            th Quickbooks
            td {{ bizservicepricing.qbk | currency }}
          tr
            th Reservation
            td {{ bizservicepricing.rsv | currency }}
          tr
            th Drive Thru
            td {{ bizservicepricing.dt | currency }}
          tr
            th Front Facing
            td {{ bizservicepricing.ff | currency }}
          tr
            th Member Basic
            td {{ bizservicepricing.member_basic | currency }}
          tr
            th Member Advanced
            td {{ bizservicepricing.member_advanced | currency }}
          tr
            th Third Party Integration
            td {{ bizservicepricing.third_party | currency }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["bizservicepricing"]),
  },
};
</script>

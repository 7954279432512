import Home from './index.vue'
import Dashboard from './Dashboard'
import Activities from './Activities'
import Contacts from './Contacts'
import ServiceLogs from './ServiceLogs'
import Histories from './Histories'

export default [
  {
    path: '/salesforce/crm', component: Home, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'dashboard' },
      { path: 'dashboard', component: Dashboard },
      { path: 'activities', component: Activities },
      { path: 'contacts', component: Contacts },
      { path: 'service-logs', component: ServiceLogs },
      { path: 'histories', component: Histories },
    ]
  },
]
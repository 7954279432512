<template lang="pug">
CountTableChart(name="City", :items="items")
</template>

<script>
export default {
  props: {
    result: { type: Array, required: true },
  },
  computed: {
    items() {
      return this.result?.map((o) => {
        const name = `${o.city} (${o.state}, ${o.country})`;
        return { name, count: o.count };
      });
    },
  },
};
</script>

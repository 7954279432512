<template lang="pug">
v-btn.text-capitalize(small, @click="submit()", :loading="loading") Auto Assign
</template>

<script>
export default {
  props: { year: { type: Number, default: null } },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async submit() {
      if (!this.year) return this.$toast.error("Please provide a year");
      this.loading = true;
      try {
        const params = { year: this.year };
        const result = await this.$api.taxFile.custom("autoAssign", params);
        this.$emit("update");
        this.$toast.success(result);
      } catch (err) {
        this.$toast.error(err.response?.data || "Failed to auto assign");
      }
      this.loading = false;
    },
  },
};
</script>

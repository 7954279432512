<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Date
        th Biz
        th Amount
        th Balance
        th To
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        @click="open(item)",
        role="button"
      )
        td {{ item.created | date }}
        td {{ item.name }}
        td {{ item.amount | currency }}
        td {{ item.balance | currency }}
        td {{ item.to }}
    tfoot
      tr
        td {{ items.length }}
        td
        td {{ total_amount | currency }}
        td {{ total_balance | currency }}
        td
  Detail(ref="detail")
</template>

<script>
import Detail from "./Detail";

export default {
  components: { Detail },
  props: ["items"],
  computed: {
    total_amount() {
      return this.items.reduce((a, b) => a + b.amount, 0);
    },
    total_balance() {
      return this.items.reduce((a, b) => a + b.balance, 0);
    },
  },
  methods: {
    open(item) {
      this.$refs.detail.open(item);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card(v-if="organization")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }} an Organization
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          v-model="organization.name",
          label="Name",
          :disabled="!isInternal"
        )
        PhoneField(v-model="organization.phone")
        v-text-field(v-model="organization.email", label="Email")
        GoogleAddressField(v-model="organization.address")
        v-switch(
          v-model="organization.billing.status",
          color="secondary",
          label="Bill Organization",
          v-if="isInternal"
        )
        v-text-field(
          v-model.number="organization.billing.discount_percentage",
          label="Discount %",
          v-if="organization.billing.status && isInternal"
        )
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  name: "EditSalesOrganization",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      organization: null,
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    title() {
      if (this.organization?._id) return "Edit";
      return "Add";
    },
    valid() {
      return !!this.organization.name;
    },
  },
  mounted() {
    EventBus.$on("edit-sales-organization", this.open);
  },
  destroyed() {
    EventBus.$off("edit-sales-organization", this.open);
  },
  methods: {
    ...mapActions(["addSalesOrganization", "updateSalesOrganization"]),
    open(data) {
      if (data) {
        this.organization = JSON.parse(JSON.stringify(data));
      } else {
        this.organization = {
          name: "",
          phone: "",
          email: "",
          address: null,
          billing: {
            status: false,
            discount_percentage: 0,
          },
        };
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.organization) return;
      const data = {
        name: this.organization.name.trim(),
        phone: this.organization.phone,
        email: this.organization.email,
        address: this.organization.address,
        billing: this.organization.billing,
      };
      this.loading = true;
      try {
        if (this.organization._id) {
          const action = { $set: data };
          const result = await this.$api.salesforce.organization.put(
            this.organization._id,
            action
          );
          this.updateSalesOrganization(result);
          this.dialog = false;
        } else {
          const result = await this.$api.salesforce.organization.create(data);
          this.addSalesOrganization(result);
          this.dialog = false;
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400", @keydown.esc="dialog = false")
  v-card(v-if="product")
    v-form(@submit.prevent="submit")
      v-toolbar(dense, flat)
        .overline {{ title }}
        v-spacer
        v-checkbox(
          label="Available",
          hide-details,
          dense,
          color="secondary",
          v-model="product.status"
        )
      v-divider
      v-card-text
        v-text-field(label="Name", v-model="product.name", ref="focus")
        v-row
          v-col(cols="6")
            PriceField(v-model="product.price")
          v-col(cols="6")
            PriceField(v-model="product.cost", label="Cost")
        v-row
          v-col(cols="6")
            v-text-field(label="SKU", v-model="product.sku")
          v-col(cols="6")
            v-text-field(label="MFR (Manufacture Code)", v-model="product.mfr")
        v-select(
          label="Tax Type",
          v-model="product.taxType",
          :items="tax_items"
        )
        v-text-field(label="Detail", v-model="product.detail")
        v-text-field(label="Amazon Link", v-model="product.amazon")
        v-text-field(label="BP Photo Link", v-model="product.bhphoto")
        v-row
          v-col(cols="6")
            v-checkbox(
              label="Taxable",
              dense,
              color="secondary",
              v-model="product.taxable"
            )
          v-col(cols="6")
            v-checkbox(
              label="Third Party Only",
              dense,
              color="secondary",
              v-model="product.thirdpartyonly"
            )
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "ProductForm",
  data() {
    return {
      product: null,
      dialog: false,
      loading: false,
      tax_items: [
        { text: "Tangible Goods", value: "tg" },
        { text: "Digital", value: "digital" },
        { text: "Business Service", value: "business_service" },
      ],
    };
  },
  computed: {
    title() {
      if (this.product && this.product._id) return "Edit Product";
      else return "Create Product";
    },
  },
  mounted() {
    EventBus.$on("edit-product", this.load);
  },
  destroyed() {
    EventBus.$off("edit-product", this.load);
  },
  methods: {
    load(input) {
      if (input) {
        this.product = JSON.parse(JSON.stringify(input));
      } else {
        this.product = {
          name: "",
          detail: "",
          status: true,
          price: 0,
          cost: 0,
          sku: "",
        };
      }
      this.errors = [];
      this.dialog = true;
      this.$nextTick(() => this.$refs.focus.focus());
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.product) {
        this.errors = ["Invalid data"];
        return;
      }
      if (!this.product.name || this.product.name.length === 0)
        this.errors.push("Name is required");
      if (this.errors?.length) return;
      this.loading = true;
      this.product.sku = this.product.sku.toUpperCase();
      try {
        if (this.product?._id) {
          const action = { $set: this.product };
          const result = await this.$api.catalog.product.put(
            this.product._id,
            action
          );
          this.$store.dispatch("catalog/updateProduct", result);
          this.dialog = false;
        } else {
          const result = await this.$api.catalog.product.create(this.product);
          this.$store.dispatch("catalog/addProduct", result);
          this.dialog = false;
        }
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
StandardChart(:input="chartData", :legend="true", v-if="stats")
  v-card-text
    div Claimed: {{ claimed }}. Used: {{ applied }}
</template>

<script>
import _ from "underscore";

export default {
  name: "CouponClaimedChart",
  props: ["stats"],
  data() {
    return {
      applied: 0,
      claimed: 0,
      chartData: {},
    };
  },
  watch: {
    stats(val) {
      if (val) this.getData();
    },
  },
  methods: {
    getData() {
      const day = 24 * 60 * 60 * 1000;
      const totaldays = Math.ceil(
        (this.stats.expiry - this.stats.created) / day
      );
      const days = _.range(1, totaldays + 2);
      this.chartData = {
        title: "Claimed & Used Coupons",
        labels: days,
        datasets: [
          { label: "Used", backgroundColor: "#2cb2b9", data: this.getApply() },
          {
            label: "Claimed",
            backgroundColor: "#7682d6",
            data: this.getClaim(),
          },
        ],
      };
    },
    getClaim() {
      if (!this.stats?.claim?.length) return [];
      this.claimed = 0;
      const claim = this.stats.claim?.toSorted();
      const last = _.last(claim);
      let data = _.map(_.range(0, last + 1), () => {
        return 0;
      });
      _.each(claim, (o) => {
        let start = o;
        while (start < data.length) {
          data[start]++;
          start++;
        }
        this.claimed++;
      });
      return data;
    },
    getApply() {
      if (!this.stats?.apply?.length) return [];
      this.applied = 0;
      const apply = this.stats.apply.toSorted();
      const last = _.last(apply);
      let data = _.map(_.range(0, last + 1), () => {
        return 0;
      });
      _.each(apply, (o) => {
        let start = o;
        while (start < data.length) {
          data[start]++;
          start++;
        }
        this.applied++;
      });
      return data;
    },
  },
};
</script>

import Home from './index.vue'
import Accounts from './Accounts/index.vue'
import Payouts from './Payouts/index.vue'

export default [{
  path: '/partner',
  component: Home,
  meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'accounts' },
    { path: 'accounts', component: Accounts },
    { path: 'payouts', component: Payouts }
  ]
}]
<template lang="pug">
.d-flex.flex-row.align-center.subtitle-2
  v-icon.mx-3(small) mdi-clock-outline
  div
    span(v-if="order.pickupTime") {{ order.pickupTime | time }}
    span.mx-1 -
    span(v-if="order.deliverTime") {{ order.deliverTime | time }}
    span.ml-1(v-if="distance") {{ distance }} mi.
</template>

<script>
export default {
  props: ["order"],
  computed: {
    distance() {
      let pos1 = this.order && this.order.deliv_location;
      if (typeof pos1 == "string") {
        pos1 = JSON.parse(pos1);
      }
      const pos2 =
        this.order &&
        this.order.customer &&
        this.order.customer.address &&
        this.order.customer.address.geometry;
      if (!pos1 || !pos2 || !pos1.lat) return null;

      // ref http://www.movable-type.co.uk/scripts/latlong.html
      // use Equirectangular approximation
      const radius = 6378000; // earth radius in meters
      const phi1 = toRadius(pos1.lat);
      const phi2 = toRadius(pos2.lat);
      const lamda1 = toRadius(pos1.lng);
      const lamda2 = toRadius(pos2.lng);
      const x = (lamda2 - lamda1) * Math.cos((phi1 + phi2) / 2);
      const y = phi2 - phi1;
      let d = Math.sqrt(x * x + y * y) * radius;

      function toRadius(input) {
        return (input * Math.PI) / 180;
      }

      const unit = "mi.";
      if (unit == "mi.") {
        d *= 0.000621371;
      } else {
        d *= 0.001;
      }
      return Math.floor(d * 100) / 100;
    },
  },
};
</script>
<template lang="pug">
v-list-item(v-on="$listeners")
  v-list-item-content
    v-list-item-title {{ item.title }}
    v-list-item-subtitle.caption
      div {{ fromNow }} | {{ item.sales_person_name }} ({{ organization }}) | {{ item.biz_name }}
      .caption {{ item.tags?.join(", ") }}
      div {{ item.description | truncate(100) }}
  v-list-item-action
    .d-flex.flex-row(v-if="count")
      v-icon.mr-2(small) mdi-comment-outline
      .caption {{ count }}
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    organization() {
      const id = this.item?.organization;
      if (!id) return "";
      return this.salesOrganizations.find((item) => item._id == id)?.name;
    },
    count() {
      return this.item?.comments?.length || 0;
    },
    fromNow() {
      const occured_at = this.item?.occured_at;
      if (!occured_at) return "";
      return moment(occured_at).fromNow();
    },
  },
};
</script>
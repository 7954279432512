const state = {
  merchantIncomes: [],
};

const getters = {
  merchantIncomes: state => state.merchantIncomes
};

const actions = {
  setMerchantIncomes: ({ commit }, data) => { commit("setMerchantIncomes", data); },
  updateMerchantIncome: ({ commit }, data) => { commit("updateMerchantIncome", data); },
  addMerchantIncome: ({ commit }, data) => { commit("addMerchantIncome", data); },
  removeMerchantIncome: ({ commit }, data) => { commit("removeMerchantIncome", data); }
};

const mutations = {
  setMerchantIncomes(state, data) {
    state.merchantIncomes = data;
  },
  updateMerchantIncome(state, data) {
    state.merchantIncomes = state.merchantIncomes.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addMerchantIncome(state, data) {
    state.merchantIncomes.push(data);
  },
  removeMerchantIncome(state, data) {
    state.merchantIncomes = state.merchantIncomes.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
v-card(flat, :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 Enterprise
    v-spacer
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  v-data-table(
    :headers="headers",
    :items="items",
    sort-by="name",
    @click:row="edit",
    role="button",
    mobile-breakpoint="0"
  )
    template(v-slot:item.avatar="{ item }")
      v-avatar(v-if="item.avatar")
        img(:src="item.avatar")
    template(v-slot:item.address="{ item }") {{ item.address | address }}
  Form(ref="form")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "/libs/components/Enterprise/Setting/Form";

export default {
  components: { Form },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Picture", value: "avatar", filterable: false },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Country", value: "country", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprises"]),
    items() {
      return this.enterprises.map((o) => {
        if (o.profile_picture) {
          o.avatar = process.env.VUE_APP_MEDIAHOST + o.profile_picture;
        }
        return o;
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    create() {
      this.$refs.form.open();
    },
    edit(data) {
      this.$refs.form.open(data);
    },
    async load() {
      this.loading = true;
      try {
        const params = { criteria: {} };
        const result = await this.$api.entp.enterprise.list(params);
        this.$store.dispatch("entp/setEnterprises", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load enterprises");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card(v-if="orderType")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Business Order Types
    v-card-text
      v-form(@submit.prevent="save()")
        v-switch(
          label="Pick Up",
          v-model="orderType.pickup.status",
          color="secondary"
        )
        v-select(
          v-if="orderType.pickup.status",
          v-model="orderType.pickup.beforehand",
          :items="items",
          label="Lead time",
          menu-props="auto",
          dense
        )
        v-switch(
          label="Delivery",
          v-model="orderType.delivery.status",
          color="secondary"
        )
        v-select(
          v-if="orderType.delivery.status",
          v-model="orderType.delivery.beforehand",
          :items="items",
          label="Lead time",
          menu-props="auto",
          dense
        )
        v-select(
          v-if="orderType.delivery.status",
          v-model="orderType.delivery.deliv",
          :items="deliv_items",
          label="Delivery",
          menu-props="auto",
          dense
        )
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      biz: null,
      orderType: null,
      dialog: false,
      loading: false,
      items: [
        { text: "15 minutes", value: 15 },
        { text: "20 minutes", value: 20 },
        { text: "25 minutes", value: 25 },
        { text: "30 minutes", value: 30 },
        { text: "35 minutes", value: 35 },
        { text: "40 minutes", value: 40 },
        { text: "45 minutes", value: 45 },
        { text: "1 hour", value: 60 },
        { text: "1.5 hours", value: 90 },
        { text: "2 hours", value: 120 },
        { text: "3 hours", value: 180 },
        { text: "6 hours", value: 360 },
        { text: "12 hours", value: 720 },
        { text: "a day", value: 1440 },
      ],
    };
  },
  computed: {
    ...mapGetters(["delivs"]),
    deliv_items() {
      let data = this.delivs.map((o) => {
        return { text: o.name, value: o._id };
      });
      data.push({ text: "Self Delivery", value: null });
      return data;
    },
  },
  mounted() {
    this.loadDelivs();
    EventBus.$on("edit-biz-ordertype", this.open);
  },
  destroyed() {
    EventBus.$off("edit-biz-ordertype", this.open);
  },
  methods: {
    ...mapActions(["updateBizAccount", "setBiz", "setDelivs"]),
    open(data) {
      if (!data) return;
      this.biz = data;
      this.orderType = JSON.parse(JSON.stringify(data.orderType));
      this.dialog = true;
    },
    async save() {
      if (!this.biz) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: {
          $set: {
            "orderType.pickup.status": this.orderType.pickup.status,
            "orderType.pickup.beforehand": this.orderType.pickup.beforehand,
            "orderType.delivery.status": this.orderType.delivery.status,
            "orderType.delivery.beforehand": this.orderType.delivery.beforehand,
            "orderType.delivery.deliv": this.orderType.delivery.deliv,
          },
        },
      };
      try {
        const result = await this.$api.biz.update(params);
        this.updateBizAccount(result);
        this.setBiz(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadDelivs() {
      const params = { criteria: { verified: true }, select: "name" };
      const { data } = await this.axios.post("/delivery/accounts/list", params);
      this.setDelivs(data);
    },
  },
};
</script>

<template lang="pug">
div
  v-btn-toggle.mb-3(v-model="selection", rounded, dense, color="secondary")
    v-btn.text-capitalize(
      v-for="(item, index) in items",
      :key="index",
      :value="item.value",
      small
    ) {{ item.text }}
  v-row(wrap, dense)
    v-col(cols="12", md="6")
      Chart(:input="posData", title="POS Growth", :selection="selection")
    v-col(cols="12", md="6")
      Chart(:input="onlineData", title="Online Growth", :selection="selection")
    v-col(cols="12", md="6")
      Chart(
        :input="creationData",
        title="New Biz Growth",
        :selection="selection"
      )
</template>

<script>
import _ from "underscore";
import Chart from "./Chart";

export default {
  components: { Chart },
  data() {
    return {
      // current year
      selection: new Date().getFullYear(),
      posData: null,
      onlineData: null,
      creationData: null,
    };
  },
  computed: {
    items() {
      const yearStart = 2016;
      const yearEnd = new Date().getFullYear();
      const years = _.map(_.range(yearEnd, yearStart, -1), (o) => {
        return { text: String(o), value: o };
      });
      let data = [
        { text: "Yearly", value: 0 },
        { text: "Monthly", value: 1 },
      ];
      return data.concat(years);
    },
  },
  mounted() {
    this.loadPOSGrowth();
    this.loadOnlineGrowth();
    this.loadCreation();
  },
  methods: {
    async loadPOSGrowth() {
      const { data } = await this.axios.post("/bizs/summary/pos_growth");
      this.posData = data;
    },
    async loadOnlineGrowth() {
      const { data } = await this.axios.post("/bizs/summary/online_growth");
      this.onlineData = data;
    },
    async loadCreation() {
      const { data } = await this.axios.post("/bizs/summary/creation_growth");
      this.creationData = data;
    },
  },
};
</script>

<template lang="pug">
div
  div Do you want to issue a chargeback on this order? It will create a chargeback data and email both the customer and the merchant.
  v-form(@submit.prevent="submit")
    v-row.mb-2(align="center", dense, wrap)
      v-col(cols="7")
        v-text-field(
          v-model.number="amount",
          label="Amount",
          hint="Exclude chargeback fee",
          persistent-hint,
          required
        )
      v-col(cols="5")
        div Default: {{ default_amount | currency }}
      v-col(cols="7")
        v-select(v-model="reason", :items="reason_items")
      v-col(cols="5")
        v-switch(v-model="mail", label="Email", color="secondary")
    v-btn(type="submit", color="secondary", :loading="loading", block) Issue Chargeback
</template>

<script>
export default {
  props: ["order"],
  data() {
    return {
      amount: 0,
      reason: "Fraudulent",
      mail: true,
      loading: false,
      reason_items: [
        "Fraudulent",
        "Not authorized",
        "Product not received",
        "Duplicate charge",
        "Product was defective",
      ],
    };
  },
  computed: {
    default_amount() {
      return this.order?.payment?.total || 0;
    },
  },
  methods: {
    reset() {
      this.reason = "Fraudulent";
      this.mail = true;
    },
    async submit() {
      this.amount = this.amount > 0 ? this.amount : this.default_amount;
      if (!this.order?._id || !this.amount) return;
      this.loading = true;
      const params = {
        orderId: this.order._id,
        amount: this.amount,
        reason: this.reason,
        mail: this.mail,
      };
      try {
        const { data } = await this.axios.post("/orders/chargeback", params);
        this.$emit("success", data);
        this.reset();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", icon="mdi-plus", title="Add")
  v-card
    v-toolbar(dense, flat)
      .overline Create a Merchant
    v-card-text
      BizSelector(@select="selectBiz")
      v-form(@submit.prevent="submit")
        v-text-field(
          v-model="start",
          label="Start Date (YYYYMMDD)",
          value="number"
        )
        v-btn(
          type="submit",
          block,
          depressed,
          color="secondary",
          :disabled="!bizId"
        ) Save
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      bizId: null,
      start: parseInt(moment().format("YYYYMMDD")),
    };
  },
  methods: {
    ...mapActions(["addMerchant"]),
    selectBiz(biz) {
      this.bizId = biz._id;
    },
    async submit() {
      const params = { bizId: this.bizId, start: this.start };

      try {
        const result = await this.$api.merchant.account.create(params);
        this.addMerchant(result);
        this.dialog = false;
        this.bizId = null;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

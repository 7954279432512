<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 Location Detail
      v-spacer
      IconBtn(@click="edit = !edit", title="Edit", icon="mdi-pencil")
    div(v-if="!edit")
      simple-table
        tbody
          tr
            th Name
            td {{ biz.name }}
          tr
            th Store Name
            td {{ biz.short_name }}
          tr
            th Address
            td {{ biz.address | address }}
          tr
            th Suite/Unit
            td {{ biz.address?.line2 }}
          tr
            th Address Note
            td {{ biz.address?.note }}
          tr
            th Online Status
            td {{ biz.status?.byAdmin == 1 ? "Online" : "Offline" }}
          tr
            th Today Hours
            td
              DayHours(:schedule="biz.schedule")
      v-card-actions
        DeleteOne(:biz="biz", @deleted="deleted")
    v-form(v-else, @submit.prevent="save")
      v-card-text
        v-text-field(v-model="biz.name", label="Name", :disabled="!isInternal")
        v-text-field(
          v-model="biz.short_name",
          label="Store Name (optional)",
          hint="Identify location for a chain of stores, such as Township, City, or Street Name",
          persistent-hint
        )
        GoogleAddressField(v-model="biz.address")
        v-text-field(
          label="Suite/Unit (optional)",
          v-model="biz.address.line2"
        )
        v-text-field(
          label="Address Note (optional)",
          v-model="biz.address.note",
          hint="Only add if necessary, such as an entrance guide"
        )
      v-card-actions
        v-btn(type="submit", color="secondary", :loading="loading", block) Save
</template>

<script>
import DeleteOne from "./DeleteOne";
export default {
  components: { DeleteOne },
  data() {
    return {
      dialog: false,
      edit: false,
      loading: false,
      biz: {},
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
  },
  methods: {
    open(biz) {
      this.edit = false;
      this.biz = JSON.parse(JSON.stringify(biz));
      this.dialog = true;
    },
    async save() {
      try {
        this.loading = true;
        const action = {
          $set: {
            name: this.biz.name,
            short_name: this.biz.short_name,
            address: this.biz.address,
          },
        };
        await this.$api.biz.put(this.biz._id, action);
        this.$toast.success("Location updated successfully");
        this.$emit("updated");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update location");
      }
      this.loading = false;
    },
    async deleted() {
      this.$emit("updated");
      this.dialog = false;
    },
  },
};
</script>
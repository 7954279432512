<template lang="pug">
StandardChart(
  :input="chartData",
  :showDownload="true",
  :showTotal="true",
  chartType="bar",
  max-width="100%"
)
</template>

<script>
import _ from "underscore";

export default {
  props: ["input", "title", "selection"],
  computed: {
    chartData() {
      // yearly
      if (this.selection == 0) {
        const data = _.chain(this.input)
          .groupBy("year")
          .map((list, k) => {
            return { year: k, count: list.reduce((a, b) => a + b.count, 0) };
          })
          .sortBy("year")
          .value();
        return {
          title: this.title,
          labels: _.pluck(data, "year"),
          values: _.pluck(data, "count"),
          isCurrency: false,
        };
      }
      // monthly
      let data = JSON.parse(JSON.stringify(this.input));
      let labels;
      if (this.selection > 1000) {
        const year = this.selection;
        const filtered = this.input?.filter((o) => o.year == year);
        data = _.map(_.range(1, 13), (month) => {
          const found = filtered?.find((o) => o.month == month);
          return found || { year, month, count: 0 };
        });
        data = _.sortBy(data, (o) => o.month);
        labels = data.map((o) => this.$options.filters.month(o.month, true));
      } else {
        data = _.sortBy(data, (o) => o.year * 100 + o.month);
        labels = data.map((o) => `${o.year}/${o.month}`);
      }
      return {
        title: this.title,
        labels,
        values: _.pluck(data, "count"),
        isCurrency: false,
      };
    },
  },
};
</script>

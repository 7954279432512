<template lang="pug">
v-list-item(@click="handler()") Merge
</template>

<script>
export default {
  props: ["program"],
  methods: {
    async handler() {
      if (!this.program) return;
      const params = { programId: this.program._id };
      try {
        const { data } = await this.axios.post(
          "/member/programs/merge",
          params
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

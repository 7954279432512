<template lang="pug">
div
  v-card-text
    .d-flex.flex-row.align-center
      .subtitle-2 7Shifts Integration
      v-spacer
      IconBtn(@click="handleEdit", small, text)
  simple-table
    tbody
      tr
        th Company ID
        td.text-right {{ app?.company_id }}
      tr
        th Store ID
        td.text-right {{ app?.store_id }}
      tr
        th Status
        td.text-right {{ app?.status }}
  v-dialog(v-model="dialog", width="400")
    v-card
      v-card-title 7Shifts Settings
      v-form(@submit.prevent="submit")
        v-card-text
          v-text-field(v-model="company_id", label="Company ID")
          v-text-field(v-model="store_id", label="Store ID")
          v-select(v-model="status", label="Status", :items="statuses")
        v-card-actions
          v-btn(color="secondary", type="submit", :loading="loading", block) Submit
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      company_id: "",
      store_id: "",
      status: "established",
      statuses: ["established"],
    };
  },
  computed: {
    app() {
      return this.item?.apps?.find((app) => app.name === "7shifts");
    },
  },
  methods: {
    ...mapActions(["setBizService"]),
    handleEdit() {
      this.company_id = this.app?.company_id;
      this.store_id = this.app?.store_id;
      this.status = this.app?.status;
      this.dialog = true;
    },
    async submit() {
      this.loading = true;
      let criteria;
      let action;
      if (this.app) {
        criteria = { _id: this.item._id, "apps.name": "7shifts" };
        action = {
          $set: {
            "apps.$.company_id": this.company_id,
            "apps.$.store_id": this.store_id,
            "apps.$.status": this.status,
          },
        };
      } else {
        criteria = { _id: this.item._id };
        action = {
          $push: {
            apps: {
              name: "7shifts",
              company_id: this.company_id,
              store_id: this.store_id,
              status: this.status,
            },
          },
        };
      }
      try {
        const result = await this.$api.service.account.update({
          criteria,
          action,
        });
        this.setBizService(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
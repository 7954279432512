<template lang="pug">
.d-flex.flex-row.align-center
  v-select(v-model="enterprise", :items="items", label="Enterprise")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EnterprisePicker",
  props: { value: { type: String, default: null } },
  data() {
    return {
      enterprise: this.value,
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprises"]),
    items() {
      return this.enterprises
        .map((i) => ({ text: i.name, value: i._id }))
        .toSorted((a, b) => a.text.localeCompare(b.text));
    },
  },
  watch: {
    value(v) {
      this.enterprise = v;
    },
    enterprise(v) {
      this.$emit("input", v);
    },
  },
  methods: {
    async load() {},
    setEnterprise(enterprise) {
      this.enterprise = enterprise._id;
    },
  },
};
</script>
<template lang="pug">
div
  v-text-field.my-3(
    v-model="searchText",
    label="Search Biz",
    clearable,
    solo,
    dense
  )
  div(
    v-for="(byState, sindex) in grouped",
    :key="sindex",
    rounded,
    depressed,
    @click="selectedCities = byState.cities"
  )
    .d-flex.flex-row.align-center.my-3
      .title {{ byState.state }}
      v-chip.ml-3(small, outlined) {{ byState.cities.length }}
    div(
      v-for="(byCity, cindex) in byState.cities",
      :key="cindex",
      rounded,
      depressed
    )
      .d-flex.flex-row.align-center.my-3
        .subtitle-2 {{ byCity.city }}
        v-chip.ml-3(small, outlined) {{ byCity.bizs.length }}
      Table(:bizs="byCity.bizs", :searchText="searchText")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      searchText: "",
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      selectedBizId: null,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    grouped() {
      return _.chain(this.bizAccounts)
        .groupBy((o) => o.address.state)
        .map((o, k) => {
          const bizs = _.sortBy(o, (item) => item.name);
          const groupByCity = _.chain(bizs)
            .groupBy((item) => item.address.city)
            .map((co, ck) => {
              return { city: ck, bizs: co };
            })
            .sortBy("city")
            .value();
          return { state: k, cities: groupByCity };
        })
        .sortBy("state")
        .value();
    },
  },
};
</script>

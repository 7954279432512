const state = {
  members: []
}

const getters = {
  members: state => state.members
}

const actions = {
  setMembers: ({ commit }, data) => { commit('setMembers', data) },
  updateMember: ({ commit }, data) => { commit('updateMember', data) },
  addMember: ({ commit }, data) => { commit('addMember', data) },
  removeMember: ({ commit }, data) => { commit('removeMember', data) }
}

const mutations = {
  setMembers(state, data) { state.members = data },
  updateMember(state, data) {
    state.members = state.members.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  addMember(state, data) { state.members.push(data) },
  removeMember(state, data) {
    state.members = state.members.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
<template lang="pug">
div
  YearSelector(@select="select")
  Monthly.my-3(:items="monthly", :year="year")
  Generate.my-3(:year="year", @refresh="select")
</template>

<script>
import Monthly from "./Monthly";
import Generate from "./Generate";

export default {
  components: { Monthly, Generate },
  data() {
    return { year: 0, items: [], monthly: [] };
  },
  methods: {
    select(year) {
      if (year) this.year = year;
      this.loadMonthly();
    },
    async loadMonthly() {
      const params = { criteria: { "period.year": this.year } };
      this.monthly = await this.$api.bizOrder.custom("monthly/list", params);
    },
  },
};
</script>
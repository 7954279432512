const state = {
  blacklistUsers: []
};

const getters = {
  blacklistUsers: state => state.blacklistUsers
};

const actions = {
  setBlacklistUsers: ({ commit }, data) => { commit("setBlacklistUsers", data); },
  updateBlacklistUser: ({ commit }, data) => { commit("updateBlacklistUser", data); },
  addBlacklistUser: ({ commit }, data) => { commit('addBlacklistUser', data) },
  removeBlacklistUser: ({ commit }, data) => { commit("removeBlacklistUser", data); },
};

const mutations = {
  setBlacklistUsers(state, data) { state.blacklistUsers = data; },
  updateBlacklistUser(state, data) {
    state.blacklistUsers = state.blacklistUsers.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addBlacklistUser(state, data) { state.blacklistUsers.push(data) },
  removeBlacklistUser(state, data) {
    state.blacklistUsers = state.blacklistUsers.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  .link-box
    v-btn(@click="proceed", :loading="loading", small, outlined) Ops Duplicate Check
    YearSelector(@select="year = $event")
  .caption(v-for="(item, index) in result", :key="index") {{ item.biz }} {{ item.date }} {{ item.count }}
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      result: [],
      year: 2023,
    };
  },
  methods: {
    async proceed() {
      this.result = [];
      this.loading = true;
      try {
        const params = { year: this.year };
        const { data } = await this.axios.post("/bizops/migrate", params);
        this.result = data;
      } catch (err) {
        const message = err.response?.data || err.message;
        this.$toast.error(message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="load()", :loading="loading") List Terminals
  simple-table
    tbody
      tr(v-for="(item, index) in items", :key="index")
        th Terminal ID
        td {{ item }}
</template>

<script>
export default {
  props: {
    mid: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  methods: {
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = { mid: this.mid };
      try {
        const result = await this.$api.cardpointe.bolt.listTerminals(params);
        this.items = result?.terminals;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
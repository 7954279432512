import Sales from './index.vue'
import BizOrder from './Orders/index.vue'
import BizOrderAnnualReport from './Report/Annual/index.vue'
import Purchase from './Purchase/index.vue'
import Growth from './Growth/index.vue'
import YoY from './YoY/index.vue'


export default [
  {
    path: '/shop/sales', component: Sales, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'bizorder' },
      { path: 'bizorder', component: BizOrder },
      { path: 'annual_report', component: BizOrderAnnualReport },
      { path: 'purchase', component: Purchase },
      { path: 'growth', component: Growth },
      { path: 'yoy', component: YoY },
    ]
  },
]
<template lang="pug">
Page(title="Product Catalog", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Groups", url: "/shop/catalog/groups" },
        { title: "SubGroups", url: "/shop/catalog/subgroups" },
        { title: "Products", url: "/shop/catalog/products" },
        { title: "State Tax", url: "/shop/catalog/statetax" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="created",
  sort-desc,
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.address="{ item }")
    span {{ item.address | address("city, state") }}
  template(v-slot:item.online_start="{ item }")
    span {{ item.online_start | date }}
  template(v-slot:item.pos_start="{ item }")
    span {{ item.pos_start | date }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Created", value: "created", filterable: false },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Online", value: "online_start" },
        { text: "POS", value: "pos_start" },
      ],
    };
  },
};
</script>
<template lang="pug">
v-card(v-if="product", flat)
  Icon(:product="product")
  v-toolbar(dense, flat)
    v-icon.mr-2(small, v-if="product.status == false", color="error") mdi-cancel
    span {{ product.name }}
    v-spacer
    v-btn(small, icon, @click="edit()")
      v-icon(small, color="secondary") mdi-pencil
  v-divider
  v-card-text
    span {{ product.price | currency }} {{ product.taxType | taxType }}
    .overline SKU: {{ product.sku }}, MFR: {{ product.mfr }}
    .overline
      span.mr-2 Tax
      v-icon(small, v-if="product.taxable", color="success") mdi-check
      v-icon(small, v-else, color="error") mdi-cancel
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Icon from "./Icon";

export default {
  components: { Icon },
  filters: {
    taxType(value) {
      switch (value) {
        case "tg":
          return "Tangible Goods";
        case "digital":
          return "Digital";
        case "business_service":
          return "Business Service";
        default:
          return "N/A";
      }
    },
  },
  props: ["product"],
  methods: {
    edit() {
      if (!this.product) return;
      EventBus.$emit("edit-product", this.product);
    },
  },
};
</script>

const state = {
  taxfiles: []
}

const getters = {
  taxfiles: state => state.taxfiles
}

const actions = {
  setTaxFiles: ({ commit }, data) => { commit('setTaxFiles', data) },
  addTaxFile: ({ commit }, data) => { commit("addTaxFile", data); },
  updateTaxFile: ({ commit }, data) => { commit('updateTaxFile', data) },
  removeTaxFile: ({ commit }, data) => { commit('removeTaxFile', data) }
}

const mutations = {
  setTaxFiles(state, data) { state.taxfiles = data },
  addTaxFile(state, data) { state.taxfiles.push(data); },
  updateTaxFile(state, data) {
    state.taxfiles = state.taxfiles.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeTaxFile(state, data) {
    state.taxfiles = state.taxfiles.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
<template lang="pug">
Page(title="Corp-level Access")
  template(v-slot:header)
    IconBtn(@click="create()", icon="mdi-plus", title="Add")
  Table.mb-5
  AccessForm
</template>

<script>
import Table from "./Table";
import AccessForm from "./AccessForm";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table, AccessForm },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setCorpAccesses"]),
    create() {
      EventBus.$emit("edit-corp-access");
    },
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.corp.access.list(params);
      this.setCorpAccesses(result);
    },
  },
};
</script>

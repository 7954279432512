<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Name
        th.text-right Total
    tbody
      tr(v-for="(item, index) in items", :key="index")
        td {{ item.name }}
        td.text-right {{ item.total | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
export default {
  computed: {
    ...mapGetters(["partnerPayouts"]),
    items() {
      return _.chain(this.partnerPayouts)
        .groupBy("partner")
        .map((o, driverId) => {
          const count = o.reduce((a, b) => a + b.count, 0);
          const total = o.reduce((a, b) => a + b.amount, 0);
          return {
            driverId,
            name: _.last(o).name,
            count,
            total,
          };
        })
        .sortBy("total")
        .value();
    },
  },
};
</script>
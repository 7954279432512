<template lang="pug">
div
  div(v-for="comment in issue.comments", :key="comment._id")
    v-divider
    .my-1
      .caption {{ comment.user_name }} {{ comment.created | datetime }}
      div {{ comment.content }}
  v-divider
  v-form(v-if="commentable", @submit.prevent="submit")
    v-textarea(v-model="content", label="Comment", rows="2")
    v-select(
      v-model="status",
      :items="status_items",
      label="Status",
      v-if="isInternal"
    )
    v-btn(
      @click="submit",
      :disabled="!content",
      color="secondary",
      :loading="loading"
    ) Submit
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    issue: { type: Object, required: true },
  },
  data() {
    return {
      content: "",
      status: "",
      loading: false,
      app_name: process.env.VUE_APP_NAME,
      status_items: ["open", "no_more_comments", "closed"],
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    commentable() {
      return this.issue?.status == "open";
    },
    user_name() {
      if (this.isInternal) {
        return `${this.$auth.user()?.name.first}`;
      } else if (this.salesPerson) {
        return `${this.salesPerson.first_name} ${this.salesPerson.last_name}`;
      }
      return "";
    },
  },
  mounted() {
    this.status = this.issue?.status;
  },
  methods: {
    clear() {
      this.content = "";
    },
    async submit() {
      const user = this.$auth.user();
      if (!user) return;
      this.loading = true;
      const action = {
        $push: {
          comments: {
            content: this.content,
            user: this.$auth.user()?._id,
            user_name: this.user_name,
            created: Date.now(),
          },
        },
      };
      if (this.isInternal && this.status) {
        action.$set = { status: this.status };
      }
      try {
        const result = await this.$api.dev.issue.put(this.issue._id, action);
        this.$store.dispatch("dev/updateIssue", result);
        this.$emit("update", result);
        this.content = "";
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    update(data) {
      this.$emit("update", data);
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Country
        th Zone
        th Timezone
        th Basic Rate
        th Delivery Rate
        th Delivery Range
        th Delivery
        th Driver
        th Manager
        th.text-right Hours
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        @click="edit(item)",
        role="button"
      )
        td {{ item.country }}
        td {{ item.zoneName }}
        td {{ item.timezone }}
        td {{ item.basicRate }}%
        td {{ item.delivery.rate }}% &gt;{{ item.delivery.minService | currency }}
        td ~{{ item.delivery.inclusiveRange }} &lt;{{ item.delivery.maxRange }}
        td {{ item.delivery.fee | currency }} + {{ item.delivery.unitFee | currency }}/unit
        td {{ item.driverBasePay | currency }}
        td {{ item.managerBasePay | currency }}
        td.text-right
          v-btn(small, icon, color="secondary", @click.stop="editHour(item)")
            v-icon(small) mdi-pencil
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["geoServiceZones"]),
    items() {
      return _.chain(this.geoServiceZones).sortBy("zoneName").value();
    },
  },
  methods: {
    edit(data) {
      EventBus.$emit("edit-geo-service-zone", data);
    },
    editHour(item) {
      EventBus.$emit("edit-geo-service-zone-hours", item);
    },
  },
};
</script>
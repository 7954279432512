<template lang="pug">
v-text-field(
  type="number",
  :label="label",
  v-model.number="content",
  v-on="$listeners",
  prefix="$",
  :min="min",
  :step="step",
  :hint="hint",
  :persistent-hint="persistentHint",
  :hide-details="hideDetails",
  :single-line="singleLine",
  :dense="dense"
)
</template>

<script>
export default {
  name: "PriceField",
  props: {
    value: { type: Number, default: 0 },
    label: { type: String, default: "Price" },
    hint: { type: String, default: "" },
    persistentHint: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    step: { type: Number, default: 0.01 },
  },
  data() {
    return { content: this.value };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(oldVal, newVal) {
      if (oldVal == newVal) return;
      const result = parseFloat(this.content);
      if (isNaN(result)) this.$emit("input", 0);
      this.$emit("input", result);
    },
  },
};
</script>

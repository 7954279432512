<template lang="pug">
v-img(:src="deliv_photo", v-if="deliv_photo", contain, @click="show")
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["delivOrder"],
  computed: {
    deliv_photo() {
      if (this.delivOrder && this.delivOrder.deliv_photo)
        return "https://media.selflane.com/" + this.delivOrder.deliv_photo;
      return null;
    },
  },
  methods: {
    show() {
      EventBus.$emit("show-photo", this.deliv_photo);
    },
  },
};
</script>

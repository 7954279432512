<template lang="pug">
v-card(:loading="loading", flat)
  v-data-table(
    :items="blacklistUsers",
    :headers="headers",
    sort-by="created",
    sort-desc,
    dense,
    mobile-breakpoint=0,
    @click:row="edit",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | datetime("M/D/YY HH:mm") }}
    template(v-slot:item.reason_code="{ item }")
      span {{ item.reason_code | truncate(15) }}
    template(v-slot:item.action="{ item }")
      v-btn(icon, small, color="secondary", @click.stop="showUser(item.user)")
        v-icon(small) mdi-account
      v-btn(
        icon,
        small,
        color="secondary",
        @click.stop="showOrders(item.user)"
      )
        v-icon(small) mdi-cart
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["loading"],
  data() {
    return {
      headers: [
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Reason", value: "reason_code" },
        { text: "Stage", value: "stage" },
        { text: "Time", value: "created" },
        { text: "Action", value: "action", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["blacklistUsers"]),
  },
  methods: {
    ...mapActions(["removeBlacklistUser"]),
    showOrders(userId) {
      if (!userId) return;
      EventBus.$emit("list-order-by-userid", userId);
    },
    showUser(userId) {
      if (!userId) return;
      EventBus.$emit("show-user-detail", userId);
    },
    edit(item) {
      this.$emit("edit", item);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", color="error", text, x-small) Delete Location
  v-card
    v-card-title Delete Location
    v-card-text
      v-alert(type="warning") Are you sure you want to delete this location?
      v-btn(color="error", text, @click="deleteOne", :loading="loading") Yes, delete it
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: { biz: { type: Object, required: true } },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise"]),
  },
  methods: {
    async deleteOne() {
      try {
        this.loading = true;
        const id = this.enterprise?._id;
        const action = { $pull: { bizs: this.biz._id } };
        const result = await this.$api.entp.enterprise.put(id, action);
        this.$store.dispatch("entp/updateEnterprise", result);
        this.$toast.success("Location deleted successfully");
        this.$emit("deleted");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to delete location");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  v-if="visible",
  :items="result",
  :headers="headers",
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.subtotal="{ item }")
    span {{ item.subtotal | currency }}
  template(v-slot:item.total="{ item }")
    span {{ item.total | currency }}
  template(v-slot:item.commission="{ item }")
    span {{ item.commission | currency }}
  template(v-slot:item.fee="{ item }")
    span {{ item.fee | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["type", "showDownload"],
  data() {
    return {
      result: [],
      headers: [
        { text: "Currency", value: "currency" },
        { text: "Year", value: "year" },
        { text: "Week", value: "week" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Total", value: "total" },
        { text: "Commission", value: "commission" },
        { text: "Selflane", value: "fee" },
        { text: "Count", value: "count" },
      ],
    };
  },
  computed: {
    visible() {
      return this.type == "weekly";
    },
  },
  methods: {
    async load(year) {
      const result = await this.$api.influencer.account.custom(
        "stats/growth_weekly",
        { year }
      );
      this.result = _.chain(result)
        .sortBy("currency")
        .sortBy("week")
        .sortBy("year")
        .value();
    },
  },
};
</script>

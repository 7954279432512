<template lang="pug">
.s-vstack
  MarkerMap(:geometries="geometries")
  v-text-field.mx-3(
    v-model="search",
    label="Search",
    placeholder="Search Name",
    clearable
  )
  Table(
    v-for="(group, index) in groups",
    :key="index",
    :items="group.items",
    :search="search",
    :title="group.title"
  )
</template>

<script>
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: "",
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    geometries() {
      return this.items
        .map((o) => {
          const geo = o.address?.geometry;
          if (geo) return { ...geo, title: o.name };
          return null;
        })
        .filter((o) => o);
    },
    // group items by org_access
    groups() {
      const result = _.chain(this.items)
        .groupBy((o) => o.org_access)
        .map((items, key) => {
          let title = "Unknown";
          if (key == 1) title = "Admin";
          if (key == 2) title = "Sales";
          return { key, title, items };
        })
        .value();
      return result;
    },
  },
};
</script>

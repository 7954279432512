<template lang="pug">
List
</template>

<script>
import { mapActions } from "vuex";
import List from "./List";

export default {
  components: { List },
  methods: {
    ...mapActions(["setBizServices"]),
    mounted() {
      this.load();
    },
    async load() {
      let params = { criteria: { "third_party.status": true } };
      const result = await this.$api.service.account.list(params);
      this.setBizServices(result);
    },
  },
};
</script>

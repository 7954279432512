<template lang="pug">
div
  v-btn(color="error", @click="open", outlined, small, :loading="loading")
    v-icon(icon, left, small) mdi-delete
    span.text-notransform Delete
  v-dialog(v-model="dialog", max-width="350")
    v-card
      v-card-title Delete Order
      v-card-text
        div Are you sure you want to delete this order?
        v-text-field.mt-3(
          label="Reason",
          v-model="reason",
          :rules="[(v) => !!v || 'Reason is required']",
          outlined
        )
      v-card-actions
        v-btn(text, @click="dialog = false") Cancel
        v-btn(color="error", text, @click="submit", :disabled="!reason") Delete
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["bizorder"],
  data() {
    return {
      dialog: false,
      reason: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["deleteBizOrder"]),
    open() {
      this.dialog = true;
      this.reason = "";
    },
    async submit() {
      this.dialog = false;
      this.loading = true;
      const id = this.bizorder._id;
      try {
        const params = { id: id, reason: this.reason };
        const result = await this.$api.bizOrder.delete(params);
        this.deleteBizOrder(result);
        this.$toast.success("Order deleted");
        this.$emit("deleted");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

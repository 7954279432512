<template lang="pug">
v-row.my-5(justify="center")
  v-card(width="300")
    v-card-text
      DaySelector.mb-5(@select="selectDay")
      BizSelector(@select="selectBiz")
      v-btn.text-capitalize(
        @click="load()",
        block,
        color="secondary",
        :disabled="!date || !biz"
      ) Load
        v-icon(right, small) mdi-send
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data() {
    return {
      biz: null,
      date: moment().format("YYYY-MM-DD"),
    };
  },
  mounted() {
    this.setReservations([]);
  },
  methods: {
    ...mapActions(["setReservations"]),
    selectBiz(biz) {
      this.biz = biz;
    },
    selectDay(value) {
      this.date = value;
    },
    async load() {
      if (!this.date || !this.biz) return;
      const start = moment(this.date).startOf("days").valueOf();
      const end = moment(this.date).endOf("days").valueOf();
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          needed: { $gte: start, $lt: end },
        },
        select: "seller.name needed orderer status order tableId",
      };
      try {
        const result = await this.$api.reservation.list(params);
        this.setReservations(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
div(v-if="order")
  .link-box
    .caption ID:
    ClickToCopy(:text="order._id")
  .link-box.my-3
    PartialRefund(:order="order")
    AdjustPrice(:order="order")
    TaxExempt(:order="order")
  .link-box.my-3
    CloseOrder(:order="order")
    CancelOrder(:order="order")
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on.stop="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        EditTime(:order="order")
        EditTip(:order="order")
</template>

<script>
import { mapGetters } from "vuex";
import AdjustPrice from "./AdjustPrice";
import CancelOrder from "./CancelOrder";
import CloseOrder from "./CloseOrder";
import EditTime from "./EditTime";
import EditTip from "./EditTip";
import PartialRefund from "./PartialRefund";
import TaxExempt from "./TaxExempt";

export default {
  components: {
    CloseOrder,
    PartialRefund,
    AdjustPrice,
    TaxExempt,
    CancelOrder,
    EditTime,
    EditTip,
  },
  computed: {
    ...mapGetters(["order"]),
  },
};
</script>


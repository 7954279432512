<template lang="pug">
div
  .mb-3
    IconBtn(@click="create", icon="mdi-plus", title="SubGroup")
  List(@edit="edit")
  Form(ref="form")
</template>

<script>
import List from "./List";
import Form from "./Form/index";

export default {
  components: { List, Form },
  methods: {
    create() {
      this.$refs.form.open();
    },
    edit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>
<template lang="pug">
div
  .d-flex.flex-row.justify-end.mb-3
    Create
  List
</template>

<script>
import Create from "./Create";
import List from "./List";
import { mapActions } from "vuex";

export default {
  components: { Create, List },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBouncingEmails"]),
    async load() {
      try {
        const result = await this.$api.bouncingEmail.list({ criteria: {} });
        this.setBouncingEmails(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

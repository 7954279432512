<template lang="pug">
StandardChart(:input="chartData", chartType="bar", maxWidth="100%", legend)
  v-data-table(
    :items="items",
    :headers="headers",
    sort-by="created",
    sort-desc,
    @click:row="show",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.address="{ item }")
      span(v-if="item.address") {{ item.address.city }}, {{ item.address.state }}
    template(v-slot:item.payment.total="{ item }")
      span {{ item.payment.total | currency }}
    template(v-slot:item.status="{ item }")
      span(v-if="item.payment.charge?.amount") {{ item.payment.charge.amount | currency }} {{ item.payment.charge.method }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["items", "title"],
  data() {
    return {
      year: null,
      headers: [
        { text: "Date", value: "created" },
        { text: "Client", value: "name", sortable: false },
        { text: "Address", value: "address", sortable: false },
        { text: "Total", value: "payment.total" },
        { text: "Paid", value: "status", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    total() {
      const result = this.items.reduce((a, b) => a + b.payment.total, 0);
      return Math.round(result * 100) / 100;
    },
    myTitle() {
      return this.title + ` | Total: $${this.total}`;
    },
    // chart data for sales by month
    chartData() {
      // convert [1, ..., 12] to [Jan, ..., Dec]
      const months = Array.from({ length: 12 }, (_, i) => i + 1).map((o) =>
        new Date(2020, o - 1, 1).toLocaleString("default", { month: "short" })
      );
      let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let paid = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      this.items.forEach((o) => {
        const month = new Date(o.created).getMonth();
        if (month >= 0 && month < 12) {
          total[month] = total[month] + o.payment.total;
          paid[month] = paid[month] + (o.payment.charge?.amount || 0);
        }
      });
      total = total.map((o) => Math.round(o * 100) / 100);

      return {
        title: this.myTitle,
        labels: months,
        datasets: [
          { label: "Total", backgroundColor: "#7f7fff", data: total },
          { label: "Paid", backgroundColor: "#8D6E63", data: paid },
        ],
        isCurrency: true,
      };
    },
  },
  methods: {
    show(bizorder) {
      EventBus.$emit("show-biz-order", bizorder);
    },
  },
};
</script>

<template lang="pug">
div
  .d-flex.flex-row
    YearSelector(@select="load")
    v-spacer
    Create.mr-3(@created="load")
    Announce
  Table.my-5
  Detail
</template>

<script>
import { mapActions } from "vuex";
import Create from "./Create";
import Announce from "./Announce";
import Table from "./Table";
import Detail from "./Detail";

export default {
  components: { Create, Announce, Table, Detail },
  methods: {
    ...mapActions(["setOrgInvoices"]),
    async load(year) {
      const begin = new Date(year, 0, 1).valueOf();
      const end = new Date(year, 11, 31).valueOf();
      const params = { criteria: { created: { $gte: begin, $lt: end } } };
      const result = await this.$api.salesforce.invoice.list(params);
      this.setOrgInvoices(result);
    },
  },
};
</script>
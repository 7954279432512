<template lang="pug">
IconBtn(@click="download()", title="CSV", icon="mdi-download")
</template>

<script>
import CSV from "/libs/utils/CSV";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["bizAccounts"]),
  },
  methods: {
    download() {
      const dateFilter = this.$options.filters.date;
      const csv = new CSV();
      csv.addRow([
        "Name",
        "Address",
        "City",
        "State",
        "Country",
        "POS",
        "Online",
        "url",
      ]);
      this.bizAccounts.forEach((item) => {
        csv.addRow([
          item.name,
          this.addressline(item.address),
          item.address.city,
          item.address.state,
          item.address.country,
          dateFilter(item.pos_start),
          dateFilter(item.online_start),
          "https://order.selflane.com/bizs/" + item.url,
        ]);
      });
      csv.save("biz_list");
    },
    addressline(address) {
      const lines = [];
      if (address.line1) {
        lines.push(address.line1);
      }
      if (address.line2) {
        lines.push(address.line2);
      }
      return lines.join(" ");
    },
  },
};
</script>

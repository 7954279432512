<template lang="pug">
v-card(flat)
  v-col(align="center")
    YearSelector(@select="load")
  simple-table
    thead
      tr
        th {{ header.name }}
        th(v-for="(o, mindex) in header.months", :key="mindex") {{ o }}
        th {{ header.total }}
    tbody
      tr(v-for="(item, rindex) in items", :key="rindex")
        th {{ item.name }}
        td(v-for="(o, mindex) in item.months", :key="mindex") {{ o | currency }}
        td {{ item.total | currency }}
    tfoot
      tr
        th {{ footer.name }}
        td(v-for="(o, mindex) in footer.months", :key="mindex") {{ o | currency }}
        td {{ footer.total | currency }}
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";

export default {
  data() {
    return {
      year: 2019,
      months: [],
      header: {},
      result: [],
    };
  },
  computed: {
    items() {
      return this.result?.toSorted((a, b) => a.name.localeCompare(b.name));
    },
    footer() {
      const total = this.result.reduce((a, b) => a + b.total, 0);
      const months = _.map(_.range(this.months.length), (index) => {
        return this.result.reduce((memo, o) => {
          return memo + o.months[index];
        }, 0);
      });
      return { name: "Total", months, total };
    },
  },
  methods: {
    loadHeader() {
      this.result = [];
      const currentYear = moment().year();
      if (currentYear == this.year) {
        this.months = _.range(1, moment().month() + 2);
      } else {
        this.months = _.range(1, 13);
      }
      this.header = {
        name: "",
        months: this.months.map((o) => `${o}/${this.year}`),
        total: "Annual",
      };
    },
    async loadGrowth(name, url) {
      const params = { year: this.year };
      const { data } = await this.axios.post(url, params);
      const months = this.months.map((m) => {
        return data.find((o) => o.month == m)?.total || 0;
      });
      const total = months.reduce((a, b) => a + b, 0);
      this.result.push({ name, months, total });
    },
    async loadMerchant() {
      const params = { criteria: { year: this.year } };
      const result = await this.$api.merchant.income.list(params);
      const months = this.months.map((m) => {
        return result.find((o) => o.month == m)?.income || 0;
      });
      const total = months.reduce((a, b) => a + b, 0);
      this.result.push({ name: "Merchant", months, total });
    },
    load(year) {
      if (year) this.year = year;
      this.loadHeader();
      this.loadGrowth("POS Fee", "/invoices/monthly-growth");
      this.loadGrowth("Gift Card", "/giftcard/monthly-growth");
      this.loadGrowth("Delivery", "/delivOrders/monthly-growth");
      this.loadGrowth("Waitlist", "/bizaccount/monthly-growth");
      this.loadGrowth("Promotion", "/promoclaim/monthly-growth");
      this.loadMerchant();
    },
  },
};
</script>

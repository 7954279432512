<template lang="pug">
.s-vstack
  .d-flex
    IconBtn(@click="handleAdd", title="Add", icon="mdi-plus")
  List(ref="list", @select="handleEdit")
  Form(ref="form", @refresh="refresh")
</template>

<script>
import List from "/libs/components/Biz/Domain/List/index.vue";
import Form from "/libs/components/Biz/Domain/Form/index.vue";

export default {
  components: { List, Form },
  methods: {
    refresh() {
      this.$refs.list.load();
    },
    handleAdd() {
      this.$refs.form.open();
    },
    handleEdit(domain) {
      this.$refs.form.open(domain);
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }}
  v-data-table(
    :items="items",
    :headers="headers",
    dense,
    mobile-breakpoint=0,
    @click:row="edit",
    role="button",
    :search="search"
  )
    template(v-slot:item.service_item="{ item }")
      span.text-capitalize {{ getServiceName(item.service_item) }}
    template(v-slot:item.status="{ item }")
      span.text-capitalize {{ item.status ? "Active" : "Inactive" }}
    template(v-slot:item.percentage="{ item }")
      span.text-capitalize(v-if="item.percentage") {{ item.percentage }}%
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["title", "items", "search"],
  data() {
    return {
      headers: [
        { text: "Name", value: "biz_name" },
        { text: "Quantity", value: "quantity" },
        { text: "Status", value: "status", align: "end" },
        { text: "Discount", value: "percentage", align: "end" },
      ],
    };
  },
  methods: {
    edit(item) {
      EventBus.$emit("edit-service-subscription", item);
    },
  },
};
</script>
const state = {
  orders: [],
  order: null
}

const getters = {
  orders: state => state.orders,
  order: state => state.order
}

const actions = {
  setOrders: ({ commit }, data) => { commit('setOrders', data) },
  setOrder: ({ commit }, data) => { commit('setOrder', data) },
  updateOrder: ({ commit }, data) => { commit('updateOrder', data) }
}

const mutations = {
  setOrders(state, data) { state.orders = data },
  setOrder(state, data) { state.order = data },
  updateOrder(state, data) {
    state.orders = state.orders.map(o => {
      if (o._id === data._id) return data
      return o
    })
    state.order = data
  }
}

export default { state, getters, actions, mutations }
<template lang="pug">
div
  MonthSelector(@select="load")
  List(:bizs="bizs")
</template>

<script>
import List from "./../List/index";
import { mapActions } from "vuex";
import _ from "underscore";

export default {
  components: { List },
  data() {
    return {
      range: {},
      bizs: [],
    };
  },
  methods: {
    ...mapActions(["setInfluencerPlans", "setInfluencers"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;
      const params = {
        criteria: { created: { $gte: this.range.begin, $lt: this.range.end } },
      };
      const result = await this.$api.influencer.plan.list(params);
      this.setInfluencerPlans(result);
      this.loadBizs(result);
      this.loadInfluencers(result);
    },
    async loadBizs(plans) {
      const ids = plans.map((o) => o.biz);
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      this.bizs = await this.$api.biz.list(params);
    },
    async loadInfluencers(plans) {
      const ids = _.chain(plans).pluck("influencer").uniq().compact().value();
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
  },
};
</script>

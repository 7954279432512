<template lang="pug">
Page(title="Device")
  Counts
  ShowSelectedDevices
</template>

<script>
import Counts from "./Counts";
import ShowSelectedDevices from "./ShowSelectedDevices";

export default {
  components: { Counts, ShowSelectedDevices },
};
</script>
<template lang="pug">
.link-box.mt-2
  v-btn(
    color="secondary",
    outlined,
    small,
    @click="$emit('select', item)",
    v-for="(item, index) in items",
    :key="index"
  )
    span.text-notransform {{ item.name }}
  v-btn(icon, small, color="secondary", @click="load()", :loading="loading")
    v-icon(small) mdi-refresh
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import _ from "underscore";

export default {
  data() {
    return {
      loading: false,
      date: new Date().toISOString().substring(0, 10),
    };
  },
  computed: {
    ...mapGetters(["missedTransactions"]),
    items() {
      return _.chain(this.missedTransactions)
        .filter((o) => o.status == 0)
        .groupBy("biz")
        .map((list) => {
          return {
            _id: list[0].biz,
            name: list[0].bizName,
            count: list.length,
          };
        })
        .value();
    },
  },
  methods: {
    ...mapActions(["setMissedTransactions"]),
    async load(date) {
      if (date) this.date = date;
      const timezone = "America/Denver";
      const day = moment.tz(this.date, timezone);
      const begin = day.startOf("day").toDate();
      const end = day.endOf("day").toDate();
      const params = { criteria: { createdAt: { $gte: begin, $lt: end } } };
      this.loading = true;
      try {
        const result = await this.$api.transaction.missed.list(params);
        this.setMissedTransactions(result);
      } catch (err) {
        this.setMissedTransactions([]);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-card.mx-auto(max-width="500", outlined)
  v-card-title
    .subtitle-2 Add Interaction
  v-form(@submit.prevent="submit")
    v-card-text
      v-row(wrap)
        v-col(cols="4")
          v-select(
            v-model="type",
            :items="type_items",
            label="Type",
            hide-details
          )
        v-col(cols="4")
          v-select(
            v-model="status",
            :items="status_items",
            label="Status",
            hide-details
          )
      v-text-field(v-model="subject", label="Subject", hide-details)
      v-textarea(v-model="content", label="Content", rows="3", hide-details)
    v-card-actions
      v-btn(color="secondary", :loading="loading", type="submit") Save
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    lead: { type: Object, default: null },
    opportunity: { type: Object, default: null },
  },
  data() {
    return {
      type: "phone",
      subject: "",
      content: "",
      status: null,
      loading: false,
      type_items: [
        { text: "Call", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Meeting", value: "meeting" },
        { text: "Other", value: "other" },
      ],
    };
  },
  computed: {
    ...mapGetters(["salesPerson", "salesOrganization"]),
    status_items() {
      let items = [];
      if (this.lead?.status) {
        items = [
          "new",
          "contacted",
          "qualified",
          "unqualified",
          "converted",
          "dead",
        ];
      } else {
        items = ["open", "won", "lost"];
      }
      return items.map((status) => {
        return {
          text: status.charAt(0).toUpperCase() + status.slice(1),
          value: status,
        };
      });
    },
  },
  mounted() {
    this.reset();
  },
  methods: {
    async submit() {
      const organization = this.salesOrganization._id;
      if (!organization) {
        this.$toast.error("Organization is required");
        return;
      }
      if (!this.lead && !this.opportunity) {
        this.$toast.error("Lead or opportunity is required");
        return;
      }
      const sales_person = this.salesPerson._id;
      const sales_person_name = this.salesPerson.first_name;
      if (!sales_person) {
        this.$toast.error("Sales person is required");
        return;
      }
      this.subject = this.subject.trim();
      this.content = this.content.trim();
      if (!this.subject || !this.content) {
        this.$toast.error("Subject and content are required");
        return;
      }
      this.loading = true;
      const interaction = {
        type: this.type,
        subject: this.subject,
        content: this.content,
        lead: this.lead?._id,
        opportunity: this.opportunity?._id,
        sales_person,
        sales_person_name,
        organization,
      };
      try {
        const result = await this.$api.crm.interaction.create(interaction);
        this.$store.dispatch("crm/addInteraction", result);
        this.$toast.success("Interaction created");
        await this.updateStatus();
        if (result.lead) {
          const lead = await this.$api.crm.lead.get(result.lead);
          this.$store.dispatch("crm/updateLead", lead);
        } else if (result.opportunity) {
          const opportunity = await this.$api.crm.opportunity.get(
            result.opportunity
          );
          this.$store.dispatch("crm/updateOpportunity", opportunity);
        }
        this.reset();
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create interaction");
      }
      this.loading = false;
    },
    reset() {
      this.type = "phone";
      this.subject = "";
      this.content = "";
      if (this.lead) {
        this.status = this.lead.status;
      } else if (this.opportunity) {
        this.status = this.opportunity.status;
      }
    },
    async updateStatus() {
      this.loading = true;
      try {
        if (this.lead?._id && this.status !== this.lead?.status) {
          const result = await this.$api.crm.lead.put(this.lead._id, {
            status: this.status,
          });
          this.$store.dispatch("crm/updateLead", result);
          this.$emit("update", result);
        } else if (
          this.opportunity?._id &&
          this.status !== this.opportunity?.status
        ) {
          const result = await this.$api.crm.opportunity.put(
            this.opportunity._id,
            { status: this.status }
          );
          this.$store.dispatch("crm/updateOpportunity", result);
          this.$emit("update", result);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update status");
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="load()", :loading="loading") Settle Stat
  simple-table
    thead
      tr
        th Host Batch
        th Batch ID
        th Charge Total
        th Refund Total
    tbody
      tr(v-for="(item, index) in items", :key="index")
        td {{ item.hostbatch }}
        td {{ item.batchid }}
        td {{ item.chargetotal }}
        td {{ item.refundtotal }}
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    mid: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  methods: {
    async load() {
      if (!this.mid) return;
      this.loading = true;
      // format MMDD
      const date = moment().format("MMDD");
      const params = { merchid: this.mid, date };
      try {
        this.items = await this.$api.cardpointe.cardconnect.settlestat(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-btn(
  @click="pay",
  :loading="loading",
  color="secondary",
  block,
  v-if="!isPaid"
) Pay
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isPaid() {
      const amount = this.item.payment?.charge?.amount;
      return amount && amount > 0;
    },
  },
  methods: {
    ...mapActions(["updateOrgInvoice"]),
    async pay() {
      if (!this.item) return;
      this.loading = true;
      const params = { invoiceId: this.item._id };
      try {
        const result = await this.$api.salesforce.invoice.custom("pay", params);
        this.updateOrgInvoice(result);
        this.$toast.success("Invoice paid");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
.tags-container(v-if="visible")
  v-chip(
    v-for="tag in tags",
    :key="tag",
    x-small,
    color="secondary",
    label,
    outlined,
    v-if="tags.length > 0"
  )
    v-icon(x-small, left) mdi-tag
    span {{ tag }}
</template>

<script>
export default {
  props: {
    tags: { type: Array, default: () => [] },
  },
  computed: {
    visible() {
      return this.tags?.length;
    },
  },
};
</script>

<style lang="sass" scoped>
// wrapped row with gap of 2px
.tags-container
  display: flex
  flex-wrap: wrap
  gap: 2px
</style>
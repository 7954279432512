<template lang="pug">
div
  div(v-for="comment in item.comments", :key="comment._id")
    v-divider
    .my-1
      .caption {{ comment.sales_person_name }} {{ comment.created | datetime }}
      div {{ comment.content }}
  v-divider
  v-form(@submit.prevent="submit", v-if="!isInternal")
    v-textarea(v-model="content", label="Add Comment", rows="2")
    v-btn(
      @click="submit",
      :disabled="!content",
      color="secondary",
      :loading="loading"
    ) Submit
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      content: "",
      loading: false,
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapGetters(["salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
  },
  mounted() {
    this.status = this.item?.status;
  },
  methods: {
    async submit() {
      this.content = this.content.trim();
      if (!this.content) return;
      const sales_person = this.salesPerson?._id;
      const sales_person_name = `${this.salesPerson?.first_name} ${this.salesPerson?.last_name}`;
      this.loading = true;
      const action = {
        $push: {
          comments: {
            content: this.content,
            sales_person,
            sales_person_name,
            created: Date.now(),
          },
        },
      };
      try {
        const result = await this.$api.crm.serviceLog.put(
          this.item._id,
          action
        );
        this.$store.dispatch("crm/updateServiceLog", result);
        this.$emit("update", result);
        this.content = "";
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
simple-table(v-if="charges?.length")
  tbody
    tr(
      v-for="(charge, index) in charges",
      :key="index",
      @click="show(charge)",
      role="button"
    )
      td {{ charge.stripe_type }}
      td {{ charge.created | time }}
      td.text-right {{ charge.amount | currency }}
      td.text-right
        v-btn(
          v-if="!isCurrent(charge)",
          icon,
          small,
          @click.stop="confirmDelete(charge)",
          color="error",
          :loading="loading"
        )
          v-icon(small) mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: {
    invoice: { type: Object, default: () => ({}) },
    charges: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    show(item) {
      EventBus.$emit("show-stripe-charge", item);
    },
    isCurrent(item) {
      return this.invoice.payment?.charge?.id === item.stripe_id;
    },
    confirmDelete(item) {
      confirm("Are you sure you want to delete this charge?") &&
        this.handleDelete(item);
    },
    async handleDelete(item) {
      const params = {
        invoiceId: this.invoice._id,
        chargeId: item.stripe_id,
        method: item.stripe_type,
      };
      this.loading = true;
      try {
        await this.$api.invoice.custom("voidCharge", params);
        this.$toast.success("Charge deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }

      this.loading = false;
    },
  },
};
</script>
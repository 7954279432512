<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit a Partner
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(v-model="name", label="Name")
        PhoneField(v-model="phone")
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditPartner",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      name: "",
      phone: "",
      partner: null,
    };
  },
  computed: {
    valid() {
      return !!this.name && !!this.phone;
    },
  },
  methods: {
    ...mapActions(["updatePartner"]),
    open(data) {
      this.dialog = true;
      this.name = data.name;
      this.phone = data.phone;
      this.partner = data;
    },
    async submit() {
      if (!this.partner) return;
      const params = {
        criteria: { _id: this.partner._id },
        action: { $set: { name: this.name.trim(), phone: this.phone } },
      };
      this.loading = true;
      const result = await this.$api.partner.account.update(params);
      this.loading = false;
      this.dialog = false;
      this.updatePartner(result);
    },
  },
};
</script>

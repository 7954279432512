<template lang="pug">
Page(title="Delivery")
  template(v-slot:header)
    LoadByDate
  HeatMap
  .text-center.mb-2
    v-btn-toggle(v-model="type", dense, rounded, color="secondary")
      v-btn.text-capitalize(small, value="0") Selflane {{ selflaneCount }}
      v-btn.text-capitalize(small, value="1") Others {{ delivOrders.length - selflaneCount }}
  .text-center.mb-2
    v-btn-toggle(v-model="open", dense, rounded, color="secondary")
      v-btn.text-capitalize(
        small,
        v-for="(item, index) in openItems",
        :key="index",
        :value="item.value"
      ) {{ item.title }}
  List(:orders="selectedOrders")
  v-row(wrap)
    v-col(cols="12", sm="6")
      OntimeScore
    v-col(cols="12", sm="6")
      Zone
  Actions
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import LoadByDate from "./LoadByDate/index";
import HeatMap from "./HeatMap/index";
import List from "./List/index";
import OntimeScore from "./OntimeScore";
import Zone from "./Zone/index";
import Actions from "./Actions/index";

export default {
  components: { LoadByDate, HeatMap, List, OntimeScore, Zone, Actions },
  data: () => ({
    type: "0",
    open: true,
    openItems: [
      { title: "Open", value: true },
      { title: "Delivered", value: false },
    ],
  }),
  computed: {
    ...mapGetters(["delivOrders"]),
    selflaneCount() {
      return _.filter(this.delivOrders, (o) => o.type == 0).length;
    },
    selectedOrders() {
      if (this.open) {
        return _.chain(this.delivOrders)
          .reject((o) => o.status == 4)
          .filter((o) => o.type == this.type)
          .sortBy("needed")
          .value();
      } else {
        return _.chain(this.delivOrders)
          .filter((o) => o.status == 4)
          .filter((o) => o.type == this.type)
          .sortBy("needed")
          .value()
          .reverse();
      }
    },
  },
};
</script>

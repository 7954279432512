<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Pay Partner
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col(cols="6")
            PriceField(v-model.number="amount", label="Amount")
          v-col(cols="6")
            v-select(
              v-model="currency",
              label="Currency",
              :items="currency_items"
            )
        v-text-field(v-model="note", label="Note")
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Save
</template>

<script>
export default {
  name: "PayPartner",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      amount: 0,
      currency: "usd",
      partner: null,
      note: "",
      currency_items: ["usd", "cad"],
    };
  },
  computed: {
    valid() {
      return !!this.amount;
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.amount = 0;
      this.partner = data;
      this.note = "";
    },
    async submit() {
      if (!this.partner) return;
      const params = {
        partnerId: this.partner._id,
        amount: this.amount,
        currency: this.currency,
        note: this.note,
      };
      this.loading = true;
      try {
        await this.$api.partner.payout.create(params);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

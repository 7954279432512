<template lang="pug">
v-btn(color="secondary", @click="submit", outlined, small) Pay By Check
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["bizorder"],
  methods: {
    async submit() {
      EventBus.$emit("pay-biz-order-check", this.bizorder);
    },
  },
};
</script>

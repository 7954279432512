const state = {
  announcements: [],
  success_stories: [],
  podcasts: [],
};

const getters = {
  announcements: state => state.announcements,
  success_stories: state => state.success_stories,
  podcasts: state => state.podcasts,
};

const actions = {
  setAnnouncements: ({ commit }, data) => { commit("setAnnouncements", data); },
  addAnnouncement: ({ commit }, data) => { commit("addAnnouncement", data); },
  updateAnnouncement: ({ commit }, data) => { commit("updateAnnouncement", data); },
  removeAnnouncement: ({ commit }, data) => { commit("removeAnnouncement", data); },
  setSuccessStories: ({ commit }, data) => { commit("setSuccessStories", data); },
  addSuccessStory: ({ commit }, data) => { commit("addSuccessStory", data); },
  updateSuccessStory: ({ commit }, data) => { commit("updateSuccessStory", data); },
  removeSuccessStory: ({ commit }, data) => { commit("removeSuccessStory", data); },
  setPodcasts: ({ commit }, data) => { commit("setPodcasts", data); },
  addPodcast: ({ commit }, data) => { commit("addPodcast", data); },
  updatePodcast: ({ commit }, data) => { commit("updatePodcast", data); },
  removePodcast: ({ commit }, data) => { commit("removePodcast", data); },
};

const mutations = {
  setAnnouncements(state, data) { state.announcements = data; },
  addAnnouncement(state, data) { state.announcements.push(data); },
  updateAnnouncement(state, data) {
    state.announcements = state.announcements.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  removeAnnouncement(state, data) {
    state.announcements = state.announcements.filter(o => o._id !== data._id);
  },
  setSuccessStories(state, data) { state.success_stories = data; },
  addSuccessStory(state, data) { state.success_stories.push(data); },
  updateSuccessStory(state, data) {
    state.success_stories = state.success_stories.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  removeSuccessStory(state, data) {
    state.success_stories = state.success_stories.filter(o => o._id !== data._id);
  },
  setPodcasts(state, data) { state.podcasts = data; },
  addPodcast(state, data) { state.podcasts.push(data); },
  updatePodcast(state, data) {
    state.podcasts = state.podcasts.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  removePodcast(state, data) {
    state.podcasts = state.podcasts.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };
<template lang="pug">
v-dialog(
  v-model="dialog",
  fullscreen,
  hide-overlay,
  scrollable,
  transition="dialog-bottom-transition"
)
  v-card(:loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Lead Worksheet | {{ lead?.sales_person_name }}
      v-spacer
      IconBtn.mr-3(@click="edit(lead)")
      v-btn(color="secondary", @click="dialog = false", small) Close
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn.ml-3(icon, v-on="on", small, color="secondary")
            v-icon mdi-dots-vertical
        v-list
          v-list-item(@click="convert") Convert to Opportunity
    StaticInfo(:lead="lead")
    Interaction(:lead="lead", ref="interaction", @update="update")
  Form(ref="form", @updateLead="update")
  OpportunityForm(ref="opportunity")
</template>

<script>
import Form from "./../Form";
import StaticInfo from "./StaticInfo";
import Interaction from "/libs/components/CRM/Interaction/List";
import OpportunityForm from "/libs/components/CRM/Opportunity/Form";

export default {
  components: { StaticInfo, Interaction, Form, OpportunityForm },
  data() {
    return {
      dialog: false,
      lead: null,
      loading: false,
    };
  },
  computed: {
    isOpen() {
      // ['new', 'contacted', 'qualified']
      return ["new", "contacted", "qualified"].includes(this.lead?.status);
    },
  },
  methods: {
    open(lead) {
      this.lead = lead;
      this.dialog = true;
      this.loadInteractions();
    },
    edit() {
      this.$refs.form.open(this.lead);
    },
    async loadInteractions() {
      if (!this.lead._id) return;
      try {
        const criteria = { lead: this.lead._id };
        const result = await this.$api.crm.interaction.list({ criteria });
        this.$store.dispatch("crm/setInteractions", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load interactions");
      }
    },
    convert() {
      const opportunity = {
        company_name: this.lead.company_name,
        phone: this.lead.phone,
        email: this.lead.email,
        size: this.lead.size,
        tags: this.lead.tags,
        address: this.lead.address,
        notes: this.lead.notes,
        lead: this.lead._id,
        contact: this.lead.contact,
        biz: this.lead.biz,
        biz_name: this.lead.biz_name,
        organization: this.lead.organization,
        status: "open",
      };
      this.$refs.opportunity.open(opportunity);
    },
    update(lead) {
      this.lead = lead;
    },
  },
};
</script>
<template lang="pug">
StandardChart(
  :input="chartData",
  maxWidth="100%",
  chartType="bar",
  :legend="true"
)
  template(v-slot:header)
    v-btn-toggle(v-model="type", dense, small, color="secondary")
      v-btn(
        :value="item",
        v-for="(item, index) in items",
        :key="index + 'type'",
        small
      ) {{ item }}

  simple-table
    thead
      tr
        th
        th(v-for="(month, mindex) in months", :key="mindex") {{ month | month(true) }}
        th Total
    tbody
      tr(v-for="(row, rindex) in group.rows", :key="rindex")
        th {{ row.name | truncate(16) }}
        td(v-for="(item, index) in row.items", :key="index")
          span(v-if="type == 'total'") {{ item.total | currency }}
          span(v-else) {{ item.count }}
        th {{ row.total | currency }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["group", "year"],
  data() {
    return {
      type: "total",
      items: ["total", "quantity"],
      months: _.range(1, 13),
      colors: ["#7682d6", "#2cb2b9", "#50a948", "#ad4d24", "#a74ac3"],
    };
  },
  computed: {
    chartData() {
      const month = this.$options.filters.month;
      const datasets = [];
      for (let i = 0; i < this.group.rows.length; i++) {
        const row = this.group.rows[i];
        const data = [];
        if (i < 5) {
          for (let j = 0; j < row.items.length; j++) {
            const item = row.items[j];
            data.push(item[this.type]);
          }
          datasets.push({
            label: row.name.slice(0, 13),
            backgroundColor: this.colors[i % 5],
            data: data,
          });
        } else {
          datasets[4].label = "Others";
          datasets[4].data = datasets[4].data.map((o, index) => {
            return o + row.items[index][this.type];
          });
        }
      }
      return {
        title: `Growth by Organization (${this.group.currency})`,
        labels: this.months.map((o) => month(o, true)),
        datasets,
        isCurrency: this.type == "amount",
      };
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="last_charge",
  sort-desc,
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.biz.address="{ item }")
    span {{ item.biz?.address | address }}
  template(v-slot:item.balance="{ item }")
    span {{ item.balance | currency }}
  template(v-slot:item.amount="{ item }")
    span {{ item.amount | currency }}
  template(v-slot:item.action="{ item }")
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on.stop="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click="showCredits(item)") Credits
        v-list-item(@click="showMonthly(item)") Waitlist Monthly Usage
        v-list-item(@click="handleCreateCredit(item)") Give Credit
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["accounts", "bizs"],
  data() {
    return {
      headers: [
        { text: "Name", value: "biz.name" },
        { text: "Address", value: "biz.address" },
        { text: "Amount", value: "amount" },
        { text: "Balance", value: "balance" },
        { text: "Action", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    items() {
      return this.accounts.map((o) => {
        o.biz = this.bizs.find((biz) => biz._id == o._id);
        return o;
      });
    },
  },
  methods: {
    showCredits(item) {
      EventBus.$emit("show-biz-account-credit-list", item._id);
    },
    showMonthly(item) {
      EventBus.$emit("show-waitlist-history", item._id);
    },
    handleCreateCredit(item) {
      confirm("Are you sure to give free credit to this business?") &&
        EventBus.$emit("create-biz-account-credit", item._id);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="group")
    v-toolbar(dense, flat)
      .overline {{ title }}
      v-spacer
      Delete(:group="group", @removed="dialog = false")
    v-divider
    v-card-text
      v-form.mt-5(@submit.prevent="save()")
        v-text-field(v-model="group.name", label="name")
        simple-table
          tbody
            tr(
              v-for="(item, index) in subgroups",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-icon(small) mdi-menu
              td {{ item.name }}
              td
                v-btn(icon, @click="removeSubGroup(index)")
                  v-icon(small, color="error") mdi-delete
        v-btn.my-3(
          block,
          color="secondary",
          type="submit",
          :disabled="!group || !group.name"
        ) Save
      Search(@add="addSubGroup")
</template>

<script>
import Search from "./Search";
import Delete from "./Delete";

export default {
  components: { Search, Delete },
  data() {
    return {
      loading: false,
      dialog: false,
      group: null,
      subgroups: [],
      draggingIndex: null,
    };
  },
  computed: {
    title() {
      if (this.group && this.group._id) return "Edit Group";
      else return "Create Group";
    },
  },
  methods: {
    open(data) {
      if (!data) {
        this.group = { name: "", subgroups: [] };
      } else {
        this.group = JSON.parse(JSON.stringify(data));
      }
      this.dialog = true;
      this.subgroups = [];
      if (this.group.subgroups.length) this.initSubGroups();
    },
    async initSubGroups() {
      const ids = this.group.subgroups;
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      const result = await this.$api.catalog.subgroup.list(params);
      this.subgroups = ids.map((id) => result.some((o) => o._id == id));
    },
    addSubGroup(item) {
      const found = this.subgroups.find((o) => o._id == item._id);
      if (!found) this.subgroups.push(item);
    },
    removeSubGroup(index) {
      this.subgroups.splice(index, 1);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.subgroups.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.subgroups.length)
        return;
      let subgroups = JSON.parse(JSON.stringify(this.subgroups));
      let tmp = JSON.parse(JSON.stringify(subgroups[this.draggingIndex]));
      subgroups.splice(this.draggingIndex, 1);
      subgroups.splice(index, 0, tmp);
      this.subgroups = subgroups;
    },
    async save() {
      if (!this.group || !this.group.name) return;
      if (!this.group._id) {
        this.create();
        return;
      }
      this.loading = true;
      const subgroups = this.subgroups.map((o) => o._id);
      try {
        const action = { $set: { subgroups, name: this.group.name.trim() } };
        const result = await this.$api.catalog.group.put(
          this.group._id,
          action
        );
        this.$store.dispatch("catalog/updateGroup", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    async create() {
      const subgroups = this.subgroups.map((o) => o._id);
      const data = { name: this.group.name.trim(), subgroups };
      this.loading = true;
      try {
        const result = await this.$api.catalog.group.create(data);
        this.$store.dispatch("catalog/addGroup", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  dense,
  mobile-breakpoint=0,
  @click:row="apply",
  role="button"
)
  template(v-slot:item.type="{ item }")
    span {{ item.type | paxType }}
  template(v-slot:item.created="{ item }")
    span.nowrap {{ item.trace.timestamp | date }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import moment from "moment-timezone";

export default {
  data() {
    return {
      headers: [
        { text: "ID", value: "trace.transNum" },
        { text: "Card", value: "account.account" },
        { text: "Tip", value: "amount.tipAmount" },
        { text: "Total", value: "amount.approveAmount" },
        { text: "Ref", value: "trace.refNum" },
        { text: "Type", value: "type" },
        { text: "Auth", value: "host.authCode" },
        { text: "Created", value: "created", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["order", "transactions"]),
    charges() {
      if (this.order.splitChecks.length > 0) {
        return this.order.splitChecks.flatMap((o) => o.payment.charges);
      } else {
        return this.order.payment.charges;
      }
    },
    items() {
      return _.chain(this.transactions)
        .reject((o) => o.biz != this.order.seller.id)
        .filter((item) => {
          const refNum = item.trace.refNum;
          let orderNum = refNum;
          if (refNum.split("=").length > 1) {
            orderNum = refNum.split("=")[0];
          }
          return orderNum == this.order.orderNumber;
        })
        .reject((o) =>
          this.charges.some((charge) => charge.approve == o.host?.authCode)
        )
        .sortBy((o) => o.trace.transNum)
        .value();
    },
  },
  methods: {
    apply(item) {
      const timestamp = item?.trace?.timestamp;
      if (timestamp) {
        const time = moment(timestamp.toString(), "YYYYMMDDHHmmss");
        // reject if time is 24 hours before
        if (time.isBefore(moment().subtract(24, "hours"))) {
          this.$toast.error("Transaction is too old to apply");
          return;
        }
      }
      this.$emit("apply", item);
    },
  },
};
</script>
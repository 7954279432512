<template lang="pug">
div
  .subtitle-2.my-5 {{ title }}
  Map(:url="map_url")
  CountByState.my-3(:result="result")
  CountByCity.my-3(:result="result")
  CountByCate.my-3(:url="cate_url")
</template>

<script>
import CountByState from "./CountByState";
import CountByCity from "./CountByCity";
import CountByCate from "./CountByCate";
import Map from "./Map";

export default {
  components: { CountByState, CountByCity, CountByCate, Map },
  props: {
    title: { type: String, required: true },
    city_url: { tyle: String, required: true },
    cate_url: { tyle: String, required: true },
    map_url: { tyle: String, required: true },
  },
  data() {
    return {
      result: [],
      cates: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const { data } = await this.axios.post(this.city_url);
      this.result = data.sort((a, b) => b.count - a.count);
    },
    async loadCate() {
      const { data } = await this.axios.post(this.cate_url);
      this.cates = data;
    },
  },
};
</script>
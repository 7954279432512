<template lang="pug">
div
  v-btn(color="secondary", @click="openDialog()") Upload Files
  v-dialog(v-model="dialog", width="500")
    v-card
      v-form(@submit.prevent="submit")
        v-card-text
          .sl-title Upload Form
          v-row(dense)
            v-col(cols="6")
              v-select(v-model="year", :items="yearitems", label="Year")
            v-col(cols="6")
              v-select(
                v-model="filetype",
                :items="filetypeitems",
                label="File Type"
              )
          v-text-field(
            v-model="items",
            label="Items",
            hint="Separate with comma"
          )
          v-btn(color="secondary", type="submit", :loading="loading") Submit
</template>

<script>
import _ from "underscore";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      yearitems: _.range(new Date().getFullYear(), 2015),
      year: new Date().getFullYear() - 1,
      filetypeitems: ["1099-k", "1099-nec", "1099-misc"],
      filetype: "1099-k",
      items: [],
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.items = [];
    },
    async submit() {
      var items = _.map(this.items.split(","), (o) => o.trim());
      if (!items || !items.length) {
        return this.$toast.error("Empty items");
      }
      this.loading = true;
      const params = { year: this.year, filetype: this.filetype, items: items };
      try {
        const result = await this.$api.taxFile.custom("upload", params);
        this.$toast.success(result);
        this.reset();
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    reset() {
      this.dialog = false;
      this.items = [];
    },
  },
};
</script>
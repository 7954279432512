<template lang="pug">
div
  v-card.mb-3(
    v-for="(item, country) in grouped",
    :key="country",
    flat,
    :loading="loading"
  )
    v-toolbar(flat, dense)
      .subtitle-2 {{ item.country }} ({{ item.sum }})
    v-data-table(
      :items="item.list",
      :headers="headers",
      dense,
      mobile-breakpoint=0,
      @click:row="edit",
      role="button"
    )
      template(v-slot:item.link="{ item }")
        .d-flex.flex-row.justify-end
          v-btn(
            icon,
            small,
            :href="item.link",
            target="_blank",
            @click.stop=""
          )
            v-icon(small) mdi-link
          v-btn(icon, small, @click="editParallax(item)")
            v-icon(small, color="success", v-if="item.parallax") mdi-image
            v-icon(small, v-else) mdi-image
          v-btn(icon, small, color="error", @click="handleRemove(item)")
            v-icon(small) mdi-delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["loading"],
  data() {
    return {
      sortItems: ["state", "city"],
      headers: [
        { text: "State", value: "state" },
        { text: "City", value: "city", sortable: false },
        { text: "URL", value: "url", sortable: false },
        { text: "Count", value: "nbiz" },
        { text: "Link", value: "link", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["cities"]),
    grouped() {
      return _.chain(this.cities)
        .map((item) => {
          item.link = "https://selflane.com/city/" + item.url;
          return item;
        })
        .sortBy("city")
        .sortBy("state")
        .groupBy("country")
        .map((list, key) => {
          const sum = list.reduce((a, b) => a + b.nbiz, 0);
          return { country: key, list, sum };
        })
        .value();
    },
  },
  methods: {
    ...mapActions(["removeCity"]),
    edit(item) {
      EventBus.$emit("edit-city", item);
    },
    editParallax(item) {
      EventBus.$emit("edit-city-parallax", item);
    },
    async handleRemove(item) {
      try {
        await this.$api.city.delete(item._id);
        this.removeCity(item);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<template lang="pug">
div
  MonthSelector(@select="load")
  List.my-3(:items="items")
</template>

<script>
import List from "/libs/components/CRM/Log/List";

export default {
  components: { List },
  computed: {
    items() {
      return this.$store.getters["crm/logs"];
    },
  },
  methods: {
    async load({ range }) {
      const { begin, end } = range;
      if (!begin || !end) return;
      const criteria = {
        createdAt: {
          $gte: new Date(begin),
          $lte: new Date(end),
        },
      };
      const result = await this.$api.crm.log.list({ criteria });
      this.$store.dispatch("crm/setLogs", result);
    },
  },
};
</script>
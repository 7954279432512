<template lang="pug">
v-dialog(v-model="dialog", max-width="400px")
  v-card(v-if="order")
    v-toolbar(dense, flat, color="red", dark)
      .subtitle-2 Compensate \#{{ order.orderNumber }} {{ order.needed | datetime }}
      v-spacer
      .subtitle-2 {{ order.payment.total | currency }}
    v-card-subtitle Restaurant: {{ order.seller.name }}
    v-card-subtitle(v-if="order.customer") Customer: {{ order.customer.name }}
    v-card-text
      span Are you sure to issue a gift card on this order? It will send customer free gift card.
      v-form(@submit.prevent="submit")
        v-text-field(v-model.number="amount", label="Compensate Amount")
        v-text-field(v-model="message", label="Message")
        v-row(justify="space-between")
          v-btn(text, @click="dialog = false") Cancel
          v-btn(
            type="submit",
            text,
            color="secondary",
            :disabled="!amount",
            :loading="loading"
          ) Proceed
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      order: null,
      amount: 0,
      message: "",
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("compensate-order", this.open);
  },
  destroyed() {
    EventBus.$off("compensate-order", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open(data) {
      if (!data) return;
      this.order = data;
      this.load(data._id);
      this.amount = 0;
      this.message = "";
      this.dialog = true;
      this.email = true;
    },
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      try {
        this.order = await this.$api.order.retrieve(params);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async submit() {
      if (!this.order || !this.order._id || !this.amount) return;
      this.loading = true;
      const params = {
        orderId: this.order._id,
        amount: this.amount,
        message: this.message,
      };
      try {
        const result = await this.$api.giftcard.custom("compensate", params);
        this.updateOrder(result);
        this.$toast.success("Issued $" + result.amount);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
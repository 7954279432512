<template lang="pug">
v-dialog(v-model="dialog", max-width="400px")
  v-form(@submit.prevent="submit")
    v-card(v-if="order")
      v-card-title
      v-card-text
        OrderHeader(:order="order")
        v-text-field(v-model="reason", label="Reason", required, autofocus)
        v-switch(
          v-model="pass_fee",
          label="Pass Fee to Merchant",
          color="secondary"
        )
      v-card-actions
        v-btn(text, @click="dialog = false") Cancel
        v-btn(
          type="submit",
          text,
          color="secondary",
          :disabled="!reason.length",
          :loading="loading"
        ) Cancel & Refund
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      reason: "",
      pass_fee: true,
      loading: false,
    };
  },
  computed: { ...mapGetters(["order"]) },
  mounted() {
    EventBus.$on("cancel-order", this.open);
  },
  destroyed() {
    EventBus.$off("cancel-order", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    open() {
      this.reason = "";
      this.pass_fee = true;
      this.dialog = true;
    },
    async submit() {
      if (!this.order || !this.order._id || !this.reason) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.order._id },
        pass_fee: this.pass_fee,
        cancelNote: this.reason,
      };
      try {
        const result = await this.$api.order.cancel(params);
        this.updateOrder(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
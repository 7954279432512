<template lang="pug">
Page(title="On-time Score")
  template(v-slot:header)
    WeekSelector.mb-3(@select="load")
  v-row(wrap, dense)
    v-col(cols="12", sm="6", v-for="(group, gindex) in grouped", :key="gindex")
      v-card(outlined)
        v-toolbar(flat, dense)
          .subtitle-2 {{ group.zone }}
          v-spacer
          .subtitle-2 {{ group.total }}
        simple-table
          thead
            tr
              th Date
              th Count
              th.text-right Score
          tbody
            tr(v-for="(item, index) in group.data", :key="index")
              td {{ item.month }}/{{ item.day }}
              td {{ item.count }}
              td.text-right {{ ((item.sum_score / item.count) * 20) | number(0) }}
  RankDriver(:driver_result="driver_result")
  RewardForm
</template>

<script>
import RankDriver from "./RankDriver";
import _ from "underscore";
import RewardForm from "./../../Driver/RewardPenalties/Form.vue";

export default {
  components: { RankDriver, RewardForm },
  data() {
    return { result: [], driver_result: [] };
  },
  computed: {
    grouped() {
      return _.chain(this.result)
        .sortBy("day")
        .sortBy("month")
        .sortBy("year")
        .sortBy("zone")
        .groupBy("zone")
        .map((data, zone) => {
          const total = data.reduce((a, b) => a + b.count, 0);
          return { zone, total, data };
        })
        .value();
    },
  },
  methods: {
    async load(range) {
      const params = { begin: range.begin, end: range.end };
      const url = "/delivOrders/qa/ontime_score/daily_zone";
      const { data } = await this.axios.post(url, params);
      this.result = data;
      this.rankDriver(range);
    },
    async rankDriver(range) {
      const params = { begin: range.begin, end: range.end };
      const url = "/delivOrders/qa/ontime_score/rank_driver";
      const { data } = await this.axios.post(url, params);
      this.driver_result = data;
    },
  },
};
</script>

<template lang="pug">
StandardChart(
  :input="chartData",
  chartType="bar",
  max-width="100%",
  :loading="loading"
)
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      organization: null, // ID of the organization
      loading: false,
      results: [],
      colors: ["#7682d6", "#2cb2b9", "#50a948", "#ad4d24", "#a74ac3"],
    };
  },
  computed: {
    chartData() {
      // stack results by month, fill in missing months
      // label with [Jan, Feb, Mar, ...]
      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      const labels = months.map((month) => {
        const date = new Date(this.year, month - 1);
        return date.toLocaleString("default", { month: "short" });
      });
      // fill in missing months
      let results = months.map((month) => {
        const found = this.results.find((result) => result.month === month);
        if (found) return found.count;
        return 0;
      });
      return { title: `${this.year} Contact Growth`, labels, values: results };
    },
  },
  methods: {
    async load({ year, organization }) {
      this.loading = true;
      if (year) this.year = year;
      this.organization = organization;
      const params = { year: this.year, organization: this.organization };
      try {
        this.results = await this.$api.crm.contact.custom("yearly", params);
      } catch (e) {
        console.log(e?.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>
<!--
  Update business address
-->
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", @click="open")
  v-card
    v-card-title Edit Business Address
    v-form(@submit.prevent="save")
      v-card-text
        GoogleAddressField(v-model="address")
        v-text-field(label="Suite/Room (optional)", v-model="address.line2")
        v-text-field(label="Instruction", v-model="address.note")
      v-card-actions
        v-btn(color="secondary", type="submit", :loading="loading", block) Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BizAddressForm",
  data() {
    return {
      dialog: false,
      address: {},
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      this.address = JSON.parse(JSON.stringify(this.biz.address)) || {};
      this.dialog = true;
    },
    async save() {
      if (!this.biz?._id) return;
      if (!this.address?.line1) return;
      this.loading = true;
      try {
        const action = {
          $set: { address: this.address, place_id: this.address.place_id },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", max-width="500")
  v-card
    v-card-title
      .subtitle-2 {{ isNew ? "Create" : "Update" }} Region
    v-form(@submit.prevent="save")
      v-card-text
        v-text-field(
          v-model="region.name",
          :rules="[(v) => !!v || 'Name is required']",
          label="Name",
          required
        )
        v-text-field(v-model="region.description", label="Description")
        v-btn(type="submit", block, color="secondary", :loading="loading") Save
        div(v-if="!isNew")
          v-divider.my-5
          .my-10 Do you want to delete this enterprise region?
          v-switch(v-model="deletable", label="Yes, delete it", color="error")
          v-btn(
            block,
            small,
            outlined,
            color="error",
            :disabled="!deletable",
            :loading="deleting",
            @click="deleteOne()",
            v-if="!isNew"
          ) Delete Enterprise Region
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  props: {
    bizs: { type: Array, required: true },
  },
  data() {
    return {
      region: {},
      loading: false,
      deleting: false,
      deletable: false,
      dialog: false,
      items: [],
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise"]),
    isNew() {
      return !this.region._id;
    },
    sortedItems() {
      return this.items.toSorted((a, b) => {
        if (a?.selected && !b?.selected) return -1;
        if (!a?.selected && b?.selected) return 1;
        return a?.name?.localeCompare(b?.name);
      });
    },
  },
  mounted() {
    EventBus.$on("enterprise-region-form", this.open);
  },
  destroyed() {
    EventBus.$off("enterprise-region-form", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.region = JSON.parse(JSON.stringify(data));
      } else {
        this.region = {
          name: "",
          description: "",
          enterprise: this.enterprise?._id,
        };
      }
      this.items = this.enterprise.bizs
        .map((item) => {
          let biz = this.bizs.find((b) => b._id === item);
          const selected = this.region.bizs?.includes(item) || false;
          if (biz) biz.selected = selected;
          return biz;
        })
        .filter((item) => item);
      this.items = JSON.parse(JSON.stringify(this.items)); // fresh copy to ensure reactivity
      this.deletable = false;
      this.dialog = true;
    },
    select(item) {
      this.items.find((i) => i._id === item._id).selected = !item.selected;
    },
    async save() {
      this.loading = true;
      this.region.bizs = this.items
        .filter((item) => item.selected)
        .map((item) => item._id);
      try {
        if (!this.isNew) {
          const action = {
            name: this.region.name,
            description: this.region.description,
          };
          const result = await this.$api.entp.region.put(
            this.region._id,
            action
          );
          this.$store.dispatch("entp/updateRegion", result);
        } else {
          const result = await this.$api.entp.region.create(this.region);
          this.$store.dispatch("entp/addRegion", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async deleteOne() {
      this.deleting = true;
      try {
        const result = await this.$api.entp.region.delete(this.region._id);
        this.$store.dispatch("entp/removeRegion", result);
        this.$toast.success("Region deleted successfully");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.deleting = false;
    },
  },
};
</script>

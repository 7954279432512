<template lang="pug">
v-card(v-if="order")
  v-toolbar(flat, dense)
    .subtitle-2 Upgrade to Delivery
  v-card-text
    OrderHeader(:order="order")
    v-form(@submit.prevent="submit")
      v-row(wrap, dense)
        v-col(cols="8")
          GoogleAddressField(v-model="address")
        v-col(cols="4")
          v-text-field(
            label="Apt, room",
            v-model="address.line2",
            hide-details
          )
        v-col(cols="12")
          v-text-field(
            label="Delivery Note",
            v-model="address.note",
            hide-details
          )
      v-row(wrap, dense)
        v-col(cols="6")
          PriceField(label="Basic Delivery Fee", v-model.number="baseFee")
        v-col(cols="6")
          PriceField(label="Additional Tip to Driver", v-model.number="addTip")
      .text-center.sl-title Distance: {{ geoDistance2.value | number(1) }} {{ geoDistance2.unit }}
      v-btn.text-capitalize(
        block,
        type="submit",
        color="secondary",
        :loading="loading"
      ) Upgrade to Delivery
</template>

<script>
import { mapActions } from "vuex";
import BizUtils from "/libs/utils/biz.js";

export default {
  props: ["order"],
  data() {
    return {
      loading: false,
      addTip: 0,
      baseFee: 5.99,
      address: {},
    };
  },
  computed: {
    distanceUnit() {
      if (this.order?.seller?.address?.country == "CA") return "km";
      return "mi.";
    },
    geoDistance2() {
      let a = this.order?.seller?.address?.geometry;
      let b = this.address?.geometry;
      if (a && b && b.lat) {
        return BizUtils.geoDistance2(a, b, this.distanceUnit);
      }
      return {};
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async submit() {
      if (!this.order) return;
      const params = {
        orderId: this.order._id,
        addTip: this.addTip,
        baseFee: this.baseFee,
        address: this.address,
      };
      this.loading = true;
      try {
        const url = "/orders/addOnlineDeliv";
        const { data } = await this.axios.post(url, params);
        this.updateOrder(data);
        this.dialog = false;
        this.address = {};
        this.$emit("done");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

import Enterprise from './index.vue'
import Home from './Home/index.vue'
import Settings from './Settings/index.vue'
import Regions from './Regions/index.vue'

export default [{
  path: '/enterprise', component: Enterprise, meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'home' },
    { path: 'home', component: Home },
    { path: 'settings', component: Settings },
    { path: 'regions', component: Regions }
  ]
}]
<template lang="pug">
v-btn(
  icon,
  small,
  color="error",
  v-if="item.not_found",
  @click="deleteone()",
  :loading="loading"
)
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["removeTaxFile"]),
    async deleteone() {
      if (!this.item) return;
      this.loading = true;
      const params = { fileid: this.item._id };
      await this.$api.taxFile.delete(params);
      this.removeTaxFile(this.item);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  EditLogo
  EditParallax
  v-row
    v-col(
      cols="12",
      sm="6",
      md="4",
      lg="3",
      v-for="(item, index) in pois",
      :key="index"
    )
      Cell(:poi="item")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";
import EditLogo from "./EditLogo";
import EditParallax from "./EditParallax";

export default {
  components: { Cell, EditLogo, EditParallax },
  computed: {
    ...mapGetters(["pois"]),
  },
};
</script>

<template lang="pug">
v-row(dense)
  v-col(cols="12", md="6")
    .s-vstack
      slot(name="content")
      .d-flex.flex-row.align-center
        v-text-field.mr-5(
          v-model="searchText",
          label="Filter Result",
          clearable,
          solo,
          hide-details,
          flat,
          dense
        )
        Download
      v-data-table(
        :items="bizAccounts",
        :headers="headers",
        :search="searchText",
        @click:row="show",
        role="button",
        sort-by="created",
        sort-desc,
        dense,
        mobile-breakpoint=0
      )
        template(v-slot:item.created="{ item }")
          .d-flex.flex-row.align-center
            StandingIcon.mr-1(:biz="item")
            span.nowrap {{ item.created | date({ dynamic: true }) }}
        template(v-slot:item.online_start="{ item }")
          .d-flex.flex-row
            v-icon.mr-1(color="success", v-if="isOnline(item)", small) mdi-check-circle
            span.nowrap {{ item.online_start | date({ dynamic: true }) }}
        template(v-slot:item.pos_start="{ item }")
          span.nowrap {{ item.pos_start | date({ dynamic: true }) }}
        template(v-slot:item.tax_items="{ item }")
          span {{ item.tax_items | tax_items }}
        template(v-slot:item.industry="{ item }")
          span.nowrap {{ item.industry | industry }}
  v-col(cols="12", md="6")
    BizDetail
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import BizDetail from "./Detail/index";
import Download from "./Download";
import StandingIcon from "/libs/components/Biz/StandingIcon";

export default {
  filters: {
    tax_items(items) {
      return items?.map((o) => o.percentage + "%")?.join(", ");
    },
  },
  components: { BizDetail, Download, StandingIcon },
  props: ["search"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Created", value: "created", filterable: false },
        { text: "Name", value: "name" },
        { text: "City", value: "address.city" },
        { text: "State", value: "address.state" },
        { text: "Online", value: "online_start", filterable: false },
        { text: "POS", value: "pos_start", filterable: false },
        { text: "Tax", value: "tax_items", filterable: false },
        { text: "Industry", value: "industry", align: "end" },
      ],
      selectedBizId: null,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
  },
  methods: {
    show(item) {
      EventBus.$emit("show-biz-detail", item._id);
    },
    isOnline(item) {
      return item.status.byAdmin == 1;
    },
  },
};
</script>

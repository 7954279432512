<template lang="pug">
v-btn(@click="submit", :loading="loading") Fix Currency
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      this.loading = true;
      const params = { limit: 100 };
      try {
        const { data } = await this.axios.post("/invoices/fixCurrency", params);
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

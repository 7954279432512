<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="biz")
    v-toolbar(dense, flat)
      .subtitle-2 Create Location: {{ biz.name }}
    v-card-text Do you want to create a terminal location for this business?
    v-card-actions
      v-btn(block, color="secondary", @click="submit()", :loading="loading") Create Location
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      biz: null,
      loading: false,
    };
  },
  methods: {
    open(biz) {
      this.biz = biz;
      this.dialog = true;
    },
    async submit() {
      if (!this.biz) return;
      this.loading = true;
      const params = { bizId: this.biz._id };
      try {
        await this.$api.stripe.terminalLocation.create(params);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>
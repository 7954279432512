<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="biz_name",
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.subtotal="{ item }")
    span {{ item.subtotal | currency }}
  template(v-slot:item.tax="{ item }")
    span {{ item.tax | currency }}
  template(v-slot:item.total="{ item }")
    span {{ item.total | currency }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Name", value: "biz_name" },
        { text: "Period", value: "period" },
        { text: "Subtotal", value: "subtotal" },
        { text: "Tax", value: "tax" },
        { text: "Total", value: "total" },
      ],
    };
  },
};
</script>
<template lang="pug">
div
  v-data-table(:items="result", :headers="headers", dense)
    template(v-slot:item.program="{ item }")
      span {{ formatProgram(item.program) }}
  StandardChart.my-3(:input="chartData", :showTotal="true", chartType="bar")
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Count", value: "count" },
        { text: "#", value: "num", align: "end" },
      ],
      result: [],
    };
  },
  computed: {
    chartData() {
      return {
        title: "Count Distribution",
        labels: this.result.map((o) => o.count),
        values: this.result.map((o) => o.num),
      };
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.result = await this.$api.memberProgram
        .custom("stats/count")
        .catch(() => null);
    },
  },
};
</script>
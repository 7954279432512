<template lang="pug">
v-card(v-if="items")
  v-toolbar(flat, dense)
    .subtitle Loaded members
    v-spacer
    v-btn(color="secondary", outlined, @click="upload()", :loading="loading") Upload
  v-card-text
    v-text-field(label="Search", v-model="searchText")
    v-data-table(
      :headers="headers",
      :items="items",
      :search="searchText",
      dense
    )
      template(v-slot:item.created="{ item }")
        span {{ item.created | date }}
      template(v-slot:item.last="{ item }")
        span {{ item.last | date }}
    div(v-for="(line, index) in info", :key="index") {{ line }}
    .red--text(v-for="(err, eindex) in errors", :key="eindex") {{ err }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Last", value: "name.last" },
        { text: "First", value: "name.first" },
        { text: "Created", value: "created" },
        { text: "Last", value: "last" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Balance", value: "balance" },
        { text: "Amount", value: "amount" },
        { text: "Count", value: "count" },
      ],
      loading: false,
      info: [],
      errors: [],
    };
  },

  methods: {
    async upload() {
      if (!this.items?.length) return;
      let items = JSON.parse(JSON.stringify(this.items));
      this.info = [];
      this.errors = [];
      this.loading = true;

      const groups = this.getGroups(items);
      for (let i = 0; i < groups.length; i++) {
        let group = groups[i];
        const text = `Upload group ${i} of ${groups.length} with ${group.length} items`;
        this.info.push(text);
        try {
          const { data } = await this.axios.post("/member/upload", {
            items: group,
          });
          this.info.push(data);
        } catch (err) {
          this.errors.push(err.response?.data);
        }
        this.loading = false;
      }
    },
    getGroups(items) {
      let groups = [];
      while (items.length > 0) {
        const fragments = items.splice(0, 100);
        groups.push(fragments);
      }
      return groups;
    },
  },
};
</script>
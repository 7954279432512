<template lang="pug">
Table
  template(v-slot:content)
    Form
</template>

<script>
import Table from "./../Table/index";
import Form from "./Form";

export default {
  components: { Form, Table },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="300")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Pay By Check
    v-form(@submit.prevent="save")
      v-card-text
        v-text-field(label="Check info", v-model="checkInfo", ref="focus")
      v-card-actions
        v-btn(block, :disabled="!checkInfo", :loading="loading", type="submit") save
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      bizorder: null,
      checkInfo: "",
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("pay-biz-order-check", this.open);
  },
  destroyed() {
    EventBus.$off("pay-biz-order-check", this.open);
  },
  methods: {
    ...mapActions(["updateBizOrder"]),
    open(data) {
      this.bizorder = data;
      this.dialog = true;
      this.checkInfo = "";
    },
    async save() {
      if (!this.bizorder) return;
      const params = { id: this.bizorder._id, check: this.checkInfo };
      this.loading = true;
      try {
        const result = await this.$api.bizOrder.custom("payByCheck", params);
        this.updateBizOrder(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="driverId && estimateResult")
    v-toolbar(dense, flat)
      .subtitle-2 Payout Estimate
      v-spacer
      v-btn.text-capitalize(
        color="secondary",
        outlined,
        small,
        rounded,
        :loading="loading",
        @click="send()"
      ) Send Payout
    simple-table
      tbody
        tr
          td Country
          td.text-right {{ estimateResult.country }}
        tr
          td Count
          td.text-right {{ estimateResult.count }}
        tr
          td Fee
          td.text-right {{ estimateResult.fee | currency }}
        tr
          td Tip
          td.text-right {{ estimateResult.tip | currency }}
        tr
          td Reward
          td.text-right {{ estimateResult.reward | currency }}
      tfoot
        tr
          th Total
          th.text-right {{ total | currency }}
            span.ml-1(v-if="estimateResult.count") ({{ (total / estimateResult.count) | currency }})
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      driverId: null,
      estimateResult: null,
      loading: false,
    };
  },
  computed: {
    total() {
      if (!this.estimateResult) return 0;
      return (
        this.estimateResult.fee +
        this.estimateResult.tip +
        this.estimateResult.reward
      );
    },
  },
  mounted() {
    EventBus.$on("show-send-driver-payout", this.open);
  },
  destroyed() {
    EventBus.$off("show-send-driver-payout", this.open);
  },
  methods: {
    open(driverId) {
      this.driverId = driverId;
      this.dialog = true;
      this.estimateResult = null;
      this.estimate();
    },
    async estimate() {
      if (!this.driverId) return;
      const params = { driverId: this.driverId };
      try {
        const { data } = await this.axios.post(
          "/driver/payouts/estimate",
          params
        );
        this.estimateResult = data;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async send() {
      if (!this.driverId) return;
      const params = { driverId: this.driverId };
      this.loading = true;
      try {
        await this.axios.post("/driver/payouts/create", params);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-avatar(size="18", :color="color", dark)
  .text-style
    slot
</template>

<script>
export default {
  props: {
    color: { type: String, default: "secondary" },
  },
};
</script>

<style lang="sass" scoped>
.text-style
  color: white
  font-size: 12px
  font-weight: 600
</style>
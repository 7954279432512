<template lang="pug">
Page(title="Store Orders")
  Load
  v-row
    v-col(cols="12", md="6")
      List(@select="selectOrder")
    v-col(cols="12", md="6")
      .s-vstack
        MissedTransactions
        div
          LoadTransaction
  OrderDetail(ref="detail")
</template>

<script>
import List from "./List";
import Load from "./Load/index";
import LoadTransaction from "./LoadTransaction";
import MissedTransactions from "./MissedTransactions";
import OrderDetail from "./Order";

export default {
  components: { LoadTransaction, Load, List, OrderDetail, MissedTransactions },
  methods: {
    selectOrder(id) {
      this.$refs.detail.open(id);
    },
  },
};
</script>

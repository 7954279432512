<template lang="pug">
div
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 Categories
      v-spacer
      IconBtn(@click="create", title="Add", icon="mdi-plus")
    v-list.py-0(dense)
      v-list-item(
        v-for="(item, index) in categories",
        :key="index",
        @click="edit(item)"
      )
        v-list-item-content
          v-list-item-title {{ item.name }}
  Form(ref="form")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["timeline_categories"]),
    categories() {
      if (!this.timeline_categories) return [];
      return this.timeline_categories.toSorted((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setTimelineCategories"]),
    async load() {
      this.loading = true;
      try {
        const params = { criteria: {} };
        const result = await this.$api.timeline.category.list(params);
        this.setTimelineCategories(result);
      } catch (e) {
        this.$toast.error(e.response?.data);
      }
      this.loading = false;
    },
    create() {
      const data = { name: "" };
      this.$refs.form.open(data);
    },
    edit(event) {
      this.$refs.form.open(event);
    },
  },
};
</script>
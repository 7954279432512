<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open()", v-on="on", :small="false", :xSmall="true", text)
  v-card(v-if="item")
    v-card-title Edit IP
    v-form(@submit.prevent="save")
      v-card-text
        v-text-field(v-model="ip", label="IP", required)
      v-card-actions
        v-btn(type="submit", :loading="loading", color="secondary") Submit
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      dialog: false,
      ip: null,
    };
  },
  methods: {
    ...mapActions(["updateMissedTransaction"]),
    open() {
      this.ip = this.item.loc;
      this.dialog = true;
    },
    async save() {
      if (!this.ip || !this.item?._id) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.item._id },
        action: { $set: { loc: this.ip } },
      };
      try {
        const result = await this.$api.transaction.missed.update(params);
        this.updateMissedTransaction(result);
        this.dialog = false;
        this.$emit("updated", result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card(v-if="order")
    v-card-title
    v-card-text
      OrderHeader(:order="order")
      v-form(@submit.prevent="submit()")
        v-text-field(v-model="amount", type="number", label="Refund Amount")
        v-text-field(v-model="reason", label="Reason")
        v-switch(
          v-model="calculate_tax",
          label="Before Tax",
          color="secondary"
        )
        v-switch(v-model="send_email", label="Send Email", color="secondary")
        v-btn(
          type="submit",
          :disabled="!amount",
          block,
          color="secondary",
          :loading="loading"
        ) Partial Refund
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "PartialRefund",
  data() {
    return {
      dialog: false,
      loading: false,
      amount: 0,
      send_email: true,
      reason: "",
      calculate_tax: true,
    };
  },
  computed: { ...mapGetters(["order"]) },
  mounted() {
    EventBus.$on("order-partial-refund", this.open);
  },
  destroyed() {
    EventBus.$off("order-partial-refund", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async open() {
      this.amount = 0;
      this.send_email = true;
      this.reason = "";
      this.calculate_tax = true;
      this.dialog = true;
    },
    async submit() {
      if (!this.order) return;
      if (!this.amount) return;
      if (this.amount > this.order.payment.total) {
        this.$toast.error("Maximum is " + this.order.payment.total);
        return;
      }
      this.loading = true;
      const params = {
        orderId: this.order._id,
        amount: this.amount,
        send_email: this.send_email,
        reason: this.reason,
        calculate_tax: this.calculate_tax,
      };
      try {
        const { data } = await this.axios.post("/orders/partialRefund", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

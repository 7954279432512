<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="bizorder")
    Head(:bizorder="bizorder")
    Items(:bizorder="bizorder")
    v-divider
    Payment(:bizorder="bizorder")
    v-card-text
      Misc(:bizorder="bizorder", @deleted="dialog = false", @cancelled="load")
    StripeCharges(:charges="charges")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Head from "./Head";
import Items from "./Items";
import Payment from "./Payment";
import Misc from "./Misc/index";
import StripeCharges from "./StripeCharges";

export default {
  name: "BizOrderDetail",
  components: { Head, Items, Payment, Misc, StripeCharges },
  data() {
    return {
      bizorder: null,
      charges: [], // stripe charges
      dialog: false,
    };
  },
  mounted() {
    EventBus.$on("show-biz-order", this.load);
  },
  destroyed() {
    EventBus.$off("show-biz-order", this.load);
  },
  methods: {
    async load(bizorder) {
      this.bizorder = bizorder;
      await this.loadCharges();
      this.dialog = true;
    },
    async loadCharges() {
      const params = { criteria: { biz_order_id: this.bizorder._id } };
      try {
        this.charges = await this.$api.transaction.stripe.list(params);
      } catch (err) {
        //
      }
    },
  },
};
</script>

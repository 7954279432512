<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card(v-if="deliv")
    v-toolbar(flat, dense)
      .subtitle-2 Update Delivery Zone
    v-card-text
      v-form(@submit.prevent="submit")
        v-select(
          v-model="zones",
          :items="zone_items",
          label="Working Zones",
          multiple
        )
        v-btn(
          block,
          text,
          color="secondary",
          type="submit",
          :loading="loading"
        ) Set Zone
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      deliv: null,
      zones: [],
    };
  },
  computed: {
    ...mapGetters(["geoServiceZones"]),
    zone_items() {
      return this.geoServiceZones.map((o) => o.zoneName);
    },
  },
  mounted() {
    EventBus.$on("set-deliv-zone", this.open);
    this.loadZones();
  },
  destroyed() {
    EventBus.$off("set-deliv-zone", this.open);
  },
  methods: {
    ...mapActions(["updateDeliv", "setGeoServiceZones"]),
    open(data) {
      this.deliv = data;
      if (this.deliv.zones) {
        this.zones = JSON.parse(JSON.stringify(this.deliv.zones));
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.deliv) return;
      const params = {
        criteria: { _id: this.deliv._id },
        action: { $set: { zones: this.zones } },
      };
      this.loading = true;
      try {
        const { data } = await this.axios.post(
          "/delivery/accounts/update",
          params
        );
        this.updateDeliv(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadZones() {
      const params = { criteria: {} };
      const result = await this.$api.geoZone.list(params);
      this.setGeoServiceZones(result);
    },
  },
};
</script>

<template lang="pug">
v-dialog(max-width="800", v-model="dialog")
  v-card(v-if="url")
    v-img(:src="url", contain)
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["delivOrder"],
  data() {
    return {
      dialog: false,
      url: null,
    };
  },
  mounted() {
    EventBus.$on("show-photo", this.open);
  },
  destroyed() {
    EventBus.$off("show-photo", this.open);
  },
  methods: {
    open(url) {
      if (!url) return;
      this.dialog = true;
      this.url = url;
    },
  },
};
</script>

<template lang="pug">
div
  Header
  Table(:chain="chain")
  AccessForm
</template>

<script>
import Header from "./Header";
import Table from "./Table";
import AccessForm from "./AccessForm";
import { mapActions } from "vuex";

export default {
  components: { Header, Table, AccessForm },
  props: { chain: { type: Object, required: true } },
  computed: {
    chainId() {
      if (this.chain) return this.chain._id;
      return null;
    },
  },
  watch: {
    chainId(val, oldVal) {
      if (val && val != oldVal) this.load();
    },
  },
  methods: {
    ...mapActions(["setChainAccesses"]),
    async load() {
      if (!this.chainId) return;
      const params = { criteria: { chain: this.chainId } };
      const result = await this.$api.chain.access.list(params);
      this.setChainAccesses(result);
    },
  },
};
</script>
<template lang="pug">
v-btn.text-capitalize(
  @click="announce",
  :loading="loading",
  outlined,
  small,
  color="secondary"
) Announce Invoices
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async announce() {
      this.loading = true;
      try {
        const result = await this.$api.salesforce.invoice.custom("announce");
        this.$toast.success(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.response);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div(v-if="visible")
  StandardChart(:input="userData", :showDownload="showDownload")
</template>

<script>
import _ from "underscore";

export default {
  props: ["year", "type", "showDownload"],
  data() {
    return {
      result: [],
    };
  },
  computed: {
    visible() {
      return this.type == "monthly";
    },
    userData() {
      const months = _.map(this.result, (o) => {
        switch (o.month) {
          case 1:
            return "January";
          case 2:
            return "Feburary";
          case 3:
            return "March";
          case 4:
            return "April";
          case 5:
            return "May";
          case 6:
            return "June";
          case 7:
            return "July";
          case 8:
            return "August";
          case 9:
            return "September";
          case 10:
            return "October";
          case 11:
            return "November";
          case 12:
            return "December";
        }
      });
      return {
        title: "User Growth / Month",
        labels: months,
        values: _.pluck(this.result, "count"),
        isCurrency: false,
      };
    },
  },
  watch: {
    year() {
      this.load();
    },
    type() {
      this.load();
    },
  },
  methods: {
    async load() {
      if (!this.year || !this.visible) return;
      const params = { year: this.year };
      const result = await this.$api.influencer.account.custom(
        "stats/growth_monthly",
        params
      );
      this.result = _.chain(result).sortBy("month").sortBy("year").value();
    },
  },
};
</script>

<style lang="sass" scoped>
.growth-box
  max-width: 500px
  width: 95%
  margin-left: auto
  margin-right: auto
</style>
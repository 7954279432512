<template lang="pug">
Page(title="Driver Reward/Penalty")
  template(v-slot:header)
    MonthSelector(@select="load")
  .d-flex.flex-row.align-center.my-3
    v-spacer
    v-btn.text-capitalize(
      @click="create()",
      outlined,
      small,
      depressed,
      color="grey darken-2"
    ) Add
      v-icon(right, small) mdi-plus
  Table
  Summary
  Form
  WeeklyReward
  Announce
</template>

<script>
import { mapActions } from "vuex";
import Table from "./Table/index";
import Summary from "./Summary";
import Form from "./Form";
import WeeklyReward from "./WeeklyReward";
import Announce from "./Announce";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Table, Summary, Form, WeeklyReward, Announce },
  data() {
    return { range: null };
  },
  methods: {
    ...mapActions(["setRewardPenalties"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;
      const params = {
        criteria: { created: { $gt: this.range.begin, $lte: this.range.end } },
      };
      const { data } = await this.axios.post(
        "/driver/rewardPenalties/list",
        params
      );
      this.setRewardPenalties(data);
    },
    create() {
      EventBus.$emit("edit-driver-rewardpenalty");
    },
  },
};
</script>
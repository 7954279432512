const state = {
  cities: [],
  selectedCity: null,
};

const getters = {
  cities: state => state.cities,
  selectedCity: state => state.selectedCity,
};

const actions = {
  setCities: ({ commit }, data) => { commit("setCities", data); },
  updateCity: ({ commit }, data) => { commit("updateCity", data); },
  setSelectedCity: ({ commit }, data) => { commit("setSelectedCity", data); },
  removeCity: ({ commit }, data) => { commit('removeCity', data) }
};

const mutations = {
  setCities(state, data) { state.cities = data; },
  updateCity(state, data) {
    state.cities = state.cities.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  setSelectedCity(state, data) { state.selectedCity = data; },
  removeCity(state, data) {
    state.cities = state.cities.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations, };
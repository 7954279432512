<template lang="pug">
div
  .text-center.mb-3
    v-btn-toggle(
      v-model="ended",
      dense,
      rounded,
      color="secondary",
      @change="load()"
    )
      v-btn.text-capitalize(
        small,
        v-for="(item, index) in status_items",
        :key="index",
        :value="item.value"
      ) {{ item.name }}
  List
  .link-box.my-10
    Offline
    Pricing
    CreateLastMonth
  SalesForceOrganizationLoad
</template>

<script>
import { mapActions } from "vuex";
import List from "./List/index";
import Pricing from "./Pricing";
import Offline from "./Offline";
import CreateLastMonth from "./CreateLastMonth";

export default {
  components: { List, Pricing, Offline, CreateLastMonth },
  data() {
    return {
      ended: false,
      status_items: [
        { name: "Active", value: false },
        { name: "Retired", value: true },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServices", "setSalesPersons"]),
    async load() {
      let params = { criteria: { ended: { $not: { $gt: 0 } } } };
      if (this.ended) {
        params = { criteria: { ended: { $gt: 0 } } };
      }
      const result = await this.$api.service.account.list(params);
      this.setBizServices(result);
      await this.loadPersons();
    },
    async loadPersons() {
      const params = { criteria: {} };
      const result = await this.$api.salesforce.person.list(params);
      this.setSalesPersons(result);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-form(@submit.prevent="submit")
    v-card(v-if="item")
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Service Item
      v-divider
      v-card-text
        v-row(dense)
          v-col(cols="5")
            v-text-field(label="Name", v-model="item.name")
          v-col(cols="4")
            v-text-field(
              label="SKU",
              v-model="item.sku",
              :disabled="item._id != null"
            )
          v-col(cols="3")
            v-select(label="Sort", v-model="item.sort", :items="sortItems")
        v-text-field(label="Detail", v-model="item.detail")
        v-select(label="Note", v-model="item.note", :items="note_items")
        v-row(dense)
          v-col(cols="6")
            v-select(
              label="Category",
              v-model="item.category",
              :items="category_items"
            )
          v-col(cols="6")
            v-select(label="Unit", v-model="item.unit", :items="unit_items")
          v-col(cols="6")
            PriceField(label="US Price", v-model.number="item.us_price")
          v-col(cols="6")
            PriceField(label="CA Price", v-model.number="item.ca_price")
      v-card-actions
        v-btn(color="secondary", type="submit", block, :loading="loading") Save
      v-card-text
        DeleteOne(:item="item", @deleted="dialog = false")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,
      category_items: ["regular", "enterprise"],
      unit_items: ["Per Location Monthly", "Per Item", "Per Transaction"],
      note_items: [
        "",
        "Included in Essential Plan",
        "Included in Power Plan",
        "Included in Essential & Power Plan",
        "Included in Essential (up to 2000/month) & Power Plan",
      ],
    };
  },
  computed: {
    ...mapGetters(["service_items"]),
    title() {
      return this.item?._id ? "Edit" : "Create";
    },
    sortItems() {
      const count = this.service_items.length || 0;
      return _.range(1, count + 2);
    },
  },
  methods: {
    ...mapActions(["addBizServiceItem", "updateBizServiceItem"]),
    open(data) {
      if (data) {
        this.item = JSON.parse(JSON.stringify(data));
      } else {
        this.item = {
          name: "",
          sku: "",
          detail: "",
          category: "regular",
          us_price: 0,
          ca_price: 0,
          unit: "per location monthly",
        };
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.item) return;
      if (!this.item.name) return this.$toast.error("Name is required.");
      if (!this.item.sku) return this.$toast.error("SKU is required.");
      this.loading = true;
      if (this.item._id) await this.update();
      else await this.create();
      this.loading = false;
    },
    async create() {
      if (!this.item || this.item._id) return;
      try {
        const result = await this.$api.service.item.create(this.item);
        this.addBizServiceItem(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
    },
    async update() {
      if (!this.item || !this.item._id) return;
      const params = {
        criteria: { _id: this.item._id },
        action: {
          $set: {
            name: this.item.name,
            detail: this.item.detail,
            note: this.item.note,
            sort: this.item.sort,
            category: this.item.category,
            us_price: this.item.us_price,
            ca_price: this.item.ca_price,
            unit: this.item.unit,
          },
        },
      };
      try {
        const result = await this.$api.service.item.update(params);
        this.updateBizServiceItem(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
    },
  },
};
</script>
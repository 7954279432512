<template lang="pug">
.my-3
  .link-box
    PayByCheck(:bizorder="bizorder", v-if="!isPaid")
    Email(:bizorder="bizorder", v-if="!isPaid")
    Delete(:bizorder="bizorder", @deleted="$emit('deleted')", v-if="!isPaid")
    Cancel(:bizorder="bizorder", @cancelled="handleCanceled", v-if="isPaid")
  .my-3 Created: {{ bizorder.created | datetime }}
  ClickToCopy(:text="bizorder._id")
</template>

<script>
import Email from "./Email";
import Delete from "./Delete";
import PayByCheck from "./PayByCheck";
import Cancel from "./Cancel";

export default {
  components: { Email, Delete, PayByCheck, Cancel },
  props: ["bizorder"],
  computed: {
    isPaid() {
      return this.bizorder?.status === 1;
    },
  },
  methods: {
    handleCanceled(data) {
      this.$emit("cancelled", data);
    },
  },
};
</script>

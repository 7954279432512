<template lang="pug">
v-menu(offset-y)
  template(v-slot:activator="{ on }")
    a.secondary--text(@click.stop="", v-on="on", color="secondary") {{ title }}
  v-list
    v-list-item(
      v-for="region in items",
      :key="region._id",
      @click="assign(biz, region)"
    )
      v-list-item-title {{ region.name }}
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    biz: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("entp", ["regions", "enterprise"]),
    items() {
      return this.regions
        .filter((o) => o.enterprise == this.enterprise?._id)
        .toSorted((a, b) => a.name.localeCompare(b.name));
    },
    title() {
      return (
        this.regions
          .filter((o) => o.bizs.includes(this.biz._id))
          .map((o) => o.name)
          .toSorted()
          .join(", ") || "Select"
      );
    },
  },
  methods: {
    async assign(biz, region) {
      if (!biz || !region) return;
      this.loading = true;
      try {
        // 1. add add biz to region
        const action = { $addToSet: { bizs: biz._id } };
        const result = await this.$api.entp.region.put(region._id, action);
        this.$store.dispatch("entp/updateRegion", result);
        // 2. remove biz from other regions
        const regions = this.regions.filter(
          (o) => o._id !== region._id && o.bizs.includes(biz._id)
        );
        for (const item of regions) {
          const action = { $pull: { bizs: biz._id } };
          const result = await this.$api.entp.region.put(item._id, action);
          this.$store.dispatch("entp/updateRegion", result);
        }
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
div(v-if="valid")
  v-divider.my-10
  v-btn(
    color="error",
    small,
    outlined,
    @click="handleDelete",
    :loading="loading"
  ) Delete this Post
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    valid() {
      return this.item?._id;
    },
  },
  methods: {
    ...mapActions(["removePressReport"]),
    handleDelete() {
      if (!this.item._id) return;
      confirm("Do you want to delete this press report?") && this.submit();
    },
    async submit() {
      if (!this.item._id) return;
      this.loading = true;
      try {
        const result = await this.$api.corp.pressReport.delete(this.item?._id);
        this.removePressReport(result);
        this.$toast.success("Press report deleted successfully");
        this.$emit("deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
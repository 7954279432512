<template lang="pug">
div
  EnterpriseSelector(@select="select")
  v-divider
  .s-vstack
    v-col(align="center")
      ProfilePicture(:enterprise="enterprise")
    Profile
    Access
</template>

<script>
import ProfilePicture from "/libs/components/Enterprise/Setting/ProfilePicture";
import Profile from "./Profile";
import Access from "./Access";
import { EventBus } from "@/event-bus";

export default {
  components: { ProfilePicture, Profile, Access },
  computed: {
    enterprise() {
      return this.$store.getters["entp/enterprise"];
    },
  },
  mounted() {
    this.$store.dispatch("entp/setEnterprise");
    EventBus.$emit("enterprise:changed");
  },
  methods: {
    select(enterprise) {
      this.$store.dispatch("entp/setEnterprise", enterprise);
      EventBus.$emit("enterprise:changed");
    },
  },
};
</script>
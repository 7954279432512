<template lang="pug">
div
  SettleStat(:mid="mid")
  ListTerminals(:mid="mid")
  TerminalDetails(:mid="mid")
  .link-box
    Auth(:mid="mid")
    VoidCharge(:mid="mid")
    Refund(:mid="mid")
    Inquire(:mid="mid")
</template>

<script>
import Auth from "./Auth";
import Inquire from "./Inquire";
import ListTerminals from "./ListTerminals";
import Refund from "./Refund";
import SettleStat from "./SettleStat";
import TerminalDetails from "./TerminalDetails";
import VoidCharge from "./VoidCharge";

export default {
  components: {
    SettleStat,
    ListTerminals,
    TerminalDetails,
    Auth,
    VoidCharge,
    Refund,
    Inquire,
  },
  props: { mid: { type: String, required: true } },
};
</script>
<template lang="pug">
v-row(wrap, dense)
  v-col(cols="12", md="3")
    Chart(
      :input="countData",
      title="Delivery #",
      :selection="selection",
      :isCurrency="noCurrency"
    )
  v-col(cols="12", md="3")
    Chart(
      :input="driverData",
      title="Driver Payroll",
      :selection="selection",
      :isCurrency="yesCurrency"
    )
  v-col(cols="12", md="3")
    Chart(
      :input="manfeeData",
      title="Manage Payroll",
      :selection="selection",
      :isCurrency="yesCurrency"
    )
  v-col(cols="12", md="3")
    Chart(
      :input="incomeData",
      title="Income",
      :selection="selection",
      :isCurrency="yesCurrency"
    )
</template>

<script>
import _ from "underscore";
import Chart from "./Chart";

export default {
  components: { Chart },
  props: ["input", "selection"],
  data() {
    return {
      yesCurrency: true,
      noCurrency: false,
    };
  },
  computed: {
    countData() {
      const data = JSON.parse(JSON.stringify(this.input));
      return data.map((o) => {
        o.value = o.count;
        return o;
      });
    },
    driverData() {
      const data = JSON.parse(JSON.stringify(this.input));
      return _.map(data, (o) => {
        o.value = Math.round((o.fee + o.tip) * 100) / 100;
        return o;
      });
    },
    manfeeData() {
      const data = JSON.parse(JSON.stringify(this.input));
      return _.map(data, (o) => {
        o.value = o.manfee;
        return o;
      });
    },
    incomeData() {
      const data = JSON.parse(JSON.stringify(this.input));
      return _.map(data, (o) => {
        o.value = o.surcharge;
        return o;
      });
    },
  },
};
</script>

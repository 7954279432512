<template lang="pug">
v-btn(
  @click="remind()",
  v-if="valid",
  :loading="loading",
  outlined,
  small,
  color="secondary"
) Remind
</template>

<script>
export default {
  props: ["item"],
  data() {
    return { loading: false };
  },
  computed: {
    valid() {
      return this.item.to;
    },
  },
  methods: {
    async remind() {
      const gcId = this.item._id;
      if (!gcId) return;
      const params = { gcId };
      this.loading = true;
      try {
        await this.axios.post("/giftcard/remind", params);
        this.$toast.success("Reminded");
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>

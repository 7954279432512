<template lang="pug">
v-list-item(v-if="valid", @click.stop="handleRefundDeliv()") Refund Delivery Fee
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order"],
  computed: {
    valid() {
      return this.order.deliv && this.order.status >= 0;
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleRefundDeliv() {
      confirm(
        "Do you really want to refund the delivery fee and change this order to pick up?"
      ) && this.refundDeliv();
    },
    async refundDeliv() {
      if (!this.order) return;
      const params = { orderId: this.order._id };
      try {
        const result = await this.$api.order.custom("deliv/refund", params);
        this.updateOrder(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

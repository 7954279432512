<template lang="pug">
div(v-if="!item.emailtime")
  v-list-item(@click="handleDelete()") Delete
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  methods: {
    ...mapActions(["removeTaxFile"]),
    handleDelete() {
      confirm("Do you want to delete this file?") && this.deleteone();
    },
    async deleteone() {
      if (!this.item) return;
      const params = { fileid: this.item._id };
      try {
        await this.$api.taxFile.delete(params);
        this.removeTaxFile(this.item);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

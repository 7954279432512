<template lang="pug">
Page(title="Stripe Charges", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Day", url: "/stripecharge/day" },
        { title: "Year", url: "/stripecharge/year" },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  .d-flex.flex-row.justify-center.my-10
    v-card(width="400px")
      v-toolbar(flat, dense)
        .subtitle-2 Import members
      v-card-text
        MemberProgramSelector(@select="selectProgram")
        div(v-if="program")
          .my-5
            .subtitle-2 Read Five Star Members
            input(type="file", label="File", @change="read")
          .my-5
            .subtitle-2 Read Not Your Average Joe
            input(type="file", label="File", @change="readNYAJ")
          .my-5
            .subtitle-2 Read The Simple Brew
            input(type="file", label="File", @change="readSimpleBrew")
          .my-5
            .subtitle-2 Read The Family Fresh
            input(type="file", label="File", @change="readFamilyFresh")
  DataTable(:items="items")
</template>

<script>
import DataTable from "./DataTable";
import MemberProgramSelector from "@/components/basic/MemberProgramSelector";
import papa from "papaparse";
import moment from "moment-timezone";
import _ from "underscore";

export default {
  components: { MemberProgramSelector, DataTable },
  data() {
    return {
      program: null,
      items: [],
      delimiter: ",",
    };
  },
  computed: {
    canReset() {
      return this.items && this.items.length > 0;
    },
  },
  methods: {
    selectProgram(program) {
      this.program = program;
    },
    read(ev) {
      this.items = [];
      const dateToUnix = this.dateToUnix;
      const program = this.program;
      const items = this.items;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        papa.parse(file.target.result, {
          delimiter: this.delimiter,
          worker: true, // Don't bog down the main thread if its a big file
          step: function (result) {
            if (result.data?.length == 9 && result.data[1] != "Phone") {
              let name = result.data[0];
              let first = "";
              let last = "";
              if (name.indexOf("User ") == 0) {
                first = name;
                last = "";
              } else if (name.length > 0) {
                let items = name.split(" ");
                first = items[0];
                if (items.length > 1) {
                  last = _.rest(items).join(" ");
                }
              }
              let amount = result.data[5].replace("pts", "");
              let balance = result.data[4].replace("pts", "");

              const data = {
                program: program._id,
                name: { first: first, last: last },
                created: dateToUnix(result.data[6]),
                phone: result.data[1].replace(/\D+/g, ""),
                email: "",
                amount: parseInt(amount),
                balance: parseInt(balance),
                count: 1,
                last: dateToUnix(result.data[6]),
              };
              if (data.phone && data.phone.length == 10) items.push(data);
            }
          },
          complete: () => {},
        });
      };
      reader.readAsText(file);
    },
    readNYAJ(ev) {
      // read from not average Joe
      this.items = [];
      let items = this.items;
      const dateToUnix = this.dateToUnix;
      const getMMDD = this.getMMDD;
      const program = this.program;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        papa.parse(file.target.result, {
          delimiter: this.delimiter,
          worker: true, // Don't bog down the main thread if its a big file
          step: function (result) {
            if (
              result.data &&
              result.data.length >= 13 &&
              result.data[3] != "Phone"
            ) {
              let name = result.data[1];
              let first = "";
              let last = "";
              if (name.indexOf("User ") == 0) {
                first = name;
                last = "";
              } else if (name.length > 0) {
                let items = name.split(" ");
                first = items[0];
                if (items.length > 1) {
                  last = _.rest(items).join(" ");
                }
              }
              let email = result.data[2];
              let phone = result.data[3].replace(/\D+/g, "");
              let birthday = result.data[5];
              let amount = result.data[9];
              let balance = result.data[11];

              const data = {
                program: program._id,
                name: { first, last },
                created: dateToUnix(result.data[6]),
                phone: phone,
                email: email,
                bd: getMMDD(birthday),
                amount: parseInt(amount),
                balance: parseInt(balance),
                count: parseInt(result.data[8]),
                last: dateToUnix(result.data[7]),
              };
              if (data.phone && data.phone.length == 10) items.push(data);
            }
          },
          complete: () => {},
        });
      };
      reader.readAsText(file);
    },
    readSimpleBrew(ev) {
      // read from not average Joe
      this.items = [];
      let items = this.items;
      const dateToUnix = this.dateToUnix;
      const getMMDD = this.getMMDD;
      const program = this.program;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        papa.parse(file.target.result, {
          delimiter: this.delimiter,
          worker: true, // Don't bog down the main thread if its a big file
          step: function (result) {
            if (
              result.data &&
              result.data.length >= 11 &&
              result.data[2] != "Phone"
            ) {
              let name = result.data[0];
              let first = "";
              let last = "";
              if (name.indexOf("User ") == 0) {
                first = name;
                last = "";
              } else if (name.length > 0) {
                let items = name.split(" ");
                first = items[0];
                if (items.length > 1) {
                  last = _.rest(items).join(" ");
                }
              }
              let email = result.data[1];
              let phone = result.data[2].replace(/\D+/g, "");
              let birthday = result.data[4];
              let amount = result.data[7].replace("$", "");
              let balance = result.data[9];

              const data = {
                program: program._id,
                name: { first, last },
                created: dateToUnix(result.data[5]),
                phone,
                email,
                bd: getMMDD(birthday),
                amount: parseInt(amount),
                balance: parseInt(balance),
                count: 1,
                last: dateToUnix(result.data[6]),
              };
              if (data?.phone?.length == 10) items.push(data);
            }
          },
          complete: () => {},
        });
      };
      reader.readAsText(file);
    },
    readFamilyFresh(ev) {
      this.items = [];
      let items = this.items;
      const dateToUnix = this.dateToUnix;
      const program = this.program;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (file) => {
        papa.parse(file.target.result, {
          delimiter: this.delimiter,
          worker: true, // Don't bog down the main thread if its a big file
          step: function (result) {
            if (
              result.data &&
              result.data.length >= 5 &&
              result.data[2] != "Phone"
            ) {
              let name = result.data[1];
              let first = "";
              let last = "";
              if (name.length > 0) {
                let items = name.split(" ");
                first = items[0];
                if (items.length > 1) {
                  last = _.rest(items).join(" ");
                }
              }
              let phone = result.data[2].replace(/\D+/g, "");
              let amount = result.data[4].replace("$", "");
              let balance = 0;

              const data = {
                program: program._id,
                name: { first: first, last: last },
                created: dateToUnix(result.data[5]),
                phone: phone,
                amount: parseInt(amount),
                balance: parseInt(balance),
                count: 1,
                last: dateToUnix(result.data[6]),
              };
              if (data.phone && data.phone.length == 10) items.push(data);
            }
          },
          complete: () => {},
        });
      };
      reader.readAsText(file);
    },
    dateToUnix(date) {
      if (date == "N/A") return Date.now();
      if (date) return moment(date).unix() * 1000;
      else return moment().unix() * 1000;
    },
    getMMDD(val) {
      let mm = 0;
      let dd = 0;
      if (!val || !val.length) return { mm: mm, dd: dd };
      let items = val.trim().split(" ");
      if (!items || items.length != 2) return { mm: mm, dd: dd };
      switch (items[0]) {
        case "Jan":
          mm = 1;
          break;
        case "Feb":
          mm = 2;
          break;
        case "Mar":
          mm = 3;
          break;
        case "Apr":
          mm = 4;
          break;
        case "May":
          mm = 5;
          break;
        case "Jun":
          mm = 6;
          break;
        case "Jul":
          mm = 7;
          break;
        case "Aug":
          mm = 8;
          break;
        case "Sep":
          mm = 9;
          break;
        case "Oct":
          mm = 10;
          break;
        case "Nov":
          mm = 11;
          break;
        case "Dec":
          mm = 12;
          break;
        default:
          mm = 0;
      }
      dd = parseInt(items[1]);
      return { mm, dd };
    },
  },
};
</script>
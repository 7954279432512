<template lang="pug">
Page(title="Delivery Payouts")
  template(v-slot:header)
    MonthSelector(@select="load")
  List
</template>

<script>
import List from "./List";
import { mapActions } from "vuex";

export default {
  components: { List },
  data() {
    return {
      range: {},
    };
  },
  methods: {
    ...mapActions(["setDeliveryPayouts"]),
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;
      const params = {
        criteria: {
          created: { $gt: this.range.begin, $lte: this.range.end },
        },
      };
      const { data } = await this.axios.post("/delivery/payouts/list", params);
      this.setDeliveryPayouts(data);
    },
  },
};
</script>

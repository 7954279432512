<template lang="pug">
div
  Block(
    v-for="(item, index) in items",
    :key="index",
    :title="item.title",
    :city_url="item.city_url",
    :cate_url="item.cate_url",
    :map_url="item.map_url"
  )
</template>

<script>
import Block from "./Block";

export default {
  components: { Block },
  data() {
    return {
      items: [
        {
          title: "POS Clients",
          city_url: "/bizs/summary/pos_by_city",
          cate_url: "/bizs/summary/pos_by_cate",
          map_url: "/bizs/summary/pos_heatmap",
        },
        {
          title: "Online Clients",
          city_url: "/bizs/summary/online_by_city",
          cate_url: "/bizs/summary/online_by_cate",
          map_url: "/bizs/summary/online_heatmap",
        },
      ],
    };
  },
};
</script>

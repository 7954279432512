<template lang="pug">
.s-vstack
  Table
  ChainEditor(@edit="$emit('edit', $event)")
</template>

<script>
import Table from "./Table";
import ChainEditor from "./ChainEditor/index";

export default {
  components: { Table, ChainEditor },
};
</script>
<template lang="pug">
div
  MarkerMap.mb-3(:geometries="geometries")
  v-data-table(
    :items="sortedItems",
    :headers="headers",
    @click:row="edit",
    role="button",
    sort-by="name",
    mobile-breakpoint=0
  )
    template(v-slot:item.avatar="{ item }")
      v-avatar(@click.stop="editPic(item)")
        img(:src="item.avatar")
    template(v-slot:item.phone="{ item }")
      span.nowrap {{ item.phone | phone }}
    template(v-slot:item.billing="{ item }")
      .link-box.link-box-right
        v-chip(v-if="item.billing.status", x-small, color="success") Bill {{ -item.billing.discount_percentage }}%
        v-icon(:color="addressColor(item)") mdi-map-marker
  UploadForm(ref="form")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import UploadForm from "./../ProfilePicture/UploadForm";

export default {
  components: { UploadForm },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        { text: "Picture", value: "avatar", filterable: false },
        { text: "Name", value: "name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "", value: "billing", align: "end" },
      ],
    };
  },
  computed: {
    geometries() {
      return this.items
        .map((o) => {
          const geo = o.address?.geometry;
          if (geo) return { ...geo, title: o.name };
          return null;
        })
        .filter((o) => o);
    },
    sortedItems() {
      return this.items.map((o) => {
        if (o.profile_picture) {
          o.avatar = process.env.VUE_APP_MEDIAHOST + o.profile_picture;
        }
        return o;
      });
    },
  },
  methods: {
    hasAddress(item) {
      return item.address?.geometry?.lat && item.address?.geometry?.lng;
    },
    addressColor(item) {
      if (this.hasAddress(item)) return "success";
      return "error";
    },
    edit(item) {
      EventBus.$emit("edit-sales-organization", item);
    },
    editPic(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>

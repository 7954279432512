<template lang="pug">
Page(title="Delivery")
  div
    v-btn.mr-2.text-capitalize(
      small,
      rounded,
      outlined,
      @click="handleToday()"
    ) Today
    v-btn.mr-2.text-capitalize(
      small,
      rounded,
      outlined,
      @click="handleThisWeek()"
    ) This Week
    v-btn.mr-2.text-capitalize(
      small,
      rounded,
      outlined,
      @click="handleLastWeek()"
    ) Last Week
    v-btn.mr-2.text-capitalize(
      small,
      rounded,
      outlined,
      @click="handleThisMonth()"
    ) This Month
    v-btn.mr-2.text-capitalize(
      small,
      rounded,
      outlined,
      @click="handleLastMonth()"
    ) Last Month
  v-card.my-3(flat)
    simple-table(v-if="result")
      thead
        tr
          th Country
          th State
          th City
          th All
          th Minor
          th Major
          th Disaster
      tbody
        tr(v-for="(item, index) in result", :key="index")
          td {{ item.country }}
          td {{ item.state }}
          td {{ item.city }}
          td {{ item.count_all }}
          td
            span {{ item.count_minor }}
            span.caption.ml-2 {{ ((item.count_minor / item.count_all) * 100) | number }}%
          td
            span {{ item.count_major }}
            span.caption.ml-2 {{ ((item.count_major / item.count_all) * 100) | number }}%
          td
            span {{ item.count_disaster }}
            span.caption.ml-2 {{ ((item.count_disaster / item.count_all) * 100) | number }}%
  v-card.my-3(flat)
    simple-table(v-if="stats")
      thead
        tr
          th Country
          th State
          th City
          th Count
          th Fee
          th Tip
          th Manager
          th Selflane
      tbody
        tr(v-for="(item, index) in stats", :key="index")
          td {{ item.country }}
          td {{ item.state }}
          td {{ item.city }}
          td {{ item.count }}
          td {{ item.fee | currency }}
          td {{ item.tip | currency }}
          td {{ item.manfee | currency }}
          td {{ item.surcharge | currency }}
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";

export default {
  data() {
    return { result: [], stats: [] };
  },
  methods: {
    handleToday() {
      const begin = moment().startOf("day").valueOf();
      const end = moment().endOf("day").valueOf();
      this.load(begin, end);
    },
    handleThisWeek() {
      const begin = moment().startOf("isoWeek").valueOf();
      const end = moment().endOf("isoWeek").valueOf();
      this.load(begin, end);
    },
    handleLastWeek() {
      const begin = moment().subtract(1, "weeks").startOf("isoWeek").valueOf();
      const end = moment().subtract(1, "weeks").endOf("isoWeek").valueOf();
      this.load(begin, end);
    },
    handleThisMonth() {
      const begin = moment().startOf("month").valueOf();
      const end = moment().endOf("month").valueOf();
      this.load(begin, end);
    },
    handleLastMonth() {
      const begin = moment().subtract(1, "month").startOf("month").valueOf();
      const end = moment().subtract(1, "month").endOf("month").valueOf();
      this.load(begin, end);
    },
    async load(begin, end) {
      if (!begin || !end) return;
      this.loadStats(begin, end);
      const params = { begin: begin, end: end };
      const url = "/delivOrders/QA/issue_tracker/range";
      const { data } = await this.axios.post(url, params);
      this.result = _.chain(data)
        .sortBy("city")
        .sortBy("state")
        .sortBy("country")
        .value();
    },
    async loadStats(begin, end) {
      if (!begin || !end) return;
      const params = { begin: begin, end: end };
      const url = "/delivOrders/stats/range";
      const { data } = await this.axios.post(url, params);
      this.stats = _.chain(data)
        .sortBy("city")
        .sortBy("state")
        .sortBy("country")
        .value();
    },
  },
};
</script>

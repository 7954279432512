<template lang="pug">
simple-table(v-if="orders")
  thead
    tr
      th Date
      th Biz
      th Total
      th 
  tbody
    tr(
      v-for="(item, index) in orders",
      :key="index",
      dense,
      @click="showOrder(item._id)",
      role="button"
    )
      td {{ item.needed | date }}
      td {{ item.seller.name }}
      td {{ item.payment.total | currency }}
      td.text-right
        v-icon.mr-2(small, color="success", v-if="item.type == 'delivery'") mdi-truck
        v-icon(small, color="success") {{ item.proxy | proxy }}
  tfoot
    tr
      td Total
      td {{ orders.length }}
      td {{ total | currency }}
      td 
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["orders"],
  computed: {
    total() {
      return this.orders?.reduce((a, b) => a + b.payment.total, 0) || 0;
    },
  },
  methods: {
    showOrder(orderId) {
      EventBus.$emit("show-order-detail", orderId);
    },
  },
};
</script>

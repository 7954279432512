<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(dense, flat)
    v-toolbar(flat, dense)
      .subtitle-2 Printer Action Log
    simple-table
      thead
        tr
          th Printer
          th Action
          th.text-right Time
      tbody
        tr(v-for="(item, index) in items", :key="index")
          th {{ item.tag }}
          td {{ item.action }}
          td.text-right {{ item.created | datetime("smart") }}
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      actions: [],
    };
  },
  computed: {
    // sort from newest to oldest
    items() {
      return this.actions.toSorted((a, b) => b.created - a.created);
    },
  },
  methods: {
    async load(uuid) {
      if (!uuid) return;
      const params = { criteria: { uuid: uuid } };
      try {
        this.actions = await this.$api.posPrinterAction.list(params);
        this.dialog = true;
      } catch (e) {
        //
      }
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card(v-if="person")
    v-card-title {{ title }}
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col(cols="6")
            v-text-field(v-model="person.first_name", label="First Name")
          v-col(cols="6")
            v-text-field(v-model="person.last_name", label="Last Name")
        PhoneField(v-model="person.phone")
        v-text-field(
          v-model="person.email",
          label="Email",
          :disabled="hasUser"
        )
        GoogleAddressField(v-model="person.address")
        v-select(
          v-model="person.org_access",
          :items="access_items",
          label="Access",
          :disabled="isMe",
          v-if="isInternal || isAdmin"
        )
        v-select(
          v-model="person.organization",
          :items="organizations",
          label="Organization",
          :disabled="!isInternal"
        )
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "EditSalesPerson",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      person: null,
      app_name: process.env.VUE_APP_NAME,
      access_items: [
        { text: "Admin", value: 1 },
        { text: "Sales", value: 2 },
      ],
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations", "salesPerson"]),
    title() {
      return this.person?._id ? "Edit Agent" : "Create Agent";
    },
    isMe() {
      return this.salesPerson?._id == this.person?._id && !!this.person?._id;
    },
    isAdmin() {
      return this.salesPerson?.org_access == 1;
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    hasUser() {
      return !!this.person.user;
    },
    valid() {
      return (
        !!this.person.email &&
        !!this.person.first_name &&
        !!this.person.last_name
      );
    },
    organizations() {
      return this.salesOrganizations.map((o) => {
        return { text: o.name, value: o._id };
      });
    },
  },
  mounted() {
    EventBus.$on("edit-sales-person", this.open);
  },
  destroyed() {
    EventBus.$off("edit-sales-person", this.open);
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.person = JSON.parse(JSON.stringify(data));
      // migrate name to first_name and last_name
      if (
        !this.person.first_name &&
        !this.person.last_name &&
        this.person.name
      ) {
        const items = this.person.name.split(" ") || [];
        this.person.first_name = items.shift() || "";
        this.person.last_name = items.join(" ") || "";
      }
      this.person.first_name = this.person.first_name || "";
      this.person.last_name = this.person.last_name || "";
    },
    async submit() {
      if (!this.person) return;
      if (!this.person.org_access) {
        this.$toast.error("Missing Access");
        return;
      }
      const action = {
        first_name: this.person.first_name,
        last_name: this.person.last_name,
        phone: this.person.phone,
        email: this.person.email,
        address: this.person.address,
        org_access: this.person.org_access,
        organization: this.person.organization,
      };
      this.loading = true;
      try {
        if (this.person._id) {
          const result = await this.$api.salesforce.person.put(
            this.person._id,
            action
          );
          this.$store.dispatch("updateSalesPerson", result);
        } else {
          const result = await this.$api.salesforce.person.create(action);
          this.$store.dispatch("addSalesPerson", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update sales person");
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
.d-flex.flex-row.align-center.py-2.px-4
  .subtitle-2 Users & Accesses
  v-spacer
  v-btn(color="secondary", outlined, rounded, x-small, @click="create()")
    v-icon(left, small) mdi-plus
    span.text-capitalize Account
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    create() {
      EventBus.$emit("edit-chain-access");
    },
  },
};
</script>
<template>
  <div :style="myStyles">
    <Pie
      :data="chartData"
      :options="options"
    />
  </div>
</template>

<script>
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  Title,
  Tooltip,
} from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: { Pie },
  props: {
    chartData: { type: Object, required: true },
    options: {
      type: Object,
      default: () => ({
        layout: { padding: 12 },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "left",
            labels: { boxWidth: 12, font: { size: 12 } },
          },
        },
      }),
    },
    height: { type: Number, default: 150 },
  },
  computed: {
    myStyles() {
      return {
        width: "100%",
        height: this.height + "px",
      };
    },
  },
};
</script>

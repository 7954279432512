<template lang="pug">
div
  v-card(flat, v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 Tax Rates
      v-spacer
      IconBtn(@click="create()", title="Add", icon="mdi-plus")
    Table
  Form
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Form from "./Form";
import Table from "./Table";

export default {
  components: { Table, Form },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    create() {
      const item = {
        name: "",
        percentage: 0,
        type: 0,
        status: true,
        courses: [],
      };
      EventBus.$emit("edit-tax-item", item);
    },
  },
};
</script>
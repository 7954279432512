import BizAccount from './BizAccount/index.vue'
import Device from './Device/index.vue'
import Documents from './Documents/index.vue'

import biz from './Biz/route'
import enterprise from './Enterprise/route'
import grouping from './Grouping/route'
import growth from './Growth/route'

export default [
  { path: '/bizaccount', component: BizAccount, meta: { auth: 'corp' } },
  { path: '/biz/documents', component: Documents, meta: { auth: 'corp' } },
  { path: '/biz/device', component: Device, meta: { auth: 'corp' } },
  ...biz, ...enterprise, ...grouping, ...growth,
]
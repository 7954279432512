<template lang="pug">
simple-table(v-if="report")
  thead
    tr
      th Payouts Report
      th
  tbody
    tr
      td Last Payout
      td.text-right {{ report.lastCreated | date }}
    tr
      td # payouts
      td.text-right {{ report.count }}
    tr
      td Total
      td.text-right {{ (report.deduct + report.amount) | currency }}
    tr
      td Bank Fee
      td.text-right {{ report.deduct | currency }}
    tr
      td Deposit
      td.text-right {{ report.amount | currency }}
</template>

<script>
export default {
  props: ["report"],
};
</script>

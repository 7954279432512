const state = {
  reviews: []
}

const getters = {
  reviews: state => state.reviews
}

const actions = {
  setReviews: ({ commit }, data) => { commit('setReviews', data) },
  updateReview: ({ commit }, data) => { commit('updateReview', data) },
  removeReview: ({ commit }, data) => { commit('removeReview', data) }
}

const mutations = {
  setReviews(state, data) { state.reviews = data },
  updateReview(state, data) {
    state.reviews = state.reviews.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeReview(state, data) {
    state.reviews = state.reviews.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
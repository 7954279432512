<template lang="pug">
v-data-table(:items="users", :headers="headers", v-if="users && users.length")
  template(v-slot:item.action="{ item }")
    v-btn(small, color="secondary", depressed, @click="assign(item)") assign
</template>

<script>
export default {
  props: ["users"],
  data() {
    return {
      headers: [
        { text: "Email", value: "email" },
        { text: "Actions", value: "action" },
      ],
    };
  },
  methods: {
    assign(user) {
      if (!user) return;
      this.$emit("assign-user", user);
    },
  },
};
</script>

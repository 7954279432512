<template lang="pug">
div
  v-text-field(label="Search Email/Stripe ID", v-model="search", clearable)
  v-data-table(
    :items="userAccounts",
    :headers="headers",
    :search="search",
    :sort-by="sort_by_items",
    sort-desc,
    dense,
    mobile-breakpoint=0,
    @click:row="showUser",
    role="button"
  )
    template(v-slot:item.email="{ item }")
      .link-box
        v-icon(x-small) {{ getIcon(item) }}
        span {{ item.email | truncate }}
    template(v-slot:item.created="{ item }")
      span {{ item.created | datetime("smart") }}
    template(v-slot:item.status.verified="{ item }")
      span(v-if="item.status.verified") {{ item.status.verified | datetime("smart") }}
      span.red--text(v-else) XXX ({{ item.status.activationCode }})
    template(v-slot:item.action="{ item }")
      v-menu(offset-y)
        template(v-slot:activator="{ on }")
          v-btn(icon, small, v-on.stop="on")
            v-icon(color="secondary") mdi-dots-horizontal
        v-list.py-0(dense)
          v-list-item(
            @click="handleResend(item.email)",
            v-if="!item.status.verified"
          ) Resend Verification
          v-list-item(@click="showOrders(item._id)") Show Orders
          v-list-item(@click="assignGiftCard(item._id)") Assign Gift Card
          v-list-item(@click="handleDelete(item)") Delete Account
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      sort_by_items: ["status.verified", "status.created"],
      headers: [
        { text: "Email", value: "email" },
        { text: "Stripe ID", value: "payments.customer" },
        { text: "Created", value: "created", filterable: false },
        { text: "Verified", value: "status.verified", filterable: false },
        { text: "Action", value: "action", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["userAccounts"]),
  },
  methods: {
    ...mapActions(["removeUserAccount"]),
    getIcon(user) {
      if (user.apple?.id) return "mdi-food-apple";
      if (user.google?.id) return "mdi-google";
      return "mdi-email";
    },
    showOrders(userId) {
      if (!userId) return;
      EventBus.$emit("list-order-by-userid", userId);
    },
    showUser(user) {
      if (!user) return;
      EventBus.$emit("show-user-detail", user._id);
    },
    async assignGiftCard(userId) {
      if (!userId) return;
      const { data } = await this.axios.post("/users/assignGiftCard", {
        userId,
      });
      this.$toast.success(data);
    },
    handleResend(email) {
      confirm("Do you want resend confirmation email") && this.resend(email);
    },
    resend(email) {
      this.axios.post("/users/resendVerification", { email });
    },
    handleDelete(user) {
      confirm("Do you really want to delete this user?") &&
        this.deleteOne(user);
    },
    async deleteOne(user) {
      if (!user) return;
      const params = { userId: user._id };
      try {
        await this.axios.post("/users/deleteOne", params);
        this.removeUserAccount(user);
      } catch (err) {
        this.$toast.error(err.response.data);
      }
    },
  },
};
</script>
<template lang="pug">
.growth-box
  YearSelector(@select="select")
  StandardChart.my-3(:input="userData", chartType="bar")
  StandardChart.my-5(:input="starData", chartType="bar")
</template>
<script>
import _ from "underscore";

export default {
  data() {
    return {
      userData: null,
      starData: null,
    };
  },
  methods: {
    async select(year) {
      if (!year) return;
      const data = await this.$api.review.custom("stats/weekly", { year });
      this.result = _.chain(data).sortBy("week").sortBy("year").value();
      const labels = this.result.map((o) => `${o.week}`);
      const counts = this.result.map((o) => o.count);
      const stars = _.map(this.result, (o, index) => {
        if (counts[index])
          return Math.round((o.stars / counts[index]) * 10) / 10;
        else return 0;
      });
      this.userData = { title: "#Review / Week", labels, values: counts };
      this.starData = { title: "#Rating / Week", labels, values: stars };
    },
  },
};
</script>

<style lang="sass" scoped>
.growth-box
  max-width: 500px
  width: 95%
  margin-left: auto
  margin-right: auto
</style>
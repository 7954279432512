<template lang="pug">
div
  v-btn(icon, small, v-if="charge", @click="handleEdit()")
    v-icon(small, color="secondary") mdi-currency-usd
  v-dialog(v-model="dialog", width="500")
    v-card
      v-toolbar(dense, flat)
        .subtitle-2 Edit Charge
      v-form(@submit.prevent="submit")
        v-card-text
          div Base: {{ paymentBase | currency }}
          div Total: {{ paymentTotal | currency }}
          v-row(wrap)
            v-col(cols="6")
              v-text-field(label="Base", v-model="base")
            v-col(cols="6")
              v-text-field(label="Tip", v-model="tip")
            v-col(cols="6")
              v-text-field(label="Adjustment", v-model="adjust_amount")
            v-col(cols="6")
              v-text-field(label="Total", v-model="amount")
          v-btn(type="submit", block, color="secondary", :loading="loading") Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["order", "charge"],
  data() {
    return {
      dialog: false,
      base: 0,
      tip: 0,
      adjust_amount: 0,
      amount: 0,
      loading: false,
    };
  },
  computed: {
    paymentBase() {
      const dish = this.order?.payment?.dish || 0;
      const tax = this.order?.payment?.tax || 0;
      return dish + tax;
    },
    paymentTotal() {
      return this.order?.payment?.total || 0;
    },
  },
  methods: {
    ...mapActions(["updateOrder"]),
    handleEdit() {
      if (!this.charge) return;
      this.dialog = true;
      this.base = this.charge.base;
      this.tip = this.charge.tip;
      this.adjust_amount = this.charge.adjust_amount;
      this.amount = this.charge.amount;
    },
    async submit() {
      if (!this.order || !this.charge || !this.base || !this.amount) return;
      this.loading = true;
      const params = {
        criteria: {
          _id: this.order._id,
          "payment.charges._id": this.charge._id,
        },
        action: {
          $set: {
            "payment.charges.$.base": this.base,
            "payment.charges.$.tip": this.tip,
            "payment.charges.$.adjust_amount": this.adjust_amount,
            "payment.charges.$.amount": this.amount,
          },
        },
      };
      try {
        const result = await this.$api.order.update(params);
        this.updateOrder(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

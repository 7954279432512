<template lang="pug">
simple-table
  tbody
    tr
      th API Key
      td
        .d-flex.flex-row.align-center
          span {{ item.third_party.chowly_id }}
          v-btn(icon, small, @click="$emit('open')", color="secondary")
            v-icon(small) mdi-pencil
    tr
      th Store ID
      td {{ item.third_party.store_id }}

    tr
      th Test URL
      td
        ClickToCopy(:text="item.third_party.test_url")
    tr
      th Combine Menus
      td {{ item.third_party.merge_menu ? "Yes" : "No" }}
    tr
      th Last Updated
      td {{ item.third_party.last_sync | date({ dynamic: true }) }}
    tr
      th Note
      td {{ item.third_party.message }}
</template>

<script>
export default {
  props: {
    item: { type: Object, required: true },
  },
};
</script>
<template lang="pug">
v-icon(:color="color", small) mdi-earth
</template>

<script>
export default {
  props: {
    biz: { type: Object, required: true },
  },
  computed: {
    isOnline() {
      return this.biz?.status?.byAdmin == 1;
    },
    color() {
      return this.isOnline ? "success" : "error";
    },
  },
};
</script>
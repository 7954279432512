<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="createdAt",
  :sort-desc="true"
)
  template(v-slot:item.createdAt="{ item }")
    span {{ item.createdAt | datetime }}
</template>

<script>
export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        { text: "Category", value: "category" },
        { text: "Description", value: "description" },
        { text: "Time", value: "createdAt", align: "end" },
      ],
    };
  },
};
</script>
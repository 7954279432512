<template lang="pug">
v-select(v-model="content", :items="items", label="Tags", multiple)
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      content: this.value,
      items: ["Overnight", "Food Truck"],
    };
  },
  watch: {
    value(val) {
      this.content = val;
    },
    content() {
      this.$emit("input", this.content);
    },
  },
};
</script>
<template lang="pug">
div
  v-btn.mr-2(
    small,
    rounded,
    depressed,
    color="secondary",
    @click="handleAdd()"
  ) Create
  v-dialog(width="400", v-model="dialog")
    .sl-plain-card
      .sl-title Create an Invoice
      BizSelector(@select="selectBiz")
      v-layout(v-if="selectedBiz")
        MonthSelector(@select="selectMonth")
        v-spacer
        v-btn(small, depressed, round, color="secondary", @click="create()") Create
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CreateInvoice",
  data: () => ({
    dialog: false,
    selectedBiz: null,
    month: 0,
    year: 0,
  }),
  methods: {
    ...mapActions(["addInvoice"]),
    handleAdd() {
      this.dialog = true;
      this.selectedBiz = null;
    },
    reset() {
      this.dialog = false;
      this.month = 0;
      this.year = 0;
      this.selectedBiz = null;
    },
    selectBiz(biz) {
      this.selectedBiz = biz;
    },
    selectMonth(data) {
      if (!data || !data.name) return;
      const items = data.name.split("-");
      if (!items || items.length != 2) return;
      this.year = parseInt(items[0]);
      this.month = parseInt(items[1]);
    },
    async create() {
      if (!this.selectedBiz || !this.selectedBiz._id) return;
      if (!this.year || !this.month) return;
      const params = {
        bizId: this.selectedBiz._id,
        period: {
          year: this.year,
          month: this.month,
        },
      };
      try {
        const { data } = await this.axios.post("/invoices/create", params);
        this.addInvoice(data);
        this.reset();
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to create invoice");
      }
    },
  },
};
</script>

<template lang="pug">
div(style="height: 100%; overflow-y: auto")
  v-subheader Interactions
  Form(:lead="lead", :opportunity="opportunity", @update="update")
  Cell(
    v-for="interaction in items",
    :key="interaction._id",
    :interaction="interaction"
  )
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";
import Form from "./Form";

export default {
  components: { Form, Cell },
  props: {
    lead: { type: Object, default: null },
    opportunity: { type: Object, default: null },
  },
  computed: {
    ...mapGetters("crm", ["interactions"]),
    items() {
      if (!this.lead && !this.opportunity) return [];
      if (this.lead) {
        return this.interactions
          .filter((i) => i.lead === this.lead._id)
          .toSorted((a, b) => b.created - a.created); // sort from newest to oldest
      } else {
        return this.interactions
          .filter((i) => i.opportunity === this.opportunity._id)
          .toSorted((a, b) => b.created - a.created); // sort from newest to oldest
      }
    },
  },
  methods: {
    update($event) {
      this.$emit("update", $event);
    },
  },
};
</script>
<template lang="pug">
div
  v-text-field(
    v-model="search",
    label="Filter Result",
    clearable,
    solo,
    hide-details,
    flat,
    dense
  )
  Table.my-3(:items="groups.named", :search="search", title="Named Accounts")
  Table(:items="groups.unnamed", :search="search", title="Unnamed Accounts")
</template>

<script>
import Table from "./Table.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Table },
  data() {
    return { search: "" };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    groups() {
      const named = [];
      const unnamed = [];
      this.bizAccounts.forEach((o) => {
        if (o.payments?.statement_descriptor) named.push(o);
        else unnamed.push(o);
      });
      return { named, unnamed };
    },
  },
  mounted() {
    this.load();
  },
  destroyed() {
    this.setBizAccounts([]);
  },
  methods: {
    ...mapActions(["setBizAccounts"]),
    async load() {
      const params = {
        criteria: {
          $and: [
            { "payments.managed_account": { $exists: true, $ne: "" } },
            { "payments.managed_account": { $ne: null } },
          ],
        },
        select: "name address payments",
      };
      const result = await this.$api.biz.list(params);
      this.setBizAccounts(result);
    },
  },
};
</script>

<template lang="pug">
v-card(flat, v-if="enterprise")
  v-toolbar(flat, dense)
    .subtitle-2 Profile
    v-spacer
    v-btn(icon, small, color="secondary", @click="edit")
      v-icon(small) mdi-pencil
  v-list(dense)
    v-list-item
      v-list-item-content
        v-list-item-title Name
        v-list-item-subtitle {{ enterprise.name }}
    v-divider
    v-list-item
      v-list-item-content
        v-list-item-title Address
        v-list-item-subtitle {{ enterprise.address | address }}
  Form(ref="form")
</template>

<script>
import Form from "/libs/components/Enterprise/Setting/Form";
import { mapGetters } from "vuex";

export default {
  components: { Form },
  computed: { ...mapGetters("entp", ["enterprise"]) },
  methods: {
    edit() {
      this.$refs.form.open(this.enterprise);
    },
  },
};
</script>
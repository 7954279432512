const state = {
  influencers: [],
  influencerPlans: []
};

const getters = {
  influencers: state => state.influencers,
  influencerPlans: state => state.influencerPlans
};

const actions = {
  setInfluencers: ({ commit }, data) => { commit("setInfluencers", data); },
  updateInfluencer: ({ commit }, data) => { commit("updateInfluencer", data); },
  addInfluencer: ({ commit }, data) => { commit("addInfluencer", data); },
  removeInfluencer: ({ commit }, data) => { commit("removeInfluencer", data); },
  setInfluencerPlans: ({ commit }, data) => { commit("setInfluencerPlans", data); },
};

const mutations = {
  setInfluencers(state, data) {
    state.influencers = data;
  },
  updateInfluencer(state, data) {
    state.influencers = state.influencers.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addInfluencer(state, data) {
    state.influencers.push(data);
  },
  removeInfluencer(state, data) {
    state.influencers = state.influencers.filter(o => o._id !== data._id);
  },
  setInfluencerPlans(state, data) {
    state.influencerPlans = data;
  },
};

export default { state, getters, actions, mutations };
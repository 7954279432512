<template lang="pug">
div
  v-btn.mr-2(
    small,
    rounded,
    depressed,
    color="secondary",
    @click="submit()",
    :loading="loading"
  ) Migrate Bill
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const { data } = await this.axios.post("/invoices/migrateBillings");
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

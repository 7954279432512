<template lang="pug">
v-row(wrap, justify="center")
  simple-table.ma-5(v-if="orders")
    tbody
      tr(
        v-if="proxies",
        v-for="(item, index) in proxies",
        :key="index + 'proxy'"
      )
        td {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.count }}
      tr(
        v-if="values",
        v-for="(item, index) in values",
        :key="index + 'value'"
      )
        td.text-capitalize {{ item.name }}
        td {{ item.value | currency }}
        td {{ item.count }}
      tr
        td Total
        td {{ totalvalue | currency }}
        td {{ orders.length }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["orders"]),
    totalvalue() {
      return _.reduce(
        this.orders,
        (memo, item) => {
          return memo + item.payment.total;
        },
        0
      );
    },
    values() {
      if (!this.orders) return [];
      return _.chain(this.orders)
        .groupBy("type")
        .map((list, k) => {
          return {
            name: k,
            count: list.length,
            value: _.reduce(
              list,
              (memo, item) => {
                return memo + item.payment.total;
              },
              0
            ),
          };
        })
        .sortBy("name")
        .value();
    },
    proxies() {
      if (!this.orders) return [];
      return _.chain(this.orders)
        .groupBy("proxy")
        .map((list, k) => {
          return {
            name: k,
            count: list.length,
            value: _.reduce(
              list,
              (memo, item) => {
                return memo + item.payment.total;
              },
              0
            ),
          };
        })
        .sortBy("name")
        .value();
    },
  },
};
</script>

<template lang="pug">
v-btn.text-capitalize(@click="handleRemind()") Remind Payment Setup
</template>

<script>
export default {
  props: ["driver"],
  methods: {
    handleRemind() {
      confirm("Do you want to email a remind?") && this.remind();
    },
    async remind() {
      if (!this.driver) return;
      const params = { driverId: this.driver._id };
      try {
        const { data } = await this.axios.post(
          "/driver/accounts/mail/remindPayment",
          params
        );
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

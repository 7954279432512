<template lang="pug">
div
  v-text-field(v-model="searchText", label="Search")
  v-data-table(
    :items="driverRewardPenalties",
    :headers="headers",
    :search="searchText",
    dense,
    @click:row="edit",
    role="button"
  )
    template(v-slot:item.amount="{ item }")
      span {{ item.amount | currency }}
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.action="{ item }")
      v-btn(
        icon,
        v-if="!item.driverPayout",
        color="error",
        @click.stop="handleDelete(item)"
      )
        v-icon(small) mdi-delete
      v-btn(icon, v-if="item.order", @click.stop="showOrder(item)")
        v-icon(small) mdi-information
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "driverName" },
        { text: "Type", value: "type" },
        { text: "Zone", value: "zone" },
        { text: "Amount", value: "amount" },
        { text: "Order", value: "order" },
        { text: "Deliv Order", value: "delivOrder" },
        { text: "Payout", value: "driverPayout" },
        { text: "Created", value: "created", align: "end" },
        { text: "Action", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["driverRewardPenalties"]),
  },
  methods: {
    ...mapActions(["removeRewardPenalty"]),
    edit(item) {
      EventBus.$emit("edit-driver-rewardpenalty", item);
    },
    handleDelete(item) {
      confirm("Do you want to delete this record?") && this.deleteOne(item);
    },
    async deleteOne(item) {
      const params = { id: item._id };
      const { data } = await this.axios.post(
        "/driver/rewardPenalties/deleteOne",
        params
      );
      this.removeRewardPenalty(data);
    },
    showOrder(item) {
      if (!item.order) return;
      EventBus.$emit("show-order-detail", item.order);
    },
  },
};
</script>
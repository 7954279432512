<template lang="pug">
CountTableChart(name="Category", :items="items", :loading="loading")
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      result: [],
      loading: false,
    };
  },
  computed: {
    items() {
      const cates = this.$options.filters.cates;
      return this.result.map((o) => {
        const name = cates([o.cate]);
        return { name, count: o.count };
      });
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const { data } = await this.axios.post(this.url);
      this.result = data;
      this.loading = false;
    },
  },
};
</script>

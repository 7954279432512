<template lang="pug">
v-list-item.red--text(@click="handleRemove()") Delete Invoice
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["invoice"],
  methods: {
    ...mapActions(["removeInvoice"]),
    handleRemove() {
      confirm("Are you sure to delete this invoice?") && this.remove();
    },
    async remove() {
      if (!this.invoice) return;
      const params = { invoiceId: this.invoice._id };
      try {
        await this.axios.post("/invoices/remove", params);
        this.removeInvoice(this.invoice);
        this.$toast.success("Invoice deleted");
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to delete invoice");
      }
    },
  },
};
</script>

<!--
  Load up payments made to either an invoice or an organization.
-->

<template lang="pug">
div
  YearSelector(@select="load")
  v-card.my-5(flat)
    v-toolbar(flat, dense)
      .subtitle-2 Duplicated Payments ({{ dups.length }}, {{ total | currency }})
    simple-table
      thead
        tr
          th Date
          th Invoice ID
          th Count
          th Methods
          th IDs
          th Amount
      tbody
        tr(v-for="(item, index) in dups", :key="index")
          td {{ item.time | date }}
          td
            v-btn(text, x-small, @click="showInvoice(item.invoice_id)") {{ item.invoice_id }}
          td {{ item.count }}
          td {{ item.methods }}
          td {{ item.ids }}
          td {{ item.amount | currency }}
  InvoiceDetail
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      items: [],
    };
  },
  computed: {
    dups() {
      return _.chain(this.items)
        .filter((v) => v.invoice_id)
        .groupBy("invoice_id")
        .filter((v) => v.length > 1)
        .map((v) => {
          return {
            time: v[0].created,
            invoice_id: v[0].invoice_id,
            count: v.length,
            items: v,
            methods: v.map((o) => o.stripe_type).join(", "),
            ids: v.map((o) => o.stripe_id).join(", "),
            amount: v.reduce((a, b) => a + b.selflane_net, 0),
          };
        })
        .value();
    },
    total() {
      return this.dups?.reduce((a, b) => a + b.amount, 0);
    },
  },
  methods: {
    async load(year) {
      const begin = new Date(year, 0, 1).valueOf();
      const end = new Date(year + 1, 0, 1).valueOf();
      const params = {
        criteria: {
          $or: [
            { invoice_id: { $exists: true } },
            { org_invoice_id: { $exists: true } },
          ],
          created: { $gte: begin, $lt: end },
        },
      };
      this.items = await this.$api.transaction.stripe.list(params);
    },
    showInvoice(id) {
      EventBus.$emit("show-invoice-detail", id);
    },
  },
};
</script>
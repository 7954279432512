<template lang="pug">
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setSalesOrganizations"]),
    async load() {
      const params = { criteria: {} };
      try {
        const result = await this.$api.salesforce.organization.list(params);
        this.setSalesOrganizations(result);
      } catch (e) {
        this.setSalesOrganizations([]);
      }
    },
  },
};
</script>
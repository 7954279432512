<template lang="pug">
.s-vstack(v-if="enterprise")
  Locations(@saved="loadBizs()", :bizs="bizs")
  Regions(:bizs="bizs")
  Chains
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import Chains from "./Chains/index";
import Locations from "./Locations/index.vue";
import Regions from "./Regions/index";

export default {
  name: "EnterpriseRegion",
  components: { Locations, Regions, Chains },
  data() {
    return {
      bizs: [], // list of bizs that are associated with this enterprise
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise"]),
  },
  mounted() {
    this.load();
    EventBus.$on("enterprise:changed", this.load);
  },
  destroyed() {
    EventBus.$off("enterprise:changed", this.load);
  },
  methods: {
    async load() {
      await this.refreshEnterprise();
      await this.loadRegions();
      await this.loadChains();
      await this.loadBizs();
    },
    async refreshEnterprise() {
      if (!this.enterprise) return;
      try {
        const result = await this.$api.entp.enterprise.get(this.enterprise._id);
        this.$store.dispatch("entp/updateEnterprise", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async loadRegions() {
      if (!this.enterprise) return;
      try {
        const params = {
          criteria: { enterprise: this.enterprise._id },
        };
        const result = await this.$api.entp.region.list(params);
        this.$store.dispatch("entp/setRegions", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async loadChains() {
      if (!this.enterprise) return;
      try {
        const params = {
          criteria: { enterprise: this.enterprise._id },
        };
        const result = await this.$api.chain.account.list(params);
        this.$store.dispatch("entp/setChains", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    async loadBizs() {
      if (!this.enterprise?.bizs?.length) return;
      try {
        const params = {
          criteria: { _id: { $in: this.enterprise.bizs } },
          select: "name short_name address status schedule enterprise",
        };
        const result = await this.$api.biz.list(params);
        // sort bizs by 1. state, 2. city, 3. name
        this.bizs = result.toSorted((a, b) => {
          if (a.address?.state && !b.address?.state) return -1;
          if (!a.address?.state && b.address?.state) return 1;
          if (a.address?.state && b.address?.state) {
            if (a.address?.state !== b.address?.state)
              return a.address?.state.localeCompare(b.address?.state);
          }
          if (a.address?.city && !b.address?.city) return -1;
          if (!a.address?.city && b.address?.city) return 1;
          if (a.address?.city && b.address?.city) {
            if (a.address?.city !== b.address?.city)
              return a.address?.city.localeCompare(b.address?.city);
          }
          return a.name.localeCompare(b.name);
        });
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load bizs");
      }
    },
  },
};
</script>

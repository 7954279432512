<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="city")
    v-toolbar(dense, flat)
      .subtitle-2 Geo Service Zone
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense)
          v-col(cols="6")
            v-text-field(label="City", v-model="city.city")
          v-col(cols="6")
            v-text-field(label="State", v-model="city.state")
        .d-flex.flex-row.justify-space-between.align-center.mb-3
          span(v-if="city.geometry") {{ city.geometry.lat }}, {{ city.geometry.lng }}
          v-btn(@click.stop="loadGeo()", outlined, rounded, small) Load Geo
        #map.mb-3(ref="map")
        v-btn(color="secondary", type="submit", :loading="loading", block) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import MapUtils from "/libs/utils/map";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      city: null,
    };
  },
  mounted() {
    EventBus.$on("edit-city", this.open);
  },
  destroyed() {
    EventBus.$off("edit-city", this.open);
  },
  methods: {
    ...mapActions(["updateCity"]),
    open(data) {
      if (!data) return;
      if (data) {
        this.city = JSON.parse(JSON.stringify(data));
      }
      this.dialog = true;
      const initMap = this.initMap;
      let center = null;
      if (this.city.geometry && this.city.geometry.lat) {
        center = this.city.geometry;
      }
      setTimeout(() => {
        initMap(center);
      }, 500);
    },
    async submit() {
      if (!this.city) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.city._id },
        action: this.city,
      };
      try {
        const result = await this.$api.city.update(params);
        this.updateCity(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    loadGeo() {
      if (!this.city) return;
      const address = {
        line1: "",
        city: this.city.city,
        state: this.city.state,
        country: this.city.country,
      };
      MapUtils.getGeoCode(address).then((response) => {
        if (response?.data?.results?.length) {
          this.city.geometry = response.data.results[0].geometry.location;
          this.drawMarker();
        }
      });
    },
    drawMarker() {
      if (!this.city || !this.city.geometry || !this.city.geometry.lat) return;
      // eslint-disable-next-line no-undef
      new google.maps.Marker({
        position: this.city.geometry,
        map: this.map,
      });
      this.map.panTo(this.city.geometry);
    },
    initMap(input) {
      var center = input;
      if (!center) {
        center = { lat: 35.0844, lng: -106.6504 };
      }
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 11,
      });
      this.drawMarker();
    },
  },
};
</script>


<style lang="sass" scoped>
#map
  height: 300px
  width: 100%
  background: gray
</style>
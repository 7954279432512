<template lang="pug">
.d-flex.flex-row.align-center
  .subtitle-2 {{ service?.name }}
  v-btn.ml-2(
    @click="submit",
    color="secondary",
    icon,
    small,
    :loading="loading"
  )
    v-icon(small) mdi-refresh
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["service"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["updateBizService"]),
    async submit() {
      if (!this.service) return;
      this.loading = true;
      const params = { bizId: this.service.biz };
      try {
        const result = await this.$api.service.account.custom(
          "populateBiz",
          params
        );
        this.updateBizService(result);
        this.$toast.success("Populated");
      } catch (err) {
        this.$toast.error(err.response.data);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
.head
  .title {{ bizorder.name }}
  .detail {{ bizorder.address | address }}
  .detail {{ bizorder.created | datetime }}
  .detail.red--text(v-if="bizorder.status < 0") Cancelled
</template>

<script>
export default {
  props: ["bizorder"],
};
</script>

<style lang="sass" scoped>
.head
  background: #f5f5f8
  padding: 20px 24px 20px 24px

.title
  font-weight: 500
  font-size: 18px

.detail
  font-size: 14px
  color: rgba(28, 31, 52, 0.6)
</style>


const state = {
  bizTransfers: [],
  chargebacks: []
};

const getters = {
  bizTransfers: state => state.bizTransfers,
  chargebacks: state => state.chargebacks
};

const actions = {
  setBizTransfers: ({ commit }, data) => { commit("setBizTransfers", data); },
  updateBizTransfer: ({ commit }, data) => { commit("updateBizTransfer", data); },
  removeBizTransfer: ({ commit }, data) => { commit("removeBizTransfer", data); },
  setChargebacks: ({ commit }, data) => { commit("setChargebacks", data); },
  updateChargeback: ({ commit }, data) => { commit("updateChargeback", data); },
  removeChargeback: ({ commit }, data) => { commit("removeChargeback", data); },
};

const mutations = {
  setBizTransfers(state, data) { state.bizTransfers = data; },
  updateBizTransfer(state, data) {
    state.bizTransfers = state.bizTransfers.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeBizTransfer(state, data) {
    state.bizTransfers = state.bizTransfers.filter(o => o._id !== data._id);
  },
  setChargebacks(state, data) { state.chargebacks = data; },
  updateChargeback(state, data) {
    state.chargebacks = state.chargebacks.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeChargeback(state, data) {
    state.chargebacks = state.chargebacks.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
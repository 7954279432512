<template lang="pug">
v-list-item(@click="select(item)")
  v-list-item-content
    v-list-item-title.text-truncate {{ item.name }}
    v-list-item-subtitle
      span(v-if="item.short_name && item.short_name.length") {{ item.short_name }}
      span(v-else-if="item.address") {{ item.address.line1 }}
  v-list-item-action
    v-progress-circular(
      v-if="loading",
      size="20",
      color="primary",
      :indeterminate="true"
    )
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { item: { type: Object, default: null } },
  data() {
    return { loading: false };
  },
  computed: { ...mapGetters("entp", ["enterprise"]) },
  methods: {
    async select() {
      this.loading = true;
      try {
        const params = {
          enterpriseId: this.enterprise._id,
          bizId: this.item._id,
        };
        const result = await this.$api.entp.biz.create(params);
        this.$store.dispatch("entp/updateEnterprise", result);
        const biz = await this.$api.biz.retrieve({
          criteria: { _id: this.item._id },
        });
        this.$store.dispatch("updateBizAccount", biz);
        this.$emit("saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>



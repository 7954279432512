<template lang="pug">
div(v-if="!isPaid")
  v-divider.my-5
  v-checkbox(v-model="confirmed", label="Confirm", color="error")
  v-btn(
    @click="submit",
    outlined,
    color="error",
    :disabled="!valid",
    :loading="loading"
  ) Delete
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
      confirmed: false,
    };
  },
  computed: {
    isPaid() {
      const amount = this.item.payment?.charge?.amount;
      return amount && amount > 0;
    },
    valid() {
      return this.confirmed && !this.isPaid;
    },
  },
  methods: {
    ...mapActions(["removeOrgInvoice"]),
    async submit() {
      if (!this.item) return;
      try {
        const result = await this.$api.salesforce.invoice.delete(this.item._id);
        this.removeOrgInvoice(result);
        this.$toast.success("Invoice deleted");
        this.$emit("deleted");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
<template lang="pug">
.avatar-container
  .subtitle-2 Logo
  v-avatar(:size="size", color="primary", tile)
    img(:src="picture")
  .edit-btn
    v-btn(@click="open", icon, color="secondary")
      v-icon(small) mdi-pencil
  UploadForm(ref="form", :chain="chain", @updated="updated")
</template>

<script>
import UploadForm from "./UploadForm";

const default_pic =
  "https://firebasestorage.googleapis.com/v0/b/selflane-docs.appspot.com/o/icons%2Fblank-profile-picture-973460_640.png?alt=media&token=763eec08-5e8a-48aa-8876-b562cd66ab20";
export default {
  components: { UploadForm },
  props: {
    chain: { type: Object, required: true },
    size: { type: Number, default: 120 },
  },
  computed: {
    picture() {
      const url = this.chain?.logo;
      if (url) {
        return process.env.VUE_APP_MEDIAHOST + url;
      } else {
        return default_pic;
      }
    },
  },
  methods: {
    open() {
      this.$refs.form.open();
    },
    updated($event) {
      this.$emit("updated", $event);
    },
  },
};
</script>

<style lang="sass" scoped>
// make edit btn align to bottom right of avatar
.avatar-container
  position: relative
  display: inline-block
  text-align: center
.edit-btn
  background: white
  position: absolute
  bottom: 0
  right: 0px
  z-index: 1
</style>
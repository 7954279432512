const state = {
  salesOrganization: null,
  salesPerson: null,
  salesOrganizations: [],
  salesPersons: [],
  org_invoices: []
};

const getters = {
  salesOrganization: state => state.salesOrganization,
  salesPerson: state => state.salesPerson,
  salesOrganizations: state => state.salesOrganizations,
  salesPersons: state => state.salesPersons,
  org_invoices: state => state.org_invoices
};

const actions = {
  setSalesOrganization: ({ commit }, data) => { commit("setSalesOrganization", data); },
  setSalesPerson: ({ commit }, data) => { commit("setSalesPerson", data); },
  // Sales Organizations
  setSalesOrganizations: ({ commit }, data) => { commit("setSalesOrganizations", data); },
  updateSalesOrganization: ({ commit }, data) => { commit("updateSalesOrganization", data); },
  addSalesOrganization: ({ commit }, data) => { commit("addSalesOrganization", data); },
  removeSalesOrganization: ({ commit }, data) => { commit("removeSalesOrganization", data); },
  // Sales Persons
  setSalesPersons: ({ commit }, data) => { commit("setSalesPersons", data); },
  updateSalesPerson: ({ commit }, data) => { commit("updateSalesPerson", data); },
  addSalesPerson: ({ commit }, data) => { commit("addSalesPerson", data); },
  removeSalesPerson: ({ commit }, data) => { commit("removeSalesPerson", data); },
  // Org Invoices
  setOrgInvoices: ({ commit }, data) => { commit("setOrgInvoices", data); },
  updateOrgInvoice: ({ commit }, data) => { commit("updateOrgInvoice", data); },
  addOrgInvoice: ({ commit }, data) => { commit("addOrgInvoice", data); },
  removeOrgInvoice: ({ commit }, data) => { commit("removeOrgInvoice", data); },
};

const mutations = {
  setSalesOrganization(state, data) {
    state.salesOrganization = data;
  },
  // set current sales person and its associated sales organization
  setSalesPerson(state, data) {
    state.salesPerson = data;
    const found = state.salesOrganizations.find(item => item._id == data.organization)
    this.dispatch('setSalesOrganization', found)
  },
  // Sales Organizations
  setSalesOrganizations(state, data) {
    state.salesOrganizations = data;
  },
  updateSalesOrganization(state, data) {
    state.salesOrganizations = state.salesOrganizations.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
    if (state.salesOrganization?._id === data._id) {
      state.salesOrganization = data;
    }
  },
  addSalesOrganization(state, data) {
    state.salesOrganizations.push(data);
  },
  removeSalesOrganization(state, data) {
    state.salesOrganizations = state.salesOrganizations.filter(o => o._id !== data._id);
  },
  // Sales Persons
  setSalesPersons(state, data) { state.salesPersons = data; },
  updateSalesPerson(state, data) {
    state.salesPersons = state.salesPersons.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
    if (state.salesPerson?._id === data._id) {
      state.salesPerson = data;
    }
  },
  addSalesPerson(state, data) { state.salesPersons.push(data); },
  removeSalesPerson(state, data) {
    state.salesPersons = state.salesPersons.filter(o => o._id !== data._id);
  },
  // Org Invoices
  setOrgInvoices(state, data) {
    state.org_invoices = data;
  },
  updateOrgInvoice(state, data) {
    state.org_invoices = state.org_invoices.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addOrgInvoice(state, data) {
    state.org_invoices.push(data);
  },
  removeOrgInvoice(state, data) {
    state.org_invoices = state.org_invoices.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };
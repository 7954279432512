<template lang="pug">
div
  IconBtn(@click="handleAdd()", title="Add", icon="mdi-plus")
  List.my-3(:items="salesOrganizations")
  Form
</template>

<script>
import Form from "/libs/components/SalesForce/Organization/Form";
import List from "/libs/components/SalesForce/Organization/List";
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Form, List },
  computed: { ...mapGetters(["salesOrganizations"]) },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      try {
        const result = await this.$api.salesforce.organization.list(params);
        this.$store.dispatch("setSalesOrganizations", result);
      } catch (e) {
        this.setSalesOrganizations([]);
      }
    },
    handleAdd() {
      EventBus.$emit("edit-sales-organization");
    },
  },
};
</script>

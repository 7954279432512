<template lang="pug">
v-card(v-if="report", flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }}
  simple-table
    thead
      tr
        th Period
        th #Sales
        th Total
        th Tax
        th Tip
        th App Fee
        th Canceled
    tbody
      tr(v-for="(item, index) in report", :key="index")
        td {{ time(item) }}
        td {{ item.count }}
        td {{ item.total | currency }}
        td {{ item.tax | currency }}
        td {{ item.tip | currency }}
        td {{ item.fee | currency }}
        td {{ item.cancel_amount | currency }}
    tfoot
      tr
        td {{ sum.period }}
        td {{ sum.count }}
        td {{ sum.total | currency }}
        td {{ sum.tax | currency }}
        td {{ sum.tip | currency }}
        td {{ sum.fee | currency }}
        td {{ sum.cancel_amount | currency }}
</template>

<script>
import moment from "moment-timezone";
import _ from "underscore";

export default {
  props: ["report", "title", "year"],
  computed: {
    sum() {
      return {
        period: "Sum",
        total: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.total;
          },
          0
        ),
        tax: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.tax;
          },
          0
        ),
        tip: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.tip;
          },
          0
        ),
        fee: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.fee;
          },
          0
        ),
        count: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.count;
          },
          0
        ),
        refund_amount: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.refund_amount;
          },
          0
        ),
        cancel_amount: _.reduce(
          this.report,
          (memo, item) => {
            return memo + item.cancel_amount;
          },
          0
        ),
      };
    },
  },
  methods: {
    time(item) {
      if (item.month) {
        switch (item.month) {
          case 1:
            return "January";
          case 2:
            return "Feburary";
          case 3:
            return "March";
          case 4:
            return "April";
          case 5:
            return "May";
          case 6:
            return "June";
          case 7:
            return "July";
          case 8:
            return "August";
          case 9:
            return "September";
          case 10:
            return "October";
          case 11:
            return "November";
          case 12:
            return "December";
        }
      } else {
        const begin = moment()
          .year(this.year)
          .week(item.week + 1)
          .startOf("week")
          .format("MMM D");
        const end = moment()
          .year(this.year)
          .week(item.week + 1)
          .endOf("week")
          .format("MMM D");
        return begin + " - " + end;
      }
    },
  },
};
</script>

<template lang="pug">
div
  v-btn(@click="open()") Share Payments
  v-dialog(v-model="dialog", width="500")
    v-card
      v-toolbar(dense, flat)
        .subtitle-2 Share Payments
      v-card-text
        .mb-2 {{ note }}
        BizSelector(@select="setOriginal", label="Original")
        BizSelector(@select="setTarget", label="Target")
      v-card-actions
        v-btn(
          @click="submit",
          color="secondary",
          block,
          :disabled="!originID || !targetIDs",
          :loading="loading"
        ) Save
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      originID: null,
      targetIDs: [],
      loading: false,
      note: "Do you want to share the managed account among businesses. This will delete the existing managed account of target business if it exists.",
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    setOriginal(biz) {
      this.originID = biz._id;
    },
    setTarget(biz) {
      this.targetIDs = [biz._id];
    },
    async submit() {
      if (!this.originID || !this.targetIDs?.length) return;
      const params = { originID: this.originID, targetIDs: this.targetIDs };
      this.loading = true;
      try {
        const { data } = await this.axios.post("/bizs/sharepayments", params);
        this.$toast.success(data);
        this.originID = null;
        this.targetIDs = [];
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

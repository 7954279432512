const state = {
  promotions: []
}

const getters = {
  promotions: state => state.promotions
}

const actions = {
  setPromotions: ({ commit }, data) => { commit('setPromotions', data) },
  addPromotion: ({ commit }, data) => { commit('addPromotion', data) },
  updatePromotion: ({ commit }, data) => { commit('updatePromotion', data) },
  removePromotion: ({ commit }, data) => { commit('removePromotion', data) }
}

const mutations = {
  setPromotions(state, data) { state.promotions = data },
  addPromotion(state, data) { state.promotions = state.promotions.concat([data]) },
  updatePromotion(state, data) {
    state.promotions = state.promotions.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removePromotion(state, data) {
    state.promotions = state.promotions.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
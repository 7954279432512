<template lang="pug">
div
  v-text-field(label="Search", v-model="search", single-line)
  v-data-table(
    :items="items",
    :headers="headers",
    sort-by="count",
    sort-desc,
    :search="search",
    dense,
    mobile-breakpoint=0
  )
</template>

<script>
export default {
  data() {
    return {
      items: [],
      search: "",
      headers: [
        { text: "Domain", value: "domain" },
        { text: "count", value: "count", align: "end" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const { data } = await this.axios.get("/users/stats/domain");
      this.items = data;
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Created
        th Amount
        th Note
        th.text-right Name
    tbody
      tr(v-for="(item, index) in partnerPayouts", :key="index")
        td {{ item.created | date({ dynamic: true }) }}
        td {{ item.amount | currency }} {{ item.currency }}
        th {{ item.note }}
        td.text-right
          span(v-if="item.name") {{ item.name }}
          span(v-else) {{ item.email }}
      tr
        td {{ partnerPayouts.length }}
        td {{ sum.amount | currency }}
        td
        td
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["partnerPayouts"]),
    sum() {
      const amount = this.partnerPayouts?.reduce((a, b) => a + b.amount, 0);
      return { amount };
    },
  },
};
</script>

<template lang="pug">
Page(title="Promotion")
  .text-center.mb-3
    v-btn-toggle(v-model="statusType", dense, color="secondary", rounded)
      v-btn.text-capitalize(
        v-for="(item, index) in statusTypeItems",
        :key="index",
        :value="item.value",
        small
      ) {{ item.text }}
  List(:promotions="filteredItems")
  Stats
  Remind
</template>

<script>
import _ from "underscore";
import List from "./List/index";
import { mapGetters, mapActions } from "vuex";
import Stats from "./Stats";
import Remind from "./Remind";

export default {
  components: { List, Stats, Remind },
  data() {
    return {
      statusType: 0,
      statusTypeItems: [
        { text: "Active", value: 0 },
        { text: "Inactive", value: -1 },
      ],
    };
  },
  computed: {
    ...mapGetters(["promotions"]),
    filteredItems() {
      const now = Date.now();
      if (this.statusType == 0) {
        return _.filter(this.promotions, (o) => {
          return o.expiry > now;
        });
      } else {
        return _.reject(this.promotions, (o) => {
          return o.expiry > now;
        });
      }
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setPromotions"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.promotion.list(params);
      this.setPromotions(result);
    },
  },
};
</script>

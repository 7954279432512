<template lang="pug">
v-card(flat)
  v-text-field.mx-5(v-model="searchText", label="Search", clearable)
  v-data-table(
    :items="items",
    :headers="headers",
    :search="searchText",
    @click:row="edit",
    role="button",
    sort-by="name",
    mobile-breakpoint=0,
    dense,
    :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
  )
    template(v-slot:item.mid="{ item }")
      div {{ getMID(item) }}
    template(v-slot:item.store="{ item }")
      div {{ getStoreId(item) }}
    template(v-slot:item.status="{ item }")
      div {{ getStatus(item) }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["items"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "MID", value: "mid", filterable: false },
      ],
    };
  },
  methods: {
    edit(item) {
      if (!item) return;
      EventBus.$emit("show-service", item);
    },
    getMID(item) {
      return item.apps
        .find((app) => app.name === "card_pointe")
        ?.meta?.find((meta) => meta.key === "mid")?.value;
    },
  },
};
</script>

<template lang="pug">
StandardChart(:input="chartData", v-if="stats")
</template>

<script>
import _ from "underscore";

export default {
  name: "CouponSalesChart",
  props: ["stats"],
  data() {
    return {
      chartData: {},
    };
  },
  watch: {
    stats(val) {
      if (val) this.getData();
    },
  },
  methods: {
    getData() {
      const day = 24 * 60 * 60 * 1000;
      const totaldays = Math.ceil(
        (this.stats.expiry - this.stats.created) / day
      );
      const days = _.range(1, totaldays + 2);
      this.chartData = {
        title: "Generated Net Sales",
        labels: days,
        values: this.getSales(),
        isCurrency: true,
      };
    },
    getSales() {
      if (!this.stats || !this.stats.apply || !this.stats.apply.length)
        return [];
      this.applied = 0;
      // eslint-disable-next-line vue/no-mutating-props
      const apply = this.stats.apply.sort(function (a, b) {
        return a - b;
      });
      const last = _.last(apply);
      let data = _.map(_.range(0, last + 1), () => {
        return 0;
      });
      _.each(this.stats.sales, (o) => {
        let start = o.day;
        while (start < data.length) {
          data[start] += o.amount;
          start++;
        }
      });
      data = _.map(data, (o) => Math.round(o * 100) / 100);
      return data;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn.text-capitalize(rounded, small, v-on="on") Unsubscribe Many
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Unsubscribe many emails
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(v-model="text", label="Emails (separate with comma)")
        v-btn(block, color="secondary", type="submit", :disabled="!text") Submit
        .caption Number of emails: {{ numberofemails }}
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      text: "",
    };
  },
  computed: {
    numberofemails() {
      return this.text.split(",").length;
    },
  },
  methods: {
    async submit() {
      const emails = this.text.split(",").map((o) => o.trim());
      await this.axios.post("/member/unsubscribemany", { emails });
      this.text = "";
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} User Access
      v-card-text
        v-row(wrap, dense)
          v-col(cols="6")
            v-text-field(label="Name", v-model="name")
          v-col(cols="6")
            PhoneField(v-model="phone")
          v-col(cols="6")
            v-text-field(
              label="Email",
              v-model="email",
              :error-messages="emailErrors",
              @blur="$v.email.$touch()",
              :disabled="isOld"
            )
          v-col(cols="6")
            v-select(
              label="Access Level",
              v-model="privilege",
              :items="roles",
              :error-messages="privilegeErrors",
              @blur="$v.privilege.$touch()",
              :disabled="isMe"
            )
        v-checkbox(
          v-model="access_wage",
          label="Can access wage and edit timecard",
          v-if="isManager || isCustom",
          color="secondary"
        )
        div(
          v-for="(group, gindex) in accesses_groups",
          :key="gindex + 'group'",
          v-if="isCustom"
        )
          .subtitle-2 {{ group.title }}
          v-radio-group(v-model="items", multiple, row)
            v-radio(
              v-for="(item, index) in group.items",
              :key="index + 'item'",
              :label="item.title",
              :value="item.name",
              color="secondary"
            )
      v-card-actions
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="$v.$invalid",
          :loading="loading"
        ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import AccessGroups from "/libs/json/BizNav.json";

export default {
  name: "BizAccessForm",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    privilege: { required },
  },
  data() {
    return {
      menu: null,
      dialog: false,
      access: null,
      loading: false,
      email: "",
      name: "",
      phone: "",
      privilege: null,
      access_wage: false,
      items: [],
      roles: [
        { text: "Owner", value: 1 },
        { text: "Manager", value: 2 },
        { text: "POS App Only", value: 3 },
        { text: "Custom", value: 4 },
      ],
      accesses_groups: AccessGroups,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    isOld() {
      return this.access?._id;
    },
    isMe() {
      return this.access?.user == this.$auth.user()._id;
    },
    isManager() {
      return this.privilege == 2;
    },
    isCustom() {
      return this.privilege == 4;
    },
    mapped_items() {
      if (!this.isCustom) return [];
      return this.items.map((o) => {
        return { name: o };
      });
    },
    title() {
      if (this.access) return "Edit";
      else return "Add";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    privilegeErrors() {
      const errors = [];
      if (!this.$v.privilege.$dirty) return errors;
      !this.$v.privilege.required && errors.push("Role is required");
      return errors;
    },
  },
  mounted() {
    EventBus.$on("edit-access", this.open);
  },
  destroyed() {
    EventBus.$off("edit-access", this.open);
  },
  methods: {
    ...mapActions(["addBizAccess", "updateBizAccess"]),
    open(data) {
      this.access = data;
      if (data) {
        this.email = data.email;
        this.name = data.name;
        this.phone = data.phone;
        this.privilege = data.privilege;
        this.items = data.items.map((o) => o.name);
        this.access_wage = data.access_wage;
      } else {
        this.email = "";
        this.name = "";
        this.phone = "";
        this.privilege = null;
        this.items = [];
        this.access_wage = false;
      }
      this.dialog = true;
      this.$v.$reset();
    },
    async submit() {
      if (!this.email || !this.privilege) {
        alert("Please enter email and select an access level");
        return;
      }
      this.loading = true;
      try {
        if (this.access?._id) await this.update();
        else await this.create();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async update() {
      let phone = "";
      if (this.phone) phone = this.phone.replace(/[^\d]/g, "");
      const params = {
        criteria: { _id: this.access._id },
        action: {
          $set: {
            name: this.name,
            phone: phone,
            privilege: this.privilege,
            access_wage: this.access_wage,
            items: this.mapped_items,
          },
        },
      };
      const result = await this.$api.b.access.update(params);
      this.updateBizAccess(result);
    },
    async create() {
      if (!this.biz) return;
      let phone = "";
      if (this.phone) phone = this.phone.replace(/[^\d]/g, "");
      const params = {
        biz: this.biz._id,
        email: this.email,
        name: this.name,
        phone: phone,
        privilege: this.privilege,
        items: this.mapped_items,
      };
      const result = await this.$api.b.access.create(params);
      this.addBizAccess(result);
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  :search="searchText",
  sort-by="serverName",
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.clockIn="{ item }")
    span {{ item.clockIn | time }}
  template(v-slot:item.clockOut="{ item }")
    span(v-if="item.clockOut") {{ item.clockOut | time }}
  template(v-slot:item.action="{ item }")
    DeleteOne(:item="item", v-if="isDeletable")
</template>

<script>
import DeleteOne from "./DeleteOne";

export default {
  components: { DeleteOne },
  props: ["searchText", "items", "isDeletable"],
  data() {
    return {
      headers: [
        { text: "Name", value: "serverName" },
        { text: "Clock In", value: "clockIn" },
        { text: "Clock Out", value: "clockOut" },
        { text: "Length", value: "minutes" },
        { text: "Action", value: "action", align: "end" },
      ],
      selectedBizId: null,
    };
  },
};
</script>

<template lang="pug">
div(v-if="product")
  .icon-img-block(@click.stop="edit()")
    v-img(:src="mediaHost + product.icon", v-if="product.icon")
    v-icon(v-else) mdi-image
  v-dialog(v-model="dialog", max-width="250")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Product Icon
      v-divider
      v-card-text.text-center
        croppa.my-5(
          v-model="myCroppa",
          :width="180",
          :height="180",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
        v-text-field(
          v-model="canvas_color",
          label="Background Color",
          hint="Name, hex code, or valid color format"
        )
        v-btn(
          @click="generateBlob()",
          :loading="loading",
          block,
          color="secondary"
        ) save
</template>

<script>
export default {
  name: "CatalogGroupIcon",
  props: ["product"],
  data() {
    return {
      dialog: false,
      myCroppa: {},
      loading: false,
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      canvas_color: "#FFFFFF",
    };
  },
  methods: {
    edit() {
      this.dialog = true;
    },
    generateBlob() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
            return;
          }
          this.uploadLogo(blob);
        },
        "image/jpeg",
        0.8
      );
    },
    async uploadLogo(blob) {
      if (!this.product || !this.product._id) return;
      this.loading = true;
      const productId = this.product._id;
      const formData = new FormData();
      formData.append("file", blob);
      formData.append("productId", productId);
      try {
        const result = await this.$api.catalog.product.custom(
          "updateIcon",
          formData
        );
        this.$store.dispatch("catalog/updateProduct", result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.icon-img-block
  width: 180px
  height: 180px
  margin-left: auto
  margin-right: auto
  border-radius: 6px
  overflow: hidden
  cursor: pointer
</style>
<template lang="pug">
.s-vstack
  Cell(v-for="(item, index) in filtered", :key="index", :chain="item")
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  name: "EnterpriseChainList",
  components: { Cell },
  computed: {
    ...mapGetters("entp", ["enterprise", "chains"]),
    filtered() {
      return this.chains
        .filter((o) => o.enterprise == this.enterprise?._id)
        .toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="filtered",
  :headers="headers",
  dense,
  mobile-breakpoint=0,
  :search="searchText",
  :footer-props="{ itemsPerPageOptions: [20, 50, -1] }",
  @click:row="$emit('show', $event)",
  role="button"
)
  template(v-slot:item.createdAt="{ item }")
    span.nowrap {{ item.createdAt | time(timezone) }}
  template(v-slot:item.type="{ item }")
    span {{ item.type | paxType }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["items", "searchText", "compact", "biz"],
  data() {
    return {
      headers: [
        { text: "ID", value: "transNum" },
        { text: "Created", value: "createdAt", filterable: false },
        { text: "Last4", value: "account" },
        { text: "Name", value: "name" },
        { text: "Amount", value: "approveAmount" },
        { text: "Tip", value: "tipAmount", filterable: false },
        { text: "Type", value: "type", filterable: false },
        { text: "Auth Code", value: "authCode" },
        { text: "Order/Check", value: "refNum" },
        { text: "Batch", value: "batchNum", filterable: false, align: "end" },
      ],
    };
  },
  computed: {
    timezone() {
      return this.biz?.address?.timezone;
    },
    filtered() {
      if (this.compact) {
        const result = _.chain(this.items)
          .sortBy("transNum")
          .sortBy("batchNum")
          .groupBy((o) => o.transNum + " " + o.batchNum)
          .map((list) => {
            const adjusted = list.filter((o) => o.type == "06");
            let sorted;
            if (adjusted?.length) {
              sorted = _.sortBy(adjusted, "createdAt");
            } else {
              sorted = _.sortBy(list, (o) => parseInt(o.timestamp));
            }
            return _.last(sorted);
          })
          .value();
        return result;
      } else {
        return _.chain(this.items)
          .sortBy("timestamp")
          .sortBy("transNum")
          .sortBy("batchNum")
          .value();
      }
    },
  },
};
</script>
<template lang="pug">
v-card(flat, v-if="biz?.schedule")
  v-toolbar(dense, flat)
    .subtitle-2 Weekly Hours
    v-spacer
    Form(:biz="biz", @updated="updated")
  simple-table
    tbody
      tr(v-for="(item, index) in biz.schedule.weekdays", :key="index")
        th {{ item.date | day }}
        td.text-right {{ item | dayschedule }}
      tr(v-if="open_delay")
        th Open Delay
        td.text-right {{ open_delay }} minutes
</template>

<script>
import Form from "./Form";

export default {
  components: { Form },
  props: { biz: { type: Object, required: true } },
  computed: {
    open_delay() {
      return this.biz?.schedule?.open_delay || 0;
    },
  },
  methods: {
    updated($event) {
      this.$emit("updated", $event);
    },
  },
};
</script>

<template lang="pug">
Page(title="Enterprise", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Home", url: "/enterprise/home" },
        { title: "Settings", url: "/enterprise/settings" },
        { title: "Regions", url: "/enterprise/regions" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-list-item(@click="populate()") Populate
</template>

<script>
export default {
  props: ["program"],
  methods: {
    async populate() {
      if (!this.program) return;
      const params = { programId: this.program._id };
      const { data } = await this.axios.post(
        "/member/programs/populate",
        params
      );
      this.$toast.success(data);
    },
  },
};
</script>

<template lang="pug">
Page(title="Clients Growth", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/biz/growth/dashboard" },
        { title: "Monthly", url: "/biz/growth/monthly" },
        { title: "Map", url: "/biz/growth/map" },
        { title: "List", url: "/biz/growth/list" },
      ],
    };
  },
};
</script>

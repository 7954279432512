<template lang="pug">
div
  MonthSelector.ma-2(@select="selectMonth")
  ListProducts(:products="products", :users="users", :ins="ins")
  ListMisc(:users="users", :miscItems="miscItems")
</template>

<script>
import ListProducts from "./ListProducts";
import ListMisc from "./ListMisc";
import { EventBus } from "@/event-bus.js";

export default {
  components: { ListProducts, ListMisc },
  data() {
    return {
      range: null,
      ins: [],
      miscItems: [],
      products: [],
      users: [],
    };
  },
  mounted() {
    EventBus.$on("CreateInventoryIn", this.load);
  },
  destroyed() {
    EventBus.$off("CreateInventoryIn", this.load);
  },
  methods: {
    selectMonth(data) {
      this.range = data.range;
      this.load();
    },
    async load() {
      if (!this.range || !this.range.begin || !this.range.end) return;
      const params = {
        criteria: { date: { $gte: this.range.begin, $lt: this.range.end } },
      };
      this.ins = await this.$api.catalog.inventory.in.list(params);
      this.loadMisc();
    },
    async loadMisc() {
      if (!this.range || !this.range.begin || !this.range.end) return;
      const params = {
        criteria: { date: { $gte: this.range.begin, $lt: this.range.end } },
      };
      this.miscItems = await this.$api.catalog.inventory.misc.list(params);
      this.loadProductNames();
      this.loadUsers();
    },
    async loadProductNames() {
      const ids = this.ins.map((o) => o.product);
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      this.products = await this.$api.catalog.product.list(params);
    },
    async loadUsers() {
      const ids = this.ins.map((o) => o.staff);
      const params = { criteria: { _id: { $in: ids } }, select: "email" };
      this.users = await this.$api.user.list(params);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="700")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Delivery Orders
    simple-table
      thead
        tr
          th Driver
          th Date
          th Base
          th Tip
          th Reward
          th Total
          th Zone
      tbody(v-for="(group, gindex) in sortedData", :key="gindex")
        tr(v-for="(item, index) in group.items", :key="index")
          td
            span(v-if="item.driver") {{ item.driver.name }}
          td {{ item.needed | date({ dynamic: true }) }}
          td {{ item.fee | currency }}
          td {{ item.tip | currency }}
          td {{ item.reward | currency }}
          td {{ (item.fee + item.tip + item.reward) | currency }}
          td {{ item.zone }}
        tr
          td
          td
          td
          td
          td {{ group.reward | currency }}
          td {{ group.sum | currency }}
          td
            v-btn(
              small,
              icon,
              @click="handleSend(group.driverId, group.driverName)",
              v-if="group.driverId"
            )
              v-icon(small) mdi-send
      tfoot
        tr
          th Sum
          th
          th {{ sum.fee | currency }}
          th {{ sum.tip | currency }}
          th {{ sum.reward | currency }}
          th {{ (sum.fee + sum.tip + sum.reward) | currency }}
          th
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapGetters(["delivOrders"]),
    sortedData() {
      return _.chain(this.delivOrders)
        .groupBy((o) => o.driver?.id)
        .map((items, driverId) => {
          const sum = items.reduce((a, b) => a + b.fee + b.tip + b.reward, 0);
          const reward = items.reduce((a, b) => a + b.reward, 0);
          let driverName = "";
          if (_.first(items).driver) driverName = _.first(items).driver.name;
          return { items, sum, reward, driverId, driverName };
        })
        .sortBy("driverName")
        .value();
    },
    sum() {
      return {
        fee: this.delivOrders.reduce((a, b) => a + b.fee, 0),
        tip: this.delivOrders.reduce((a, b) => a + b.tip, 0),
        reward: this.delivOrders.reduce((a, b) => a + b.reward, 0),
      };
    },
  },
  mounted() {
    EventBus.$on("show-deliv-orders", this.show);
  },
  destroyed() {
    EventBus.$off("show-deliv-orders", this.show);
  },
  methods: {
    ...mapActions(["setDelivOrders"]),
    async show(ids) {
      if (!ids) return;
      this.dialog = true;
      this.setDelivOrders();
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.delivOrder.list(params);
      const data = _.sortBy(result, "needed");
      this.setDelivOrders(data);
      this.loading = false;
    },
    handleSend(driverId) {
      if (!driverId) return;
      EventBus.$emit("show-send-driver-payout", driverId);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="900")
  v-card(v-if="zone")
    v-toolbar(dense, flat)
      .subtitle-2 Geo Service Zone
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(dense)
          v-col(cols="6")
            v-row(dense)
              v-col(cols="6")
                v-select(
                  v-model="zone.country",
                  :items="country_items",
                  label="Country"
                )
              v-col(cols="6")
                v-text-field(label="Zone Name", v-model="zone.zoneName")
            v-text-field(label="Zone Detail", v-model="zone.zoneDetail")
            v-row(dense)
              v-col(cols="6")
                v-text-field(
                  label="Basic Rate",
                  v-model="zone.basicRate",
                  suffix="%"
                )
              v-col(cols="6")
                PriceField(
                  label="Min. Service Fee",
                  v-model.number="zone.minBasic"
                )
              v-col(cols="6")
                PriceField(
                  label="Delivery Rate",
                  v-model.number="zone.delivery.rate"
                )
              v-col(cols="6")
                PriceField(
                  label="Delivery Min. Service Fee",
                  v-model.number="zone.delivery.minService"
                )
            v-row(dense, wrap)
              v-col(cols="6")
                v-text-field(
                  label="Delivery Inclusive Range",
                  v-model="zone.delivery.inclusiveRange"
                )
              v-col(cols="6")
                v-text-field(
                  label="Delivery Max Range",
                  v-model="zone.delivery.maxRange"
                )
              v-col(cols="6")
                PriceField(
                  label="Delivery Base Fee",
                  v-model.number="zone.delivery.fee"
                )
              v-col(cols="6")
                PriceField(
                  label="Delivery Unit Fee",
                  v-model.number="zone.delivery.unitFee"
                )
              v-col(cols="6")
                PriceField(
                  label="Driver Pay",
                  v-model.number="zone.driverBasePay"
                )
              v-col(cols="6")
                PriceField(
                  label="Manager Pay",
                  v-model.number="zone.managerBasePay"
                )
              v-col(cols="6")
                v-select(
                  v-model="zone.timezone",
                  :items="timezoneItems",
                  label="Timezone"
                )
          v-col(cols="6")
            #map(ref="map")
            simple-table
              thead
                tr
                  th Lat
                  th Lng
                  th
              tbody
                tr(v-for="(item, index) in zone.zonePolygon", :key="index")
                  td {{ item.lat }}
                  td {{ item.lng }}
                  td
                    v-btn(
                      icon,
                      color="red",
                      small,
                      @click.stop="removePoint(index)"
                    )
                      v-icon(small) mdi-close
            v-btn(@click.stop="drawPolygon()") Draw Polygon
            v-btn(@click.stop="clearPolygon()") Clear Polygon
        v-btn(color="secondary", type="submit", :loading="loading", block) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      zone: null,
      country_items: [
        { text: "United States", value: "US" },
        { text: "Canada", value: "CA" },
      ],
      timezoneItems: [
        "America/New_York",
        "America/Chicago",
        "America/Denver",
        "America/Los_Angeles",
        "America/Toronto",
      ],
      bizs: [],
    };
  },
  mounted() {
    EventBus.$on("edit-geo-service-zone", this.open);
    this.loadBizs();
  },
  destroyed() {
    EventBus.$off("edit-geo-service-zone", this.open);
  },
  methods: {
    ...mapActions(["updateGeoServiceZone", "addGeoServiceZone"]),
    open(data) {
      if (data) {
        this.zone = JSON.parse(JSON.stringify(data));
      } else {
        this.zone = {
          country: "US",
          zoneName: "US-",
          timezone: "America/Denver",
          zonePolygon: [],
          basicRate: 6,
          delivery: {
            inclusiveRange: 5,
            maxRange: 10,
            minService: 3,
            rate: 11,
            fee: 2.99,
            unitFee: 1.5,
          },
          driverBasePay: 4,
          managerBasePay: 1,
        };
      }
      this.dialog = true;
      const initMap = this.initMap;
      setTimeout(() => {
        initMap();
      }, 500);
    },
    async submit() {
      if (!this.zone) return;
      this.loading = true;
      if (this.zone._id) {
        const params = { criteria: { _id: this.zone._id }, action: this.zone };
        try {
          const { data } = await this.axios.post(
            "/geoService/zones/update",
            params
          );
          this.updateGeoServiceZone(data);
          this.dialog = false;
        } catch (err) {
          this.$toast.error(err.response?.data || err.message);
        }
      } else {
        try {
          const { data } = await this.axios.post(
            "/geoService/zones/create",
            this.zone
          );
          this.addGeoServiceZone(data);
          this.dialog = false;
        } catch (err) {
          this.$toast.error(err.response?.data || err.message);
        }
      }
      this.loading = false;
    },
    async loadBizs() {
      const params = {
        criteria: { "status.byAdmin": 1, "status.byUser": 1 },
        select: "name address",
      };
      this.bizs = await this.$api.biz.list(params);
    },
    removePoint(index) {
      if (!this.zone) return;
      this.zone.zonePolygon.splice(index, 1);
    },
    drawBizs() {
      this.bizs.forEach((biz) => {
        if (!biz || !biz.address || !biz.address.geometry) {
          return;
        }
        // eslint-disable-next-line no-undef
        new google.maps.Marker({
          position: biz.address.geometry,
          map: this.map,
        });
      });
    },
    drawPolygon() {
      if (!this.zone || !this.zone.zonePolygon) return;
      // eslint-disable-next-line no-undef
      this.polygon = new google.maps.Polygon({
        path: this.zone.zonePolygon,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
        map: this.map,
      });
    },
    clearPolygon() {
      this.polygon.setMap(null);
    },
    addZone(geoPoint) {
      if (!this.zone) return;
      if (!this.zone.zonePolygon) {
        this.zone.zonePolygon = [];
      }
      this.zone.zonePolygon.push({
        lat: geoPoint.lat(),
        lng: geoPoint.lng(),
      });
    },
    initMap(input) {
      var center = input;
      if (!center) {
        center = { lat: 35.0844, lng: -106.6504 };
      }
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 11,
      });
      this.map.addListener("click", (data) => {
        if (data && data.latLng) {
          this.addZone(data.latLng);
        }
      });
      this.drawBizs();
      this.drawPolygon();
    },
  },
};
</script>

<style lang="sass" scoped>
#map
  height: 300px
  width: 100%
  background: gray
</style>

<template lang="pug">
v-card.center-align(max-width="400", flat)
  v-toolbar(flat, dense)
    .subtitle-2 Search Online Orders
  v-card-text
    BizSelector(@select="selectBiz")
    DaySelector(@select="selectDate")
  v-card-actions
    v-btn(
      @click="loadAll",
      color="secondary",
      :loading="loading",
      x-small,
      outlined
    ) Load All
    v-spacer 
    v-btn(
      @click="loadByBiz",
      color="secondary",
      :disabled="!biz",
      :loading="loading"
    ) Search
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data: () => ({
    biz: null,
    date: "",
    loading: false,
  }),
  mounted() {
    this.setOrders();
  },
  methods: {
    ...mapActions(["setOrders", "updateOrder"]),
    selectBiz(biz) {
      this.biz = biz;
    },
    selectDate(date) {
      this.date = date;
    },
    async loadByBiz() {
      if (!this.date) return;
      if (!this.biz) return;
      const begin = moment(this.date).startOf("day").unix() * 1000;
      const end = moment(this.date).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          "seller.id": this.biz._id,
          "orderer.type": 0,
          needed: { $gte: begin, $lte: end },
        },
        select:
          "seller orderer needed orderNumber status payment proxy type deliv",
      };
      this.load(params);
    },
    async loadAll() {
      if (!this.date) return;
      const begin = moment(this.date).startOf("day").unix() * 1000;
      const end = moment(this.date).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          "orderer.type": 0,
          created: { $gte: begin, $lte: end },
        },
        select:
          "seller orderer needed orderNumber status payment proxy type deliv",
      };
      this.load(params);
    },

    async load(params) {
      this.loading = true;
      const result = await this.$api.order.list(params);
      this.setOrders(result);
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.center-align
  margin-left: auto
  margin-right: auto
  max-width: 400px
</style>
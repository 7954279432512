<template lang="pug">
.mb-2
  #map(ref="map")
  v-btn.text-capitalize.my-1(@click="drawHeatmap()", outlined, rounded) Draw Heat Map
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      heatmap: null,
    };
  },
  computed: {
    ...mapGetters(["delivOrders"]),
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      var center = { lat: 35.0844, lng: -106.6504 };
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: center,
        zoom: 10,
      });
    },
    drawHeatmap() {
      if (this.heatmap) {
        this.heatmap.setMap(null);
      }
      const heatmapData = _.chain(this.delivOrders)
        .reject((o) => o.status == 4 || o.type != 0)
        .pluck("seller")
        .pluck("address")
        .pluck("geometry")
        .compact()
        .map((o) => {
          // eslint-disable-next-line no-undef
          return new google.maps.LatLng(o.lat, o.lng);
        })
        .value();
      // eslint-disable-next-line no-undef
      this.heatmap = new google.maps.visualization.HeatmapLayer({
        data: heatmapData,
      });
      this.heatmap.setMap(this.map);
    },
  },
};
</script>


<style lang="sass" scoped>
#map
  height: 400px
  width: 100%
  background: gray
</style>

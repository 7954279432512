<template lang="pug">
div
  .d-flex.flex-row.mb-5
    v-btn(@click="createSample", outlined, color="secondary", small) Create
    v-spacer
    YearSelector(@select="load")
  List(@edit="handleEdit")
  Form(ref="form")
</template>

<script>
import { mapActions } from "vuex";
import List from "./List/index";
import Form from "./Form";
import moment from "moment-timezone";

export default {
  components: { List, Form },
  methods: {
    ...mapActions(["setSuccessStories"]),
    async load(year) {
      const time = moment.tz(year, "YYYY", "America/Denver");
      const begin = time.startOf("year").valueOf();
      const end = time.endOf("year").valueOf();
      const criteria = { created: { $gte: begin, $lt: end } };
      const result = await this.$api.successStory.list({ criteria });
      this.setSuccessStories(result);
    },
    createSample() {
      this.$refs.form.open();
    },
    handleEdit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>
const state = {
  email_samples: []
};

const getters = {
  email_samples: state => state.email_samples
};

const actions = {
  setEmailSamples: ({ commit }, data) => { commit("setEmailSamples", data); },
  addEmailSample: ({ commit }, data) => { commit("addEmailSample", data); },
  updateEmailSample: ({ commit }, data) => { commit("updateEmailSample", data); },
  removeEmailSample: ({ commit }, data) => { commit("removeEmailSample", data); },
};

const mutations = {
  setEmailSamples(state, data) { state.email_samples = data; },
  addEmailSample(state, data) { state.email_samples.push(data); },
  updateEmailSample(state, data) {
    state.email_samples = state.email_samples.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeEmailSample(state, data) {
    state.email_samples = state.email_samples.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
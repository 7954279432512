<template lang="pug">
.box-container(:class="{ opacity: loading }")
  v-btn(icon, small, @click="previous()", title="previous")
    v-icon mdi-menu-left
  v-menu(offset-y, :close-on-content-click="false", v-model="dialog")
    template(v-slot:activator="{ on }")
      .btn-title(v-on="on", disabled="loading") {{ day | date({ dynamic: true }) }}
    v-date-picker(v-model="day", :max="maxDate", scrollable, @change="send")
  v-btn(icon, small, @click="next()", title="next")
    v-icon mdi-menu-right
</template>

<script>
import moment from "moment-timezone";
export default {
  name: "DaySelector",
  props: {
    loading: { type: Boolean, default: false },
    end: { type: Date, default: () => new Date() },
  },
  data() {
    return {
      day: moment().format("YYYY-MM-DD"),
      dialog: false,
    };
  },
  computed: {
    maxDate() {
      if (this.end) return moment(this.end).format("YYYY-MM-DD");
      return moment().format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.send();
  },
  methods: {
    next() {
      this.day = moment(this.day).add(1, "day").format("YYYY-MM-DD");
      this.send();
    },
    previous() {
      this.day = moment(this.day).subtract(1, "day").format("YYYY-MM-DD");
      this.send();
    },
    send() {
      this.dialog = false;
      const unix = moment(this.day).valueOf();
      this.$emit("select", unix);
    },
  },
};
</script>

<style lang="sass" scoped>
.box-container
  display: inline-flex
  flex-direction: row
  align-items: center
  border-radius: 6px
  border: 1px solid #e4e4e4
  background-color: white
.box-container.opacity
  opacity: 0.5
</style>
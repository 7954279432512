<template lang="pug">
div
  v-btn(
    v-if="visible",
    @click="dialog = true",
    small,
    outlined,
    color="secondary"
  ) Turn on POS
  v-dialog(v-model="dialog", width="400")
    v-card
      v-toolbar(flat)
        .subtitle-2 Turn on POS
      v-card-text
        v-form(@submit.prevent="turnon")
          .text-center.mb-5
            v-date-picker(v-model="pos_start", color="secondary")
          v-btn(type="submit", block, color="secondary", :loading="loading") Yes, I Confirm
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      pos_start: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    visible() {
      const items = ["test", "permanently_closed"];
      return !items.includes(this.biz?.standing);
    },
  },
  methods: {
    ...mapActions(["updateBizAccount"]),
    async turnon() {
      this.loading = true;
      const params = {
        bizId: this.biz._id,
        pos_start: moment(this.pos_start).valueOf(),
      };
      const result = await this.$api.b.action.custom("turnOnPOS", params);
      this.updateBizAccount(result);
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    v-btn(@click="open()", small, depressed, color="error") Pull Fund
  v-card(v-if="biz")
    v-toolbar(flat, dense)
      .subtitle-2 Pull fund from {{ biz.name }}
    v-divider
    v-card-text
      v-form(@submit.prevent="save")
        PriceField(label="Amount", v-model.number="amount")
        v-select(label="Currency", v-model="currency", :items="currency_items")
        v-row
          v-col
            v-btn(block, text, @click="dialog = false") Cancel
          v-col
            v-btn(
              type="submit",
              block,
              color="secondary",
              :disabled="!amount",
              :loading="loading"
            ) Pull
</template>

<script>
export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      amount: 1.0,
      currency: "usd",
      currency_items: ["usd", "cad"],
      loading: false,
    };
  },
  methods: {
    open() {
      if (!this.biz) return;
      this.dialog = true;
      this.amount = 1.0;
      this.currency = "usd";
      this.$nextTick(() => this.$refs.focus.focus());
    },
    async save() {
      if (!this.biz || !this.amount) return;
      this.loading = true;
      const data = {
        bizId: this.biz._id,
        amount: parseFloat(this.amount),
        currency: this.currency,
      };
      try {
        await this.axios.post("/charge/pullFromBiz", data);
        this.$toast.success("Fund pulled");
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
StandardChart(
  :input="chartData",
  chartType="bar",
  maxWidth="100%",
  :loading="loading"
)
  template(v-slot:header)
    v-btn(icon, small, color="secondary", @click="$emit('refresh')")
      v-icon(small) mdi-sync
  simple-table
    thead
      tr
        th Year
        th Total
        th.text-right Count
        th.text-right Average
        th.text-right Growth
    tbody
      tr(v-for="(item, index) in sorted", :key="index")
        td {{ item.year }}
        td {{ item.total | currency }}
        td.text-right {{ item.count }}
        td.text-right {{ (item.total / item.count) | currency }}
        td.text-right(:class="{ 'red--text': item.growth < 0 }") {{ item.growth }}%
</template>

<script>
export default {
  props: ["items", "currency", "loading"],
  computed: {
    sorted() {
      return this.items.toSorted((a, b) => b.year - a.year);
    },
    chartData() {
      return {
        title: `Hardware & Service Growth (${this.currency})`,
        labels: this.items.map((o) => o.year),
        values: this.items.map((o) => o.total),
        isCurrency: true,
      };
    },
  },
};
</script>

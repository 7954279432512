<template lang="pug">
Page(title="Online Annual Charts")
  v-row.mb-3(wrap, dense)
    v-col(cols="12", sm="6")
      StandardChart(:input="totalData")
    v-col(cols="12", sm="6")
      StandardChart(:input="countData")
  Table(:report="result", title="Monthly Online Sales Summary", :year="year")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return {
      year: null,
      result: [],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    totalData() {
      if (!this.result) return null;
      const months = this.result.map((o) => o.month);
      const values = this.result.map((o) => Math.round(o.total * 100) / 100);
      return {
        title: "Montly Sales",
        labels: months,
        values,
        isCurrency: true,
      };
    },
    countData() {
      if (!this.result) return null;
      const months = this.result.map((o) => o.month);
      return {
        title: "Montly #Sales",
        labels: months,
        values: this.result.map((o) => o.count),
        isCurrency: false,
      };
    },
  },
  methods: {
    selectYear(year) {
      this.year = year;
      this.load();
    },
    async load() {
      if (!this.year) return;
      const params = { year: this.year };
      try {
        const url = "/orders/online/selflane/annual";
        const { data } = await this.axios.post(url, params);
        if (!data) return;
        this.result = _.chain(data)
          .sortBy((o) => o.month)
          .sortBy((o) => o.year)
          .value();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

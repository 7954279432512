
import OnlineAnnual from './OnlineAnnual/index.vue'
import Transactions from './Transactions/index.vue'
import Reservation from './Reservation/index.vue'

import GiftCard from './GiftCard/index.vue'

import OrdersOnline from './Orders/Online/index.vue'
import OrdersOnlineSearch from './Orders/Online/Search/index.vue'
import OrdersOnlineLast4Week from './Orders/Online/Last4Week/index.vue'
import OrdersOnlineCharts from './Orders/Online/Charts/index.vue'

import OrdersStore from './Orders/Store/index.vue'
import OrdersDelivery from './Orders/Delivery/index.vue'

import Promotions from './Promotion/index.vue'

import Returns from './Returns/index.vue'

import ChargeBack from './ChargeBack/index'
import Event from './Event/index.vue'

// stripe charge
import StripeCharge from './StripeCharge/index.vue'
import StripeChargeDay from './StripeCharge/Day/index.vue'
import StripeChargeYear from './StripeCharge/Year/index.vue'

export default [{
  path: '/onlineAnnual',
  component: OnlineAnnual,
  meta: { auth: 'corp' }
}, {
  path: '/reservations',
  component: Reservation,
  meta: { auth: 'corp' }
}, {
  path: '/transactions',
  component: Transactions,
  meta: { auth: 'corp' }
}, {
  path: '/returns',
  component: Returns,
  meta: { auth: 'corp' }
}, {
  path: '/giftcard',
  component: GiftCard,
  meta: { auth: 'corp' },
}, {
  path: '/orders/online',
  component: OrdersOnline,
  meta: { auth: 'corp' },
  children: [{
    path: '',
    redirect: 'search'
  }, {
    path: 'search',
    component: OrdersOnlineSearch
  }, {
    path: 'last4week',
    component: OrdersOnlineLast4Week
  }, {
    path: 'charts',
    component: OrdersOnlineCharts
  }]
}, {
  path: '/orders/store',
  component: OrdersStore,
  meta: { auth: 'corp' }
}, {
  path: '/orders/delivery',
  component: OrdersDelivery,
  meta: { auth: 'corp' }
}, {
  path: '/promotions',
  component: Promotions,
  meta: { auth: 'corp' }
}, {
  path: '/chargeback',
  component: ChargeBack,
  meta: { auth: 'corp' },
}, {
  path: '/event',
  component: Event,
  meta: { auth: 'corp' }
}, {
  path: '/stripecharge',
  component: StripeCharge,
  meta: { auth: 'corp' },
  children: [{
    path: '',
    redirect: 'day'
  }, {
    path: 'day',
    component: StripeChargeDay
  }, {
    path: 'year',
    component: StripeChargeYear
  }]
}]
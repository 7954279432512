<template lang="pug">
div
  CreatePartner
  List.my-3
</template>

<script>
import CreatePartner from "./CreatePartner";
import List from "./List";
import { mapActions } from "vuex";

export default {
  components: { CreatePartner, List },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setPartners"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.partner.account.list(params);
      this.setPartners(result);
    },
  },
};
</script>

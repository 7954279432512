<template lang="pug">
.my-2
  v-form(@submit.prevent="submit()")
    v-text-field(
      v-model="search",
      :label="label",
      placeholder="Enter business name",
      append-icon="search",
      clearable,
      flat,
      :hideDetails="hideDetails",
      dense,
      color="secondary",
      :loading="loading",
      @click:append="submit"
    )
  v-list.py-0.list-box(dense, v-if="items?.length")
    v-list-item(
      v-for="(item, index) in items",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item.name }}
          span.ml-1(v-if="item.short_name") ({{ item.short_name }})
        v-list-item-subtitle(v-if="item.address") {{ item.address | address("street") }} {{ item.address | address("city, state") }}
      v-list-item-action(v-if="item._id == selection")
        v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  props: {
    autoSelect: { type: Boolean, default: true }, // auto select if only one result
    hideDetails: { type: Boolean, default: false },
    label: { type: String, default: "Search Business" },
    exclude: { type: Array, default: () => [] }, // list of ids to exclude
  },
  data() {
    return {
      search: "",
      bizs: [],
      selection: null,
      loading: false,
    };
  },
  computed: {
    items() {
      return this.bizs?.filter((b) => !this.exclude.includes(b._id));
    },
  },
  watch: {
    search(val) {
      if (val.length >= 4) this.submit();
    },
  },
  methods: {
    async submit() {
      this.search = this.search.trim();
      if (!this.search || this.search.length < 3) {
        return this.$toast.error("Please enter a name with at least 3 letters");
      }
      if (this.loading) return;
      this.loading = true;
      const params = {
        criteria: { name: { $regex: this.search, $options: "i" } },
        select: "name short_name address",
      };
      try {
        this.bizs = await this.$api.biz.list(params);
        if (!this.bizs?.length) {
          this.$toast.error("No business found");
        } else if (this.bizs?.length == 1 && this.autoSelect) {
          this.select(this.bizs[0]); // select the only one
        } else {
          this.bizs?.sort((a, b) => a.name.localeCompare(b.name));
        }
      } catch (e) {
        this.$toast.error(e.response?.data || "Error searching business");
      }
      this.loading = false;
    },
    select(biz) {
      if (!biz) return;
      this.selection = biz._id;
      this.$emit("select", biz);
    },
  },
};
</script>

<style lang="sass" scoped>
.list-box
  max-height: 200px
  overflow-y: auto
  &::-webkit-scrollbar
    display: none
</style>

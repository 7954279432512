<template lang="pug">
div
  .d-flex.flex-row.flex-wrap.align-center.mb-3
    MonthSelector(@select="load")
    v-spacer
    div(style="width: 300px")
      v-text-field(
        v-model="search",
        append-icon="search",
        label="Search",
        solo,
        dense,
        hide-details
      )
  v-subheader Charged Items
  Table(:items="charged_items", :search="search")
  v-subheader Free Items
  Table(:items="free_items", :search="search")
  v-subheader Paying Clients with no activities
  ClientTable.my-5(:items="filtered_clients")
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import Table from "./Table";
import ClientTable from "./../Clients/List/Table";

export default {
  components: { Table, ClientTable },
  data() {
    return {
      selectedBiz: null,
      search: "",
      docs: [],
    };
  },
  computed: {
    ...mapGetters(["bizservices"]),
    items() {
      return _.chain(this.docs)
        .map((doc) => {
          let online = doc.orders.selflane || 0;
          let store = doc.orders.in_store || 0;
          let third_party = doc.orders.third_party || 0;
          const found = this.bizservices.find((o) => o.biz == doc.biz);
          let pos = found?.pos?.status || false;
          let pos_amount = found?.amount || 0;
          return {
            biz: doc.biz,
            biz_name: doc.biz_name,
            online,
            store,
            third_party,
            pos,
            pos_amount,
          };
        })
        .value();
    },
    charged_items() {
      return this.items?.filter((o) => o.pos_amount) || [];
    },
    free_items() {
      return this.items?.filter((o) => !o.pos_amount) || [];
    },
    filtered_clients() {
      return _.filter(this.bizservices, (o) => {
        if (!o.amount) return false;
        return !this.items.some((item) => item.biz == o.biz);
      });
    },
  },
  mounted() {
    this.loadPOS();
  },
  methods: {
    ...mapActions(["setBizServices"]),
    async loadPOS() {
      let params = { criteria: { ended: { $not: { $gt: 0 } } } };
      const result = await this.$api.service.account.list(params);
      this.setBizServices(result);
    },
    async load(month) {
      // convert month from 'YYYY-MM' to yyyymm as integer
      if (!month?.name) return;
      const month_num = parseInt(month.name.replace("-", ""));
      const params = {
        criteria: { month: month_num },
        select: "orders biz biz_name",
      };
      try {
        this.docs = await this.$api.b.opsmonth.list(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

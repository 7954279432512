<template lang="pug">
div
  .sl-title Playground
  GetDelivery
</template>

<script>
import GetDelivery from "/libs/components/DoorDash/GetDelivery";

export default {
  components: { GetDelivery },
};
</script>
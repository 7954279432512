<template lang="pug">
div
  YearSelector.mb-3(@select="setYear")
  Table.my-3(:items="unpaid_items", title="Unpaid Orders")
  Table.my-3(:items="charged_items", title="Paid Orders")
  PaybyCheck
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";
import Table from "./Table";
import PaybyCheck from "./PaybyCheck";

export default {
  components: { Table, PaybyCheck },
  computed: {
    ...mapGetters(["bizorders"]),
    charged_items() {
      return this.bizorders?.filter((o) => o.payment.charge?.amount) || [];
    },
    unpaid_items() {
      return this.bizorders?.filter((o) => !o.payment.charge?.amount) || [];
    },
  },
  mounted() {
    this.setBizOrders([]);
  },
  methods: {
    ...mapActions(["setBizOrders"]),
    async setYear(year) {
      const begin = moment().year(year).startOf("year").unix() * 1000;
      const end = moment().year(year).endOf("year").unix() * 1000;
      const params = { criteria: { created: { $gte: begin, $lt: end } } };
      const result = await this.$api.bizOrder.list(params);
      this.setBizOrders(result);
    },
  },
};
</script>

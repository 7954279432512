<template lang="pug">
v-btn(
  rounded,
  outlined,
  small,
  color="secondary",
  @click="correctZone()",
  :loading="loading"
) Correct Zone
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async correctZone() {
      this.loading = true;
      const { data } = await this.axios.post(
        "/delivery/dailysummary/correctZone"
      );
      this.$toast.success(data);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
.form-container
  .text-center.mb-2(v-if="isLogin")
    .form-title Sign in
    .sl-secondary-text New to Selflane?
      a.ml-3(@click="toggleLogin") Sign up
  .text-center.mb-2(v-else)
    .form-title Sign up
    .sl-secondary-text Already have an account?
      a.ml-3(@click="toggleLogin") Sign in
  GoogleSignIn.my-2
  v-form(@submit.prevent="submit")
    v-text-field(
      label="E-mail",
      v-model.trim="email",
      :error-messages="emailErrors",
      @blur="$v.email.$touch()",
      required,
      prepend-icon="email"
    )
    v-text-field(
      name="password",
      type="password",
      label="Password",
      v-model="password",
      :error-messages="passwordErrors",
      required,
      @blur="$v.password.$touch()",
      prepend-icon="lock_outline"
    )
    v-text-field(
      name="passwordRep",
      type="password",
      label="Repeat Password",
      v-model="passwordRep",
      :error-messages="passwordRepErrors",
      required,
      @blur="$v.passwordRep.$touch()",
      prepend-icon="lock_outline",
      v-if="!isLogin"
    )
    v-btn.mt-4(
      block,
      depressed,
      :disabled="$v.$invalid",
      type="submit",
      color="primary",
      :loading="loading",
      style="height: 44px"
    )
      span(v-if="isLogin") Sign in
      span(v-else) Sign up
  span.caption.red--text(v-if="error") {{ error }}
  v-alert.mt-3(
    type="success",
    border="left",
    icon="mdi-information",
    dense,
    :value="isGmail",
    transition="scale-transition"
  ) You can use Google Sign In
  ForgotPWD(v-if="isLogin")
  v-dialog(v-model="codeDialog", persistent, max-width="400")
    v-card
      v-card-title Enter Verification Code
      v-form(@submit.prevent="verify")
        v-card-text
          .subtitle-2.mb-3 A 6-digit code has been sent to
          v-alert(color="success", dark, dense) {{ email }}
          ResendCode(:email="email")
          v-text-field(
            label="Verification Code",
            v-model="verificationCode",
            required,
            v-mask="codeMask",
            hint="6-digit code",
            persistent-hint
          )
          span.caption.red--text(v-if="error") {{ error }}
        v-card-actions
          v-btn(
            block,
            type="submit",
            :disabled="verifying || !verificationCode",
            :loading="verifying",
            color="secondary"
          ) submit
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { validationMixin } from "vuelidate";
import { email, minLength, required, sameAs } from "vuelidate/lib/validators";
import ForgotPWD from "./ForgotPWD";
import GoogleSignIn from "./GoogleSignIn";
import ResendCode from "./ResendCode";

export default {
  components: { GoogleSignIn, ForgotPWD, ResendCode },
  mixins: [validationMixin],
  validations() {
    if (this.isLogin) {
      return {
        email: { required, email },
        password: { required },
      };
    } else {
      return {
        email: { required, email },
        password: { required, minLength: minLength(6) },
        passwordRep: {
          sameAsPassword: sameAs("password"),
        },
      };
    }
  },
  data() {
    return {
      isLogin: true,
      email: "",
      password: "",
      passwordRep: "",
      loading: false,
      error: null,
      codeDialog: false,
      codeMask: "######",
      verificationCode: "",
      verifying: false,
    };
  },
  computed: {
    isGmail() {
      if (this.email) return this.email.indexOf("gmail") > -1;
      return false;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      if (!this.isLogin) {
        !this.$v.password.minLength &&
          errors.push("Password should be at least 6 characters.");
      }
      return errors;
    },
    passwordRepErrors() {
      const errors = [];
      if (!this.$v.passwordRep.$dirty) return errors;
      !this.$v.passwordRep.sameAsPassword &&
        errors.push("Repeat password is not the same");
      return errors;
    },
  },
  methods: {
    toggleLogin() {
      this.isLogin = !this.isLogin;
      this.error = null;
    },
    submit() {
      this.error = "";
      if (this.isLogin) {
        this.login();
      } else {
        this.signup();
      }
    },
    login() {
      this.loading = true;
      this.error = null;
      let redirect = { name: "Home" };
      if (this.$auth.redirect()) {
        redirect = this.$auth.redirect().from;
      }
      this.$auth.login({
        url: "/users/login",
        method: "POST",
        fetchUser: true,
        headers: { "Content-Type": "application/json" },
        data: { email: this.email, password: this.password },
        redirect: redirect,
        rememberMe: true,
        success() {
          this.loading = false;
          EventBus.$emit("login");
        },
        error(err) {
          this.loading = false;
          if (err.response) {
            this.error = err.response.data;
          } else {
            this.error = "Failed";
          }
        },
      });
    },
    async signup() {
      this.loading = true;
      const params = { email: this.email, password: this.password };
      try {
        await this.$api.user.create(params);
        this.codeDialog = true;
      } catch (err) {
        if (err.response) {
          if (err.response.data.indexOf("You have already signed up") != -1) {
            this.codeDialog = true;
          } else {
            this.error = err.response.data;
          }
        }
      }
      this.loading = false;
    },
    async verify() {
      this.error = null;
      this.verifying = true;
      const url =
        "/users/verify?a=" + this.email + "&b=" + this.verificationCode;
      try {
        await this.axios.get(url);
        this.codeDialog = false;
        this.login();
      } catch (err) {
        if (err.response) this.error = err.response.data;
      }
      this.verifying = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.form-container
  background: #ffffff
  border-radius: 4px
  overflow: hidden
  padding: 30px

.form-title
  font-weight: 500
  font-size: 30px
  margin-bottom: 7px
</style>

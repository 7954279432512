<template lang="pug">
v-card-text
  div(v-if="hasBizOrg")
    .subtitle-2 {{ cartOrder.name }}
    div {{ cartOrder.address | address }}
    v-btn(small, outlined, color="secondary", @click="clear()") Change
  div(v-else)
    BizSelector(@select="selectBiz")
    OrgSelector(@select="selectOrg")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
    ...mapGetters("catalog", ["stateTaxes"]),
    hasBizOrg() {
      return this.cartOrder.biz || this.cartOrder.organization;
    },
  },
  mounted() {
    this.loadTaxRates();
  },
  methods: {
    ...mapActions(["setCartTaxRate"]),
    selectBiz(biz) {
      this.cartOrder.biz = biz._id;
      this.cartOrder.organization = null;
      this.cartOrder.email = biz.email;
      this.cartOrder.name = biz.name;
      this.setAddress(biz.address);
    },
    selectOrg(org) {
      this.cartOrder.organization = org._id;
      this.cartOrder.email = org.email;
      this.cartOrder.name = org.name;
      this.setAddress(org.address);
    },
    setAddress(address) {
      this.cartOrder.address = address;
      const state = address.state;
      const country = address.country;
      const found = this.stateTaxes.find(
        (o) => o.country == country && o.state == state
      );
      this.setCartTaxRate(found.rate || 0);
    },
    async loadTaxRates() {
      const params = { criteria: {} };
      const result = await this.$api.catalog.stateTax.list(params);
      this.$store.dispatch("catalog/setStateTaxes", result);
    },
    async clear() {
      this.cartOrder.biz = null;
      this.cartOrder.organization = null;
      this.cartOrder.email = null;
      this.cartOrder.name = null;
      this.cartOrder.address = null;
      this.setCartTaxRate(0);
    },
  },
};
</script>

<template lang="pug">
v-menu(offset-y, :nudge-right="40")
  template(v-slot:activator="{ on }")
    v-text-field(
      v-on="on",
      :label="label",
      v-model="content",
      readonly,
      :hint="hint",
      :persistent-hint="persistentHint"
    )
  v-date-picker(v-model="content", @change="change", scrollable, :min="min")
</template>
<script>
/// pick a date
/// input value should be YYYY-MM-DD

export default {
  name: "DatefieldPicker",
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "Date" },
    hint: { type: String, default: "" },
    persistentHint: { type: Boolean, default: false },
    min: { type: String, default: null },
  },
  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value() {
      this.content = this.value;
    },
  },
  methods: {
    change() {
      this.$emit("input", this.content);
    },
  },
};
</script>

const state = {
  contacts: [],
  logs: [],
  leads: [],
  opportunities: [],
  interactions: [],
  service_logs: [],
  tasks: [],
}

const getters = {
  contacts: state => state.contacts,
  logs: state => state.logs,
  leads: state => state.leads,
  opportunities: state => state.opportunities,
  interactions: state => state.interactions,
  service_logs: state => state.service_logs,
  tasks: state => state.tasks,
}

const actions = {
  setContacts: ({ commit }, data) => { commit("setContacts", data); },
  updateContact: ({ commit }, data) => { commit("updateContact", data); },
  addContact: ({ commit }, data) => { commit("addContact", data); },
  removeContact: ({ commit }, data) => { commit("removeContact", data); },
  setLogs: ({ commit }, data) => { commit("setLogs", data); },
  setLeads: ({ commit }, data) => { commit("setLeads", data); },
  updateLead: ({ commit }, data) => { commit("updateLead", data); },
  addLead: ({ commit }, data) => { commit("addLead", data); },
  removeLead: ({ commit }, data) => { commit("removeLead", data); },
  setOpportunities: ({ commit }, data) => { commit("setOpportunities", data); },
  updateOpportunity: ({ commit }, data) => { commit("updateOpportunity", data); },
  addOpportunity: ({ commit }, data) => { commit("addOpportunity", data); },
  removeOpportunity: ({ commit }, data) => { commit("removeOpportunity", data); },
  setInteractions: ({ commit }, data) => { commit("setInteractions", data); },
  updateInteraction: ({ commit }, data) => { commit("updateInteraction", data); },
  addInteraction: ({ commit }, data) => { commit("addInteraction", data); },
  removeInteraction: ({ commit }, data) => { commit("removeInteraction", data); },
  setServiceLogs: ({ commit }, data) => { commit("setServiceLogs", data); },
  updateServiceLog: ({ commit }, data) => { commit("updateServiceLog", data); },
  addServiceLog: ({ commit }, data) => { commit("addServiceLog", data); },
  removeServiceLog: ({ commit }, data) => { commit("removeServiceLog", data); },
  setTasks: ({ commit }, data) => { commit("setTasks", data); },
  updateTask: ({ commit }, data) => { commit("updateTask", data); },
  addTask: ({ commit }, data) => { commit("addTask", data); },
  removeTask: ({ commit }, data) => { commit("removeTask", data); },
};

const mutations = {
  setContacts(state, data) {
    state.contacts = data;
  },
  updateContact(state, data) {
    state.contacts = state.contacts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addContact(state, data) {
    state.contacts.push(data);
  },
  removeContact(state, data) {
    state.contacts = state.contacts.filter(o => o._id !== data._id);
  },
  setLogs(state, data) {
    state.logs = data;
  },
  setLeads(state, data) {
    state.leads = data;
  },
  updateLead(state, data) {
    state.leads = state.leads.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addLead(state, data) {
    state.leads.push(data);
  },
  removeLead(state, data) {
    state.leads = state.leads.filter(o => o._id !== data._id);
  },
  setOpportunities(state, data) {
    state.opportunities = data;
  },
  updateOpportunity(state, data) {
    state.opportunities = state.opportunities.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addOpportunity(state, data) {
    state.opportunities.push(data);
  },
  removeOpportunity(state, data) {
    state.opportunities = state.opportunities.filter(o => o._id !== data._id);
  },
  setInteractions(state, data) {
    state.interactions = data;
  },
  updateInteraction(state, data) {
    state.interactions = state.interactions.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addInteraction(state, data) {
    state.interactions.push(data);
  },
  removeInteraction(state, data) {
    state.interactions = state.interactions.filter(o => o._id !== data._id);
  },
  setServiceLogs(state, data) {
    state.service_logs = data;
  },
  updateServiceLog(state, data) {
    state.service_logs = state.service_logs.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addServiceLog(state, data) {
    state.service_logs.push(data);
  },
  removeServiceLog(state, data) {
    state.service_logs = state.service_logs.filter(o => o._id !== data._id);
  },
  setTasks(state, data) {
    state.tasks = data;
  },
  updateTask(state, data) {
    state.tasks = state.tasks.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addTask(state, data) {
    state.tasks.push(data);
  },
  removeTask(state, data) {
    state.tasks = state.tasks.filter(o => o._id !== data._id);
  },
};

export default { namespaced: true, state, getters, actions, mutations }
<template lang="pug">
div
  IconBtn(icon="mdi-pencil", @click="open()", title="")
  v-dialog(v-model="dialog", width="300")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Edit URL
      v-card-text
        v-form(v-on:submit.prevent="save")
          v-text-field(v-model="url", ref="focus")
          v-btn(
            type="submit",
            block,
            :disabled="!url",
            color="secondary",
            :loading="loading"
          ) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      loading: false,
      url: "",
    };
  },
  methods: {
    ...mapActions(["setBiz"]),
    open() {
      if (!this.biz) return this.$toast.error("No biz is set");

      this.url = this.biz.url;
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    },
    async save() {
      if (!this.biz || !this.url) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.biz._id },
        action: { $set: { url: this.url.trim() } },
      };
      try {
        const result = await this.$api.biz.update(params);
        this.setBiz(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-card(flat, v-if="biz?.schedule")
    v-toolbar(dense, flat)
      .subtitle-2 Special Hours
      v-spacer
      IconBtn(@click="edit()", title="Add", icon="mdi-plus")
    simple-table
      tbody
        tr(
          v-for="(item, index) in biz.schedule.specialdays",
          :key="index",
          @click.stop="edit(item)",
          role="button"
        )
          th {{ item.date }}
            span.ml-1.text--secondary.nowrap {{ item.date | holiday }}
          td
            .d-flex.flex-row.justify-end.align-center
              span {{ item | dayschedule }}
              DeleteItem.ml-3(:item="item", :biz="biz", @updated="updated")
  Form(:biz="biz", ref="form", @updated="updated")
</template>

<script>
import DeleteItem from "./DeleteItem";
import Form from "./Form";

export default {
  components: { Form, DeleteItem },
  props: { biz: { type: Object, required: true } },
  methods: {
    edit(item) {
      this.$refs.form.open(item);
    },
    updated($event) {
      this.$emit("updated", $event);
    },
  },
};
</script>

const state = {
  partners: []
};

const getters = {
  partners: state => state.partners
};

const actions = {
  setPartners: ({ commit }, data) => { commit("setPartners", data); },
  updatePartner: ({ commit }, data) => { commit("updatePartner", data); },
  addPartner: ({ commit }, data) => { commit("addPartner", data); },
  removePartner: ({ commit }, data) => { commit("removePartner", data); },
};

const mutations = {
  setPartners(state, data) { state.partners = data; },
  updatePartner(state, data) {
    state.partners = state.partners.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addPartner(state, data) { state.partners.push(data); },
  removePartner(state, data) {
    state.partners = state.partners.filter(o => o._id !== data._id);
  },
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  v-btn.mr-3(@click="create", outlined, color="secondary", small) Create
  RunDailyJobs2
  RunMonday.ml-3
</template>

<script>
import FileSaver from "file-saver";
import RunDailyJobs2 from "./RunDailyJobs2";
import RunMonday from "./RunMonday";

export default {
  components: { RunDailyJobs2, RunMonday },
  methods: {
    async create() {
      const { data } = await this.axios.get("/corp/tool/sitemap/create");
      var blob = new Blob([data], { type: "application/xml" });
      FileSaver.saveAs(blob, "sitemap.xml");
    },
  },
};
</script>
<template lang="pug">
Page(title="Documents")
  template(v-slot:header)
    YearSelector(@select="setYear")
  v-card(flat)
    simple-table
      thead
        tr
          th Date
          th Biz
          th Type
          th.text-right status

      tbody
        tr(
          v-for="(item, index) in items",
          :key="index",
          @click="handleView(item)",
          role="button"
        )
          td {{ item.created | date }}
          td {{ item.biz_name }}
          td {{ item.file_type }}
          td.text-right {{ getStatus(item.status) }}
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["bizDocuments"]),
    items() {
      return this.bizDocuments.toSorted((a, b) => b.created - a.created);
    },
  },
  methods: {
    ...mapActions(["setBizDocuments"]),
    async setYear(year) {
      if (year) this.year = year;
      const user = this.$auth.user();
      if (!user) return;
      const begin = new Date(this.year, 0, 1).getTime();
      const end = new Date(this.year + 1, 0, 1).getTime();
      const params = { criteria: { created: { $gt: begin, $lte: end } } };
      const result = await this.$api.b.document.list(params);
      this.setBizDocuments(result);
    },
    getStatus(value) {
      if (value == 0) {
        return "Created";
      } else if (value == -1) {
        return "Rejected";
      } else if (value == 1) {
        return "Accepted";
      }
    },
    handleView(item) {
      EventBus.$emit("show-biz-document", item);
    },
  },
};
</script>


<template lang="pug">
.my-2
  v-form(@submit.prevent="submit()")
    v-text-field(
      v-model="search",
      label="Search Member Program",
      append-icon="search",
      @click:append="submit()",
      solo,
      hide-details,
      dense
    )
  v-list.py-0(dense, max-height="200", style="overflow: scroll")
    v-list-item(
      v-for="(item, index) in programs",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item.name }}
        v-list-item-subtitle(v-if="item.address") {{ item.short_name }} {{ item.address.city }}, {{ item.address.state }}
      v-list-item-action(v-if="item._id == selection")
        v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  data() {
    return {
      search: "",
      programs: [],
      selection: null,
    };
  },
  methods: {
    async submit() {
      const search = this.search.trim();
      if (!search || search.length < 3) {
        return this.$toast.error("Please enter a name with at least 3 letters");
      }
      const params = {
        criteria: { name: { $regex: search, $options: "i" } },
      };
      this.programs = await this.$api.memberProgram.list(params);
    },
    select(item) {
      if (!item) return;
      this.selection = item._id;
      this.$emit("select", item);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500", @keydown.esc="dialog = false")
  v-card(v-if="chain")
    v-toolbar(flat, dense)
      .subtitle-2 Edit Chain
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(label="Name", v-model.trim="chain.name")
        v-row(dense, v-if="isInternal")
          v-col
            v-text-field(label="URL", v-model.trim="chain.url")
          v-col
            v-select(label="Level", v-model="chain.level", :items="levelItems")
        v-textarea(label="Description", v-model.trim="chain.shortd", rows="3")
        div(v-if="isInternal")
          EnterprisePicker(v-model="chain.enterprise")
          v-subheader Locations {{ chain.bizs.length }}
          simple-table
            tbody
              tr(
                v-for="(item, index) in chain.bizs",
                :key="index",
                draggable="true",
                @dragstart="drag(index, $event)",
                @dragend="dragend($event)",
                @drop="drop(index, $event)",
                @dragover.prevent=""
              )
                td
                  v-icon(small) mdi-menu
                td {{ bizName(item) }}
                td.text-right
                  v-btn(icon, small, @click="removeBiz(index)")
                    v-icon(small, color="error") mdi-delete
          BizSelector(@select="selectBiz", :exclude="chain.bizs")
        v-btn.mt-2(block, color="secondary", type="submit", :loading="loading") Save
        .mt-5.text-center(v-if="chain._id && isInternal")
          .title If you want to delete this chain, please click below.
          v-btn(color="error", block, text, @click="handleDelete()") Delete Chain
</template>

<script>
import EnterprisePicker from "/libs/components/Enterprise/Picker/index";

export default {
  name: "ChainForm",
  components: { EnterprisePicker },
  data() {
    return {
      chain: null,
      dialog: false,
      loading: false,
      defaultBizs: [],
      levelItems: [
        { text: "Promoted", value: 1 },
        { text: "Regular", value: 0 },
      ],
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
  },
  methods: {
    open(val) {
      if (!val) {
        this.chain = {
          name: "",
          url: "",
          shortd: "",
          level: 0,
          bizs: [],
          enterprise: null,
        };
      } else {
        this.chain = JSON.parse(JSON.stringify(val));
      }
      this.dialog = true;
      this.loadDefault();
    },
    async loadDefault() {
      if (!this.chain || !this.chain.bizs) return;
      const params = {
        criteria: { _id: { $in: this.chain.bizs } },
        select: "name",
      };
      this.defaultBizs = await this.$api.biz.list(params);
    },
    bizName(id) {
      if (!this.defaultBizs) return id;
      return this.defaultBizs.find((o) => o._id == id)?.name || id;
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend(e) {
      e.target.style.opacity = 1;
    },
    drop(index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.chain.bizs.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.chain.bizs.length
      )
        return;
      let chain_bizs = JSON.parse(JSON.stringify(this.chain.bizs));
      let tmp = JSON.parse(JSON.stringify(chain_bizs[this.draggingIndex]));
      chain_bizs.splice(this.draggingIndex, 1);
      chain_bizs.splice(index, 0, tmp);
      this.chain.bizs = chain_bizs;
    },
    removeBiz(index) {
      this.chain.bizs.splice(index, 1);
    },
    selectBiz(biz) {
      if (!this.chain || !biz) return;
      if (!this.chain.bizs) this.chain.bizs = [biz._id];
      if (this.chain.bizs.indexOf(biz._id) < 0) this.chain.bizs.push(biz._id);
      this.defaultBizs.push(biz);
    },
    async submit() {
      this.loading = true;
      try {
        if (this.chain._id) {
          const action = {
            name: this.chain.name,
            url: this.chain.url,
            shortd: this.chain.shortd,
            level: this.chain.level,
            bizs: this.chain.bizs,
            enterprise: this.chain.enterprise,
          };
          const result = await this.$api.chain.account.put(
            this.chain._id,
            action
          );
          this.$store.dispatch("entp/updateChain", result);
        } else {
          const result = await this.$api.chain.account.create(this.chain);
          this.$store.dispatch("entp/addChain", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    handleDelete() {
      if (!this.chain._id) return;
      confirm("Do you want to delete this chain? This can not be reverted!") &&
        this.deleteChain();
    },
    async deleteChain() {
      if (!this.chain._id) return;
      this.loading = true;
      try {
        const result = await this.$api.chain.account.delete(this.chain._id);
        this.$store.dispatch("entp/removeChain", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-select(label="Price Level", v-model="content", :items="items")
</template>

<script>
import PriceRangeJSON from "../../json/PriceLevel.json";
export default {
  name: "PriceRange",
  props: ["value"],
  data() {
    return {
      content: this.value,
      items: JSON.parse(JSON.stringify(PriceRangeJSON)),
    };
  },
  watch: {
    value() {
      this.content = this.value;
    },
    content(newval, oldval) {
      if (newval == oldval) return;
      this.$emit("input", this.content);
    },
  },
};
</script>

<template lang="pug">
IconBtn(
  @click="handleSubmit",
  :loading="loading",
  icon="mdi-delete",
  title="Cancel Order & Payment",
  color="error"
)
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["bizorder"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateBizOrder"]),
    handleSubmit() {
      confirm("Are you sure you want to cancel this order?") && this.submit();
    },
    async submit() {
      if (!this.bizorder) return;
      this.loading = true;
      const params = { id: this.bizorder._id };
      try {
        const result = await this.$api.bizOrder.cancel(params);
        this.updateBizOrder(result);
        this.$toast.success("Order cancelled");
        this.$emit("cancelled", result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-card.mb-5(flat)
    Header
    Table
  Form
  Notice.my-5(v-if="showNotice")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";
import Header from "./Header";
import Notice from "./Notice";
import Table from "./Table";

export default {
  components: { Header, Table, Form, Notice },
  props: {
    showNotice: { type: Boolean, default: true },
  },
  computed: { ...mapGetters(["biz"]) },
  mounted() {
    this.load();
    EventBus.$on("switch-biz", this.load);
  },
  destroyed() {
    EventBus.$off("switch-biz", this.load);
  },
  methods: {
    ...mapActions(["setBizAccesses"]),
    async load() {
      if (!this.biz) return;
      const params = { criteria: { biz: this.biz._id } };
      const result = await this.$api.b.access.list(params);
      this.setBizAccesses(result);
    },
  },
};
</script>

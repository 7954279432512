import Accounts from './Accounts/index.vue'

import Terminals from './Terminals/index.vue'
import TerminalLocations from './Terminals/Locations/index.vue'
import TerminalPayments from './Terminals/Payments/index.vue'
import Files from './Files/index.vue'

export default [
  { path: '/stripe/accounts', component: Accounts, meta: { auth: 'corp' } },
  {
    path: '/stripe/terminals', component: Terminals, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'locations' },
      { path: 'locations', component: TerminalLocations },
      { path: 'payments', component: TerminalPayments }
    ]
  },
  { path: '/stripe/files', component: Files, meta: { auth: 'corp' } }
]
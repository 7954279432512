<template lang="pug">
IconBtn(
  @click="recreate()",
  icon="mdi-sync",
  title="Recreate",
  :loading="loading"
)
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async recreate() {
      this.loading = true;
      await this.axios.get("/city/recreate");
      this.loading = false;
      this.$emit("done");
    },
  },
};
</script>

<template lang="pug">
v-btn(@click="submit()", small, v-if="!payout.deliv") Update Deliv
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["payout"],
  methods: {
    ...mapActions(["updateDriverPayout"]),
    async submit() {
      if (!this.payout) return;
      const params = {
        payoutId: this.payout._id,
      };
      try {
        const { data } = await this.axios.post(
          "/driver/payouts/updateDeliv",
          params
        );
        this.updateDriverPayout(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
Page(title="Gift Card")
  v-text-field(
    v-model="searchText",
    label="Search",
    append-icon="mdi-magnify",
    solo,
    flat,
    dense
  )
  Table(:items="items")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Table from "./Table";

export default {
  components: { Table },
  data() {
    return { searchText: "" };
  },
  computed: {
    ...mapGetters(["giftcards"]),
    items() {
      if (this.searchText) {
        return this.giftcards
          .filter((item) => {
            return item.name
              ?.toLowerCase()
              ?.includes(this.searchText.toLowerCase());
          })
          .toSorted((a, b) => b.created - a.created);
      }
      return this.giftcards.toSorted((a, b) => b.created - a.created);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setGiftCards", "updateGiftCard"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.giftcard.list(params);
      this.setGiftCards(result);
    },
  },
};
</script>

const pricing = {
  pos: 29.99,
  ccm: 20,
  qbk: 15,
  kds: 20,
  inventory: 0,
  member_basic: 30,
  member_advanced: 120,
  rsv: 5,
  dt: 30,
  ff: 10,
  third_party: 30
}

function evaluate(service) {
  if (!service) return
  let amount = 0;
  if ((service?.ended && service.ended > 0) || service?.waive) {
    service.amount = amount
    return
  }
  if (service.pos?.status) amount += pricing.pos;
  if (service.ccm?.status) amount += pricing.ccm * service.ccm.quantity;
  if (service.qbk) amount += pricing.qbk;
  if (service.kds) amount += pricing.kds;
  if (service.rsv) amount += pricing.rsv;
  if (service.dt) amount += pricing.dt;

  if (service.member == 1) {
    amount += pricing.member_basic
  } else if (service.member == 2) {
    amount += pricing.member_advanced
  }
  if (service.ff) amount += pricing.ff;
  if (service.third_party?.status) amount += pricing.third_party
  if (service.discount?.amount) amount -= service.discount.amount
  if (service.custom?.amount) amount += service.custom.amount
  service.amount = amount;
}

const state = {
  bizservice: null,
  bizservices: [],
  totalvalue: 0,
  service_items: [],
  service_plans: [],
  service_subscriptions: [],
}

const getters = {
  bizservice: state => state.bizservice,
  bizservices: state => state.bizservices,
  service_subscriptions: state => state.service_subscriptions,
  bizservicetotalvalue: state => state.totalvalue,
  bizservicepricing: () => pricing,
  service_items: state => state.service_items,
  service_plans: state => state.service_plans,
}

const actions = {
  setBizService: ({ commit }, data) => { commit('setBizService', data) },
  setBizServices: ({ commit }, data) => { commit('setBizServices', data) },
  updateBizService: ({ commit }, data) => { commit('updateBizService', data) },
  addBizService: ({ commit }, data) => { commit('updateBizService', data) },
  setBizServiceItems: ({ commit }, data) => { commit('setBizServiceItems', data) },
  updateBizServiceItem: ({ commit }, data) => { commit('updateBizServiceItem', data) },
  addBizServiceItem: ({ commit }, data) => { commit('addBizServiceItem', data) },
  removeBizServiceItem: ({ commit }, data) => { commit('removeBizServiceItem', data) },
  setBizServicePlans: ({ commit }, data) => { commit('setBizServicePlans', data) },
  updateBizServicePlan: ({ commit }, data) => { commit('updateBizServicePlan', data) },
  addBizServicePlan: ({ commit }, data) => { commit('addBizServicePlan', data) },
  setBizServiceSubscriptions: ({ commit }, data) => { commit('setBizServiceSubscriptions', data) },
  updateBizServiceSubscription: ({ commit }, data) => { commit('updateBizServiceSubscription', data) },
  addBizServiceSubscription: ({ commit }, data) => { commit('addBizServiceSubscription', data) },
  removeBizServiceSubscription: ({ commit }, data) => { commit('removeBizServiceSubscription', data) },
}

const mutations = {
  setBizService(state, data) {
    evaluate(data)
    state.bizservice = data
  },
  setBizServices(state, data) {
    state.bizservices = data.map(o => {
      evaluate(o)
      return o;
    });
    state.totalvalue = state.bizservices.reduce((a, b) => a + (b.amount || 0), 0)
  },
  updateBizService(state, data) {
    evaluate(data)
    let index = state.bizservices.findIndex(o => o._id === data._id)
    if (index !== -1) {
      state.bizservices.splice(index, 1, data)
    } else {
      state.bizservices.push(data)
    }
    if (state.bizservice?._id === data._id) state.bizservice = data
    state.totalvalue = state.bizservices.reduce((a, b) => a + (b.amount || 0), 0)
  },
  addBizService(state, bizservice) {
    evaluate(bizservice)
    state.bizservices.push(bizservice)
    state.totalvalue = state.bizservices.reduce((a, b) => a + (b.amount || 0), 0)
  },
  setBizServiceItems(state, data) {
    state.service_items = data
  },
  updateBizServiceItem(state, data) {
    state.service_items = state.service_items.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  addBizServiceItem(state, data) {
    state.service_items.push(data)
  },
  removeBizServiceItem(state, data) {
    state.service_items = state.service_items.filter(o => o._id !== data._id)
  },
  setBizServicePlans(state, data) {
    state.service_plans = data
  },
  updateBizServicePlan(state, data) {
    state.service_plans = state.service_plans.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  addBizServicePlan(state, data) {
    state.service_plans.push(data)
  },
  setBizServiceSubscriptions(state, data) {
    state.service_subscriptions = data
  },
  updateBizServiceSubscription(state, data) {
    state.service_subscriptions = state.service_subscriptions.map(o => {
      return o._id === data._id ? data : o;
    });
  },
  addBizServiceSubscription(state, data) {
    state.service_subscriptions.push(data)
  },
  removeBizServiceSubscription(state, data) {
    state.service_subscriptions = state.service_subscriptions.filter(o => o._id !== data._id)
  }
}

export default { state, getters, actions, mutations }
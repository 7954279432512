<template lang="pug">
#map(ref="map")
</template>

<script>
export default {
  name: "MarkerMap", // a map that shows markers with input geometries
  props: {
    geometries: { type: Array, default: () => [] },
    center: { type: Object, default: () => ({ lat: 37.0902, lng: -95.7129 }) }, // center of USA
    zoom: { type: Number, default: 3.5 },
  },
  data() {
    return {
      markers: [],
      icon: "https://maps.google.com/mapfiles/kml/shapes/placemark_circle_highlight.png",
    };
  },
  watch: {
    geometries() {
      this.drawMarkers();
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.center,
        zoom: this.zoom,
        mapTypeControl: false,
        streetViewControl: false,
      });
    },
    setMapOnAll(map) {
      this.markers?.forEach((o) => o.setMap(map));
    },
    drawMarkers() {
      // reset center
      this.map.setCenter(this.center);
      // reset zoom
      this.map.setZoom(this.zoom);
      // reset markers
      this.setMapOnAll(null);
      this.markers = [];
      this.geometries?.forEach((o) => {
        const position = o;
        const icon = this.icon;
        const title = o?.title || "";
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker({ position, icon, title });
        this.markers.push(marker);
      });
      this.setMapOnAll(this.map);
    },
  },
};
</script>

<style lang="sass" scoped>
#map
  min-height: 400px
  width: 100%
  background: gray
  border-radius: 12px
</style>

<template lang="pug">
div
  .d-flex.flex-row.justify-end
    v-btn(:loading="loading", @click="load", icon, small, color="secondary")
      v-icon(small) mdi-sync
  v-row(wrap, dense)
    v-col(cols="12", sm="6")
      StandardChart(
        :input="totalData",
        chartType="bar",
        :loading="loading",
        :legend="true",
        max-width="100%"
      )
    v-col(cols="12", sm="6")
      StandardChart(
        :input="countData",
        chartType="bar",
        :loading="loading",
        :legend="true",
        max-width="100%"
      )
</template>

<script>
import _ from "underscore";
export default {
  props: ["year"],
  data() {
    return {
      thisyear: [],
      lastyear: [],
      loading: false,
      totalData: null,
      countData: null,
    };
  },
  watch: {
    year() {
      this.load();
    },
  },
  methods: {
    getChart(name, title, isCurrency = false) {
      if (!this.thisyear?.length) return null;
      const weeks = this.thisyear.map((o) => o.week);
      const min = Math.min(...weeks);
      const max = Math.max(...weeks);
      const labels = _.range(min, max + 1);
      const thisyear = {
        label: `${this.year}`,
        backgroundColor: "#3f51b5",
        data: _.map(labels, (label) => {
          const item = _.findWhere(this.thisyear, { week: label });
          return item ? item[name] : 0;
        }),
      };
      const lastyear = {
        label: `${this.year - 1}`,
        backgroundColor: "#50a948",
        data: _.map(labels, (label) => {
          const item = _.findWhere(this.lastyear, { week: label });
          return item ? item[name] : 0;
        }),
      };
      return {
        title,
        labels: labels,
        datasets: [lastyear, thisyear],
        isCurrency: isCurrency,
      };
    },
    async load() {
      if (!this.year) return;
      this.loading = true;
      try {
        const params = { year: this.year };
        const { data } = await this.axios.post("/orders/onlineweekly", params);
        this.thisyear = data;
      } catch (err) {
        //
      }
      try {
        const params = { year: this.year - 1 };
        const { data } = await this.axios.post("/orders/onlineweekly", params);
        this.lastyear = data;
      } catch (err) {
        //
      }
      this.totalData = this.getChart("total", "Online Sales", true);
      this.countData = this.getChart("count", "# of Orders");
      this.loading = false;
    },
  },
};
</script>

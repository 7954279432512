<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Stripe Terminal
        th(v-for="(month, index) in months", :key="index + 'month'") {{ month }}
        th Total
    tbody
      tr(v-for="(group, index) in groups", :key="index")
        th {{ group.title }}
        td(v-for="(item, mindex) in group.months", :key="mindex")
          span(v-if="group.title != 'Count'") {{ item.total | currency }}
          span(v-else) {{ item.total }}
        td
          span(v-if="group.title != 'Count'") {{ group.total | currency }}
          span(v-else) {{ group.total }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["items"],
  data() {
    return {
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    groups() {
      const dicts = [
        { name: "selflane_net", title: "Selflane Net" },
        { name: "stripe_fee", title: "Stripe Fee" },
        { name: "application_fee_amount", title: "Merchant Pay" },
        { name: "amount", title: "Volumn" },
        { name: "count", title: "Count" },
      ];
      const filtered = this.items.filter(
        (o) => o.category == "stripe_terminal" && o.currency
      );
      return dicts.map((o) => this.getItems(filtered, o));
    },
  },
  methods: {
    getItems(data, dict) {
      const months = _.map(_.range(1, 13, 1), (month) => {
        const filtered = data.filter((item) => item.month % 100 == month);
        const items = _.pluck(filtered, dict.name);
        const total = items.reduce((a, b) => a + b, 0);
        return { month: month, total: total };
      });
      const total = months.reduce((a, b) => a + b.total, 0);
      let title = dict.title;
      return { title: title, months: months, total: total };
    },
  },
};
</script>
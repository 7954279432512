import Home from './index.vue'
import Month from './Month'
import OrgInvoice from './OrgInvoice'
import Payment from './Payment'
import Growth from './Growth'
import YoY from './YoY'

export default [{
  path: '/invoice',
  component: Home,
  meta: { auth: 'corp' },
  children: [
    { path: '', redirect: 'month' },
    { path: 'month', component: Month },
    { path: 'org_invoice', component: OrgInvoice },
    { path: 'payment', component: Payment },
    { path: 'growth', component: Growth },
    { path: 'yoy', component: YoY }
  ]
}]
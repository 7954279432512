<template lang="pug">
div
  v-data-table(
    :headers="headers",
    :items="payments",
    @click:row="show",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ (item.created * 1000) | date }}
    template(v-slot:item.amount="{ item }")
      span {{ (item.amount / 100) | currency }}
    template(v-slot:item.application_fee_amount="{ item }")
      span {{ (item.application_fee_amount / 100) | currency }}
    template(v-slot:item.action="{ item }")
      v-btn(
        v-if="item.status >= 0 && item.status < 2",
        x-small,
        color="secondary",
        @click="capture(item)"
      ) Capture
  AutoCapture.my-5
</template>

<script>
import _ from "underscore";
import AutoCapture from "./AutoCapture";

export default {
  components: { AutoCapture },
  data() {
    return {
      payments: [],
      headers: [
        { text: "Created", value: "created" },
        { text: "ID", value: "stripeId" },
        { text: "Amount", value: "amount" },
        { text: "Fee", value: "application_fee_amount" },
        { text: "Stripe Fee", value: "stripe_fee" },
        { text: "Net", value: "net" },
        { text: "Dest.", value: "on_behalf_of" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", align: "end" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {}, limit: 10 };
      this.payments = await this.$api.stripe.paymentIntent.list(params);
    },
    async capture(item) {
      const params = { stripeId: item.stripeId };
      try {
        const result = await this.$api.stripe.paymentIntent.custom(
          "capture",
          params
        );
        this.payments = _.map(this.payments, (o) => {
          if (o._id == result._id) return result;
          else return o;
        });
      } catch (err) {
        this.$toast.error(err.response?.data || err.message || "Failed");
      }
    },
    async show(item) {
      if (!item) return;
      const params = { stripeId: item.stripeId };
      const result = await this.$api.stripe.paymentIntent.retrieve(params);
      console.log(result);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", v-on="on") Send Topic to All Owners
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Send to All Owners
    v-card-text
      v-select(:items="city_items", v-model="city_id", dense)
      v-select(:items="items", v-model="sel_sample", dense)
      p {{ content_short }}
      v-btn(
        color="secondary",
        block,
        @click="send()",
        :disabled="!valid",
        :loading="loading"
      ) Send Emails
</template>
<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    cities: [],
    city_id: null,
    sel_sample: null,
    loading: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters(["email_samples"]),
    items() {
      return _.chain(this.email_samples)
        .map((o) => {
          return { text: o.subject, value: o._id };
        })
        .sortBy("subject")
        .value();
    },
    valid() {
      return this.sel_sample != undefined;
    },
    content_short() {
      if (this.sel_sample) {
        const found = this.email_samples?.find((o) => o._id == this.sel_sample);
        return found?.content_short || "";
      }
      return "";
    },
    city_items() {
      return _.chain(this.cities)
        .map((o) => {
          return {
            value: o._id,
            text: [o.country, o.state, o.city].join("/"),
          };
        })
        .sortBy("text")
        .value();
    },
  },
  mounted() {
    this.loadCities();
  },
  methods: {
    async send() {
      if (!this.sel_sample) return;
      let city;
      if (this.city_id) {
        city = _.find(this.cities, (o) => o._id == this.city_id);
      }
      this.loading = true;
      const params = { sample_id: this.sel_sample, city: city };
      try {
        const { data } = await this.axios.post("/email/mailAllOwner", params);
        this.$toast.success(data);
        this.city_id = null;
        this.sel_sample = null;
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    async loadCities() {
      const params = {
        criteria: { nbiz: { $gte: 1 } },
        select: "city state country",
      };
      try {
        this.cities = await this.$api.city.list(params);
      } catch (err) {
        //
      }
    },
  },
};
</script>

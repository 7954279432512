<template lang="pug">
v-row.mt-3(wrap)
  v-col(
    cols="12",
    sm="6",
    md="3",
    v-for="(item, index) in products",
    :key="index"
  )
    Product(:product="item") 
</template>

<script>
import Product from "./Product";

export default {
  components: { Product },
  props: ["products"],
};
</script>

<template lang="pug">
v-card(flat)
  v-text-field.mx-5(v-model="searchText", label="Search", clearable)
  v-data-table(
    :items="items",
    :headers="headers",
    :search="searchText",
    @click:row="edit",
    role="button",
    sort-by="name",
    mobile-breakpoint=0,
    dense,
    :footer-props="{ itemsPerPageOptions: [50, 100, -1] }"
  )
    template(v-slot:item.name="{ item }")
      .d-flex.flex-row
        v-icon.mr-1(x-small, color="green", v-if="item.third_party?.status") mdi-circle-medium
        span {{ item.name }}
    template(v-slot:item.third_party.last_sync="{ item }")
      div {{ item.third_party.last_sync | datetime("smart") }}
    template(v-slot:item.action="{ item }")
      SyncMenu(:item="item")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import SyncMenu from "/libs/components/Biz/Chowly/SyncMenu";

export default {
  components: { SyncMenu },
  props: ["items"],
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Chowly ID", value: "third_party.store_id" },
        { text: "Sync", value: "third_party.last_sync", filterable: false },
        { text: "Message", value: "third_party.message", filterable: false },
        { text: "Actions", value: "action", align: "end" },
      ],
    };
  },
  methods: {
    edit(item) {
      if (!item) return;
      EventBus.$emit("show-service", item);
    },
  },
};
</script>

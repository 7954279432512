<template lang="pug">
v-select(
  :label="title",
  v-bind:value="value",
  @input="handleInput",
  :items="items",
  dense,
  menu-props="auto",
  hide-details
)
</template>

<script>
import _ from "underscore";

export default {
  props: {
    title: { type: String, default: "" },
    value: { type: Number, default: 0 },
    min: { type: Number, default: 0 },
  },
  computed: {
    items() {
      const minutesToTime = this.$options.filters.minutesToTime;
      // allow up to 5 am next day
      return _.map(_.range(this.min, 1741, 15), (o) => {
        return { value: o, text: minutesToTime(o) };
      });
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="location")
    v-toolbar(dense, flat)
      .subtitle-2 Update Location: {{ location.display_name }}
    v-form(@submit.prevent="submit()")
      v-card-text
        v-text-field(v-model="display_name", label="Display Name")
      v-card-actions
        v-btn(
          type="submit",
          block,
          color="secondary",
          :loading="loading",
          :disabled="!display_name"
        ) Update
</template>

<script>
export default {
  data() {
    return {
      display_name: "",
      dialog: false,
      location: null,
      loading: false,
    };
  },
  methods: {
    open(location) {
      this.location = location;
      this.display_name = "";
      this.dialog = true;
    },
    async submit() {
      if (!this.display_name)
        return this.$toast.error("Please enter a display name");

      this.loading = true;
      const params = {
        docId: this.location._id,
        terminal_id: this.location.terminal_id,
        display_name: this.display_name,
      };
      try {
        await this.$api.stripe.terminalLocation.update(params);
        this.dialog = false;
        this.$emit("updated");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
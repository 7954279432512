import Home from './index.vue'
import Accounts from './Accounts/index.vue'
import Payouts from './Payouts/index.vue'
import Partners from './Partners/index.vue'
import GeoService from './GeoService/index.vue'

import Growth from './Growth/index.vue'
import GrowthDashboard from './Growth/Dashboard/index.vue'
import GrowthGenerate from './Growth/Generate/index.vue'
import GrowthHourly from './Growth/Hourly/index.vue'

export default [
  {
    path: '/delivery', component: Home, meta: { auth: 'corp' },
    children: [
      { path: 'accounts', component: Accounts },
      { path: 'payouts', component: Payouts },
      { path: 'partners', component: Partners },
      { path: 'geoservice', component: GeoService }
    ]
  }, {
    path: '/delivery/growth', component: Growth, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'dashboard' },
      { path: 'dashboard', component: GrowthDashboard },
      { path: 'generate', component: GrowthGenerate },
      { path: 'hourly', component: GrowthHourly }
    ]
  }
]
<template lang="pug">
.body-2(v-if="customer")
  .d-flex.flex-row.align-center
    v-icon.mx-3(small) mdi-account
    .text-truncate {{ customer.name }} {{ customer.phone | phone }}
  .d-flex.flex-row.align-center
    v-icon.mx-3(small) mdi-map-marker
    .text-truncate {{ customer.address | address }}
</template>

<script>
export default {
  props: ["delivOrder"],
  computed: {
    customer() {
      if (!this.delivOrder) return null;
      return this.delivOrder.customer;
    },
  },
};
</script>

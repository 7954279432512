<template lang="pug">
div
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      .subtitle-2 POS Devices and Printer Assignments
      v-spacer
      v-btn(@click="load", icon, color="secondary", :disabled="loading", small)
        v-icon(small) mdi-refresh
    div(v-if="recent_devices?.length")
      Table(
        :printers="printers",
        :devices="recent_devices",
        @select="handleSelect",
        @showPrinterLog="showPrinterLog"
      )
    div(v-if="old_devices?.length")
      v-divider
      v-card-text
        .red--text POS Devices that have not been used in over a year
      Table(
        :printers="printers",
        :devices="old_devices",
        @select="handleSelect",
        @showPrinterLog="showPrinterLog"
      )
    v-card-text
      table.info-table
        tbody
          tr
            td
              RoleAvatar(color="success")
            td Highlighted Device has auto printing enabled for online orders
          tr(v-if="hasMore")
            td
              RoleAvatar(color="warning")
            td More than one device has auto printing enabled. This may cause duplicate prints.
  PrinterActionDialog(ref="action")
  DeviceDetail(ref="detail")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PrinterActionDialog from "./PrinterActionDialog";
import Table from "./Table";
import DeviceDetail from "/libs/components/POS/DeviceDetail";

export default {
  components: { Table, PrinterActionDialog, DeviceDetail },
  data() {
    return {
      loading: false,
      printers: [],
    };
  },
  computed: {
    ...mapGetters(["biz", "pos_devices"]),
    hasMore() {
      return this.pos_devices?.filter((o) => o.auto_print_online).length > 1;
    },
    old_devices() {
      // cut off is one year ago in milliseconds
      const cutOff = new Date().getTime() - 365 * 24 * 60 * 60 * 1000;
      return this.pos_devices?.filter((o) => o.last_visit < cutOff);
    },
    recent_devices() {
      // cut off is one year ago in milliseconds
      const cutOff = new Date().getTime() - 365 * 24 * 60 * 60 * 1000;
      return this.pos_devices?.filter((o) => o.last_visit >= cutOff);
    },
  },
  methods: {
    ...mapActions(["setPOSDevices"]),
    async load() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const params = { criteria: { biz: this.biz._id } };
        const result = await this.$api.posDevice.list(params);
        this.setPOSDevices(result);
        await this.loadPrinters();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async loadPrinters() {
      const ids = this.pos_devices?.map((o) => o.deviceUUID);
      if (!ids?.length) return;
      const params = { criteria: { uuid: { $in: ids } } };
      this.printers = await this.$api.posPrinter.list(params);
    },
    handleSelect(item) {
      this.$refs.detail.load(item);
    },
    showPrinterLog(uuid) {
      this.$refs.action.load(uuid);
    },
  },
};
</script>
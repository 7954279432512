<template lang="pug">
div(v-if="item")
  v-list-item(@click="open()") Open
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: ["item"],
  methods: {
    async open() {
      if (!this.item) return;
      const params = { fileid: this.item._id };
      const name = this.item.filetype + "-" + this.item.year + ".pdf";
      try {
        const file = await this.axios.post("/tax/taxfile/open", params, {
          responseType: "blob",
        });
        FileSaver.saveAs(file.data, name);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(icon, small, @click="open")
      v-icon(small) mdi-pencil
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit Statement Descriptor
    v-card-text
      div {{ biz.name }}
      div {{ biz.address | address }}
      v-form.mt-5(@submit.prevent="save")
        v-text-field(
          v-model="statement_descriptor",
          label="Statement Descriptor",
          counter="22"
        )
        v-btn(
          type="submit",
          block,
          :disabled="!statement_descriptor",
          color="secondary",
          :loading="loading"
        ) Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["biz"],
  data() {
    return {
      dialog: false,
      statement_descriptor: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["setBiz"]),
    open() {
      if (!this.biz) return;
      const statement_descriptor = this.biz?.payments?.statement_descriptor;
      if (statement_descriptor) {
        this.statement_descriptor = statement_descriptor;
      } else if (this.biz?.name) {
        const regex = /[^a-zA-Z]/g;
        let text = "";
        const items = this.biz.name.split(" ");
        for (var i = 0; i < items.length; i++) {
          let item = items[i];
          if (text.length + item.length > 22 && text.length > 0) {
            break;
          }
          text += item;
        }
        text = text.replace(regex, "");
        this.statement_descriptor = text.slice(0, 22);
      }
      this.dialog = true;
      this.loading = false;
    },
    async save() {
      if (!this.biz || !this.statement_descriptor) return;
      this.loading = true;
      const params = {
        biz_id: this.biz._id,
        statement_descriptor: this.statement_descriptor,
      };
      try {
        const { data } = await this.axios.post(
          "/bizs/actions/setStatementDescriptor",
          params
        );
        this.setBiz(data);
        this.$toast.success("Statement Descriptor Updated");
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  .d-flex.flex-row.align-center
    v-overflow-btn(v-model="taxType", :items="type_items", dense, hide-details)
    v-spacer
    Create
  List(:products="selectedProducts")
  EditForm
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./Create";
import List from "./List/index";
import EditForm from "./EditForm";

export default {
  components: { Create, List, EditForm },
  data() {
    return {
      taxType: "tg",
      type_items: [
        { value: "tg", text: "Tangible Goods" },
        { value: "digital", text: "Digital" },
        { value: "business_service", text: "Business Service" },
        { value: "all", text: "All" },
        { value: "unknown", text: "Unknown" },
      ],
    };
  },
  computed: {
    ...mapGetters("catalog", ["products"]),
    selectedProducts() {
      if (this.taxType == "all") return this.products;
      if (this.taxType == "unknown") {
        return this.products
          .filter((o) => !o.taxType)
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      return this.products
        .filter((o) => o.taxType == this.taxType)
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        const params = { criteria: {} };
        const result = await this.$api.catalog.product.list(params);
        this.$store.dispatch("catalog/setProducts", result);
      } catch (e) {
        this.$store.dispatch("catalog/setProducts", []);
      }
    },
  },
};
</script>

<template lang="pug">
.s-vstack
  StatsGrowth(:api="api", :title="title")
  Growth
</template>

<script>
import Growth from "./Growth/index";

export default {
  components: { Growth },
  data() {
    return {
      api: "/bizs/stats/growth",
      title: "Biz Growth",
    };
  },
};
</script>

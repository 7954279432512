<template lang="pug">
simple-table
  thead
    tr
      th Name
      th Percentage
      th Courses
      th.nowrap Exclude Types
      th Type
      th.text-right Status
      th
  tbody
    tr(
      v-for="(item, index) in biz.tax_items",
      :key="index",
      @click="edit(item)",
      role="button",
      draggable="true",
      @dragstart="drag(index, $event)",
      @dragend="dragend($event)",
      @drop="drop(index, $event)",
      @dragover.prevent=""
    )
      td
        .d-flex.flex-row.align-center
          v-icon.mr-2(x-small) mdi-menu
          span.nowrap {{ item.name }}
      td {{ item.percentage }}%
      td {{ getCourses(item.courses) }}
      td {{ getExcludeOrderTypes(item.excludeOrderTypes) }}
      td {{ item.type | item_type }}
      td.text-right {{ item.status ? "On" : "Off" }}
      td.text-right
        v-btn(icon, color="error", @click.stop="handleRemove(item)")
          v-icon(small) mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus";
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  filters: {
    item_type(val) {
      if (val == 0) return "Exclusive";
      return "Inclusive";
    },
  },
  computed: {
    ...mapGetters(["biz"]),
  },
  methods: {
    getExcludeOrderTypes(items) {
      if (!items) return "";
      return items.join(",");
    },
    getCourses(items) {
      if (!items?.length) return "All";
      return _.chain(items)
        .map((item) => this.biz.courses?.find((o) => o._id == item)?.name)
        .compact()
        .sortBy()
        .value()
        .join(", ");
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: async function (index) {
      if (index == this.draggingIndex) return;
      if (!this.biz || !this.biz.tax_items) return;
      if (index < 0 || index >= this.biz.tax_items.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.biz.tax_items.length
      )
        return;
      let tax_items = JSON.parse(JSON.stringify(this.biz.tax_items));
      let tmp = JSON.parse(JSON.stringify(tax_items[this.draggingIndex]));
      tax_items.splice(this.draggingIndex, 1);
      tax_items.splice(index, 0, tmp);
      const action = { $set: { tax_items } };
      const result = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", result);
    },
    edit(item) {
      EventBus.$emit("edit-tax-item", item);
    },
    handleRemove(item) {
      confirm("Do you want to delete this tax rate?") && this.remove(item);
    },
    async remove(item) {
      if (!item?._id) return;
      const action = { $pull: { tax_items: { _id: item._id } } };
      const result = await this.$api.biz.put(this.biz._id, action);
      this.$store.dispatch("setBiz", result);
    },
  },
};
</script>
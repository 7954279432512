<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  template(v-slot:activator="{ on }")
    IconBtn(@click="open")
  v-form(@submit.prevent="submit")
    v-card
      v-card-title Basic Profile
      v-card-text
        v-row(dense)
          v-col(cols="6")
            PriceLevelPicker(v-model="price_range")
          v-col(cols="6")
            v-text-field(
              label="Website",
              v-model="website",
              @blur="$v.website.$touch()",
              :error-messages="websiteErrors"
            )
          v-col(cols="4")
            v-menu(
              v-model="menu",
              :nudge-right="40",
              transition="scale-transition",
              offset-y
            )
              template(v-slot:activator="{ on }")
                v-btn(v-on="on", depressed, small) {{ industry | industry }}
                  v-icon(right) mdi-chevron-down
              v-list
                v-list-item-group(
                  v-model="industry",
                  v-for="(group, index) in placeTypeGroups",
                  :key="index"
                )
                  v-subheader {{ group.title }}
                  v-list-item(
                    v-for="(item, index) in group.items",
                    :key="index",
                    :value="item.value"
                  )
                    v-list-item-title {{ item.text }}
          v-col(cols="8", v-if="industry < 100")
            v-select(
              label="Categories",
              v-model="cates",
              multiple,
              :items="sortedCateItems",
              item-text="name",
              item-value="value"
            )
        v-textarea(
          label="Description",
          v-model="description",
          rows="3",
          @blur="$v.description.$touch()",
          :error-messages="descriptionErrors",
          counter="1000"
        )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :disabled="$v.$invalid") Save
</template>

<script>
import _ from "underscore";
import { validationMixin } from "vuelidate";
import { maxLength, url } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    website: { url },
    description: { maxLength: maxLength(1000) },
  },
  data() {
    return {
      dialog: false,
      menu: false,
      price_range: 0,
      website: "",
      industry: 0,
      cates: [],
      description: "",
      placeTypeGroups: [
        {
          title: "Food & Drink",
          items: [
            { text: "Restaurant", value: 0 },
            { text: "Food Truck", value: 1 },
            { text: "Catering Service", value: 2 },
            { text: "Commercial Kitchen", value: 3 },
            { text: "Virtual Kitchen", value: 4 },
          ],
        },
        {
          title: "Beauty & Health",
          items: [
            { text: "Nail Salon", value: 100 },
            { text: "Hair Salon", value: 101 },
          ],
        },
        {
          title: "Auto Industry",
          items: [{ text: "Car Wash", value: 200 }],
        },
        {
          title: "Home Improvement",
          items: [{ text: "Nursery", value: 300 }],
        },
        {
          title: "Retail",
          items: [{ text: "Retail", value: 400 }],
        },
      ],
      cateItems: [
        { name: "Other", value: "9999" },
        { name: "American", value: "1" },
        { name: "Chinese", value: "2" },
        { name: "French", value: "3" },
        { name: "Japanese", value: "4" },
        { name: "Korean", value: "5" },
        { name: "Greek", value: "6" },
        { name: "Indian", value: "7" },
        { name: "Italian", value: "8" },
        { name: "Mexican", value: "9" },
        { name: "Thai", value: "10" },
        { name: "Vietnamese", value: "11" },
        { name: "Brazilian", value: "12" },
        { name: "Cajun/Creole", value: "13" },
        { name: "Caribbean", value: "14" },
        { name: "Cuban", value: "15" },
        { name: "Greek and Mediterranean", value: "16" },
        { name: "Hawaiian", value: "17" },
        { name: "Brewpub", value: "18" },
        { name: "Vegan", value: "19" },
        { name: "Peruvian", value: "20" },
        { name: "Asian Fusion", value: "21" },
        { name: "Ice Cream Parlor", value: "22" },
        { name: "Spanish", value: "23" },
        { name: "New Mexican", value: "24" },
        { name: "Canadian", value: "25" },
        { name: "Coffeehouse", value: "26" },
        { name: "Kosher", value: "27" },
      ],
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    sortedCateItems() {
      return _.sortBy(this.cateItems, "name");
    },
    websiteErrors() {
      const errors = [];
      if (!this.$v.website.$dirty) return errors;
      !this.$v.website.url &&
        errors.push("Invalid website address. Should be http://example.com");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.maxLength && errors.push("Max length of 500");
      return errors;
    },
  },
  methods: {
    open() {
      if (!this.biz) return;
      this.dialog = true;
      this.price_range = this.biz.price_range;
      this.website = this.biz.website;
      this.industry = this.biz.industry;
      this.cates = this.biz.cates;
      this.description = this.biz.description;
      this.$v.$reset();
    },
    async submit() {
      if (!this.biz) return;
      this.dialog = false;
      const action = {
        $set: {
          price_range: this.price_range,
          website: this.website,
          industry: this.industry,
          cates: this.cates,
          description: this.description,
        },
      };
      try {
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update");
      }
    },
  },
};
</script>

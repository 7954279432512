<template lang="pug">
div
  .subtitle-2 Printing
  simple-table.my-table
    thead
      tr
        th Time
        th Action
        th Device
    tbody
      tr(v-for="(item, index) in items", :key="index + '-row'")
        td {{ item.created | datetime("smart", timezone) }}
        td {{ item.title }}
        td {{ item.device }}
</template>

<script>
export default {
  props: {
    order: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
  },
  methods: {
    async load() {
      const orderId = this.order?._id;
      if (!orderId) return;
      this.loading = true;
      const params = { order_id: orderId };
      try {
        const res = await await this.$api.orderAction.printLog.retrieve(params);
        this.items = res?.items || [];
      } catch (e) {
        this.items = [];
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
// wrapping lines
.my-table
  th, td
    white-space: normal
    word-wrap: break-word
</style>
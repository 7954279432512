<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="opportunity")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }}
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(
          v-model="opportunity.company_name",
          label="Company Name",
          required
        )
        v-row(wrap)
          v-col(cols="6")
            PhoneField(v-model="opportunity.phone")
          v-col(cols="6")
            v-text-field(v-model="opportunity.email", label="Email")
          v-col(cols="6")
            SizeSelector(v-model="opportunity.size")
          v-col(cols="6")
            TagInput(v-model="opportunity.tags")
        GoogleAddressField(v-model="opportunity.address")
        v-row
          v-col(cols="6")
            ContactPicker(v-model="opportunity.contact")
          v-col(cols="6")
            v-select(
              :disabled="!isInternal",
              v-model="opportunity.organization",
              :items="organization_items",
              label="Organization"
            )
        v-text-field(v-model="opportunity.notes", label="Notes", multi-line)
        .link-box(v-if="opportunity.biz")
          .subtitle-2 Biz: {{ opportunity.biz_name }}
          v-btn(icon, small, @click="clearBiz", color="error")
            v-icon(small) mdi-close
        BizSelector(
          @select="selectBiz",
          v-else,
          label="Assign a Biz",
          :auto-select="false"
        )
      v-card-actions
        v-btn(color="secondary", :loading="loading", type="submit") Save
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import ContactPicker from "/libs/components/CRM/Contact/Picker";
import SizeSelector from "/libs/components/CRM/FormInput/SizeSelector";
import TagInput from "/libs/components/CRM/FormInput/TagInput";

export default {
  components: { ContactPicker, SizeSelector, TagInput },
  data() {
    return {
      dialog: false,
      loading: false,
      opportunity: {},
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations", "salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    title() {
      return this.opportunity._id ? "Edit Opportunity" : "New Opportunity";
    },
    organization_items() {
      return this.salesOrganizations.map((o) => ({
        text: o.name,
        value: o._id,
      }));
    },
  },
  mounted() {
    EventBus.$on("edit-opportunity", this.open);
  },
  destroyed() {
    EventBus.$off("edit-opportunity", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.opportunity = JSON.parse(JSON.stringify(data));
      } else {
        this.opportunity = {};
      }
      this.dialog = true;
    },
    selectBiz(biz) {
      this.opportunity.biz = biz._id;
      this.opportunity.biz_name = biz.name;
    },
    clearBiz() {
      this.opportunity.biz = null;
      this.opportunity.biz_name = null;
    },
    async submit() {
      const sales_person =
        this.opportunity.sales_person || this.salesPerson._id;
      const sales_person_name =
        this.opportunity.sales_person_name || this.salesPerson.first_name;
      if (!sales_person) {
        this.$toast.error("Sales person is required");
        return;
      }
      this.loading = true;
      const action = {
        company_name: this.opportunity.company_name,
        phone: this.opportunity.phone,
        email: this.opportunity.email,
        address: this.opportunity.address,
        contact: this.opportunity.contact,
        biz: this.opportunity.biz,
        biz_name: this.opportunity.biz_name,
        organization: this.opportunity.organization,
        lead: this.opportunity.lead,
        sales_person,
        sales_person_name,
        status: this.opportunity.status,
        size: this.opportunity.size,
        tags: this.opportunity.tags,
        updated: Date.now(),
      };
      try {
        if (this.opportunity._id) {
          delete action.status;
          const result = await this.$api.crm.opportunity.put(
            this.opportunity._id,
            action
          );
          this.$store.dispatch("crm/updateOpportunity", result);
          this.$emit("done", result);
        } else {
          const result = await this.$api.crm.opportunity.create(action);
          this.$store.dispatch("crm/addOpportunity", result);
          await this.convertLead(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async convertLead(opportunity) {
      if (!opportunity.lead) return;
      const action = { $set: { status: "converted" } };
      try {
        const result = await this.$api.crm.lead.put(opportunity.lead, action);
        this.$store.dispatch("crm/updateLead", result);
        this.$emit("updateLead", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
<template lang="pug">
v-container
  SubsForm
</template>

<script>
import SubsForm from "./SubsForm";

export default {
  components: { SubsForm },
};
</script>

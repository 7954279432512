<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="country")
    v-toolbar(dense, flat)
      .subtitle-2 Geo Service Country
    v-card-text
      v-form(@submit.prevent="submit")
        v-select(
          v-model="country.country",
          :items="country_items",
          label="Country"
        )
        v-row(dense)
          v-col(cols="6")
            v-text-field(
              label="Basic Rate",
              v-model="country.basicRate",
              suffix="%"
            )
          v-col(cols="6")
            v-text-field(
              label="Delivery Rate",
              v-model="country.delivery.rate",
              suffix="%"
            )
        v-row(dense, wrap)
          v-col(cols="6")
            v-text-field(
              label="Delivery Inclusive Range",
              v-model="country.delivery.inclusiveRange"
            )
          v-col(cols="6")
            v-text-field(
              label="Delivery Max Range",
              v-model="country.delivery.maxRange"
            )
          v-col(cols="6")
            PriceField(
              label="Min. Service Fee",
              v-model.number="country.delivery.minService"
            )
          v-col(cols="6")
            PriceField(
              label="Delivery Base Fee",
              v-model.number="country.delivery.fee"
            )
          v-col(cols="6")
            PriceField(
              label="Delivery Unit Fee",
              v-model.number="country.delivery.unitFee"
            )
        v-row(dense)
          v-col(cols="6")
            PriceField(
              label="Driver Pay",
              v-model.number="country.driverBasePay"
            )
          v-col(cols="6")
            PriceField(
              label="Manager Pay",
              v-model.number="country.managerBasePay"
            )
        v-btn(color="secondary", type="submit", :loading="loading", block) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      country: null,
      country_items: [
        { text: "United States", value: "US" },
        { text: "Canada", value: "CA" },
      ],
    };
  },
  mounted() {
    EventBus.$on("edit-geo-service-country", this.open);
  },
  destroyed() {
    EventBus.$off("edit-geo-service-country", this.open);
  },
  methods: {
    ...mapActions(["updateGeoServiceCountry", "addGeoServiceCountry"]),
    open(data) {
      if (data) {
        this.country = JSON.parse(JSON.stringify(data));
      } else {
        this.country = {
          country: "US",
          basicRate: 6,
          deliveryRate: 6,
          delivery: {
            inclusiveRange: 5,
            maxRange: 10,
            minService: 3,
            rate: 11,
            fee: 2.99,
            unitFee: 1.5,
          },
          driverBasePay: 4,
          managerBasePay: 1,
        };
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.country) return;
      this.loading = true;
      if (this.country._id) {
        const params = {
          criteria: { _id: this.country._id },
          action: this.country,
        };
        try {
          const { data } = await this.axios.post(
            "/geoService/countries/update",
            params
          );
          this.updateGeoServiceCountry(data);
          this.dialog = false;
        } catch (err) {
          this.$toast.error(err.response?.data || err.message);
        }
      } else {
        try {
          const { data } = await this.axios.post(
            "/geoService/countries/create",
            this.country
          );
          this.addGeoServiceCountry(data);
          this.dialog = false;
        } catch (err) {
          this.$toast.error(err.response?.data || err.message);
        }
      }
      this.loading = false;
    },
  },
};
</script>
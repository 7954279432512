<template lang="pug">
div(v-if="delivOrder && delivOrder.driver")
  .item
    .item-name Driver
    .item-detail {{ delivOrder.driver.name }} {{ delivOrder.driver.phone | phone }}
  .item
    .item-name Pick up time
    .item-detail {{ delivOrder.pickupTime | time }}
  .item
    .item-name Delivery time
    .item-detail {{ delivOrder.deliverTime | time }}
  .item
    .item-name Status
    .item-detail {{ delivOrder.status | delivStatus }}
  .item(v-if="deliv_photo")
    .item-name Delivery Photo
    .item-detail
      v-img(
        :src="deliv_photo",
        v-if="deliv_photo",
        contain,
        @click="show",
        width="200"
      )
  .sl-dash-divider.my-2
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["delivOrder"],
  computed: {
    deliv_photo() {
      if (this.delivOrder && this.delivOrder.deliv_photo)
        return "https://media.selflane.com/" + this.delivOrder.deliv_photo;
      return null;
    },
  },
  methods: {
    show() {
      EventBus.$emit("show-photo", this.deliv_photo);
    },
  },
};
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: center

  &-name
    font-size: 14px
    font-weight: 500
    color: black
    flex-grow: 1

  &-detail
    font-size: 14px
    font-weight: 500
    color: black
</style>
<template lang="pug">
v-dialog(v-model="dialog", max-width="500px", scrollable)
  v-card(v-if="order", :loading="loading")
    v-card-title Chargeback
    v-card-text
      OrderHeader(:order="order")
      Items(
        :items="chargebacks",
        :order="order",
        v-if="hasChargebacks",
        @updated="load"
      )
      Form(v-else, :order="order", @success="assign")
</template>

<script>
import Items from "./Items";
import Form from "./Form";
import { EventBus } from "@/event-bus.js";

export default {
  components: { Items, Form },
  data() {
    return {
      dialog: false,
      order: null,
      chargebacks: [],
      loading: false,
    };
  },
  computed: {
    hasChargebacks() {
      return this.chargebacks?.length;
    },
  },
  mounted() {
    EventBus.$on("chargeback-order", this.open);
  },
  destroyed() {
    EventBus.$off("chargeback-order", this.open);
  },
  methods: {
    open(data) {
      if (!data) return;
      this.order = data;
      this.chargebacks = [];
      this.load();
      this.dialog = true;
    },
    async load() {
      if (!this.order) return;
      this.loading = true;
      try {
        const params = { criteria: { order: this.order._id } };
        this.chargebacks = await this.$api.chargeback.list(params);
      } catch (err) {
        // fail silently
      }
      this.loading = false;
    },
    // Assign chargeback when the issue form is submitted
    assign(data) {
      this.chargebacks = [data];
    },
  },
};
</script>

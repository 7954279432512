<template lang="pug">
Page(title="Stripe Files")
  template(v-slot:header)
    Create
  simple-table
    tbody
      tr(v-for="doc in docs", :key="doc.id")
        td {{ doc.filename }}
        td {{ doc.purpose }}
        td {{ (doc.created * 1000) | date }}
        td {{ doc.type }}
        td {{ doc.url }}
</template>

<script>
import Create from "./Create";
export default {
  components: { Create },
  data() {
    return {
      docs: [],
    };
  },
  async mounted() {
    await this.getFiles();
  },
  methods: {
    async getFiles() {
      const { data } = await this.$api.stripe.files.list();
      this.docs = data;
    },
  },
};
</script>
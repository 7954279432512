import Home from '@/components/Home'
import Vue from 'vue'
import Router from 'vue-router'

import business from '@/components/Business/route'
import customer from '@/components/Customer/route'
import delivery from '@/components/Delivery/route'
import driver from '@/components/Driver/route'
import influencer from '@/components/Influencer/route'
import internal from '@/components/Internal/route'
import merchant from '@/components/Merchant/route'
import misc from '@/components/Misc/route'
import partner from '@/components/Partner/route'
import qa from '@/components/QA/route'
import sales from '@/components/Sales/route'
import salesForce from '@/components/SalesForce/route'
import shop from '@/components/Shop/route'
import stripe from '@/components/Stripe/route'
import tax from '@/components/Tax/route'
import login from '@/components/User/Login/route'
import timeline from '/libs/components/Timeline/route'

Vue.use(Router)

const routes = [
  { path: '/', name: 'Home', component: Home, meta: { auth: 'corp' } },
  ...login, ...customer, ...business,
  ...sales, ...delivery, ...misc,
  ...internal, ...tax, ...shop,
  ...stripe, ...driver, ...influencer,
  ...merchant, ...partner, ...salesForce,
  ...qa, ...timeline
]

export default new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
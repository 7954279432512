<template lang="pug">
v-dialog(v-model="dialog", width="900", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="podcast")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} Story
        v-spacer
        v-switch(v-model="podcast.published", label="Published")
      v-card-text
        v-row
          v-col(cols="6")
            v-text-field(label="Title", v-model="podcast.title")
          v-col(cols="6")
            DateFieldPicker(label="date", v-model="date")
        v-row
          v-col(cols="10")
            v-text-field(label="URL", v-model="podcast.url")
          v-col(cols="2")
            v-btn(@click="sluggify", text) Auto
        v-text-field(label="Youtube", v-model="podcast.youtube")
        v-text-field(label="Apple", v-model="podcast.apple")
        v-text-field(label="Spotify", v-model="podcast.spotify")
        v-md-editor.mb-2(v-model="podcast.summary")
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data() {
    return {
      podcast: null,
      dialog: false,
      loading: false,
      date: moment().toISOString().substring(0, 10),
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.podcast?._id) return "Edit an";
      return "Create a New";
    },
  },
  methods: {
    ...mapActions(["addPodcast", "updatePodcast"]),
    open(data) {
      if (!data) {
        this.podcast = {
          title: "",
          summary: "",
          url: "",
          youtube: "",
          apple: "",
          spotify: "",
          published: false,
        };
      } else {
        this.podcast = JSON.parse(JSON.stringify(data));
      }
      this.date = moment(this.podcast.date).toISOString().substring(0, 10);
      this.dialog = true;
    },
    sluggify() {
      this.podcast.url = this.podcast.title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    submit() {
      if (!this.podcast) return;
      if (!this.podcast._id) this.create();
      else this.update();
    },
    async update() {
      if (!this.podcast._id) return;
      const params = {
        criteria: { _id: this.podcast._id },
        action: {
          $set: {
            title: this.podcast.title,
            summary: this.podcast.summary,
            url: this.podcast.url,
            date: moment(this.date).valueOf(),
            youtube: this.podcast.youtube,
            apple: this.podcast.apple,
            spotify: this.podcast.spotify,
            published: this.podcast.published,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.podcast.update(params);
        this.updatePodcast(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async create() {
      if (this.podcast._id) return;
      this.podcast.date = moment(this.date).valueOf();
      this.loading = true;
      try {
        const result = await this.$api.podcast.create(this.podcast);
        this.addPodcast(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>

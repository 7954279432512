<template lang="pug">
v-dialog(v-model="dialog", width="500")
  ThirtyDay(:bizId="bizId")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import ThirtyDay from "/libs/components/Order/Performance/ThirtyDay";

export default {
  components: { ThirtyDay },
  data() {
    return {
      dialog: false,
      bizId: null,
    };
  },
  mounted() {
    EventBus.$on("show-thirty-day", this.load);
  },
  destroyed() {
    EventBus.$off("show-thirty-day", this.load);
  },
  methods: {
    async load(bizId) {
      if (!bizId) return;
      this.bizId = bizId;
      this.dialog = true;
    },
  },
};
</script>

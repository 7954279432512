<template lang="pug">
v-dialog(v-model="dialog", max-width="500", width="95%")
  v-card(v-if="member")
    v-container(grid-list-sm)
      v-form(@submit.prevent="submit")
        v-list.py-0(dense, max-height="200", style="overflow: scroll")
          v-list-item(
            v-for="(item, index) in bizs",
            :key="index",
            @click="select(item)"
          )
            v-list-item-content
              v-list-item-title {{ item.name }}
              v-list-item-subtitle(v-if="item.address") {{ item.short_name }} {{ item.address.city }}, {{ item.address.state }}
            v-list-item-action(v-if="item._id == bizId")
              v-icon.fa.fa-check(small, color="success")
        v-text-field(label="Amount", v-model.number="amount")
        v-btn(block, color="secondary", type="submit") Save
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      member: null,
      amount: 0,
      program: null,
      bizs: [],
      bizId: null,
    };
  },
  mounted() {
    EventBus.$on("award-member-points", this.edit);
  },
  destroyed() {
    EventBus.$off("award-member-points", this.edit);
  },
  methods: {
    ...mapActions(["updateMember"]),
    edit(input) {
      if (!input) return;
      this.member = JSON.parse(JSON.stringify(input));
      this.dialog = true;
      this.loadProgram();
    },
    async submit() {
      if (!this.member || !this.member._id) return;
      const params = {
        memberId: this.member._id,
        bizId: this.bizId,
        amount: this.amount,
      };
      const res = await this.axios.post("/member/actions/awardPoints", params);
      this.updateMember(res.data);
      this.dialog = false;
      this.amount = 0;
      this.program = null;
      this.bizs = [];
      this.bizId = null;
    },
    async loadProgram() {
      const params = { criteria: { _id: this.member.program } };
      this.program = await this.$api.memberProgram.retrieve(params);
      this.loadBizs();
    },
    async loadBizs() {
      const params = {
        criteria: { _id: { $in: this.program.bizs } },
        select: "name address",
      };
      this.bizs = await this.$api.biz.list(params);
    },
    select(biz) {
      this.bizId = biz._id;
    },
  },
};
</script>
<template lang="pug">
v-btn(small, text, @click="email()", v-if="canEmail", :loading="loading") Email
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    canEmail() {
      return this.item && !this.item.emailtime && this.item.email;
    },
  },
  methods: {
    ...mapActions(["updateTaxFile"]),
    async email() {
      if (!this.item) {
        return this.$toast.error("No tax file found.");
      }
      this.loading = true;
      const params = { fileid: this.item._id };
      try {
        const result = await this.$api.taxFile.custom("email", params);
        this.updateTaxFile(result);
        this.$toast.success("Email sent successfully.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="600")
  v-card(:loading="loading")
    v-card-title Manage Gallery
    v-card-text(v-if="domain")
      v-row(wrap)
        v-col(cols="4", v-for="image in domain.gallery", :key="image._id")
          ImageCell(
            :domainId="domain._id",
            :item="image",
            @deleted="domain = $event"
          )
    form(@submit.prevent="generateBlob")
      v-card-text
        croppa(
          v-model="myCroppa",
          :width="450",
          :height="300",
          :quality="2",
          initial-size="cover"
        )
        v-text-field(v-model="description", label="Description")
      v-card-actions
        v-btn(@click="dialog = false") Cancel
        v-btn(type="submit", :loading="loading") Save
</template>

<script>
import ImageCell from "./ImageCell";

export default {
  components: { ImageCell },
  data() {
    return {
      dialog: false,
      loading: false,
      domain: null,
      myCroppa: {},
      description: "",
    };
  },
  methods: {
    async open(domain) {
      this.dialog = true;
      this.loading = false;
      this.domain = await this.$api.b.domain.retrieve({
        criteria: { _id: domain._id },
      });
      this.loading = false;
    },
    generateBlob() {
      this.myCroppa.generateBlob((blob) => {
        if (!blob) {
          this.myCroppa.chooseFile();
          return;
        }
        this.submit(blob);
      }, "image/jpeg");
    },
    async submit(blob) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("domainId", this.domain._id);
        formData.append("description", this.description);
        this.domain = await this.$api.b.domain.custom(
          "uploadGallery",
          formData
        );
        this.$toast.success("Gallery updated.");
        this.reset();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    reset() {
      this.myCroppa.remove();
      this.description = "";
    },
  },
};
</script>
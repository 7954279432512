<template lang="pug">
List(:items="items")
</template>

<script>
import List from "/libs/components/Enterprise/Access/";

import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";

export default {
  components: { List },
  computed: {
    ...mapGetters("entp", ["enterprise", "accesses"]),
    items() {
      return this.accesses.filter((o) => o.enterprise == this.enterprise?._id);
    },
  },
  mounted() {
    this.load();
    EventBus.$on("enterprise:changed", this.load);
  },
  destroyed() {
    EventBus.$off("enterprise:changed", this.load);
  },
  methods: {
    async load() {
      if (!this.enterprise) return;
      try {
        const params = { criteria: { enterprise: this.enterprise._id } };
        const result = await this.$api.entp.access.list(params);
        this.$store.dispatch("entp/addAccesses", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
<template lang="pug">
IconBtn(
  @click.prevent="handleRemove()",
  color="error",
  v-if="subgroup && subgroup._id",
  :loading="loading",
  icon="mdi-delete",
  title="Remove"
) 
</template>

<script>
export default {
  props: ["subgroup"],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    handleRemove() {
      confirm("Do you really want to remove this subgroup?") && this.remove();
    },
    async remove() {
      if (!this.subgroup?._id) return;
      try {
        const result = await this.$api.catalog.subgroup.delete(
          this.subgroup._id
        );
        this.$store.dispatch("catalog/removeSubGroup", result);
        this.$emit("removed");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
<template lang="pug">
Table
  template(v-slot:content)
    div
      MonthSelector(@select="load")
</template>

<script>
import { mapActions } from "vuex";
import Table from "./../Table";

export default {
  components: { Table },
  mounted() {
    this.setBizAccounts([]);
    this.setBiz();
  },
  destroyed() {
    this.setBizAccounts([]);
    this.setBiz();
  },
  methods: {
    ...mapActions(["setBizAccounts", "setBiz"]),
    async load(month) {
      if (!month) return;
      const params = {
        criteria: {
          created: { $gte: month.range.begin, $lt: month.range.end },
        },
        select: "-menus -courses -dishes",
        limit: 1000,
      };
      try {
        const result = await this.$api.biz.list(params);
        this.setBizAccounts(result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load bizs");
      }
    },
  },
};
</script>

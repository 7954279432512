<template lang="pug">
.pa-3
  Count
  StatsGrowth(:api="api", :title="title")
</template>

<script>
import Count from "./Count";

export default {
  components: { Count },
  data() {
    return {
      api: "/users/stats/growth",
      title: "User Growth",
    };
  },
};
</script>

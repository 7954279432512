<template lang="pug">
v-card(flat, v-if="biz", :loading="loading")
  v-toolbar(dense, flat)
    .subtitle-2
      v-icon(color="primary") mdi-phone
      span.ml-2 {{ phone | phone }}
    v-spacer
    Form
  v-list.py-0(dense)
    v-list-item(@click="toggle")
      v-list-item-content
        v-list-item-title Auto call on online order
        v-list-item-subtitle We deduct 10&#162; from the payment as the fee for the phone service.
      v-list-item-action
        v-icon(:color="color") mdi-check
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    phone() {
      if (this.biz?.phones?.length) return this.biz.phones[0].number;
      return null;
    },
    call_online() {
      return this.biz.settings?.call_online == true;
    },
    color() {
      return this.call_online ? "green" : "grey lighten-2";
    },
  },
  methods: {
    async toggle() {
      if (!this.biz) return;
      const call_online = !this.call_online;
      this.loading = true;
      try {
        const action = { $set: { "settings.call_online": call_online } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
.sl-medal.count-box
  h2 Total Reviews
  h1 {{ count }}
</template>

<script>
export default {
  data() {
    return {
      count: 0,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const { count } = await this.$api.review.count(params);
      this.count = count;
    },
  },
};
</script>

<style lang="sass" scoped>
.count-box
  max-width: 190px
  margin-left: auto
  margin-right: auto
  margin-top: 20px
  margin-bottom: 12px
</style>
<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Last 4-Week Sales: {{ total | currency }}
    v-spacer
    v-chip(small, outlined) {{ totalCount }}
    v-btn.ml-2(
      icon,
      @click="load",
      small,
      color="secondary",
      :loading="loading"
    )
      v-icon(small) mdi-refresh
  simple-table
    tbody
      tr(v-for="(item, index) in result")
        td {{ index + 1 }}
        td {{ item.biz_name }}
        td {{ item.total | currency }}
        td.text-right {{ item.count }}
</template>

<script>
export default {
  data() {
    return {
      result: [],
      loading: false,
    };
  },
  computed: {
    total() {
      return this.items.reduce((a, b) => {
        return a + b.total;
      }, 0);
    },
    totalCount() {
      return this.result.reduce((a, b) => {
        return a + b.count;
      }, 0);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const { data } = await this.axios.get("/orders/last4week");
      this.result = data.toSorted((a, b) => b.total - a.total);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  :search="search",
  dense,
  sort-by="biz_name",
  role="button",
  @click:row="editService($event.biz)"
)
  template(v-slot:item.pos="{ item }")
    v-icon(small, color="success", v-if="item.pos") mdi-check-circle
  template(v-slot:item.pos_amount="{ item }")
    span {{ item.pos_amount | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["items", "search"],
  data() {
    return {
      headers: [
        { text: "Name", value: "biz_name" },
        { text: "POS", value: "pos" },
        { text: "Charge", value: "pos_amount" },
        { text: "Store", value: "store" },
        { text: "Online", value: "online" },
        { text: "Third Party", value: "third_party" },
      ],
    };
  },
  methods: {
    editService(biz) {
      EventBus.$emit("show-edit-service", biz);
    },
  },
};
</script>

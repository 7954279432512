<template lang="pug">
v-dialog(v-model="dialog", max-width="500px", scrollable)
  v-card(:loading="loading")
    v-card-title Dispute
    simple-table
      tbody
        tr
          th ID
          td {{ dispute?.id }}
        tr
          th Amount
          td {{ (dispute?.amount / 100) | currency }}
        tr
          th Charge
          td
            a(:href="url", target="_blank") {{ dispute?.charge }}
        tr
          th Status
          td {{ dispute?.status }}
        tr
          th Reason
          td {{ dispute?.reason }}
        tr(v-for="evidence in evidences", :key="evidence.key")
          th {{ evidence.key }}
          td {{ evidence.value }}
    v-card-actions
      v-btn(
        v-if="dispute?.status == 'needs_response'",
        color="secondary",
        small,
        @click="update",
        :loading="updating"
      ) Update
      AttachReceipt(:dispute="dispute", @update="dispute = $event")
      AttachCommunication(:dispute="dispute", @update="dispute = $event")
      CancelPolicy(:dispute="dispute", @update="dispute = $event")
      v-spacer
      Submit(:dispute="dispute", @update="dispute = $event")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import AttachCommunication from "./AttachCommunication";
import AttachReceipt from "./AttachReceipt";
import CancelPolicy from "./CancelPolicy";
import Submit from "./Submit";
export default {
  components: { AttachReceipt, AttachCommunication, CancelPolicy, Submit },
  data() {
    return {
      dialog: false,
      dispute: null,
      chargeback: null,
      loading: false,
      updating: false,
    };
  },
  computed: {
    url() {
      const id = this.dispute?.charge;
      if (!id) return "";
      return `https://dashboard.stripe.com/payments/${id}`;
    },
    // convert stripe dispute evidence object to array
    evidences() {
      if (!this.dispute?.evidence) return [];
      return Object.keys(this.dispute.evidence).map((key) => {
        return {
          key,
          value: this.dispute.evidence[key],
        };
      });
    },
  },
  mounted() {
    EventBus.$on("dispute-detail", this.open);
  },
  destroyed() {
    EventBus.$off("dispute-detail", this.open);
  },
  methods: {
    open(chargeback) {
      if (!chargeback) {
        return this.$toast.error("No chargeback found");
      }
      this.chargeback = chargeback;
      this.dialog = true;
      this.dispute = null;
      this.load(chargeback.disputeId);
    },
    async load(id) {
      if (!id) return;
      this.loading = true;
      try {
        this.dispute = await this.$api.stripe.disputes.retrieve({ id });
      } catch (e) {
        // fail silently
      }
      this.loading = false;
    },
    async update() {
      const status = this.dispute?.status;
      if (status != "needs_response") return;
      if (!this.chargeback?.order) return;
      this.updating = true;
      try {
        const order = await this.$api.order.retrieve({
          criteria: { _id: this.chargeback.order },
        });
        const timezone = order?.seller?.timezone || "America/Chicago";
        const customer_email_address = order?.customer?.email || "";
        const phone = this.$options.filters.phone(order?.customer?.phone || "");
        const customer_name = `${order?.customer?.name || ""} ${phone || ""}`;
        const type = order.type || "";
        const address = this.$options.filters.address(order.seller.address);
        const product_description = `Customer purchased a ${type} order from ${order.seller.name} at ${address}. The order was properly fulfilled by the restaurant.`;
        const refund_policy_disclosure = `The customer was informed of our refund policy at the time of purchase.`;
        const service_date = this.$options.filters.datetime(
          order.needed,
          null,
          timezone
        );
        this.dispute = await this.$api.stripe.disputes.update({
          id: this.dispute.id,
          params: {
            evidence: {
              customer_email_address,
              customer_name,
              product_description,
              refund_policy_disclosure,
              cancellation_policy_disclosure: refund_policy_disclosure,
              service_date,
            },
            submit: false,
          },
        });
      } catch (e) {
        console.log(e);
      }
      this.updating = false;
    },
  },
};
</script>
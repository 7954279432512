<template lang="pug">
div
  v-data-table(:items="items", :headers="headers", :search="searchText", dense)
    template(v-slot:item.amount="{ item }")
      span {{ item.amount | currency }}
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      searchText: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["driverRewardPenalties"]),
    items() {
      return _.chain(this.driverRewardPenalties)
        .groupBy((o) => {
          return o.type + " " + o.zone;
        })
        .map((o, name) => {
          return {
            name,
            amount: o.reduce((a, b) => a + b.amount, 0),
          };
        })
        .value();
    },
  },
};
</script>
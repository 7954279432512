<template lang="pug">
.s-vstack(v-if="item")
  v-img(:src="url", height="100px")
  v-text-field(
    v-model="item.description",
    label="Description",
    @blur="handleUpdate",
    dense,
    hide-details
  )
  v-btn(color="error", icon, small, @click="handleDelete", :loading="loading")
    v-icon(small) mdi-delete
</template>

<script>
export default {
  props: {
    domainId: { type: String, default: null },
    item: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    url() {
      if (this.item?.url) {
        return process.env.VUE_APP_MEDIAHOST + this.item.url;
      }
      return null;
    },
  },
  methods: {
    async handleDelete() {
      this.loading = true;
      try {
        const result = await this.$api.b.domain.custom("deleteGalleryItem", {
          domainId: this.domainId,
          galleryId: this.item._id,
        });
        this.$toast.success("Item deleted.");
        this.$emit("deleted", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async handleUpdate() {
      this.loading = true;
      try {
        const criteria = { _id: this.domainId, "gallery._id": this.item._id };
        const action = {
          $set: { "gallery.$.description": this.item.description },
        };
        const result = await this.$api.b.domain.update({ criteria, action });
        this.$toast.success("Item updated.");
        this.$emit("updated", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
.s-vstack
  v-form(@submit.prevent="search")
    .d-flex.flex-row
      v-select(dense, solo, v-model="type", :items="items")
      v-text-field.mx-3(v-model="text", label="Search", solo, dense)
      v-btn(icon, type="submit", color="secondary")
        v-icon mdi-magnify
  List
  div
    JWTTest
</template>

<script>
import { mapActions } from "vuex";
import List from "./../List";
import JWTTest from "./JWTTest";

export default {
  components: { List, JWTTest },
  data() {
    return {
      text: "",
      type: "Email",
      items: ["Email", "ID", "Stripe ID", "Phone"],
    };
  },
  computed: {
    phone() {
      return this.text.replace(/\D+/g, "");
    },
  },
  watch: {
    text() {
      // is object id
      if (this.text.length == 24 && this.text.match(/^[0-9a-fA-F]+$/)) {
        this.type = "ID";
        this.search();
        return;
      }
      // is striper customer id
      if (this.text.length == 18 && this.text.startsWith("cus_")) {
        this.type = "Stripe ID";
        this.search();
        return;
      }
      // is phone number
      if (this.phone?.length == 10) {
        this.type = "Phone";
        this.search();
        return;
      }
    },
  },
  destroyed() {
    this.setUserAccounts([]);
  },
  methods: {
    ...mapActions(["setUserAccounts"]),
    async search() {
      this.text = this.text.trim();
      if (!this.text) return;

      let criteria;
      if (this.type == "Email") criteria = { email: { $regex: this.text } };
      else if (this.type == "ID") criteria = { _id: this.text };
      else if (this.type == "Stripe ID")
        criteria = { "payments.customer": this.text };
      else if (this.type == "Phone") {
        const formatted = this.$options.filters.phone(this.phone);
        criteria = {
          $or: [
            { "phones.number": this.phone },
            { "phones.number": formatted },
          ],
        };
      } else return;

      const select = "email google apple status phones member payments created";
      const params = { criteria: criteria, select: select, limit: 100 };
      const result = await this.$api.user.list(params);
      this.setUserAccounts(result);
    },
  },
};
</script>

<template lang="pug">
Page(title="Admin", :items="items")
  SalesForceOrganizationLoad
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Organizations", url: "/salesforce/admin/organizations" },
        { title: "Agents", url: "/salesforce/admin/agents" },
      ],
    };
  },
};
</script>
<template lang="pug">
v-card-text
  .red--text(v-if="blockUser") Blocked User
  v-btn(
    color="error",
    :loading="loading",
    @click="block()",
    outlined,
    small,
    v-else
  )
    .text-notransform Block this User
  simple-table(v-if="blockUser")
    tbody
      tr
        th Blocked Reason
        td {{ blockUser.reason_code }}
      tr
        th Stage
        td {{ blockUser.stage }}
      tr
        th Blocked Date
        td {{ blockUser.created | datetime }}
      tr
        th Unblock
        td
          v-btn(
            color="error",
            :loading="unblocking",
            @click="handleUnblock()",
            outlined,
            small
          )
            .text-notransform Unblock
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["blockUser", "userId"],
  data() {
    return { loading: false, unblocking: false };
  },
  methods: {
    ...mapActions(["removeBlacklistUser", "addBlacklistUser"]),
    async block() {
      if (!this.userId) return;
      this.loading = true;
      const reason_code = "Fraud - manual block";
      const params = { userId: this.userId, reason_code: reason_code };
      try {
        const result = await this.$api.blacklist.create(params);
        this.addBlacklistUser(result);
        this.$emit("update", result);
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async handleUnblock() {
      confirm("Do you really want to unblock this user?") && this.unblock();
    },
    async unblock() {
      if (!this.blockUser) return;
      this.unblocking = true;
      const params = { id: this.blockUser._id };
      try {
        await this.axios.post("/blacklist/user/deleteOne", params);
        this.removeBlacklistUser(this.blockUser);
        this.$emit("unblocked", null);
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.unblocking = false;
    },
  },
};
</script>
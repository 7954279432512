<template lang="pug">
v-dialog(v-model="dialog", width="800")
  v-card(v-if="item")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }}
      v-spacer
      v-btn(
        icon,
        small,
        @click="show_form = !show_form",
        v-if="isInternal || isMine",
        color="secondary"
      )
        v-icon(small) mdi-pencil
    v-form(@submit.prevent="submit", v-if="show_form")
      v-card-text
        v-text-field(v-model="item.title", label="Title", required)
        v-textarea(v-model="item.description", label="Description")
        v-select(
          v-model="item.tags",
          :items="tag_items",
          label="Tags",
          multiple,
          chips,
          deletable-chips,
          hide-selected,
          :menuProps="menuProps"
        )
        .list-box(v-if="item.biz")
          .subtitle-2 Biz: {{ item.biz_name }}
          v-btn(icon, small, @click="clearBiz", color="error")
            v-icon(small) mdi-close
        BizSelector(
          @select="selectBiz",
          v-else,
          label="Assign a Biz",
          :auto-select="false"
        )
        ContactPicker(v-model="item.contact")
        DateTimePicker(v-model="item.occured_at")
        div Please describe the service with details that includes business name.
      v-card-actions
        v-btn(color="secondary", :loading="loading", type="submit") Save
    v-card-text(v-else)
      .caption {{ item.tags?.join(", ") }}
      div {{ item.description }}
      Comments(:item="item", @update="update")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Comments from "./Comments";
import tagsJSON from "./tags.json";
import ContactPicker from "/libs/components/CRM/Contact/Picker";

export default {
  components: { Comments, ContactPicker },
  data() {
    return {
      dialog: false,
      loading: false,
      item: {},
      tag_items: JSON.parse(JSON.stringify(tagsJSON)),
      menuProps: { "offset-y": true },
      show_form: false,
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapGetters(["salesOrganization", "salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    isNew() {
      return !this.item?._id;
    },
    isMine() {
      return this.item?.sales_person === this.salesPerson?._id;
    },
    title() {
      if (this.show_form)
        return this.isNew ? "New Service Log" : "Edit Service Log";
      return this.item?.title;
    },
    user_name() {
      if (this.salesPerson) {
        return `${this.salesPerson.first_name} ${this.salesPerson.last_name}`;
      }
      return "";
    },
  },
  mounted() {
    EventBus.$on("edit-service-log", this.open);
  },
  destroyed() {
    EventBus.$off("edit-service-log", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.item = JSON.parse(JSON.stringify(data));
      } else {
        this.item = {
          title: "",
          description: "",
          tags: [],
          biz: null,
          biz_name: null,
          contact: null,
          occured_at: Date.now(),
        };
      }
      this.show_form = this.isNew;
      this.dialog = true;
    },
    selectBiz(biz) {
      this.item.biz = biz._id;
      this.item.biz_name = biz.name;
    },
    clearBiz() {
      this.item.biz = null;
      this.item.biz_name = null;
    },
    async submit() {
      let sales_person = this.item.sales_person || this.salesPerson?._id;
      let sales_person_name = this.item.sales_person_name || this.user_name;
      if (!this.item.biz) {
        this.$toast.error("Please assign a biz");
        return;
      }
      const organization =
        this.item.organization || this.salesOrganization?._id;
      this.loading = true;
      const action = {
        title: this.item.title,
        description: this.item.description,
        tags: this.item.tags,
        biz: this.item.biz,
        biz_name: this.item.biz_name,
        sales_person,
        sales_person_name,
        organization,
        contact: this.item.contact,
        occured_at: this.item.occured_at,
      };
      try {
        if (this.item._id) {
          const result = await this.$api.crm.serviceLog.put(
            this.item._id,
            action
          );
          this.$store.dispatch("crm/updateServiceLog", result);
        } else {
          const result = await this.$api.crm.serviceLog.create(action);
          this.$store.dispatch("crm/addServiceLog", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    update(data) {
      this.item = JSON.parse(JSON.stringify(data));
    },
  },
};
</script>
<template lang="pug">
Page(title="Transactions")
  v-row.my-3(justify="center")
    v-card(width="300", flat)
      v-card-text
        DaySelector.mb-5(@select="selectDay")
        BizSelector(@select="selectBiz")
        v-btn.text-capitalize(
          @click="load(date, biz._id)",
          block,
          color="secondary",
          :disabled="!date || !biz",
          :loading="loading"
        ) Load
          v-icon(right, small) mdi-send
  DailySummary.mb-5(:groups="groups", :loading="loading")
  MissedTransactions(@load-txn="load", ref="missedTxn")
</template>
<script>
import moment from "moment-timezone";
import DailySummary from "/libs/components/Transaction/DailySummary";
import MissedTransactions from "./MissedTransactions/index";

export default {
  components: { DailySummary, MissedTransactions },
  data: () => ({
    biz: null,
    date: null,
    loading: false,
    groups: [],
  }),
  methods: {
    selectBiz(data) {
      this.biz = data;
    },
    selectDay(data) {
      this.date = moment(data).format("YYYY-MM-DD");
      this.$refs.missedTxn.load(this.date);
    },
    // date: YYYY-MM-DD
    // bizId: 24 char string
    async load(date, bizId) {
      if (!date || !bizId) return;
      this.loading = true;
      const params = { bizId: bizId, date: date };
      try {
        this.groups = await this.$api.paxTrans.custom("dailysummary", params);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

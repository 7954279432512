<template lang="pug">
div
  SendEmail
</template>

<script>
import SendEmail from "./SendEmail";

export default {
  components: { SendEmail }
};
</script>
<template lang="pug">
div
  v-navigation-drawer(
    v-model="drawer",
    app,
    fixed,
    v-if="$auth.check()",
    :clipped="$vuetify.breakpoint.lgAndUp",
    width="230"
  )
    v-list.pa-0(dense)
      v-list-group(
        v-for="item in items",
        v-model="item.active",
        :key="item.title",
        :prepend-icon="item.action",
        no-action
      )
        template(v-slot:activator)
          v-list-item-title {{ item.title }}
        v-list-item(
          v-for="subItem in item.items",
          :key="subItem.title",
          :to="subItem.to"
        )
          v-list-item-content
            v-list-item-title {{ subItem.title }}
      v-list-item(@click="$auth.logout()")
        v-list-item-title Sign Out
        v-icon logout
  v-app-bar(
    app,
    dark,
    flat,
    color="primary",
    :clipped-left="$vuetify.breakpoint.lgAndUp"
  )
    v-app-bar-nav-icon(@click="drawer = !drawer", v-if="$auth.check()")
    .title.ml-2 Selflane Internal
</template>

<script>
import NavGroups from "@/components/basic/NavGroups.json";

export default {
  data() {
    return {
      drawer: null,
      items: NavGroups,
    };
  },
};
</script>

<template lang="pug">
div
  UserExpense
</template>

<script>
import UserExpense from "./UserExpense";

export default {
  components: { UserExpense },
};
</script>
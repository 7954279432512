<template lang="pug">
v-card(v-if="order", outlined)
  v-toolbar(flat, dense)
    div
      .subtitle-2.text-truncate {{ bizName }}
      .subtitle-2 {{ order.needed | time }} {{ order.zone }}
    v-spacer
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click="show(order)", v-if="order.order") Order Details
        EditTip(v-if="order.status != -1", :delivOrder="order")
        EditIssueTracker(:delivOrder="order")
        EditAddressNote(:delivOrder="order")
        v-list-item(@click="handleDeletePicked(order)") Clear Time
        v-list-item(@click="handleCancel(order)") Cancel
  .text-center.subtitle-2.green--text.text-truncate {{ order.bizmsg }}
  v-divider
  v-row
    v-col(cols="9")
      div
        Customer(:delivOrder="order")
        .d-flex.flex-row.align-center
          v-icon.mx-3(small) mdi-credit-card-outline
          .subtitle-2 {{ order.fee | currency }}+{{ order.tip | currency }}
        .d-flex.flex-row.align-center
          v-icon.mx-3(small) mdi-car
          .subtitle-2(v-if="order.driver") {{ order.driver.name }} {{ order.driver.phone | phone }}
            v-icon.ml-2(v-if="order.proxy", color="secondary", small) {{ order.proxy | proxy }}
        .d-flex.flex-row.align-center.subtitle-2(v-if="order.driver")
          v-icon.mx-3(small, v-if="!order.driver.accept") mdi-check-circle
          v-icon.mx-3(color="success", small, v-else) mdi-check-circle
          div
            span(v-if="order.driver.assign") {{ order.driver.assign | time }}
            span.mx-1 -
            span(v-if="order.driver.accept") {{ order.driver.accept | time }}
        DelivTime(:order="order")
    v-col(cols="3")
      Photo(:delivOrder="order")
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import Customer from "./Customer";
import DelivTime from "./DelivTime";
import Photo from "./Photo";
import EditTip from "./EditTip";
import EditIssueTracker from "./EditIssueTracker";
import EditAddressNote from "./EditAddressNote";

export default {
  components: {
    Customer,
    DelivTime,
    Photo,
    EditTip,
    EditIssueTracker,
    EditAddressNote,
  },
  props: ["order"],
  computed: {
    bizName() {
      if (this.order && this.order.bizName) {
        return this.order.bizName.substring(0, 30);
      }
      return "";
    },
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    show(item) {
      if (item && item.order) {
        EventBus.$emit("show-order-detail", item.order);
      }
    },
    handleCancel(item) {
      confirm("Do you want to cancel this delivery?") && this.cancel(item);
    },
    async cancel(item) {
      const params = { id: item._id };
      const result = await this.$api.delivOrder.custom("cancelByDeliv", params);
      this.updateDelivOrder(result);
    },
    handleDeletePicked(item) {
      confirm("Do you want to delete the pick up time?") &&
        this.deletePicked(item);
    },
    async deletePicked(item) {
      const id = item?._id;
      if (!id) return;
      const action = { $set: { status: 2, pickupTime: null } };
      const result = await this.$api.delivOrder.put(id, action);
      this.updateDelivOrder(result);
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-toolbar(dense, flat)
    .subtitle-2 Action Log
  v-data-table(:headers="headers", :items="items", dense, mobile-breakpoint=0)
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
</template>

<script>
export default {
  data() {
    return {
      items: [],
      headers: [
        { text: "Date", value: "created" },
        { text: "title", value: "title" },
        { text: "Detail", value: "detail" },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.items = await this.$api.corp.log.list({ criteria: {} });
    },
  },
};
</script>
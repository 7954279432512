<template lang="pug">
div
  PartialRefund
  TaxExempt
  CancelOrder
  ChargeBack
  AdjustPrice
</template>
<script>
import PartialRefund from "./PartialRefund";
import TaxExempt from "./TaxExempt";
import CancelOrder from "./CancelOrder";
import ChargeBack from "./ChargeBack/index";
import AdjustPrice from "./AdjustPrice";

export default {
  components: {
    PartialRefund,
    TaxExempt,
    CancelOrder,
    ChargeBack,
    AdjustPrice,
  },
};
</script>
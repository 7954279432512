<template lang="pug">
div
  YearSelector(@select="load")
  v-row.my-3(wrap)
    v-col(cols="12", md="6")
      ContactYearly(ref="contact")
    v-col(cols="12", md="6")
      LeadYearly(ref="lead")
    v-col(cols="12", md="6")
      OpportunityYearly(ref="opportunity")
    v-col(cols="12", md="6")
      InteractionYearly(ref="interaction")
</template>

<script>
import ContactYearly from "/libs/components/CRM/Contact/Yearly";
import LeadYearly from "/libs/components/CRM/Lead/Yearly";
import OpportunityYearly from "/libs/components/CRM/Opportunity/Yearly";
import InteractionYearly from "/libs/components/CRM/Interaction/Yearly";

export default {
  components: {
    ContactYearly,
    LeadYearly,
    OpportunityYearly,
    InteractionYearly,
  },
  methods: {
    async load(year) {
      await this.$refs.contact.load({ year });
      await this.$refs.lead.load({ year });
      await this.$refs.opportunity.load({ year });
      await this.$refs.interaction.load({ year });
    },
  },
};
</script>
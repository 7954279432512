<template lang="pug">
div
  .d-flex.flex-row.justify-center.align-center.mb-3
    YearSelector(@select="select")
    v-btn-toggle.mx-3(v-model="type", color="secondary", dense)
      v-btn(v-for="(item, index) in typeItems", :key="index", :value="item") {{ item }}
  .growth-box
    Weekly(:year="year", :type="type", :showDownload="showDownload")
    Monthly(:year="year", :type="type", :showDownload="showDownload")
</template>

<script>
import Weekly from "./Weekly";
import Monthly from "./Monthly";

export default {
  components: { Weekly, Monthly },
  data() {
    return {
      showDownload: true,
      year: null,
      type: "weekly",
      typeItems: ["weekly", "monthly"],
    };
  },
  methods: {
    select(year) {
      this.year = year;
    },
  },
};
</script>

<style lang="sass" scoped>
.growth-box
  max-width: 500px
  width: 95%
  margin-left: auto
  margin-right: auto
</style>
<template lang="pug">
v-list-item(@click.stop="handleRenotify()", v-if="order && order.status == 0") Renotify POS
</template>

<script>
export default {
  props: ["order"],
  methods: {
    handleRenotify() {
      confirm("Do you want to renotify this order to the POS?") &&
        this.renotify();
    },
    async renotify() {
      if (!this.order) return;
      const params = { orderId: this.order._id, bizId: this.order.seller.id };
      try {
        await this.axios.post("/bizNotif/renotifyOnline", params);
        this.$toast.success("Renotify success");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
.selector-container
  .horiz-container(v-for="(s, index) in ranges", :key="index")
    TimeRangePicker(v-model="ranges[index].range")
    v-icon(
      small,
      color="red",
      v-if="ranges.length > 1",
      @click="removeRange(index)"
    ) mdi-close
    v-icon(
      small,
      color="secondary",
      v-if="index === ranges.length - 1",
      @click="addRange()"
    ) mdi-plus
    v-icon(
      small,
      color="secondary",
      v-if="index === ranges.length - 1 && copy",
      @click="copyRange()",
      title="copy previous day"
    ) mdi-content-copy
</template>

<script>
export default {
  name: "RangesSelector",
  props: {
    // Array of { range: [start, end] }
    value: { type: Array, default: () => [] },
    copy: { type: Boolean, default: false },
  },
  data() {
    return {
      ranges: this.value,
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.ranges = this.value;
      },
    },
    ranges: {
      deep: true,
      handler() {
        this.$emit("input", this.ranges);
      },
    },
  },
  methods: {
    addRange() {
      if (this.ranges?.length) {
        const last = this.ranges[this.ranges.length - 1];
        const start = last.range[1] + 60 * 2;
        const end = start + 60 * 6;
        if (start > 1740) return;
        this.ranges.push({ range: [start, end] });
      } else {
        this.ranges = [{ range: [480, 1200] }];
      }
    },
    removeRange(index) {
      this.ranges.splice(index, 1);
    },
    copyRange() {
      this.$emit("copy");
    },
  },
};
</script>

<style lang="sass" scoped>
// stacked with direction of column and gap of 2px
.selector-container
  display: flex
  flex-direction: column
  gap: 2px
// horizontal flex with align center and gap of 2px
.horiz-container
  display: flex
  flex-direction: row
  align-items: center
  gap: 6px
</style>
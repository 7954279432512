<template lang="pug">
div
  Recreate(@done="load()")
  List.my-5(:loading="loading")
  Form
  EditParallax
</template>

<script>
import Recreate from "./Recreate";
import List from "./List";
import Form from "./Form";
import { mapActions } from "vuex";
import EditParallax from "./EditParallax";

export default {
  components: { Recreate, List, Form, EditParallax },
  data() {
    return { loading: false };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setCities"]),
    async load() {
      this.loading = true;
      const params = { criteria: {} };
      const result = await this.$api.city.list(params);
      this.setCities(result);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
Page(title="Activities")
  YearSelector(@select="load")
  LeadList.my-5(:items="leads")
  OpportunityList.my-5(:items="opportunities")
</template>

<script>
import LeadList from "/libs/components/CRM/Lead/List";
import OpportunityList from "/libs/components/CRM/Opportunity/List";

import { mapGetters } from "vuex";

export default {
  components: { LeadList, OpportunityList },
  computed: {
    ...mapGetters(["salesOrganization"]),
    ...mapGetters("crm", ["leads", "opportunities"]),
  },
  methods: {
    async load(year) {
      await this.loadLeads(year);
      await this.loadOpportunities(year);
    },
    async loadLeads(year) {
      // get unix timestamp of the year
      const begin = new Date(year, 0).getTime();
      const end = new Date(year + 1, 0).getTime();
      const params = {
        criteria: { created: { $gte: begin, $lt: end } },
      };
      try {
        const result = await this.$api.crm.lead.list(params);
        this.$store.dispatch("crm/setLeads", result);
      } catch (e) {
        // fail silently
      }
    },
    async loadOpportunities(year) {
      // get unix timestamp of the year
      const begin = new Date(year, 0).getTime();
      const end = new Date(year + 1, 0).getTime();
      const params = {
        criteria: { created: { $gte: begin, $lt: end } },
      };
      try {
        const result = await this.$api.crm.opportunity.list(params);
        this.$store.dispatch("crm/setOpportunities", result);
      } catch (e) {
        // fail silently
      }
    },
  },
};
</script>
<template lang="pug">
div
  IconBtn(@click="create()", icon="mdi-plus", title="Add")
  Table.my-5(@edit="edit")
  Form(ref="form")
</template>

<script>
import Table from "./Table/index";
import Form from "/libs/components/Enterprise/Chain/Form";

export default {
  components: { Table, Form },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.chain.account.list(params);
      this.$store.dispatch("entp/setChains", result);
    },
    create() {
      this.$refs.form.open();
    },
    edit($event) {
      this.$refs.form.open($event);
    },
  },
};
</script>

<template lang="pug">
simple-table
  tbody
    AmountTableRow(
      v-for="(item, index) in bizorder.items",
      :key="index",
      :name="getName(item)",
      :amount="item.price",
      mandatory
    )
</template>

<script>
export default {
  props: ["bizorder"],
  methods: {
    getName(item) {
      return `${item.quantity} x ${item.name}`;
    },
  },
};
</script>

<template lang="pug">
v-list-item(@click="handleDelete()") Delete
</template>

<script>
export default {
  props: {
    program: { type: Object, required: true },
  },
  methods: {
    handleDelete() {
      confirm("Do you really want to delete this program?") && this.deleteOne();
    },
    async deleteOne() {
      if (!this.program) return;
      try {
        const result = await this.$api.memberProgram.delete(this.program._id);
        this.$store.dispatch("removeMemberProgram", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-card(v-if="driver")
    v-toolbar(flat, dense)
      .subtitle-2 {{ driver.name }} {{ balance | currency }}
      v-spacer
      Payout(:driver="driver")
    PayoutReport(:report="payoutReport")
    OrderReport(:report="orderReport", :driver="driver")
    SetupPayment(:driver="driver", v-if="!payoutReport")
    StripeAccount(:account="account", :driver="driver")
    v-card-text
      ClearRejection(:driver="driver")
      Transfer(:driver="driver")
    Subscribe
    v-card-actions
      v-btn(color="error", @click="handleDelete", block, v-if="isDeletable") Delete Driver
</template>

<script>
import OrderReport from "./OrderReport";
import PayoutReport from "./PayoutReport";
import Payout from "./Payout";
import SetupPayment from "./SetupPayment";
import StripeAccount from "./StripeAccount";
import ClearRejection from "./ClearRejection";
import Transfer from "./Transfer";
import Subscribe from "./Subscribe";
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    OrderReport,
    PayoutReport,
    Payout,
    SetupPayment,
    StripeAccount,
    ClearRejection,
    Transfer,
    Subscribe,
  },
  data() {
    return {
      dialog: false,
      orderReport: null,
      payoutReport: null,
      account: null,
    };
  },
  computed: {
    ...mapGetters(["driver"]),
    balance() {
      if (this.orderReport && this.payoutReport) {
        return (
          this.orderReport.fee +
          this.orderReport.tip -
          this.payoutReport.amount -
          this.payoutReport.deduct
        );
      } else return 0;
    },
    isDeletable() {
      return !this.orderReport || this.orderReport.count == 0;
    },
  },
  mounted() {
    EventBus.$on("show-driver-detail", this.open);
  },
  destroyed() {
    EventBus.$off("show-driver-detail", this.open);
  },
  methods: {
    ...mapActions(["setDriver", "removeDriver"]),
    open(data) {
      if (!data) return;
      this.setDriver(data);
      this.orderReport = null;
      this.payoutReport = null;
      this.dialog = true;
      this.loadOrderReport();
      this.loadPayoutReport();
      this.loadAccount();
    },
    async loadOrderReport() {
      if (!this.driver) return;
      this.orderReport = null;
      const params = { userId: this.driver.user };
      try {
        const url = "/driver/payouts/orderreport";
        const { data } = await this.axios.post(url, params);
        this.orderReport = data;
      } catch (err) {
        // fail silently
      }
    },
    async loadPayoutReport() {
      if (!this.driver) return;
      const params = { userId: this.driver.user };
      this.payoutReport = null;
      try {
        const url = "/driver/payouts/report";
        const { data } = await this.axios.post(url, params);
        this.payoutReport = data;
      } catch (err) {
        // fail silently
      }
    },
    async loadAccount() {
      if (!this.driver) return;
      try {
        const params = { userId: this.driver.user };
        this.account = await this.$api.stripe.account.retrieve(params);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    handleDelete() {
      confirm("Do you really want to delete this driver?") && this.deleteOne();
    },
    async deleteOne() {
      if (!this.driver) return;
      const params = { driverId: this.driver._id };
      try {
        const { data } = await this.axios.post(
          "/driver/accounts/deleteOne",
          params
        );
        this.dialog = false;
        this.removeDriver(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
div
  v-form(@submit.prevent="search")
    .d-flex.flex-row
      v-text-field.mx-3(
        v-model="pattern",
        autofocus,
        label="Influencer Name",
        solo,
        dense
      )
      v-btn(icon, type="submit", color="secondary")
        v-icon mdi-magnify
  List
</template>

<script>
import List from "./../List";
import { mapActions } from "vuex";

export default {
  components: { List },
  data() {
    return { pattern: "" };
  },
  mounted() {
    this.setInfluencers([]);
  },
  destroyed() {
    this.setInfluencers([]);
  },
  methods: {
    ...mapActions(["setInfluencers"]),
    async search() {
      if (!this.pattern) return;
      this.pattern = this.pattern.trim();
      const params = {
        criteria: { name: { $regex: this.pattern, $options: "i" } },
        limit: 100,
      };
      const result = await this.$api.influencer.account.list(params);
      this.setInfluencers(result);
    },
  },
};
</script>

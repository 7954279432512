<template lang="pug">
IconBtn(
  @click="submit",
  title="Old Trans",
  icon="mdi-delete",
  :small="false",
  :xSmall="true",
  :loading="loading"
)
</template>


<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      this.loading = true;
      for (let i = 0; i < 50; i++) {
        try {
          const result = await this.$api.transaction.missed.custom(
            "clearOldData"
          );
          this.$toast.success(result + " at " + i);
        } catch (err) {
          this.$toast.error(err.response?.data || err.message);
          break;
        }
      }
      this.loading = false;
    },
  },
};
</script>
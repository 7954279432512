<template lang="pug">
v-btn.text-capitalize(
  outlined,
  color="secondary",
  small,
  @click="submit",
  :loading="loading"
) Send All
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      const types = [2, 5, 6, 7];
      this.loading = true;
      for (const type of types) {
        await this.sendByType(type);
      }
      this.loading = false;
    },
    async sendByType(type) {
      const params = { promoType: type };
      try {
        const { data } = await this.axios.post("/member/email/onDay", params);
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || "Failed to send email");
      }
    },
  },
};
</script>

<template lang="pug">
div
  CountByCountry.mb-3
  CountByPartner.mb-3
  CountByStage
</template>

<script>
import CountByCountry from "./CountByCountry";
import CountByPartner from "./CountByPartner";
import CountByStage from "./CountByStage";

export default {
  components: { CountByCountry, CountByPartner, CountByStage },
};
</script>
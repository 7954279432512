<template lang="pug">
v-data-table(:items="items", :headers="headers", mobile-breakpoint=0)
  template(v-slot:item.created="{ item }")
    span {{ item.created | datetime }}
</template>

<script>
export default {
  props: ["items"],
  data() {
    return {
      headers: [
        { text: "Time", value: "created" },
        { text: "Subject", value: "subject" },
        { text: "Destination", value: "destination_name" },
        { text: "Count", value: "destination_count" },
        { text: "Staff", value: "staff_email" },
      ],
    };
  },
};
</script>
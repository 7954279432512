<template lang="pug">
v-card(width="400")
  v-toolbar(flat, dense)
    .subtitle-2 Search Delivery Company
  v-card-text
    v-form(@submit.prevent="submit()")
      v-text-field(
        v-model="search",
        label="Enter Name",
        append-icon="search",
        @click:append="submit()",
        solo,
        hide-details
      )
  v-list(dense, v-if="delivs && delivs.length")
    v-list-item(
      v-for="(item, index) in delivs",
      :key="index",
      @click="select(item)"
    )
      v-list-item-content
        v-list-item-title {{ item.name }}
        v-list-item-subtitle(v-if="item.address") {{ item.address.city }}, {{ item.address.state }}
      v-list-item-action(v-if="item._id == selection")
        v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  data() {
    return {
      search: "",
      delivs: [],
      selection: null,
    };
  },
  methods: {
    async submit() {
      const search = this.search.trim();
      if (!search || search.length < 3) {
        return this.$toast.error("Please enter a name with at least 3 letters");
      }
      const params = {
        criteria: { name: { $regex: search, $options: "i" } },
        select: "name address",
      };
      this.delivs = await this.$api.delivery.list(params);
    },
    select(item) {
      if (!item) return;
      this.selection = item._id;
      this.$emit("select", item);
    },
  },
};
</script>

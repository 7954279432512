<template lang="pug">
v-card(v-if="chain", max-width="500", flat)
  v-toolbar(dense, flat)
    .subtitle-2 {{ chain.name }}
    v-spacer
    v-btn(icon, small, color="secondary", @click="$emit('edit', chain)")
      v-icon(small) mdi-pencil
  .s-vstack
    Access(:chain="chain")
    v-col(align="center")
      Logo(:chain="chain", @updated="updateChain")
    Parallax(:chain="chain", @updated="updateChain")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Access from "./Access/index";
import Logo from "/libs/components/Enterprise/Chain/Logo/index";
import Parallax from "/libs/components/Enterprise/Chain/Parallax/index";

export default {
  components: { Access, Logo, Parallax },
  computed: { ...mapGetters("entp", ["chain"]) },
  methods: {
    ...mapActions("entp", ["updateChain"]),
  },
};
</script>
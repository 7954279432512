<template lang="pug">
Page(title="Stripe Connected Accounts")
  List
</template>

<script>
import List from "./List";
export default {
  components: { List },
};
</script>

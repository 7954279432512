<template lang="pug">
div
  SelectById.mb-2
  SelectByBiz.mb-3
  Orders
</template>

<script>
import Orders from "./../Orders/index";
import SelectById from "./SelectById";
import SelectByBiz from "./SelectByBiz";

export default {
  components: { Orders, SelectById, SelectByBiz },
};
</script>

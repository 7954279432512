<template lang="pug">
div
  simple-table(v-if="contact")
    tbody
      tr
        th Name
        td {{ contact.first_name }} {{ contact.last_name }}
      tr
        th Company
        td {{ contact.company }}
      tr
        th Job Title
        td {{ contact.job_title }}
      tr
        th Email
        td {{ contact.email }}
      tr
        th Phone
        td {{ contact.phone | phone }}
      tr
        th Address
        td {{ contact.address | address }}
      tr
        th Notes
        td {{ contact.notes }}
      tr
        th Created
        td {{ contact.created | date }}
  v-card-actions
    IconBtn(@click="$emit('edit')")
</template>

<script>
export default {
  props: {
    contact: { type: Object, default: () => ({}) },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="350")
  v-card(v-if="enterprise")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }} an Enterprise
    v-card-text
      v-form(@submit.prevent="submit")
        v-text-field(
          v-model="enterprise.name",
          label="Name",
          :disabled="!isInternal"
        )
        v-select(
          v-model="enterprise.country",
          :items="countries",
          label="Country",
          :disabled="!isInternal"
        )
        GoogleAddressField(v-model="enterprise.address")
        v-btn(
          block,
          color="secondary",
          type="submit",
          :disabled="!valid",
          :loading="loading"
        ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  name: "EditSalesOrganization",
  data() {
    return {
      menu: null,
      dialog: false,
      loading: false,
      enterprise: null,
      app_name: process.env.VUE_APP_NAME || "Selflane",
      countries: ["US", "CA"],
    };
  },
  computed: {
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    title() {
      if (this.enterprise?._id) return "Edit";
      return "Add";
    },
    valid() {
      return !!this.enterprise.name;
    },
  },
  mounted() {
    EventBus.$on("edit-enterprise", this.open);
  },
  destroyed() {
    EventBus.$off("edit-enterprise", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.enterprise = JSON.parse(JSON.stringify(data));
      } else {
        this.enterprise = { name: "", address: null, country: "US" };
      }
      this.dialog = true;
    },
    async submit() {
      if (!this.enterprise) return;
      const data = {
        name: this.enterprise.name.trim(),
        country: this.enterprise.country,
        address: this.enterprise.address,
      };
      this.loading = true;
      try {
        const id = this.enterprise._id;
        if (id) {
          const action = { $set: data };
          const result = await this.$api.entp.enterprise.put(id, action);
          this.$store.dispatch("entp/updateEnterprise", result);
        } else {
          const result = await this.$api.entp.enterprise.create(data);
          this.$store.dispatch("entp/addEnterprise", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

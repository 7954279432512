<template lang="pug">
div
  MonthSelector.ma-2(@select="selectMonth")
  ListProducts(:products="products", :users="users", :outs="outs")
</template>

<script>
import ListProducts from "./ListProducts";

export default {
  components: { ListProducts },
  data() {
    return {
      range: null,
      outs: [],
      products: [],
      users: [],
    };
  },
  methods: {
    selectMonth(data) {
      this.range = data.range;
      this.load();
    },
    async load() {
      if (!this.range || !this.range.begin || !this.range.end) return;
      const params = {
        criteria: { date: { $gte: this.range.begin, $lt: this.range.end } },
      };
      this.outs = await this.$api.catalog.inventory.out.list(params);
      this.loadProductNames();
      this.loadUsers();
    },
    async loadProductNames() {
      const ids = this.outs.map((o) => o.product);
      const params = { criteria: { _id: { $in: ids } }, select: "name" };
      this.products = await this.$api.catalog.product.list(params);
    },
    async loadUsers() {
      const ids = this.outs.map((o) => o.staff);
      const params = { criteria: { _id: { $in: ids } }, select: "email" };
      this.users = await this.$api.user.list(params);
    },
  },
};
</script>
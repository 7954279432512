<template lang="pug">
simple-table(v-if="days")
  thead
    tr
      th {{ title }}
      th(v-for="(day, index) in hours", :key="index") {{ day }}
  tbody
    tr(v-for="(day, index) in days", :key="index")
      td {{ parseInt(day.day) | day(true) }}
      td(v-for="(hour, hindex) in day.countByHour", :key="hindex")
        span(v-if="drivers") {{ hour.drivers }}
        span(v-else) {{ hour.count }}
</template>

<script>
export default {
  props: ["title", "days", "drivers"],
  data() {
    return {
      hours: [
        "7am",
        "8am",
        "9am",
        "10am",
        "11am",
        "12pm",
        "1pm",
        "2pm",
        "3pm",
        "4pm",
        "5pm",
        "6pm",
        "7pm",
        "8pm",
        "9pm",
        "10pm",
        "11pm",
      ],
    };
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(
      v-on="on",
      @click="open()",
      title="Missed Transaction",
      icon="mdi-magnify"
    )
  v-card
    v-card-title Search a missed transaction
    v-card-text
      BizSelector(@select="select")
      v-form(v-if="biz", @submit.prevent="send()")
        v-row
          v-col(cols="6")
            v-text-field(v-model="ip", label="IP Address")
          v-col(cols="6")
            v-text-field(v-model="transNum", label="Transaction Number")
        v-btn(color="secondary", block, @click="send()", :loading="loading") Remote Load
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    biz: null,
    ip: "",
    transNum: "",
    loading: false,
  }),
  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },
    reset() {
      this.biz = null;
      this.ip = "";
      this.transNum = "";
    },
    select(biz) {
      this.biz = biz;
    },
    async send() {
      const bizId = this.biz?._id;
      if (!bizId) return this.$toast.error("Please select a business");
      if (!this.ip || !this.transNum) return;
      this.loading = true;
      const params = { bizId: bizId, ip: this.ip, transNum: this.transNum };
      try {
        await this.$api.paxTrans.custom("remoteload", params);
        this.$toast.success("Done");
        this.reset();
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400", @keydown.esc="dialog = false")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", small, outlined, color="error") Unsubscribe a User
  v-card
    v-card-title Unsubscribe a User
    v-form(@submit.prevent="submit")
      v-card-text
        v-text-field(label="Email", v-model="email", ref="focus")
        v-switch(label="General News", v-model="subscriptions.generalNews")
        v-switch(label="Bizs News", v-model="subscriptions.bizsNews")
        v-switch(label="Promotions", v-model="subscriptions.promotions")
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
export default {
  name: "SubsForm",
  data() {
    return {
      dialog: false,
      email: "",
      subscriptions: {
        generalNews: false,
        bizsNews: false,
        promotions: false,
      },
      loading: false,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.reset();
      }
    },
  },
  methods: {
    reset() {
      this.email = "";
      this.subscriptions = {
        generalNews: false,
        bizsNews: false,
        promotions: false,
      };
      this.loading = false;
    },
    async submit() {
      this.errors = [];
      /// validate
      if (!this.email) return;
      const params = {
        criteria: { email: this.email },
        action: { $set: { subscriptions: this.subscriptions } },
      };
      try {
        await this.$api.user.update(params);
        this.$toast.success("User unsubscribed");
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>
<template lang="pug">
div
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 Contacts
      v-spacer
      IconBtn(@click="create", title="Create", icon="add")
    v-card-text
      v-text-field(
        v-model="search",
        label="Search",
        append-icon="mdi-magnify",
        single-line,
        clearable
      )
    v-data-table(
      :items="items",
      :headers="headers",
      mobile-breakpoint="0",
      sort-by="first_name",
      :search="search",
      @click:row="edit",
      role="button"
    )
      template(v-slot:item.phone="{ item }")
        span.nowrap {{ item.phone | phone }}
      template(v-slot:item.actions="{ item }")
        DeleteOne(:item="item")
  Form(ref="form")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./../Form";
import DeleteOne from "./DeleteOne";

export default {
  components: { Form, DeleteOne },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Company", value: "company" },
        { text: "Job Title", value: "job_title" },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  computed: { ...mapGetters(["salesOrganization"]) },
  methods: {
    edit(item) {
      this.$refs.form.open(item);
    },
    create() {
      const organization = this.salesOrganization?._id;
      const contact = {
        first_name: "",
        last_name: "",
        company: "",
        job_title: "",
        email: "",
        phone: "",
        organization,
        address: {},
      };
      this.$refs.form.open(contact);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", max-width="700", scrollable)
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 {{ userId }}
      v-spacer
      .subtitle-2 {{ userId | date }}
    Table(:orders="orders", style="max-height: 500px; overflow-y: auto")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Table from "./Table";

export default {
  components: { Table },
  props: ["options", "open"],
  data: () => ({
    orders: [],
    dialog: false,
    userId: "",
  }),
  mounted() {
    this.orders = [];
    EventBus.$on("list-order-by-userid", this.load);
  },
  destroyed() {
    EventBus.$off("list-order-by-userid", this.load);
  },
  methods: {
    async load(userId) {
      if (!userId) return;
      this.userId = userId;
      this.dialog = true;
      const params = {
        criteria: { "customer.id": userId },
        select: "seller.name needed payment.total type proxy",
        limit: 200,
      };
      this.orders = [];
      const { data } = await this.axios.post("/orders/list", params);
      this.orders = data;
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    v-select(:items="select_items", v-model="selection")
  simple-table
    thead
      tr
        th
        th Jan
        th Feb
        th Mar
        th Apr
        th May
        th Jun
        th Jul
        th Aug
        th Sep
        th Oct
        th Nov
        th Dec
        th Total
    tbody
      tr(v-for="(group, index) in groups", :key="index")
        th {{ group.title }}
        td(v-for="(item, mindex) in group.months", :key="mindex") {{ item.total | currency }}
        td {{ group.total | currency }}
</template>

<script>
import _ from "underscore";
export default {
  props: ["items"],
  data() {
    return {
      selection: "compact",
      select_items: ["compact", "complete"],
    };
  },
  computed: {
    groups() {
      return _.chain(this.items)
        .map((o) => {
          let title = o.category || "unknown";
          if (this.selection == "compact") {
            if (title.includes("_sldelivery")) {
              title = "selflane delivery";
            } else if (title.includes("_catering")) {
              title = "catering";
            } else if (title == "org_invoice") {
              title = "invoice";
            } else if (title.startsWith("online")) {
              title = "online";
            }
          }
          title = title.replace(/_/g, " ");
          o.title = o.currency + "/" + title;
          return o;
        })
        .groupBy("title")
        .map((items, k) => {
          const months = _.map(_.range(1, 13, 1), (month) => {
            const filtered = items.filter((o) => o.month % 100 == month);
            const total = filtered.reduce(
              (acc, cur) => acc + cur.selflane_net,
              0
            );
            return { month: month, total: total };
          });
          const total = months.reduce((acc, cur) => acc + cur.total, 0);
          return { title: k, months: months, total: total };
        })
        .sortBy("title")
        .value();
    },
  },
};
</script>
<template lang="pug">
v-card(flat, v-if="biz", :loading="loading")
  v-toolbar(dense, flat)
    .subtitle-2
      v-icon(color="primary") mdi-email
      span.ml-2 {{ biz.email }}
    v-spacer
    Form
  v-card-text.py-1(v-if="biz.carboncopy")
    div CC Recipients: {{ biz.carboncopy }}
  v-list.py-0(dense)
    v-list-item(@click="handleToggle")
      v-list-item-content
        v-list-item-title Auto email on online order
        v-list-item-subtitle No additional cost
      v-list-item-action
        v-icon(:color="color") mdi-check
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    email_online() {
      return this.biz.settings?.email_online == true;
    },
    color() {
      return this.email_online ? "green" : "grey lighten-2";
    },
  },
  methods: {
    handleToggle() {
      if (!this.biz) return;
      const email_online = !this.email_online;
      if (email_online == false) {
        const message =
          "In turning off the email service, you understand that the order will only be notified on the POS. When the business is closed for holidays and other incidents, please make sure to update hours properly to stop the online ordering.";
        confirm(message) && this.toggle();
        return;
      }
      this.toggle();
    },
    async toggle() {
      const email_online = !this.email_online;
      this.loading = true;
      try {
        const action = { $set: { "settings.email_online": email_online } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-card-title Logo
    v-card-text
      v-col(align="center")
        croppa(
          v-model="myCroppa",
          :quality="2",
          :canvas-color="canvas_color",
          initial-size="contain"
        )
    v-card-actions
      v-btn(@click="upload", color="secondary", :loading="loading") Upload
</template>

<script>
export default {
  props: ["chain"],
  data() {
    return {
      myCroppa: {},
      dialog: false,
      loading: false,
      canvas_color: "#FFFFFF", // background color
    };
  },
  methods: {
    open() {
      // this.myCroppa?.remove();
      this.dialog = true;
    },
    upload() {
      this.myCroppa.generateBlob(
        (blob) => {
          if (!blob) {
            this.myCroppa.chooseFile();
          } else {
            this.save(blob);
          }
        },
        "image/jpeg",
        0.8
      );
    },
    async save(blob) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("id", this.chain._id);
        const result = await this.$api.chain.account.custom(
          "updateLogo",
          formData
        );
        this.$emit("updated", result);
        this.$toast.success("Logo updated");
        this.myCroppa?.remove();
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update logo");
      }
      this.loading = false;
    },
  },
};
</script>
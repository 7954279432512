<template lang="pug">
v-btn(@click="handler", :loading="loading", small) Rebuild Invoice
</template>

<script>
export default {
  props: ["year"],
  data() {
    return { loading: false };
  },
  methods: {
    async handler() {
      this.loading = true;
      try {
        const params = { year: this.year };
        const result = await this.$api.transaction.stripe.custom(
          "invoice",
          params
        );
        this.$toast.success(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
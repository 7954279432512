<template lang="pug">
Page(title="Events")
  v-card(flat)
    simple-table
      thead
        tr
          th Biz Name
          th Title
          th ID
      tbody
        tr(v-for="(item, index) in items", :key="index")
          td {{ item.biz_name }}
          td {{ item.title }}
          td {{ item._id }}
</template>

<script>
import _ from "underscore";
export default {
  data() {
    return {
      events: [],
      bizs: [],
    };
  },
  computed: {
    items() {
      return _.map(this.events, (o) => {
        const found = this.bizs.find((biz) => biz._id == o.biz_id);
        if (found) o.biz_name = found.name;
        return o;
      });
    },
  },
  mounted() {
    this.loadEvents();
  },
  methods: {
    async loadEvents() {
      let params = { criteria: {}, select: "biz_id title" };
      this.events = await this.$api.event.entry.list(params);
      this.loadBizs();
    },
    async loadBizs() {
      const ids = _.pluck(this.events, "biz_id");
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name address",
      };
      this.bizs = await this.$api.biz.list(params);
    },
  },
};
</script>
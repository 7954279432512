<template lang="pug">
table.simple-table
  tbody
    tr
      th UUID
      td {{ device.deviceUUID }}
    tr
      th Device Name
      td {{ device.deviceName }}
    tr
      th Model Name
      td {{ device.model }}
    tr
      th OS System
      td {{ device.systemName }} {{ device.systemVersion }}
    tr
      th App
      td {{ device.appName }} {{ device.appVersion }}
    tr
      th Station No.
      td {{ device.station }}
    tr
      th Device IP
      td {{ device.deviceIP }}
    tr
      th PAX IP
      td {{ device.paxIP }}
    tr
      th User Email
      td {{ device.user_email }}
    tr
      th Auto Print Online
      td {{ device.auto_print_online }}
    tr
      th Token
      td {{ device.deviceToken }}
    tr
      th Firebase Messaging
      td {{ device.fcm }}
    tr
      th Role
      td {{ device.role }}
    tr
      th Last Login
      td {{ device.last_visit | datetime }}
</template>

<script>
export default {
  props: {
    device: { type: Object, required: true },
  },
};
</script>

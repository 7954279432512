<template lang="pug">
MonthlyGrowthCharts(:url="url")
</template>

<script>
export default {
  data() {
    return {
      url: "/bizaccount/monthly-growth",
    };
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="item")
    v-card-title Missed Transaction
    table.simple-table
      tbody
        tr
          th Biz
          td {{ item.bizName }}
        tr
          th Transaction ID
          td {{ item.transNum }}
        tr
          th Terminal SN
          td {{ item.sn }}
        tr
          th IP
          td
            .link-box
              span {{ item.loc }}
              EditIP(:item="item", @updated="update")
        tr
          th Status
          td {{ status }}
        tr
          th Created
          td {{ item.createdAt | datetime("MMM D, h:mm A") }}
        tr(v-if="item.txn")
          th Trans
          td
            v-btn(
              @click="show(item.txn)",
              small,
              text,
              color="secondary",
              :loading="loading"
            ) {{ item.txn }}
        tr
          th ID
          td
            ClickToCopy(:text="item._id")
    v-card-actions
      .link-box
        Notify(v-if="!item.txn", :item="item")
        SetStatus(
          v-if="item.status == 0",
          :item="item",
          :value="1",
          @updated="update"
        )
        SetStatus(
          v-if="item.status == 0",
          :item="item",
          :value="-1",
          @updated="update"
        )
</template>

<script>
import Notify from "./Notify";
import SetStatus from "./SetStatus";
import EditIP from "./EditIP";

export default {
  components: { Notify, SetStatus, EditIP },
  data() {
    return {
      dialog: false,
      item: null,
      loading: false,
    };
  },
  computed: {
    status() {
      if (this.item?.status == 1) return "Fulfilled";
      if (this.item?.status == -1) return "False Alarm";
      if (this.item?.status == 0) return "New";
      return "Unknown";
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    update(item) {
      this.item = item;
    },
    async show(id) {
      this.loading = true;
      try {
        const result = await this.$api.paxTrans.retrieve({
          criteria: { _id: id },
        });
        this.dialog = false;
        this.$emit("show-txn", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
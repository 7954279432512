<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-form(@submit.prevent="handleSave")
    v-card(v-if="plan")
      v-toolbar(flat, dense)
        .subtitle-2 {{ title }} Service Plan
      v-divider
      v-card-text
        v-row(dense, wrap)
          v-col(cols="12")
            v-select(label="Level", v-model="plan.level", :items="levelItems")
          v-col(cols="6")
            PriceField(
              label="US Price",
              v-model.number="plan.us_price",
              :hint="us_hint",
              persistent-hint
            )
          v-col(cols="6")
            PriceField(
              label="CA Price",
              v-model.number="plan.ca_price",
              :hint="ca_hint",
              persistent-hint
            )
        v-text-field(label="Detail", v-model="plan.detail")
        ItemSelector(:items="mapped_service_items", @select="selectItem")
        simple-table.my-3
          tbody
            tr(
              v-for="(item, index) in plan.items",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-icon(small) mdi-menu
              td {{ item.name }}
              td
                v-text-field(
                  v-model="item.quantity",
                  type="number",
                  step="1",
                  min="1",
                  single-line,
                  hide-details
                )
              td.text-right
                v-btn(icon, small, @click="removeAt(index)")
                  v-icon(small, color="error") mdi-delete
      v-card-actions
        v-btn(color="secondary", type="submit", block, :loading="loading") Save
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";
import ItemSelector from "./ItemSelector";

export default {
  components: { ItemSelector },
  data() {
    return {
      dialog: false,
      plan: null,
      loading: false,
      levelItems: [
        { text: "POS", value: 1 },
        { text: "Essential", value: 2 },
        { text: "Power", value: 3 },
        { text: "Premium", value: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters(["service_items", "service_plans"]),
    title() {
      return this.plan?._id ? "Edit" : "Create";
    },
    mapped_service_items() {
      const sorted = this.service_items
        ?.filter((o) => !this.plan.items?.some((item) => item.id == o._id))
        .toSorted((a, b) => a.name.localeCompare(b.name));
      return sorted;
    },
    us_value() {
      if (!this.plan) return 0;
      const value = _.reduce(
        this.plan.items,
        (memo, item) => {
          const found = this.service_items?.find((o) => o._id == item.id);
          if (found) return memo + found.us_price * item.quantity;
          return memo;
        },
        0
      );
      return Math.round(value * 100) / 100;
    },
    ca_value() {
      if (!this.plan) return 0;
      const value = _.reduce(
        this.plan.items,
        (memo, item) => {
          const found = this.service_items?.find((o) => o._id == item.id);
          if (found) return memo + found.ca_price * item.quantity;
          return memo;
        },
        0
      );
      return Math.round(value * 100) / 100;
    },
    us_hint() {
      if (!this.plan || !this.us_value) return "";
      const off = Math.round(
        ((this.plan.us_price - this.us_value) / this.us_value) * 100
      );
      return `Value $${this.us_value}; ${off}% off`;
    },
    ca_hint() {
      if (!this.plan || !this.ca_value) return "";
      const off = Math.round(
        ((this.plan.ca_price - this.ca_value) / this.ca_value) * 100
      );
      return `Value $${this.ca_value}; ${off}% off`;
    },
  },
  methods: {
    ...mapActions(["addBizServicePlan", "updateBizServicePlan"]),
    open(data) {
      if (data) {
        this.plan = JSON.parse(JSON.stringify(data));
      } else {
        this.plan = {
          level: 1,
          detail: "",
          items: [],
          us_price: 0,
          ca_price: 0,
          us_value: 0,
          ca_value: 0,
        };
      }
      this.dialog = true;
    },
    selectItem(item) {
      const data = {
        id: item._id,
        name: item.name,
        quantity: 1,
      };
      this.plan.items.push(data);
    },
    validate() {
      this.plan.us_value = this.us_value;
      this.plan.ca_value = this.ca_value;
      if (!this.plan.level) throw new Error("Level is required");
      if (!this.plan.us_price) throw new Error("US Price is required");
      if (!this.plan.ca_price) throw new Error("CA Price is required");
      this.plan.name = this.levelItems.find(
        (o) => o.value == this.plan.level
      ).text;
    },
    async handleSave() {
      if (!this.plan) return;
      this.loading = true;
      try {
        this.validate();
        if (this.plan._id) await this.update();
        else {
          const result = await this.$api.service.plan.create(this.plan);
          this.addBizServicePlan(result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.message || e.response?.data);
      }
      this.loading = false;
    },
    async update() {
      if (!this.plan || !this.plan._id) return;

      const params = {
        criteria: { _id: this.plan._id },
        action: {
          $set: {
            name: this.plan.name,
            level: this.plan.level,
            detail: this.plan.detail,
            items: this.plan.items,
            us_price: this.plan.us_price,
            ca_price: this.plan.ca_price,
            us_value: this.plan.us_value,
            ca_value: this.plan.ca_value,
          },
        },
      };

      const result = await this.$api.service.plan.update(params);
      this.updateBizServicePlan(result);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend(e) {
      e.target.style.opacity = 1;
    },
    drop(index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.plan.items.length) return;
      if (
        this.draggingIndex < 0 ||
        this.draggingIndex >= this.plan.items.length
      )
        return;
      let items = JSON.parse(JSON.stringify(this.plan.items));
      let tmp = JSON.parse(JSON.stringify(items[this.draggingIndex]));
      items.splice(this.draggingIndex, 1);
      items.splice(index, 0, tmp);
      this.plan.items = items;
    },
    removeAt(index) {
      this.plan.items.splice(index, 1);
    },
  },
};
</script>
<template lang="pug">
v-data-table(
  :items="bouncingEmails",
  :headers="headers",
  sort-by="created",
  sort-desc,
  dense,
  mobile-breakpoint=0
)
  template(v-slot:item.created="{ item }")
    span {{ item.created | datetime("M/D/YY HH:mm") }}
  template(v-slot:item.action="{ item }")
    v-btn(icon, small, color="error", @click="handleDelete(item)")
      v-icon(small) mdi-delete
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Email", value: "email" },
        { text: "Time", value: "created" },
        { text: "Action", value: "action", align: "end", sortable: false },
      ],
    };
  },
  computed: { ...mapGetters(["bouncingEmails"]) },
  methods: {
    ...mapActions(["removeBouncingEmail"]),
    handleDelete(item) {
      confirm("Do you really want to release this user?") &&
        this.deleteOne(item);
    },
    async deleteOne(item) {
      if (!item) return;
      const params = { id: item._id };
      try {
        this.axios.post("/blacklist/email/deleteOne", params);
        this.removeBouncingEmail(item);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

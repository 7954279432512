<template lang="pug">
div
  IconBtn(@click="open", icon="mdi-plus", title="Add")
  v-dialog(v-model="dialog", width="600")
    v-card
      v-card-title.grey.lighten-2
        span Add Items to Inventory
        v-spacer
        span Total: {{ total | currency }}
      v-card-text
        v-form(@submit.prevent="search()")
          v-text-field(v-model="searchText", label="Search products")
        v-btn(
          v-for="(item, index) in products",
          :key="index",
          @click="addProduct(item)"
        ) {{ item.name }}
        v-form(@submit.prevent="save()")
          .sl-title.ml-3 Products
          v-data-table(
            :headers="headers",
            :items="productItems",
            hide-default-footer
          )
            template(slot="items", slot-scope="props")
              td {{ props.item.name }}
              td
                PriceField(
                  v-model.number="props.item.cost",
                  hide-details,
                  single-line
                )
              td
                v-text-field(
                  type="number",
                  v-model="props.item.quantity",
                  step="1",
                  hide-details,
                  single-line
                )
              td
                v-btn(
                  icon,
                  small,
                  @click="removeProductAt(props.item.index)",
                  tabindex="-1"
                )
                  v-icon(small, color="error") mdi-delete
          .sl-title.ml-3 Misc Items
            v-btn(icon, small, @click="addMisc()")
              v-icon.fa.fa-plus(small, color="secondary")
          v-data-table(
            :headers="miscHeaders",
            :items="miscItems",
            hide-default-footer
          )
            template(slot="items", slot-scope="props")
              td
                v-select(
                  v-model="props.item.type",
                  :items="miscTypeChoices",
                  hide-details,
                  single-line
                )
              td
                PriceField(
                  v-model.number="props.item.cost",
                  hide-details,
                  single-line
                )
              td
                v-btn(
                  icon,
                  small,
                  @click="removeMiscAt(props.item.index)",
                  tabindex="-1"
                )
                  v-icon(small, color="error") mdi-delete
          v-layout
            v-flex(xs4)
              v-select(
                v-model="vendor",
                :items="vendorChoices",
                label="Vendor",
                hide-details,
                solo
              )
            v-flex(xs4)
              v-text-field(
                v-model="order",
                label="Order ID",
                solo,
                hide-details
              )
            v-flex(xs4)
              v-btn(tabindex="-1") Date {{ date | date }}
          v-btn(
            type="submit",
            :disabled="!vendor || !order",
            color="secondary",
            :loading="loading"
          ) Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      date: Date.now(),
      productItems: [],
      miscItems: [],
      products: [],
      headers: [
        { text: "Name", sortable: false },
        { text: "Cost", sortable: false },
        { text: "Quantity", sortable: false },
        { text: "Action", sortable: false },
      ],
      miscHeaders: [
        { text: "Type", sortable: false },
        { text: "Cost", sortable: false },
        { text: "Action", sortable: false },
      ],
      searchText: "",
      vendor: null,
      order: "",
      miscTypeChoices: ["Tax", "Shipping"],
      vendorChoices: ["Selflane", "ScanSource"],
      loading: false,
    };
  },
  computed: {
    total() {
      const product = this.productItems.reduce(
        (a, b) => a + b.cost * b.quantity,
        0
      );
      const misc = this.miscItems.reduce((a, b) => a + parseFloat(b.cost), 0);
      return product + misc;
    },
  },
  methods: {
    open() {
      this.name = "";
      this.dialog = true;
      this.products = [];
      this.searchText = "";
      this.productItems = [];
      this.miscItems = [];
      this.date = Date.now();
      this.vendor = null;
      this.order = "";
      this.loading = false;
    },
    async search() {
      const searchText = this.searchText.trim();
      if (!searchText) return;
      const params = {
        criteria: { name: { $regex: searchText, $options: "i" } },
        select: "name",
      };
      this.products = await this.$api.catalog.product.list(params);
    },
    addProduct(item) {
      if (!item) return;
      const some = this.productItems.some((o) => o._id == item._id);
      if (some) return;
      const data = { _id: item._id, name: item.name, quantity: 1, cost: 0 };
      this.productItems.push(data);
    },
    removeProductAt(index) {
      this.productItems.splice(index, 1);
    },
    removeMiscAt(index) {
      this.miscItems.splice(index, 1);
    },
    addMisc() {
      this.miscItems.push({ type: "", cost: 0 });
    },
    async save() {
      const miscItems = _.filter(this.miscItems, (o) => {
        if (!o.type || !o.type.trim()) return false;
        if (o.cost <= 0) return false;
        return true;
      });
      const productItems = _.filter(this.productItems, (o) => {
        if (o.cost <= 0 || o.quantity < 1) return false;
        if (!o._id) return false;
        return true;
      });
      if (!this.vendor) return;
      if (!this.date) return;
      if (!miscItems?.length && !productItems?.length) {
        return this.$toast.error("Please add items");
      }
      const params = {
        vendor: this.vendor,
        date: this.date,
        order: this.order,
        miscItems: miscItems,
        productItems: productItems,
      };
      this.loading = true;
      try {
        await this.$api.catalog.inventory.in.custom("createMany", params);
        this.dialog = false;
        EventBus.emit("CreateInventoryIn");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

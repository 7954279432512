<template lang="pug">
Page(title="Misc")
  .link-box.my-3
    MessagePOS
    MessageAllPOS
  .sl-title Communication
  .link-box.my-3
    SimpleEmail
    SMS
    Call
  .link-box.my-3
    CloseOrders
  MailBizTopic.my-3
  MailOnlineBizTopic.my-3
  MailPOSBizTopic.my-3
  MailAllOwners.my-3
  MarketingEmail.my-3
  //- MigrateImpression.my-3
  MigrateOps.my-3
</template>

<script>
import SMS from "./SMS";
import Call from "./Call";
import SimpleEmail from "./SimpleEmail";
import CloseOrders from "./CloseOrders";
import MailBizTopic from "./MailBizTopic";
import MailOnlineBizTopic from "./MailOnlineBizTopic";
import MailPOSBizTopic from "./MailPOSBizTopic";
import MarketingEmail from "./MarketingEmail";
import MessagePOS from "./MessagePOS";
import MessageAllPOS from "./MessageAllPOS";
import MailAllOwners from "./MailAllOwners";
import MigrateImpression from "./MigrateImpression";
import MigrateOps from "./MigrateOps";
import { mapActions } from "vuex";

export default {
  components: {
    SMS,
    Call,
    SimpleEmail,
    CloseOrders,
    MailBizTopic,
    MailOnlineBizTopic,
    MailPOSBizTopic,
    MarketingEmail,
    MessagePOS,
    MessageAllPOS,
    MailAllOwners,
    MigrateImpression,
    MigrateOps,
  },
  data() {
    return { bizs: [] };
  },
  mounted() {
    this.loadBizs();
    this.loadSample();
  },
  methods: {
    ...mapActions(["setEmailSamples"]),
    async loadSample() {
      const params = { criteria: {}, select: "subject content_short" };
      const result = await this.$api.corp.sampleEmail.list(params);
      this.setEmailSamples(result);
    },
    async loadBizs() {
      const params = { criteria: {}, select: "name" };
      const result = await this.$api.biz.list(params);
      this.bizs = result.toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
};
</script>

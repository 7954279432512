<template lang="pug">
div
  v-card(flat)
    v-toolbar(flat, dense)
      .subtitle-2 {{ enterprise?.name }}: ({{ enterprise?.bizs?.length || 0 }} locations)
      v-spacer
      AddOne(@saved="$emit('saved')")
    simple-table
      thead
        tr
          th Name
          th Store Name
          th Address
          th.text-right City, State
          th.text-right Hours
          th.text-right Chain
          th.text-right Region
          th
      tbody
        tr(
          v-for="item in bizs",
          :key="item._id",
          @click="open(item)",
          role="button"
        )
          th
            .d-flex.flex-row.align-center
              OnlineStatus.mr-2(:biz="item")
              span {{ item.name | truncate(36) }}
          td {{ item.short_name }}
          td {{ item.address?.line1 }}
          td.text-right {{ item.address?.city }}, {{ item.address?.state }}
          td.text-right
            a.secondary--text(@click.stop="editHours(item)")
              DayHours(:schedule="item.schedule")
          td.text-right
            AssignChain(:biz="item")
          td.text-right
            AssignRegion(:biz="item")
          td.text-right
            DeleteOne(
              :enterprise="enterprise",
              :item="item",
              @deleted="$emit('saved')"
            )
  Detail(ref="detail", @updated="$emit('saved')")
  Hours(ref="hours", @updated="$emit('saved')")
</template>

<script>
import { mapGetters } from "vuex";
import AddOne from "./AddOne";
import AssignChain from "./AssignChain";
import AssignRegion from "./AssignRegion";
import DeleteOne from "./DeleteOne";
import Detail from "./Detail";
import Hours from "./Hours";
import OnlineStatus from "./OnlineStatus";

export default {
  components: {
    Detail,
    OnlineStatus,
    AssignRegion,
    AssignChain,
    Hours,
    AddOne,
    DeleteOne,
  },
  props: {
    bizs: { type: Array, required: true },
  },
  data() {
    return {
      dialog: false,
      items: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    ...mapGetters("entp", ["enterprise"]),
  },
  methods: {
    open(item) {
      this.$refs.detail.open(item);
    },
    editHours(item) {
      this.$refs.hours.open(item);
    },
  },
};
</script>


<template lang="pug">
v-container
  Count
  Weekly
</template>

<script>
import Count from "./Count";
import Weekly from "./Weekly";

export default {
  components: { Count, Weekly },
};
</script>

<template lang="pug">
table.payment-table.text-right
  AmountTableRow(name="Subtotal", :amount="invoice.payment.subtotal")
  AmountTableRow(name="Tax", :amount="invoice.payment.tax")
  AmountTableRow(name="Total", :amount="invoice.payment.total", bold)
  AmountTableRow(
    :name="paidTitle",
    :link="link",
    :amount="invoice.payment.charge.amount",
    bold,
    v-if="isPaid"
  )
</template>

<script>
export default {
  props: ["invoice"],
  computed: {
    isPaid() {
      return this.invoice?.payment?.charge?.amount;
    },
    link() {
      if (!this.isPaid) return "";
      return `https://dashboard.stripe.com/payments/${this.invoice.payment.charge.id}`;
    },
    paidTitle() {
      if (!this.isPaid) return "";
      return `Paid (${this.invoice.payment.charge.method})`;
    },
  },
};
</script>

<style lang="sass" scoped>
.payment-table
  width: 100%
</style>
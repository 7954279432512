<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    v-btn(v-on="on", icon)
      v-icon(small) mdi-pencil
  v-card
    v-toolbar(dense, flat)
      .overline Edit an Income
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col
            v-select(v-model="year", :items="year_items", label="Year")
          v-col
            v-select(v-model="month", :items="month_items", label="Month")
          v-col
            v-text-field(v-model="merchantCount", label="Number of Merchants")
        v-row
          v-col
            v-text-field(v-model="income", label="Income")
          v-col
            v-text-field(
              v-model="transAmount",
              label="Total Transaction Amount"
            )
        v-btn(
          type="submit",
          block,
          depressed,
          color="secondary",
          :disabled="!income || !transAmount"
        ) Save
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  props: ["input"],
  data() {
    return {
      dialog: false,
      year: new Date().getFullYear(),
      year_items: _.range(new Date().getFullYear(), 2015),
      month: 1,
      month_items: _.range(1, 13),
      merchantCount: 3,
      income: 0,
      transAmount: 0,
    };
  },
  watch: {
    dialog(val) {
      if (val && this.input) {
        this.year = this.input.year;
        this.month = this.input.month;
        this.merchantCount = this.input.merchantCount;
        this.income = this.input.income;
        this.transAmount = this.input.transAmount;
      }
    },
  },
  methods: {
    ...mapActions(["updateMerchantIncome"]),
    async submit() {
      if (!this.input || this.income <= 0 || this.transAmount <= 0) return;
      const params = {
        criteria: { _id: this.input._id },
        action: {
          $set: {
            year: this.year,
            month: this.month,
            merchantCount: this.merchantCount,
            income: this.income,
            transAmount: this.transAmount,
          },
        },
      };
      const result = await this.$api.merchant.income.update(params);
      this.updateMerchantIncome(result);
      this.dialog = false;
    },
  },
};
</script>

import _ from 'underscore'

const state = {
  cart: {
    order: {
      biz: null,
      organization: null,
      email: '',
      name: '',
      address: {},
      taxRate: 0,
      items: [],
      payment: { subtotal: 0, tax: 0, total: 0 }
    },
    payment: {}
  }
}

const getters = {
  cartOrder: state => state.cart.order,
  cartPayment: state => state.cart.payment,
}

const actions = {
  addItemToCart: ({ commit }, data) => { commit('addItemToCart', data) },
  removeItemAt: ({ commit }, data) => { commit('removeItemAt', data) },
  editItemAt: ({ commit }, data) => { commit('editItemAt', data) },
  updateCartPayment: ({ commit }) => { commit('updateCartPayment') },
  setCartTaxRate: ({ commit }, data) => { commit('setCartTaxRate', data) },
  resetCart: ({ commit }) => { commit('resetCart') },
  submitCart: async ({ commit, state }) => {
    // validate cart
    let errors = []
    const order = state.cart.order
    if (!order.biz && !order.organization) errors.push('Invalid biz or organization')
    if (!order.items || !order.items.length) errors.push('Empty cart')
    if (errors.length) {
      throw new Error(errors.join('</br>'))
    }
    try {
      const result = await window.axios.post('/bizOrder/create', { order })
      commit('resetCart')
      return result
    } catch (err) {
      throw new Error(err.response?.data || 'Failed to pass server validation')
    }
  }
}

const mutations = {
  setCartTaxRate(state, rate) {
    state.cart.order.taxRate = rate
    this.dispatch('updateCartPayment')
  },
  addItemToCart(state, item) {
    const data = JSON.parse(JSON.stringify(item))
    data.quantity = 1
    data.off = 0
    if (data.detail) {
      data.detail = data.detail.substring(0, 100)
    }
    state.cart.order.items.push(data)
    this.dispatch('updateCartPayment')
  },
  removeItemAt(state, index) {
    if (index < state.cart.order.items.length) {
      state.cart.order.items.splice(index, 1)
      this.dispatch('updateCartPayment')
    }
  },
  editItemAt(state, data) {
    if (data.index < state.cart.order.items.length) {
      state.cart.order.items[data.index] = data.item
      this.dispatch('updateCartPayment')
    }
  },
  updateCartPayment(state) {
    state.cart.order.payment.subtotal = _.reduce(state.cart.order.items, (memo, item) => {
      let off = 0
      if (item.off && item.off > 0 && item.off <= 100) {
        off = item.off
      }
      const value = Math.round(item.price * (100 - off) * item.quantity) / 100
      return memo + value
    }, 0)
    const taxRate = state.cart.order.taxRate || 0
    state.cart.order.payment.tax = Math.round(state.cart.order.payment.subtotal * taxRate) / 100
    state.cart.order.payment.total = Math.round((state.cart.order.payment.subtotal + state.cart.order.payment.tax) * 100) / 100
  },
  resetCart(state) {
    state.cart = {
      order: {
        biz: null,
        organization: null,
        email: '',
        name: '',
        address: {},
        taxRate: 0,
        items: [],
        payment: { subtotal: 0, tax: 0, total: 0 }
      },
      payment: {}
    }
  },
}

export default { state, getters, actions, mutations }
<template lang="pug">
v-card(v-if="orders?.length", flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }}
  simple-table
    thead
      tr
        th Business
        th Time
        th Server
        th Total
        th Charged
        th Status
        th.text-right
    tbody
      tr(
        v-for="(item, index) in items",
        :key="index",
        role="button",
        @click="show(item)"
      )
        th
          Seller(:item="item")
        td {{ item.needed | time(item.seller?.address?.timezone) }}
        td {{ item.orderer?.name }}
        td {{ item.payment.total | currency }}
          span.ml-1(v-if="item.payment.tip") ({{ item.payment.tip | currency }})
        td
          OrderCharged(:item="item")
        td
          span(:class="{ 'red--text': item.status === -1 }") {{ item.status | orderStatus }}
        td.text-right
          v-menu(offset-y)
            template(v-slot:activator="{ on }")
              v-btn(icon, small, v-on.stop="on")
                v-icon(color="secondary") mdi-dots-horizontal
            v-list.py-0(dense)
              RefundDeliv(:order="item")
              RenotifyOnline(:order="item")
              ChargeDeliverOnCancel(:order="item")
              v-list-item(
                title="delivery",
                v-if="item.deliv",
                @click.stop="showDelivOrder(item)"
              ) Delivery Status
              v-list-item(
                @click.stop="handleCancelEmail(item)",
                v-if="item.status == 0"
              ) Email Customer for Cancel
              v-list-item(
                v-if="item.type == 'pickup' && item.proxy != 'Test' && item.status >= 0",
                @click.stop="handleChangeToDelivery(item._id)"
              ) Upgrade to Delivery
              v-list-item(
                v-if="item.type == 'delivery' && item.proxy != 'Test' && item.status >= 0",
                @click.stop="handleUpdateAddress(item._id)"
              ) Edit Customer Address
              v-list-item(
                v-if="item.status == 3 && item.proxy != 'Test'",
                @click.stop="handleCompensate(item)"
              ) Issue Compensate
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions } from "vuex";
import ChargeDeliverOnCancel from "./ChargeDeliverOnCancel";
import OrderCharged from "./OrderCharged";
import RefundDeliv from "./RefundDeliv";
import RenotifyOnline from "./RenotifyOnline";
import Seller from "./Seller";

export default {
  components: {
    Seller,
    OrderCharged,
    RefundDeliv,
    RenotifyOnline,
    ChargeDeliverOnCancel,
  },
  props: ["orders", "title"],
  computed: {
    items() {
      return _.sortBy(this.orders, (o) => o.needed);
    },
  },
  methods: {
    ...mapActions(["setOrders", "updateOrder"]),
    show(item) {
      if (item?._id) {
        EventBus.$emit("show-order-detail", item._id);
      }
    },
    showDelivOrder(order) {
      if (!order) return;
      EventBus.$emit("show-deliv-order", order._id);
    },
    handleCompensate(order) {
      EventBus.$emit("compensate-order", order);
    },
    handleCancelEmail(item) {
      if (!item || item.status !== 0) return;
      confirm(
        "Do you want to email customer to check the status of the order?"
      ) && this.sendCancelEmail(item);
    },
    async sendCancelEmail(item) {
      const params = { orderId: item._id };
      try {
        await this.axios.post("/email/orderNeedCancel", params);
        this.$toast.success("Email sent");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    handleChangeToDelivery(orderId) {
      if (!orderId) return;
      EventBus.$emit("change-to-delivery", orderId);
    },
    handleUpdateAddress(orderId) {
      if (!orderId) return;
      EventBus.$emit("edit-order-address", orderId);
    },
  },
};
</script>

<template lang="pug">
v-data-table(:headers="headers", :items="items", dense)
  template(v-slot:item.name="{ item }")
    span(v-if="item.name") {{ item.name.first }} {{ item.name.last }}
  template(v-slot:item.created="{ item }")
    span {{ item.created | date }}
  template(v-slot:item.action="{ item }")
    v-btn.mr-2.text-capitalize(
      small,
      depressed,
      rounded,
      v-if="item.subscribe",
      @click="unsubscribe(item)"
    ) unsubscribe
    v-btn.mr-2.text-capitalize(
      small,
      depressed,
      rounded,
      v-else,
      @click="subscribe(item)"
    ) subscribe
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on.stop="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click="awardPoint(item)") Award Points
        v-list-item(@click="editEmail(item)") Edit Email
        v-list-item(@click="handleDelete(item)") Delete
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import _ from "underscore";

export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Program", value: "programName" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Balance", value: "balance" },
        { text: "Lifetime", value: "amount" },
        { text: "Created", value: "created" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["members", "memberPrograms"]),
    items() {
      return _.map(this.members, (o) => {
        const found = _.find(
          this.memberPrograms,
          (pro) => pro._id == o.program
        );
        if (found) o.programName = found.name;
        return o;
      });
    },
  },
  methods: {
    ...mapActions(["updateMember", "removeMember"]),
    async unsubscribe(member) {
      if (!member) return;
      const params = { memberId: member._id };
      try {
        const { data } = await this.axios.post("/member/unsubscribe", params);
        this.updateMember(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async subscribe(member) {
      if (!member) return;
      const params = { memberId: member._id };
      try {
        const { data } = await this.axios.post("/member/subscribe", params);
        this.updateMember(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    awardPoint(member) {
      EventBus.$emit("award-member-points", member);
    },
    editEmail(member) {
      EventBus.$emit("edit-member-email", member);
    },
    handleDelete(member) {
      confirm("Do you really want to delete this member?") &&
        this.deleteOne(member);
    },
    async deleteOne(member) {
      if (!member) return;
      const params = { id: member._id };
      await this.axios.post("/member/remove", params);
      this.removeMember(member);
    },
  },
};
</script>

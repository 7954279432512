<template lang="pug">
v-dialog(v-model="dialog", max-width="500", scrollable)
  Form(:order="order", @done="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Form from "./Form";

export default {
  name: "EditOrderAddress",
  components: { Form },
  data() {
    return {
      order: null,
      dialog: false,
    };
  },
  mounted() {
    EventBus.$on("edit-order-address", this.load);
  },
  destroyed() {
    EventBus.$off("edit-order-address", this.load);
  },
  methods: {
    async load(orderId) {
      if (!orderId) return;
      this.dialog = true;
      const params = { criteria: { _id: orderId } };
      this.order = await this.$api.order.retrieve(params);
      if (this.order.type !== "delivery") {
        this.$toast.error("Only delivery order can be edited");
        this.dialog = false;
        return;
      }
    },
  },
};
</script>

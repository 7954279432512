<template lang="pug">
v-card(v-if="delivOrder")
  v-toolbar(dense, flat)
    span {{ delivOrder.customer.name }}
    v-spacer
    v-btn(outlined, small, color="error", @click="update(-1)") Cancel
  v-divider
  v-card-text
    p Address: {{ delivOrder.customer.address | address }}
    p Driver:
      span(v-if="delivOrder.driver") {{ delivOrder.driver.name }} {{ delivOrder.driver.id }}
    p Fee: {{ delivOrder.fee }}
    p Tip: {{ delivOrder.tip }}
    p Surcharge: {{ delivOrder.surcharge }}
    p Status: {{ delivOrder.status }}
    v-btn(outlined, small, @click="update(4)") Fulfill
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["delivOrder"],
  methods: {
    ...mapActions(["updateDelivOrder"]),
    async update(status) {
      const id = this.delivOrder?._id;
      if (!id) return;
      const action = { $set: { status } };
      const result = await this.$api.delivOrder.put(id, action);
      this.updateDelivOrder(result);
    },
  },
};
</script>

<template lang="pug">
simple-table
  tbody
    tr(v-if="payment?.charge?._id != undefined")
      th Online
      td.text-right {{ payment.charge.amount | currency }}
    tr(v-for="item in payment.charges", :key="item._id")
      td
        span.text-capitalize {{ item.method }} {{ item.time | time }}
        span(v-if="item.last4")
          span.ml-1 ...{{ item.last4 }} \#{{ item.transId }}
          div(v-if="item.device") {{ item.device.name }} {{ item.device.loc }}
        .d-flex.flex-row
          EditChargeIP(:order="order", :charge="item")
          EditCharge(:order="order", :charge="item")
      td.text-right {{ item.amount | currency }}
        VoidCharge(:item="item", :order="order", :check="check")
  tfoot
    tr
      th Total
      th.text-right {{ total | currency }}
</template>

<script>
import EditChargeIP from "./EditChargeIP";
import EditCharge from "./EditCharge";
import VoidCharge from "./VoidCharge";

export default {
  components: { EditChargeIP, EditCharge, VoidCharge },
  props: ["payment", "order", "check"],
  computed: {
    total() {
      if (!this.payment) return 0;
      let value = 0;
      if (this.payment.charge?.amount) {
        value += this.payment.charge.amount;
      }
      const amount = this.payment.charges.reduce((a, b) => a + b.amount, 0);
      return value + amount;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400")
  template(v-slot:activator="{ on }")
    IconBtn(v-on="on", icon="mdi-plus", title="Add")
  v-card
    v-toolbar(dense, flat)
      .overline Create an Income
    v-card-text
      v-form(@submit.prevent="submit")
        v-row
          v-col
            v-select(v-model="year", :items="year_items", label="Year")
          v-col
            v-select(v-model="month", :items="month_items", label="Month")
          v-col
            v-text-field(v-model="merchantCount", label="Number of Merchants")
        v-row
          v-col
            v-text-field(v-model="income", label="Income")
          v-col
            v-text-field(
              v-model="transAmount",
              label="Total Transaction Amount"
            )
        v-btn(
          type="submit",
          block,
          depressed,
          color="secondary",
          :disabled="!income || !transAmount"
        ) Save
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      year: new Date().getFullYear(),
      year_items: _.range(new Date().getFullYear(), 2015),
      month: 1,
      month_items: _.range(1, 13),
      merchantCount: 3,
      income: 0,
      transAmount: 0,
    };
  },
  methods: {
    ...mapActions(["addMerchantIncome"]),
    async submit() {
      if (this.income <= 0 || this.transAmount <= 0) return;
      const params = {
        year: this.year,
        month: this.month,
        merchantCount: this.merchantCount,
        income: this.income,
        transAmount: this.transAmount,
      };
      try {
        const result = await this.$api.merchant.income.create(params);
        this.addMerchantIncome(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

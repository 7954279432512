<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(color="secondary", v-on="on") Announce Newsletter
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Announce Newsletter
    v-card-text
      v-select(:items="items", v-model="sel_sample", dense)
      p {{ content_short }}
      v-btn(
        color="secondary",
        block,
        @click="send()",
        :disabled="!valid",
        :loading="loading"
      ) Send Emails
</template>
<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    sel_sample: null,
    loading: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters(["email_samples"]),
    items() {
      return _.chain(this.email_samples)
        .map((o) => {
          return { text: o.subject, value: o._id };
        })
        .sortBy("subject")
        .value();
    },
    valid() {
      return this.sel_sample != undefined;
    },
    content_short() {
      if (this.sel_sample) {
        const found = this.email_samples.find((o) => o._id == this.sel_sample);
        if (found) return found.content_short;
      }
      return "";
    },
  },
  mounted() {
    this.loadSample();
  },
  methods: {
    ...mapActions(["setEmailSamples"]),
    async send() {
      if (!this.sel_sample) return;
      this.loading = true;
      const params = { sample_id: this.sel_sample, zone: "US-NM-ABQ" };
      try {
        const { data } = await this.axios.post(
          "/driver/accounts/mail/announce",
          params
        );
        this.$toast.success(data);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    async loadSample() {
      const params = { criteria: {}, select: "subject content_short" };
      const { data } = await this.axios.post(
        "/corp/tool/email_sample/list",
        params
      );
      this.setEmailSamples(data);
    },
  },
};
</script>

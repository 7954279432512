<template lang="pug">
v-dialog(v-model="dialog", width="500", scrollable)
  v-card(v-if="credits")
    v-card-title Credit History
    v-card-text
      div(v-for="(item, index) in credits", :key="index")
        .sl-dash-divider(v-if="index > 0")
        .sl-row
          .my-2
            div {{ item.created | datetime }}
            div(v-if="!item.charge") Note: {{ item.note }}
            .sl-secondary-text {{ item.charge }}
            Refund(:item="item", @refunded="load")
          v-spacer
          span {{ item.amount | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import Refund from "./Refund";

export default {
  components: { Refund },
  data() {
    return {
      bizId: null,
      dialog: false,
      credits: [],
    };
  },
  mounted() {
    EventBus.$on("show-biz-account-credit-list", this.load);
  },
  destroyed() {
    EventBus.$off("show-biz-account-credit-list", this.load);
  },
  methods: {
    async load(biz) {
      this.bizId = biz;
      if (!this.bizId) return;
      const params = { criteria: { biz: this.bizId } };
      try {
        const { data } = await this.axios.post(
          "/bizAccountCredit/list",
          params
        );
        this.credits = data;
        this.dialog = true;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
<template lang="pug">
div
  IconBtn(@click="create()", icon="mdi-plus", title="Add")
  List.my-5
  Form
</template>

<script>
import List from "./List/index";
import Form from "./Form/index";
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  components: { List, Form },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setPOIs"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.poi.list(params);
      this.setPOIs(result);
    },
    create() {
      EventBus.$emit("edit-poi");
    },
  },
};
</script>

<template lang="pug">
v-btn(@click="submit", outlined, color="secondary", small, :loading="loading") Run Monday Jobs
</template>

<script>
export default {
  data() {
    return { loading: false };
  },
  methods: {
    async submit() {
      this.loading = true;
      try {
        const { data } = await this.axios.get("/corp/tool/jobs/runMonday");
        this.$toast.success(data);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-btn.text-capitalize(
  @click="submit()",
  :loading="loading",
  color="secondary",
  block
) Pay by Credit Card
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["invoice"],
  data() {
    return { loading: false };
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    async submit() {
      const invoiceId = this.invoice?._id;
      if (!invoiceId) return;
      this.loading = true;
      try {
        const result = await this.$api.invoice.custom("pay", { invoiceId });
        this.updateInvoice(result);
        this.$toast.success("Payment successful");
        this.$emit("close");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-btn(v-if="visible", @click="dialog = true", small, outlined, color="error") Make it Dummy
  v-dialog(v-model="dialog", width="400")
    v-card
      v-toolbar(flat)
        .subtitle-2 This is a test account that would never be used in live
      v-card-text
        v-form(@submit.prevent="save")
          v-btn(type="submit", block, color="secondary") Yes, I Confirm
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return { dialog: false };
  },
  computed: {
    ...mapGetters(["biz"]),
    visible() {
      const items = ["test", "permanently_closed"];
      if (items.includes(this.biz?.standing)) return false;
      return this.biz && !this.biz.online_start && !this.biz.pos_start;
    },
  },
  methods: {
    ...mapActions(["updateBizAccount"]),
    async save() {
      const params = { bizId: this.biz._id };
      try {
        const result = await this.$api.b.action.custom("makeDummy", params);
        this.updateBizAccount(result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to update standing");
      }
    },
  },
};
</script>
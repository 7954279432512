<template lang="pug">
.d-flex.flex-row.align-center
  v-btn.text-capitalize.mr-3(@click="loadWithNoPayout()", outlined) Load No Payouts
  MonthSelector(@select="select")
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";

export default {
  data() {
    return { range: null };
  },
  computed: {
    end() {
      const endoflastweek = moment().startOf("isoWeek").valueOf();
      if (this.range) {
        if (this.range.end > endoflastweek) return endoflastweek;
        else return this.range.end;
      }
      return endoflastweek;
    },
  },
  methods: {
    ...mapActions(["setDriverPayouts"]),
    async select({ range }) {
      if (range) this.range = range;
      if (!this.range) return;
      const params = {
        criteria: {
          created: { $gte: this.range.begin, $lt: this.range.end },
        },
        limit: 1000,
      };
      const { data } = await this.axios.post("/driver/payouts/list", params);
      this.setDriverPayouts(data);
    },
    async loadWithNoPayout() {
      if (!this.range) return;
      const params = {
        criteria: {
          created: { $gte: this.range.begin, $lt: this.end },
          type: { $ne: 1 },
          status: { $gte: 0 },
          driverPayout: { $exists: false },
        },
        select: "_id",
      };
      const { data } = await this.axios.post("/delivOrders/list", params);
      const ids = data?.map((item) => item._id);
      if (!ids?.length) {
        return this.$toast.success("All have payouts");
      }
      EventBus.$emit("show-deliv-orders", ids);
    },
  },
};
</script>

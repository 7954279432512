<template lang="pug">
v-select(v-model="content", :items="items", label="Size")
</template>

<script>
export default {
  props: {
    value: { type: String, default: null },
  },
  data() {
    return {
      content: this.value,
      items: [
        "<$10k",
        "$10k-$20k",
        "$20k-$30k",
        "$30-$50k",
        "$50-$100k",
        ">$100k",
      ],
    };
  },
  watch: {
    value(val) {
      this.content = val;
    },
    content() {
      this.$emit("input", this.content);
    },
  },
};
</script>
<template lang="pug">
v-text-field(
  type="tel",
  :label="label",
  v-model="content",
  :error-messages="errorMessages",
  v-on="$listeners",
  :solo="solo"
)
</template>

<script>
export default {
  name: "AccountField",
  props: {
    value: { type: String, default: "" },
    label: { type: String, default: "Account" },
    errorMessages: { type: Array, default: null },
    solo: { type: Boolean, default: false },
    num: { type: Number, default: 4 },
  },
  data() {
    return { content: this.$options.filters.phone(this.value) };
  },
  watch: {
    value() {
      this.content = this.spacing(this.value);
    },
    content(newval, oldval) {
      if (!oldval) return;
      if (newval == oldval) return;
      this.$emit("input", this.content.replace(/\D+/g, ""));
    },
  },
  methods: {
    // spacing text at every 4 characters
    spacing(text) {
      const reg = new RegExp(`(.{${this.num}})`, "g");
      return text.replace(reg, "$1 ");
    },
  },
};
</script>

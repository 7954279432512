<template lang="pug">
v-card(flat, v-if="enterprise")
  v-toolbar(flat)
    .subtitle-2 Users &#38; Access ({{ items.length }})
    v-text-field(
      v-model="search",
      append-icon="mdi-magnify",
      label="Search",
      placeholder="Name, email, phone",
      clearable,
      dense,
      hide-details,
      style="max-width: 200px; padding-left: 20px"
    )
    v-spacer
    IconBtn(@click="create()", title="Add", icon="mdi-plus")
  v-data-table(
    :search="search",
    :items="mappedItems",
    :headers="headers",
    @click:row="edit",
    role="button",
    sort-by="first_name",
    mobile-breakpoint=0
  )
    template(v-slot:item.avatar="{ item }")
      v-avatar(v-if="item.avatar")
        img(:src="item.avatar")
    template(v-slot:item.phone="{ item }")
      span.nowrap {{ item.phone | phone }}
    template(v-slot:item.privilege="{ item }")
      span.nowrap {{ item.privilege | privilege }}
    template(v-slot:item.regions="{ item }")
      span.nowrap {{ getRegions(item.regions) }}
    template(v-slot:item.created="{ item }")
      span.nowrap {{ item.created | date }}
  v-divider
  v-card-text
    Info
  Form(ref="form", :enterprise="enterprise")
</template>

<script>
import { mapGetters } from "vuex";
import Form from "./Form";
import Info from "./Info";
import Invite from "./Invite";

export default {
  name: "EnterpriseAccessList",
  components: { Invite, Form, Info },
  filters: {
    privilege(val) {
      if (val === 1) return "Admin";
      if (val === 2) return "Manager";
      return val;
    },
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: "Picture", value: "avatar", filterable: false },
        { text: "First", value: "first_name" },
        { text: "Last", value: "last_name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Access", value: "privilege" },
        { text: "Regions", value: "regions" },
        { text: "Status", value: "status", align: "end" },
        { text: "Date", value: "created", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters("entp", ["enterprise", "regions"]),
    mappedItems() {
      return this.items.map((o) => {
        if (o.profile_picture) {
          o.avatar = process.env.VUE_APP_MEDIAHOST + o.profile_picture;
        }
        return o;
      });
    },
  },
  methods: {
    create() {
      this.$refs.form.open();
    },
    edit(data) {
      this.$refs.form.open(data);
    },
    getRegions(list) {
      if (!list?.length) return "All";
      return list
        ?.map((o) => {
          const found = this.regions?.find((r) => r._id === o);
          return found?.name || o;
        })
        .join(", ");
    },
  },
};
</script>
<template lang="pug">
.my-2
  .my-box
    v-form(@submit.prevent="submit()")
      v-text-field(
        v-model="searchText",
        label="Search Enterprise",
        append-icon="search",
        solo,
        hide-details,
        dense,
        color="secondary",
        :loading="loading"
      )
    v-list.py-0.list-box(dense, v-if="items && items.length")
      v-list-item(
        v-for="(item, index) in items",
        :key="index",
        @click="select(item)"
      )
        v-list-item-content
          v-list-item-title {{ item.name }}
          v-list-item-subtitle(v-if="item.address") {{ item.address | address }}
        v-list-item-action(v-if="item._id == selection")
          v-icon.fa.fa-check(small, color="success")
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      items: [],
      selection: null,
      loading: false,
    };
  },
  watch: {
    search(val) {
      if (val.length >= 4) this.submit();
    },
  },
  methods: {
    async submit() {
      const searchText = this.searchText.trim();
      if (!searchText || searchText.length < 3) {
        return this.$toast.error("Please enter a name with at least 3 letters");
      }
      if (this.loading) return;
      this.loading = true;
      const params = {
        criteria: { name: { $regex: searchText, $options: "i" } },
      };
      this.items = await this.$api.entp.enterprise.list(params);
      this.items.sort((a, b) => a.name.localeCompare(b.name));
      if (this.items.length == 1) this.select(this.items[0]);
      this.loading = false;
    },
    select(item) {
      if (!item) return;
      this.selection = item._id;
      this.$emit("select", item);
    },
  },
};
</script>

<style lang="sass" scoped>
.my-box
  display: inline-block

.list-box
  max-height: 200px
  overflow-y: scroll

  &::-webkit-scrollbar
    display: none
</style>

<template lang="pug">
div
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServiceItems"]),
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.service.item.list(params);
      this.setBizServiceItems(result);
    },
  },
};
</script>

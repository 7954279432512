<template lang="pug">
div(v-if="invoices")
  v-toolbar(dense, flat)
    .subtitle-2 {{ title }} {{ total | currency }} | Org Bill: {{ org_bill_total | currency }} | Org Invoice: {{ org_invoice_total | currency }}
    v-spacer
    v-text-field(
      v-model="search",
      append-icon="search",
      label="Search",
      single-line,
      hide-details,
      clearable
    )
  v-data-table(
    :items="invoices",
    :headers="headers",
    sort-by="biz.name",
    :search="search",
    dense,
    mobile-breakpoint=0,
    @click:row="show",
    role="button"
  )
    template(v-slot:item.period="{ item }")
      span {{ item.period.year }}/{{ item.period.month }}
      v-icon.ml-1(v-if="item.emailed", x-small, color="success") mdi-email
    template(v-slot:item.payment.total="{ item }")
      span {{ item.payment.total | currency }}
    template(v-slot:item.billing_organization="{ item }")
      span {{ getOrganizationName(item.billing_organization) }}
    template(v-slot:item.payment.charge.amount="{ item }")
      span {{ item.payment.charge?.amount | currency }}
    template(v-slot:item.payment.charge.time="{ item }")
      span(v-if="item.payment.charge?.time") {{ item.payment.charge?.time | datetime }}
    template(v-slot:item.actions="{ item }")
      span(v-if="item.payment.charge?.time") Paid
      v-menu(offset-y, v-else)
        template(v-slot:activator="{ on }")
          v-btn(icon, small, v-on="on")
            v-icon(color="secondary") mdi-dots-horizontal
        v-list.py-0(dense)
          v-list-item(@click="handlePay(item)") Pay
          v-list-item(@click="handleAddCheck(item)") Pay By Check
          v-list-item(@click="getBalance(item)") Retrieve Biz Balance
          v-list-item(@click="sendEmail(item)", v-if="!item.emailed") Send Email
          v-list-item(@click="emailReminder(item)") Email Reminder
          v-list-item(@click="showThirtyDay(item)") 30 Day Stats
          Migrate(:invoice="item")
          Remove(:invoice="item")
  v-dialog(v-model="dialog", width="300")
    v-card
      v-toolbar(flat, dense)
        .subtitle Pay by check
      v-card-text
        v-form(@submit.prevent="payByCheck")
          v-text-field(label="Check info", v-model="checkInfo", ref="focus")
          v-layout(row)
            v-spacer
            v-btn(text, @click.stop="dialog = false") cancel
            v-btn(text, :disabled="!checkInfo", type="submit") save
  SalesForceOrganizationLoad
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions, mapGetters } from "vuex";
import Migrate from "./Migrate";
import Remove from "./Remove";

export default {
  components: { Remove, Migrate },
  props: ["invoices", "title"],
  data() {
    return {
      dialog: false,
      checkInfo: "",
      invoiceId: "",
      search: "",
      headers: [
        { text: "Biz", value: "biz.name" },
        { text: "Period", value: "period", sortable: false },
        { text: "Total", value: "payment.total" },
        { text: "Billing", value: "billing_organization" },
        { text: "Org Invoice", value: "org_invoice" },
        { text: "Paid", value: "payment.charge.amount" },
        { text: "Paid Time", value: "payment.charge.time" },
        { text: "Actions", value: "actions", sortable: false, align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    total() {
      return this.invoices.reduce((a, b) => a + b.payment.total, 0);
    },
    org_bill_total() {
      return this.invoices
        .filter((o) => o.billing_organization)
        .reduce((a, b) => a + b.payment.total, 0);
    },
    org_invoice_total() {
      return this.invoices
        .filter((o) => o.org_invoice)
        .reduce((a, b) => a + b.payment.total, 0);
    },
  },
  watch: {
    dialog(val) {
      if (!val) return;
      requestAnimationFrame(() => {
        this.$refs.focus.focus();
      });
    },
  },
  methods: {
    ...mapActions(["updateInvoice"]),
    handleAddCheck(invoice) {
      this.invoiceId = invoice._id;
      confirm("Are you sure to add a check as payment for this invoice?") &&
        this.openAddCheck();
    },
    openAddCheck() {
      this.dialog = true;
      this.checkInfo = "";
    },
    async payByCheck() {
      const params = { invoiceId: this.invoiceId, check: this.checkInfo };
      try {
        const result = await this.$api.invoice.custom("payByCheck", params);
        this.updateInvoice(result);
        this.$toast.success("Paid by check successfully");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.dialog = false;
    },
    handlePay(invoice) {
      EventBus.$emit("show-pay-invoice", invoice);
    },
    async getBalance(invoice) {
      const bizId = invoice.biz.id;
      const params = { bizId: bizId };
      try {
        const result = await this.$api.stripe.balance.retrieve(params);
        this.$toast.success(JSON.stringify(result));
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async emailReminder(invoice) {
      const bizId = invoice.biz.id;
      try {
        await this.$api.invoice.custom("emailReminder", { bizId });
        this.$toast.success("Email reminder sent successfully");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    async sendEmail(invoice) {
      if (!invoice) return;
      try {
        const result = await this.$api.invoice.custom("sendEmail", {
          invoiceId: invoice._id,
        });
        this.updateInvoice(result);
        this.$toast.success("Email sent successfully");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    showThirtyDay(invoice) {
      if (!invoice) return;
      EventBus.$emit("show-thirty-day", invoice.biz.id);
    },
    show(item) {
      if (!item) return;
      EventBus.$emit("show-invoice-detail", item._id);
    },
    getOrganizationName(id) {
      const found = this.salesOrganizations.find((o) => o._id === id);
      return found?.name || "";
    },
  },
};
</script>

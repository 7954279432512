<template lang="pug">
div
  IconBtn(title="Add", icon="mdi-plus", @click="create")
  Table.my-5
  Form
  BizServiceLoad
</template>

<script>
import Table from "./Table";
import Form from "./Form";
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";

export default {
  components: { Table, Form },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServiceSubscriptions"]),
    create() {
      EventBus.$emit("edit-service-subscription");
    },
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.service.subscription.list(params);
      this.setBizServiceSubscriptions(result);
    },
  },
};
</script>
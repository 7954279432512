<template lang="pug">
v-btn.my-5(@click="handleMail", v-if="hasNotAnnounced") Weekly Reward
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["driverRewardPenalties"]),
    hasNotAnnounced() {
      return this.driverRewardPenalties.some(
        (o) => o.amount > 0 && !o.driverPayout
      );
    },
  },
  methods: {
    handleMail() {
      confirm("Do you want to annouce weekly reward to drivers?") &&
        this.send();
    },
    async send() {
      const { data } = await this.axios.post(
        "/driver/accounts/mail/weeklyReward",
        {}
      );
      this.$toast.success(data);
    },
  },
};
</script>

<template lang="pug">
Page(title="Credit Return")
  MonthSelector(@select="load")
  v-data-table.my-3(:headers="headers", :items="result", v-if="result")
    template(v-slot:item.created="{ item }")
      span {{ item.created | datetime }}
    template(v-slot:item.amount="{ item }")
      span {{ item.amount | currency }}
    template(v-slot:item.action="{ item }")
      v-btn(icon, small, @click="showOrder(item.order)")
        v-icon(small) mdi-information
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  data: () => ({
    result: null,
    range: null,
    headers: [
      { text: "Time", value: "created" },
      { text: "Amount", value: "amount", sortable: false },
      { text: "Name", value: "name", sortable: false },
      { text: "Last4", value: "last4", sortable: false },
      { text: "Auth Code", value: "approve", sortable: false },
      { text: "", value: "action", sortable: false },
    ],
  }),
  methods: {
    async load(selected) {
      if (selected) this.range = selected.range;
      if (!this.range) return;

      const params = {
        criteria: { created: { $gt: this.range.begin, $lte: this.range.end } },
      };
      this.loading = true;
      try {
        this.result = await this.$api.transaction.refund.list(params);
      } catch (err) {
        this.result = null;
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
    showOrder(orderId) {
      if (!orderId) return;
      EventBus.$emit("show-order-detail", orderId);
    },
  },
};
</script>

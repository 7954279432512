<template lang="pug">
Page(title="Inventory", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "In", url: "/shop/inventory/in" },
        { title: "Out", url: "/shop/inventory/out" },
      ],
    };
  },
};
</script>

<template lang="pug">
div
  v-card(flat, :loading="loading")
    v-toolbar(flat, dense)
      YearSelector(@select="load")
      v-spacer
      v-select(
        v-model="category_selected",
        :items="categories",
        multiple,
        chips,
        label="Categories",
        hide-details
        item-text='name'
        item-value='_id'
      )
      IconBtn(@click="create", title="Add", icon="mdi-plus")
    v-card-text
      v-timeline(align-top, dense)
        v-timeline-item(v-for="(item, index) in items", :key="index", small)
          .link-box(style='align-items: flex-start')
            strong(style='width=100px') {{ item.date | date({dynamic: true}) }}
            div
              strong(@click="edit(item)" role='button') {{ item.name }}
              div {{ item.detail }}
  Form(ref="form")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form";

export default {
  components: { Form },
  data() {
    return {
      category_selected: [],
      year: new Date().getFullYear(),
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["timeline_events", "timeline_categories"]),
    categories() {
      if (!this.timeline_categories?.length) return [];
      return this.timeline_categories.toSorted((a, b) =>
        a.name?.localeCompare(b.name)
      );
    },
    events() {
      if (!this.timeline_events?.length) return [];
      if (typeof this.timeline_events != "object") return [];
      return this.timeline_events.toSorted((a, b) => b.date - a.date) || [];
    },
    items() {
      if (!this.category_selected?.length) return this.events;
      return this.events.filter((event) =>
        event.categories.some((category) =>
          this.category_selected.includes(category)
        )
      );
    },
  },
  methods: {
    ...mapActions(["setTimelineEvents"]),
    async load(year) {
      this.loading = true;
      if (year) this.year = year;
      const begin = new Date(this.year, 0, 1);
      const end = new Date(this.year + 1, 0, 1);
      const criteria = { date: { $gte: begin.getTime(), $lt: end.getTime() } };
      try {
        const result = await this.$api.timeline.event.list({ criteria });
        this.setTimelineEvents(result);
      } catch (e) {
        this.$toast.error(e.response?.data);
      }
      this.loading = false;
    },
    create() {
      const data = {
        name: "",
        detail: "",
        link: "",
        date: new Date().getTime(),
        categories: [],
        access: "public",
      };
      this.$refs.form.open(data);
    },
    edit(event) {
      this.$refs.form.open(event);
    },
  },
};
</script>
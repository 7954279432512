<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card(v-if="order")
    v-card-title
    v-card-text
      OrderHeader(:order="order")
      v-form(@submit.prevent="submit()")
        v-switch(
          v-model="confirm",
          label="Confirm that you approve the tax exempt",
          color="secondary"
        )
        v-btn(
          type="submit",
          :disabled="!confirm",
          block,
          color="secondary",
          :loading="loading"
        ) Exempt Tax
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "TaxExempt",
  data() {
    return {
      dialog: false,
      loading: false,
      confirm: false,
    };
  },
  computed: { ...mapGetters(["order"]) },
  mounted() {
    EventBus.$on("order-tax-exempt", this.open);
  },
  destroyed() {
    EventBus.$off("order-tax-exempt", this.open);
  },
  methods: {
    ...mapActions(["updateOrder"]),
    async open() {
      this.confirm = false;
      this.dialog = true;
    },
    async submit() {
      if (!this.order) return;
      this.loading = true;
      const params = { orderId: this.order._id };
      try {
        const { data } = await this.axios.post("/orders/taxExempt", params);
        this.updateOrder(data);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-list-item(@click="assignCode(item)") AssignCode
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  methods: {
    ...mapActions(["updateInfluencer"]),
    async assignCode(item) {
      const params = { influencerId: item._id };
      try {
        const result = await this.$api.salesforce.influencer.custom(
          "assignCode",
          params
        );
        this.updateInfluencer(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
  },
};
</script>

<template lang="pug">
simple-table
  thead
    tr
      th Name
      th Email
      th Phone
      th Access
      th
  tbody
    tr(v-for="(item, index) in items", @click="edit(item)", role="button")
      th {{ item.name }}
      td {{ item.email }}
      td {{ item.phone | phone }}
      td {{ item.privilege | role }}
        span.ml-2(
          v-if="item.access_wage && (item.privilege == 2 || item.privilege == 4)"
        ) (Wage)
      td.text-right
        v-btn(icon, small, color="error", @click.stop="handleRemove(item)")
          v-icon(small) mdi-delete
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  filters: {
    role(val) {
      if (val == 1) return "Owner";
      if (val == 2) return "Manager";
      if (val == 3) return "POS App Only";
      if (val == 4) return "Custom";
      return "Role";
    },
  },
  computed: {
    ...mapGetters(["bizaccess", "biz"]),
    items() {
      if (!this.biz) return [];
      return _.chain(this.bizaccess)
        .filter((o) => o.biz == this.biz._id)
        .sortBy("name")
        .sortBy("privilege")
        .value();
    },
  },
  methods: {
    ...mapActions(["removeBizAccess"]),
    handleRemove(access) {
      const isMe = access?.user == this.$auth.user()._id;
      if (isMe) return this.$toast.error("You can't remove yourself");
      confirm("Do you really want to delete this user & access?") &&
        this.remove(access);
    },
    async remove(access) {
      if (!access) return;
      try {
        const result = await this.$api.b.access.delete(access._id);
        this.removeBizAccess(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
    edit(item) {
      EventBus.$emit("edit-access", item);
    },
  },
};
</script>
<template lang="pug">
simple-table
  tbody
    tr(
      v-for="(charge, index) in charges",
      :key="index",
      @click="show(charge)",
      role="button"
    )
      td {{ charge.selflane_net }}
      td.text-right {{ charge.created | datetime }}
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: ["charges"],
  methods: {
    show(charge) {
      EventBus.$emit("show-stripe-charge", charge);
    },
  },
};
</script>
<template lang="pug">
v-card(flat)
  simple-table
    thead
      tr
        th Amount
        th Spending
        th.text-right Balance
    tbody
      tr
        td {{ amount | currency }}
        td {{ (amount - balance) | currency }}
        td.text-right {{ balance | currency }}
</template>

<script>
export default {
  props: ["accounts"],
  computed: {
    amount() {
      return this.accounts?.reduce((a, b) => a + b.amount, 0);
    },
    balance() {
      return this.accounts?.reduce((a, b) => a + b.balance, 0);
    },
  },
};
</script>
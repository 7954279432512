<template lang="pug">
v-row(wrap, dense)
  v-col(
    cols="12",
    sm="6",
    v-for="(group, gindex) in grouped_driver",
    :key="gindex"
  )
    v-card(outlined)
      v-toolbar(flat, dense)
        .subtitle-2 {{ gindex }}
      simple-table
        thead
          tr
            th Index
            th Driver
            th Count
            th.text-right Score
            th
        tbody
          tr(v-for="(item, index) in group", :key="index")
            td {{ index + 1 }}
            td {{ item.driverName }}
            td {{ item.count }}
            td.text-right {{ (item.avg_score * 20) | number(0) }}
            td.text-right
              v-btn(
                @click="handleReward(item)",
                x-small,
                color="secondary",
                v-if="item.count >= 40"
              ) Reward
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  props: ["driver_result"],
  computed: {
    grouped_driver() {
      const data = _.chain(this.driver_result)
        .sortBy((o) => -o.avg_score)
        .sortBy((o) => -Math.floor(o.count / 10))
        .sortBy("zone")
        .groupBy("zone")
        .value();
      return data;
    },
  },
  methods: {
    handleReward(item) {
      const reason = item.count + " orders";
      let amount = 0;
      if (item.count >= 40 && item.count < 60) amount = 20;
      else if (item.count >= 60 && item.count < 70) amount = 30;
      else if (item.count >= 70 && item.count < 80) amount = 40;
      else if (item.count >= 80) amount = 50;
      const data = {
        driver: item.driverId,
        driverName: item.driverName,
        type: "Reward",
        reason,
        zone: item.zone,
        delivOrder: null,
        order: null,
        amount,
        currency: "usd",
      };
      EventBus.$emit("edit-driver-rewardpenalty", data);
    },
  },
};
</script>

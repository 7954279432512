<template lang="pug">
v-data-table(
  :headers="headers",
  :items="items",
  dense,
  :search="search",
  mobile-breakpoint=0
)
  template(v-slot:item.action="{ item }")
    v-btn(small, icon, @click="deleteItem(item)")
      v-icon(color="error", small) mdi-delete
</template>

<script>
export default {
  props: ["items"],
  data() {
    return { search: "" };
  },
  computed: {
    headers() {
      return [
        { text: "Date", value: "date", filterable: false },
        { text: "Zone", value: "zone", filterable: false },
        { text: "Orders", value: "count", align: "end" },
        { text: "Drivers", value: "drivers", align: "end" },
        { text: "Action", value: "action", align: "end" },
      ];
    },
  },
  methods: {
    deleteItem(item) {
      this.$emit("delete", item);
    },
  },
};
</script>

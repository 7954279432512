<template lang="pug">
div
  v-btn(small, text, color="secondary", @click="open()", :loading="loading") Auth Card
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Auth Card
      v-form(@submit.prevent="load()")
        v-card-text
          v-text-field(v-model.number="amount", label="Amount (cent)")
          div(v-if="result")
            .subtitle-2 Result
            pre {{ result }}
        v-card-actions
          v-btn(color="primary", type="submit", :loading="loading") Submit
</template>

<script>
export default {
  props: {
    mid: { type: String, required: true },
    hsn: { type: String, required: true },
  },
  data() {
    return { loading: false, amount: 1000, result: null, dialog: false };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    async load() {
      if (!this.mid) return;
      this.loading = true;
      const params = { mid: this.mid, hsn: this.hsn, amount: this.amount };
      try {
        this.result = await this.$api.cardpointe.bolt.authCard(params);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
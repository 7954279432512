<template lang="pug">
div
  IconBtn(title="Add", icon="mdi-plus", @click="handleEdit()")
  Table.my-10(@edit="handleEdit")
  Form(ref="form")
</template>

<script>
import Table from "./Table";
import Form from "./Form";
import { mapActions } from "vuex";

export default {
  components: { Table, Form },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServiceItems"]),
    handleEdit(item) {
      this.$refs.form.open(item);
    },
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.service.item.list(params);
      this.setBizServiceItems(result);
    },
  },
};
</script>
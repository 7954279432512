<template lang="pug">
v-card(flat, :loading="loading", v-if="valid")
  v-toolbar(dense, flat)
    .subtitle-2 Count by {{ name }} ({{ total }})
  v-card-text
    v-row
      v-col(cols="12", md="6")
        v-data-table(
          :items="items",
          :headers="headers",
          sort-by="name",
          :sort-desc="sortDesc",
          dense,
          mobile-breakpoint=0,
          @click:row="$emit('click', $event)",
          role="button"
        )
      v-col(cols="12", md="6")
        PieChart(:chartData="pieData")
</template>

<script>
import PieChart from "./PieChart";

export default {
  components: { PieChart },
  props: {
    name: { type: String, required: true },
    items: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    sortDesc: { type: Boolean, default: false },
  },
  data() {
    return {
      colors: ["#7682d6", "#2cb2b9", "#50a948", "#ad4d24", "#a74ac3"],
    };
  },
  computed: {
    valid() {
      return this.items?.length;
    },
    total() {
      return this.items?.reduce((a, b) => a + b.count, 0) || 0;
    },
    headers() {
      return [
        { text: this.name, value: "name" },
        { text: "Count", value: "count", align: "end" },
      ];
    },
    pieData() {
      const labels = this.items?.map((o) => o.name?.slice(0, 13));
      if (labels.length > 4) {
        labels.splice(4, labels.length - 4);
        labels.push("Others");
      }
      const data = this.items?.map((o) => o.count);
      if (data.length > 4) {
        data.splice(4, data.length - 4);
        data.push(this.items.slice(4).reduce((a, b) => a + b.count, 0));
      }
      return {
        labels,
        datasets: [{ backgroundColor: this.colors, data: data }],
      };
    },
  },
};
</script>

<template lang="pug">
table.mx-3
  thead
    tr
      th Batch
      th Count
      th ID Range
  tbody
    tr(v-for="(group, index) in groups", :key="index")
      td {{ group.batchNum }}
      td {{ group.count }}
      td {{ group.firstId }} - {{ group.lastId }}
</template>

<script>
import _ from "underscore";

export default {
  props: ["items"],
  computed: {
    groups() {
      return _.chain(this.items)
        .groupBy("batchNum")
        .map((o, k) => {
          const sorted = _.sortBy(o, "transNum");
          const count = _.chain(o).groupBy("transNum").keys().value().length;
          return {
            batchNum: k,
            count: count,
            firstId: _.first(sorted).transNum,
            lastId: _.last(sorted).transNum,
          };
        })
        .value();
    },
  },
};
</script>

<style lang="sass" scoped>
table
  font-size: 14px
  border-spacing: 12px 12px
  text-align: left
</style>
<template lang="pug">
IconBtn(@click="add", title="Add", icon="mdi-plus")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";

export default {
  name: "CreateSalesPerson",
  computed: {
    ...mapGetters(["salesPerson"]),
  },
  methods: {
    add() {
      const person = {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        address: {},
        org_access: null,
        organization: this.salesPerson?.organization,
      };
      EventBus.$emit("edit-sales-person", person);
    },
  },
};
</script>

<template lang="pug">
v-card(flat, :max-width="maxWidth", height="100%", :loading="loading")
  v-toolbar(flat, dense)
    .subtitle-2 {{ input?.title }}
    span.ml-1.caption(v-if="showTotal && total")
      span(v-if="isCurrency") ({{ total | currency }})
      span(v-else) ({{ total | number(0) }})
    v-spacer
    slot(name="header")
  v-card-text
    BarChart(
      :chartData="chartData",
      :options="options",
      :height="height",
      v-if="chartType === 'bar'"
    )
    LineChart(
      :chartData="chartData",
      :options="options",
      :height="height",
      v-if="chartType === 'line'"
    )
    slot(name="content")
  slot
  v-card-actions(v-if="showDownload")
    v-spacer
    IconBtn(
      @click="download()",
      title="CSV",
      icon="mdi-download",
      v-if="showDownload"
    )
</template>

<script>
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import CSV from "/libs/utils/CSV";

export default {
  name: "StandardChart",
  components: { LineChart, BarChart },
  props: {
    // example input:
    // {
    //   title: "Orders",
    //   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    //   datasets: [], // use either datasets or values
    //   values: [], simple array of numbers for a single dataset
    //   isCurrency: true,
    //   postTick: "%",
    // }
    input: { type: Object, default: null },
    chartType: { type: String, default: "line" },
    gridLines: { type: Boolean, default: false },
    min: { type: Number, default: 0 },
    stacked: { type: Boolean, default: false },
    legend: { type: Boolean, default: false },
    showTotal: { type: Boolean, default: false },
    showDownload: { type: Boolean, default: false },
    loading: Boolean,
    height: { type: Number, default: 300 },
    maxWidth: { default: 600 },
    yBeginAtZero: { type: Boolean, default: true },
  },
  computed: {
    isCurrency() {
      return this.input?.isCurrency || false;
    },
    preTick() {
      if (this.input?.isCurrency) return "$";
      return "";
    },
    postTick() {
      return this.input?.postTick || "";
    },
    options() {
      return {
        plugins: { legend: { display: this.legend } },
        scales: {
          x: {
            grid: { display: this.gridLines },
            stack: this.stacked,
          },
          y: {
            grid: { display: this.gridLines },
            ticks: {
              min: this.min,
              precision: 0,
              callback: (value) => this.preTick + value + this.postTick,
            },
            beginAtZero: this.yBeginAtZero,
          },
        },
      };
    },
    chartData() {
      let datasets;
      if (this.input?.datasets) {
        datasets = this.input.datasets;
      } else if (this.chartType == "bar") {
        datasets = [
          { backgroundColor: "#7682d6", data: this.input?.values || [] },
        ];
      } else {
        datasets = [
          {
            label: "Value",
            borderColor: "#004775",
            borderWidth: 2,
            fill: "origin",
            data: this.input?.values || [],
          },
        ];
      }
      return {
        labels: this.input?.labels || [],
        datasets: datasets,
      };
    },
    total() {
      return this.input?.values?.reduce((a, b) => a + b, 0);
    },
  },
  methods: {
    download() {
      if (!this.input) return;
      const csv = new CSV();
      csv.addRow(this.chartData.labels);
      this.chartData.datasets.forEach((dataset) => {
        csv.addRow(dataset.data);
      });
      const title = this.slugify(this.input?.title);
      csv.save(title);
    },
    // slugify a string
    slugify(str) {
      if (!str) return "csv_download";
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();
      // remove accents, swap ñ for n, etc
      const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
      const to = "aaaaeeeeiiiioooouuuunc------";
      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }
      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes
      return str;
    },
  },
};
</script>

<template lang="pug">
Page(title="Partner", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Accounts", url: "/partner/accounts" },
        { title: "Payouts", url: "/partner/payouts" },
      ],
    };
  },
};
</script>

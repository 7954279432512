/// Register all global components here

import EnterpriseSelector from "@/components/basic/EnterpriseSelector";
import MonthlyGrowthCharts from "@/components/basic/MonthlyGrowthCharts";
import OrderHeader from "@/components/basic/OrderHeader";
import OrgSelector from "@/components/basic/OrgSelector";
import StatsGrowth from "@/components/basic/StatsGrowth";
// libs
import AccountField from "/libs/components/AccountField";
import AmountTableRow from '/libs/components/Basic/AmountTableRow';
import SimpleTable from "/libs/components/Basic/SimpleTable";
import BizAddress from '/libs/components/Biz/Address';
import BizBasic from '/libs/components/Biz/Basic';
import DayHours from '/libs/components/Biz/DayHours';
import BizEmail from '/libs/components/Biz/Email';
import BizLogo from '/libs/components/Biz/Logo';
import DishCell from '/libs/components/Biz/Menu/DishCell';
import BizPhone from '/libs/components/Biz/Phone';
import PriceLevelPicker from '/libs/components/Biz/PriceLevelPicker';
import BizSelector from '/libs/components/Biz/Selector';
import BizServiceLoad from '/libs/components/Biz/Service/Load';
import BizTaxRates from '/libs/components/Biz/TaxRates';
import CountTableChart from '/libs/components/Chart/CountTableChart';
import StandardChart from '/libs/components/Chart/StandardChart';
import ClickToCopy from '/libs/components/ClickToCopy';
import EmptyView from '/libs/components/EmptyView';
import MarkerMap from '/libs/components/Google/MarkerMap';
import GoogleAddressField from "/libs/components/GoogleAddressField";
import IconBtn from "/libs/components/IconBtn";
import PriceField from "/libs/components/Inputs/PriceField";
import RoleAvatar from '/libs/components/Inputs/RoleAvatar';
import InvoiceDetail from '/libs/components/Invoice/Detail';
import Page from '/libs/components/Page';
import PhoneField from "/libs/components/PhoneField";
import SalesForceOrganizationLoad from '/libs/components/SalesForce/Organization/Load';
import DateFieldPicker from '/libs/components/Time/DateFieldPicker';
import DaySelector from '/libs/components/Time/DaySelector';
import MonthSelector from '/libs/components/Time/MonthSelector';
import TimeRangePicker from '/libs/components/Time/TimeRangePicker';
import WeekSelector from '/libs/components/Time/WeekSelector';
import YearSelector from '/libs/components/Time/YearSelector';

function handler(Vue) {
  Vue.component('BizSelector', BizSelector)
  Vue.component('OrgSelector', OrgSelector)
  Vue.component('OrderHeader', OrderHeader)
  Vue.component('StatsGrowth', StatsGrowth)
  Vue.component('EnterpriseSelector', EnterpriseSelector)
  Vue.component('MonthlyGrowthCharts', MonthlyGrowthCharts)
  // libs
  Vue.component('SimpleTable', SimpleTable)
  Vue.component('IconBtn', IconBtn)
  Vue.component('AccountField', AccountField)
  Vue.component('PhoneField', PhoneField)
  Vue.component('PriceField', PriceField)
  Vue.component('Page', Page)
  Vue.component('ClickToCopy', ClickToCopy)
  Vue.component('GoogleAddressField', GoogleAddressField)
  Vue.component('EmptyView', EmptyView)
  Vue.component('AmountTableRow', AmountTableRow)
  Vue.component('RoleAvatar', RoleAvatar)
  Vue.component('DateFieldPicker', DateFieldPicker)
  Vue.component('TimeRangePicker', TimeRangePicker)
  Vue.component('DaySelector', DaySelector)
  Vue.component('WeekSelector', WeekSelector)
  Vue.component('MonthSelector', MonthSelector)
  Vue.component('YearSelector', YearSelector)
  Vue.component('PriceLevelPicker', PriceLevelPicker)
  Vue.component('BizBasic', BizBasic)
  Vue.component('BizPhone', BizPhone)
  Vue.component('BizAddress', BizAddress)
  Vue.component('BizLogo', BizLogo)
  Vue.component('BizTaxRates', BizTaxRates)
  Vue.component('BizEmail', BizEmail)
  Vue.component('DayHours', DayHours)
  Vue.component('DishCell', DishCell)
  Vue.component('BizServiceLoad', BizServiceLoad)
  Vue.component('StandardChart', StandardChart)
  Vue.component('CountTableChart', CountTableChart)
  Vue.component('SalesForceOrganizationLoad', SalesForceOrganizationLoad)
  Vue.component('MarkerMap', MarkerMap)
  Vue.component('InvoiceDetail', InvoiceDetail)
}

export default handler
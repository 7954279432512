<template lang="pug">
StandardChart(
  :input="chartData",
  max-width="100%",
  chartType="bar",
  stacked,
  legend
)
  v-data-table(
    :headers="headers",
    :items="items",
    sort-by="created",
    sort-desc,
    mobile-breakpoint=0,
    dense,
    @click:row="showOrder($event)",
    role="button"
  )
    template(v-slot:item.created="{ item }")
      span {{ item.created | date }}
    template(v-slot:item.amount="{ item }")
      span {{ item.amount | currency }}
    template(v-slot:item.fee="{ item }")
      span {{ item.fee | currency }}
    template(v-slot:item.total="{ item }")
      span {{ item.total | currency }}
  simple-table
    thead
      tr
        th Month
        th.text-right Amount
        th.text-right Total
    tbody
      tr(v-for="(item, index) in groupByMonth", :key="index")
        td {{ item.month }}
        td.text-right {{ item.amount | currency }}
        td.text-right {{ item.total | currency }}
</template>

<script>
import { EventBus } from "@/event-bus.js";
import moment from "moment-timezone";
import _ from "underscore";

export default {
  props: ["title", "items"],
  computed: {
    headers() {
      return [
        { text: "Created", value: "created" },
        { text: "Amount", value: "amount" },
        { text: "Total", value: "total" },
        { text: "Status", value: "dispute_status" },
        { text: "Reason", value: "dispute_reason" },
        { text: "Currency", value: "currency", align: "end" },
      ];
    },
    chartData() {
      // group items by month and show stacked bar chart with amount and fee
      let data = {};
      this.items.forEach((o) => {
        const month = moment(o.created).format("MMM");
        if (!data[month]) {
          data[month] = { amount: 0, fee: 0 };
        }
        data[month].amount += o.amount;
        data[month].fee += o.fee;
      });
      // sort by month and fill up missing months
      const months = moment.monthsShort();
      const sorted = {};
      months.forEach((month) => {
        sorted[month] = data[month] || { amount: 0, fee: 0 };
      });
      data = sorted;

      let amount = Object.values(data).reduce((a, o) => a + o.amount, 0);
      let fee = Object.values(data).reduce((a, o) => a + o.fee, 0);
      amount = Math.round(amount * 100) / 100;
      fee = Math.round(fee * 100) / 100;
      let total = amount + fee;
      total = Math.round(total * 100) / 100;
      return {
        title: `${this.title} ($${total})`,
        labels: Object.keys(data),
        datasets: [
          {
            label: `Amount ($${amount})`,
            backgroundColor: "#7f7fff",
            data: Object.values(data).map((o) => o.amount),
            stack: "Stack 0",
          },
          {
            label: `Fee ($${fee})`,
            backgroundColor: "#f87979",
            data: Object.values(data).map((o) => o.fee),
            stack: "Stack 0",
          },
        ],
        isCurrency: true,
      };
    },
    groupByMonth() {
      return _.chain(this.items)
        .sortBy((o) => -o.created)
        .groupBy((o) => moment(o.created).format("MMM"))
        .map((value, key) => {
          return {
            month: key,
            amount: value.reduce((a, o) => a + o.amount, 0),
            total: value.reduce((a, o) => a + o.total, 0),
          };
        })
        .value();
    },
  },
  methods: {
    showOrder(item) {
      if (item.order) {
        EventBus.$emit("show-order-detail", item.order);
      } else if (item.invoice) {
        EventBus.$emit("show-invoice-detail", item.invoice);
      }
    },
  },
};
</script>

<template lang="pug">
div
  YearSelector(@select="load")
  List.my-3(:items="items")
  Form
</template>

<script>
import List from "/libs/components/CRM/ServiceLog/List";
import Form from "/libs/components/CRM/ServiceLog/Form";

export default {
  components: { List, Form },
  computed: {
    items() {
      return this.$store.getters["crm/service_logs"];
    },
  },
  methods: {
    async load(year) {
      const begin = new Date(year, 0).getTime();
      const end = new Date(year + 1, 0).getTime();
      const params = {
        criteria: { created: { $gte: begin, $lt: end } },
      };
      const result = await this.$api.crm.serviceLog.list(params);
      this.$store.dispatch("crm/setServiceLogs", result);
    },
  },
};
</script>
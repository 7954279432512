<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="bizservice")
    v-card-title {{ bizservice.name }} ({{ bizservice.amount | currency }})
    v-card-text
      span {{ bizservice.address | address }}
    v-divider
    v-list.py-0(dense)
      Payment(:item="bizservice")
      Status(title="POS", :item="bizservice", property="pos.status")
      Member(:item="bizservice")
      Status(
        title="Credit Card Machines",
        :item="bizservice",
        property="ccm.status"
      )
      Status(title="KDS", :item="bizservice", property="kds")
      Status(title="Drive Thru", :item="bizservice", property="dt")
      Status(title="Front Face Display", :item="bizservice", property="ff")
      v-divider
      Chowly(:item="bizservice")
    v-divider
    SevenShifts(:item="bizservice")
    v-divider
    CardPointe(:item="bizservice")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";
import Payment from "./Payment";
import Status from "./Status";
import Member from "./Member";
import Chowly from "./Chowly";
import SevenShifts from "./SevenShifts";
import CardPointe from "./CardPointe";

export default {
  components: { Payment, Status, Member, Chowly, SevenShifts, CardPointe },
  data() {
    return { dialog: false };
  },
  computed: { ...mapGetters(["bizservice"]) },
  mounted() {
    EventBus.$on("show-service", this.show);
  },
  destroyed() {
    EventBus.$off("show-service", this.show);
  },
  methods: {
    ...mapActions(["setBizService"]),
    show(data) {
      this.setBizService(data);
      this.dialog = true;
    },
  },
};
</script>
<template lang="pug">
v-btn(
  v-if="valid",
  color="error",
  @click="handler",
  rounded,
  depressed,
  x-small
) Cancel & Refund
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["order"],
  computed: {
    valid() {
      return this.order && this.order.status >= 0;
    },
  },
  methods: {
    async handler() {
      EventBus.$emit("cancel-order");
    },
  },
};
</script>


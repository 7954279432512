<template lang="pug">
v-dialog(v-model="dialog", width="700", scrollable)
  v-form(@submit.prevent="submit")
    v-card(v-if="sample")
      v-toolbar(dense, flat)
        .subtitle-2 {{ title }} Sample
      v-card-text
        v-text-field(label="Subject", v-model="sample.subject")
        quill-editor(
          v-model="sample.content",
          ref="myQuillEditor",
          :options="editorOption"
        )
      v-card-actions
        v-btn(block, color="secondary", type="submit", :loading="loading") Save
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
const { convert } = require("html-to-text");

const toolbarOptions = [
  ["bold", "italic", "underline"], // toggled buttons
  ["blockquote", "code-block"],
  ["link", "image"],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ align: [] }],
  ["clean"], // remove formatting button
];

export default {
  name: "EmailSampleForm",
  components: { quillEditor },
  data() {
    return {
      sample: null,
      dialog: false,
      loading: false,
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    title() {
      if (this.sample && this.sample._id) return "Edit an";
      else return "Create a New";
    },
  },
  mounted() {
    EventBus.$on("edit-email-sample", this.open);
  },
  destroyed() {
    EventBus.$off("edit-email-sample", this.open);
  },
  methods: {
    ...mapActions(["addEmailSample", "updateEmailSample"]),
    open(data) {
      if (!data) {
        this.sample = { subject: "", content: "" };
      } else {
        this.sample = JSON.parse(JSON.stringify(data));
      }
      this.dialog = true;
    },
    submit() {
      if (!this.sample) return;
      this.sample.content_short = convert(this.sample.content, {
        wordwrap: null,
      })
        ?.replace(/(?:\r\n|\r|\n)/g, " ") // remove newline
        ?.replace(/\s+/g, " ") // remove extra spacer
        ?.slice(0, 100);
      if (!this.sample._id) this.create();
      else this.update();
    },
    async update() {
      if (!this.sample._id) return;
      const params = {
        sample_id: this.sample._id,
        action: {
          $set: {
            subject: this.sample.subject,
            content_short: this.sample.content_short,
            content: this.sample.content,
          },
        },
      };
      this.loading = true;
      try {
        const result = await this.$api.corp.sampleEmail.update(params);
        this.updateEmailSample(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
    async create() {
      if (this.sample._id) return;
      this.loading = true;
      try {
        const result = await this.$api.corp.sampleEmail.create(this.sample);
        this.addEmailSample(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-col(align="center")
    YearSelector(@select="load")
  v-row(wrap, dense)
    v-col(cols="12", md="4")
      StandardChart(
        :input="amountData",
        chartType="bar",
        :showTotal="true",
        maxWidth="100%"
      )
    v-col(cols="12", md="4")
      StandardChart(
        :input="countData",
        chartType="bar",
        :showTotal="true",
        maxWidth="100%"
      )
    v-col(cols="12", md="4")
      StandardChart(:input="averageData", chartType="bar", maxWidth="100%")
</template>

<script>
export default {
  props: {
    url: { type: String, required: true },
  },
  data() {
    return {
      year: new Date().getFullYear(),
      result: [],
    };
  },
  computed: {
    items() {
      return this.result.toSorted((a, b) => a.month - b.month) || [];
    },
    labels() {
      return this.items.map((o) => this.$options.filters.month(o.month, true));
    },
    amountData() {
      return {
        title: "Amount",
        labels: this.labels,
        values: this.items.map((o) => o.amount),
        isCurrency: true,
      };
    },
    countData() {
      return {
        title: "Count",
        labels: this.labels,
        values: this.items.map((o) => o.count),
      };
    },
    averageData() {
      return {
        title: "Average",
        labels: this.labels,
        values: this.items.map((o) => {
          if (o.count) return Math.round(o.amount / o.count);
          else return 0;
        }),
        isCurrency: true,
      };
    },
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      const params = { year: this.year };
      const { data } = await this.axios.post(this.url, params);
      this.result = data;
    },
  },
};
</script>

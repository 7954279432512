const state = {
  products: [],
  groups: [],
  subgroups: [],
  stateTaxes: []
}

const getters = {
  products: state => state.products,
  groups: state => state.groups,
  subgroups: state => state.subgroups,
  stateTaxes: state => state.stateTaxes
}

const actions = {
  setProducts: ({ commit }, products) => { commit('setProducts', products) },
  addProduct: ({ commit }, data) => { commit('addProduct', data) },
  updateProduct: ({ commit }, data) => { commit('updateProduct', data) },
  removeProduct: ({ commit }, data) => { commit('removeProduct', data) },
  setGroups: ({ commit }, groups) => { commit('setGroups', groups) },
  addGroup: ({ commit }, data) => { commit('addGroup', data) },
  updateGroup: ({ commit }, data) => { commit('updateGroup', data) },
  removeGroup: ({ commit }, data) => { commit('removeGroup', data) },
  setSubGroups: ({ commit }, subgroups) => { commit('setSubGroups', subgroups) },
  addSubGroup: ({ commit }, data) => { commit('addSubGroup', data) },
  updateSubGroup: ({ commit }, data) => { commit('updateSubGroup', data) },
  removeSubGroup: ({ commit }, data) => { commit('removeSubGroup', data) },
  setStateTaxes: ({ commit }, stateTaxes) => { commit('setStateTaxes', stateTaxes) },
  addStateTax: ({ commit }, data) => { commit('addStateTax', data) },
  updateStateTax: ({ commit }, data) => { commit('updateStateTax', data) },
  removeStateTax: ({ commit }, data) => { commit('removeStateTax', data) }
}

const mutations = {
  setProducts(state, products) { state.products = products },
  addProduct(state, data) { state.products = state.products.concat([data]) },
  updateProduct(state, data) {
    state.products = state.products.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeProduct(state, data) {
    state.products = state.products.filter(o => o._id !== data._id)
  },
  setGroups(state, groups) { state.groups = groups },
  addGroup(state, data) { state.groups = state.groups.concat([data]) },
  updateGroup(state, data) {
    state.groups = state.groups.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeGroup(state, data) {
    state.groups = state.groups.filter(o => o._id !== data._id)
  },
  setSubGroups(state, subgroups) { state.subgroups = subgroups },
  addSubGroup(state, data) { state.subgroups = state.subgroups.concat([data]) },
  updateSubGroup(state, data) {
    state.subgroups = state.subgroups.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeSubGroup(state, data) {
    state.subgroups = state.subgroups.filter(o => o._id !== data._id)
  },
  setStateTaxes(state, stateTaxes) { state.stateTaxes = stateTaxes },
  addStateTax(state, data) { state.stateTaxes = state.stateTaxes.concat([data]) },
  updateStateTax(state, data) {
    state.stateTaxes = state.stateTaxes.map(o => {
      if (o._id === data._id) return data
      return o
    })
  },
  removeStateTax(state, data) {
    state.stateTaxes = state.stateTaxes.filter(o => o._id !== data._id)
  }
}

export default { namespaced: true, state, getters, actions, mutations }
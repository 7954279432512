<template lang="pug">
div(v-if="valid")
  v-switch(v-model="confirmed") Confirm
  v-btn(@click="submit", outlined, :loading="loading", :disabled="!confirmed") Delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      confirmed: false,
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.item?._id;
    },
  },
  methods: {
    ...mapActions(["removeBizServiceItem"]),
    async submit() {
      if (!this.confirmed) return this.$toast.error("Please confirm");
      this.loading = true;
      try {
        await this.$api.service.item.delete(this.item._id);
        this.$toast.success("Service item deleted");
        this.$emit("deleted");
        this.removeBizServiceItem(this.item);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
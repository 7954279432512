<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Edit Influencer Level
    v-card-text
      v-select(v-model="level", label="Level", :items="level_items")
    v-card-actions
      v-btn(block, color="secondary", @click="setLevel()", :loading="loading") Save
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      item: null,
      dialog: false,
      level: 0,
      level_items: [
        { text: "Green", value: 0 },
        { text: "Gold", value: 1 },
        { text: "Platinum", value: 2 },
      ],
      loading: false,
    };
  },
  mounted() {
    EventBus.$on("edit-influencer-level", this.open);
  },
  destroyed() {
    EventBus.$off("edit-influencer-level", this.open);
  },
  methods: {
    ...mapActions(["updateInfluencer"]),
    open(data) {
      this.item = data;
      this.level = this.item.level;
      this.dialog = true;
    },
    async setLevel() {
      this.loading = true;
      const params = { influencerId: this.item._id, level: this.level };
      const result = await this.$api.influencer.custom("setLevel", params);
      this.updateInfluencer(result);
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
Page(title="Delivery Growth", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Dashboard", url: "/delivery/growth/dashboard" },
        { title: "Generate", url: "/delivery/growth/generate" },
        { title: "Hourly", url: "/delivery/growth/hourly" },
      ],
    };
  },
};
</script>

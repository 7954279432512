<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    v-btn(outlined, small, color="secondary", v-on="on", @click="open()")
      span Edit
      v-icon(small, right) mdi-pencil
  v-card
    v-toolbar(dense, flat)
      .subtitle-2 Edit Weekly Hours
    v-divider
    v-card-text
      v-form(@submit.prevent="submit")
        v-row(v-for="(item, windex) in weekdays", :key="windex")
          v-col(cols="3")
            v-checkbox(
              v-model="item.status",
              :label="weekday(item.date)",
              hide-details,
              color="secondary"
            )
          v-col(v-if="item.status")
            v-icon.mr-2(
              small,
              color="secondary",
              v-if="!item.schedule || !item.schedule.length",
              @click="addRange(windex)"
            ) mdi-plus
            v-row(
              v-for="(s, sindex) in item.schedule",
              :key="sindex",
              align="end"
            )
              v-col(cols="4")
                v-select(
                  v-model="item.schedule[sindex].range[0]",
                  :items="items",
                  menu-props="auto",
                  dense,
                  hide-details,
                  single-line
                )
              v-col(cols="4")
                v-select(
                  v-model="item.schedule[sindex].range[1]",
                  :items="items",
                  menu-props="auto",
                  dense,
                  hide-details,
                  single-line
                )
              v-col(cols="4")
                v-icon.mr-2(
                  small,
                  color="red",
                  v-if="item.schedule.length > 1",
                  @click="removeRange(windex, sindex)"
                ) mdi-close
                v-icon.mr-2(
                  small,
                  color="secondary",
                  v-if="sindex === item.schedule.length - 1",
                  @click="addRange(windex)"
                ) mdi-plus
                v-icon(
                  small,
                  color="secondary",
                  v-if="sindex === item.schedule.length - 1 && windex > 0",
                  @click="copyRange(windex)",
                  title="copy previous day"
                ) mdi-content-copy
        v-btn.mt-3(block, color="secondary", type="submit") Save
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapActions } from "vuex";
import _ from "underscore";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      geoService: null,
      weekdays: [],
    };
  },
  computed: {
    items() {
      const minutesToTime = this.$options.filters.minutesToTime;
      return _.map(_.range(0, 1470, 30), (value) => {
        return { value, text: minutesToTime(value) };
      });
    },
  },
  mounted() {
    EventBus.$on("edit-geo-service-zone-hours", this.open);
  },
  destroyed() {
    EventBus.$off("edit-geo-service-zone-hours", this.open);
  },
  methods: {
    ...mapActions(["updateGeoServiceZone"]),
    weekday(date) {
      return this.$options.filters.day(date, true);
    },
    open(data) {
      if (!data) return;
      this.geoService = data;
      if (data.schedule?.weekdays?.length) {
        this.weekdays = JSON.parse(JSON.stringify(data.schedule.weekdays));
      } else {
        this.weekdays = _.map([1, 2, 3, 4, 5, 6, 0], (date) => {
          return {
            date,
            status: true,
            schedule: [{ range: [510, 1320] }],
          };
        });
      }
      this.dialog = true;
    },
    addRange(windex) {
      if (windex < this.weekdays.length) {
        if (this.weekdays[windex].schedule) {
          this.weekdays[windex].schedule.push({ range: [480, 1200] });
        } else {
          this.weekdays[windex].schedule = [{ range: [480, 1200] }];
        }
      }
    },
    copyRange(windex) {
      if (windex < this.weekdays.length && windex > 0) {
        this.weekdays[windex].schedule = this.weekdays[windex - 1].schedule;
      }
    },
    removeRange(windex, sindex) {
      if (windex < this.weekdays.length) {
        if (sindex < this.weekdays[windex].schedule.length) {
          this.weekdays[windex].schedule.splice(sindex, 1);
        }
      }
    },
    async submit() {
      if (!this.geoService || !this.geoService._id) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.geoService._id },
        action: { $set: { "schedule.weekdays": this.weekdays } },
      };
      const url = "/geoService/zones/update";
      const { data } = await this.axios.post(url, params);
      this.updateGeoServiceZone(data);
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>
import Users from './Users/index.vue'
import UsersDashboard from './Users/Dashboard/index.vue'
import UsersDaily from './Users/Daily/index.vue'
import UsersSearch from './Users/Search/index.vue'
import UsersDomain from './Users/Domain.vue'
import UsersUnverified from './Users/Unverified/index'
import BlacklistUser from './Users/Blacklist/index.vue'
import BouncingEmail from './Users/BouncingEmail/index.vue'

import Member from './Member/index.vue'
import MemberSearch from './Member/Search/index.vue'
import MemberMarketing from './Member/Marketing/index.vue'
import MemberDashboard from './Member/Dashboard/index.vue'
import MemberActions from './Member/Actions/index.vue'
import MemberMonthlyReport from './Member/MonthlyReport/index.vue'
import MemberPrograms from './Member/Programs/index.vue'

import Upload from './Member/Actions/Upload/index.vue'
import Subscription from './Subscription/index.vue'
import Review from './Review/index.vue'
import ReviewDashBoard from './Review/DashBoard/index.vue'
import ReviewRecent from './Review/Recent.vue'
import ReviewSearch from './Review/Search.vue'

export default [
  {
    path: '/users', component: Users, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'search' },
      { path: 'dashboard', component: UsersDashboard },
      { path: 'daily', component: UsersDaily },
      { path: 'search', component: UsersSearch },
      { path: 'unverified', component: UsersUnverified },
      { path: 'domain', component: UsersDomain },
      { path: 'blacklist', component: BlacklistUser },
      { path: 'bouncingemail', component: BouncingEmail }
    ]
  },
  {
    path: '/member', component: Member, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'marketing' },
      { path: 'marketing', component: MemberMarketing },
      { path: 'search', component: MemberSearch },
      { path: 'dashboard', component: MemberDashboard },
      { path: 'actions', component: MemberActions },
      { path: 'monthly', component: MemberMonthlyReport },
      { path: 'programs', component: MemberPrograms }
    ]
  },
  { path: '/member/upload', component: Upload, meta: { auth: 'corp' } },
  { path: '/subs', component: Subscription, meta: { auth: 'corp' } },
  {
    path: '/reviews', component: Review, meta: { auth: 'corp' },
    children: [
      { path: 'dashboard', component: ReviewDashBoard },
      { path: 'recent', component: ReviewRecent },
      { path: 'search', component: ReviewSearch }
    ]
  }
]
<template lang="pug">
Page(title="Grouping", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Chain", url: "/biz/grouping/chain" },
        { title: "Point of Interest", url: "/biz/grouping/poi" },
        { title: "City", url: "/biz/grouping/city" },
      ],
    };
  },
};
</script>

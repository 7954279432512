<template lang="pug">
div
  v-form(@submit.prevent="handleSearch")
    .d-flex.flex-row
      v-select(dense, solo, v-model="searchType", :items="searchTypeItems")
      v-text-field.mx-3(
        v-model="search",
        autofocus,
        label="Search",
        solo,
        dense
      )
      v-btn(icon, type="submit", color="secondary")
        v-icon mdi-magnify
  List.my-3
  EditEmail
  AwardPoints
  UnsubscribeMany
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import List from "./List";
import EditEmail from "./EditEmail";
import AwardPoints from "./AwardPoints";
import UnsubscribeMany from "./UnsubscribeMany";
import _ from "underscore";

export default {
  components: { List, EditEmail, AwardPoints, UnsubscribeMany },
  data() {
    return {
      search: "",
      searchType: "email",
      searchTypeItems: ["email", "phone", "ID"],
    };
  },
  computed: {
    ...mapGetters(["members"]),
  },
  methods: {
    ...mapActions(["setMembers", "setMemberPrograms"]),
    handleSearch() {
      const text = this.search.trim();
      if (!text || text.length < 3) return;
      switch (this.searchType) {
        case "email":
          this.searchEmail(text);
          break;
        case "ID":
          this.searchID(text);
          break;
        case "phone":
          this.searchPhone(text);
          break;
        default:
          this.searchEmail(text);
      }
    },
    searchEmail(text) {
      const params = { criteria: { email: { $regex: text } } };
      this.submit(params);
    },
    searchID(text) {
      const params = { criteria: { _id: text } };
      this.submit(params);
    },
    searchPhone(text) {
      const params = { criteria: { phone: { $regex: text } } };
      this.submit(params);
    },
    async submit(params) {
      const result = await this.$api.member.list(params);
      this.setMembers(result);
      this.search = "";
      this.loadPrograms();
    },
    async loadPrograms() {
      if (!this.members) return;
      let ids = _.chain(this.members).pluck("program").uniq().value();
      if (!ids || !ids.length) return;
      const params = { criteria: { _id: { $in: ids } } };
      const result = await this.$api.memberProgram.list(params);
      this.setMemberPrograms(result);
    },
  },
};
</script>

<template lang="pug">
.d-flex.flex-row
  Invoice.mr-3(:year="year")
  BizOrder.mr-3
  EventOrder.mr-3(:year="year")
</template>

<script>
import Invoice from "./Invoice";
import BizOrder from "./BizOrder";
import EventOrder from "./EventOrder";

export default {
  components: { Invoice, BizOrder, EventOrder },
  props: ["year"],
};
</script>
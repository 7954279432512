<template lang="pug">
.d-flex.flex-row.align-center
  .my-text.mr-2(v-if="!icon") {{ text }}
  v-btn(
    small,
    @click.stop="copy",
    icon,
    color="secondary",
    title="Copy to clipboard"
  )
    v-icon(x-small) mdi-content-copy
</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    icon: { type: Boolean, default: false }, // if true, show icon only
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.text);
      this.$toast.success("Copied to clipboard");
    },
  },
};
</script>

<style lang="sass" scoped>
.my-text
  font-size: 13px
  font-weight: 500
  color: #6c757d
  max-width: 180px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
</style>
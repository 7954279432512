<template lang="pug">
List
</template>

<script>
import { mapActions } from "vuex";
import List from "./List";

export default {
  components: { List },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setBizServices"]),
    async load() {
      let params = { criteria: { "apps.name": "7shifts" } };
      const result = await this.$api.service.account.list(params);
      this.setBizServices(result);
    },
  },
};
</script>

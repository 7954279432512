<template>
  <MyLine
    :data="chartData"
    :options="options"
    :style="myStyles"
  />
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line as MyLine } from "vue-chartjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "LineChart",
  components: { MyLine },
  props: {
    chartData: { type: Object, required: true },
    options: { type: Object, default: () => ({}) },
    height: { type: Number, default: 300 },
  },
  computed: {
    myStyles() {
      return {
        width: "100%",
        height: this.height + "px",
      };
    },
  },
};
</script>

<template lang="pug">
div
  v-col(align="center")
    YearSelector(@select="load")
    .sl-medal.elevation-2
      h2 Total Value
      h1 {{ total | currency }}
  v-row(wrap, dense)
    v-col(cols="12", md="4")
      StandardChart(chartType="bar", :input="dishData", maxWidth="100%")
    v-col(cols="12", md="4")
      StandardChart(chartType="bar", :input="countData", maxWidth="100%")
    v-col(cols="12", md="4")
      StandardChart(chartType="bar", :input="amountData", maxWidth="100%")
</template>

<script>
import _ from "underscore";

export default {
  data() {
    return {
      year: 2019,
      result: [],
      dishData: null,
      countData: null,
      amountData: null,
    };
  },
  computed: {
    total() {
      return this.result.reduce((a, b) => a + b.amount, 0);
    },
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      const params = { year: this.year };
      const res = await this.axios.post("/promoclaim/monthly-growth", params);
      this.result = _.chain(res.data).sortBy("month").sortBy("year").value();
      this.setData();
    },
    setData() {
      const labels = this.result.map((o) =>
        this.$options.filters.month(o.month, true)
      );
      this.amountData = {
        title: "Amount",
        labels,
        values: this.result.map((o) => o.amount),
        isCurrency: true,
      };
      this.countData = {
        title: "Count",
        labels,
        values: this.result.map((o) => o.count),
      };
      this.dishData = {
        title: "Average",
        labels,
        values: this.result.map((o) => o.dish),
        isCurrency: true,
      };
    },
  },
};
</script>

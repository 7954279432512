<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Waitlist Usage
      v-spacer
      YearSelector(@select="setYear")
    v-divider.mb-3
    StandardChart.mt-3(:input="countData", chartType="bar")
    v-divider.mb-3
    StandardChart.mt-3(:input="amountData", chartType="bar")
</template>

<script>
import _ from "underscore";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      dialog: false,
      bizId: null,
      // set to current year
      year: new Date().getFullYear(),
      result: [],
      amountData: null,
      countData: null,
    };
  },
  mounted() {
    EventBus.$on("show-waitlist-history", this.load);
  },
  destroyed() {
    EventBus.$off("show-waitlist-history", this.load);
  },
  methods: {
    setYear(year) {
      if (year) this.year = year;
      this.load();
    },
    async load(bizId) {
      if (bizId) this.bizId = bizId;
      if (!this.bizId) return;
      if (!this.year) return;
      const params = { bizId: this.bizId, year: this.year };
      try {
        const data = await this.$api.waitlist.custom("monthly", params);
        this.result = _.chain(data).sortBy("month").sortBy("year").value();
        this.setData();
        this.dialog = true;
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
    },
    setData() {
      const filters = this.$options.filters;
      const labels = this.result.map((o) => {
        return filters.month(o.month, true);
      });
      const amounts = _.map(this.result, (o) => o.cost);
      const counts = _.map(this.result, (o) => o.count);
      this.amountData = {
        title: "Cost",
        labels,
        values: amounts,
        isCurrency: true,
      };
      this.countData = {
        title: "Usage",
        labels,
        values: counts,
        isCurrency: false,
      };
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="500")
  v-card(v-if="issue")
    v-toolbar(flat, dense)
      .subtitle-2 {{ title }}
      v-spacer
      v-btn(
        icon,
        small,
        @click="show_form = !show_form",
        v-if="isInternal || isMine",
        color="secondary"
      )
        v-icon(small) mdi-pencil
    v-form(@submit.prevent="submit", v-if="show_form")
      v-card-text
        v-text-field(v-model="issue.title", label="Title", required)
        v-textarea(v-model="issue.description", label="Description")
        v-select(
          v-model="issue.tags",
          :items="tag_items",
          label="Tags",
          multiple
        )
        .list-box(v-if="issue.biz")
          .subtitle-2 Biz: {{ issue.biz_name }}
          v-btn(icon, small, @click="clearBiz", color="error")
            v-icon(small) mdi-close
        BizSelector(
          @select="selectBiz",
          v-else,
          label="Assign a Biz",
          :auto-select="false"
        )
        div Please describe the issue with details that includes business name and the app name.
      v-card-actions
        v-btn(color="secondary", :loading="loading", type="submit") Save
    v-card-text(v-else)
      Tags.mb-2(:tags="issue.tags")
      Author(:item="issue")
      v-divider.my-2
      div {{ issue.description }}
      Comments(:issue="issue", @update="update", ref="comments")
</template>

<script>
import { EventBus } from "@/event-bus";
import { mapGetters } from "vuex";
import Author from "./../Author";
import Tags from "./../Tags";
import Comments from "./Comments";

export default {
  components: { Comments, Author, Tags },
  data() {
    return {
      dialog: false,
      loading: false,
      issue: {},
      tag_items: [
        "pos app",
        "kds app",
        "business website",
        "customer app",
        "customer website",
        "future feature",
      ],
      show_form: false,
      app_name: process.env.VUE_APP_NAME,
    };
  },
  computed: {
    ...mapGetters(["salesOrganization", "salesPerson"]),
    isInternal() {
      return this.app_name === "Selflane Internal";
    },
    isNew() {
      return !this.issue?._id;
    },
    isMine() {
      return this.issue?.user === this.$auth.user()?._id;
    },
    title() {
      if (this.show_form) return this.isNew ? "New Issue" : "Edit Issue";
      return this.issue?.title;
    },
    commentable() {
      return this.issue?.status == "open";
    },
    user_name() {
      if (this.isInternal) {
        return `${this.$auth.user()?.name.first}`;
      } else if (this.salesPerson) {
        return `${this.salesPerson.first_name} ${this.salesPerson.last_name}`;
      }
      return "";
    },
  },
  mounted() {
    EventBus.$on("edit-issue", this.open);
  },
  destroyed() {
    EventBus.$off("edit-issue", this.open);
  },
  methods: {
    open(data) {
      if (data) {
        this.issue = JSON.parse(JSON.stringify(data));
      } else {
        this.issue = { title: "", description: "", tags: [], biz: null };
      }
      if (this.isNew) {
        this.show_form = true;
      }
      this.$refs.comments?.clear();
      this.dialog = true;
    },
    selectBiz(biz) {
      this.issue.biz = biz._id;
      this.issue.biz_name = biz.name;
    },
    clearBiz() {
      this.issue.biz = null;
      this.issue.biz_name = null;
    },
    async submit() {
      const user = this.issue.user || this.$auth.user()?._id;
      let user_name = this.issue.user_name || this.user_name;
      const organization =
        this.issue.organization || this.salesOrganization?._id;
      this.loading = true;
      const action = {
        title: this.issue.title,
        description: this.issue.description,
        tags: this.issue.tags,
        biz: this.issue.biz,
        biz_name: this.issue.biz_name,
        user,
        user_name,
        organization,
      };
      try {
        if (this.issue._id) {
          const result = await this.$api.dev.issue.put(this.issue._id, action);
          this.$store.dispatch("dev/updateIssue", result);
        } else {
          const result = await this.$api.dev.issue.create(action);
          this.$store.dispatch("dev/addIssue", result);
        }
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    update(data) {
      this.issue = JSON.parse(JSON.stringify(data));
    },
  },
};
</script>
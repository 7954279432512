<template lang="pug">
div(v-if="bizAccounts")
  v-dialog(v-model="dialog", width="300")
    template(v-slot:activator="{ on }")
      IconBtn(
        v-on="on",
        :disabled="!bizAccounts || bizAccounts.length < 2",
        title="Combine Menus"
      )
    v-card
      v-form(@submit.prevent="save")
        v-toolbar(flat, dense)
          .subtitle-2 Combine Menus
        v-card-text
          v-select(
            v-model="originIDs",
            :items="items",
            dense,
            label="Originals",
            multiple
          )
          v-select(v-model="targetID", :items="items", dense, label="Target")
          v-checkbox(v-model="confirmed", label="Confirm Combine Menus")
        v-card-actions
          v-btn(
            type="submit",
            color="secondary",
            block,
            :loading="loading",
            :disabled="!originIDs || !targetID || !confirmed"
          ) Save
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      confirmed: false,
      originIDs: [],
      targetID: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    items() {
      return this.bizAccounts
        .map((o) => {
          return { text: o.name, value: o._id };
        })
        .sort((a, b) => a.text.localeCompare(b.text));
    },
  },
  methods: {
    async save() {
      if (!this.originIDs || !this.targetID) return;
      this.loading = true;
      const params = { originIDs: this.originIDs, targetID: this.targetID };
      try {
        await this.$api.biz.custom("combineMenus", params);
        this.$toast.success("Combined Successfully");
        this.originIDs = [];
        this.targetID = null;
        this.confirmed = false;
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  .subtitle-2 Actions
  simple-table
    thead
      tr
        th Time
        th Title
        th Detail
        th Amount
    tbody
      tr(v-for="(item, index) in items", :key="index + '-row'")
        td {{ item.created | datetime("smart", timezone) }}
        td {{ item.title }}
        td {{ item.detail }}
        td
          span(v-if="item.amount") {{ item.amount | currency }}
</template>

<script>
export default {
  props: {
    order: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  computed: {
    timezone() {
      return this.order?.seller?.address?.timezone;
    },
  },
  methods: {
    async load() {
      const orderId = this.order?._id;
      if (!orderId) return;
      this.loading = true;
      const params = { criteria: { order: orderId } };
      try {
        this.items = await await this.$api.orderAction.action.list(params);
      } catch (e) {
        this.items = [];
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
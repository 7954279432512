const state = {
  invoices: []
}

const getters = {
  invoices: state => state.invoices
}

const actions = {
  setInvoices: ({ commit }, invoices) => { commit('setInvoices', invoices) },
  addInvoices: ({ commit }, invoices) => { commit('addInvoices', invoices) },
  addInvoice: ({ commit }, invoice) => { commit('addInvoice', invoice) },
  updateInvoice: ({ commit }, invoice) => { commit('updateInvoice', invoice) },
  removeInvoice: ({ commit }, invoice) => { commit('removeInvoice', invoice) }
}

const mutations = {
  setInvoices(state, invoices) { state.invoices = invoices },
  addInvoices(state, invoices) {
    // replace if already exists or add
    if (!invoices?.length) return
    for (const invoice of invoices) {
      const index = state.invoices.findIndex(o => o._id === invoice._id)
      if (index === -1) {
        state.invoices.push(invoice)
      } else {
        state.invoices[index] = invoice
      }
    }
  },
  addInvoice(state, invoice) { state.invoices = state.invoices.concat([invoice]) },
  updateInvoice(state, invoice) {
    state.invoices = state.invoices.map(o => {
      return o._id === invoice._id ? invoice : o
    })
  },
  removeInvoice(state, invoice) {
    state.invoices = state.invoices.filter(o => o._id !== invoice._id)
  }
}

export default { state, getters, actions, mutations }
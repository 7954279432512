<template lang="pug">
div(v-if="item")
  div {{ item.user_name }} ({{ organization }}) | {{ item.biz_name }}
  div {{ fromNow }} | last updated {{ item.updated | datetime("smart") }}
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  props: {
    item: { type: Object, default: null },
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    organization() {
      const id = this.item?.organization;
      if (!id) return "";
      return this.salesOrganizations.find((item) => item._id == id)?.name;
    },
    fromNow() {
      const created = this.item?.created;
      if (!created) return "";
      return moment(created).fromNow();
    },
  },
};
</script>
<template lang="pug">
CountTableChart(name="State", :items="items")
</template>

<script>
import _ from "underscore";

export default {
  props: {
    result: { type: Array, required: true },
  },
  computed: {
    items() {
      return _.chain(this.result)
        .groupBy("state")
        .map((list, k) => {
          const name = `${k} (${_.first(list)?.country})`;
          const count = list.reduce((a, b) => a + b.count, 0);
          return { name, count };
        })
        .sortBy("count")
        .reverse()
        .value();
    },
  },
};
</script>

<template lang="pug">
Page(title="Tax File")
  List
  Upload
  Assign
</template>

<script>
import List from "./List";
import Upload from "./Upload";
import Assign from "./Assign/index";

export default {
  components: { List, Upload, Assign },
};
</script>
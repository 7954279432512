<template lang="pug">
v-dialog(v-model="dialog", width="350")
  template(v-slot:activator="{ on }")
    v-list-item(@click="open()", v-on="on") Edit Address Note
  v-card(v-if="delivOrder")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Address Note
    v-card-text
      v-form(@submit.prevent="save")
        v-text-field(label="Note", v-model="note")
        v-btn(type="submit", color="secondary", block) Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["delivOrder"],
  data() {
    return {
      dialog: false,
      note: "",
    };
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    open() {
      this.note = this.delivOrder.customer.address.note;
      this.dialog = true;
    },
    async save() {
      const id = this.delivOrder?._id;
      if (!id) return;
      const action = { $set: { "customer.address.note": this.note } };
      const result = await this.$api.delivOrder.put(id, action);
      this.updateDelivOrder(result);
      this.dialog = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-btn.mt-3(
    v-if="!resolved",
    color="secondary",
    @click="open",
    :loading="loading",
    small,
    outlined
  ) Resolve
  v-dialog(v-model="dialog", width="500")
    v-card(v-if="item")
      v-toolbar(flat, dense)
        .subtitle-2 Resolve Chargeback
      v-card-text
        v-form(@submit.prevent="submit")
          v-select(
            label="Resolution",
            v-model="resolution",
            :items="resolution_items"
          )
          v-select(
            label="Type",
            v-model="resolution_type",
            :items="resolution_type_items"
          )
          v-textarea(label="Detail", v-model="resolution_detail")
          v-btn(type="submit", block, color="secondary", :loading="loading") Submit
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      resolution_items: [
        { text: "Favor Business", value: "favor_business" },
        { text: "Favor Customer", value: "favor_customer" },
      ],
      resolution: "favor_business",
      resolution_type_items: [
        { text: "fulfilled", value: "fulfilled" },
        { text: "unfulfilled", value: "unfulfilled" },
      ],
      resolution_type: "",
      resolution_detail: "",
      loading: false,
    };
  },
  computed: {
    resolved() {
      return this.item && this.item.resolution !== "pending";
    },
  },
  methods: {
    ...mapActions(["updateChargeback"]),
    open() {
      this.dialog = true;
      this.resolution = "favor_business";
      this.resolution_type = "";
      this.resolution_detail = "";
    },
    async submit() {
      if (!this.item) return;
      this.loading = true;
      const params = {
        id: this.item._id,
        resolution: this.resolution,
        resolution_type: this.resolution_type,
        resolution_detail: this.resolution_detail,
      };
      try {
        const url = "/orders/chargeback/resolve";
        const { data } = await this.axios.post(url, params);
        this.updateChargeback(data);
        this.dialog = false;
        this.$emit("updated");
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>
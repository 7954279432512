<!--
  Select a week by clicking on the previous/next buttons or by selecting a date in the calendar.
  The week is sent to the parent component as a begin and end timestamp.
-->
<template lang="pug">
.box-container
  v-btn(icon, small, @click="previous()", title="previous")
    v-icon mdi-menu-left
  v-menu(offset-y)
    template(v-slot:activator="{ on }")
      .btn-title(v-on="on") {{ begin | datetime("(ddd) YYYY MMM D") }} - {{ end | datetime("MMM D") }}
    v-date-picker(
      v-model="begin",
      :allowed-dates="allowedDates",
      :max="maxDate"
    )
  v-btn(icon, small, @click="next()", title="next")
    v-icon mdi-menu-right
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "WeekSelector",
  props: {
    day: { type: Number, default: 1 }, // 0 = Sunday, 1 = Monday, etc.
    timezone: { type: String, default: moment.tz.guess() },
    maxDate: { type: String, default: () => moment().format("YYYY-MM-DD") },
  },
  data() {
    return {
      begin: "2023-01-01",
    };
  },
  computed: {
    end() {
      return moment(this.begin).add(6, "days").format("YYYY-MM-DD");
    },
  },
  watch: {
    // send whenever begin is edited, either by clicking next/previous buttons or by selecting date
    begin() {
      this.send();
    },
    day() {
      this.reset();
    },
  },
  mounted() {
    this.reset();
    this.send();
  },
  methods: {
    allowedDates(val) {
      return moment(val).day() == this.day;
    },
    next() {
      this.begin = moment(this.begin).add(1, "weeks").format("YYYY-MM-DD");
    },
    previous() {
      this.begin = moment(this.begin).subtract(1, "weeks").format("YYYY-MM-DD");
    },
    send() {
      const timezone = this.timezone || moment.tz.guess();
      const beginUnix = moment
        .tz(this.begin, timezone)
        .startOf("day")
        .valueOf();
      const endUnix = moment.tz(this.end, timezone).endOf("day").valueOf();
      this.$emit("select", {
        begin: beginUnix,
        end: endUnix,
        range: [this.begin, this.end], // e.g. ['2020-12-27', '2021-01-02']
      });
    },
    reset() {
      const time = moment().day(this.day);
      if (time.isAfter(moment())) time.subtract(1, "weeks");
      this.begin = time.format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="sass" scoped>
.box-container
  display: inline-flex
  flex-direction: row
  align-items: center
  border-radius: 6px
  border: 1px solid #e4e4e4
  background-color: white
</style>

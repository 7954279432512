const state = {
  issues: [],
  issueWatchers: [],
}

const getters = {
  issues: state => state.issues,
  issueWatchers: state => state.issueWatchers,
}

const actions = {
  setIssues: ({ commit }, data) => { commit("setIssues", data); },
  updateIssue: ({ commit }, data) => { commit("updateIssue", data); },
  addIssue: ({ commit }, data) => { commit("addIssue", data); },
  removeIssue: ({ commit }, data) => { commit("removeIssue", data); },
  setIssueWatchers: ({ commit }, data) => { commit("setIssueWatchers", data); },
  updateIssueWatcher: ({ commit }, data) => { commit("updateIssueWatcher", data); },
  addIssueWatcher: ({ commit }, data) => { commit("addIssueWatcher", data); },
  addIssueWatchers: ({ commit }, data) => { commit("addIssueWatchers", data); },
  removeIssueWatcher: ({ commit }, data) => { commit("removeIssueWatcher", data); },
}

const mutations = {
  setIssues(state, data) {
    state.issues = data;
  },
  updateIssue(state, data) {
    state.issues = state.issues.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addIssue(state, data) {
    state.issues.push(data);
  },
  removeIssue(state, data) {
    state.issues = state.issues.filter(o => o._id !== data._id);
  },
  setIssueWatchers(state, data) {
    state.issueWatchers = data;
  },
  updateIssueWatcher(state, data) {
    state.issueWatchers = state.issueWatchers.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  addIssueWatcher(state, data) {
    state.issueWatchers.push(data);
  },
  // if existing watcher, update, else add
  addIssueWatchers(state, data) {
    if (!Array.isArray(data)) return
    data.forEach(o => {
      const existing = state.issueWatchers.find(w => w._id === o._id);
      if (existing) {
        state.issueWatchers = state.issueWatchers.map(w => {
          if (w._id === o._id) return o;
          return w;
        });
      } else {
        state.issueWatchers.push(o);
      }
    })
  },
  removeIssueWatcher(state, data) {
    state.issueWatchers = state.issueWatchers.filter(o => o._id !== data._id);
  },
};

export default { namespaced: true, state, getters, actions, mutations }
<template lang="pug">
.mx-3.my-1
  .subtitle-2 {{ interaction.subject }}
  .caption {{ interaction.sales_person_name }} {{ interaction.created | datetime("smart") }}
  .body-2 {{ interaction.content }}
  v-divider
</template>

<script>
export default {
  props: {
    interaction: { type: Object, default: () => ({}) },
  },
};
</script>
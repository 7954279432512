<template lang="pug">
v-dialog(
  v-model="dialog",
  fullscreen,
  hide-overlay,
  transition="dialog-bottom-transition"
)
  v-card
    v-toolbar(flat, dense)
      .subtitle-2 Opportunity Worksheet | {{ opportunity?.sales_person_name }}
      v-spacer
      IconBtn.mr-3(@click="edit(opportunity)")
      v-btn(color="secondary", @click="dialog = false", small) Close
    StaticInfo(:opportunity="opportunity")
    Interaction(
      :opportunity="opportunity",
      ref="interaction",
      @update="update"
    )
  Form(ref="form", @done="opportunity = $event")
</template>

<script>
import Form from "./../Form";
import StaticInfo from "./StaticInfo";
import Interaction from "/libs/components/CRM/Interaction/List";

export default {
  components: { StaticInfo, Interaction, Form },
  data() {
    return {
      dialog: false,
      opportunity: null,
    };
  },
  methods: {
    open(opportunity) {
      this.opportunity = opportunity;
      this.dialog = true;
      this.loadInteractions();
    },
    edit() {
      this.$refs.form.open(this.opportunity);
    },
    async loadInteractions() {
      if (!this.opportunity._id) return;
      try {
        const criteria = { opportunity: this.opportunity._id };
        const result = await this.$api.crm.interaction.list({ criteria });
        this.$store.dispatch("crm/setInteractions", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load interactions");
      }
    },
    update(opportunity) {
      this.opportunity = opportunity;
    },
  },
};
</script>
import salesRoute from './Sales/route.js'

import Catalog from './Catalog/index.vue'
import Groups from './Catalog/Groups/index.vue'
import SubGroups from './Catalog/SubGroups/index.vue'
import Products from './Catalog/Products/index.vue'
import StateTax from './Catalog/StateTax/index.vue'

import Inventory from './Inventory/index.vue'
import InventoryIn from './Inventory/In/index.vue'
import InventoryOut from './Inventory/Out/index.vue'

export default [
  ...salesRoute,
  {
    path: '/shop/catalog', component: Catalog, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'groups' },
      { path: 'groups', component: Groups },
      { path: 'subgroups', component: SubGroups },
      { path: 'products', component: Products },
      { path: 'statetax', component: StateTax }
    ]
  }, {
    path: '/shop/inventory', component: Inventory, meta: { auth: 'corp' },
    children: [
      { path: '', redirect: 'in' },
      { path: 'in', component: InventoryIn },
      { path: 'out', component: InventoryOut }
    ]
  }
]
<template lang="pug">
StandardChart(:input="chartData", :legend="true", chartType="bar")
</template>

<script>
export default {
  props: ["summary"],
  computed: {
    chartData() {
      if (!this.summary) return null;
      const perCustomer = {
        label: "Spending/Member",
        borderColor: "#f87979",
        borderWidth: 2,
        data: this.summary.map((o) => {
          if (o.people > 0)
            return Math.round((o.amount / o.people) * 100) / 100;
          else return 0;
        }),
      };
      const perVisit = {
        label: "Spending/Visit",
        borderColor: "#3f51b5",
        borderWidth: 2,
        data: this.summary.map((o) => {
          if (o.visit > 0) return Math.round((o.amount / o.visit) * 100) / 100;
          else return 0;
        }),
      };
      return {
        title: "Spending Power",
        labels: this.summary.map(
          (o) => `${parseInt(o.month / 100)}/${o.month % 100}`
        ),
        datasets: [perCustomer, perVisit],
        isCurrency: true,
      };
    },
  },
};
</script>
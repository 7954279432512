<template lang="pug">
div
  .d-flex.flex-row.justify-center
    DelivSelector(@select="select")
  v-btn.text-capitalize.my-2(@click="loadAll()") Load All
  List
  Detail
</template>

<script>
import DelivSelector from "@/components/basic/DelivSelector";
import List from "./List/index";
import Detail from "./Detail/index";
import { mapActions } from "vuex";

export default {
  components: { DelivSelector, List, Detail },
  methods: {
    ...mapActions(["setDrivers"]),
    select(deliv) {
      if (!deliv) return;
      this.loadByDeliv(deliv._id);
    },
    async loadByDeliv(delivId) {
      if (!delivId) return;
      const params = { criteria: { deliv: delivId } };
      const { data } = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(data);
    },
    async loadAll() {
      const params = { criteria: {} };
      const { data } = await this.axios.post("/driver/accounts/list", params);
      this.setDrivers(data);
    },
  },
};
</script>

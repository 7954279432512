<template lang="pug">
v-dialog(v-model="dialog", width="350")
  template(v-slot:activator="{ on }")
    v-list-item(@click="open()", v-on="on") Edit Tip
  v-card(v-if="delivOrder")
    v-toolbar(dense, flat)
      .subtitle-2 Edit Tip
    v-card-text
      span {{ delivOrder.bizName }}
      v-form(@submit.prevent="save")
        v-row(dense, wrap)
          v-col(cols="6")
            PriceField(label="Tip", v-model.number="tip")
          v-col(cols="6")
            PriceField(label="Fee", v-model.number="fee")
          v-col(cols="6")
            PriceField(label="Manage Fee", v-model.number="manfee")
          v-col(cols="6")
            v-select(
              label="Score",
              v-model="ontime_score",
              :items="ontime_score_items"
            )
        v-checkbox.mb-3(
          v-model="agree",
          color="secondary",
          label="This would change the amount paid to the driver. Make sure you understand the consequence."
        )
        v-btn(type="submit", color="secondary", block, :disabled="!agree") Yes, Edit Amounts
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["delivOrder"],
  data() {
    return {
      dialog: false,
      tip: 0,
      fee: 0,
      manfee: 0,
      ontime_score: 5,
      agree: false,
      ontime_score_items: [
        { text: "Great", value: 5 },
        { text: "Good", value: 4 },
        { text: "Late", value: 3 },
        { text: "Very Late", value: 2 },
        { text: "Super Late", value: 1 },
        { text: "Disaster", value: 0 },
      ],
    };
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    open() {
      this.tip = this.delivOrder.tip;
      this.fee = this.delivOrder.fee;
      this.manfee = this.delivOrder.manfee;
      this.ontime_score = this.delivOrder.ontime_score;
      this.agree = false;
    },
    async save() {
      const id = this.delivOrder?._id;
      if (!id) return;
      const action = {
        $set: {
          tip: this.tip,
          fee: this.fee,
          manfee: this.manfee,
          ontime_score: this.ontime_score,
        },
      };
      const result = await this.$api.delivOrder.put(id, action);
      this.updateDelivOrder(result);
      this.dialog = false;
    },
  },
};
</script>

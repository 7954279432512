<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ title }} ({{ sortedItems.length }})
  v-data-table(
    :search="search",
    :items="sortedItems",
    :headers="headers",
    @click:row="edit",
    role="button",
    sort-by="organization",
    mobile-breakpoint=0
  )
    template(v-slot:item.avatar="{ item }")
      v-avatar(v-if="item.avatar")
        img(:src="item.avatar")
    template(v-slot:item.phone="{ item }")
      span.nowrap {{ item.phone | phone }}
    template(v-slot:item.action="{ item }")
      v-btn(
        @click.stop="connectUser(item)",
        icon,
        :color="connection_color(item)",
        small
      )
        v-icon(small) mdi-account
</template>

<script>
import { EventBus } from "@/event-bus.js";
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SalesForcePersonListTable",
  props: {
    title: { type: String, default: "" },
    items: { type: Array, default: () => [] },
    search: { type: String, default: "" },
  },
  data() {
    return {
      app_name: process.env.VUE_APP_NAME || "Selflane",
    };
  },
  computed: {
    ...mapGetters(["salesOrganizations"]),
    sortedItems() {
      return _.chain(this.items)
        .map((o) => {
          if (o.organization) {
            const found = this.salesOrganizations.find(
              (s) => s._id == o.organization
            );
            o.organizationName = found?.name || "";
          }
          if (o.profile_picture) {
            o.avatar = process.env.VUE_APP_MEDIAHOST + o.profile_picture;
          }
          return o;
        })
        .sortBy("organizationName")
        .sortBy("first_name")
        .value();
    },
    isInternal() {
      return this.app_name == "Selflane Internal";
    },
    headers() {
      let items = [
        { text: "Picture", value: "avatar", filterable: false },
        { text: "First", value: "first_name" },
        { text: "Last", value: "last_name" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Organization", value: "organizationName" },
        { text: "City", value: "address.city" },
        { text: "State", value: "address.state" },
      ];
      if (this.isInternal) {
        items.push({
          text: "Action",
          value: "action",
          align: "end",
          filterable: false,
        });
      }
      return items;
    },
  },
  methods: {
    ...mapActions(["updateSalesPerson"]),
    edit(item) {
      EventBus.$emit("edit-sales-person", item);
    },
    connection_color(item) {
      if (item.user) return "success";
      return "secondary";
    },
    async connectUser(item) {
      if (item.user) {
        this.$toast.info("User already connected");
        return;
      }
      const params = { person_id: item._id };
      try {
        const result = await this.$api.salesforce.person.custom(
          "connectUser",
          params
        );
        this.updateSalesPerson(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>

<template lang="pug">
v-form.center-align(@submit.prevent="load()")
  v-text-field(
    v-model="order_id",
    label="Search ID",
    append-icon="search",
    @click:append="load()",
    solo,
    hide-details,
    dense,
    :loading="loading",
    flat
  )
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      order_id: null,
      loading: false,
    };
  },
  watch: {
    order_id() {
      if (!this.order_id) return;
      this.load();
    },
  },
  methods: {
    ...mapActions(["setOrders"]),
    async load() {
      if (!this.order_id || this.order_id.length != 24) return;
      this.loading = true;
      const params = { criteria: { _id: this.order_id } };
      try {
        const result = await this.$api.order.list(params);
        this.setOrders(result);
      } catch (err) {
        this.$toast.error(err.response?.data || err.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.center-align
  margin-left: auto
  margin-right: auto
  max-width: 400px
</style>
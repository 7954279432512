<template lang="pug">
div
  .body-2.mb-5 {{ teaser }}
  .link-box
    v-btn(:href="apple", target="_blank", text, color="secondary", small) Apple Podcasts
    v-btn(:href="spotify", target="_blank", text, color="secondary", small) Spotify
    v-btn(:href="youtube", target="_blank", text, color="secondary", small) YouTube
  v-divider.my-5
  .d-flex.flex-row.mb-5
    v-btn(@click="createSample", outlined, color="secondary", small) Create
    v-spacer
    YearSelector(@select="load")
  List(@edit="handleEdit")
  Form(ref="form")
</template>

<script>
import { mapActions } from "vuex";
import List from "./List/index";
import Form from "./Form";
import moment from "moment-timezone";

export default {
  components: { List, Form },
  data() {
    return {
      teaser:
        "On the Restaurant and Tech Podcast Hosts Kevin Zhou and Isaiah Simpson delve into the world of restaurant technology and the people behind it. With each episode they take a personal look at the people behind the scenes running restaurants and the technology that powers them. Through their stories we learn how their businesses came to be, and how their experiences and values drive their decisions in a competitive field.Every week they bring you new advancements in the industry and how they will impact the individual: owners, servers, resellers, and customers.Subscribe to join us on this journey to better understand our businesses and ourselves.",
      apple:
        "https://podcasts.apple.com/us/podcast/selflane-podcast/id1708990701",
      spotify:
        "https://open.spotify.com/show/3dqjVLC1VrTu6UhnzNAmfv?si=be46d46b93da4597",
      youtube:
        "https://youtube.com/playlist?list=PLQLGBiDiIbLKr9fR7kj_Qan-_-SNbYqsT&si=c05P0XI6aHGU79Nb",
    };
  },
  methods: {
    ...mapActions(["setPodcasts"]),
    async load(year) {
      const time = moment.tz(year, "YYYY", "America/Denver");
      const begin = time.startOf("year").valueOf();
      const end = time.endOf("year").valueOf();
      const criteria = { created: { $gte: begin, $lt: end } };
      const result = await this.$api.podcast.list({ criteria });
      this.setPodcasts(result);
    },
    createSample() {
      this.$refs.form.open();
    },
    handleEdit(item) {
      this.$refs.form.open(item);
    },
  },
};
</script>
<template lang="pug">
IconBtn(
  icon="mdi-sync",
  title="Push Menu",
  :loading="loading",
  @click.stop="submit",
  :x-small="xSmall",
  :small="small"
)
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: { type: Object, required: true },
    xSmall: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
  },
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["bizservice"]),
  },
  methods: {
    async submit() {
      const biz = this.item?.biz;
      if (!biz) return;
      this.loading = true;
      const params = { bizId: biz };
      try {
        const result = await this.$api.biz.custom("sync/third_party", params);
        this.$store.dispatch("updateBizService", result);
        this.$toast.success("Menu is synced to Chowly.");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

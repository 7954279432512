<template lang="pug">
div
  v-list.py-0(dense, two-line)
    div(v-for="(item, index) in cartOrder.items", :key="index")
      v-list-item
        v-list-item-content
          v-list-item-title {{ item.name }}
            span.ml-3.red--text(v-if="item.off > 0") {{ item.off }}% off {{ ((-item.off * item.price * item.quantity) / 100) | currency }}
          v-list-item-subtitle.text-truncate {{ item.modifiers | modifiers }}
        v-list-item-action
          .body-2 {{ item.price | currency }} &#215; {{ item.quantity }} | {{ item.cost | currency }}
          v-row
            v-btn.mr-1(icon, small, @click.stop="decrease(item, index)")
              v-icon(color="error", small) mdi-minus
            v-btn.mr-1(icon, small, @click.stop="increase(item, index)")
              v-icon(color="success", small) mdi-plus
            v-btn(icon, small, @click.stop="removeItemAt(index)")
              v-icon(small) mdi-delete
  simple-table
    tbody
      tr
        td Subtotal
        td.text-right {{ cartOrder.payment.subtotal | currency }}
      tr
        td Tax ({{ cartOrder.taxRate }}%)
        td.text-right {{ cartOrder.payment.tax | currency }}
      tr.subtitle-2
        td Total
        td.text-right {{ cartOrder.payment.total | currency }}
      tr.subtitle-2.green--text
        td Profit
        td.text-right {{ profit | currency }}
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";

export default {
  computed: {
    ...mapGetters(["cartOrder"]),
    profit() {
      if (!this.cartOrder || !this.cartOrder.items) return 0;
      const result = _.reduce(
        this.cartOrder.items,
        (memo, o) => {
          const value = (o.price * (100 - o.off)) / 100;
          const cost = o.cost || value;
          return memo + value - cost;
        },
        0
      );
      return result;
    },
  },
  methods: {
    ...mapActions(["removeItemAt", "editItemAt"]),
    increase(item, index) {
      if (item && item.quantity < 100) {
        item.quantity++;
        this.editItemAt({
          item: item,
          index: index,
        });
      }
    },
    decrease(item, index) {
      if (item && item.quantity > 1) {
        item.quantity--;
        this.editItemAt({
          item: item,
          index: index,
        });
      }
    },
  },
};
</script>

<template lang="pug">
div
  v-row(wrap, v-if="partners")
    v-col(
      cols="12",
      sm="6",
      md="3",
      v-for="(item, index) in items",
      :key="index",
      fill-height
    )
      v-card(flat)
        v-card-text
          .d-flex.flex-row.align-center.justify-space-between
            .sl-title {{ item.name }}
            div
              v-btn(icon, small, color="secondary", @click="edit(item)")
                v-icon(small) mdi-pencil
              v-btn(icon, small, color="secondary", @click="pay(item)")
                v-icon(small) mdi-cash
          div {{ item.phone | phone }}
          div {{ item.email }}
  EditPartner(ref="edit")
  PayPartner(ref="pay")
</template>

<script>
import { mapGetters } from "vuex";
import EditPartner from "./EditPartner";
import PayPartner from "./PayPartner";

export default {
  components: { EditPartner, PayPartner },
  computed: {
    ...mapGetters(["partners"]),
    items() {
      return this.partners?.toSorted((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    edit(item) {
      this.$refs.edit.open(item);
    },
    pay(item) {
      this.$refs.pay.open(item);
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-card-text
    .link-box
      v-text-field(
        v-model="search",
        label="Search",
        append-icon="mdi-magnify",
        single-line,
        clearable
      )
      v-btn-toggle(v-model="status", mandatory, small)
        v-btn(
          small,
          v-for="item in status_items",
          :key="item.value",
          :value="item.value"
        ) {{ item.text }} ({{ groups[item.value]?.length }})
  v-list(two-line, dense)
    template(v-for="(item, index) in paged_items")
      v-divider
      Cell(:item="item", :key="index", @click="select(item)")
  v-card-actions(v-if="total_pages > 1")
    v-pagination(
      v-model="current_page",
      :length="total_pages",
      color="secondary",
      circle
    )
</template>

<script>
import { EventBus } from "@/event-bus";
import Cell from "./Cell";

export default {
  components: { Cell },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      status: "open",
      status_items: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
      ],
      search: "",
      current_page: 1,
      page_size: 20,
    };
  },
  computed: {
    paged_items() {
      const start = (this.current_page - 1) * this.page_size;
      const end = start + this.page_size;
      return this.selected.slice(start, end);
    },
    selected() {
      let items = [];
      if (this.status == "open") items = this.groups.open;
      else if (this.status == "closed") items = this.groups.closed;
      if (!this.search) return items;
      return items.filter((o) => {
        const search = this.search.trim().toLowerCase();
        return (
          o.title.toLowerCase().includes(search) ||
          o.description.toLowerCase().includes(search)
        );
      });
    },
    groups() {
      let open = [],
        closed = [];
      this.items?.forEach((o) => {
        if (o.status == "closed") closed.push(o);
        else open.push(o);
      });
      open.sort((a, b) => b.created - a.created);
      closed.sort((a, b) => b.created - a.created);
      return { open, closed };
    },
    total_pages() {
      return Math.ceil(this.selected.length / this.page_size);
    },
  },
  methods: {
    select(item) {
      EventBus.$emit("edit-issue", item);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="300")
  template(v-slot:activator="{ on }")
    IconBtn(@click="open", v-on="on")
  v-form(@submit.prevent="submit")
    v-card
      v-toolbar(flat, dense)
        .subtitle-2 Email Contacts
      v-card-text
        v-text-field(label="Main Email Contact", v-model="email")
        v-text-field(
          label="CC Recipients",
          v-model="carboncopy",
          hint="Extra recipients for online orders. Separate with comma.",
          placeholder="a@gmail.com, b@gmail.com"
        )
      v-card-actions
        v-btn(block, type="submit", color="secondary", :loading="loading") save
</template>

<script>
import _ from "underscore";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      email: "",
      carboncopy: "",
      loading: false,
    };
  },
  computed: { ...mapGetters(["biz"]) },
  methods: {
    open() {
      this.email = this.biz.email;
      this.carboncopy = this.biz.carboncopy;
    },
    cleanCarbonCopy() {
      if (!this.carboncopy) return;
      this.carboncopy = this.carboncopy.trim();
      let items = this.carboncopy.split(",");
      items = _.chain(items)
        .map((o) => o.toLowerCase().trim())
        .filter((o) => this.validateEmail(o))
        .value();
      this.carboncopy = items.join(",");
    },
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const result = re.test(String(email).toLowerCase());
      return result;
    },
    async submit() {
      if (!this.biz) return;
      this.cleanCarbonCopy();
      const email = this.email.toLowerCase().trim();
      if (!this.validateEmail(email)) {
        return this.$toast.error("Invalid email address");
      }
      this.loading = true;
      try {
        const action = { $set: { email: email, carboncopy: this.carboncopy } };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Saved");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
Page(title="Online Orders", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Search", url: "/orders/online/search" },
        { title: "Last 4 Weeks", url: "/orders/online/last4week" },
        { title: "Charts", url: "/orders/online/charts" },
      ],
    };
  },
};
</script>

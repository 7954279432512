<template lang="pug">
Page(title="Merchant", :items="items")
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Accounts", url: "/merchant/accounts" },
        { title: "Incomes", url: "/merchant/incomes" },
      ],
    };
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Chain Settings
    v-spacer
    v-btn(icon, small, color="secondary", @click="edit")
      v-icon(small) mdi-pencil
  simple-table
    tbody
      tr
        th Name
        td {{ chain.name }}
      tr
        th Description
        td {{ chain.shortd }}
      tr
        th Link
        td
          a(:href="link", target="_blank") {{ chain.url }}
  v-card-text
    .s-vstack
      Logo(:chain="chain", style="max-width: 500px")
      Parallax(:chain="chain", style="max-width: 500px")
  Form(ref="form")
</template>

<script>
import Form from "/libs/components/Enterprise/Chain/Form";
import Logo from "/libs/components/Enterprise/Chain/Logo";
import Parallax from "/libs/components/Enterprise/Chain/Parallax";

export default {
  components: { Logo, Parallax, Form },
  props: {
    chain: { type: Object, required: true },
  },
  computed: {
    link() {
      const url = this.chain?.url;
      if (!url) return null;
      return `https://selflane.com/chains/${url}`;
    },
  },
  methods: {
    edit() {
      this.$refs.form.open(this.chain);
    },
  },
};
</script>

<template lang="pug">
v-card(flat)
  v-card-text
    .link-box
      v-text-field(
        v-model="search",
        label="Search",
        append-icon="mdi-magnify",
        single-line,
        clearable
      )
  v-list(two-line, dense)
    template(v-for="(item, index) in paged_items")
      v-divider
      Cell(:item="item", :key="index", @click="select(item)")
  v-card-actions(v-if="total_pages > 1")
    v-pagination(
      v-model="current_page",
      :length="total_pages",
      color="secondary",
      circle
    )
</template>

<script>
import { EventBus } from "@/event-bus";
import Cell from "./Cell";

export default {
  components: { Cell },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: "",
      current_page: 1,
      page_size: 20,
    };
  },
  computed: {
    paged_items() {
      const start = (this.current_page - 1) * this.page_size;
      const end = start + this.page_size;
      return this.selected.slice(start, end);
    },
    selected() {
      let items = this.items.toSorted((a, b) => {
        return b.occured_at - a.occured_at;
      });
      if (!this.search) return items;
      return items.filter((o) => {
        const search = this.search.trim().toLowerCase();
        return (
          o.title.toLowerCase().includes(search) ||
          o.description.toLowerCase().includes(search)
        );
      });
    },
    total_pages() {
      return Math.ceil(this.selected.length / this.page_size);
    },
  },
  methods: {
    select(item) {
      EventBus.$emit("edit-service-log", item);
    },
  },
};
</script>
<template lang="pug">
v-card
  v-toolbar(dense, flat)
    .subtitle-2 {{ item.name }}
    v-spacer
    v-menu(offset-y)
      template(v-slot:activator="{ on }")
        v-btn(icon, small, v-on.stop="on")
          v-icon(color="secondary") mdi-dots-horizontal
      v-list.py-0(dense)
        v-list-item(@click="show()") Detail
        v-list-item(@click="populate()") Populate Biz Info
        v-list-item(@click="handleEdit()") Edit
  v-card-text
    v-img.mx-auto(
      :src="mediaHost + item.logo",
      max-width="180",
      height="180",
      contain
    )
    .subtitle-2.green--text
      span(v-if="item.type == 'pct'") {{ -item.pct }}%
      span(v-else) {{ -item.fix | currency }}
    div {{ item.created | date }} - {{ item.expiry | date }}
    div {{ item.claimed }} of {{ item.total }} claimed
</template>

<script>
import { mapActions } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  name: "CouponListCell",
  props: ["item"],
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
    };
  },
  methods: {
    ...mapActions(["updatePromotion"]),
    async populate() {
      if (!this.item) return;
      const params = { promoId: this.item._id };
      const result = await this.$api.promotion.custom("populate", params);
      this.updatePromotion(result);
    },
    show() {
      if (!this.item) return;
      EventBus.$emit("show-promotion", this.item._id);
    },
    handleEdit() {
      EventBus.$emit("update-promotion", this.item);
    },
  },
};
</script>

<template lang="pug">
v-btn(small, @click.stop="toggle", :color="color")
  v-icon(small, left) {{ icon }}
  span {{ title }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["biz"]),
    icon() {
      return this.biz.no_online ? "mdi-eye-off" : "mdi-eye";
    },
    title() {
      return this.biz.no_online
        ? "Not Suitable for Online"
        : "Suitable for Online";
    },
    color() {
      return this.biz.no_online ? "error" : "success";
    },
  },
  methods: {
    async toggle() {
      const criteria = { _id: this.biz._id };
      const action = { $set: { no_online: !this.biz.no_online } };
      try {
        const result = await this.$api.biz.update({ criteria, action });
        this.$store.dispatch("setBiz", result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
    },
  },
};
</script>
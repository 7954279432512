<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 Missed Transactions
    v-spacer
    v-btn-toggle(v-model="filtered", color="secondary")
      v-btn(
        v-for="item in status_items",
        :key="item.value",
        :value="item.value",
        x-small
      ) {{ item.text }}
    v-btn.mx-3(icon, small, color="secondary", @click="load")
      v-icon(small) mdi-sync
    ClearOldData
  Table(:items="items", @load-txn="load_txn")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment-timezone";
import ClearOldData from "./ClearOldData";
import Table from "./Table";

export default {
  components: { ClearOldData, Table },
  data() {
    return {
      date: "",
      filtered: true,
      status_items: [
        { text: "Filtered", value: true },
        { text: "All", value: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["missedTransactions"]),
    items() {
      if (this.filtered) {
        return this.missedTransactions?.filter((o) => o.status >= 0);
      }
      return this.missedTransactions;
    },
  },
  methods: {
    ...mapActions(["setMissedTransactions"]),
    async load(date) {
      this.date = date;
      const timezone = "America/Denver";
      const day = moment.tz(this.date, timezone);
      const begin = day.startOf("day").toDate();
      const end = day.endOf("day").toDate();
      const params = { criteria: { createdAt: { $gte: begin, $lt: end } } };
      const result = await this.$api.transaction.missed.list(params);
      this.setMissedTransactions(result);
    },
    load_txn(date, biz) {
      this.$emit("load-txn", date, biz);
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="500")
  template(v-slot:activator="{ on }")
    IconBtn(title="Add", icon="mdi-plus", v-on="on")
  v-card
    v-card-title Assign Enterprise Business
    v-list.pt-0(dense)
      Cell(
        v-for="(item, index) in items",
        :key="index",
        :item="item",
        @saved="handleSave"
      )
</template>

<script>
import { mapGetters } from "vuex";
import Cell from "./Cell";

export default {
  components: { Cell },
  data() {
    return { dialog: false };
  },
  computed: {
    ...mapGetters(["bizAccounts"]),
    ...mapGetters("entp", ["enterprise"]),
    items() {
      return this.bizAccounts
        ?.filter((i) => !i.enterprise)
        ?.toSorted((a, b) => {
          return a.name.localeCompare(b.name);
        });
    },
  },
  methods: {
    handleSave() {
      if (this.items.length === 0) this.dialog = false;
      this.$emit("saved");
    },
  },
};
</script>

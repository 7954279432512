<template lang="pug">
v-btn(
  v-if="!hasStatus",
  color="secondary",
  text,
  :loading="loading",
  @click="updateStatus()"
) Update Status
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["chargeback"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    hasStatus() {
      return this.chargeback?.dispute_status && this.chargeback?.dispute_reason;
    },
  },
  methods: {
    ...mapActions(["updateChargeback"]),
    async updateStatus() {
      const id = this.chargeback.disputeId;
      if (!id) return;
      this.loading = true;
      try {
        // retrieve dispute
        const dispute = await this.$api.stripe.disputes.retrieve({ id });
        const action = {
          $set: {
            dispute_status: dispute.status,
            dispute_reason: dispute.reason,
          },
        };
        // update dispute status
        const result = await this.$api.chargeback.put(
          this.chargeback._id,
          action
        );
        this.updateChargeback(result);
        this.$toast.success("Dispute status updated.");
      } catch (e) {
        this.$toast.error(
          e.response?.data || "Failed to update dispute status."
        );
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="400", @keydown.esc="dialog = false")
  v-card
    v-toolbar(dense, flat, dark, color="green")
      .subtitle-2 Send Gift Card to a Friend
    v-card-text.pt-2
      span Enter email below. If entered email is a registered user of our services, your gift card will be delivered immediately. Otherwise, your friend will receive an email with instruction to claim the gift card.
      v-form(@submit.prevent="submit")
        v-text-field(
          label="Email",
          v-model="email",
          :error-messages="emailErrors",
          @blur="$v.email.$touch()",
          autofocus,
          ref="focus"
        )
        v-text-field(label="Message", v-model="message")
        v-row(justify="space-between")
          v-btn(text, @click="dialog = false") Cancel
          v-btn(
            :disabled="$v.$invalid",
            type="submit",
            color="secondary",
            :loading="loading"
          ) Send
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  validations: { email: { required, email } },
  data() {
    return {
      giftcard: null,
      email: "",
      message: "",
      loading: false,
      dialog: false,
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required");
      !this.$v.email.email && errors.push("Invalid email address");
      return errors;
    },
  },
  methods: {
    ...mapActions(["updateGiftCard"]),
    open(giftcard) {
      this.giftcard = giftcard;
      this.message = giftcard.message || "";
      this.dialog = true;
    },
    async submit() {
      const gcId = this.giftcard._id;
      const params = { gcId: gcId, email: this.email, message: this.message };
      this.loading = true;
      try {
        const { data } = await this.axios.post("/giftcard/transfer", params);
        this.updateGiftCard(data);
        this.$toast.success("Gift card sent");
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

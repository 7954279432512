<template lang="pug">
Page(title="Shop Sales", :items="items")
  OrderDetail
</template>

<script>
import OrderDetail from "./OrderDetail/index";
export default {
  components: { OrderDetail },
  data() {
    return {
      items: [
        { title: "Orders", url: "/shop/sales/bizorder" },
        { title: "Annual Report", url: "/shop/sales/annual_report" },
        { title: "Purchase", url: "/shop/sales/purchase" },
        { title: "Growth", url: "/shop/sales/growth" },
        { title: "YoY", url: "/shop/sales/yoy" },
      ],
    };
  },
};
</script>

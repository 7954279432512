<template lang="pug">
div
  div(v-for="(byCountry, cindex) in grouped", :key="cindex")
    .title {{ byCountry.country }}
    div(
      v-for="(byState, sindex) in byCountry.states",
      :key="sindex",
      rounded,
      depressed,
      @click="selectedCities = byState.cities"
    )
      .d-flex.flex-row.align-center.my-3
        .sl-title {{ byState.state }}
        v-chip.ml-3(small, outlined) {{ byState.cities.length }}
      div(
        v-for="(byCity, cindex) in byState.cities",
        :key="cindex",
        rounded,
        depressed
      )
        .d-flex.flex-row.align-center.my-3
          .subtitle-2 {{ byCity.city }}
          v-chip.mx-3(small, outlined) {{ byCity.bizs.length }}
          v-menu
            template(v-slot:activator="{ on }")
              v-btn(v-on="on", rounded, outlined, x-small) Set Zone
                v-icon(right, small) mdi-menu-down
            v-card
              v-list.py-0
                v-list-item(
                  v-for="(zone, zone_index) in geoServiceZones",
                  :key="zone_index",
                  @click="setZone(byCity, zone)"
                )
                  v-list-item-content {{ zone.zoneName }}

        simple-table(v-if="byCity.bizs && byCity.bizs.length")
          tbody
            tr(v-for="(item, bindex) in byCity.bizs", :index="bindex")
              td(style="width: 100px")
                v-avatar
                  v-img(:src="mediaHost + item.logo", v-if="item.logo")
                  v-icon(v-else) mdi-image-outline
              td {{ item.name }}
              td
                v-btn.mr-2(
                  x-small,
                  rounded,
                  outlined,
                  @click="handleEditOrderType(item)"
                )
                  span.mx-1(v-if="item.orderType?.pickup.status")
                    v-icon.fa.fa-shopping-basket.mr-1(x-small)
                    span {{ item.orderType.pickup.beforehand }}
                  span.mx-1(v-if="item.orderType?.delivery?.status")
                    v-icon.fa.fa-truck.mr-1(
                      x-small,
                      :color="item.orderType?.delivery?.deliv ? 'success' : ''"
                    )
                    span {{ item.orderType?.delivery?.beforehand }}
                v-chip(
                  small,
                  outlined,
                  v-if="item.delivTiers && item.delivTiers.length"
                )
                  span.mx-1(
                    v-for="(item, tindex) in item.delivTiers",
                    :key="tindex"
                  ) ${{ item.threshold }}: -${{ item.deduction }}
                v-icon(
                  color="error",
                  v-if="item.status?.byAdmin != 1 || item.status?.byUser != 1"
                ) mdi-cancel
              td
                DayHours(:schedule="item.schedule")
                v-btn(icon, small, @click.stop="showSchedule(item.schedule)")
                  v-icon mdi-dots-horizontal
              td.text-right
                span.mr-2(v-if="item.geo_service_zone") {{ item.geo_service_zone.name }}
                WebExposure(:biz="item")
</template>

<script>
import { mapGetters } from "vuex";
import _ from "underscore";
import WebExposure from "./WebExposure";
import { EventBus } from "@/event-bus.js";

export default {
  components: { WebExposure },
  data() {
    return {
      mediaHost: process.env.VUE_APP_MEDIAHOST,
      selectedBizId: null,
    };
  },
  computed: {
    ...mapGetters(["bizAccounts", "geoServiceZones"]),
    grouped() {
      return _.chain(this.bizAccounts)
        .groupBy((o) => o.address.country)
        .map((o, country) => {
          const states = _.chain(o)
            .groupBy((o) => o.address.state)
            .map((o, state) => {
              const bizs = _.sortBy(o, "name");
              const cities = _.chain(bizs)
                .groupBy((item) => item.address?.city)
                .map((bizs, city) => {
                  return { city, bizs };
                })
                .sortBy("city")
                .value();
              return { state, cities };
            })
            .sortBy("state")
            .value();
          return { country, states };
        })
        .value();
    },
  },
  methods: {
    show(item) {
      this.selectedBizId = item._id;
    },
    showSchedule(schedule) {
      EventBus.$emit("show-hours", schedule);
    },
    handleEditOrderType(item) {
      EventBus.$emit("edit-biz-ordertype", item);
    },
    async setZone(city, zone) {
      const bizIds = _.pluck(city.bizs, "_id");
      const params = {
        bizIds,
        geo_service_zone: { name: zone.zoneName, id: zone._id },
      };
      await this.$api.b.action.custom("updateGeoServiceZone", params);
      this.$toast.success("Zone set");
    },
  },
};
</script>

<template lang="pug">
.s-vstack(v-if="biz")
  div(v-if="isClosed")
    .red--text Your business has been set to <b>temporarily closed</b>.
    .red--text Reason: <b>{{ biz.schedule?.temporary_close?.reason }}</b>
    .red--text(v-if="fromNow") Entered <b>{{ fromNow }}</b>
    v-form(@submit.prevent="closeBiz")
      v-text-field(label="Reason", v-model="reason")
      .d-flex.flex-row
        v-btn(type="submit", color="secondary") Update Reason
        v-btn.mx-2.text-capitalize(@click.stop="handleOpen", color="success") Reopen Business
  .body-2(v-else)
    span {{ instruction }}
    .text-center
      v-btn.my-5.text-capitalize(
        @click="handleClose()",
        color="error",
        outlined,
        small
      ) Close Business Temporarily
  v-dialog(v-model="dialog", width="500")
    v-card
      v-card-title Temporary Close
      v-card-text
        p {{ instruction }}
        v-form(@submit.prevent="closeBiz")
          v-text-field(
            label="Reason",
            v-model="reason",
            placeholder="Remodeling, COVID-19, etc."
          )
          .d-flex.flex-wrap.flex-row.mb-5
            v-btn.mr-3.text-capitalize(
              v-for="(item, index) in items",
              :key="index",
              @click="reason = item.text",
              outlined,
              color="secondary",
              small
            ) {{ item.title }}
          v-btn(color="error", block, :disabled="!reason", type="submit") Yes, I want to close business.
</template>

<script>
import moment from "moment";
export default {
  props: { biz: { type: Object, required: true } },
  data() {
    return {
      dialog: false,
      reason: "",
      items: [
        { title: "Remodeling", text: "We are remodeling, will be back soon." },
        {
          title: "Vacation",
          text: "We are taking a break, will be back soon.",
        },
      ],
      instruction:
        "If you need to close your business for an extended period, such as for remodeling or taking a family vacation, you can temporarily close it.",
    };
  },
  computed: {
    isClosed() {
      return this.biz.schedule?.temporary_close?.status;
    },
    fromNow() {
      const start = this.biz.schedule?.temporary_close?.start;
      if (!start) return "";
      return moment(start).fromNow();
    },
  },
  mounted() {
    this.reason = this.biz.schedule?.temporary_close?.reason;
  },
  methods: {
    handleClose() {
      this.reason = "";
      this.dialog = true;
    },
    async closeBiz() {
      if (!this.reason || !this.biz) return;
      try {
        const action = {
          $set: {
            "schedule.temporary_close": {
              status: true,
              reason: this.reason,
              start: Date.now(),
            },
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$store.dispatch("setBiz", result);
        this.$toast.success("Business closed successfully");
        this.$emit("updated", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to close business");
      }
    },
    handleOpen() {
      confirm("Do you want to open business for online ordering again?") &&
        this.openBiz();
    },
    async openBiz() {
      if (!this.biz) return;
      try {
        const action = {
          $set: {
            "schedule.temporary_close": {
              status: false,
              reason: "",
              start: null,
            },
          },
        };
        const result = await this.$api.biz.put(this.biz._id, action);
        this.$emit("updated", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to resume business");
      }
    },
  },
};
</script>
<template lang="pug">
List(:items="contacts")
</template>

<script>
import List from "/libs/components/CRM/Contact/List";
import { mapGetters } from "vuex";

export default {
  components: { List },
  computed: {
    ...mapGetters("crm", ["contacts"]),
  },
  async mounted() {
    await this.load();
  },
  methods: {
    async load() {
      try {
        const criteria = {};
        const result = await this.$api.crm.contact.list({ criteria });
        this.$store.dispatch("crm/setContacts", result);
      } catch (e) {
        this.$toast.error(e.response?.data || "Failed to load contacts");
      }
    },
  },
};
</script>
<template lang="pug">
v-list(v-if="driver")
  v-list-item
    v-list-item-content
      v-list-item-title Driver Newsletter Subscription
    v-list-item-action
      v-switch(
        v-model="driver.subscribe",
        color="secondary",
        :loading="loading",
        @click="toggleSubscribe()"
      )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return { loading: false };
  },
  computed: {
    ...mapGetters(["driver"]),
  },
  methods: {
    ...mapActions(["updateDriver"]),
    async toggleSubscribe() {
      if (!this.driver) return;
      const params = {
        criteria: { _id: this.driver._id },
        action: { $set: { subscribe: this.driver.subscribe } },
      };
      this.loading = true;
      const { data } = await this.axios.post("/driver/accounts/update", params);
      this.updateDriver(data);
      this.loading = false;
    },
  },
};
</script>

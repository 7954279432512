<template lang="pug">
v-card(flat)
  v-toolbar(flat, dense)
    .subtitle-2 {{ region.name }}: ({{ region.bizs?.length }} locations)
    v-spacer
    v-btn(icon, small, color="secondary", @click="edit")
      v-icon(small) mdi-pencil
  v-card-text
    div {{ region.description }}
  simple-table
    thead
      tr
        th Name
        th Short Name
        th Address
        th City
        th.text-right State
    tbody
      tr(v-for="item in items", :key="item._id")
        th {{ item.name | truncate(20) }}
        td {{ item.short_name }}
        td {{ item.address?.line1 }}
        td {{ item.address?.city }}
        td.text-right {{ item.address?.state }}
</template>

<script>
export default {
  props: {
    region: { type: Object, required: true },
    bizs: { type: Array, default: () => [] },
  },
  computed: {
    // mapped bizs
    items() {
      return this.bizs.filter((o) => this.region.bizs.includes(o._id));
    },
  },
  methods: {
    edit() {
      this.$emit("edit", this.region);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="350")
  template(v-slot:activator="{ on }")
    v-list-item(@click="open()", v-on="on") Issue Tracker
  v-card(v-if="delivOrder")
    v-toolbar(dense, flat)
      .subtitle-2 Issue Tracker
    v-card-text(v-if="issue_tracker")
      v-form(@submit.prevent="save")
        v-select(
          v-model="issue_tracker.level",
          :items="level_items",
          label="Level"
        )
        v-select(
          v-model="issue_tracker.tags",
          :items="tag_items",
          multiple,
          label="Tags"
        )
        v-text-field(v-model="issue_tracker.note", label="Note")
        v-btn(type="submit", color="secondary", block, :loading="loading") Save
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["delivOrder"],
  data() {
    return {
      issue_tracker: null,
      dialog: false,
      level_items: [
        { text: "None", value: 0 },
        { text: "Minor", value: 1 },
        { text: "Major", value: 2 },
        { text: "Disaster", value: 3 },
      ],
      tag_items: [
        "missing_item",
        "driver_late",
        "redelivery",
        "change_pickup",
        "wrong_food",
        "customer_cancel",
        "disater_cancel",
      ],
      loading: false,
    };
  },
  methods: {
    ...mapActions(["updateDelivOrder"]),
    open() {
      this.issue_tracker = JSON.parse(
        JSON.stringify(this.delivOrder.issue_tracker)
      );
      this.dialog = true;
    },
    async save() {
      const id = this.delivOrder?._id;
      if (!id) return;
      this.loading = true;
      const action = { $set: { issue_tracker: this.issue_tracker } };
      const result = await this.$api.delivOrder.put(id, action);
      this.updateDelivOrder(result);
      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", width="400")
  v-card(v-if="invoice")
    v-toolbar(dense, flat)
      .subtitle-2 Pay Invoice
      v-spacer
      .subtitle-2(v-if="invoice") Total: {{ invoice.payment.total | currency }}
    v-card-text
      Balance.mb-3(ref="balance")
      Transfer(:invoice="invoice", @close="dialog = false")
      .text-center.my-5 or
      CreditCard(:invoice="invoice", @close="dialog = false")
</template>

<script>
import { EventBus } from "@/event-bus.js";
import { mapGetters } from "vuex";
import CreditCard from "./CreditCard";
import Transfer from "./Transfer";
import Balance from "/libs/components/Stripe/Balance";

export default {
  components: { Balance, Transfer, CreditCard },
  data() {
    return {
      dialog: false,
      invoice: null,
    };
  },
  computed: {
    ...mapGetters(["biz"]),
    managed_account() {
      return this.biz?.payments?.managed_account;
    },
  },
  mounted() {
    EventBus.$on("show-pay-invoice", this.open);
  },
  destroyed() {
    EventBus.$off("show-pay-invoice", this.open);
  },
  methods: {
    async open(invoice) {
      if (!invoice) return;
      this.invoice = invoice;
      this.dialog = true;
      await this.loadBiz();
      this.$refs.balance?.load(this.managed_account);
    },
    async loadBiz() {
      const bizId = this.invoice.biz?.id;
      if (!bizId) return;
      const result = await this.$api.biz.retrieve({ criteria: { _id: bizId } });
      this.$store.dispatch("setBiz", result);
    },
  },
};
</script>

<template lang="pug">
v-dialog(v-model="dialog", max-width="1000")
  v-card(flat, :loading="loading", v-if="order")
    v-card-title
    v-card-text.pb-0
      OrderHeader(:order="order")
      .d-flex.flex-row.justify-space-between
        ClickToCopy(:text="order._id")
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            v-btn(icon, small, v-on.stop="on")
              v-icon(color="secondary") mdi-dots-horizontal
          v-list.py-0(dense)
            EditBiz(:order="order")
      v-row(wrap, dense)
        v-col(cols="12", md="6")
          DishCell(
            v-for="(dish, dindex) in order.dishes",
            :key="dindex + 'dish'",
            :dish="dish",
            :divider="dindex < order.dishes.length - 1",
            prints,
            editable,
            @delete="handleDeleteDish(dish)"
          )
          div(v-if="order?.splitChecks?.length")
            div(v-for="(check, index) in order.splitChecks", :key="index")
              v-card-text
                .subtitle-2 Check \#{{ index + 1 }} {{ check._id }}
                Payment(:payment="check?.payment")
              Charges(
                :order="order",
                :payment="check?.payment",
                :check="check"
              )
          Charges(:order="order", :payment="order?.payment")
          v-card-text
            Payment(:payment="order?.payment")
            div(v-if="order?.status == -1 && isLocal")
              .d-flex.flex-row.justify-space-between
                .red--text Canceled
                v-btn(@click="handleUndoCancel()", small, color="error") Undo Cancel
              span {{ order.cancelNote }}
            .caption(v-if="order?.chowly_id") Chowly: {{ order.chowly_id }}
            AddCharge.my-3(:order="order", v-if="order.status >= 0")
            MessagePOS(:order="order")
        v-col(cols="12", md="6")
          v-card-text
            ActionLogs(:order="order", ref="actionLogs")
</template>

<script>
import AddCharge from "./AddCharge";
import { mapActions, mapGetters } from "vuex";
import EditBiz from "./EditBiz";
import MessagePOS from "./MessagePOS";
import Payment from "./Payment";
import Charges from "./Charges";
import ActionLogs from "/libs/components/Order/ActionLogs/index";

export default {
  components: { AddCharge, EditBiz, MessagePOS, Payment, Charges, ActionLogs },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["order"]),
    canDelete() {
      return (
        this.order.status >= 0 &&
        this.order.status < 3 &&
        this.order.orderer?.type == 1
      );
    },
    isLocal() {
      return this.order?.orderer?.type == 1;
    },
  },
  methods: {
    ...mapActions(["updateOrder", "setOrder"]),
    async open(id) {
      if (!id) return;
      this.dialog = true;
      this.loading = true;
      try {
        const params = { criteria: { _id: id } };
        const result = await this.$api.order.retrieve(params);
        this.setOrder(result);
        await this.wait(100);
        this.$refs.actionLogs.load();
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    async wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    handleDeleteDish(dish) {
      if (!this.canDelete) {
        return this.$toast.error("Cannot delete dish from this order");
      }
      confirm("Are you sure to delete this dish?") && this.deleteDish(dish);
    },
    async deleteDish(dish) {
      if (!this.order) return;
      this.loading = true;
      const params = { orderId: this.order._id, dish: dish };
      try {
        const { data } = await this.axios.post("/orders/deleteDish", params);
        this.updateOrder(data);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
    handleUndoCancel() {
      confirm("Are you sure to undo cancel?") && this.undoCancel();
    },
    async undoCancel() {
      if (!this.order) return;
      this.loading = true;
      const params = {
        criteria: { _id: this.order._id },
        action: { $set: { status: 2, cancelNote: "" } },
      };
      const result = await this.$api.order.update(params);
      this.updateOrder(result);
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  v-col(align="center")
    YearSelector(@select="load")
  v-row(justify="center")
    .sl-medal.elevation-2
      h2 Total Value
      h1 {{ total | currency }}
  v-row(wrap, justify="center")
    v-col(cols="12", md="4")
      Chart(:input="amountData")
</template>

<script>
import _ from "underscore";
export default {
  data() {
    return {
      year: 2019,
      result: [],
      amountData: null,
    };
  },
  computed: {
    total() {
      return this.result.reduce((a, b) => a + b.income, 0);
    },
  },
  methods: {
    async load(year) {
      if (year) this.year = year;
      const params = { criteria: { year: this.year } };
      const result = await this.$api.merchant.income.list(params);
      this.result = _.chain(result).sortBy("month").sortBy("year").value();
      this.setData();
    },
    setData() {
      const months = this.result.map((o) => o.year + "/" + o.month);
      const amounts = this.result.map((o) => o.income);
      this.amountData = {
        title: "Amount",
        labels: months,
        values: amounts,
        isCurrency: true,
      };
    },
  },
};
</script>

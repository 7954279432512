<template lang="pug">
List(:reviews="reviews")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import List from "/libs/components/Review/List";

export default {
  components: { List },
  computed: {
    ...mapGetters(["reviews"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["setReviews"]),
    async load() {
      const params = { criteria: {}, limit: 20 };
      const result = await this.$api.review.list(params);
      this.setReviews(result);
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="items",
  :headers="headers",
  sort-by="biz_name",
  dense,
  mobile-breakpoint=0,
  @click:row="select",
  role="button"
)
  template(v-slot:item.last_visit="{ item }")
    span {{ item.last_visit | date({ dynamic: true }) }}
</template>

<script>
import _ from "underscore";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Biz", value: "biz_name" },
        { text: "Model", value: "model" },
        { text: "iOS", value: "systemVersion" },
        { text: "App", value: "appVersion" },
        { text: "Device ID", value: "deviceUUID" },
        { text: "Last Visit", value: "last_visit", align: "end" },
      ],
    };
  },
  computed: {
    ...mapGetters(["pos_devices", "bizAccounts"]),
    items() {
      return _.map(this.pos_devices, (o) => {
        const found = _.find(this.bizAccounts, (biz) => biz._id == o.biz);
        if (found) o.biz_name = found.name;
        else o.biz_name = o.biz;
        o.deviceUUID = o.deviceUUID.slice(0, 8);
        return o;
      });
    },
  },
  methods: {
    ...mapActions(["removePOSDevice"]),
    select(item) {
      this.$emit("select", item);
    },
  },
};
</script>
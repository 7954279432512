const state = {
  userAccounts: []
};

const getters = {
  userAccounts: state => state.userAccounts
};

const actions = {
  setUserAccounts: ({ commit }, data) => { commit("setUserAccounts", data); },
  updateUserAccount: ({ commit }, data) => { commit("updateUserAccount", data); },
  removeUserAccount: ({ commit }, data) => { commit("removeUserAccount", data); },
};

const mutations = {
  setUserAccounts(state, data) { state.userAccounts = data; },
  updateUserAccount(state, data) {
    state.userAccounts = state.userAccounts.map(o => {
      if (o._id === data._id) return data;
      return o;
    });
  },
  removeUserAccount(state, data) {
    state.userAccounts = state.userAccounts.filter(o => o._id !== data._id);
  }
};

export default { state, getters, actions, mutations };
<template lang="pug">
div
  .sl-title Products
  v-data-table(:headers="headers", :items="ins", mobile-breakpoint=0)
    template(v-slot:item.date="{ item }")
      td {{ item.date | date }}
    template(v-slot:item.staff="{ item }")
      UserName(:users="users", :id="item.staff")
    template(v-slot:item.product="{ item }")
      ProductName(:products="products", :id="item.product")
    template(v-slot:item.action="{ item }")
      Delete(:item="item", @deleted="ins.splice(item.index, 1)")
</template>

<script>
import ProductName from "./ProductName";
import UserName from "./UserName";
import Delete from "./Delete";

export default {
  components: { ProductName, UserName, Delete },
  props: ["ins", "products", "users"],
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Staff", value: "staff", sortable: "" },
        { text: "Product", value: "product", sortable: "" },
        { text: "Vendor", value: "vendor", sortable: false },
        { text: "Order", value: "order", sortable: false },
        { text: "Cost", value: "cost", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
    };
  },
};
</script>

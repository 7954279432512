<template lang="pug">
v-row(wrap)
  v-col(
    cols="12",
    sm="6",
    md="4",
    lg="3",
    v-for="(item, index) in subgroups",
    :key="index"
  )
    v-card(flat)
      v-card-text
        Icon(:subgroup="item")
        v-row.mt-3(justify="center", align="center")
          .subtitle-2 {{ item.name }}
          v-chip.mx-3(small, outlined) {{ item.products.length }}
          v-btn(icon, small, @click="handleEdit(item)")
            v-icon(small, color="secondary") mdi-pencil
</template>

<script>
import { mapGetters } from "vuex";
import Icon from "./Icon";

export default {
  components: { Icon },
  computed: {
    ...mapGetters("catalog", ["subgroups"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      const params = { criteria: {} };
      const result = await this.$api.catalog.subgroup.list(params);
      result.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      this.$store.dispatch("catalog/setSubGroups", result);
    },
    handleEdit(subgroup) {
      this.$emit("edit", subgroup);
    },
  },
};
</script>

<template lang="pug">
v-btn(small, text, @click="handleAssign()", v-if="canAssign") Assign
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  props: ["item"],
  computed: {
    canAssign() {
      return this.item && !this.item.biz && !this.item.deliv && !this.item.user;
    },
  },
  methods: {
    handleAssign() {
      EventBus.$emit("tax-file-assign", this.item);
    },
  },
};
</script>

<template lang="pug">
Page(title="Documents")
  List
  ViewDocument
</template>

<script>
import List from "./List";
import ViewDocument from "./ViewDocument/index.vue";

export default {
  components: { List, ViewDocument },
};
</script>
<template lang="pug">
IconBtn(@click="create", icon="mdi-plus", title="Product")
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    create() {
      EventBus.$emit("edit-product");
    },
  },
};
</script>

<template lang="pug">
v-data-table(
  :items="email_samples",
  :headers="headers",
  sort-by="title",
  mobile-breakpoint=0,
  @click:row="edit",
  type="button"
)
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  data() {
    return {
      headers: [
        { text: "Subject", value: "subject" },
        { text: "Content", value: "content_short" },
      ],
    };
  },
  computed: {
    ...mapGetters(["email_samples"]),
  },
  methods: {
    edit(item) {
      EventBus.$emit("edit-email-sample", item);
    },
  },
};
</script>
<template lang='pug'>
div(v-if="payment")
  .item(v-for="(item, index) in service_items", :key="index")
    .item-name {{ item.name }}
    .item-detail {{ item.value | currency }}
  .item
    .item-name Subtotal
    .item-detail {{ payment.subtotal || payment.dish | currency }}
  div(v-for="(item, index) in payment.tax_items", :key="index + 'tax'")
    .item(v-if="item.type == 0")
      .item-name {{ item.name }} {{ item.percentage }}%
      .item-detail {{ item.value | currency }}
  .item(v-if="payment.gift > 0")
    .item-name Gift
    .item-detail {{ payment.gift | currency }}
  .item(v-if="payment.fee > 0")
    .item-name.pr-5 Fee
    .item-detail {{ payment.fee | currency }}
  .green--text(v-if="payment.deduction")
    .item(
      v-for="(item, index) in payment.deduction.items",
      :key="index + 'deduction'"
    )
      .item-name {{ item.name }}
      .item-detail {{ -item.value | currency }}
  .green--text(v-if="payment.adjustment")
    .item(
      v-for="(item, index) in payment.adjustment.items",
      :key="index + 'adjustment'"
    )
      .item-name {{ item.name }}
      .item-detail {{ item.value | currency }}
  .orange--text(v-else-if="payment.selflane")
    .item(
      v-for="(item, index) in payment.selflane.items",
      :key="index + 'selflane'"
    )
      .item-name {{ item.name }}
      .item-detail {{ item.value | currency }}
  .item(v-if="payment.tip > 0")
    .item-name.pr-5 Tip
    .item-detail {{ payment.tip | currency }}
  .item
    .item-name.body-2 Total
    .item-detail {{ payment.total | currency }}
</template>

<script>
export default {
  props: ["payment"],
  computed: {
    service_items() {
      return this.payment?.service?.items || [];
    },
  },
};
</script>

<style lang="sass" scoped>
.item
  display: flex
  align-items: center

  &-name
    font-size: 15px
    flex-grow: 1

  &-detail
    font-size: 15px
    font-weight: 500
    text-align: end
</style>
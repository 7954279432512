<template lang="pug">
v-list-item(@click="toggle")
  v-list-item-content
    v-list-item-title
      span {{ title }}
  v-list-item-action
    v-progress-circular(v-if="loading", size="12", color="secondary")
    v-icon(small, :color="color", v-else) {{ icon }}
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    title: { type: String, required: true },
    item: { type: Object, required: true },
    property: { type: String, required: true },
  },
  data() {
    return { loading: false };
  },
  computed: {
    value() {
      const keys = this.property.split(".");
      if (keys.length === 1) return this.item[keys[0]];
      if (keys.length === 2) return this.item[keys[0]][keys[1]];
      return null;
    },
    color() {
      return this.value ? "success" : "gray";
    },
    icon() {
      return this.value ? "mdi-check" : "mdi-minus";
    },
  },
  methods: {
    ...mapActions(["setBizService"]),
    async toggle() {
      this.loading = true;
      const newValue = !this.value;
      const action = { $set: { [this.property]: newValue } };
      try {
        const result = await this.$api.service.account.put(
          this.item._id,
          action
        );
        this.setBizService(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
<template lang="pug">
v-dialog(v-model="dialog", width="450")
  v-card(v-if="subgroup")
    v-toolbar(dense, flat)
      .overline {{ title }}
      v-spacer
      Delete(:subgroup="subgroup", @removed="dialog = false")
    v-divider
    v-card-text
      v-form.mt-5(@submit.prevent="save()")
        v-text-field(v-model="subgroup.name", label="name")
        simple-table
          tbody
            tr(
              v-for="(item, index) in products",
              :key="index",
              draggable="true",
              @dragstart="drag(index, $event)",
              @dragend="dragend($event)",
              @drop="drop(index, $event)",
              @dragover.prevent=""
            )
              td
                v-icon(small) mdi-menu
              td {{ item.name }}
              td
                v-btn(icon, @click="removeProduct(index)")
                  v-icon(small, color="error") mdi-delete
        v-btn.my-3(
          block,
          color="secondary",
          type="submit",
          :loading="loading",
          :disabled="!subgroup || !subgroup.name"
        ) Save
      Search(@add="addProduct")
</template>

<script>
import Search from "./Search";
import Delete from "./Delete";
import _ from "underscore";

export default {
  components: { Search, Delete },
  data() {
    return {
      loading: false,
      dialog: false,
      subgroup: null,
      products: [],
      draggingIndex: null,
    };
  },
  computed: {
    title() {
      if (this.subgroup && this.subgroup._id) return "Edit SubGroup";
      else return "Create SubGroup";
    },
  },
  methods: {
    open(data) {
      if (!data) {
        this.subgroup = { name: "", products: [] };
      } else {
        this.subgroup = JSON.parse(JSON.stringify(data));
      }
      this.dialog = true;
      if (this.subgroup.products.length) this.initProducts();
    },
    async initProducts() {
      if (!this.subgroup) return;
      const ids = this.subgroup.products;
      const params = {
        criteria: { _id: { $in: ids } },
        select: "name",
      };
      try {
        const result = await this.$api.catalog.product.list(params);
        this.products = _.map(ids, (id) => {
          return result.find((o) => o._id == id);
        });
      } catch (e) {
        this.products = [];
      }
    },
    addProduct(item) {
      const some = this.products.some((o) => o._id == item._id);
      if (!some) this.products.push(item);
    },
    removeProduct(index) {
      this.products.splice(index, 1);
    },
    drag(index, ev) {
      this.draggingIndex = index;
      ev.target.style.opacity = 0.5;
    },
    dragend: function (e) {
      e.target.style.opacity = 1;
    },
    drop: function (index) {
      if (index == this.draggingIndex) return;
      if (index < 0 || index >= this.products.length) return;
      if (this.draggingIndex < 0 || this.draggingIndex >= this.products.length)
        return;
      let products = JSON.parse(JSON.stringify(this.products));
      let tmp = JSON.parse(JSON.stringify(products[this.draggingIndex]));
      products.splice(this.draggingIndex, 1);
      products.splice(index, 0, tmp);
      this.products = products;
    },
    async save() {
      if (!this.subgroup || !this.subgroup.name) return;
      if (!this.subgroup._id) return this.create();

      this.loading = true;
      const products = this.products.map((o) => o._id);
      const action = { $set: { products, name: this.subgroup.name } };
      try {
        const result = await this.$api.catalog.subgroup.put(
          this.subgroup._id,
          action
        );
        this.$store.dispatch("catalog/updateSubGroup", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response.data);
      }
      this.loading = false;
    },
    async create() {
      const products = this.products.map((o) => o._id);
      const params = { name: this.subgroup.name.trim(), products };
      this.loading = true;
      try {
        const result = await this.$api.catalog.subgroup.create(params);
        this.$store.dispatch("catalog/addSubGroup", result);
        this.dialog = false;
      } catch (e) {
        this.$toast.error(e.response.data);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
v-btn(
  icon,
  @click="handleDelete()",
  v-if="managed_account",
  color="error",
  :loading="loading"
)
  v-icon(small) mdi-delete
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["biz"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    managed_account() {
      return this.biz?.payments?.managed_account;
    },
  },
  methods: {
    ...mapActions(["setBiz"]),
    handleDelete() {
      confirm("Do you want to remove the managed account?") && this.remove();
    },
    async remove() {
      if (!this.biz) return;
      this.loading = true;
      try {
        const params = { bizId: this.biz._id };
        const result = await this.$api.biz.custom(
          "deleteManagedAccount",
          params
        );
        this.setBiz(result);
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>

<template lang="pug">
div
  IconBtn(@click="open()", title="Add", icon="mdi-plus")
  v-dialog(v-model="dialog", width="300")
    v-card
      v-card-title Add a Bouncing Email
      v-card-text
        v-form(@submit.prevent="save")
          v-text-field(v-model="email", label="Email")
          v-btn(type="submit", block, :disabled="!email", color="secondary") Save
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      email: "",
    };
  },
  methods: {
    ...mapActions(["addBouncingEmail"]),
    open() {
      this.email = "";
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.focus.focus();
      });
    },
    async save() {
      if (!this.email) return;
      const params = { email: this.email.trim() };
      try {
        const result = await this.$api.bouncingEmail.create(params);
        this.addBouncingEmail(result);
        this.dialog = false;
      } catch (err) {
        this.$toast.error(err.response?.data);
      }
    },
  },
};
</script>

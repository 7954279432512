<template lang="pug">
div
  div(v-for="(item, index) in items", :key="index")
    Cell(:item="item", :order="order", @updated="$emit('updated')")
</template>

<script>
import Cell from "./Cell";

export default {
  components: { Cell },
  props: ["items", "order"],
};
</script>

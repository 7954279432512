<template lang="pug">
div
  v-row.mb-3(justify="center")
    DaySelector(@select="load")
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment-timezone";

export default {
  data() {
    return {
      selectedDate: null,
    };
  },
  destroyed() {
    this.setUserAccounts([]);
  },
  methods: {
    ...mapActions(["setUserAccounts"]),
    async load(date) {
      if (date) {
        this.selectedDate = date;
      }
      if (!this.selectedDate) return;
      const begin = moment(this.selectedDate).startOf("day").unix() * 1000;
      const end = moment(this.selectedDate).endOf("day").unix() * 1000;
      const params = {
        criteria: {
          created: { $gt: begin, $lte: end },
        },
        select: "email google apple status phones member payments created",
        limit: 1000,
      };
      const result = await this.$api.user.list(params);
      this.setUserAccounts(result);
    },
  },
};
</script>
